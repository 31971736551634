import React from "react";
import { Layout } from "antd";
import CustomHeader from "./header.js";
import CustomFooter from "./footer.js";
import Log from "../utils/log.js";
import { withCookies } from "react-cookie";
import "../styles/recipient.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddAirtimeRecipientDetails from "../containers/AddAirtimeRecipientDetails.js";

const { Header, Footer } = Layout;
const TAG = "AddAirtimeSenderDetailsContainer";

class AddRecipientDetailsContainer extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
  renderBody() {
    if (this.props.countryReducer.airtimeDataSendingCountries.length > 0) {
      return <AddAirtimeRecipientDetails {...this.props} />;
    } else {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.title = "Manage SenderDetails";
    Log.d(
      TAG,
      "componentDidMount() -- current state=" + JSON.stringify(this.state)
    );
    window.scrollTo(0, 0);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(AddRecipientDetailsContainer));
