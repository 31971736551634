import React from "react";
import { Layout } from "antd";
import { withCookies } from "react-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CustomHeader from "./header.js";
import CustomFooter from "./footer.js";
import "../styles/recipient.css";
import SmartBuyAirtime from "../containers/BuyAirtime";
import Log from "./../utils/log.js";

const { Header, Footer } = Layout;
const TAG = "DumbBuyAirtimeData";

class BuyAirtime extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        {this.renderContentWhenSafe()}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }

  renderBody() {
    if (this.props.countryReducer.sendingCountries.length > 0) {
      return this.renderContentWhenSafe();
    } else {
      return this.props.history.push("/");
    }
  }

  renderContentWhenSafe() {
    return <SmartBuyAirtime {...this.props} />;
  }

  componentDidMount() {
    document.title = "Send Airtime or Data";
    window.scrollTo(0, 0);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

// export default withCookies(Header)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(BuyAirtime));
