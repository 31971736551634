import React from "react";
import { Form, Input, Menu, Button, Dropdown, Icon, Checkbox, message } from "antd";
import { Layout } from "antd";
import Log from "../utils/log.js";
import "../styles/recipient.css";

import { requestAirtimeDataProducts, requestOTPCode } from "../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartAddAirtimeSender";

class AddAirtimeRecipientDetails extends React.Component {
  render() {
    return (
      <Content className="ar-content-container">
        <WrappedAddAirtimeRecipientForm {...this.props} />
      </Content>
    );
  }
}

class AddAirtimeRecipientForm extends React.Component {
  constructor(props) {
    super(props);

    const receivingCountries =
      this.props.countryReducer.airtimeDataReceivingCountries;
    let sendingCountry = {};
    let sender = {};
    let savedRecipient = {}
    if (props.location != null && props.location.state != null) {
      sendingCountry = props.location.state.sendingCountry;
      sender = props.location.state.sender;
      savedRecipient = props.location.state.recipient || {};
    }

    this.state = {
      receivingCountries,
      sender,
      sendingCountry,
      receivingCountry: receivingCountries[0],
      receivingCountryIndex: 0,
      saveRecipient: false,
      savedRecipient,
      goToOTPScreen: false,
      requestRecipientList: false,
    };
  }

  componentDidMount() {
    if (Object.keys(this.state.savedRecipient).length && this.state.receivingCountries.length) {
      const receivingCountry = this.state.receivingCountries.find(country => country.id === this.state.savedRecipient.country);
      const receivingCountryIndex = this.state.receivingCountries.findIndex(country => country.id === this.state.savedRecipient.country);
      this.setState({
        receivingCountry,
        receivingCountryIndex,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      if(this.props.userReducer.error === "Not found.") {
        message.error("No saved beneficiaries available. To view saved beneficiaries, add beneficiary first. Click NEXT to proceed to add beneficiary.");
      } else {
        message.error("" + this.props.userReducer.error);
      }
    }

    if (
      prevProps.airtimeDataProductReducer.products !==
      this.props.airtimeDataProductReducer.products &&
      Object.keys(this.props.airtimeDataProductReducer.products).length > 0
    ) {
      // get recepient from submitted form...
      const values = this.props.form.getFieldsValue();
      const recipientData = this.prepareValues(values);

      this.props.history.push({
        pathname: 'guest-select-product-option',
        state: {
          sender: this.state.sender,
          receivingCountries: this.state.receivingCountries,
          receivingCountry: recipientData.receivingCountry,
          sendingCountry: this.state.sendingCountry,
          recipient: recipientData.recipient,
        }
      });
    }

    if (prevProps.otpReducer.success !== this.props.otpReducer.success &&
      this.props.otpReducer.success) {
      const values = this.props.form.getFieldsValue();
      const recipientData = this.prepareValues(values);

      this.props.history.push({
        pathname: 'airtime-otp',
        state: {
          saveRecipient: false,
          requestTransactionHistory: false,
          requestRecipientList: true,

          otpSender: this.prepareOTPSender(this.state.sender),

          sender: this.state.sender,
          receivingCountries: this.state.receivingCountries,
          receivingCountry: recipientData.receivingCountry,
          sendingCountry: this.state.sendingCountry,
          recipient: recipientData.recipient,
        }
      });
    }

    if (
      prevProps.airtimeDataProductReducer.loading !==
      this.props.airtimeDataProductReducer.loading
    ) {
      const loading = this.props.airtimeDataProductReducer.loading;
      this.setState({
        loading,
      });
    }

    if (
      prevProps.airtimeDataProductReducer.error !==
      this.props.airtimeDataProductReducer.error
    ) {
      const errorMessage = this.props.airtimeDataProductReducer.error;
      if (errorMessage) {
        const confirmResponse = window.confirm(errorMessage);
        if (!confirmResponse) {
          this.props.history.push("/airtime-sender-details");
        }
      }
    }
  }

  prepareOTPSender(values) {
    const otpSender = {
      username: `${values.sender_mobile[0]} ${values.sender_mobile[1]}`,
      first_name: values.sender_first_name,
      last_name: values.sender_last_name,
    };
    return otpSender;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="ru-box">
        <Form className="ar-body" onSubmit={this.handleSubmit}>
          <h2 className="ar-add-recipient">Recipient Details</h2>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              {this.renderReceivingCountries(getFieldDecorator)}
              {this.renderFirstName(getFieldDecorator)}
              {this.renderLastName(getFieldDecorator)}
              {this.renderPhoneInput(getFieldDecorator)}
              {this.renderConfirmCheckbox(getFieldDecorator)}
              <div><p>{this.state.loading && 'Please wait...'}</p></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </div>
                <FormItem className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  {!this.props.authReducer.guestUserAccessToken && (
                    <Button
                      className="home-collect"
                      style={{
                        backgroundColor: "white",
                        boxSizing: "border-box",
                        color: "#154989",
                        margin: "8px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          goToOTPScreen: true,
                          requestRecipientList: true,
                        });

                        this.props.requestOTPCode(this.props.location.state.otpSender);
                      }}
                    >
                      Select from saved recipients
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  renderConfirmCheckbox(getFieldDecorator) {
    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <FormItem>
          {getFieldDecorator("confirm details", {
            rules: [
              {
                required: true,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean",
                message: "Please confirm if the above details are correct",
              },
            ],
          })(
            <Checkbox>
              <b>
                I hereby confirm that the information provided herein is
                accurate
              </b>
            </Checkbox>
          )}
        </FormItem>
      </div>
    );
  }

  renderSaveRecipientCheckbox(getFieldDecorator) {
    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <FormItem>
          {getFieldDecorator("save recipient", {
            rules: [
              {
                required: false,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean",
                message: "Please confirm if the above details are correct",
              },
            ],
          })(
            <Checkbox
              onChange={(e => this.setState({ saveRecipient: e.target.checked }))}
            >
              <b>
                Save recipient details
              </b>
            </Checkbox>
          )}
        </FormItem>
      </div>
    );
  }

  renderCountries() {
    if (!!this.state) {
      return this.state.receivingCountries.map((itemAr, index) => {
        return <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>;
      });
    } else {
      return <div />;
    }
  }

  onChangeCountry = (event) => {
    this.setState({
      receivingCountry: this.state.receivingCountries[event.key],
    });
    this.props.form.setFieldsValue({
      "receiving-country": event.key,
    });
    this.props.form.setFieldsValue({
      phonePrefix:
        this.state.receivingCountries[event.key].attributes.phone_prefix,
    });
  };

  renderCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    );
  }

  renderReceivingCountry() {
    if (
      this.state.receivingCountry != null &&
      Object.keys(this.state.receivingCountry).length > 0
    ) {
      return <div>{this.state.receivingCountry.attributes.name}</div>;
    } else {
      return <div />;
    }
  }

  renderReceivingCountries(getFieldDecorator) {
    return (
      <FormItem label="Country">
        {getFieldDecorator("receiving-country", {
          rules: [
            {
              required: true,
              message: "Please select airtime receiving country",
            },
          ],
          initialValue: this.state.receivingCountryIndex,
        })(
          <Dropdown trigger={["click"]} overlay={this.renderCountriesParent()}>
            <div className="dropdown-item">
              {this.renderReceivingCountry()}
              <Icon type="down" />
            </div>
          </Dropdown>
        )}
      </FormItem>
    );
  }

  renderFirstName(getFieldDecorator) {
    return (
      <FormItem label="First Name">
        {getFieldDecorator("first-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient first name",
            },
          ],
          initialValue: this.state.savedRecipient.first_name || '',
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderLastName(getFieldDecorator) {
    return (
      <FormItem label="Last Name">
        {getFieldDecorator("last-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient last name",
            },
          ],
          initialValue: this.state.savedRecipient.last_name || '',
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderPhonePrefix() {
    const phonePrefix = this.state.receivingCountry.attributes.phone_prefix;
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return <div style={{ width: 45 }}>+{phonePrefix}</div>;
    } else {
      return <div />;
    }
  }

  renderPhoneInput(getFieldDecorator) {
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: this.state.receivingCountry.attributes.phone_prefix,
    })(this.renderPhonePrefix());

    const phoneNumber = this.state.savedRecipient.mobile && this.state.savedRecipient.mobile.length
      ? this.state.savedRecipient.mobile[1] : "";

    const label = "Mobile Number";

    return (
      <FormItem label={label}>
        {getFieldDecorator("mobile-number", {
          rules: [
            // { validator: this.validateBSField },
            {
              required: true,
              message: "Please enter recipient mobile number",
            },
          ],
          initialValue: phoneNumber,
        })(
          <Input
            addonBefore={phonePrefix}
            className="mobile-number"
            maxlength="20"
          />
        )}
      </FormItem>
    );
  }

  prepareValues(values) {
    const receivingCountry =
      this.state.receivingCountries[values["receiving-country"]];
    const beneficiaryData = {
      receivingCountry,
      recipient: {
        beneficiary_mobile: [values["phonePrefix"], values["mobile-number"]],
        beneficiary_first_name: values["first-name"],
        beneficiary_last_name: values["last-name"],
      },
    };
    return beneficiaryData;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // we could confirm if there are any products here...
        this.props.requestAirtimeDataProducts(
          values["phonePrefix"] + "" + values["mobile-number"]
        );
        this.setState({
          loading: true,
        });
      }
    });
  };
}

const WrappedAddAirtimeRecipientForm = Form.create()(AddAirtimeRecipientForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestAirtimeDataProducts,
      requestOTPCode,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAirtimeRecipientDetails);
