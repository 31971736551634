import React from 'react';
import { Layout, Tabs } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import SmartAgents from '../../containers/Agents.js'
import SmartCashInPoints from '../../containers/CashInPoints.js';
import SmartCashOutPoints from '../../containers/CashOutPoints.js';
import '../../styles/dashboard.css'

const { Content, Header, Footer } = Layout;
const TabPane = Tabs.TabPane;

class FindAgents extends React.Component {

  componentDidMount() {
    document.title = "Find Agents"
    window.scrollTo(0, 0)
  }

  constructor(props) {
    super(props);
    this.state = {
      headerKey: "1",
      defaultActiveKey: "1"
    }
  }

  onChange = (activeKey) => {
    this.setState({
      defaultActiveKey : activeKey
    })
  }

  render() {
    return (
      <Layout style={{ minWidth: '600px' }}>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="agent-top-bg"/>
        <Content className="agent-body">
        <Tabs defaultActiveKey="1" activeKey={this.state.defaultActiveKey} onChange={this.onChange}>
          <TabPane tab="Registration Points" key="1">
            <h3 style={{ textAlign: 'center', margin: '20px'}}>Brand Ambassadors in South Africa</h3>
            <h6 style={{ textAlign: 'center', marginBottom: '20px'}}><i>Where to register on Sasai Money Transfer</i></h6>
            <SmartAgents {... this.props}/>
          </TabPane>
          <TabPane tab="Cash In Points" key="2">
            <h3 style={{ textAlign: 'center', margin: '20px'}}>Cash-In Agents in South Africa</h3>
            <h6 style={{ textAlign: 'center', marginBottom: '20px'}}><i>Where to pay in South Africa</i></h6>
            <SmartCashInPoints {... this.props}/>
          </TabPane>
          <TabPane tab="Cash Out Points" key="3">
            <h3 style={{ textAlign: 'center', margin: '20px' }}>Cash Collection in Zimbabwe</h3>
            <h6 style={{ textAlign: 'center', marginBottom: '20px'}}><i>Where to collect cash in Zimbabwe</i></h6>
            <SmartCashOutPoints {... this.props}/>
          </TabPane>
        </Tabs>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(FindAgents)
