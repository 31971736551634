import { Input, Layout, Table, Button, Divider, Spin } from "antd";
import React from "react";
import {
  requestRecipients,
  fetchUserDetails,
  deleteRecipient,
  requestAirtimeDataReceivingCountries,
  showRecipient,
  showSendingCountry,
  requestProductDetails,
  requestRecipientByID,
  showSendingCountries
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../styles/user.css";
import Log from "../utils/log";
import config from "../env-config.json";
import ManageUrls from "../endpoints/manage_urls.js";

const { Content } = Layout;
const Search = Input.Search;
const TAG = "SMART SEND AIRTIME / DATA";

class BuyAirtime extends React.Component {
  constructor(props) {
    super(props);
    Log.d(
      TAG,
      "AIRTIME/ DATA Beneficiaries List::constructor() -- props=" +
        JSON.stringify(props)
    );
    let beneficiarySettings = {};

    const manageUrls = new ManageUrls();

    this.state = {
      access: this.props.authReducer.accessToken || "",
      beneficiarySettings: beneficiarySettings,
      loading: false,
      loadingTip: "",
      currentLink:
        manageUrls.getApiUrl() +
        "beneficiaries/?filter[beneficiary_type]=AT&page[size]=20&include=country",
      columns: [
        {
          title: "Recipient Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Location",
          dataIndex: "location",
          render: (text, record) => this.renderRecipientCountry(record),
        },
        // {
        //   title: "Action",
        //   key: "action",
        //   render: (text, record) => this.renderRecipientActions(record),
        // },
      ],
      recipients: [],
      recipient: {},
      sendingCountry: this.props.countryReducer.sendingCountry,
      receivingCountries:
        this.props.countryReducer.airtimeDataReceivingCountries,
    };
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.recipientReducer.recipients !==
      this.props.recipientReducer.recipients
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- recipients=" +
          JSON.stringify(this.props.recipientReducer.recipients)
      );
      this.setState({
        loading: false,
        loadingTip: "",
        currentLink: this.props.recipientReducer.url,
        recipients: this.props.recipientReducer.recipients,
      });
    }

    if (
      prevProps.countryReducer.airtimeDataReceivingCountries !==
      this.props.countryReducer.airtimeDataReceivingCountries
    ) {
      this.setState({
        receivingCountries:
          this.props.countryReducer.airtimeDataReceivingCountries,
      });
    }

    if (
      prevProps.recipientReducer.recipient !==
        this.props.recipientReducer.recipient &&
      "data" in this.props.recipientReducer.recipient
    ) {
      let receivingCountry = {};
      if (
        "included" in this.props.recipientReducer.recipient &&
        Object.keys(this.props.recipientReducer.recipient.included).length > 0
      ) {
        receivingCountry = this.props.recipientReducer.recipient.included.find(
          (included) => included.type === "countries"
        );
      }
      this.props.history.push({
        pathname: `edit-airtime-recipient/${this.state.recipient.id}`,
        state: {
          recipient: this.props.recipientReducer.recipient,
          productOption: this.state.productOption,
          sendingCountry: this.state.sendingCountry,
          receivingCountries: this.state.receivingCountries,
          receivingCountry,
        },
      });
    }

    if (
      prevProps.userReducer.user !== this.props.userReducer.user &&
      Object.keys(this.props.userReducer.user).length > 0
    ) {
      var userIncluded = this.props.userReducer.user.included;
      if (userIncluded.length > 0) {
        var sendingCountries = [];
        for (var k = 0; k < this.props.userReducer.user.included.length; k++) {
          if (this.props.userReducer.user.included[k].type === "countries") {
            sendingCountries.push(userIncluded[k]);
          }
        }
        this.props.showSendingCountries(sendingCountries);
      }
      this.setSendingCountry(userIncluded);
    }
  }

  setSendingCountry(collection) {
    if (collection.length > 0) {
      Log.d(TAG, "setSendingCountry() -- country=" + JSON.stringify(collection[0]));
      this.setState({
        sendingCountry : collection[0],
      });

      this.requestRecipients(this.state.currentLink)
    }
  }

  requestRecipients(url) {
    const { cookies } = this.props;
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      // Log.d(TAG, "requestRecipients() -- access=" + cookies.get('access'));
      Log.d(
        TAG,
        "requestRecipients() -- access=" + this.props.authReducer.accessToken
      );
      this.setState({
        loading: true,
        loadingTip: "Fetching beneficiaries ..",
      });
      // check if there is sending country and sending countries...
      if (this.props.countryReducer.sendingCountries.length > 0) {
        // this.props.requestRecipients(url, cookies.get('access'));
        this.props.requestRecipients(url, this.props.authReducer.accessToken);

        // this fix will not work for none authenticated users...
        // we'll need to check how the sendingCountries object
        // is structured when user is not authenticated
        const sendingCountry = this.checkHasSendingCountry()
          ? this.state.sendingCountry
          : this.getSendingCountryObject();
        this.props.showSendingCountry(sendingCountry);
        this.props.requestAirtimeDataReceivingCountries(
          sendingCountry.attributes.alpha_2_code
        );
      } else {
        // update user and run comp-did-update
        // to update sending country from user state.
        this.props.fetchUserDetails(cookies, this.props.authReducer.accessToken, false, "Fetched user's details")
      }
    } else {
      // route user to login if there's no access token
      this.props.history.push("/login");
    }
  }

  getSendingCountryObject() {
    const sendingCountry = this.props.countryReducer.sendingCountries[0];
    this.setState({
      sendingCountry,
    });
    return sendingCountry;
  }

  checkHasSendingCountry() {
    return this.props.countryReducer.sendingCountry.hasOwnProperty(
      "attributes"
    );
  }

  reset() {
    this.setState({
      recipients: [],
      next: null,
      prev: null,
      loading: false,
      loadingTip: "",
    });
    if (this.state.currentLink) {
      this.requestRecipients(this.state.currentLink);
    }
  }

  render() {
    return (
      <Content className="recipient-content-container">
        <div
          className="rl-top"
          style={{ paddingLeft: "10px", paddingRight: "10px" }}
        >
          <h2 className="rl-select-recipient">Select your recipient</h2>
          <div className="rl-p-search-add-right">
            <Search
              placeholder="Search recipient and press 'Enter'"
              onSearch={this.onSearch}
              className="search"
            />
            <Button
              className="add-recipient"
              icon="plus"
              onClick={this.onAddRecipient}
              disabled={this.state.disableAddButton}
            >
              {" "}
              Add Recipient
            </Button>
          </div>
        </div>
        <Divider />
        <div className="trl-point-table" style={{ width: "100%" }}>
          {this.renderBuyAirtimeDataForm()}
        </div>
        <div className="pagination-box">
          <div className="pagination-box-left">
            {this.showPrevButton()}
            {this.showFirstButton()}
          </div>
          <div className="pagination-box-middle">
            {this.renderCurrentLink()}
          </div>
          <div className="pagination-box-right">
            {this.showLastButton()}
            {this.showNextButton()}
          </div>
        </div>
      </Content>
    );
  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (
        <span>
          Page{" "}
          {this.getCurrentPage(this.state.currentLink)}{" "}
          of {this.showLastPage()} pages
        </span>
      );
    }
    return <div />;
  }

  getCurrentPage(link) {
    const search = link.split('?')[1];
    let params = new URLSearchParams(`?${search}`);
    if(params.has('page[number]')) {
      return params.get('page[number]');
    } else {
      return 1;
    }
  }

  renderBuyAirtimeDataForm() {
    return (
      <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "" : "table-even-row"
          }
          size="middle"
          pagination={false}
          className="trans-body"
          bordered
          dataSource={this.state.recipients}
          columns={this.state.columns}
          onRow={(recipient) => {
            return {
              onClick: () => {
                Log.d(
                  TAG,
                  "onClickRow() -- recipient=" + JSON.stringify(recipient)
                );
                this.selectRecipient(recipient);
              },
            };
          }}
        />
      </Spin>
    );
  }

  onAddRecipient = () => {
    this.props.history.push({
      pathname: "/add-airtime-recipient",
      state: {
        recipient: this.props.recipientReducer.recipient,
        productOption: this.state.productOption,
        sendingCountry: this.state.sendingCountry,
        receivingCountries: this.state.receivingCountries,
      },
    });
  };

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value);
    this.requestRecipients(
      config.api_url + "beneficiaries/?filter[q]=" + value + "&page=1&include=country"
    );
  };

  renderRecipientActions = (record) => {
    return (
      <div>
        <Button
          onClick={() => {
            Log.d(TAG, "onClickRow() -- recipient=" + JSON.stringify(record));
            this.selectRecipient(record);
          }}
          style={{
            height: "30px",
            marginRight: "8px",
            color: "rgb(41, 108, 187)",
          }}
        >
          Select
        </Button>
        <Button
          onClick={() => {
          }}
          style={{
            height: "30px",
            marginRight: "8px",
            color: "rgb(41, 108, 187)",
          }}
        >
          Edit
        </Button>
        <Button
          style={{
            height: "30px",
            color: "rgb(41, 108, 187)",
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  renderRecipientCountry = (record) => {
    if (
      this.state.recipients.length > 0 &&
      "included" in record &&
      Object.keys(record.included).length > 0
    ) {
      return <span>{record.included.attributes.name}</span>;
    }
    return <span></span>;
  };

  selectRecipient = (recipient) => {
    // this.props.history.push('/edit-airtime-recipient');
    this.setState({
      recipient,
    });
    this.props.requestRecipientByID(recipient.id, this.state.access);
  };

  showLastPage() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <span>
          {this.getCurrentPage(this.props.recipientReducer.last)}
        </span>
      );
    } else {
      return <span></span>;
    }
  }

  showFirstButton() {
    if (this.props.recipientReducer.first !== null) {
      return (
        <Button
          className="pagination-box-item"
          onClick={() =>
            this.requestRecipients(this.props.recipientReducer.first)
          }
        >
          First
        </Button>
      );
    }
  }

  showLastButton() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <Button
          className="pagination-box-item"
          onClick={() =>
            this.requestRecipients(this.props.recipientReducer.last)
          }
        >
          Last
        </Button>
      );
    }
  }

  showPrevButton() {
    if (this.props.recipientReducer.prev !== null) {
      return (
        <Button
          className="pagination-box-item"
          onClick={() =>
            this.requestRecipients(this.props.recipientReducer.prev)
          }
        >
          Prev
        </Button>
      );
    } else {
      return (
        <Button
          className="pagination-box-item"
          onClick={() =>
            this.requestRecipients(this.props.recipientReducer.prev)
          }
          disabled
        >
          {" "}
          Prev
        </Button>
      );
    }
  }

  showNextButton() {
    if (this.props.recipientReducer.next !== null) {
      return (
        <Button
          className="pagination-box-item"
          onClick={() =>
            this.requestRecipients(this.props.recipientReducer.next)
          }
        >
          Next&gt;&gt;
        </Button>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestRecipients,
      fetchUserDetails,
      requestAirtimeDataReceivingCountries,
      deleteRecipient,
      showRecipient,
      showSendingCountry,
      requestProductDetails,
      requestRecipientByID,
      showSendingCountries
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyAirtime);
