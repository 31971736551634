import React from 'react';
import { Layout } from 'antd';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import UserResetPassword from '../../containers/ResetPassword.js'
import { withCookies } from 'react-cookie';
import '../../styles/index.css'
import '../../styles/user.css'
// import '../../styles/ant-theme-vars.less'
//import 'antd/dist/antd.css';
const { Header, Footer } = Layout;

class ResetPassword extends React.Component {

  render() {
    return (
      <Layout className="log-layout">
        <Header className="custom-header">
          <CustomHeader {...this.props}/>
        </Header>
        <UserResetPassword {...this.props}/>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  componentDidMount() {
    document.title = "Reset Password"
    window.scrollTo(0, 0)
  }
}

export default withCookies(ResetPassword)
