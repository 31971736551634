import {
  Card,
  Form,
  Layout,
  Dropdown,
  Icon,
  Button,
  Menu,
  Checkbox,
} from "antd";
import React from "react";
import "../styles/qoute.css";
import Log from "../utils/log";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "ORDER DETAILS";

class OrderDetails extends React.Component {
  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedOrderDetailsForm {...this.props} />
        </Content>
      </Layout>
    );
  }
}

class OrderDetailsForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    let productOption = "";
    let sendingCountry = {};
    let recipient = {};
    let receivingCountries = [];
    let receivingCountry = {};

    if (props.location != null) {
      if (props.location.state != null) {
        productOption = this.props.location.state.productOption;
        sendingCountry = this.props.location.state.sendingCountry;
        recipient = this.props.location.state.recipient;
        receivingCountries = this.props.location.state.receivingCountries;
        receivingCountry = this.props.location.state.receivingCountry;
      }
    }

    this.state = {
      productOption: productOption,
      airtimeDataOptions: [],
      recipient: recipient,
      receivingCountries: receivingCountries,
      sendingCountry: sendingCountry,
      receivingCountry: receivingCountry,
      sendingAmount: 0,
      selectedProductOption: null,
      purchaseOption: "data",
      sourceOfFunds: [
        ["SAL", "Salary"],
        ["SV", "Savings"],
        ["GF", "Gift"],
        ["PN", "Pension"],
      ],
      paymentOptions: [],
    };
  }

  componentDidMount() {
    this.props.form.setFieldsValue({
      sendingAmount: this.state.sendingAmount,
    });
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content style={{ width: "100%" }}>
        <Form
          // onSubmit={this.handleSubmit.bind(this)}
          hideRequiredMark={true}
        >
          <div className="row">
            <div className="col-sm-9">
              <Card
                style={{ fontFamily: "Lato", borderRadius: "4px" }}
                title={`Confirm order details`}
              >
                {this.renderOrderDetailsForm(getFieldDecorator)}
              </Card>
            </div>
          </div>
        </Form>
      </Content>
    );
  }

  renderOrderDetailsForm(getFieldDecorator) {
    return (
      <div className="home-item" style={{ alignItems: "flex-start" }}>
        <div>
          <p>
            You are about to send airtime/data worth {"sending amount"} for mobile
            number {"recipient"}
          </p>
          <p>Amount to Pay {"amount to pay"}</p>
        </div>
        <FormItem label="Select payment option" className="quote-form--1">
          {getFieldDecorator(
            "payment_method",
            {}
          )(
            <Dropdown
              overlay={this.renderPaymentOptionsParent()}
              trigger={["click"]}
            >
              <div className="dropdown-item">
                {this.renderPaymentOptionsDropdown()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator(
            "terms",
            {}
          )(<Checkbox>Accept Terms &amp; Conditions</Checkbox>)}
        </FormItem>
        {this.renderFormActions()}
      </div>
    );
  }

  onChangeOption = () => {};

  renderPaymentOptionsDropdown() {
    return <div>Select payment Option</div>;
  }

  renderPaymentOptionsParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeOption}
      >
        {this.renderPaymentOptions()}
      </Menu>
    );
  }

  renderPaymentOptions() {
    return this.state.paymentOptions.map((item, index) => {
      return <Menu.Item key={index}>{item}</Menu.Item>;
    });
  }

  renderFormActions = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 10,
        }}
      >
        <Button
          style={{
            height: "30px",
            color: "white",
            backgroundColor: "rgb(41, 108, 187)",
          }}
          // type="primary"
        >
          Accept terms and Proceed to pay
        </Button>
        <Button
          style={{
            height: "30px",
            marginRight: "8px",
          }}
          type="danger"
        >
          Cancel
        </Button>
      </div>
    );
  };
}

const WrappedOrderDetailsForm = Form.create()(OrderDetailsForm);

// TODO
// to connect to redux
export default OrderDetails;
