import React from 'react';
import { Layout, Button } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import SmartCashInPoints from '../containers/CashInPoints.js';
import '../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class CashInPoints extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
    }
  }

  componentDidMount() {
    document.title = "Cash In Points"
    window.scrollTo(0, 0)
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className="cash-points-body">
          <h2 style={{ fontFamily: 'Lato', color: 'black', fontSize: '48px', textAlign: 'center', marginTop: '80px'}}>Sasai Money Transfer Cash-In Points</h2>
          <SmartCashInPoints />
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
            <Button style={{ maxWidth: '100%', background: '#154989', marginTop: '20px', marginLeft: '8px', marginRight: '8px', border: '1px solid #154989'}} type="primary" onClick={() => {
              this.props.history.push({
                pathname: "/find-agents/cash-out-Zimbabwe",
              });
            }}>
              Cash-Out Points
            </Button>
          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(CashInPoints)
