import React from 'react';
import Log from '../utils/log.js';
import {
  requestRecipientByID,
  fetchUserDetails,
  requestReceivingCountries,
  requestReceivingCountry,
  requestManageBWBeneficiary,
  requestBWBeneficiaries,
  deleteBWBeneficiary
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { 
  Layout, 
  Button, 
  Input, 
  Form, 
  DatePicker, 
  Tooltip, 
  Icon, 
  message, 
  Checkbox, 
  Menu, 
  Dropdown} from 'antd';
import '../styles/dashboard.css'
import moment from 'moment'
import Helper from '../utils/helper.js';

const { Content } = Layout;
const TAG = "SmartBreadwinner";
const FormItem = Form.Item;

class BreadWinner extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "BreadWinner::constructor() -- props=" + JSON.stringify(props));

    var recipientId = null;

    if (props.location != null) {
      if (props.location.state != null) {
        recipientId = props.location.state.recipientId
      }
    }

    this.state = {
      recipientId : recipientId,
      loading: false
    }
  }

  callback = (key) =>{}

  componentWillMount() {
    Log.d(TAG, "componentWillMount() -- called ..");
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.accessToken !== '') {
      Log.d(TAG, "componentWillMount() -- request a user");
      // Log.d(TAG, "componentWillMount() -- access=" + this.props.cookies.cookies.access);
      Log.d(TAG, "componentWillMount() -- access=" + this.props.authReducer.accessToken);
      // Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.cookies.cookies.access));
      Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.authReducer.accessToken));
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, true, null);
      this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, true, null);
    }
  }

  requestReceivingCountries(sendingCountry) {
    this.props.requestReceivingCountries(sendingCountry);
  }

  getPolicyStatus(policyStatus) {
    if (policyStatus === "N") {
            return "Not Ready";
    }
    if (policyStatus === "R") {
        return "Ready";
    }
    if (policyStatus === "P") {
        return "Pending";
    }
    if (policyStatus === "A") {
        return "Active";
    }
    if (policyStatus === "D") {
        return "Declined";
    }
    return  policyStatus;
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.userReducer.user).length > 0 && (prevProps.userReducer.user !== this.props.userReducer.user)) {
      //var bwStatus = this.props.userReducer.user.included[0].attributes.policy_status;
      for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
        if (this.props.userReducer.user.included[i].type === "countries") {
          this.requestReceivingCountries(this.props.userReducer.user.included[i].attributes.alpha_2_code);
        }
      }
    }
  }

  render() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
        return (
          <div className="userprofile-content-container">
            <Content className="breadwinner-content-container">
              <span style={{ textAlign: 'center', fontFamily: 'Lato-Bold' }}>BreadWinner Policy</span>
              <span style={{ textAlign: 'center' }}>BreadWinners need to specify their beneficiary</span>
              <WrappedBreadwinnerForm {...this.props} />
            </Content>
          </div>
        )
    }
    return (
      <div className="userprofile-content-container"/>
    )
  }
}

class BreadwinnerForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "BreadwinnerForm::constructor() -- props1=" + JSON.stringify(props));

    var bwBeneficiary = {};
    var recipient = {};

    if (props.location != null) {
      if (props.location.state != null) {
        bwBeneficiary = props.location.state.bwBeneficiary
        recipient = props.location.state.recipient
      }
    }

    this.state = {
      confirmDirty: false,
      maxBirthDate : moment().subtract(18, 'years'),
      receivingCountry: {},
      receivingCountries: [],
      verificationTypes: [
        // { "id": "SI", "value": "South African ID" },
        // { "id": "FP", "value": "Foreign Passport" },
        // { "id": "AP", "value": "Asylum Permit" },
        { "id": "ZC", "value": "Zimbabwe ID" }
      ],
      chosenVerificationType: 0,
      bwBeneficiary : bwBeneficiary,
      recipient : recipient
    }
  }

  componentDidUpdate(prevProps) {

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.bwPolicyReducer.error !== null && prevProps.bwPolicyReducer.error !== this.props.bwPolicyReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.bwPolicyReducer.error)
      message.error("" + this.props.bwPolicyReducer.error, 5)
    }

    // if (prevProps.bwPolicyReducer.bwBeneficiaries !== this.props.bwPolicyReducer.bwBeneficiaries) {
    //   this.manageBreadWinnerBeneficiary();
    // }

    if (prevProps.bwPolicyReducer.index !== this.props.bwPolicyReducer.index) {
      Log.d(TAG, "componentDidUpdate() -- bwPolicyReducers index=" + this.props.bwPolicyReducer.index);
      Log.d(TAG, "componentDidUpdate() -- bwPolicyReducers total=" + this.props.bwPolicyReducer.total);
      if (this.props.bwPolicyReducer.index < this.props.bwPolicyReducer.total) {
        // this.props.deleteBWBeneficiary(this.props.userReducer.user.data.id, this.props.bwPolicyReducer.bwBeneficiaries[this.props.bwPolicyReducer.index].id,  this.props.bwPolicyReducer.index, this.props.bwPolicyReducer.total, this.props.cookies.cookies.access)
        this.props.deleteBWBeneficiary(this.props.userReducer.user.data.id, this.props.bwPolicyReducer.bwBeneficiaries[this.props.bwPolicyReducer.index].id,  this.props.bwPolicyReducer.index, this.props.bwPolicyReducer.total, this.props.authReducer.accessToken)
      } else {
        message.success("Breadwinner policy beneficiary has updated.", 5)
        /*this.props.history.push({
          pathname: "/recipient-list"
        })*/
        this.props.history.push({
          pathname: "/breadwinner-policy",
        });
      }
    }

    if (Object.keys(this.props.bwPolicyReducer.bwBeneficiary).length > 0 && (prevProps.bwPolicyReducer.bwBeneficiary !== this.props.bwPolicyReducer.bwBeneficiary)) {
      if (this.props.bwPolicyReducer.bwBeneficiaries.length > 0 && Object.keys(this.state.bwBeneficiary).length <= 0) {
        // this.props.deleteBWBeneficiary(this.props.userReducer.user.data.id, this.props.bwPolicyReducer.bwBeneficiaries[0].id, 0, this.props.bwPolicyReducer.bwBeneficiaries.length, this.props.cookies.cookies.access)
        this.props.deleteBWBeneficiary(this.props.userReducer.user.data.id, this.props.bwPolicyReducer.bwBeneficiaries[0].id, 0, this.props.bwPolicyReducer.bwBeneficiaries.length, this.props.authReducer.accessToken)
      } else {
        message.success("Breadwinner policy beneficiary has updated.", 5)
        this.props.history.push({
          pathname: "/breadwinner-policy",
        });
      }
    }

    if (this.props.countryReducer.receivingCountries.length > 0 && (prevProps.countryReducer.receivingCountries !== this.props.countryReducer.receivingCountries)) {
      this.setState({
        receivingCountries: this.props.countryReducer.receivingCountries,
      });
      // WARNING: Hard coded receiving country id to select the last item (Zimbabwe)
      this.requestCountryDetails(this.props.countryReducer.receivingCountries[this.props.countryReducer.receivingCountries.length - 1].id)
    }

    if (Object.keys(this.props.recipientReducer.recipient).length > 0 && (prevProps.recipientReducer.recipient !== this.props.recipientReducer.recipient)) {
      this.props.form.setFieldsValue({
        first_name: this.props.recipientReducer.recipient.data.attributes.first_name,
        middle_name: this.props.recipientReducer.recipient.data.attributes.middle_name,
        surname: this.props.recipientReducer.recipient.data.attributes.last_name,
        id_document_number: this.props.recipientReducer.recipient.data.attributes.id_document_number,
        birth_date: this.props.recipientReducer.recipient.data.attributes.birth_date ? moment(this.props.recipientReducer.recipient.data.attributes.birth_date, 'YYYY/MM/DD') : "",
        address: this.props.recipientReducer.recipient.data.attributes.address_line_1,
        city: this.props.recipientReducer.recipient.data.attributes.city,
        postcode: this.props.recipientReducer.recipient.data.attributes.postcode,
        phone: (this.props.recipientReducer.recipient.data.attributes.mobile) ? this.props.recipientReducer.recipient.data.attributes.mobile[1] : "",
        email: this.props.recipientReducer.recipient.data.attributes.email
      })
      var chosenIDType = this.state.chosenVerificationType;
      for (var idIndex = 0; idIndex < this.state.verificationTypes.length; idIndex++) {
        if (this.state.verificationTypes[idIndex].id === this.props.recipientReducer.recipient.data.attributes.id_verification_type) {
          chosenIDType = idIndex;
        }
      }
      this.props.form.setFieldsValue({
        id_verification_document : chosenIDType
      });
      this.setState({
        chosenVerificationType: chosenIDType
      });
    }
    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0 && (prevProps.countryReducer.receivingCountry !== this.props.countryReducer.receivingCountry)) {
      Log.d(TAG, "componentDidUpdate() -- country details=" + JSON.stringify(this.props.countryReducer.receivingCountry));
      this.setState({
        receivingCountry : this.props.countryReducer.receivingCountry.data
      });

      Log.d(TAG, "componentDidUpdate() -- bwBeneficiary=" + JSON.stringify(this.state.bwBeneficiary));
      if (Object.keys(this.state.bwBeneficiary).length > 0) {
        this.setDetails(this.state.bwBeneficiary)
      } else if (Object.keys(this.state.recipient).length > 0) {
        this.setDetails(this.state.recipient)
      }
    }
  }

  setDetails(recipient) {
    this.props.form.setFieldsValue({
      first_name: recipient.attributes.first_name,
      middle_name: recipient.attributes.middle_name,
      surname: recipient.attributes.last_name,
      id_document_number: recipient.attributes.id_document_number,
      birth_date: recipient.attributes.birth_date ? moment(recipient.attributes.birth_date, 'YYYY/MM/DD') : "",
      address: recipient.attributes.address_line_1,
      city: recipient.attributes.city,
      postcode: recipient.attributes.postcode,
      phone: (recipient.attributes.mobile) ? recipient.attributes.mobile[1] : "",
      email: recipient.attributes.email
    })
    var chosenBWIDType = this.state.chosenVerificationType;
    for (var idBWIndex = 0; idBWIndex < this.state.verificationTypes.length; idBWIndex++) {
      if (this.state.verificationTypes[idBWIndex].id === recipient.attributes.id_verification_type) {
        chosenBWIDType = idBWIndex;
      }
    }
    this.props.form.setFieldsValue({
      id_verification_document : chosenBWIDType
    });
    this.setState({
      chosenVerificationType: chosenBWIDType
    });
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // disable SA ID validation 
          if(false) {
        // if (values.id_document_number && Number(this.state.chosenVerificationType) === 0) {
          if (this.checkIDNumber(values.id_document_number)) {
            if (this.checkBdayAndIDNumber(values)) {
              // this.props.requestBWBeneficiaries(this.props.userReducer.user.data.id, this.props.cookies.cookies.access);
              this.props.requestBWBeneficiaries(this.props.userReducer.user.data.id, this.props.authReducer.accessToken);
            } else {
              Helper.setError(this.props.form, "id_document_number", 'Birthdate did not match with SA ID number value combination');
            }
          } else {
            Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
          }
        } else {
          // this.props.requestBWBeneficiaries(this.props.userReducer.user.data.id, this.props.cookies.cookies.access);
          this.props.requestBWBeneficiaries(this.props.userReducer.user.data.id, this.props.authReducer.accessToken);
        }
      }
    });
  }

  manageBreadWinnerBeneficiary() {

    if (!this.state.loading) {
      // this.setState({
      //   loading : true
      // })
      var countryObj = {}
      var countryDataObj = {}
      countryDataObj.type = "countries";
      countryDataObj.id = this.state.receivingCountry.id;
      countryObj.data = countryDataObj;

      var relationshipObj = {}
      relationshipObj.country = countryObj;

      var attributesObj = {}
      attributesObj.email = this.props.form.getFieldValue('email');
      attributesObj.first_name = this.props.form.getFieldValue('first_name');
      attributesObj.middle_name = this.props.form.getFieldValue('middle_name') ? this.props.form.getFieldValue('middle_name') : "";
      attributesObj.last_name = this.props.form.getFieldValue('surname');
      attributesObj.birth_date = this.props.form.getFieldValue('birth_date').format("YYYY-MM-DD");
      attributesObj.id_document_number = this.props.form.getFieldValue('id_document_number');
      attributesObj.id_verification_type = this.state.verificationTypes[this.state.chosenVerificationType].id;
      attributesObj.postcode = this.props.form.getFieldValue('postcode');
      attributesObj.address_line_1 = this.props.form.getFieldValue('address');
      attributesObj.city = this.props.form.getFieldValue('city');
      attributesObj.mobile = [this.state.receivingCountry.attributes.phone_prefix, this.props.form.getFieldValue('phone').replace(/\s/g, '')];

      var dataObj = {}
      dataObj.type = "breadwinner_beneficiaries";
      var bwId = null;
      if (Object.keys(this.state.bwBeneficiary).length > 0) {
        bwId = this.state.bwBeneficiary.id;
        dataObj.id = bwId;
      } else {
        dataObj.id = null;
      }
      dataObj.attributes = attributesObj;
      dataObj.relationships = relationshipObj;

      var payloadObj = {};
      payloadObj.data = dataObj;

      Log.d(TAG, "manageBreadWinnerBeneficiary() -- payloadObj=" + JSON.stringify(payloadObj));
      this.props.requestManageBWBeneficiary(payloadObj, this.props.userReducer.user.data.id, bwId, this.props.authReducer.accessToken)
    }
  }

  checkIDNumber(idNumber) {
    return Helper.isValidSAID(idNumber);
  }

  renderVerificationTypeParent(){
    return (
      <Menu onClick={this.onChangeVerificationType}>
        {this.renderVerificationTypes()}
      </Menu>
    )
  }

  renderVerificationTypes(){
    return this.state.verificationTypes.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
        );
    })
  }

  validateID = (rule, value, callback) => {
    Log.d(TAG, "validateID() -- called ..")
    if (value && Number(this.state.chosenVerificationType) === 0) {
      if (this.checkIDNumber(value)) {
        callback();
      } else {
        callback('This field has invalid SA ID number.');
      }
    } else {
      callback();
    }
  }

  checkBdayAndIDNumber(values) {
    var birthYear = values.birth_date.format("YYYY-MM-DD").substring(2, 4);
    var birthMonth = values.birth_date.format("YYYY-MM-DD").substring(5, 7);
    var birthDay = values.birth_date.format("YYYY-MM-DD").substring(8, 10);

    var idYear = values.id_document_number.substring(0, 2);
    var idMonth = values.id_document_number.substring(2, 4);
    var idDay = values.id_document_number.substring(4, 6);

    Log.d(TAG, "checkBdayAndIDNumber() -- birthYear=" + birthYear + " birthMonth=" + birthMonth + " birthDay=" + birthDay);
    Log.d(TAG, "checkBdayAndIDNumber() -- idYear=" + idYear + " idMonth=" + idMonth + " idDay=" + idDay);

    if (birthYear !== idYear || birthMonth !== idMonth || birthDay !== idDay) {
      return false;
    }

    return true;
  }

  onChangeVerificationType = (event) => {
    Log.d(TAG, "onChangeVerificationType() -- event.key=" + event.key);
    this.setState({
      chosenVerificationType : event.key
    })
    this.props.form.setFieldsValue({
      id_verification_document: event.key
    })

    if (this.props.form.getFieldValue('id_document_number') && Number(event.key) === 0) {
      if (this.checkIDNumber(this.props.form.getFieldValue('id_document_number'))) {
        this.setNoErrorID();
      } else {
        Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
      }
    } else {
      this.setNoErrorID();
    }
  }

  setNoErrorID() {
    this.props.form.setFields({
      'id_document_number': {
        value: this.props.form.getFieldValue('id_document_number'),
        errors: null,
      }
    });
  }

  renderVerificationType() {
    return (
      <div>
        {this.state.verificationTypes[this.state.chosenVerificationType].value}
      </div>
    )
  }

  renderReceivingCountry() {
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return (
        <div>
          {this.state.receivingCountry.attributes.name}
        </div>
        )
    } else {
      return (
        <div/>
      )
    }
  }

  renderCountries(){
    return this.state.receivingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  renderCountriesParent(){
    return (
        <Menu onClick={this.onChangeCountry}>
          {this.renderCountries()}
        </Menu>
      )
  }

  onChangeCountry = (event) => {
    this.props.form.setFieldsValue({
      receivingCountry: {},
    })
    this.requestCountryDetails(this.state.receivingCountries[event.key].id)
  }

  requestCountryDetails = (countryID) => {
    this.props.requestReceivingCountry(countryID);
  }

  renderReceivingCountryDropDown(getFieldDecorator) {
    return (
      <FormItem label="Country">
        {getFieldDecorator('receivingCountry', {
            rules: [{ required: true, message: 'Please provide country' }],
            initialValue : this.state.receivingCountry
          })(
            <Dropdown
              trigger={['click']}
              overlay={this.renderCountriesParent()}>
              <div className="dropdown-item">
                {this.renderReceivingCountry()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
      </FormItem>
      )
  }

  renderPhonePrefix() {
    Log.d(TAG, "renderPhonePrefix() -- receivingCountries=" + JSON.stringify(this.props.countryReducer.receivingCountries));
    Log.d(TAG, "renderPhonePrefix() -- receivingCountry=" + JSON.stringify(this.state.receivingCountry));
    if (this.props.countryReducer.receivingCountries) {
      if (this.props.countryReducer.receivingCountries.length > 0 && Object.keys(this.state.receivingCountry).length > 0){
        return (
          <div style={{ width: 45 }}>
            +{this.state.receivingCountry.attributes.phone_prefix}
          </div>
        )
      } else {
        return (
          <div/>
        )
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    var phonePrefix = getFieldDecorator('phonePrefix', {
      initialValue: "",
    })(
      this.renderPhonePrefix()
    );

    return (
      <Form className="user-body"
        onSubmit={this.handleSubmit.bind(this)}>
        {this.renderReceivingCountryDropDown(getFieldDecorator)}
        <FormItem label={(
          <span>
            First Name
            <Tooltip title="As they appear on the Zim ID">
              <Icon type="question-circle-o" />
            </Tooltip>
          </span>
        )}>
          {getFieldDecorator('first_name', {
              rules: [{ required: true, message: 'Please provide first name' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label={(
          <span>
            Middle Name
            <Tooltip title="As they appear on the Zim ID">
              <Icon type="question-circle-o" />
            </Tooltip>
          </span>
        )}>
          {getFieldDecorator('middle_name', {
              // rules: [{ required: true, message: 'Please provide middle name' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label={(
          <span>
            Surname
            <Tooltip title="As they appear on the Zim ID">
              <Icon type="question-circle-o" />
            </Tooltip>
          </span>
        )}>
          {getFieldDecorator('surname', {
              rules: [{ required: true, message: 'Please provide surname' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label="ID Type">
           {getFieldDecorator('id_verification_document', { initialValue: this.state.chosenVerificationType })(
              <Dropdown
                className="su-tf-2 form-control"
                trigger={['click']}
                overlay={this.renderVerificationTypeParent()}>
                <div className="dropdown-item">
                  <div>
                    {this.renderVerificationType()}
                  </div>
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
        <FormItem label="ID Number">
          {getFieldDecorator('id_document_number', {
            rules: [
              { required: true, message: 'Please provide ID Number' },
              { pattern: /^\d{2}-?–?\d{6,7}[a-zA-Z]\d{2}$/g, message: 'Please provide a valid ID Number, with the following pattern 00-0000000X00 or 000000000X00' },
            ]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem
          label={(
            <span>
              Birth Date
              <Tooltip title="Beneficiary must be 18 and not greater than 65 years old, pattern YYYY/MM/DD">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          )}>
          {getFieldDecorator('birth_date', {
              rules: [{ required: true, message: 'Please provide birth date' }]
            })(
              <DatePicker
                allowClear={false}
                format={'YYYY/MM/DD'}
                className="su-tf-2"
                disabledDate={this.disabledDate} />
            )}
        </FormItem>
        <FormItem label="Address">
          {getFieldDecorator('address', {
              rules: [{ required: true, message: 'Please provide address' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label="City">
          {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Please provide city' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label="Postcode">
          {getFieldDecorator('postcode', {
              rules: [{ required: true, message: 'Please provide postcode' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem label="Mobile Number">
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please provide your phone number' }, { max: 10, message: "Mobile number should have at most 10 digits." }, { min: 9, message: "Mobile number should have at least 9 digits." }, { pattern: /^[0-9]*$/, message: "Mobile number should contain valid numbers."}]
          })(
            <Input className="mobile-number"
              placeholder="Phone number*" addonBefore={phonePrefix} onChange={this.onMobileChanged} maxLength="10" />
          )}
        </FormItem>
        <FormItem label="Email Address">
          {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please provide e-mail address' }, { type: 'email', message: 'Please provide a valid e-mail address' }]
            })(
            <Input className = "form-control"/>
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('bw_terms', {
              rules: [{ required: true, message: 'Please tick this checkbox' }],
            })(
            <Checkbox>BreadWinner Policy Beneficiary</Checkbox>
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('agree_terms', {
              rules: [{ required: true, message: 'Please agree on terms' }],
            })(
            <Checkbox>Accept Terms and Condition</Checkbox>
          )}
        </FormItem>
        <FormItem className="user-custom-btn-row">
          <Button className="user-custom-btn"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              width: '100%'
            }} type="primary" htmlType="submit">Update Beneficiary</Button>
        </FormItem>
      </Form>
    )
  }

  validatePhone = (rule, value, callback) => {
    Log.d(TAG, "validatePhone() -- called ..")
    // if (value) {
    //   if (value.length >= 9) {
    //     if (isNaN(value)) {
    //       callback("Mobile number should contain valid numbers");
    //     } else {
    //       callback();
    //     }
    //   } else {
    //     callback("Mobile number should have at least 9 digits");
    //   }
    // }

    if(value){try {
      if (value.length >= 9) {
        if (isNaN(value)) {
          throw new Error("Mobile number should contain valid numbers.");
        }
      } else {
        throw new Error("Mobile number should have at least 9 digits.");
      }
    } catch (err) {
      callback(err)
    }}
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(65, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestRecipientByID,
    fetchUserDetails,
    requestReceivingCountries,
    requestReceivingCountry,
    requestManageBWBeneficiary,
    requestBWBeneficiaries,
    deleteBWBeneficiary
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

const WrappedBreadwinnerForm = Form.create()(BreadwinnerForm);
export default connect(mapStateToProps, mapDispatchToProps)(BreadWinner)
