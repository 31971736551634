import React from "react";
import "../../styles/dashboard.css";

const TermsZa = () => {
  return (
    <>
      <b className="terms-item-parent">1. Terms and Conditions</b>
      <span className="terms-item-child">1.1. This document sets out the terms and conditions of Sasai Fintech (Pty) Ltd (“Sasai”) [“Terms”] pertaining to the access to and use of this website: <a href="https://moneytransfer.sasai.global/">moneytransfer.sasai.global</a> (“the/this Website”), the Sasai Money Transfer App (“the App”), as well as the Sasai Money Transfer via USSD, money transfer service, as described below (“the Service”).</span>
      <span className="terms-item-child">1.2. Please read these Terms carefully as the use of this Website, the App and the Service is subject always to these Terms. Sasai reserves the right to amend and/or replace any of, or the whole of, these Terms by posting new Terms to this Website (which are also accessible via the App). Such amendments shall supersede and replace any previous Terms available on this Website (which are also accessible via the App).</span>
      <span className="terms-item-child">1.3. Each time you (“you”/“the user”/“the customer”) access or use this Website, the App or the Service, (whether via the Website, App or USSD), you shall be deemed by such access and/or use to have agreed to these Terms, as amended and/or replaced by Sasai, from time to time.</span>
      <span className="terms-item-child">1.4. By downloading and/or using the App, you in addition agree that you are granted a limited, non-exclusive, revocable, non-transferrable licence to use the App, only in accordance with these Terms.</span>
      <span className="terms-item-child">1.5. Should you not accept these Terms or if you disagree with any of these Terms, you must immediately refrain from accessing and from using the Website, the App and the Service.</span>

      <b className="terms-item-parent">2. Important Notice</b>
      <b className="terms-item-child-bold">2.1. These Terms contain specific provisions that have the effect of limiting the liability of Sasai, allocate risk or liability to you, or constitute acknowledgement of facts and certain undertakings by you. Please pay particular attention to these provisions since they limit your ability to recover losses that you may incur in connection with your use of this Website, the App and the Service.</b>
      <b className="terms-item-child-bold">2.2 Unfortunately, if you are under 18 (and are not an emancipated minor) you may not use this Website, the App or the Service.</b>

      <b className="terms-item-parent">3. Description of the Service</b>
      <span className="terms-item-child">The Service is an international money transfer service, which provides registered users in South Africa with the ability to initiate money transfer transactions to designated beneficiaries in Zimbabwe and such other territories, outside the Republic of South Africa, as may be indicated on the Website and the App, from time to time.</span>

      <u className="terms-item-parent">4. South African Reserve Bank Requirements</u>
      <u className="terms-item-child">Declaration:</u>
      <span className="terms-item-child">By confirming a money transfer transaction (“money transfer transaction/transaction”), you, the customer, declare that:</span>
      <ul>
        <li>You have read this document, know and understand the contents thereof and are bound by same;</li>
        <li>the information you have furnished to Sasai is in all respects both true and correct and is up to date in all respects;</li>
        <li>the currency applied for will only be used for the specific purpose stated;</li>
        <li>the documentation presented in support of your application is in all respects authentic;</li>
        <li>You have been informed of the limit applicable to the confirmed transaction and confirm that this limit will not be exceeded as a result of the conclusion of the relevant transaction; and</li>
        <li>You consent to the information that you have provided to Sasai being provided to the South African Revenue Service (“SARS”) and/or the Financial Intelligence Centre (“FIC”) and/or the Financial Sector Conduct Authority (“FSCA”) (or their successors).</li>
      </ul>
      <u className="terms-item-child">Discretionary Limits:</u>
      <span className="terms-item-child">In terms of the prevailing exchange control laws, if you are a permanent resident of, domiciled or registered in South Africa (“a South African resident”), it is up to you to ensure that you do not exceed your single discretionary allowance. The single discretionary allowance is an allowance within an overall limit of R1 million per calendar year which a South African resident over the age of 18 years is availed of.</span>
      <span className="terms-item-child">By agreeing to the Terms, you assume responsibility for not exceeding your single discretionary allowance while using the Service, or any other financial institution, to transfer funds out of the Republic of South Africa.</span>

      <u className="terms-item-parent">SARB (South African Reserve Bank) Balance of Payment (BOP) Reporting:</u>
      <span className="terms-item-child">For every successfully completed transaction and corresponding pay-out to a designated beneficiary (“Beneficiary”), Sasai issues the SARB with a report. This report includes your personal details, the Beneficiary’s personal details, as provided by you and details of the completed transaction (including payment, and pay-out). Sasai takes your personal details from the documents you submit to us, as required by the Financial Intelligence Centre Act (“FICA documents”).</span>

      <span className="terms-item-child">By submitting the FICA documents and using the Service, you agree to let Sasai take your personal details from the FICA documents and to use them for Sasai’s SARB reporting, as prescribed by law.</span>

      <span className="terms-item-child">Sasai will not be responsible for any legal proceedings of any kind brought against you by any private, government or financial institution or body of any kind, due to the information that Sasai has passed on to these institutions or bodies.</span>

      <span className="terms-item-child">If we have reason to believe that the information or documents you have provided us with are false and tampered with in any way, we will immediately close your account and notify any relevant authority.</span>

      <span className="terms-item-child">All customers with a South African ID must select the BOP category relevant to the money remittance (as per below):</span>

      <span className="terms-item-child">401 – Gifts; or</span>

      <span className="terms-item-child">For customers with a Foreign Passport, Asylum papers, Emergency Travel Document, the BOP category relevant for the money remittance is:</span>

      <span className="terms-item-child">416 – Migrant Remittances</span>

      <span className="terms-item-child">You the customer agree that you will only transact for the reasons set out above and will not transact for any other reason which includes, but not limited to, business activities, illegal and/or unlawful activities and/or terrorist financing.</span>

      <span className="terms-item-child">For further information, please visit <a href="https://www.resbank.co.za/">www.resbank.co.za</a></span>

      <u className="terms-item-child">Legal Requirements:</u>

      <span className="terms-item-child">Subject at all times to the applicable law, Sasai may disclose your personal data if required to do so by law (including, without limitation responding to a subpoena or request from law enforcement, court or government agency) or in the good faith belief that such action is necessary (i) to comply with a legal obligation, (ii) to protect and defend the rights or property of Sasai, (iii) to act in urgent circumstances to protect the personal safety of users of the Website, the App or the Service or the public, or (iv) to protect against legal liability.</span>

      <b className="terms-item-parent">5. FICA Documentation</b>

      <span className="terms-item-child">Sasai collects personal data from you, which you voluntarily provide during the registration process for the Service. The collection and storage of various documents relating to you are a legal requirement imposed on Sasai by the Financial Intelligence Centre Act (“FICA”). By providing these documents and agreeing to our terms you are agreeing that the FIC may at any time request these documents from Sasai regarding a FICA audit or on any other legal grounds. Sasai is required to and will, provide the FIC with these documents upon request. Sasai shall at all times ensure that in the collection, processing, storage and otherwise dealing with your personal information, it complies with all applicable data protection legislation in force from time to time.</span>

      <b className="terms-item-parent">6. Service Restriction</b>
      <span className="terms-item-child">The Service is available to natural persons legally resident in South Africa only and may not be used by legal entities or natural persons residing outside of the Republic of South Africa.</span>

      <b className="terms-item-parent">7. Registration, Account & Password</b>

      <span className="terms-item-child">7.1. You may register for the Service via the Website or the App (registration via the App may also be done at an approved Sasai registration agent [“Registration Agent”]).</span>
      <span className="terms-item-child">7.2. Registration for the Service is free of charge.</span>
      <span className="terms-item-child">7.3. Sasai only allows persons to register herself/himself in her/his personal capacity. A customer may not register or act on behalf or on the authority of another customer.</span>
      <span className="terms-item-child">7.4. You will be required to choose a password when registering on the Website or the App to use the Service. Thereafter you will be required to enter your password every time you wish to use the Service. Upon registration you will also be allocated with a unique customer account number (“Account Number”).</span>
      <span className="terms-item-child">7.5. You agree not to share your Account Number or password with any other person, nor to use any other person’s Account Number or password;</span>
      <span className="terms-item-child">7.6. You agree that Sasai will not be responsible for losses incurred by you relating to, without limitation, the use of your account by any person other than you, arising as the result of misuse of passwords, and furthermore you accept that you will be personally liable for all transactions concluded on your account;</span>
      <span className="terms-item-child">7.7. You agree to immediately notify Sasai of any unauthorised use of your Account Number, password, this Website, the App, or any other breach of security. (Please send your notice to us via the Help section of the Website or contact us, using the email or telephone contact details listed under the Help section of the Website and the App).</span>
      <span className="terms-item-child">7.8. Sasai reserves the right at any time in our sole discretion to modify or discontinue the Website, the App or the Service and to modify or discontinue any materials, information or other content contained on the Website or the App, or to terminate your account, Account Number and/or password, your access to or use of the Website, the App or the Service, for any reason or without a reason, in its discretion.</span>

      <b className="terms-item-parent">8. Fees for use of Service & Payment</b>

      <span className="terms-item-child">8.1. Sasai charges a transfer fee, per money transfer transaction (“Fee”). The Fee payable is indicated on the quote provided at the time that a quote is generated at the instance of the customer (via the Website, App or USSD [“Quote”]).</span>
      <span className="terms-item-child">8.2. The Fee will be deducted from the incoming funds paid by you for the money transfer transaction. By confirming the money transfer transaction, you are confirming that Sasai will charge its Fee for the money transfer service as shown and accepted by you prior to finalisation of the money transfer transaction. The Fee is inclusive of VAT.</span>
      <span className="terms-item-child">8.3. The total amount payable including the Fee and the amount to be transferred to the customer’s chosen Beneficiary will be indicated on the Quote (“Total Amount”).</span>
      <span className="terms-item-child">8.4. By creating a money transfer transaction, you request a certain amount of money to be paid to your designated Beneficiary, utilising a certain exchange rate. The exchange rate used by Sasai is clearly shown on the Quote, when a money transfer transaction is created and is valid for a period of 12 (twelve) hours. By creating an order on the Website, through the App or USSD, you agree to the exchange rate provided by Sasai at that point.</span>
      <span className="terms-item-child">8.5. Sasai reserves the right to change the Fee or exchange rate at any time.</span>
      <span className="terms-item-child">8.6. Please note that Sasai will not be obliged to pay out the designated amount to the Beneficiary prior to receiving full payment of the Total Amount payable in respect of the money transfer transaction created.</span>
      <span className="terms-item-child">8.7. Should we not receive payment of the Total Amount, for any reason whatsoever, the Quote will expire within 24 (twenty-four) hours. Should the Quote expire, we cannot guarantee the same exchange rate as initially quoted. You will have to create a new money transfer transaction and a new exchange rate may apply.</span>
      <span className="terms-item-child">8.8. All money transfer transaction payments must be made in South African Rand (ZAR). We do not accept any other currencies. In addition, money transfer transaction payments must be settled by card, Electronic Fund Transfer (EFT) or by cash deposit at a Sasai approved cash-in partner. When paying via EFT, a customer will be redirected to their online banking profile. Sasai will never ask you for your bank account details. These are strictly confidential. Sasai will require your debit/credit card details, should payment be made by card.</span>
      <span className="terms-item-child">8.9. The customer must use the allocated transaction reference number as their reference number when performing a transaction. A customer’s name, ID number or any other information cannot be accepted as the reference number and will cause delays in Sasai processing your money transfer transaction.</span>
      <span className="terms-item-child">8.10. Sasai will not be held responsible for delays or non-fulfilment of a money transfer transaction if a customer:</span>
      <ul className="cross">
        <li>uses a wrong transaction reference number; or</li>
        <li>transfers an amount of funds different to the money transfer transaction created.</li>
      </ul>

      <b className="terms-item-parent">9. Pay-out</b>

      <span className="terms-item-child">9.1. Sasai will pay out the funds to the designated Beneficiary only once payment of the Total Amount has been received in full.</span>

      <span className="terms-item-child">9.2. Sasai will endeavour to make payment to the designated Beneficiary within 72 (seventy-two) hours of having received payment of the Total Amount for a valid money transfer transaction (provided that your FICA documentation provided is in order at the time of the envisaged transaction).</span>

      <span className="terms-item-child">9.3. In the event that a pay-out has not occurred within the 72 (seventy two) hour period, due to a technical problem on Sasai’s side or that of its pay-out partner or any other reason that is not due to the negligence or fault of the customer, such as lack of available cash at the pay-out partner, the customer may cancel the money transfer transaction and request a refund, free of charge. In all such instances, the refund to the customer as aforesaid shall operate as the customer’s sole recourse against Sasai and Sasai shall have no other liability to the customer in this regard. The refund process is detailed in clause 10 below.</span>

      <b className="terms-item-parent">10. Cancellation of Money Transfer Transactions & Refunds</b>

      <span className="terms-item-child">10.1. A money transfer transaction may only be cancelled / reversed by a customer within 14 days of completion of money transfer AND if pay-out to the Beneficiary has not yet occurred. Once a pay-out has taken place, the process is irreversible.</span>

      <span className="terms-item-child">10.2. In order to request a refund, the customer must contact the Sasai call centre (the details of which are listed on the Help section of the Website and the App). The Sasai call centre will advise you on the further steps to be taken in order to process the refund, which shall depend on the original payment method used to perform the money transfer transaction.</span>

      <span className="terms-item-child">10.3. A refund may only be initiated by and subject to clause 10.4 paid to the customer that originally effected the money transfer transaction. The cancellation / reversal cost will be carried by the party whose fault it is that the money transfer transaction could not be completed. Accordingly, if cancellation / reversal arises pursuant to a technical problem on Sasai’s side or that of its pay-out partner or any other reason that is not due to the negligence or fault of the customer, the refund to the customer shall be free of charge. In all other instances, the customer will be charged a refund fee, which shall be comprised as follows: (i) 3% (three percent) of the Total Amount of the original money transfer transaction PLUS (ii) a fixed amount of R10 PLUS (iii) any cancellation / reversal fees that the pay-out partner may charge, which shall be deducted from the value of the Total Amount of the original money transfer transaction.</span>
      <span className="terms-item-child">10.4. In respect of card money transfer transactions, the relevant refund will be made onto the card which was used to effect the money transfer transaction. In respect of all non-card money transfer transactions, refunds will be made using a cardless ATM solution. In this case, the customer will be notified via SMS of the code to be used at the ATM in order to access the refund. In the event that a customer wishes payment of the refund to be effected to a different cell phone number than the cell phone number used to register for the Service, Sasai may in its discretion and should it be of the view that the circumstances justify it, agree to do so, subject to the signature by the customer of Sasai’s  applicable Authorisation Form, including terms and conditions which shall apply thereto.</span>
      <span className="terms-item-child">10.5. In respect of money transfer transactions made via EFT, the customer may requests that the refund be made using the cardless ATM solution or alternatively that same be paid into the customer’s bank account. In the latter instance, Sasai will require proof of the bank account into which the refund is to be paid.</span>
      <span className="terms-item-child">10.6. Sasai will endeavour to refund the customer as quickly as possible. Typically a reversal should not take more than 7 (seven) days to complete but Sasai cannot guarantee the time which the reversal may take to complete.</span>
      <span className="terms-item-child">10.7. Further details in relation to refunds, are available from the Sasai call centre, (the details of which are listed on the Help section of the Website and the App).</span>

      <b className="terms-item-parent">11. Details of Beneficiaries</b>
      <span className="terms-item-child">You provide us with the pay-out information regarding your Beneficiaries. The name provided in respect of the Beneficiary must match the name appearing on the relevant identification document of the Beneficiary. We will not be responsible for a pay-out going to the wrong person if you have provided us with incorrect information relating to the Beneficiary. Sasai will not be responsible in any way for loss of funds, fees, delays or damages of any kind when the incorrect Beneficiary information has been provided by you.</span>

      <b className="terms-item-parent">12. Content</b>

      <span className="terms-item-child">12.1. Sasai reserves the right to make improvements, to change or to discontinue, without notice, any aspect or feature of the Service, the Website or the App and any materials, information or content on the Website or App.</span>
      <span className="terms-item-child">12.2. Sasai reserves the right to change and amend the prices and rates stated on the Website and App at any time and from time to time without notice, and all amended prices and rates shall be published on the Website and App, from the time when they become applicable.</span>
      <span className="terms-item-child">12.3. Sasai may use the services of other organisations to provide information on the Website and the App. Sasai has no control over this information and makes no representations or warranties of any nature as to its accuracy, appropriateness or correctness. The User agrees that such information is provided “as is” and that Sasai and its online partners shall not be liable for any losses or damages that may arise from the user’s reliance on it, howsoever these may arise.</span>

      <b className="terms-item-parent">13. Advertisements that may appear</b>
      <span className="terms-item-child">If any advertisement for a product or service provided by anyone other than Sasai appears on the Website or the App, Sasai does not endorse, warrant or guarantee any such product or service and will not be a party to or in any way monitor any transaction between you and third-party providers of products or services. As with the purchase of a product or service through any medium or in any environment, you should always use your best judgment and exercise caution where appropriate.</span>

      <b className="terms-item-parent">14. Linking to and from third party websites and third-party content</b>
      <span className="terms-item-child">14.1. Sasai may provide links to third party websites on the Website and/or the App. These links are provided to the user for convenience purposes only and Sasai does not endorse, nor does the inclusion of any link imply Sasai’s endorsement of, such websites, their owners, licensees or administrators or such websites’ content or security practices and operations.</span>
      <span className="terms-item-child">14.2. Whilst Sasai tries to provide links only to reputable websites or online partners, Sasai cannot accept responsibility or liability for the information provided on other websites. Linked websites or pages are not under, nor subject to, the control of Sasai. Accordingly, you acknowledge and agree that Sasai is not responsible for and gives no warranties or makes any representations in respect of the privacy policies or practices of linked or any third party or advertised websites on the Website and/or the App.</span>
      <span className="terms-item-child">14.3. You agree that Sasai shall not be held liable, directly or indirectly, in any way for the content, the use or inability to use or access any linked website or any link(s) contained in a linked website, nor for any loss or damage of any sort incurred as a result of any dealings with, or as the result of the presence of such third party linked websites on the Website or the App. Any dealings you have with any linked websites, including advertisers, found on the Website or the App are solely between you and the third-party site, and are at your own risk.</span>

      <b className="terms-item-parent">15. Restricted activities and rules relating to usage of the Website and the App</b>

      <span className="terms-item-child">The user hereby agrees that it shall not itself, nor through a third party:</span>
      <span className="terms-item-child">15.1. copy (other than for backup, archival or disaster recovery purposes), reproduce, translate, adapt, vary, modify, lease, licence, sub-licence, encumber or in any other way deal with any portion of the Website or the App for any reason and in any manner, unless it is consistent with the intent and purpose of these Terms;</span>
      <span className="terms-item-child">15.2. decompile, disassemble or reverse engineer any portion of the Website or the App;</span>
      <span className="terms-item-child">15.3. collect service listings, descriptions or other information displayed on the Website or the App;</span>
      <span className="terms-item-child">15.4. write and/or develop any derivative of the Website or any other software program based on the Website or the App;</span>
      <span className="terms-item-child">15.5. modify or enhance the Website or the App. In the event of a user effecting any modifications or enhancements to the Website or the App in breach of this clause, such modifications and enhancements shall be the property of Sasai;</span>
      <span className="terms-item-child">15.6. without Sasai’s prior written consent, provide, disclose, divulge or make available to or permit the use of or give access to the Website or the App by persons other than the user;</span>
      <span className="terms-item-child">15.7. remove any identification, trademark, copyright or other notices from the Website or the App; and</span>
      <span className="terms-item-child">15.8. notwithstanding anything contained to the contrary in these Terms, use the Website or the App for any purpose other than for personal, non-commercial or information purposes only.</span>

      <b className="terms-item-parent">16. Restricted activities related to the Service</b>
      <span className="terms-item-child">The following uses of the Service are prohibited by these Terms. Any account found to be created for the following uses will be closed forthwith:</span>
      <ul className="cross">
        <li>Using the Service while impersonating any person or entity, falsely claiming an affiliation with any person or entity.</li>
        <li>Using the Service to make unsolicited offers, advertisements, proposals, or send junk mail or spam to others. This includes, but is not limited to, unsolicited advertising, promotional materials, or other solicitation materials, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signature.</li>
        <li>Using the Service for any illegal purpose, or in violation of any local, provincial, national, or international law, including, without limitation, laws governing intellectual property, taxation and other proprietary rights and data protection and privacy.</li>
        <li>Using the Service to defame, harass, abuse, threaten or defraud others, or collect, or attempt to collect, personal information about users, Beneficiaries or third parties without their consent.</li>
        <li>Using the Service if you are under the age of eighteen (18).</li>
        <li>Using the Service to act as a private banking institution.</li>
        <li>Using the Service in any manner that could damage, disable, overburden, or impair it, including, without limitation, using the Service in an automated manner.</li>
        <li>Using the Service to intentionally interfere with a user’s or registered Beneficiary’s enjoyment of it, by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code.</li>
        <li>Using the Service in any way that assists you in violating any law, statute or ordinance.</li>
        <li>Using the Service to send or receive what Sasai reasonably believes to be potentially fraudulent funds.</li>
        <li>Using your account or the Service in a manner that results in or may result in complaints, disputes, reversals, chargebacks, fees, fines, penalties and other liability to Sasai, a user, a third party or you.</li>
        <li>Using your account or the Service in a manner that Sasai or any bank, financial institution or card payment network reasonably believe to be an abuse of the payment card system, a violation of payment card network rules or a violation of any banking rules or laws.</li>
        <li>Using the Service to collect or contribute money for something that may be deemed harmful, false, misleading, unlawful, obscene, defamatory, libellous, threatening, pornographic, harassing, hateful, or racially or ethnically offensive.</li>
        <li>Using the Service to control an Account that is linked to another Account that has engaged in any of the foregoing activities. Sasai may use evidence other than your Account information to determine whether you control an Account in someone else’s name, including but not limited to Internet Protocol addresses, common business names, phone numbers, and mailing addresses.</li>
        <li>Using the Service to collect payments that support pyramid or ponzi schemes, matrix programs, other “get rich quick” schemes or certain multi-level marketing programs.</li>
      </ul>
      <br/>
      <span className="terms-item-child">It is further prohibited for a Customer to use Sasai or the Service to sell:</span>
      <br/>
      <ul className="cross">
        <li>Items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the laws of any jurisdiction.</li>
        <li>Items that promote hate, violence, racial intolerance, or the financial exploitation of a crime.</li>
        <li>Items that encourage, promote, facilitate or instruct others to engage in illegal activity.</li>
        <li>Items that promote, support or glorify acts of violence or harm towards self or others.</li>
        <li>Drugs, alcohol or drug paraphernalia.</li>
        <li>Items that are obscene or pornographic.</li>
        <li>Real estate or motor vehicles.</li>
        <li>Live animals.</li>
      </ul>
      <span className="terms-item-child">You agree that Sasai may seize or freeze funds that are reasonably deemed by Sasai to have resulted from fraud or any prohibited behaviour outlined in this section.</span>

      <b className="terms-item-parent">17. Your Privacy</b>

      <span className="terms-item-child">17.1. Sasai undertakes to take all due care with any information which you may provide to Sasai when accessing this Website or the App or registering for the Service (such as your personal details, proof of identification, proof of residence) and in such regard to comply with the provisions of all applicable legislation. However, to the extent permissible in terms of applicable law, Sasai does not warrant and cannot guarantee the security of any information which you may provide to Sasai. Information you transmit to us is entirely at your own risk although we undertake to take reasonable steps to preserve such information in a secure manner.</span>

      <span className="terms-item-child">17.2. To the extent that the Website or the App collects personal information about you, our use of that information will be in compliance with applicable privacy and data protection legislation, and is subject to Sasai’s Privacy Policy, which can be read at
      <a href="https://moneytransfer.sasai.global/privacy-policy"> moneytransfer.sasai.global/privacy-policy</a></span>

      <b className="terms-item-parent">18. Storing of Customer details</b>

      <span className="terms-item-child">For verification purposes we reserve the right to request proof of identification in an off-line format. Your IP address and access times are tracked against your user id for the purpose of preventing fraud and creating an audit trail when sending a payment or logging onto our secure server. A cookie is a small text file that is stored on a User’s computer for record- keeping purposes. We use cookies on the Website and the App and link the information we store in cookies to any personal identification information you submit while on the Website and the App.</span>

      <b className="terms-item-parent">19. Security</b>
      <span className="terms-item-child">19.1. In order to ensure the security and reliable operation of the Service for all users, Sasai hereby reserves the right to take whatever action it finds necessary to preserve the security, integrity and reliability of its network and back-office applications.</span>

      <span className="terms-item-child">19.2. You may not utilise the Service and/or the Website or App in any manner which may compromise the security of Sasai’s networks or systems, or tamper with the services provided by Sasai or the Website or App in any manner whatsoever, which shall include without limitation, gaining or attempting to gain unauthorised access to the Website or App, or delivering or attempting to deliver any unauthorised, damaging or malicious code to the Website or App, all of which is expressly prohibited. Any person or entity which does so, or attempts to do so, shall be held criminally liable. Furthermore, should Sasai suffer any damage or loss, civil damages shall be claimed by Sasai against the user.</span>

      <span className="terms-item-child">19.3. Any user who commits any of the offences detailed in Chapter 13 of the Electronic Communications and Transactions Act (<b className="terms-item-bold">“ECT Act”</b>) [specifically sections 85 to 88 (inclusive)] shall, notwithstanding criminal prosecution, be liable for all resulting liability, loss or damages suffered and/or incurred by Sasai and its affiliates, agents and/or partners.</span>

      <b className="terms-item-parent">20. Intellectual Property Rights</b>
      <span className="terms-item-child">&ensp;&ensp;20.1. For the purpose of this clause, the following words shall have the following meanings ascribed
      to them:</span>
      <span className="terms-item-child">&ensp;&ensp;20.1.1. <b className="terms-item-bold">“copyright”</b> means whether existing now or in the future, in and to, the Website and the App including without limitation, designs and documentation relating thereto;</span>
      <span className="terms-item-child">&ensp;&ensp;20.1.2. <b className="terms-item-bold">“intellectual property rights”</b> means all intellectual property of any nature whatsoever owned and/or controlled directly or under licence by Sasai, including without limitation, Sasai’s rights, title and interest in and to all technology, source code/s, trade secrets, logos, systems, methods, trademarks, trade names, styles, insignia, designs, patents and copyright relating to the Website or the App, whether registered or not.</span>

      <span className="terms-item-child">20.2. All copyright and other intellectual property rights in all content, trademarks, software, data, material, including logos, databases, text, graphics, icons, hyperlinks, private information, designs, agreements, and multimedia works, published on or via the Website, are the property of, or are licensed to, Sasai and as such are protected from infringement by local and international legislation and treaties.</span>

      <span className="terms-item-child">20.3. All rights not expressly granted are reserved. The copyright in all content, information and logos vesting in the Website and the App shall continue to vest in Sasai and no right, title or interest in any proprietary material or information contained in this Website is granted to you.</span>

      <span className="terms-item-child">20.4. Except with Sasai’s express written permission, no logo, graphic, sound or image from this Website may be copied or retransmitted. In particular, the name “Sasai” or “Sasai Money Transfer” or any combination of the aforesaid and any logo related thereto may not be used in any way, including in advertising or publicity pertaining to distribution of materials on the Website or the App, without the prior written permission from Sasai.</span>

      <span className="terms-item-child">20.5. Irrespective of the existence of copyright, the user acknowledges that Sasai is the proprietor of all material on the Website and the App (except where a third party is indicated as the proprietor), whether it constitutes proprietary or confidential information or not, and that the user has no right, title or interest in any such material.</span>

      <span className="terms-item-child">20.6. Sasai authorises you only to view, temporarily download to a local drive and to print the content of this Website or the App, or any part thereof, provided that such content is used for personal, non-commercial or information purposes only.</span>

      <b className="terms-item-parent">21. Sasai FinTech arrangements with third parties relating to the Services</b>
      <span className="terms-item-child">21.1. The arrangements between Sasai and any third parties shall not be construed as any form of joint venture, partnership or association of any kind, and Sasai takes no responsibility whatsoever for the actions of such third parties or their employees or agents.</span>

      <span className="terms-item-child">21.2. Sasai, in providing its services is acting as an independent contractor, and is not an agent of any other party, nor does it or any third party have any authority to represent the other as to any matters, except as expressly authorised in these Terms.</span>

      <b className="terms-item-parent">22. Disclaimer relating to Agents</b>
      <span className="terms-item-child">It is your responsibility to ensure that when (i) you register as a customer that you only do so through a Sasai approved Registration Agent and (ii) perform a money transfer transaction, that you only do so through a Sasai approved cash-in partner. Sasai will be not be at all responsible for any loss or damage incurred as a result of use of a non-approved agents/ partners.</span>

      <b className="terms-item-parent">23. Disclaimer relating to professional advice</b>
      <span className="terms-item-child">It is expressly recorded and agreed that any service available on or via this Website or the App, or any materials or information made available on or gathered from this Website or the App, is not intended to constitute legal, financial, accounting, tax, investment, consulting or other professional advice or services. Before making any such decision, please ensure that you seek advice from a qualified professional advisor.</span>

      <b className="terms-item-parent">24. Disclaimer of warranties and liabilities in respect of the Service</b>
      <span className="terms-item-child">24.1. The Service is provided to you subject to your statutory rights but are otherwise provided without any warranty and not subject to any condition, express or implied, except as specifically stated in these Terms.</span>

      <span className="terms-item-child">24.2. Whilst Sasai will do its best to maintain the operation of the Service, we do not guarantee continuous, uninterrupted or secure access to the Service and you acknowledge and agree that the operation of the Service provided by Sasai may be affected by numerous factors beyond our reasonable control. The Service is provided to you subject to your statutory rights but are otherwise provided without any warranty and not subject to any condition, express or implied, except as specifically stated in these Terms.</span>

      <span className="terms-item-child">24.3. We shall make reasonable efforts to ensure that money transfer transactions are processed in a timely manner. We will endeavour to make pay-outs to the Beneficiary as soon as possible, but we make no representations or warranties regarding the amount of time needed to complete processing because the Service is largely dependent upon many factors beyond our control, such as, without being limited to, delays in the banking system and telecommunication systems of mobile phone networks.</span>

      <span className="terms-item-child">24.4. Whilst we will do our best to maintain the operation of our Service, we do not guarantee continuous, uninterrupted or secure access to the service and operation of the Website and the App and mobile systems may be affected by numerous factors beyond our control. We shall not be liable for any delay or failure in the provision of our services or the working of our Website, the App and Service where such failure is due to factors beyond our reasonable control, save that we will use reasonable endeavours to fulfil our obligations hereunder.</span>

      <span className="terms-item-child">24.5. Sasai relies on a variety of firms and partners to implement the Service. We shall not be liable for any delay or failure in the provision of the Service due to any delay or failure in the provision our partners’ services or the working of their sites and services. This relates to, yet not exclusively to, SMS notifications, all banking, financial services, mobile phone network and website services.</span>

      <span className="terms-item-child">24.6. Although we at all times attempt to ensure that the details of the Service offered on our Website and App are displayed and described accurately, we do not warrant same and disclaim to the maximum extent permitted by law any liability arising from any omissions or inaccuracies pertaining to such display and description.</span>

      <span className="terms-item-child">24.7. You acknowledge that your access to the Website and the App may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore the Website or App, as the case may be, as soon as we reasonably can.</span>

      <span className="terms-item-child">24.8. Sasai shall not be liable for any delay or failure in the provision of the Services where such failure is due to factors beyond our reasonable control, save that we will use reasonable endeavours to fulfil our obligations under these Terms.</span>

      <b className="terms-item-parent">25. Disclaimer of warranties in respect of the Website and App</b>

      <span className="terms-item-child">25.1. You acknowledge and agree that the materials, information and any content on this Website and the App are provided “as is” without warranties of any kind, either express or implied.</span>
      <span className="terms-item-child">25.2. Sasai makes no representations or warranties, whether express or implied, as to the availability, accuracy, completeness or reliability of any materials, information, data and/or content on the Website and the App, including without limitation:</span>
      <span className="terms-item-child">&ensp;&ensp;25.2.1. Sasai does not warrant that the Website or the App or provided/displayed information or downloads shall be error-free or that they shall meet any particular criteria of performance or quality. Sasai expressly disclaims all implied warranties, including without limitation, warranties of merchantability, completeness, fitness for a purpose, non-infringement, compatibility, security, integrity and accuracy;</span>
      <span className="terms-item-child">&ensp;&ensp;25.2.2. whilst Sasai has taken reasonable measures to ensure the integrity of the Website, the App and their contents, no warranty, whether express or implied, is given that any files, downloads or applications available via the Website or App are free of viruses, Trojans, time bombs, time-locks or any other data or code which has the ability to corrupt, damage or affect the operation of the user’s system; and</span>
      <span className="terms-item-child">&ensp;&ensp;25.2.3. Sasai disclaims any responsibility for the verification of any claims made on this Website or the App. In this regard, any materials, information or content published on the Website or the App may be done so in the format in which Sasai receives it and statements from external parties are accepted as fact.</span>

      <span className="terms-item-child">25.3. Although the information on the Website and App is presented in good faith and believed to be correct, Sasai makes no representations or warranties as to the completeness or accuracy of the materials, information or content on the Website or App, or any websites or apps linked to the Website or App.</span>
      <span className="terms-item-child">25.4. Certain content on the Website and the App is the property of third parties, used with their permission. The opinions expressed by third parties do not necessarily reflect those of Sasai.</span>

      <b className="terms-item-parent">26. RISK, LIMITATION OF LIABILITY AND INDEMNITY</b>

      <span className="terms-item-child">26.1. THE USER’S USE OF THE WEBSITE AND THE APP AND THE INFORMATION CONTAINED THEREON IS ENTIRELY AT THE USER’S OWN RISK AND THE USER ASSUMES FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM THE USE THEREOF.</span>
      <span className="terms-item-child">26.2. THE TRANSMISSION OF INFORMATION VIA THE INTERNET, INCLUDING WITHOUT LIMITATION EMAIL, IS SUSCEPTIBLE TO MONITORING AND INTERCEPTION. THE USER BEARS ALL RISK OF TRANSMITTING INFORMATION IN THIS MANNER. UNDER NO CIRCUMSTANCES SHALL SASAI BE LIABLE FOR ANY LOSS, HARM, OR DAMAGE SUFFERED BY THE USER AS A RESULT THEREOF. SASAI RESERVES THE RIGHT TO REQUEST INDEPENDENT VERIFICATION OF ANY INFORMATION TRANSMITTED VIA EMAIL AND THE USER CONSENTS TO SUCH VERIFICATION SHOULD SASAI DEEM IT NECESSARY.</span>
      <span className="terms-item-child">26.3. TO THE EXTENT PERMISSIBLE BY LAW:</span>
      <span className="terms-item-child">&ensp;&ensp;26.3.1. NEITHER SASAI, ITS AFFILIATES, SHAREHOLDERS, AGENTS, CONSULTANTS OR EMPLOYEES SHALL BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, HOWSOEVER ARISING (WHETHER IN AN ACTION ARISING OUT OF CONTRACT, STATUTE, DELICT OR OTHERWISE) RELATED TO THE SERVICE PROVIDED BY SASAI, THE USE OF, OR THE INABILITY TO ACCESS OR USE THE CONTENT OF THE WEBSITE OR APP ANY FUNCTIONALITY THEREOF, OR THE INFORMATION CONTAINED ON THE WEBSITE OR APP, OR OF ANY LINKED WEBSITEOR APP, EVEN IF SASAI KNOWS OR SHOULD REASONABLY HAVE KNOWN OR IS EXPRESSLY ADVISED THEREOF.</span>
      <span className="terms-item-child">&ensp;&ensp;26.3.2. THE LIABILITY OF SASAI FOR FAULTY EXECUTION OF THE WEBSITE AND THE APP, AS WELL AS ALL DAMAGES SUFFERED BY THE USER, WHETHER DIRECT OR INDIRECT, AS A RESULT OF THE MALFUNCTIONING OF THE WEBSITE OR APP OR SERVICE SHALL BE LIMITED TO SASAI RECTIFYING THE MALFUNCTION, WITHIN A REASONABLE TIME AND FREE OF CHARGE, PROVIDED THAT SASAI IS NOTIFIED IMMEDIATELY OF THE DAMAGE OR FAULTY EXECUTION OF THE WEBSITE, SERVICE OR APP. THIS LIABILITY SHALL FALL AWAY AND BE EXPRESSLY EXCLUDED IF THE USER ATTEMPTS TO CORRECT OR ALLOWS THIRD PARTIES TO CORRECT OR ATTEMPT TO CORRECT THE WEBSITE, APP OR SERVICE WITHOUT THE PRIOR WRITTEN APPROVAL OF SASAI. HOWEVER, IN NO EVENT SHALL SASAI BE LIABLE TO THE USER FOR LOSS OF PROFITS OR FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE LOSSES OR DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE WEBSITE, THE APP, THE SERVICE</span>
      <span className="terms-item-child">&ensp;&ensp;26.3.3. YOU HEREBY UNCONDITIONALLY AND IRREVOCABLY INDEMNIFY SASAI AND AGREE TO HOLD SASAI FREE FROM ALL LOSS, DAMAGES, CLAIMS AND/OR COSTS, OF WHATSOEVER NATURE SUFFERED OR INCURRED BY SASAI OR INSTITUTED AGAINST SASAI AS A DIRECT OR INDIRECT RESULT OF:</span>
      <span className="terms-item-child">&ensp;&ensp;&ensp;26.3.3.1. YOUR USE OF THE WEB, APP OR THE SERVICE PROVIDED BY SASAI;</span>
      <span className="terms-item-child">&ensp;&ensp;&ensp;26.3.3.2. SOFTWARE, PROGRAMS AND SUPPORT SERVICES SUPPLIED BY, OBTAINED BY OR MODIFIED BY YOU OR ANY THIRD PARTY WITHOUT THE CONSENT OR KNOWLEDGE OF SASAI; OR</span>
      <span className="terms-item-child">&ensp;&ensp;&ensp;26.3.3.3. YOUR FAILURE TO COMPLY WITH ANY OF THE TERMS OR ANY OTHER REQUIREMENTS WHICH SASAI MAY IMPOSE FROM TIME TO TIME;</span>
      <span className="terms-item-child">&ensp;&ensp;&ensp;26.3.3.4. THE ACTIONS OR REQUIREMENTS OF ANY TELECOMMUNICATIONS AUTHORITY OR A SUPPLIER OF TELECOMMUNICATIONS SERVICES OR SOFTWARE; OR</span>
      <span className="terms-item-child">&ensp;&ensp;&ensp;26.3.3.5. ANY UNAVAILABILITY OF, OR INTERRUPTION IN, THE SERVICES PROVIDED BY SASAI WHICH IS BEYOND THE CONTROL OF SASAI.</span>

      <b className="terms-item-parent">27. Breach & Cancellation by Sasai FinTech</b>
      <span className="terms-item-child">27.1. Sasai is entitled without notice, in addition to any other remedy available to it at law or under these Terms, including obtaining an interdict, to cancel these Terms, limit or deny such user use of the Website, the App and/or the Service provided by Sasai, or to claim specific performance of any obligation whether or not the due date for performance has arrived, in either event without prejudice to Sasai’s right to claim damages, should any user:</span>
      <span className="terms-item-child">&ensp;&ensp;27.1.1. breach any of these Terms;</span>
      <span className="terms-item-child">&ensp;&ensp;27.1.2. damage or harm the reputation of Sasai;</span>
      <span className="terms-item-child">&ensp;&ensp;27.1.3. in the sole discretion of Sasai, use the Website, App and/or the Service provided by Sasai in an unauthorised manner; or</span>
      <span className="terms-item-child">&ensp;&ensp;27.1.4. infringe any statute, regulation, ordinance or law.</span>
      <span className="terms-item-child">27.2. Breach of these Terms entitles Sasai to take legal action without prior notice to the user and the user agrees to reimburse the costs associated with such legal action to Sasai on an attorney and own client scale.</span>

      <b className="terms-item-parent">28. Maintaining your account information</b>
      <span className="terms-item-child">If you have reason to believe that your account is no longer secure (for example, in the event of a loss, theft or unauthorised disclosure or use of your email address, password, or any credit, debit or charge card number, if applicable), then you agree to immediately notify Sasai.</span>

      <b className="terms-item-parent">29. General compliance with laws</b>
      <span className="terms-item-child">Without prejudice to any other provision set out in these Terms, you undertake that you shall at all times comply with all applicable laws, statutes, ordinances and regulations pertaining to your accessing of this Website and/or your use of the Service provided by Sasai.</span>

      <b className="terms-item-parent">30. Address for delivery of legal notices</b>

      <span className="terms-item-child">30.1. Except as expressly stated otherwise, any notices shall be given by email to&nbsp;
      <a href="mailto:support@sasai.global">support@sasai.global</a> (in the case of Sasai) or to the email address you have provided to Sasai (in your case), or such other address that has been specified. Notice shall be deemed given 48 hours after an email is sent, unless the sending party is notified that the email address is invalid. Alternatively, Sasai may give you notice by hand or by registered mail to the address which you have provided to Sasai. In such case, notice shall be deemed given 7 (seven) days after the date of mailing.</span>

      <span className="terms-item-child">30.2. The user of this Website, the App and the Service acknowledges that all agreements, notices or other communication required to be given in terms of the law or these Terms may be given via electronic means and that such communications shall be “in writing”. Notwithstanding anything to the contrary, a written notice or communication received by a party shall be an adequate written notice or communication to it, notwithstanding that it was not sent to or delivered at its chosen address(es) for that purpose.</span>

      <b className="terms-item-parent">31. General</b>
      <span className="terms-item-child">31.1. These Terms shall be governed in all respects by the laws of the Republic of South Africa as such laws are applied to agreements entered into and to be performed entirely within South Africa.</span>
      <span className="terms-item-child">31.2. This Website, the App and the Service is controlled, operated and administered by Sasai and/or its authorised service providers from offices within the Republic of South Africa. Sasai makes no representation that the materials, information or content of the Website or the App are appropriate or available for use outside of South Africa. Access to the Website from territories or countries where the materials or content of the Website are illegal is prohibited. Users may not use this Website in violation of South African export laws and regulations. If the user accesses this Website from locations outside of South Africa, that user is responsible for compliance with all local laws.</span>
      <span className="terms-item-child">31.3. If any provision of these Terms is declared by a competent court to be illegal, invalid or unenforceable for any reason, such provision shall be deemed severable from these Terms and the remaining provisions shall be enforced to the full extent of the law.</span>
      <span className="terms-item-child">31.4. The failure of Sasai to exercise or enforce any right or provision of these Terms, in particular with respect to a breach by you or others of these Terms, does not constitute a waiver of such right or provision, nor does it constitute a waiver to act with respect to similar or subsequent breaches.</span>
      <span className="terms-item-child">31.5. You shall not be entitled to cede your rights or assign your rights or delegate your obligations in terms of these Terms to any third party without the prior written consent of Sasai.</span>
      <span className="terms-item-child">31.6. Sasai shall not be bound by any express or implied term, representation, warranty, promise or the like not recorded herein, whether it induced the contract and/or whether it was negligent or not.</span>
      <span className="terms-item-child">31.7. Words importing the singular shall include the plural and vice versa, and words importing the masculine gender shall include females and words importing persons shall include partnerships and corporate and unincorporated entities.</span>

      <b className="terms-item-parent">32. Company Information</b>
      <span className="terms-item-child">For your convenience we list some general information regarding our company:</span>
      <span className="terms-item-child">32.1. The legal name of the company is Sasai Fintech (Pty) Ltd</span>
      <span className="terms-item-child">32.2. We are a private company incorporated in accordance with the laws of the Republic of South Africa with registration number: 2015/225878/07.</span>
      <span className="terms-item-child">32.3. We operate from Econet Head Office, Worcestor House, Eton Office Park, Corner Sloane and Harrison Avenue, Bryanston, Johannesburg, South Africa and our contact details are as follows:</span>
      <span className="terms-item-child">&ensp;&ensp;32.3.1. Telephone: <a href="tel:+27105008801">010 500 8801</a></span>
      <span className="terms-item-child">&ensp;&ensp;32.3.2. Email: <a href="mailto:support@sasai.global">support@sasai.global</a></span>
      <span className="terms-item-child">&ensp;&ensp;32.3.3. Website: <a href="https://moneytransfer.sasai.global/">moneytransfer.sasai.global</a></span>

      <b className="terms-item-parent">33. General</b>
      <span className="terms-item-child">These Terms and all relevant terms implied herein by applicable law constitute the entire agreement between you and us in respect of the use of the Website, the App and the Service.</span>
      <br />
      <b className="terms-item-parent">B. CUSTOMER PRIVACY NOTICE</b>
      <span className="terms-item-child">This Notice explains how we obtain, use and disclose your personal information, in accordance with the requirements of the Protection of Personal Information Act (“POPIA”)</span>
      <span className="terms-item-child">Sasai is committed to protecting your privacy and to ensure that your personal information is collected and used properly, lawfully and transparently.</span>
      <h6><b>About the Company</b></h6>
      <span className="terms-item-child">Sasai Fintech (Pty) Ltd is a category 3 authorised dealer with limited authority. We conduct a money remittance service from South Africa to several other countries on behalf of our customers.</span>
      <h6><b>The information we collect</b></h6>
      <span className="terms-item-child">We collect and process potential and existing customers’ personal information mainly to:</span>
      <br />
      <ul className="cross">
        <li>ensure compliance with applicable laws and regulations</li>
        <li>follow your instructions in respect of the service offering</li>
        <li>meet our contractual responsibilities to you</li>
        <li>process your personal information for ordinary business purposes (this includes to open and maintain your account, give effect to transactions processing and monitoring, administer claims where applicable, manage our risks and maintain our overall relationship with you)</li>
        <li>carry out statistical and other analyses to identify potential markets and industry trends, evaluate and improve our business processes and service offering (this includes improving existing and developing new products and services)</li>
        <li>inform you about our services and product offerings.</li>
      </ul>
      <span className="terms-item-child">We collect information directly from potential and existing customers who provide us with their personal details willingly. Where possible, we will inform you what information you are required to provide to us and what information is optional.</span>
      <span className="terms-item-child">Website usage information may be collected using “cookies” which allows us to collect standard internet visitor usage information.</span>
      <h6><b>How we use your information</b></h6>
      <span className="terms-item-child">We will use your personal information only for the purposes for which it was collected and agreed with you. In addition, where necessary your information may be retained for legal or research purposes.</span>
      <span className="terms-item-child">For example:</span>
      <ul className="cross">
        <li>To gather contact information</li>
        <li>To confirm and verify your identity or to verify that you are an authorised user for security purposes</li>
        <li>For the detection and prevention of fraud, crime, money laundering or other malpractice</li>
        <li>To conduct market or customer satisfaction research or for statistical analysis;</li>
        <li>For audit and record keeping purposes</li>
        <li>In connection with legal proceedings.</li>
      </ul>
      <h6><b>Disclosure of information</b></h6>
      <span className="terms-item-child">We may disclose your personal information to our service providers who are involved in the delivery of products or services to you on our behalf. We have agreements in place to ensure that all service providers comply with the privacy requirements as required by the Protection of Personal Information Act.</span>
      <span className="terms-item-child">We may also disclose your information</span>
      <ul className="cross">
        <li>Where we have a duty or a right to disclose in terms of law or industry codes</li>
        <li>Where we believe it is necessary to protect our rights.</li>
      </ul>
      <h6><b>Information Security</b></h6>
      <span className="terms-item-child">We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorized access and use of personal information. We will, on an on-going basis, continue to review our security controls and related processes to ensure that your personal information remains secure.</span>
      <span className="terms-item-child">Our security policies and procedures cover:</span>
      <ul className="cross">
        <li>Physical security</li>
        <li>Computer and network security</li>
        <li>Access to personal information</li>
        <li>Secure communications</li>
        <li>Security in contracting out activities or functions</li>
        <li>Retention and disposal of information</li>
        <li>Acceptable usage of personal information</li>
        <li>Governance and regulatory issues</li>
        <li>Monitoring access and usage of private information</li>
        <li>Investigating and rectifying security breaches.</li>
      </ul>
      <span className="terms-item-child">When we contract with third parties, we legally impose appropriate security, privacy and confidentiality obligations on them to ensure that personal information is secure. We remain ultimately responsible for the security of personal information.</span>
      <span className="terms-item-child">We will ensure that anyone to whom we pass your personal information agrees to treat your information with the same level of protection as we are obliged to.</span>
      <h6><b>Your Rights: Access to information</b></h6>
      <span className="terms-item-child">You have the right to request a copy of the personal information we hold about you. To do this, simply contact us at the numbers/addresses as provided on our website and specify what information you require. We will need to authenticate you before we provide you with details of your personal information in order to ensure that the information is shared with the legitimate party. The authentication process will include the provision of your ID document and may include authentication questions and provision of other supporting information.</span>
      <span className="terms-item-child">Please note that any such access request may be subject to a payment of a legally allowable fee.</span>
      <h6><b>Correction of your information</b></h6>
      <span className="terms-item-child">You have the right to ask us to update, correct or delete your personal information. We will need to authenticate your identity before making changes to personal information. We implore you to provide accurate personal information.</span>
      <h6><b>Definition of personal information</b></h6>
      <span className="terms-item-child">According to the Act ‘‘personal information’’ means information relating to an identifiable, living, natural person, and where it is applicable, an identifiable, existing juristic person. Further to the POPI Act, Sasai also includes the following items as personal information:</span>
      <ul className="cross">
        <li>All addresses including residential, postal and email addresses.</li>
        <li>Change of name – for which we require copies of the marriage certificate or official change of name document issued by the state department</li>
        <li>Detail of the beneficiary you are sending money to.</li>
      </ul>
      <h6><b>How to contact us</b></h6>
      <span className="terms-item-child">If you have any queries about this notice; you need further information about our privacy practices; wish to withdraw consent; exercise preferences or access or correct your personal information, please contact us at the numbers/addresses listed on our website.</span>
    </>       
  )
}

export default TermsZa;
