import React from 'react';
import { Layout, Button } from 'antd';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import { message } from 'antd';
import {
  requestSubmitEmailCode
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../styles/index.css'
import '../styles/user.css'
// import '../../styles/ant-theme-vars.less'
//import 'antd/dist/antd.css';

import ManageUrls from '../endpoints/manage_urls.js';
import baseUrlOptions from '../endpoints/base_url_options.js';


const { Content } = Layout;
const TAG = "SmartVerifyEmail";

class SmartVerifyEmail extends React.Component {

  render() {
    return (
      <Content className="user-content-container-2"
        style={{minHeight: '400px', background: 'transparent'}}>
        <Button
          className="home-collect"
          style={{
          backgroundColor: 'rgb(41, 108, 187)',
          borderColor: 'rgb(41, 108, 187)',
          boxSizing: 'border-box',
          color: 'white',
          border: '1px solid',
          textAlign: 'center',
          whiteSpace: 'normal', 
          maxWidth: 300,
        }}
        type="primary"
        onClick={this.onVerify}>Click to verify e-mail address</Button>
      </Content>
    )
  }

  onVerify = () => {
    this.requestVerifyEmail();
  }

  componentDidMount() {
    document.title = "Verify Email"

    const manageUrls = new ManageUrls();

    if(this.props.match.params.country === 'za'){
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA);
    } if (this.props.match.params.country === 'gb') {
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM);
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
    if (!!this.props.authReducer.accessToken) {
        Log.d(TAG, "componentDidUpdate() -- load home ")
        this.props.history.push({
          pathname: "/"
        });
      } else {
        Log.d(TAG, "componentDidUpdate() -- load login ")
        Helper.clearCookies(this.props.cookies) 
        this.props.history.push({
          pathname: "/login"
        });
      }
    }
  }

  removeTrailingSlash(site){
    return site.replace(/\/+$/, "");
  }

  requestVerifyEmail(){
    var url = this.removeTrailingSlash(this.props.match.url);
    var urlSplit = url.split("/");

    Log.d(TAG, "requestVerifyEmail() -- urlSplit=" + JSON.stringify(urlSplit));

    var uid = urlSplit[urlSplit.length - 2];
    var token = urlSplit[urlSplit.length - 1];

    Log.d(TAG, "requestVerifyEmail() -- uid=" + uid);
    Log.d(TAG, "requestVerifyEmail() -- token=" + token);

    var attributesObj = {};
    attributesObj.token  = token;

    var dataObj = {};
    dataObj.attributes = attributesObj;
    dataObj.id = uid;
    dataObj.type = "emails";

    var payloadObj = {};
    payloadObj.data = dataObj;
    Log.d(TAG, "requestVerifyEmail() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestSubmitEmailCode(payloadObj, uid, token);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestSubmitEmailCode
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartVerifyEmail)
