import React from 'react';
import { Layout, List, Card, Button, Modal  } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'
import sugra from '../../images/promotions_sugra.jpg'
import flyer from '../../images/promotions_customers_flyer.jpg'

const { Content, Header, Footer } = Layout;
const promotions = [
  {
    title: "Win School Fees",
    image: sugra,
  },
  {
    title: "0% Charge",
    image: flyer,
  },
];


class Promotions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedIndex: 0
    }
  }

  showModal = (index) => {
    this.setState({
      visible: true,
      selectedIndex: index
    });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  componentDidMount() {
    document.title = "Promotions"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="dashboard-body">
          <h2 style={{ fontFamily: 'Lato', color: 'black', fontSize: '48px', textAlign: 'center', marginTop: '80px'}}>Promotions</h2>
          <List
            style={{ marginTop: '5%' }}
            grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3, }}
            dataSource={promotions}
            renderItem={(item, index) => (
              <List.Item>
                <Card>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div className="outer-box">
                      <img className="promotions-image" src={item.image} alt="promotions"/>
                      <div className="inner-box"  onClick={() => {
                        this.showModal(index);
                        }}>
                        <div>
                          <p style={{ borderStyle: "solid" }}>Zoom Promotion</p>
                        </div>
                      </div>
                    </div>
                    <Button style={{ maxWidth: '100%', background: '#154989', marginTop: '20px', marginLeft: '8px', marginRight: '8px', border: '1px solid #154989'}} type="primary" onClick={() => {
                      this.props.history.push({
                        pathname: "/terms-of-use",
                      });
                    }}>
                      Read Ts & Cs
                    </Button>
                  </div>
                </Card>
              </List.Item>
            )}
          />
          <Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={900}
            footer={null}>
            <img className="big-promotions" src={promotions[this.state.selectedIndex].image} alt="promotions"/>
          </Modal>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(Promotions)
