import Log from '../utils/log.js';
import config from '../env-config.json'
import ManageUrls from '../endpoints/manage_urls.js';
import baseUrlOptions from '../endpoints/base_url_options.js';
//import config from '../env-config.json'; // use this for App ID header

const TAG = "Helper";

class Helper {

  static get BeneficiaryFields() {
    return {
      country : 1,
      recipient_type : 2,
      first_name : 3,
      middle_name : 4,
      last_name : 5,
      nick_name : 6,
      relationship : 7,
      id_number : 8,
      passport_number : 9,
      passport_country : 10,
      reference_to_appear : 11,
      gender : 12,
      birth_date : 13,
      entity_name : 14,
      entity_registration_number : 15,
      entity_classification : 16,
      entity_type : 17,
      entity_contact_name : 18,
      entity_reference : 19,
      landline : 20,
      mobile : 21,
      phone : 22,
      email : 23,
      airtime_msisdn : 24,
      postcode : 25,
      address_line_1 : 26,
      address_line_2 : 27,
      city : 28,
      province : 29,
      suburb : 30,
      county : 31,
      postal_postcode : 32,
      postal_address_line_1 : 33,
      postal_address_line_2 : 34,
      postal_city : 35,
      postal_province : 36,
      postal_suburb : 37,
      postal_county : 38,
      bank : 39,
      bank_name : 40,
      bank_city : 41,
      branch_name : 42,
      branch_state : 43,
      branch_district : 44,
      branch_city : 45,
      branch : 46,
      account_holder_name : 47,
      account_number : 48,
      account_type : 49,
      swift_code : 50,
      iban_number : 51,
      bsb_number : 52,
      tax_number : 53,
      suffix : 54,
      eight_digit_code : 55,
      physical_address : 56,
      sort_code : 57,
      cash_pickup_provider : 58,
      cash_pickup_location : 59,
      mobile_money_provider : 60,
      mobile_money_msisdn : 61,
      ewallet_id : 62,
      

    }
  }

  static getLoginHeaders(recaptchaToken) {
    return {
      'Content-Type': 'application/json',
      'App-Id': config.app_id,
      'Accept' : 'application/json'
    }
  }

  static getRefreshTokenHeaders() {
    return {
      'Content-Type': 'application/json',
      'App-Id': config.app_id,
      'Accept' : 'application/json'
    }
  }

  static getNoTokenHeaders() {
    return {
      'Content-Type': 'application/vnd.api+json',
      'App-Id': config.app_id,
      'Accept' : 'application/vnd.api+json'
    }
  }

  static getHeaders(token) {
    return {
      'Content-Type': 'application/vnd.api+json',
      'App-Id': config.app_id,
      'Accept' : 'application/vnd.api+json',
      'Authorization' : 'Bearer ' + token
    }
  }

  static getAuthHeaders(token) {
    return {
      'Content-Type': 'application/json',
      'App-Id': config.app_id,
      'Accept' : 'application/json',
      'Authorization' : 'Bearer ' + token
    }
  }

  static getFormDataHeaders(token) {
    return {
      'Authorization' : 'Bearer ' + token
    }
  }

  static getCardTypes() {
    const saCards = [
      ["VISA", "Visa Debit / Delta"],
      ["MC", "MasterCard Debit / Maestro"],
    ]

    const ukCards = [
      ["VISA_DEBIT", "Visa Debit"],
      ["SOLO", "Solo"],
      ["MAESTRO", "Maestro"],
      ["UKE", "Electron"],
      ["MC_DEBIT", "MasterCard Debit"],
      ["ELECTRON", "Electron"]
    ]

    return Helper.baseCountryIsSA ? saCards : ukCards
  }

  static clearCookies(cookies) {
    cookies.remove('access')
    cookies.remove('refresh')
    cookies.remove('first_name')
    cookies.remove('last_name')
  }

  static containsIDAttributes(mainKey) {
      return (mainKey === "gender") ||
        (mainKey === "entity_classification") ||
        (mainKey === "entity_type") ||
        (mainKey === "province") ||
        (mainKey === "postal_province") ||
        (mainKey === "recipient_type");
  }

  static containsContactDetails(mainKey) {
      return (mainKey === "landline") ||
        (mainKey === "mobile") ||
        (mainKey === "phone") ||
        (mainKey === "email") ||
        (mainKey === "airtime_msisdn");
  }

  static containsPhoneNumbers(mainKey) {
      return (mainKey === "landline") ||
        (mainKey === "mobile") ||
        (mainKey === "phone") ||
        (mainKey === "mobile_money_msisdn") ||
        (mainKey === "airtime_msisdn");
  }

  static containsPostalAddressFields(mainKey) {
    return (mainKey === "postal_postcode") ||
      (mainKey === "postal_address_line_1") ||
      (mainKey === "postal_address_line_2") ||
      (mainKey === "postal_city") ||
      (mainKey === "postal_province") ||
      (mainKey === "postal_suburb") ||
      (mainKey === "postal_county");
  }

  static containsAddressFields(mainKey) {
      return (mainKey === "postcode") ||
        (mainKey === "address_line_1") ||
        (mainKey === "address_line_2") ||
        (mainKey === "city") ||
        (mainKey === "province") ||
        (mainKey === "suburb") ||
        (mainKey === "county") ;
  }

  static containsRecipientFields(mainKey) {
    return (mainKey === "first_name") ||
      (mainKey === "middle_name") ||
      (mainKey === "last_name") ||
      (mainKey === "id_number") ||
      (mainKey === "passport_number") ||
      (mainKey === "passport_country") ||
      (mainKey === "reference_to_appear") ||
      (mainKey === "gender") ||
      (mainKey === "birth_date") ||
      (mainKey === "recipient_type") ||
      (mainKey === "nickname") ||
      (mainKey === "ewallet_id") ||
      (mainKey === "country") ||
      (mainKey === "relationship");
  }

  static containsEntityFields(mainKey) {
    return (mainKey === "entity_name") ||
      (mainKey === "entity_registration_number") ||
      (mainKey === "entity_classification") ||
      (mainKey === "entity_type") ||
      (mainKey === "entity_contact_name") ||
      (mainKey === "entity_reference")||
      (mainKey === "recipient_type") ||
      (mainKey === "ewallet_id") ||
      (mainKey === "country");
  }

  static containsUniqueFields(mainKey) {
     return mainKey !== "airtime_msisdn";
  }

  static containsBankFields(mainKey) {
    return (mainKey === "bank") ||
      (mainKey === "bank_name") ||
      (mainKey === "bank_city") ||
      (mainKey === "branch") ||
      (mainKey === "branch_name") ||
      (mainKey === "branch_city") ||
      (mainKey === "branch_district") ||
      (mainKey === "branch_state") ||
      (mainKey === "account_holder_name") ||
      (mainKey === "account_number") ||
      (mainKey === "account_type") ||
      (mainKey === "swift_code") ||
      (mainKey === "iban_number") ||
      (mainKey === "bsb_number") ||
      (mainKey === "tax_number") ||
      (mainKey === "suffix") ||
      (mainKey === "eight_digit_code") ||
      (mainKey === "physical_address") ||
      (mainKey === "sort_code");
  }

  static containsCppFields(mainKey) {
    return (mainKey === "cash_pickup_provider") ||
      (mainKey === "cash_pickup_location");
  }

  static containsMmpFields(mainKey) {
    return (mainKey === "mobile_money_provider") || (mainKey === "mobile_money_msisdn");
  }

  static isEntityWithEntityFields(isEntity, beneficiarySetting) {
    return (isEntity && (Helper.containsEntityFields(beneficiarySetting.mainKey)
      || Helper.containsContactDetails(beneficiarySetting.mainKey)
      || Helper.containsAddressFields(beneficiarySetting.mainKey)
      || Helper.containsPostalAddressFields(beneficiarySetting.mainKey)
      || Helper.containsBankFields(beneficiarySetting.mainKey)
      || Helper.containsCppFields(beneficiarySetting.mainKey)
      || Helper.containsMmpFields(beneficiarySetting.mainKey)));
  }

    static  isReceipientWithReceipientFields(isRecipient, beneficiarySetting) {
      return (isRecipient && (Helper.containsRecipientFields(beneficiarySetting.mainKey)
        || Helper.containsContactDetails(beneficiarySetting.mainKey)
        || Helper.containsAddressFields(beneficiarySetting.mainKey)
        || Helper.containsPostalAddressFields(beneficiarySetting.mainKey)
        || Helper.containsBankFields(beneficiarySetting.mainKey)
        || Helper.containsCppFields(beneficiarySetting.mainKey)
        || Helper.containsMmpFields(beneficiarySetting.mainKey)));
    }


  static getOrder(mainKey) {
    if (mainKey === "country") {
      return Helper.BeneficiaryFields.country;
    } else if (mainKey === "recipient_type") {
      return Helper.BeneficiaryFields.recipient_type;
    } else if (mainKey === "first_name") {
      return Helper.BeneficiaryFields.first_name;
    } else if (mainKey === "middle_name") {
      return Helper.BeneficiaryFields.middle_name;
    } else if (mainKey === "last_name") {
      return Helper.BeneficiaryFields.last_name;
    } else if (mainKey === "id_number") {
      return Helper.BeneficiaryFields.id_number;
    } else if (mainKey === "passport_number") {
      return Helper.BeneficiaryFields.passport_number;
    } else if (mainKey === "passport_country") {
      return Helper.BeneficiaryFields.passport_country;
    } else if (mainKey === "reference_to_appear") {
      return Helper.BeneficiaryFields.reference_to_appear;
    } else if (mainKey === "gender") {
      return Helper.BeneficiaryFields.gender;
    } else if (mainKey === "birth_date") {
      return Helper.BeneficiaryFields.birth_date;
    } else if (mainKey === "entity_name") {
      return Helper.BeneficiaryFields.entity_name;
    } else if (mainKey === "entity_registration_number") {
      return Helper.BeneficiaryFields.entity_registration_number;
    } else if (mainKey === "entity_classification") {
      return Helper.BeneficiaryFields.entity_classification;
    } else if (mainKey === "entity_type") {
      return Helper.BeneficiaryFields.entity_type;
    } else if (mainKey === "entity_contact_name") {
      return Helper.BeneficiaryFields.entity_contact_name;
    } else if (mainKey === "entity_reference") {
      return Helper.BeneficiaryFields.entity_reference;
    } else if (mainKey === "landline") {
      return Helper.BeneficiaryFields.landline;
    } else if (mainKey === "mobile") {
      return Helper.BeneficiaryFields.mobile;
    } else if (mainKey === "phone") {
      return Helper.BeneficiaryFields.phone;
    } else if (mainKey === "email") {
      return Helper.BeneficiaryFields.email;
    } else if (mainKey === "airtime_msisdn") {
      return Helper.BeneficiaryFields.airtime_msisdn;
    } else if (mainKey === "postcode") {
      return Helper.BeneficiaryFields.postcode;
    } else if (mainKey === "address_line_1") {
      return Helper.BeneficiaryFields.address_line_1;
    } else if (mainKey === "address_line_2") {
      return Helper.BeneficiaryFields.address_line_2;
    } else if (mainKey === "city") {
      return Helper.BeneficiaryFields.city;
    } else if (mainKey === "province") {
      return Helper.BeneficiaryFields.province;
    } else if (mainKey === "suburb") {
      return Helper.BeneficiaryFields.suburb;
    } else if (mainKey === "county") {
      return Helper.BeneficiaryFields.county;
    } else if (mainKey === "postal_postcode") {
      return Helper.BeneficiaryFields.postal_postcode;
    } else if (mainKey === "postal_address_line_1") {
      return Helper.BeneficiaryFields.postal_address_line_1;
    } else if (mainKey === "postal_address_line_2") {
      return Helper.BeneficiaryFields.postal_address_line_2;
    } else if (mainKey === "postal_city") {
      return Helper.BeneficiaryFields.postal_city;
    } else if (mainKey === "postal_province") {
      return Helper.BeneficiaryFields.postal_province;
    } else if (mainKey === "postal_suburb") {
      return Helper.BeneficiaryFields.postal_suburb;
    } else if (mainKey === "postal_county") {
      return Helper.BeneficiaryFields.postal_county;
    } else if (mainKey === "bank") {
      return Helper.BeneficiaryFields.bank;
    } else if (mainKey === "bank_name") {
      return Helper.BeneficiaryFields.bank_name;
    } else if (mainKey === "bank_city") {
      return Helper.BeneficiaryFields.bank_city;
    } else if (mainKey === "branch") {
      return Helper.BeneficiaryFields.branch;
    } else if (mainKey === "branch_name") {
      return Helper.BeneficiaryFields.branch_name;
    } else if (mainKey === "branch_city") {
      return Helper.BeneficiaryFields.branch_city;
    } else if (mainKey === "branch_state") {
      return Helper.BeneficiaryFields.branch_state;
    } else if (mainKey === "branch_district") {
      return Helper.BeneficiaryFields.branch_district;
    } else if (mainKey === "account_holder_name") {
      return Helper.BeneficiaryFields.account_holder_name;
    } else if (mainKey === "account_number") {
      return Helper.BeneficiaryFields.account_number;
    } else if (mainKey === "account_type") {
      return Helper.BeneficiaryFields.account_type;
    } else if (mainKey === "swift_code") {
      return Helper.BeneficiaryFields.swift_code;
    } else if (mainKey === "iban_number") {
      return Helper.BeneficiaryFields.iban_number;
    } else if (mainKey === "bsb_number") {
      return Helper.BeneficiaryFields.bsb_number;
    } else if (mainKey === "tax_number") {
      return Helper.BeneficiaryFields.tax_number;
    } else if (mainKey === "suffix") {
      return Helper.BeneficiaryFields.suffix;
    } else if (mainKey === "eight_digit_code") {
      return Helper.BeneficiaryFields.eight_digit_code;
    } else if (mainKey === "physical_address") {
      return Helper.BeneficiaryFields.physical_address;
    } else if (mainKey === "sort_code") {
      return Helper.BeneficiaryFields.sort_code;
    } else if (mainKey === "cash_pickup_provider") {
      return Helper.BeneficiaryFields.cash_pickup_provider;
    } else if (mainKey === "cash_pickup_location") {
      return Helper.BeneficiaryFields.cash_pickup_location;
    } else if (mainKey === "mobile_money_provider") {
      return Helper.BeneficiaryFields.mobile_money_provider;
    } else if (mainKey === "mobile_money_msisdn") {
      return Helper.BeneficiaryFields.mobile_money_msisdn;
    } else if (mainKey === "nickname") {
      return Helper.BeneficiaryFields.nick_name;
    } else if (mainKey === "ewallet_id") {
      return Helper.BeneficiaryFields.ewallet_id;
    } else if (mainKey === "relationship") {
      return Helper.BeneficiaryFields.relationship;
    } else {
      return 0;
    }
  }

  static findBDByMainKey(beneficiarySettings, mainKey) {
    var currentBS = beneficiarySettings.filter(beneficiarySetting => beneficiarySetting.mainKey === mainKey);
    if (Object.keys(currentBS).length > 0) {
      return currentBS[0];
    }
    return {};
  }

  static validateBSField(form, beneficiarySettings, rule, value, stateValue, callback){
    Log.d(TAG, "validateBSField() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value) + " typeof value=" + (typeof value));
    //Log.d(TAG, "validateBSField() -- beneficiarySettings=" + JSON.stringify(beneficiarySettings));
    var currentBS0 = this.findBDByMainKey(beneficiarySettings, rule.field);
    //Log.d(TAG, "validateBSField() -- currentBS=" + JSON.stringify(currentBS0));
    // means BS has been found
    if (Object.keys(currentBS0).length > 0) {
      if ((typeof value) === 'object') { // this should be a dropdown
        Log.d(TAG, "validateBSField() -- dropdown at mainKey=" + currentBS0.mainKey + "value=" + JSON.stringify(value) + " state value=" + JSON.stringify(stateValue));
        try {
          if (stateValue === null && currentBS0.required === true) {
            Log.d(TAG, "validateBSField() -- dropdown at mainKey=" + currentBS0.mainKey + " value is empty");
            callback("Please provide " + currentBS0.label);
          } else {
            var currentKeyObj = JSON.parse(value.key);
            var currentKeyID = currentKeyObj.id
            if (currentKeyID === "0" && currentBS0.required === true) {
              Log.d(TAG, "validateBSField() -- dropdown at mainKey=" + currentBS0.mainKey + " ID is 0");
              callback("Please provide " + currentBS0.label);
            } else {
              callback()
            }
          }
        } catch(e) {
          callback()
        }
      } else { // this should be an input
        var currentKeyValue = value;
        Log.d(TAG, "validateBSField() -- currentKeyValue=" + currentKeyValue + " at mainkey=" + rule.field);
        if ((currentKeyValue === undefined && currentBS0.required === true) || (Helper.isEmpty(currentKeyValue) && currentBS0.required === true)) {
          callback("Please provide " + currentBS0.label);
        } else if ((Helper.containsPhoneNumbers(rule.field) && currentBS0.required === true) || (Helper.containsPhoneNumbers(rule.field) && currentKeyValue)) {
          if (isNaN(value)) {
            callback("Mobile number should contain valid numbers");
          } else {
            callback();
          }
        } else {
          if (rule.field === "sort_code" && currentKeyValue.length >= 8) {
            callback("Please provide " + currentBS0.label + " value with no more than 8 characters");
          } else {
            callback()
          }
        }
      }
    }
  }

  static validateParentChildBS(form, parentMainKey, childMainKey, beneficiarySettings, callback) {
    Log.d(TAG, "validateParentChildBS() -- called ..")
    var currentParentBS = this.findBDByMainKey(beneficiarySettings, parentMainKey);
    var currentChildBS = this.findBDByMainKey(beneficiarySettings, childMainKey);
    if (Object.keys(currentParentBS).length > 0) {
      try {
        var currentParent = form.getFieldValue(parentMainKey);
        var currentKeyObj = JSON.parse(currentParent.key);
        var currentKeyID = currentKeyObj.id
        var hasNoParentIDAndRequired = (currentKeyID === "0" && currentParentBS.required === true);
        if (Object.keys(currentChildBS).length > 0) {
          // check parent and child
          var currentChild = form.getFieldValue(childMainKey);
          var hasNoChildAndRequired = (Helper.isEmpty(currentChild) && currentChildBS.required === true);

          /*Log.d(TAG, "validateParentChildBS() -- currentKeyID=" + currentKeyID + " currentChild=" + currentChild)
          Log.d(TAG, "validateParentChildBS() -- currentChild is empty=" + Helper.isEmpty(currentChild))
          Log.d(TAG, "validateParentChildBS() -- currentParentBS.required =" + currentParentBS.required)
          Log.d(TAG, "validateParentChildBS() -- currentChildBS.required =" + currentChildBS.required)
          Log.d(TAG, "validateParentChildBS() -- hasNoParentIDAndRequired=" + hasNoParentIDAndRequired + " hasNoChildAndRequired=" + hasNoChildAndRequired)*/
          if (hasNoParentIDAndRequired && hasNoChildAndRequired) {
            Log.d(TAG, "validateParentChildBS() -- show error 1 at parent=" + parentMainKey)
            callback("Please provide " + currentParentBS.label + " or " + currentChildBS.label);
          } else {
            Log.d(TAG, "validateParentChildBS() -- show no error 1 at parent=" + parentMainKey)
            callback(null)
          }
        } else {
          // check parent only
          if (hasNoParentIDAndRequired) {
            Log.d(TAG, "validateParentChildBS() -- show error 2 at parent=" + parentMainKey)
            callback("Please provide " + currentParentBS.label);
          } else {
            Log.d(TAG, "validateParentChildBS() -- show no error 2 at parent=" + parentMainKey)
            callback(null)
          }
        }
      } catch(e) {
        Log.d(TAG, "validateParentChildBS() -- show error 3 at parent=" + parentMainKey)
        callback(null)
      }
    } else {
      if (Object.keys(currentChildBS).length > 0) {
        var currentChildOnly = form.getFieldValue(childMainKey);
        var hasNoChildAndRequiredOnly = (Helper.isEmpty(currentChildOnly) && currentChildBS.required === true);
        // check child only
        if (hasNoChildAndRequiredOnly) {
          callback("Please provide " + currentChildBS.label);
        } else {
          callback(null)
        }
      } else {
        callback(null)
      }
    }
  }

  static validateParentChildBSByID(form, parentMainKey, childMainKey, beneficiarySettings, callback) {
    Log.d(TAG, "validateParentChildBSByID() -- called ..")
    var currentParentBS = this.findBDByMainKey(beneficiarySettings, parentMainKey);
    var currentChildBS = this.findBDByMainKey(beneficiarySettings, childMainKey);
    if (Object.keys(currentParentBS).length > 0) {
      try {
        var currentParent = form.getFieldValue(parentMainKey);
        var currentKeyObj = JSON.parse(currentParent.key);
        var currentKeyID = currentKeyObj.id

        var hasNoParentIDAndRequired = (currentKeyID === "0" && currentParentBS.required === true);
        var hasNoParentID = currentKeyID === "0";

        if (Object.keys(currentChildBS).length > 0) {
          // check parent and child
          var currentChild = form.getFieldValue(childMainKey);
          var hasNoChild = false;
          var validate = false
          try {
            var currentCKeyObj = JSON.parse(currentChild.key);
            var currentCKeyID = currentCKeyObj.id
            hasNoChild = currentCKeyID === "0";
            validate = !hasNoParentID && hasNoChild;
          } catch (error) {
            hasNoChild = Helper.isEmpty(currentChild)
            validate = (hasNoParentID && !hasNoChild) || (!hasNoParentID && hasNoChild)
          }

          Log.d(TAG, "validateParentChildBSByID() -- hasNoParentID=" + hasNoParentID + " hasNoChild=" + hasNoChild)
          if (validate) {
            Log.d(TAG, "validateParentChildBSByID() -- show error 1 at parent=" + parentMainKey)
            callback("Please provide " + currentChildBS.label + " with corresponding " + currentParentBS.label);
          } else {
            Log.d(TAG, "validateParentChildBSByID() -- show no error 1 at parent=" + parentMainKey)
            callback(null)
          }

        } else {
          // check parent only
          if (hasNoParentIDAndRequired) {
            Log.d(TAG, "validateParentChildBSByID() -- show error 2 at parent=" + parentMainKey)
            callback("Please provide " + currentParentBS.label);
          } else {
            Log.d(TAG, "validateParentChildBSByID() -- show no error 2 at parent=" + parentMainKey)
            callback(null)
          }
        }
      } catch(e) {
        Log.d(TAG, "validateParentChildBSByID() -- show error 3 at parent=" + parentMainKey)
        callback(null)
      }
    } else {
      // if no options here, no reason to check the child value
      callback(null)
    }
  }

  static setError(form, field, message) {
    form.setFields({
      [field]: {
        errors: [new Error(message)],
      },
    });
  }
  static isEmpty(value){
    return (value == null || value.length === 0);
  }

  static isValidAmount(amount) {
    if (amount !== undefined && amount !== null) {
      var regex1 = new RegExp("^[+]?([1-9][0-9]*(?:[.][0-9]{0,2})?|0*.0*[1-9][0-9]{0,2})$");
      var regex2 = new RegExp("^(?=.*[0-9])^\\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)?(\\.[0-9]{1,2})?$");
      var testAmount = regex1.test(amount) || regex2.test(amount);
      Log.d(TAG, "isValidAmount() -- testAmount=" + testAmount + " amount=" + amount);
      if (testAmount) {
        return true;
      } else {
        return false;
      }
    } else {
      Log.d(TAG, "isValidAmount() -- amount is null");
    }
    return false;
  }

  static isValidWholeAmount(amount) {
    if (amount !== undefined && amount !== null) {
      var regex1 = new RegExp("^[0-9]+$");
      var regex2 = new RegExp("^(?=.*[0-9])^\\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)+$");
      var testAmount = regex1.test(amount) || regex2.test(amount);
      Log.d(TAG, "isValidWholeAmount() -- testAmount=" + testAmount + " amount=" + amount);
      if (testAmount) {
        return true;
      } else {
        return false;
      }
    } else {
      Log.d(TAG, "isValidWholeAmount() -- amount is null");
    }
    return false;
  }

  static showUserStatus(status) {
    if (status === "P") {
      return "Pending"
    } else if (status === "H") {
      return "Paused"
    } else if (status === "A") {
      return "Approved"
    } else if (status === "X") {
      return "Cancelled"
    } else if (status === "B") {
      return "Blacklisted"
    } else if (status === "D") {
      return "Duplicate"
    } else {
      return "Unavailable"
    }
  }

  static showStatus(status) {
    if (status === "P") {
      return "Pending"
    } else if (status === "A") {
      return "Authorised"
    } else if (status === "C") {
      return "Completed"
    } else if (status === "R") {
      return "Reversed"
    } else if (status === "S") {
      return "Quoted"
    } else if (status === "E") {
      return "Expired"
    } else {
      return "Cancelled"
    }
  }

  static hasValidDate(id) {
    var year = id.substring(0, 2);
    var month = id.substring(2, 4);
    var day = id.substring(4, 6);

    var tryDate1 = "19" + year + "-" + month + "-" + day;
    var tryDate2 = "20" + year + "-" + month + "-" + day;

    const pattern = "^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$";

    var date1Match = new RegExp(pattern).test(tryDate1);
    var date2Match = new RegExp(pattern).test(tryDate2);

    Log.d(TAG, "hasValidDate() -- tryDate1=" + tryDate1 + " matches=" + date1Match);
    Log.d(TAG, "hasValidDate() -- tryDate2=" + tryDate2 + " matches=" + date2Match);

    return  date1Match || date2Match;
  }

  static isValidNumberWith13Digits(id) { return id.length === 13 && !isNaN(id); }

  static isNormalInteger(str) { return /^\+?(0|[1-9]\d*)$/.test(str); }

  static isOdd(number) { return number % 2 !== 0; }

  static isValidSAID(id) {
    var result = false;
    Log.d(TAG, "isValidSAID() -- id=" + id);
    Log.d(TAG, "isValidSAID() -- isValidNumberWith13Digits=" + Helper.isValidNumberWith13Digits(id));
    Log.d(TAG, "isValidSAID() -- valid date=" + Helper.hasValidDate(id));
    if (Helper.isValidNumberWith13Digits(id) && Helper.hasValidDate(id)) {
        var sum = 0;
        for (var index = id.length - 1; index >= 0; index--) {
            var currentChar = id.charAt(index);
            var digit = parseInt(currentChar, 10);
            Log.d(TAG, "isValidSAID() -- index=" + index + " currentChar=" + currentChar);
            if (Helper.isOdd(index)) {
                Log.d(TAG, "isValidSAID() -- odd index=" + index);
                digit = digit * 2;
                if (digit > 9) {
                    var subSum = 0;
                    while (digit > 0) {
                        subSum += digit % 10;
                        digit = parseInt(digit / 10, 10);
                        Log.d(TAG, "isValidSAID() -- digit=" + digit);
                    }
                    digit = subSum;
                }
            }
            sum += digit;
            Log.d(TAG, "isValidSAID() -- sum=" + sum);
        }
        result = sum % 10 === 0;
        Log.d(TAG, "isValidSAID() -- result=" + result);
    }
    return result;
  }

  static get baseCountryIsSA () {
    const manageUrls = new ManageUrls()
    return manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA
  }

  static pendingSelfiesCount(user) {
    if(!Helper.baseCountryIsSA) {
      return 1
    }

    if (Object.keys(user).length > 0) {
      if (Object.keys(user.data.attributes.files).length > 0) {
        if (user.data.attributes.files.SL) {
          if (user.data.attributes.files.SL.P) {
            return user.data.attributes.files.SL.P;
          }
        }
      }
    }
    return -1;
  }

  static acceptedSelfiesCount(user) {
    if(!Helper.baseCountryIsSA) {
      return 1
    }
      
    if (Object.keys(user).length > 0) {
      if (Object.keys(user.data.attributes.files).length > 0) {
        if (user.data.attributes.files.SL) {
          if (user.data.attributes.files.SL.A) {
            return user.data.attributes.files.SL.A;
          }
        }
      }
    }
    return -1;
  }

  static acceptedIDDocumentsCount(user) {
    if(!Helper.baseCountryIsSA) {
      return 5
    }

    var count = -1;
    if (Object.keys(user).length > 0) {
      if (Object.keys(user.data.attributes.files).length > 0) {
        count = 0
        if (user.data.attributes.files.SI) {
          if (user.data.attributes.files.SI.A) {
            if (user.data.attributes.files.SI.A > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.SP) {
          if (user.data.attributes.files.SP.A) {
            if (user.data.attributes.files.SP.A > 0) {
              count+=1;
            }
          }
        }


        if (user.data.attributes.files.FP) {
          if (user.data.attributes.files.FP.A) {
            if (user.data.attributes.files.FP.A > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.AP) {
          if (user.data.attributes.files.AP.A) {
            if (user.data.attributes.files.AP.A > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.ET) {
          if (user.data.attributes.files.ET.A) {
            if (user.data.attributes.files.ET.A > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.FI) {
          if (user.data.attributes.files.FI.A) {
            if (user.data.attributes.files.FI.A > 0) {
              count+=1;
            }
          }
        }
      }
    }
    return count;
  }


  static pendingIDDocumentsCount(user) {
    if(!Helper.baseCountryIsSA) {
      return 4
    }

    var count = -1;
    if (Object.keys(user).length > 0) {
      if (Object.keys(user.data.attributes.files).length > 0) {
        count = 0
        if (user.data.attributes.files.SI) {
          if (user.data.attributes.files.SI.P) {
            if (user.data.attributes.files.SI.P > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.SP) {
          if (user.data.attributes.files.SP.P) {
            if (user.data.attributes.files.SP.P > 0) {
              count+=1;
            }
          }
        }


        if (user.data.attributes.files.FP) {
          if (user.data.attributes.files.FP.P) {
            if (user.data.attributes.files.FP.P > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.AP) {
          if (user.data.attributes.files.AP.P) {
            if (user.data.attributes.files.AP.P > 0) {
              count+=1;
            }
          }
        }

        if (user.data.attributes.files.ET) {
          if (user.data.attributes.files.ET.P) {
            if (user.data.attributes.files.ET.P > 0) {
              count+=1;
            }
          }
        }
      }
    }
    return count;
  }

  static emailMask(email) {
  	var maskedEmail = email.replace(/([^@.])/g, "*").split('');
  	var previous	= "";
  	for(var i=0;i<maskedEmail.length;i++){
  		if (i<=1 || previous === "." || previous === "@"){
  			maskedEmail[i] = email[i];
  		}
  		previous = email[i];
  	}
  	return maskedEmail.join('');
  }

  static isMobile() {
    var windowWidth = window.screen.width < window.outerWidth ?
                  window.screen.width : window.outerWidth;
    return windowWidth < 1024;
  }
}

export default Helper;
