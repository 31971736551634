import React from 'react';
import '../styles/index.css'
import generalError from '../images/general_error.png';

class ErrorLayout extends React.Component {

  render() {
    return(
      <div className="gen-error-parent">
        <img className="gen-error-img" src={generalError} alt="general-error"/>
        <span>{this.props.userReducer.error}</span>
      </div>
    )
  }
}

export default ErrorLayout;
