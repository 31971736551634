import React from 'react';
import Log from '../utils/log.js';
import SmartLogin from './Login.js';
import Helper from '../utils/helper.js';
import { Layout, Menu, Icon, message, Dropdown } from 'antd';
import {
  fetchUserDetails,
  requestAccountUpdate,
  requestUploadFile,
  requestCardDetails,
  showCardList,
  deleteCard,
  requestSubmitCode,
  requestPhoneConfirmation,
  requestEmailConfirmation,
  requestLimit,
  requestCountries
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../styles/user.css'
import {Doughnut} from 'react-chartjs-2';

const { Content } = Layout;
const TAG = "SmartLimits";

class Limits extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    var cookies = {};

    if (props.cookies !== undefined) {
      cookies = props.cookies;
    }

    this.state = {
      cookies: cookies,
      headerKey: "1"
    }
  }

  renderBody() {
    // if (this.props.cookies.cookies.access) {
    if (!!this.props.authReducer.accessToken) {
      return (this.renderContent())
    } else {
      return (<SmartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>)
    }
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  render() {
    Log.d(TAG, "render() -- called ..");
    Log.d(TAG, "render() -- current props=" + JSON.stringify(this.props));
    return (
      <div className="user-limit-content-container">
        {this.renderWholeContent()}
      </div>
    )
  }

  renderWholeContent() {
    return (
      this.renderBody()
    )
  }

  renderContent() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      Log.d(TAG, "renderContent() -- render user")
      return this.renderWithContent()
    }
    Log.d(TAG, "renderContent() -- render no user")
    return this.renderNoContent()
  }

  renderWithContent() {
    Log.d(TAG, "renderWithContent() -- user=" + JSON.stringify(this.props.userReducer.user))
    return (
        <FICALimit {...this.props}/>
      )
  }

  renderNoContent() {
    return (
      <Content className="user-content-container"/>
    )
  }

  componentWillMount() {
    Log.d(TAG, "componentWillMount() -- called ..");
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.accessToken !== '') {
      Log.d(TAG, "componentWillMount() -- request a user");
      // Log.d(TAG, "componentWillMount() -- access=" + this.props.cookies.cookies.access);
      Log.d(TAG, "componentWillMount() -- access=" + this.props.authReducer.accessToken);
      // Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.cookies.cookies.access));
      Log.d(TAG, "componentWillMount() -- cookies=" + JSON.stringify(this.props.authReducer.accessToken));
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, null);
      this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, null);
    }
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- called ..");
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.userReducer.loginMessage !== null && prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage) {
      Log.d(TAG, "componentDidUpdate() -- show login .. ")
      Helper.clearCookies(this.props.cookies)
      this.props.history.push({
        pathname: "/login"
      });
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
    }

    if (prevProps.userReducer.reload !== this.props.userReducer.reload) {
      if (this.props.userReducer.reload) {
        window.location.reload();
      }
    }
  }
}

class FICALimit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      limitOptions : ["Day", "Month", "Year"],
      chosenLimitOption: 0,
      note: "",
      cumulative: "0.00",
      remaining: "0.00",
      total: "0.00",
      cumulativeP: 0,
      remainingP: 0,
    }
  }

  componentDidMount() {
    // if (this.props.cookies.cookies.access) {
    if (!!this.props.authReducer.accessToken) {
      if (this.props.userReducer.user.data.attributes.user_sub_status === "AL" || this.props.userReducer.user.data.attributes.user_sub_status === "AF") {
        this.setState({
          note: "Based on your KYC status, you are eligible to send money based on your limit. Please do not hesitate to contact us for more details of your usage limits."
        })
      } else {
        this.setState({
          note: "Your KYC status is unavailable because your registration is pending approval"
        })
      }
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "user-profile");
    }
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.userReducer.limit).length > 0 && this.props.userReducer.limit !== prevProps.userReducer.limit) {
      var cpd = this.props.userReducer.limit.data.attributes.cumulative_per_day;
      var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
      this.setState({
        cumulative : cpd,
        remaining: String(Number(tpd) - Number(cpd)),
        cumulativeP: (Number(cpd) / Number(tpd)) * 100,
        remainingP: ((Number(tpd) - Number(cpd)) / Number(tpd)) * 100
      })
    }
  }

  showSubStatus(subStatus) {
    if (subStatus === "AL") {
      return "EcoCash Lite"
    } else if (subStatus === "AF") {
      return "EcoCash Full"
    } else {
      return "Not Available"
    }
  }

  showLimit() {
    Log.d(TAG, "showLimit() -- limit=" + JSON.stringify(this.props.userReducer.limit))
    Log.d(TAG, "showLimit() -- chosenLimitOption=" + this.state.chosenLimitOption)
    if (Object.keys(this.props.userReducer.limit).length > 0) {

      var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
      var tpm = this.props.userReducer.limit.data.attributes.total_per_month;
      var tpy = this.props.userReducer.limit.data.attributes.total_per_year;

      switch (this.state.chosenLimitOption) {
        case 0:
          Log.d(TAG, "showLimit() -- day limit")
          return tpd + " per day"
        case 1:
          Log.d(TAG, "showLimit() -- month limit")
          return tpm + " per month"
        case 2:
          Log.d(TAG, "showLimit() -- year limit")
          return tpy + " per year"
        default:
          Log.d(TAG, "showLimit() -- unavailable limit")
          return "Unavailable"
      }
    } else {
      return "Unavailable"
    }
  }

  renderLimitOptionsParent(){
    return (
      <Menu onClick={this.onChangeLimitOption}>
        {this.renderLimitOptions()}
      </Menu>
    )
  }

  renderLimitOptions(){
    return this.state.limitOptions.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
        );
    })
  }

  onChangeLimitOption = (event) => {
    Log.d(TAG, "onChangeLimitOption() -- event.key=" + event.key);
    var cpd = this.props.userReducer.limit.data.attributes.cumulative_per_day;
    var cpm = this.props.userReducer.limit.data.attributes.cumulative_per_month;
    var cpy = this.props.userReducer.limit.data.attributes.cumulative_per_year;

    var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
    var tpm = this.props.userReducer.limit.data.attributes.total_per_month;
    var tpy = this.props.userReducer.limit.data.attributes.total_per_year;

    switch (Number(event.key)) {
      case 0:
        this.setState({
          cumulative : cpd,
          remaining: String(Number(tpd) - Number(cpd)),
          cumulativeP: (Number(cpd) / Number(tpd)) * 100,
          remainingP: ((Number(tpd) - Number(cpd)) / Number(tpd)) * 100
        })
        break;
      case 1:
        this.setState({
          cumulative : cpm,
          remaining: String(Number(tpm) - Number(cpm)),
          cumulativeP: (Number(cpm) / Number(tpm)) * 100,
          remainingP: ((Number(tpm) - Number(cpm)) / Number(tpm)) * 100
        })
        break;
      case 2:
        this.setState({
          cumulative : cpy,
          remaining: String(Number(tpy) - Number(cpy)),
          cumulativeP: (Number(cpy) / Number(tpy)) * 100,
          remainingP: ((Number(tpy) - Number(cpy)) / Number(tpy)) * 100
        })
        break;
      default:
        this.setState({
          cumulative : "0.00",
          remaining: "0.00",
          cumulativeP: 0,
          remainingP: 0
        })
        break;
    }

    this.setState({
      chosenLimitOption : Number(event.key)
    })
  }

  renderLimitOptionName() {
    return (
      <div>
        {this.state.limitOptions[this.state.chosenLimitOption]}
      </div>
    )
  }

  render() {
    return (
      <div className="fica-box">
        <h3 className="user-title" id="fica-limit">KYC limit</h3>
        <hr></hr>
        <div className="user-fica-parent">
          <b className="user-fica-text">Status: {this.showSubStatus(this.props.userReducer.user.data.attributes.user_sub_status)}</b>
          <Dropdown
            className="user-tf-2"
            trigger={['click']}
            overlay={this.renderLimitOptionsParent()}>
            <div className="dropdown-item">
              <div>
                {this.renderLimitOptionName()}
              </div>
              <Icon type="down" />
            </div>
          </Dropdown>
          <b className="user-fica-limit-text">Limit of ZAR {this.showLimit()}</b>
            <Doughnut
              data={{
              labels: ["Usage: " + this.state.cumulativeP.toFixed(2) + "%", "Remaining: " + this.state.remainingP.toFixed(2) + "%"],
              datasets: [
                {
                  data: [this.state.cumulativeP.toFixed(2), this.state.remainingP.toFixed(2)],
                  backgroundColor: ["#57a6d0", "white"],
                  borderColor: 'transparent'
                }
              ]}}/>
          </div>
          <div className="user-fica-parent-child-details">
            <div className="user-fica-parent-details">
              <div className="user-fica-parent-child-details">
                <b className="user-fica-text">Used {this.state.limitOptions[this.state.chosenLimitOption]}</b>
                <h2 className="user-fica-text">ZAR {Number(this.state.cumulative).toLocaleString()}</h2>
              </div>
              <div className="user-fica-parent-child-details">
                <b className="user-fica-text">Remaining {this.state.limitOptions[this.state.chosenLimitOption]}</b>
                <h2 className="user-fica-text">ZAR {Number(this.state.remaining).toLocaleString()}</h2>
              </div>
            </div>
            <b className="user-fica-note-text">{this.state.note}</b>
          </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestAccountUpdate,
    requestUploadFile,
    requestCardDetails,
    showCardList,
    deleteCard,
    requestSubmitCode,
    requestPhoneConfirmation,
    requestEmailConfirmation,
    requestLimit,
    requestCountries
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(Limits)
