import React from 'react';
import Log from '../utils/log.js';
import { Layout, Table, message, Button, Menu } from 'antd';
import {
  requestAgents
} from '../actions';
import '../styles/dashboard.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import config from '../env-config.json'

const { Content } = Layout;
const TAG = "SmartAgents";

class Agents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentLink: config.api_url + "agents/?filter[county]=&page=1",
      loading : false,
      loadingTip : "",
      columns : [{
        title: 'Name',
        dataIndex: 'first_name',
        key: 'first_name',
      }, {
        title: 'Surname',
        dataIndex: 'surname',
        key: 'surname',
      }, {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
      }, {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
      }],
      provinces : [
        { "key": "0", "code": "", "value": "All Provinces" },
        { "key": "1", "code": "EC", "value": "Eastern Cape" },
        { "key": "2", "code": "FS", "value": "Free State" },
        { "key": "3", "code": "GT", "value": "Gauteng" },
        { "key": "4", "code": "NL", "value": "KwaZulu Natal" },
        { "key": "5", "code": "LP", "value": "Limpopo" },
        { "key": "6", "code": "MP", "value": "Mpumalanga" },
        { "key": "7", "code": "NW", "value": "North West" },
        { "key": "8", "code": "NC", "value": "Northern Cape" },
        { "key": "9", "code": "WC", "value": "Western Cape" }
      ],
      chosenProvince: 0,
    }
  }

  componentDidMount() {
    this.props.requestAgents(this.state.currentLink);
  }

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (prevProps.agentsReducer.agents !== this.props.agentsReducer.agents) {
      Log.d(TAG, "componentDidUpdate() -- agents=" + JSON.stringify(this.props.agentsReducer.agents))
      this.setState({
        loading : false,
        loadingTip : "",
        currentLink : this.props.agentsReducer.url
      })
    }

    /*if (this.props.agentsReducer.agents.length > 0 && prevProps.agentsReducer.agents !== this.props.agentsReducer.agents) {

    }*/
  }

  onClickCounty = (e) => {
    Log.d(TAG, "onClickCounty() -- key=" + e.key);
    for (var i = 0; i < this.state.provinces.length; i++) {
      if (this.state.provinces[i].key === e.key) {
        this.requestAgents(config.api_url + "agents/?filter[county]=" + this.state.provinces[i].code + "&page=1")
        return;
      }
    }
  }

  render() {
    return (
      <Layout>
        <Content style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',flexWrap: 'wrap' }}>
          <Menu
            onClick={this.onClickCounty}
            style={{ width: 256 }}
            defaultSelectedKeys={['0']}
            mode="inline">
            <Menu.Item key={this.state.provinces[0].key}>{this.state.provinces[0].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[1].key}>{this.state.provinces[1].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[2].key}>{this.state.provinces[2].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[3].key}>{this.state.provinces[3].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[4].key}>{this.state.provinces[4].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[5].key}>{this.state.provinces[5].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[6].key}>{this.state.provinces[6].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[7].key}>{this.state.provinces[7].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[8].key}>{this.state.provinces[8].value}</Menu.Item><Menu.Divider/>
            <Menu.Item key={this.state.provinces[9].key}>{this.state.provinces[9].value}</Menu.Item><Menu.Divider/>
          </Menu>

          <div className="agent-table-area">
            <Table
              className="agent-table"
              pagination = {{hideOnSinglePage : true}}
              bordered
              dataSource={this.props.agentsReducer.agents}
              columns={this.state.columns}
              style={{ width: '100%', marginTop: '20px' }}/>
              <div className="pagination-box">
                <div className = "pagination-box-left">
                  {this.showPrevButton()}
                    {this.showFirstButton()}
                </div>
                <div className = "pagination-box-middle">
                  {this.renderCurrentLink()}
                </div>
                <div className = "pagination-box-right">
                    {this.showLastButton()}
                    {this.showNextButton()}
                </div>
              </div>
          </div>
        </Content>
      </Layout>
    )
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    if (value) {
      this.requestAgents(config.api_url + "agents/?filter[q]=" + value + "&page=1")
    } else {
      this.requestAgents(config.api_url + "agents/?page=1")
    }

  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (<span className="agent-page-text">Page {this.state.currentLink.slice(this.state.currentLink.lastIndexOf("=") + 1, this.state.currentLink.length)} of {this.showLastPage()} pages</span>)
    }
    return (<div/>)
  }

  showLastPage() {
    if (this.props.agentsReducer.last !== null) {
      return (
        <span>{this.props.agentsReducer.last.slice(this.props.agentsReducer.last.lastIndexOf("=") + 1, this.props.agentsReducer.last.length)}</span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  showFirstButton() {
    if (this.props.agentsReducer.first !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestAgents(this.props.agentsReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.agentsReducer.last !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestAgents(this.props.agentsReducer.last)}>Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.agentsReducer.prev !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestAgents(this.props.agentsReducer.prev)}>Prev</Button>
      )
    }else {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestAgents(this.props.recipientReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.agentsReducer.next !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestAgents(this.props.agentsReducer.next)}>Next>></Button>
      )
    }
  }

  requestAgents(url) {
    this.setState({
      loading : true,
      loadingTip : "Fetching agents .."
    })
    this.props.requestAgents(url);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestAgents
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(Agents)
