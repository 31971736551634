import React from "react";
import Log from "../utils/log.js";
import { Layout, Card, Button, Skeleton } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchRemitHopeCampaigns } from "../actions/index.js";

const { Content } = Layout;
const { Meta } = Card;
const TAG = "SmartRemitHopeCategory";

const cardStyle = {
  width: 200,
  cursor: "pointer",
  alignContent: "space-between",
};

const iconDivStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const imgStyle = {
  objectFit: "cover",
  height: "160px",
};

const buttonStyle = {
  backgroundColor: "rgb(41, 108, 187)",
  borderColor: "rgb(41, 108, 187)",
  boxSizing: "border-box",
  color: "white",
  border: "1px solid rgb(41, 108, 187)",
  marginRight: 8,
};

class RemitHopeCategory extends React.Component {

  constructor(props) {
    super(props);

    const accessToken = this.props.authReducer.accessToken;
    const categoryId = this.props.match.params.id;

    this.props.fetchRemitHopeCampaigns(accessToken, categoryId)
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            {/* <h2 style={{ fontFamily: "Lato" }}>RemitHopeCategory</h2> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2 className="remit-hope-category-heading">
                  {this.props.location.state.category.name}
                </h2>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 20,
                      justifyContent: "center",
                      margin: "50px 0",
                    }}
                  >
                    {this.props.remitHopeReducer.campaigns.length > 0 ? 
                    
                      this.props.remitHopeReducer.campaigns.map((campaign => {
                          
                          const imageSrc = campaign.images.length > 0 ? campaign.images[0].src : "https://remithope.org/wp-content/uploads/2024/05/remit-hope-constant-default.jpeg"
                          
                          return (
                            <Card
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/remit-hope/categories/${this.props.location.state.category.id}/campaigns/${campaign.id}`,
                                  state: {
                                    category: this.props.location.state.category,
                                    campaign,
                                  }
                                })
                              }
                              style={cardStyle}
                              cover={<img src={imageSrc} style={imgStyle} />}
                            >
                              <Meta 
                                title={campaign.name}  
                                description={
                                  <Button
                                    style={buttonStyle}
                                    onClick={() =>
                                      this.props.history.push({
                                        pathname: `/remit-hope/categories/${this.props.location.state.category.id}/campaigns/${campaign.id}`,
                                        state: {
                                          category: this.props.location.state.category,
                                          campaign,
                                        }
                                      })
                                    }
                                  >
                                    View Campaign
                                  </Button>
                                }
                              />
                            </Card>
                          )
                      }))

                    : <Skeleton 
                        active 
                        size="large"
                        paragraph={{ rows: 12 }}
                      />
                  }
                    <br />
                  </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchRemitHopeCampaigns
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemitHopeCategory);
