import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import getQuote from '../../images/get-quote.png'
import Helper from '../../utils/helper.js'
import '../../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class SendMoney extends React.Component {

  componentDidMount() {
    document.title = "How It Works"
    window.scrollTo(0, 0)
  }

  render() {
    var classStyle = "zoom-in-layout"
    if (Helper.isMobile()) {
      classStyle = "zoom-out-layout"
    }
    return (
      <Layout className={classStyle} style={{ minWidth: '700px' }}>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="misc-top-send-money">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">HOW TO SEND MONEY</h2>
            {/* <Youtube
              videoId="xU0MGRdJBso"
            /> */}
          </div>
        </div>
        <Content>
          <div className="send-money-body">
            <div className="send-money-row-1">
              <h2 className="send-money-body-title">How to send money online</h2>
              <div className="send-money-row-1-child">
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 1</span>
                  <span className="send-money-row-1-child-body-desc">Select the country where you need to send money to.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 2</span>
                  <span className="send-money-row-1-child-body-desc">Select the service that you wish to use. Choose between Cash pickup, Bank deposit, Mobile money or Airtime top-up (Airtime top-up is only available in South Africa).</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 3</span>
                  <span className="send-money-row-1-child-body-desc">Enter the amount you want to send or you can also enter the amount you want the recipient to receive.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 4</span>
                  <span className="send-money-row-1-child-body-desc">Sign in to continue. If you are not registered, please use the sign up option to create a free Sasai Money Transfer account.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 5</span>
                  <span className="send-money-row-1-child-body-desc">Select recipient. If you are paying a new recipient, please select this and add the details. You can select recipients that you have paid previously.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 6</span>
                  <span className="send-money-row-1-child-body-desc">Select payment method. You can pay using your debit card or bank account.</span>
                </div>
              </div>
            </div>
            <div className="send-money-row-2">
              <h2 className="send-money-body-title">How to send money using the Sasai Money Transfer App</h2>
              <div className="send-money-row-2-body">
                <div className="send-money-app">
                  <span className="send-money-app-title">GET QUOTE</span>
                  <span className="send-money-app-desc">STEP 1 : Before sending money, you can get quote first by typing the amount you want to send.</span>
                  <span className="send-money-app-desc">STEP 2 : Tap arrow button to send money.</span>
                  <span className="send-money-app-desc">STEP 3 : Follow prompts and use the generated reference number to send money at your nearest Sasai Money Transfer agent. </span>
                  <span className="send-money-app-title">SEND MONEY</span>
                  <span className="send-money-app-desc">STEP 1 : To send money, type the amount to be sent to get a quote.</span>
                  <span className="send-money-app-desc">STEP 2 : On acceptance of quote, select collection point and add beneficiary details or select preloaded beneficiaries to get valid quote.</span>
                  <span className="send-money-app-desc">STEP 3 : Follow prompts and use the generated reference number to send money at your nearest EcoCash agent.</span>
                  <span className="send-money-app-desc">STEP 4 : For your planning, you can also get a quote for your planning purposes.</span>
                </div>
                <div>
                  <img className="get-quote-img" src={getQuote} alt="get-quote"/>
                </div>
              </div>
            </div>
            <div className="send-money-row-1">
              <h2 className="send-money-body-title">How to send money via Agent</h2>
              <p className="send-money-row-1-child-title">(Only available in South Africa)</p>
              <div className="send-money-row-3-child">
                <div className="send-money-row-3-child-body">
                  <span className="send-money-row-1-child-title">STEP 1</span>
                  <span className="send-money-row-3-child-body-desc">Make sure you have your I.D and proof of residence with you</span>
                </div>
                <div className="send-money-row-3-child-body">
                  <span className="send-money-row-1-child-title">STEP 2</span>
                  <span className="send-money-row-3-child-body-desc">Dial *120*10000# and follow the prompts to get a quote.</span>
                </div>
                <div className="send-money-row-3-child-body">
                  <span className="send-money-row-1-child-title">STEP 3</span>
                  <span className="send-money-row-3-child-body-desc">On acceptance of quote, choose a add beneficiary details and choose their collection point</span>
                </div>
                <div className="send-money-row-3-child-body">
                  <span className="send-money-row-1-child-title">STEP 4</span>
                  <span className="send-money-row-3-child-body-desc">You will receive a reference number which you will then share with your agent and use to send your money where it needs to be!</span>
                </div>
              </div>
              {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '60px'}}>
                <Youtube
                  style={{minWidth: '200px'}}
                  videoId="s_VaMw3yJwU"
                />
              </div> */}
            </div>
            <div className="send-money-row-2">
              <h2 className="send-money-body-title">How to send money via USSD</h2>
              <p className="send-money-row-1-child-title">(Only available in South Africa)</p>
              <div className="send-money-row-1-child">
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 1</span>
                  <span className="send-money-row-1-child-body-desc">Dial *120*10000# and follow the prompts to get a quote.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 2</span>
                  <span className="send-money-row-1-child-body-desc">On acceptance of quote, you will receive a reference number.</span>
                </div>
                <div className="send-money-row-1-child-body">
                  <span className="send-money-row-1-child-title">STEP 3</span>
                  <span className="send-money-row-1-child-body-desc">On acceptance of quote, choose a add beneficiary details and choose their collection point.</span>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(SendMoney)
