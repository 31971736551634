import React from 'react';
import { Layout, Button, Modal } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import SmartCashOutPoints from '../containers/CashOutPoints.js';
import '../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class CashOutPoints extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
    }
  }

  componentDidMount() {
    document.title = "Cash Out Points"
    window.scrollTo(0, 0)
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }} className="cash-points-body">
          <h2 style={{ fontFamily: 'Lato', color: 'black', fontSize: '48px', textAlign: 'center', marginTop: '80px'}}>Cash-Out Points</h2>
          <SmartCashOutPoints />
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', flexDirection: 'row' }}>
            <Button style={{ maxWidth: '100%', background: '#154989', marginTop: '20px', marginLeft: '8px', marginRight: '8px', border: '1px solid #154989', display: 'none'}} type="primary" onClick={this.showModal}>
              Cash Collection Points
            </Button>
            <Button style={{ maxWidth: '100%', background: '#154989', marginTop: '20px', marginLeft: '8px', marginRight: '8px', border: '1px solid #154989'}} type="primary" onClick={() => {
              this.props.history.push({
                pathname: "/find-agents/cash-in-SouthAfrica",
              });
            }}>
              Cash-In Points
            </Button>
          </div>
          <Modal
            visible={this.state.visible}
            title="Cash Collection Points in Zimbabwe"
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width={900}
            footer={null}>
            <div className="cash-out-agents"/>
          </Modal>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(CashOutPoints)
