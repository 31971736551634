import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/index.css'
import  Log from '../../utils/log.js'
import Login from '../../containers/Login.js'
import SmartBreadwinner from '../../containers/ManageBreadWinner.js';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content, Header, Footer } = Layout;
const TAG = "AddOns";

class AddOns extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "AddOns::constructor() -- props=" + JSON.stringify(props));
  }

  componentDidMount() {
    document.title = "Manage Breadwinner Policy"
    window.scrollTo(0, 0)
  }

  renderBody() {
    if (!!this.props.authReducer.accessToken) {
      return (
        <SmartBreadwinner {... this.props}/>
      )
    } else {
      return (<Login {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>)
    }
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content>
          {this.renderBody()}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(AddOns));

// export default withCookies(AddOns)
