import React from "react";
import { Layout, Button, Collapse, Icon } from "antd";
import { withCookies } from "react-cookie";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import "../../styles/dashboard.css";
import ManageUrls from "../../endpoints/manage_urls.js";
import { Link } from "react-router-dom";
import TermsUk from "./terms-uk.js";

const { Content, Header, Footer } = Layout;
const Panel = Collapse.Panel;

const customPanelStyle = {
  padding: "14px",
  fontFamily: "Lato",
  fontSize: "16px",
  boxShadow: "none",
  background: "white",
};

class TermsSelection extends React.Component {
  componentDidMount() {
    document.title = "Terms of Use";
    window.scrollTo(0, 0);
  }

  loadPrivacyPolicy = () => {
    window.open(
      encodeURI("https://" + window.location.hostname + "/privacy-policy")
    );
  };

  linkToPrivacyPolicy = (
    <Button className="transaparent-button" onClick={this.loadPrivacyPolicy}>
      www.cassavaecocash.co.za/privacy-policy
    </Button>
  );

  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <div className="terms-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">A. TERMS & CONDITIONS OF SERVICE</h2>
          </div>
        </div>
        <Content className="terms-body">{TermsOfUseContent()}</Content>
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
}

const TermsOfUseContent = () => {
  const manageUrls = new ManageUrls();
  return manageUrls.baseCountryIsSA() ? SATermsOfUse() : TermsUk();
};

const listOfPromotions = [
  {
    id: 1,
    title: "Thatha Amathousand Promotion",
    author: "Sasai Fintech",
  },
];

const SATermsOfUse = () => {
  return (
    <>
      {/* <h2 style={{ fontFamily: "Lato" }}>Selection of Terms</h2> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          padding: "50px 0px 100px",
        }}
      >
        <Collapse
          accordion
          expandIcon={({ isActive }) => <Icon type={isActive ? "" : "plus"} />}
          style={{ width: "50%" }}
        >
          <Panel
            header="General Terms and conditions of service"
            key="1"
            style={customPanelStyle}
          >
            <span>
              <Link to="/terms-of-use/za">General Terms and conditions of service</Link>
            </span>
          </Panel>
          <Panel
            header="Promotional Terms and Conditions"
            key="2"
            style={customPanelStyle}
          >
            <span>
              {listOfPromotions.map(({title, id}) => (
                <p key={`${title} ${id}`}>
                  <Link to={`/terms-of-use/promotions/${id}`}>{title}</Link>
                </p>
              ))}
            </span>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default withCookies(TermsSelection);
