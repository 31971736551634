import React from "react";
import Log from "../utils/log.js";
import { Form, Layout, Button, Input } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helper from "../utils/helper.js";
import { buyReferralVoucher } from "../actions";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "SmartVoucherDetails";

const buttonStyle = {
  backgroundColor: "rgb(41, 108, 187)",
  borderColor: "rgb(41, 108, 187)",
  boxSizing: "border-box",
  color: "white",
  border: "1px solid rgb(41, 108, 187)",
  marginRight: 8,
};

class VoucherDetails extends React.Component {
  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedVoucherDetailsForm {...this.props} />
        </Content>
      </Layout>
    );
  }
}

class VoucherDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: "",
    };
  }

  validateAmountInput = (rule, value, callback) => {
    if (Helper.isValidAmount(value)) {
      const commissionEarnedBalance =
        this.props.referralsReducer.commissions.data.attributes
          .earnings_commissions;

      const numberValue = Number(value);
      const limit = Number(commissionEarnedBalance);
      if (numberValue > limit) {
        callback("Enter amount below or equal to earned commission balance");
      }
      callback();
    } else {
      callback("Please valid amount.");
    }
  };

  onChangeAmount = (e) => {
    this.setState({
      amount: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.requestPurchaseVoucher();
      }
    });
  };

  requestPurchaseVoucher() {
    const access = this.props.authReducer.accessToken;
    const currencyId =
      this.props.referralsReducer.commissions.data.attributes
        .earnings_commissions_currency_id;
    const payload = {
      data: {
        attributes: {
          amount: this.state.amount,
          currency: {
            id: currencyId,
            type: "currencies",
          },
        },
        id: null,
        type: "referral_customer_buy_voucher",
      },
    };

    this.props.buyReferralVoucher(access, payload, () => {
      const letterCode =
      this.props.referralsReducer.commissions.data.attributes
        .earnings_commissions_currency;

      this.props.history.push({
        pathname: "/redeemed-amount-summary",
        state: {
          redeemOption: "voucher",
          amount: this.state.amount,
          letterCode,
        },
      });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const currencyLetter =
      this.props.referralsReducer.commissions.data.attributes
        .earnings_commissions_currency;
    const commissionEarned =
      this.props.referralsReducer.commissions.data.attributes
        .earnings_commissions;
    const commissionEarnedBalance =
      this.props.referralsReducer.commissions.data.meta.balance.amount;
    return (
      <div className="signup-body">
        <h2 style={{ fontFamily: "Lato" }}>Voucher Details</h2>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "0 10px" }}>
              <p>
                Total commisson earned: {currencyLetter} {commissionEarned}
              </p>
              <p>
                Commisson earned balance: {currencyLetter}{" "}
                {commissionEarnedBalance}
              </p>
            </div>
            <div>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="su-address-text">
                    <b>Enter Voucher Amount</b>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <FormItem style={{ width: "300px" }}>
                      {getFieldDecorator("amount", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter amount",
                          },
                          { validator: this.validateAmountInput },
                        ],
                        initialValue: this.state.amount,
                      })(
                        <Input
                          className="amount"
                          placeholder="Amount*"
                          onChange={this.onChangeAmount}
                        />
                      )}
                    </FormItem>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                   <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </div>
              </div>
                  <br />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedVoucherDetailsForm = Form.create()(VoucherDetailsForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      buyReferralVoucher,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherDetails);
