import React from "react";
import { Layout } from "antd";
import moment from "moment";
import Log from "../utils/log.js";
import { withCookies } from "react-cookie";
import {
  fetchUserDetails,
  showCalculation,
  showSendingCountry,
  showReceivingCountry,
  showSendingCurrency,
  showReceivingCurrency,
  showRecipient,
  showCurrentCard,
  requestTransaction
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, Form, message, Checkbox } from "antd";
import "../styles/payment.css";
import logo from "../images/sasai-money-transfer-logo.png";
import baseUrlOptions from "../endpoints/base_url_options.js";
import ManageUrls from "../endpoints/manage_urls.js";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartIntegratedForm";

class IntegratedForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
  }

  manageUrls = new ManageUrls()
  baseCountryIsSA = () => this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA;

  componentDidMount() {
    if (this.validForm()) {
    } else {
      Log.d(TAG, "renderCheckingContent() -- no details ");
      this.props.history.push({
        pathname: "/"
      });
    }

    if (Object.keys(this.props.userReducer.user).length > 0) {
      let dateString = this.props.userReducer.user.data.attributes.id_expiry_date;

      let idDocumentYear = moment(dateString)

      if(idDocumentYear.diff(new Date()) < 0) {
        message.warning("Your identification document has expired, kindly upload the new document.", 5)
      }
    }
  }

  validForm() {
    Log.d(
      TAG,
      "validForm() -- calculation=" +
        JSON.stringify(this.props.calculationReducer.calculation)
    );
    Log.d(
      TAG,
      "validForm() -- product=" +
        JSON.stringify(this.props.productServiceReducer.product)
    );
    Log.d(
      TAG,
      "validForm() -- user=" + JSON.stringify(this.props.userReducer.user)
    );
    return (
      Object.keys(this.props.calculationReducer.calculation).length > 0 &&
      Object.keys(this.props.productServiceReducer.product).length > 0 &&
      Object.keys(this.props.userReducer.user).length > 0
    );
  }

  render() {
    return (
      <Content className="payment-content-container">
        {this.renderCheckingContent()}
      </Content>
    );
  }

  renderCheckingContent() {
    Log.d(
      TAG,
      "renderCheckingContent() -- calculation=" +
        JSON.stringify(this.props.calculationReducer.calculation)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- product=" +
        JSON.stringify(this.props.productServiceReducer.product)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- recipient=" +
        Object.keys(this.props.recipientReducer.recipient).length >
        0
        ? JSON.stringify(this.props.recipientReducer.recipient)
        : "No recipient"
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- user=" +
        JSON.stringify(this.props.userReducer.user)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- sending currency=" +
        JSON.stringify(this.props.currencyReducer.sendingCurrency)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- receiving currency=" +
        JSON.stringify(this.props.currencyReducer.receivingCurrency)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- sending country=" +
        JSON.stringify(this.props.countryReducer.sendingCountry)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- receiving country=" +
        JSON.stringify(this.props.countryReducer.receivingCountry)
    );
    Log.d(
      TAG,
      "renderCheckingContent() -- payment=" +
        JSON.stringify(this.props.paymentMethodReducer.paymentMethod)
    );
    if (this.validForm()) {
      Log.d(TAG, "renderCheckingContent() -- has details ");
      return this.renderDetails();
    } else {
      Log.d(TAG, "renderCheckingContent() -- no details ");
      return this.renderNoContent();
    }
  }

  renderNoContent() {
    var missingDetails = [];
    if (Object.keys(this.props.calculationReducer.calculation).length <= 0) {
      missingDetails.push("calculation from quote");
    }
    if (Object.keys(this.props.productServiceReducer.product).length <= 0) {
      missingDetails.push("pickup point");
    }
    if (Object.keys(this.props.userReducer.user).length <= 0) {
      missingDetails.push("user details");
    }
    return (
      <div style={{ textAlign: "center" }}>
        <span>There are missing details that results to not proceeding.</span>
        <br />
        <span>The missing detail(s) is/are: {missingDetails.join(", ")}</span>
      </div>
    );
  }

  renderDetails() {
    var reverseRateStr = Number(
      this.props.calculationReducer.calculation.data.attributes.reverse_rate
    ).toLocaleString();
    Log.d(
      TAG,
      "renderDetails() -- product=" +
        JSON.stringify(this.props.productServiceReducer.product.data.attributes)
    );
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: "5%" }}>
        <img
          style={{ width: "270px", marginBottom: "20px", objectFit: "contain" }}
          src={logo}
          alt="Ecocash Logo"
        />
        {this.baseCountryIsSA() && (<h2 style={{ fontFamily: "Lato" }}>Transaction Details</h2>)}
        <span>
          Amount to pay:{" "}
          {
            this.props.calculationReducer.calculation.data.attributes
              .amount_to_pay
          }{" "}
          {this.props.currencyReducer.sendingCurrency.attributes.letter_code}
        </span>
        <span>
          {Object.keys(this.props.recipientReducer.recipient).length > 0
            ? "Beneficiary receives"
            : "You receive"}
          :{" "}
          {
            this.props.calculationReducer.calculation.data.attributes
              .recipient_amount
          }{" "}
          {this.props.currencyReducer.receivingCurrency.attributes.letter_code}
        </span>
        <span>
          Sending currency:{" "}
          {this.props.currencyReducer.sendingCurrency.attributes.name}
        </span>
        <span>
          Receiving currency:{" "}
          {this.props.currencyReducer.receivingCurrency.attributes.name}
        </span>
        <span>
          Rate: 1{" "}
          {this.props.currencyReducer.receivingCurrency.attributes.letter_code}{" "}
          = {reverseRateStr}{" "}
          {this.props.currencyReducer.sendingCurrency.attributes.letter_code}
        </span>
        <span>
          Fees: {this.props.calculationReducer.calculation.data.attributes.fees}{" "}
          {this.props.currencyReducer.sendingCurrency.attributes.letter_code}
        </span>
        {this.baseCountryIsSA() && (
          <>
            <span>
              VAT:{" "}
              {this.props.calculationReducer.calculationRequest.data.attributes.vat}{" "}
              {this.props.currencyReducer.sendingCurrency.attributes.letter_code}
            </span>
            <span>
              BoP Category:{" "}
              {Object.keys(this.props.recipientReducer.recipient).length > 0
                // ? this.props.paymentMethodReducer.reason[0]
                ? this.props.calculationReducer.calculation.data.attributes.bop_category
                : "Not Applicable"}
            </span>
            <span>
              Reason:{" "}
              {Object.keys(this.props.recipientReducer.recipient).length > 0
                ? this.props.calculationReducer.calculation.data.attributes.bop_category_name
                : "Not Applicable"}
            </span>
            <span>
              Source:{" "}
              {Object.keys(this.props.recipientReducer.recipient).length > 0
                ? this.props.paymentMethodReducer.source[1]
                : "Not Applicable"}
            </span>
            <span>
              Pickup point:{" "}
              {this.props.productServiceReducer.product.data.attributes.name}
            </span>
            <span>
              Payment method:{" "}
              {this.props.paymentMethodReducer.paymentMethod.attributes.name}
            </span>
            {this.renderRecipientDetails()}
            <h2 style={{ marginTop: "16px", fontFamily: "Lato" }}>
              Sender Details
            </h2>
            <span>
              Full name: {this.props.userReducer.user.data.attributes.first_name}{" "}
              {this.props.userReducer.user.data.attributes.last_name}
            </span>
            <span>
              User code: {this.props.userReducer.user.data.attributes.code}
            </span>
            <span>
              Country: {this.props.countryReducer.sendingCountry.attributes.name}
            </span>
            <span>
              Postcode: {this.props.userReducer.user.data.attributes.postcode}
            </span>
            <span>
              Address line 1:{" "}
              {this.props.userReducer.user.data.attributes.address_line_1}
            </span>
            <span>
              Address line 2:{" "}
              {this.props.userReducer.user.data.attributes.address_line_2}
            </span>
            <span>City: {this.props.userReducer.user.data.attributes.city}</span>
            <span>
              Province: {this.props.userReducer.user.data.attributes.county}
            </span>
          </>)  
        }
        {this.renderBody()}
      </div>
    );
  }

  renderRecipientDetails() {
    Log.d(
      TAG,
      "renderRecipientDetails() -- recipient=" +
        JSON.stringify(this.props.recipientReducer.recipient)
    );
    if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 style={{ marginTop: "16px", fontFamily: "Lato" }}>
            Recipient Details
          </h2>
          <span>
            Full name:{" "}
            {Object.keys(this.props.recipientReducer.recipient).length > 0
              ? this.props.recipientReducer.recipient.data.attributes.name
              : "Not Available"}
          </span>
          <span>
            Country:{" "}
            {this.props.countryReducer.receivingCountry.attributes.name}
          </span>
        </div>
      );
    }
    return <div />;
  }

  renderBody() {
    return <WrappedAgreementForm {...this.props} />;
  }

  componentDidUpdate(prevProps) {}
}

class AgreementForm extends React.Component {

  manageUrls = new ManageUrls();
  baseCountryIsSA = () => this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA;

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, "Received values of form: " + values);
        this.requestTransaction(false);
      }
    });
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      message.error("" + this.props.userReducer.error, 5);
    }

    if (
      prevProps.transactionReducer.transaction !==
        this.props.transactionReducer.transaction &&
      Object.keys(this.props.transactionReducer.transaction).length > 0
    ) {
      if (
        this.props.transactionReducer.transaction.hasOwnProperty("redirect")
      ) {
        window.location = this.props.transactionReducer.transaction.redirect;
      } else {
        this.props.showReceivingCountry({});
        this.props.showSendingCountry({});
        this.props.showSendingCurrency({});
        this.props.showReceivingCurrency({});
        this.props.showCalculation({});
        this.props.showCurrentCard(null, {});
        this.props.showRecipient({}, false);

        this.props.history.push({
          pathname:
            "/transaction-number/" +
            this.props.transactionReducer.transaction.data.id
        });
      }
    }
  }

  requestTransaction = isSavedQuote => {
    var attributesObj = {};
    var paymentMethodObj = {};

    attributesObj.card_type = this.props.userReducer.currentCard;

    if (isSavedQuote) {
      paymentMethodObj.data = null;
    } else {
      var paymentMethodData = {};
      paymentMethodData.id = this.props.paymentMethodReducer.paymentMethod.id;
      paymentMethodData.type = "payment_methods";
      paymentMethodObj.data = paymentMethodData;
    }

    var crsObj = {};
    var crsData = {};
    crsData.id = this.props.calculationReducer.calculation.data.id;
    crsData.type = "calculations";
    crsObj.data = crsData;

    var relationshipObj = {};
    var beneficiaryObj = {};
    if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
      Log.d(
        TAG,
        "requestTransaction() -- beneficiary ID=" +
          this.props.recipientReducer.recipient.data.id
      );
      var beneficiaryData = {};
      beneficiaryData.id = this.props.recipientReducer.recipient.data.id;
      beneficiaryData.type = "beneficiaries";
      beneficiaryObj.data = beneficiaryData;
    } else {
      beneficiaryObj.data = null;
    }

    if (
      Object.keys(this.props.recipientReducer.recipient).length > 0 &&
      this.props.paymentMethodReducer.reason.length > 0
    ) {
      attributesObj.reason_for_transfer = this.props.paymentMethodReducer.reason[0];
    } else {
      attributesObj.reason_for_transfer = null;
    }

    if (
      Object.keys(this.props.recipientReducer.recipient).length > 0 &&
      this.props.paymentMethodReducer.source.length > 0
    ) {
      attributesObj.source_of_funds = this.props.paymentMethodReducer.source[0];
    } else {
      attributesObj.source_of_funds = null;
    }

    relationshipObj.beneficiary = beneficiaryObj;
    relationshipObj.crs = crsObj;

    relationshipObj.payment_method = paymentMethodObj;
    relationshipObj.card = this.props.userReducer.cardObj;

    var dataObj = {};
    dataObj.type = "transactions";
    dataObj.id = null;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestTransaction() -- payload=" + JSON.stringify(payloadObj));
    // if (cookies.get("access")) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "requestTransaction() -- go make transaction");
      // this.props.requestTransaction(payloadObj, cookies.get("access"), null);
      this.props.requestTransaction(payloadObj, this.props.authReducer.accessToken, null);
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form
        style={{ marginTop: "16px" }}
        onSubmit={this.handleSubmit.bind(this)}
      >
      {this.baseCountryIsSA() ? (
        <>
          <div>
            <h2 style={{ fontFamily: "Lato" }}>Declaration</h2>
            <p>I, hereby declare that:</p>
            <ul>
              <li>
                I have read this document and know and understand the contents
                thereof;
              </li>
              <li>
                the information furnished above is in all respects both true and
                correct;
              </li>
              <li>
                the currency applied for will only be used for the specific
                purpose stated herein;
              </li>
              <li>
                the documentation presented in support of this application is in
                all respects authentic;
              </li>
              <li>
                I have been informed of the limit applicable to the above
                transaction and confirm that this limit will not be exceeded as a
                result of the conclusion of this transaction and;
              </li>
              <li>
                I consent to this information being provided to the South African
                Revenue Service and/or the Financial Intelligence Centre
              </li>
            </ul>
          </div>
          <FormItem>
            {getFieldDecorator("agree_terms", {
              rules: [{
                required: true, transform: value => (value || undefined),  // Those two lines
                type: 'boolean', message: "Please agree on terms" }]
            })(<Checkbox>I agree with the declaration above.</Checkbox>)}
          </FormItem>
          <p>Please Note: Reversal charges will be incurred for any recall of transactions sent to an incorrect wallet. Please check terms and conditions for more details.</p>
          <FormItem className="user-custom-btn-row">
            <Button className="user-custom-btn" type="primary" htmlType="submit">
              Pay Now
            </Button>
{/* 
              {cookies && cookies.get("browserType") === "Sasai" ? null : (
                <Button
                  className="user-custom-btn"
                  type="primary"
                  onClick={() => {
                    this.props.form.validateFieldsAndScroll((err, values) => {
                      if (!err) {
                        this.requestTransaction(true);
                      }
                    });
                  }}
                >
                  Pay Later
                </Button>
              )} */}

              <Button
                className="user-custom-btn"
                type="primary"
                onClick={this.showPrintIntegratedForm}
              >
                Print Form
              </Button>
          </FormItem>
        </>
      ) : (
        <>
        <div>
            <h2 style={{ fontFamily: "Lato" }}>Declaration</h2>
            <ul>
              <li>I have read and fully understand the contents of this document.</li>
              <li>I confirm that the information provided is accurate and complete to the best of my knowledge.</li>
              <li>The currency requested will be used solely for the purpose outlined in this application.</li>
              <li>The documents provided in support of this application have been verified as completely authentic.</li>
              <li>I understand the limit applied to this transaction and confirm that completing this transaction will not exceed that limit.</li>
              <li>I consent to this information being provided to the relevant regulatory authorities.</li>
            </ul>
          </div>
          <FormItem>
            {getFieldDecorator("agree_terms", {
              rules: [{
                required: true, transform: value => (value || undefined),  // Those two lines
                type: 'boolean', message: "Please agree on terms" }]
            })(<Checkbox>I agree with the declaration above.</Checkbox>)}
          </FormItem>
        <FormItem className="user-custom-btn-row">
            <Button className="user-custom-btn" type="primary" htmlType="submit">
              Confirm Payment
            </Button>
        </FormItem>
        </>
      )}
          
      </Form>
    );
  }

  showPrintIntegratedForm = () => {
    Log.d(
      TAG,
      "showPrintIntegratedForm() -- agree terms=" +
        this.props.form.getFieldValue("agree_terms")
    );
    if (this.props.form.getFieldValue("agree_terms")) {
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- calculation=" +
          JSON.stringify(this.props.calculationReducer.calculation)
      );
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- payment method=" +
          JSON.stringify(this.props.paymentMethodReducer.paymentMethod)
      );
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- s currency=" +
          JSON.stringify(this.props.currencyReducer.sendingCurrency)
      );
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- r currency=" +
          JSON.stringify(this.props.currencyReducer.receivingCurrency)
      );
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- s country=" +
          JSON.stringify(this.props.countryReducer.sendingCountry)
      );
      Log.d(
        TAG,
        "showPrintIntegratedForm() -- r country=" +
          JSON.stringify(this.props.countryReducer.receivingCountry)
      );

      var receivingCountryName = "Not Available";
      if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
        if (this.props.countryReducer.receivingCountry.data) {
          receivingCountryName = this.props.countryReducer.receivingCountry.data
            .attributes.name;
        } else {
          receivingCountryName = this.props.countryReducer.receivingCountry
            .attributes.name;
        }
      }

      var sendingCountryName = "Not Available";
      if (Object.keys(this.props.countryReducer.sendingCountry).length > 0) {
        sendingCountryName = this.props.countryReducer.sendingCountry.attributes
          .name;
      }

      var benName = "Not Available";

      if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
        benName = this.props.recipientReducer.recipient.data.attributes.name;
      }

      var reason = "Not Available";
      var reasonName = "Not Available";
      var source = "Not Available";

      if (this.props.paymentMethodReducer.reason) {
        if (this.props.paymentMethodReducer.reason.length > 0) {
          if (this.props.paymentMethodReducer.reason[0]) {
            reason = this.props.paymentMethodReducer.reason[0];
          }
        }
      }

      if (this.props.paymentMethodReducer.reason) {
        if (this.props.paymentMethodReducer.reason.length > 0) {
          if (this.props.paymentMethodReducer.reason[1]) {
            reasonName = this.props.paymentMethodReducer.reason[1];
          }
        }
      }

      if (this.props.paymentMethodReducer.source) {
        if (this.props.paymentMethodReducer.source.length > 0) {
          if (this.props.paymentMethodReducer.source[0]) {
            source = this.props.paymentMethodReducer.source[0];
          }
        }
      }

      var url =
        "https://" +
        window.location.hostname +
        "/form/integrated/" +
        // this.props.cookies.cookies.access +
        this.props.authReducer.accessToken +
        "/" +
        this.props.calculationReducer.calculation.data.id +
        "/" +
        this.props.paymentMethodReducer.paymentMethod.attributes.name.replace(
          /\//g,
          "or"
        ) +
        "/" +
        sendingCountryName.replace(/\//g, "or") +
        "/" +
        receivingCountryName.replace(/\//g, "or") +
        "/" +
        benName.replace(/\//g, "or") +
        "/" +
        reason +
        "/" +
        reasonName.replace(/\//g, "or") +
        "/" +
        source;
      Log.d(TAG, "showPrintIntegratedForm() -- url=" + url);
      window.open(encodeURI(url));
    } else {
      this.props.form.setFields({
        agree_terms: {
          errors: [new Error("Please agree on terms")]
        }
      });
    }
  };
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchUserDetails,
      showSendingCountry,
      showReceivingCountry,
      showSendingCurrency,
      showReceivingCurrency,
      showCalculation,
      showRecipient,
      showCurrentCard,
      requestTransaction
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

const WrappedAgreementForm = Form.create()(withCookies(AgreementForm));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegratedForm);
