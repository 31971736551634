import * as actionType from "./ActionType";
import ServerData from "../utils/server_data.js";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";

const TAG = "Actions";

export function setAccessRefreshTokens(data) {
  return {
    type: actionType.SET_ACCESS_REFRESH_TOKENS,
    accessToken: data,
    // refreshToken: data.refresh
  };
}

export function removeAccessRefreshTokens() {
  return {
    type: actionType.REMOVE_ACCESS_REFRESH_TOKENS,
  };
}

export function receiveUserDetails(data) {
  return {
    type: actionType.RECEIVE_USER_DETAILS,
    user: data,
  };
}

function showError(error) {
  return {
    type: actionType.SHOW_ERROR,
    error,
  };
}

function showLimit(limit, requestLimitSource) {
  return {
    type: actionType.SHOW_LIMIT,
    limit,
    requestLimitSource,
  };
}

export function showCurrentCard(currentCard, cardObj) {
  return {
    type: actionType.SHOW_CURRENT_CARD,
    currentCard,
    cardObj,
  };
}

function showPCAPredictError(pcaPredictAddressError) {
  return {
    type: actionType.SHOW_PCA_PREDICT_ERROR,
    pcaPredictAddressError,
  };
}

function showSuccess(success, reload) {
  return {
    type: actionType.SHOW_SUCCESS,
    success,
    reload,
  };
}

function showSignUpSuccess(signUpSuccess) {
  return {
    type: actionType.SHOW_SUCCESS_SIGN_UP,
    signUpSuccess,
  };
}

export function showCardList(currentCardIndex, cardList) {
  return {
    type: actionType.SHOW_CARDS,
    cardList,
    currentCardIndex,
  };
}

export function showTransactionList(
  next,
  prev,
  first,
  last,
  currentPage,
  totalPages,
  transactionList
) {
  return {
    type: actionType.SHOW_TRANSACTION_LIST,
    next,
    prev,
    first,
    last,
    currentPage,
    totalPages,
    transactionList,
  };
}

export function showTransactionDetails(transaction) {
  return {
    type: actionType.SHOW_TRANSACTION_DETAILS,
    transaction,
  };
}

export function updateTransactionDetails(transaction) {
  return {
    type: actionType.UPDATE_TRANSACTION_DETAILS,
    transaction,
  };
}

export function showReceivingCountry(receivingCountry) {
  return {
    type: actionType.SHOW_RECEIVING_COUNTRY,
    receivingCountry,
  };
}

export function showSendingCountry(sendingCountry) {
  return {
    type: actionType.SHOW_SENDING_COUNTRY,
    sendingCountry,
  };
}

export function showCountries(countries) {
  return {
    type: actionType.SHOW_COUNTRIES,
    countries,
  };
}

function loadLogin(loginMessage) {
  return {
    type: actionType.LOAD_LOGIN,
    loginMessage,
  };
}

export function showPaymentMethod(paymentMethod, reason, source) {
  return {
    type: actionType.SHOW_PAYMENT,
    paymentMethod,
    reason,
    source,
  };
}

export function showSendingCountries(sendingCountries) {
  return {
    type: actionType.SHOW_SENDING_COUNTRIES,
    sendingCountries,
  };
}

function showReceivingCountries(receivingCountries) {
  return {
    type: actionType.SHOW_RECEIVING_COUNTRIES,
    receivingCountries,
  };
}

function showAirtimeDataReceivingCountries(receivingCountries) {
  return {
    type: actionType.SHOW_AIRTIME_DATA_RECEIVING_COUNTRIES,
    receivingCountries,
  };
}

function showOTPCode(success) {
  return {
    type: actionType.SHOW_OTP_CODE,
    success,
  };
}

function showOTPSignIn(success, goToNames, goToReg) {
  return {
    type: actionType.SHOW_OTP_SIGN_IN,
    success,
    goToNames,
    goToReg,
  };
}

function showGuestUserTokens(tokens) {
  return {
    type: actionType.SHOW_GUEST_USER_TOKENS,
    ...tokens,
  };
}

function showAirtimeDataSendingCountries(sendingCountries) {
  return {
    type: actionType.SHOW_AIRTIME_DATA_SENDING_COUNTRIES,
    sendingCountries,
  };
}

export function showNotifications(notifications) {
  return {
    type: actionType.SHOW_NOTIFICATIONS,
    notifications,
  };
}

export function showUnreadNotificationsCount(count) {
  return {
    type: actionType.SHOW_UNREAD_NOTIFICATIONS_COUNT,
    count,
  };
}

export function showSendingCurrency(sendingCurrency) {
  return {
    type: actionType.SHOW_SENDING_CURRENCY,
    sendingCurrency,
  };
}

export function showReceivingCurrency(receivingCurrency) {
  return {
    type: actionType.SHOW_RECEIVING_CURRENCY,
    receivingCurrency,
  };
}

export function showProductsServices(products, services) {
  return {
    type: actionType.SHOW_PRODUCTS_SERVICES,
    products,
    services,
  };
}

export function showProductDetails(product) {
  return {
    type: actionType.SHOW_PRODUCT_DETAILS,
    product,
  };
}

export function showAirtimeDataProductDetails(product) {
  return {
    type: actionType.SHOW_AIRTIME_DATA_PRODUCT_DETAILS,
    product,
  };
}

export function showAirtimeDataProducts(products, loading, error) {
  return {
    type: actionType.SHOW_AIRTIME_DATA_PRODUCTS,
    products,
    loading,
    error,
  };
}

export function showCalculation(calculation) {
  return {
    type: actionType.SHOW_CALCULATION,
    calculation,
  };
}

function showRecipients(recipients, next, prev, first, last, url) {
  return {
    type: actionType.SHOW_RECIPIENTS,
    recipients,
    next,
    prev,
    first,
    last,
    url,
  };
}

export function showRecipient(recipient, updated) {
  return {
    type: actionType.SHOW_RECIPIENT,
    recipient,
    updated,
  };
}

export function showForgetPasswordData(forgetPasswordData) {
  return {
    type: actionType.SHOW_FORGET_PASSWORD_DATA,
    forgetPasswordData,
  };
}

function showBranches(branches) {
  return {
    type: actionType.SHOW_BRANCHES,
    branches,
  };
}

function showCpls(cpls) {
  return {
    type: actionType.SHOW_CPLS,
    cpls,
  };
}

export function showCplsShops(cplsShops) {
  return {
    type: actionType.SHOW_CPLS_SHOPS,
    cplsShops,
  };
}

export function showCpps(cpps) {
  return {
    type: actionType.SHOW_CPPS,
    cpps,
  };
}

export function showCalculationRequest(calculationRequest, isReverse) {
  return {
    type: actionType.SHOW_CALCULATION_REQUEST,
    calculationRequest,
    isReverse,
  };
}

function showPhoneConfirmationID(phoneConfirmationID) {
  return {
    type: actionType.SHOW_PHONE_CONFIRMATION_ID,
    phoneConfirmationID,
  };
}

function showPCAPredictAddress(pcaPredictAddress) {
  return {
    type: actionType.SHOW_PCA_PREDICT_ADDRESS,
    pcaPredictAddress,
  };
}

function showPCAPredictAddresses(pcaPredictAddresses) {
  return {
    type: actionType.SHOW_PCA_PREDICT_ADDRESSES,
    pcaPredictAddresses,
  };
}

function showAgents(agents, next, prev, first, last, url) {
  return {
    type: actionType.SHOW_AGENTS,
    agents,
    next,
    prev,
    first,
    last,
    url,
  };
}

export function showCurrentMMP(mmp) {
  return {
    type: actionType.SHOW_CURRENT_MMP,
    mmp,
  };
}

export function showCookiesNotification(cookiesNotification) {
  return {
    type: actionType.SHOW_COOKIES_NOTIFICATION,
    cookiesNotification,
  };
}

export function showCashOutPoints(cashOutPoints, next, prev, first, last, url) {
  return {
    type: actionType.SHOW_CASH_OUT_POINTS,
    cashOutPoints,
    next,
    prev,
    first,
    last,
    url,
  };
}

export function showCashInPoints(cashInPoints, next, prev, first, last, url) {
  return {
    type: actionType.SHOW_CASH_IN_POINTS,
    cashInPoints,
    next,
    prev,
    first,
    last,
    url,
  };
}

function showBwBeneficiaries(bwBeneficiaries) {
  return {
    type: actionType.SHOW_BREADWINNER_BENEFICIARIES,
    bwBeneficiaries,
  };
}

function showBwBeneficiary(bwBeneficiary) {
  return {
    type: actionType.SHOW_BREADWINNER_BENEFICIARY,
    bwBeneficiary,
  };
}

function showBwIndex(index, total) {
  return {
    type: actionType.SHOW_BREADWINNER_BENEFICIARY_INDEX,
    index,
    total,
  };
}

function showReferenceCode(referenceCode) {
  return {
    type: actionType.SHOW_REFERENCE_CODE,
    referenceCode
  };
}

function showUploadedFile(success, fileType) {
  return {
    type: actionType.SHOW_UPLOADED_FILE,
    success,
    fileType,
  };
}

export function showRemitHopeCategories(categories) {
  return {
    type: actionType.SHOW_REMIT_HOPE_CATEGORIES,
    categories,
  };
}

export function showRemitHopeTransaction(transaction) {
  return {
    type: actionType.SHOW_REMIT_HOPE_TRANSACTION,
    transaction,
  };
}

export function showRemitHopeCalculate(calculate) {
  return {
    type: actionType.SHOW_REMIT_HOPE_CALCULATE,
    calculate,
  };
}

export function showRemitHopeCampaigns(campaigns) {
  return {
    type: actionType.SHOW_REMIT_HOPE_CAMPAIGNS,
    campaigns,
  };
}

export function showRemitHopeProduct(product) {
  return {
    type: actionType.SHOW_REMIT_HOPE_PRODUCT,
    product,
  };
}

export function showReferrals(referrals) {
  return {
    type: actionType.SHOW_REFERRALS,
    referrals,
  };
}

export function showCommissions(commissions) {
  return {
    type: actionType.SHOW_COMMISSIONS,
    commissions,
  };
}

export function showCampaigns(campaigns) {
  return {
    type: actionType.SHOW_CAMPAIGNS,
    campaigns,
  };
}

export function showReferralVoucher(voucher) {
  return {
    type: actionType.SHOW_REFERRALS_VOUCHER,
    voucher,
  };
}

export function showReferralAirtimeDataBundle(airtimeDataBundle) {
  return {
    type: actionType.SHOW_REFERRALS_AIRTIME_DATA_BUNDLE,
    airtimeDataBundle,
  };
}

export function showReferralsAirtimeDataProducts(airtimeDataBundleProducts) {
  return {
    type: actionType.SHOW_REFERRALS_AIRTIME_DATA_BUNDLE_PRODUCTS,
    airtimeDataBundleProducts,
  };
}

export function requestRemitHopeTransaction(access, payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRemitHopeTransaction({}));
    return ServerData.requestRemitHopeTransactionRequest(
      access,
      payload,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRemitHopeTransaction(data));
        }
      }
    );
  };
}

export function fetchRemitHopeCalculateRequest(access, payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRemitHopeCalculate({}));
    return ServerData.requestRemitHopeCalculate(
      access,
      payload,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRemitHopeCalculate(data));
        }
      }
    );
  };
}

export function fetchRemitHopeCampaigns(access, id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRemitHopeCampaigns([]));
    return ServerData.requestRemitHopeCampaigns(
      access,
      id,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRemitHopeCampaigns(data));
        }
      }
    );
  };
}

export function fetchRemitHopeCategories(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRemitHopeCategories([]));
    return ServerData.requestRemitHopeCategories(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRemitHopeCategories(data));
        }
      }
    );
  };
}

export function fetchRemitHopeProduct(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRemitHopeProduct([]));
    return ServerData.requestRemitHopeProductDetails(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRemitHopeProduct(data));
        }
      }
    );
  };
}

export function buyReferralVoucher(access, payload, cb) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestBuyReferralVoucher(
      access,
      payload,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showReferralVoucher(data));
          cb();
        }
      }
    );
  };
}

export function buyReferralAirtimeDataBundle(access, payload, cb) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestBuyReferralAirtimeDataBundle(
      access,
      payload,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showReferralAirtimeDataBundle(data));
          cb();
        }
      }
    );
  };
}

export function fetchReferrals(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showReferrals([]));
    return ServerData.requestReferrals(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showReferrals(data));
        }
      }
    );
  };
}

export function fetchCommissions(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCommissions([]));
    return ServerData.requestCommissions(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showCommissions(data));
        }
      }
    );
  };
}

export function fetchCampaigns(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCampaigns([]));
    return ServerData.requestCampaigns(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showCampaigns(data));
        }
      }
    );
  };
}

export function fetchReferralsAirtimeDataProducts(access, mobileNumber) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showReferralsAirtimeDataProducts([]));
    return ServerData.requestReferralsAirtimeDataProducts(
      access,
      mobileNumber,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showReferralsAirtimeDataProducts(data));
        }
      }
    );
  };
}

export function fetchNotifications(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showNotifications([]));
    return ServerData.requestNotifications(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showNotifications(data));
        }
      }
    );
  };
}

export function fetchUnreadNotificationsCount(access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showUnreadNotificationsCount({}));
    return ServerData.requestUnreadNotificationsCount(
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showUnreadNotificationsCount(data));
        }
      }
    );
  };
}

export function updateReadNotifications(payload, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showUnreadNotificationsCount({}));
    return ServerData.updateReadNotifications(
      payload,
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
          dispatch(fetchUnreadNotificationsCount(access));
          dispatch(fetchNotifications(access));
        } else {
          dispatch(fetchUnreadNotificationsCount(access));
          dispatch(fetchNotifications(access));
        }
      }
    );
  };
}

export function requestLogin(
  cookies,
  payload,
  saveToken,
  successMessage,
  recaptchaResponse,
  sendingCountry
) {
  return function (dispatch) {
    dispatch(showSuccess(null, false));
    dispatch(showError(null));
    dispatch(loadLogin(null));
    return ServerData.requestLogin(
      cookies,
      payload,
      true,
      recaptchaResponse,
      (error, access, refresh, userDetails) => {
        Log.d(
          TAG,
          "requestLogin() ______" +
          JSON.stringify({ error, access, refresh, userDetails })
        );
        dispatch(setAccessRefreshTokens(access));
        if (error) {
          Log.d(TAG, "requestLogin() -- request access token error=" + error);
          dispatch(loadLogin(error)); // load login page on the other page with the error message; do not forget to clear cookies
        } else {
          // data contains (access, refresh, userData)
          dispatch(receiveUserDetails(userDetails));
          dispatch(showSuccess(successMessage, false));
          dispatch(showSendingCountry(sendingCountry));
        }
      }
    );
  };
}

export function fetchUserDetails(cookies, access, includeBW, message) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    dispatch(receiveUserDetails({}));
    return ServerData.requestUserDetails(
      cookies,
      access,
      includeBW,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(receiveUserDetails(data));
          dispatch(showSuccess(message, false));
        }
      }
    );
  };
}

export function requestAccountUpdate(cookies, user, access, payload, password) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestAccountUpdate(
      user.data.id,
      access,
      payload,
      password,
      (error, userDetails) => {
        if (error) {
          dispatch(showError(error));
        } else {
          if (password !== null) {
            // means this came from change password
            Log.d(TAG, "requestAccountUpdate() -- go to login function ..");
            var loginPayload = {
              username:
                user.data.attributes.mobile[0] +
                " " +
                user.data.attributes.mobile[1],
              password: password,
            };
            dispatch(
              requestLogin(
                cookies,
                loginPayload,
                true,
                "Password successfully updated"
              )
            );
          } else {
            // means this came from general update profile
            Log.d(TAG, "requestAccountUpdate() -- go fetch user details ..");
            dispatch(
              fetchUserDetails(
                cookies,
                access,
                false,
                "Account updated successfully"
              )
            );
          }
        }
      }
    );
  };
}

export function requestUpdatePersonalDetails(cookies, userId, access, payload, cb) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestUpdatePersonalDetails(
      userId,
      access,
      payload,
      (error) => {
        if (error) {
          dispatch(showError(error));
        } else {
          cb()
          Log.d(TAG, "requestUpdatePersonalDetails() -- go fetch user details ..");
          // dispatch(
          //   fetchUserDetails(
          //     cookies,
          //     access,
          //     false,
          //     "Account updated successfully"
          //   )
          // );
        }
      }
    );
  };
}

export function requestUpdateAddressDetails(cookies, userId, access, payload, cb) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestUpdateAddressDetails(
      userId,
      access,
      payload,
      (error) => {
        if (error) {
          dispatch(showError(error));
        } else {
          cb()
          Log.d(TAG, "requestUpdateAddressDetails() -- go fetch user details ..");
          // dispatch(
          //   fetchUserDetails(
          //     cookies,
          //     access,
          //     false,
          //     "Account updated successfully"
          //   )
          // );
        }
      }
    );
  };
}

export function requestUploadFile(url, formData, access, successMessage, fileType = "") {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    dispatch(showUploadedFile(false, ""));
    Log.d(TAG, "requestUploadFile() -- url=" + url + " formData=" + formData);
    for (var pair of formData.entries()) {
      Log.d(TAG, "requestUploadFile() -- key=" + pair[0] + " value=" + pair[1]);
    }
    return ServerData.requestFileUpload(
      url,
      formData,
      access,
      (error, data) => {
        Log.d(TAG, "requestUploadFile() -- error1=" + error);
        if (error) {
          Log.d(TAG, "requestUploadFile() -- error2=" + error);
          dispatch(showError(error));
        } else {
          Log.d(TAG, "requestUploadFile() -- data=" + JSON.stringify(data));
          dispatch(showSuccess(successMessage, false));
          dispatch(showUploadedFile(true, fileType));
        }
      }
    );
  };
}

export function requestCardDetails(id, access, currentCardIndex, cardList) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.requestCardDetails(id, access, (error, cardDetails) => {
      if (error) {
        Log.d(TAG, "requestCardDetails() -- error=" + error);
        dispatch(showError(error));
      } else {
        cardList.push(cardDetails);
        var newCardIndex = currentCardIndex + 1;
        Log.d(
          TAG,
          "requestCardDetails() -- cardList=" + JSON.stringify(cardList)
        );
        dispatch(showCardList(newCardIndex, cardList));
      }
    });
  };
}

export function requestLimit(id, access, requestLimitSource) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showLimit({}, null));
    return ServerData.requestLimit(id, access, (error, limitDetails) => {
      if (error) {
        Log.d(TAG, "requestLimit() -- error=" + error);
        dispatch(showError(error));
      } else {
        dispatch(showLimit(limitDetails, requestLimitSource));
      }
    });
  };
}

export function deleteCard(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.deleteCard(id, access, (error) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showSuccess("Card deleted successfully", false));
      }
    });
  };
}

export function requestSubmitCode(id, payloadObj, phoneConfirmationID, access) {
  Log.d(TAG, "requestSubmitCode() -- called ..");
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.requestSubmitCode(
      payloadObj,
      access,
      id,
      phoneConfirmationID,
      (error, data) => {
        if (error) {
          Log.d(TAG, "requestSubmitCode() -- error ..");
          dispatch(showError(error));
        } else {
          Log.d(TAG, "requestSubmitCode() -- success ..");
          dispatch(
            showSuccess(
              "Your mobile has been verified. Your status is pending for approval.",
              true
            )
          );
        }
      }
    );
  };
}

export function requestPhoneConfirmation(payloadObj, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showPhoneConfirmationID(null));
    return ServerData.requestPhoneConfirmation(
      payloadObj,
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showPhoneConfirmationID(data.data.id));
        }
      }
    );
  };
}

export function requestSendingCountries() {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSendingCountries([]));
    return ServerData.requestSendingCountries((error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showSendingCountries(response.data));
      }
    });
  };
}

export function requestReceivingCountries(sendingCountry) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showReceivingCountries([]));
    return ServerData.requestReceivingCountries(
      sendingCountry,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showReceivingCountries(response.data));
        }
      }
    );
  };
}

export function requestAirtimeDataSendingCountries() {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showAirtimeDataSendingCountries([]));
    return ServerData.requestAirtimeDataSendingCountries((error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showAirtimeDataSendingCountries(response.data));
      }
    });
  };
}

export function requestAirtimeDataReceivingCountries(sendingCountry) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showAirtimeDataReceivingCountries([]));
    return ServerData.requestAirtimeDataReceivingCountries(
      sendingCountry,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showAirtimeDataReceivingCountries(response.data));
        }
      }
    );
  };
}

export function requestOTPCode(senderDetails) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showOTPCode(false));
    return ServerData.requestOTPCode(
      senderDetails,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showOTPCode(true));
        }
      }
    );
  };
}

export function logout(cookies, accessToken) {
  return function (dispatch) {
    dispatch(setAccessRefreshTokens(""))
    return ServerData.logOut(
      cookies,
      accessToken,
      true,
      (error) => {
        if (error) {
          Log.d(TAG, "onLogout() -- error=" + error);
        }
      }
    );
  };
}

export function requestOTPCodeClientSignUp(payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showOTPCode(false));
    dispatch(setAccessRefreshTokens(""))
    return ServerData.requestOTPCodeClientSignUp(
      payload,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showOTPCode(true));
        }
      }
    );
  };
}

export function requestOTPSignIn(payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showOTPSignIn(false, false));
    dispatch(setAccessRefreshTokens(""));
    return ServerData.requestOTPSignIn(
      payload,
      (error, response, goToNames, goToReg) => {
        if (error) {
          dispatch(showError(error));
        } else if(goToReg) {
          dispatch(showOTPSignIn(false, false, true));
        } else if(goToNames) {
          dispatch(showOTPSignIn(false, true));
        } else if(response) {
          dispatch(setAccessRefreshTokens(response.access));
          dispatch(showOTPSignIn(true, false));
          dispatch(
            fetchUserDetails(
              {},
              response.access,
              false,
              "Account updated successfully"
            )
          );
        } else {
          dispatch(showOTPSignIn(true, false));
        }
      }
    );
  };
}

export function requestOTPRequestForLogin(username) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showOTPCode(false));
    return ServerData.requestOTPRequestForLogin(
      username,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showOTPCode(true));
        }
      }
    );
  };
}

export function requestLoginUsingOTP(payload, sendingCountry) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showOTPCode(false));
    dispatch(setAccessRefreshTokens(""))
    return ServerData.requestLoginUsingOTP(
      payload,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(setAccessRefreshTokens(response.access))
          dispatch(showOTPCode(true));
          dispatch(showSendingCountry(sendingCountry));
          dispatch(
            fetchUserDetails(
              {},
              response.access,
              false,
              "Account updated successfully"
            )
          );
        }
      }
    );
  };
}

export function removeGuestUserToken() {
  return function (dispatch) {
    dispatch(showGuestUserTokens({}));
  }
}

export function requestGuestUserTokens(senderDetails) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showGuestUserTokens({}));
    return ServerData.requestGuestUserTokens(
      senderDetails,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showGuestUserTokens(response));
        }
      }
    );
  };
}

export function requestCountries() {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCountries([]));
    return ServerData.requestCountries((error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showCountries(response));
      }
    });
  };
}

export function requestResetPassword(payloadObj, uid) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(loadLogin(null));
    return ServerData.requestResetPassword(payloadObj, uid, (error, data) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(TAG, "requestResetPassword() -- data=" + JSON.stringify(data));
        dispatch(loadLogin("Please login again."));
      }
    });
  };
}

export function requestEmailConfirmation(payloadObj, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.requestEmailConfirmation(
      payloadObj,
      access,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestEmailConfirmation() -- data=" + JSON.stringify(data)
          );
          dispatch(
            showSuccess(
              "Please check your email for complete verification.",
              false
            )
          );
        }
      }
    );
  };
}

export function requestSubmitSendingLimitSelection(
  payloadObj,
  access,
  customerCode
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.requestSubmitSendingLimitSelection(
      payloadObj,
      access,
      customerCode,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestEmailConfirmation() -- data=" + JSON.stringify(data)
          );
          dispatch(
            showSuccess(
              "Sending limit selection has been submitted successfully and currently pending for approval.",
              false
            )
          );
        }
      }
    );
  };
}

export function requestSubmitEmailCode(payloadObj, id, emailConfirmationID) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.requestSubmitEmailCode(
      payloadObj,
      id,
      emailConfirmationID,
      (error, data) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestSubmitEmailCode() -- data=" + JSON.stringify(data)
          );
          dispatch(showSuccess("Email verification successful", false));
        }
      }
    );
  };
}

export function requestForgetPassword(payloadObj, isEmail, id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showForgetPasswordData({}));
    dispatch(showSuccess(null, false));
    return ServerData.requestForgetPassword(
      payloadObj,
      isEmail,
      id,
      (error, data) => {
        if (error) {
          Log.d(TAG, "requestForgetPassword() -- error=" + error);
          dispatch(showError(error));
        } else {
          dispatch(showForgetPasswordData(data));
          if (id) {
            dispatch(showSuccess("Please login with your new password", false));
          } else {
            if (isEmail) {
              dispatch(
                showSuccess(
                  "We have sent you an email. Please check your email address inbox.",
                  false
                )
              );
            } else {
              dispatch(
                showSuccess(
                  "We have sent you an SMS. Please check your mobile.",
                  false
                )
              );
            }
          }
        }
      }
    );
  };
}

export function requestTransactions(url, currentTransactionList, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showTransactionList(null, null, null, null, 0, 0, []));
    return ServerData.requestTransactions(url, access, (error, response) => {
      if (error) {
        Log.d(TAG, "requestTransactions() -- error=" + error);
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestTransactions() -- response data=" + JSON.stringify(response)
        );

        var transactions = [];
        var next = response.links.next;
        var prev = response.links.prev;
        var first = response.links.first;
        var last = response.links.last;
        var currentPage = response.meta.pagination.page;
        var totalPages = response.meta.pagination.pages;

        for (var i = 0; i < response.data.length; i++) {
          var currentTransaction = response.data[i];
          var newTransaction = {
            id: currentTransaction.id,
            key: currentTransaction.id,
            code: currentTransaction.attributes.code,
            beneficiary_name: currentTransaction.attributes.beneficiary_name,
            reason_for_transfer:
              currentTransaction.attributes.reason_for_transfer,
            source_of_funds: currentTransaction.attributes.source_of_funds,
            status: Helper.showStatus(currentTransaction.attributes.status),
            status_date: currentTransaction.attributes.status_date,
            log_date: currentTransaction.attributes.log_date,
          };
          if (currentTransaction.type === 'airtime_data_transactions') {
            newTransaction['airtime_data_product_name'] = currentTransaction.attributes.airtime_data_product_name
            newTransaction['amount_to_pay'] = currentTransaction.attributes.amount_to_pay
            newTransaction['sending_amount'] = currentTransaction.attributes.sending_amount
          }
          transactions.push(newTransaction);
        }
        dispatch(
          showTransactionList(
            next,
            prev,
            first,
            last,
            currentPage,
            totalPages,
            transactions
          )
        );
      }
    });
  };
}

export function requestTransactionDetails(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showTransactionDetails({}));
    return ServerData.requestTransactionByID(id, access, (error, details) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestTransactionDetails() -- details=" + JSON.stringify(details)
        );
        dispatch(showTransactionDetails(details));
      }
    });
  };
}

export function requestAirtimeDataTransactionDetails(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showTransactionDetails({}));
    return ServerData.requestAirtimeDataTransactionByID(
      id,
      access,
      (error, details) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestTransactionDetails() -- details=" + JSON.stringify(details)
          );
          dispatch(showTransactionDetails(details));
        }
      }
    );
  };
}

export function updateTransactionStatus(payload, access) {
  return function (dispatch) {
    dispatch(showError(null));
    return ServerData.patchTransactionStatusUpdate(
      payload,
      access,
      (error, details) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "updateTransactionStatus() -- details=" + JSON.stringify(details)
          );
          // we only do this is we're updating transaction
          dispatch(updateTransactionDetails(details));
        }
      }
    );
  };
}

export function requestReceivingCountry(id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showReceivingCountry({}));
    return ServerData.requestCountryDetails(id, (error, countryDetails) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestReceivingCountry() -- country=" +
          JSON.stringify(countryDetails)
        );
        dispatch(showReceivingCountry(countryDetails));
      }
    });
  };
}

export function requestSendingCountry(id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSendingCountry({}));
    return ServerData.requestCountryDetails(id, (error, countryDetails) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showSendingCountry(countryDetails));
      }
    });
  };
}

export function requestReceivingCurrency(id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showReceivingCurrency({}));
    return ServerData.requestCurrencyDetails(id, (error, currencyDetails) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showReceivingCurrency(currencyDetails));
      }
    });
  };
}

export function requestSendingCurrency(id) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSendingCurrency({}));
    return ServerData.requestCurrencyDetails(id, (error, currencyDetails) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showSendingCurrency(currencyDetails));
      }
    });
  };
}

export function requestProductsAndServices(
  receivingCountryAlpha,
  sendingCountryAlpha
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showProductsServices([], []));
    return ServerData.requestProductsAndServices(
      receivingCountryAlpha,
      sendingCountryAlpha,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          // var services = [];
          // for (var i = 0; i < response.included.length; i++) {
          //   var chosenService = response.included[i];
          //   if (!chosenService.attributes.name.includes("Airtime top-up")) {
          //     services.push(chosenService);
          //   }
          // }
          let services = response.included.filter(
            (service) => !service.attributes.name.includes("Airtime top-up")
          );
          dispatch(showProductsServices(response.data, services)); // raw products
        }
      }
    );
  };
}

export function requestProductsAndServicesWithFilter(
  receivingCountryAlpha,
  sendingCountryAlpha,
  filter
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showProductsServices([], []));
    return ServerData.requestProductsAndServicesWithFilter(
      receivingCountryAlpha,
      sendingCountryAlpha,
      filter,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          // var services = [];
          // for (var i = 0; i < response.included.length; i++) {
          //   var chosenService = response.included[i];
          //   if (!chosenService.attributes.name.includes("Airtime top-up")) {
          //     services.push(chosenService);
          //   }
          // }
          let services = response.included.filter(
            (service) => !service.attributes.name.includes("Airtime top-up")
          );
          dispatch(showProductsServices(response.data, services)); // raw products
        }
      }
    );
  };
}

export function requestProductDetails(
  id,
  access,
  receivingCountryAlpha,
  sendingCountryAlpha
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showProductDetails({}));
    return ServerData.requestProductsDetails(
      id,
      access,
      receivingCountryAlpha,
      sendingCountryAlpha,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestProductDetails() -- response=" + JSON.stringify(response)
          );
          dispatch(showProductDetails(response));
        }
      }
    );
  };
}

export function requestAirtimeDataProducts(mobileNumber, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showAirtimeDataProducts([], true, null));
    return ServerData.requestAirtimeDataProducts(
      mobileNumber,
      access,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          let { data } = response;
          if ("included" in response) {
            response.included.forEach((item, i) => {
              data = data.map((product) => {
                if (
                  item.type === product.relationships.currency.data.type &&
                  item.id === product.relationships.currency.data.id
                ) {
                  return {
                    ...product,
                    relationships: {
                      ...product.relationships,
                      currency: {
                        data: {
                          ...product.relationships.currency.data,
                          ...item.attributes,
                        },
                      },
                    },
                  };
                } else {
                  return product;
                }
              });
            });
          }

          if (data.length < 1) {
            dispatch(
              showAirtimeDataProducts(
                data,
                false,
                "Network operator not available for entered mobile number"
              )
            ); // raw products
          } else {
            dispatch(showAirtimeDataProducts(data, false, null)); // raw products
          }
        }
      }
    );
  };
}

export function requestCalculationRequest(accessToken, payload, isReverse) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCalculationRequest({}, false));
    return ServerData.requestCalculationRequest(accessToken, payload, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showCalculationRequest(response, isReverse));
      }
    });
  };
}

export function requestCalculationDetails(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCalculation({}));
    return ServerData.requestCalculationByID(id, access, (error, details) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestCalculationDetails() -- details=" + JSON.stringify(details)
        );
        dispatch(showCalculation(details));
      }
    });
  };
}

export function patchRequestCalculation(
  rcPayload,
  payload,
  prepMissingLimitValues,
  accessToken
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCalculationRequest({}, false));
    let missingLimitValues = {};
    missingLimitValues.sending_min_limit =
      prepMissingLimitValues.data.attributes.sending_min_limit;
    missingLimitValues.sending_max_limit =
      prepMissingLimitValues.data.attributes.sending_max_limit;
    missingLimitValues.receiving_min_limit =
      prepMissingLimitValues.data.attributes.receiving_min_limit;
    missingLimitValues.receiving_max_limit =
      prepMissingLimitValues.data.attributes.receiving_max_limit;
    let calcId;
    ServerData.requestCalculation(accessToken, rcPayload, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        calcId = response.data.id;
        payload.data.id = calcId;
        return ServerData.patchRequestCalculationByID(
          calcId,
          payload,
          accessToken,
          (error, details) => {
            if (error) {
              dispatch(showError(error));
              dispatch(showCalculationRequest(prepMissingLimitValues, false));
            } else {
              Log.d(
                TAG,
                "requestCalculationDetails() -- details=" +
                JSON.stringify(details)
              );
              // add missing values: "sending_min_limit", "sending_max_limit", "receiving_min_limit", "receiving_max_limit"
              details.data.attributes = {
                ...missingLimitValues,
                ...details.data.attributes,
              };
              dispatch(showCalculationRequest(details, false));
            }
          }
        );
      }
    });
  };
}

export function requestRecalculation(calcId, payload, token, cb) {
  return function (dispatch) {
    ServerData.requestRecalculate(calcId, payload, token, (error, details) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestRecalculate() -- details=" + JSON.stringify(details)
        );
        dispatch(showCalculation(details));
        cb();
      }
    });
  };
}

export function requestCalculation(access, payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCalculation({}));
    return ServerData.requestCalculation(access, payload, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestCalculation() -- response=" + JSON.stringify(response)
        );
        dispatch(showCalculation(response));
      }
    });
  };
}

export function requestRecipients(url, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRecipients([], null, null, null, null, null));
    return ServerData.requestRecipients(url, access, (error, response) => {
      if (error) {
        Log.d(TAG, "requestRecipients() -- error=" + error);
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestRecipients() -- response data=" + JSON.stringify(response)
        );
        var next = response.links.next;
        var prev = response.links.prev;
        var first = response.links.first;
        var last = response.links.last;

        var recipients = [];
        for (var i = 0; i < response.data.length; i++) {
          let currentRecipient = response.data[i];
          var newRecipient = {
            id: currentRecipient.id,
            key: currentRecipient.id,
            name: currentRecipient.attributes.name,
            recipient_type:
              currentRecipient.attributes.recipient_type === "P"
                ? "Person"
                : "Entity",
            country: currentRecipient.relationships.country.data.id,
          };
          if (currentRecipient.attributes.beneficiary_type === 'AT') {
            newRecipient = {
              ...newRecipient,
              first_name: currentRecipient.attributes.first_name,
              last_name: currentRecipient.attributes.last_name,
              mobile: currentRecipient.attributes.mobile,
            }
          }
          // set country attributes here
          let included = {};
          if ("included" in response) {
            included = response.included.find(
              (included) =>
                included.type === "countries" &&
                included.id === currentRecipient.relationships.country.data.id,
              {}
            );
          }
          recipients.push({ ...newRecipient, included });
        }
        Log.d(
          TAG,
          "requestRecipients() -- recipients=" + JSON.stringify(recipients)
        );
        dispatch(showRecipients(recipients, next, prev, first, last, url));
      }
    });
  };
}

export function requestBWBeneficiaries(userID, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showBwBeneficiaries([]));
    return ServerData.requestBWBeneficiaries(
      userID,
      access,
      (error, response) => {
        if (error) {
          Log.d(TAG, "requestBWBeneficiaries() -- error=" + error);
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestBWBeneficiaries() -- response data=" +
            JSON.stringify(response)
          );
          var bwBeneficiaries = response.data;
          Log.d(
            TAG,
            "requestBWBeneficiaries() -- bw beneficiaries=" +
            JSON.stringify(bwBeneficiaries)
          );
          dispatch(showBwBeneficiaries(bwBeneficiaries));
        }
      }
    );
  };
}

export function requestRecipientByID(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRecipient({}, false));
    ServerData.requestRecipientByID(id, access, (error, recipient) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showRecipient(recipient, false));
      }
    });
  };
}

export function manageRecipient(payload, id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showRecipient({}, false));
    return ServerData.requestManageBeneficiary(
      id,
      payload,
      access,
      (error, recipient) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showRecipient(recipient, true));
        }
      }
    );
  };
}

export function requestBranchesByBank(
  bankId,
  stateId,
  districtId,
  cityId,
  access
) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showBranches([]));
    return ServerData.requestBranchesByBankID(
      bankId,
      stateId,
      districtId,
      cityId,
      access,
      (error, response) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showBranches(response.data));
        }
      }
    );
  };
}

export function requestCpps() {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCpps([]));
    return ServerData.requestCpps((error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showCpps(response.data));
      }
    });
  };
}

export function requestCplsByCpp(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCpls([]));
    return ServerData.requestCplByCppID(id, access, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showCpls(response.data));
      }
    });
  };
}

export function requestCplsShopsByCpp(id, cppName) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCplsShops([]));
    return ServerData.requestCplByCppID(id, "", (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        var cplShops = [];
        for (var i = 0; i < response.data.length; i++) {
          var currentCplShop = response.data[i];
          var newCplShop = {
            id: currentCplShop.id,
            key: currentCplShop.id,
            name: currentCplShop.attributes.name,
            city: currentCplShop.attributes.city,
            cash_pickup_provider: cppName,
            cash_pickup_provider_id: id,
          };
          cplShops.push(newCplShop);
        }
        Log.d(
          TAG,
          "requestCplsShopsByCpp() -- cplShops=" + JSON.stringify(cplShops)
        );
        dispatch(showCplsShops(cplShops));
      }
    });
  };
}

export function deleteRecipient(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess(null, false));
    return ServerData.deleteRecipient(id, access, (error) => {
      if (error) {
        dispatch(showError(error));
      } else {
        dispatch(showSuccess(null, true));
      }
    });
  };
}

export function requestSignUp(payload) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSignUpSuccess(false));
    dispatch(receiveUserDetails({}));
    return ServerData.requestSignUp(payload, (error, data) => {
      if (error) {
        Log.d(TAG, "requestSignUp() -- sign up error=" + error);
        dispatch(showError(error));
      } else {
        Log.d(TAG, "requestSignUp() -- sign up data=" + JSON.stringify(data));
        dispatch(receiveUserDetails(data));
        dispatch(showSignUpSuccess(true));
      }
    });
  };
}

export function requestPCAPredictAddressFind(text, container, alphaCode) {
  return function (dispatch) {
    dispatch(showPCAPredictError(null));
    dispatch(showPCAPredictAddresses([]));
    return ServerData.requestPCAPredictAddressFind(
      text,
      container,
      alphaCode,
      (error, data) => {
        if (error) {
          Log.d(TAG, "requestPCAPredictAddressFind() -- error=" + error);
          dispatch(showPCAPredictError(error));
        } else {
          Log.d(TAG, "requestPCAPredictAddressFind() -- success");
          dispatch(showPCAPredictAddresses(data));
        }
      }
    );
  };
}

export function requestPCAPredictAddressRetrieve(id) {
  return function (dispatch) {
    dispatch(showPCAPredictError(null));
    dispatch(showPCAPredictAddress({}));
    return ServerData.requestPCAPredictAddressRetrieve(
      id,
      (error, addressData) => {
        Log.d(
          TAG,
          "requestPCAPredictAddressRetrieve() -- address data=" +
          JSON.stringify(addressData)
        );
        if (error) {
          dispatch(showPCAPredictError(error));
        } else {
          dispatch(showPCAPredictAddress(addressData));
        }
      }
    );
  };
}

export function requestTransaction(payload, access, transactionID) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showTransactionDetails({}));
    return ServerData.requestTransaction(
      payload,
      access,
      transactionID,
      (error, transactionDetails) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestTransaction() -- transactionDetails=" +
            JSON.stringify(transactionDetails)
          );
          dispatch(showTransactionDetails(transactionDetails));
        }
      }
    );
  };
}

export function requestAirtimeDataTransaction(payload, transactionID) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showTransactionDetails({}));
    return ServerData.requestAirtimeDataTransaction(
      payload,
      transactionID,
      (error, transactionDetails) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestTransaction() -- transactionDetails=" +
            JSON.stringify(transactionDetails)
          );
          dispatch(showTransactionDetails(transactionDetails));
        }
      }
    );
  };
}

export function requestApplyPromoCode(payload, calculationID, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCalculation({}));
    return ServerData.requestApplyPromoCode(
      payload,
      calculationID,
      access,
      (error, calculationDetails) => {
        if (error) {
          dispatch(showError(error));
        } else {
          Log.d(
            TAG,
            "requestApplyPromoCode() -- calculationDetails=" +
            JSON.stringify(calculationDetails)
          );
          dispatch(showCalculation(calculationDetails));
        }
      }
    );
  };
}

export function sendFeedback(name, email, message) {
  Log.d(
    TAG,
    "sendFeedback() -- name=" + name + " email=" + email + " message=" + message
  );
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showSuccess("Some message", false));
  };
}

export function requestAgents(url) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showAgents([], null, null, null, null, null));
    return ServerData.requestAgents(url, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        var agents = [];
        var next = response.links.next;
        var prev = response.links.prev;
        var first = response.links.first;
        var last = response.links.last;
        Log.d(TAG, "requestAgents() -- current url=" + url);
        Log.d(TAG, "requestAgents() -- current next=" + next);
        Log.d(TAG, "requestAgents() -- current prev=" + prev);

        Log.d(
          TAG,
          "requestAgents() -- data agent=" + JSON.stringify(response.data)
        );
        for (var i = 0; i < response.data.length; i++) {
          var currentAgent = response.data[i];

          var newAgent = {
            id: currentAgent.id,
            key: currentAgent.id,
            first_name: currentAgent.attributes.first_name,
            surname: currentAgent.attributes.last_name,
            county: currentAgent.attributes.county,
            location: currentAgent.attributes.city,
            contact: currentAgent.attributes.mobile,
            lat: currentAgent.attributes.lat,
            lng: currentAgent.attributes.lng,
          };
          agents.push(newAgent);
        }
        dispatch(showAgents(agents, next, prev, first, last, url));
      }
    });
  };
}

export function requestCurrentMMPDetails(id, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showCurrentMMP({}));
    return ServerData.requestMMPByID(id, access, (error, details) => {
      if (error) {
        dispatch(showError(error));
      } else {
        Log.d(
          TAG,
          "requestCurrentMMPDetails() -- details=" + JSON.stringify(details)
        );
        dispatch(showCurrentMMP(details));
      }
    });
  };
}

export function requestCashPoints(url, type) {
  return function (dispatch) {
    dispatch(showError(null));
    if (type === "out") {
      dispatch(showCashOutPoints([], null, null, null, null, null));
    } else {
      dispatch(showCashInPoints([], null, null, null, null, null));
    }
    return ServerData.requestCashPoints(url, (error, response) => {
      if (error) {
        dispatch(showError(error));
      } else {
        var cashPoints = [];
        var next = response.links.next;
        var prev = response.links.prev;
        var first = response.links.first;
        var last = response.links.last;
        Log.d(TAG, "requestCashPoints() -- current url=" + url);
        Log.d(TAG, "requestCashPoints() -- current next=" + next);
        Log.d(TAG, "requestCashPoints() -- current prev=" + prev);

        Log.d(
          TAG,
          "requestCashPoints() -- data cash points=" +
          JSON.stringify(response.data)
        );
        for (var i = 0; i < response.data.length; i++) {
          var currentCashPoint = response.data[i];

          var newCashPoint = {
            id: currentCashPoint.id,
            key: currentCashPoint.id,
            city: currentCashPoint.attributes.city,
            address: currentCashPoint.attributes.address,
            location: currentCashPoint.attributes.location,
            contact_person: currentCashPoint.attributes.contact_person,
            phone: currentCashPoint.attributes.phone,
          };
          cashPoints.push(newCashPoint);
        }
        if (type === "out") {
          dispatch(showCashOutPoints(cashPoints, next, prev, first, last, url));
        } else {
          dispatch(showCashInPoints(cashPoints, next, prev, first, last, url));
        }
      }
    });
  };
}

export function requestManageBWBeneficiary(payload, id, bwId, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showBwBeneficiary({}));
    return ServerData.requestManageBWBeneficiary(
      id,
      bwId,
      payload,
      access,
      (error, bwBeneficiary) => {
        if (error) {
          dispatch(showError(error));
        } else {
          dispatch(showBwBeneficiary(bwBeneficiary));
        }
      }
    );
  };
}

export function deleteBWBeneficiary(id, bwId, index, total, access) {
  return function (dispatch) {
    dispatch(showError(null));
    dispatch(showBwIndex(index, total));
    return ServerData.deleteBWBeneficiary(
      id,
      bwId,
      index,
      total,
      access,
      (error, sIndex, total) => {
        if (error) {
          dispatch(showError(error));
        } else {
          sIndex = sIndex + 1;
          Log.d(TAG, "deleteBWBeneficiary() -- index=" + sIndex);
          dispatch(showBwIndex(sIndex, total));
        }
      }
    );
  };
}

export function setSendingCountry(sendingCountry) {
  return function (dispatch) {
    dispatch(showSendingCountry(sendingCountry));
  };
}

export function setReferenceCode(referenceCode) {
  return function (dispatch) {
    dispatch(showReferenceCode(referenceCode));
  };
}