import React from "react";
import { Layout, message } from "antd";
import CustomHeader from "./header.js";
import CustomFooter from "./footer.js";
import AddAirtimeRecipient from "../containers/AddAirtimeRecipient.js";
import Log from "../utils/log.js";
import { withCookies } from "react-cookie";
import "../styles/recipient.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Header, Footer } = Layout;
const TAG = "AddAirtimeRecipientContainer";

class AddRecipientContainer extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
  renderBody() {
    if (this.props.countryReducer.sendingCountries.length > 0) {
      return <AddAirtimeRecipient {...this.props} />;
    } else {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.title = "Manage Recipient";
    Log.d(
      TAG,
      "componentDidMount() -- current state=" + JSON.stringify(this.state)
    );
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      message.error("" + this.props.userReducer.error, 5);
    } else if (
      this.props.recipientReducer.updated !== prevProps.recipientReducer.updated
    ) {
      if (this.props.recipientReducer.updated) {
        const recipientCountryID =
          this.props.recipientReducer.recipient.data.relationships.country.data
            .id;

        const receivingCountry =
          this.props.location.state.receivingCountries.find(
            (country) => country.id === recipientCountryID
          );

        this.props.history.push({
          pathname: "/select-product-option",
          state: {
            recipient: this.props.recipientReducer.recipient,
            productOption: this.props.location.state.productOption,
            sendingCountry: this.props.location.state.sendingCountry,
            receivingCountries: this.props.location.state.receivingCountries,
            receivingCountry,
          },
        });
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(AddRecipientContainer));
