import React from 'react';
import { Button, Form, Layout, message, Select, Input } from 'antd';
import { withCookies } from 'react-cookie';

import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import ServerData from '../../utils/server_data.js';
import '../../styles/index.css'
import Log from '../../utils/log.js';
import Helper from '../../utils/helper.js';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content, Header, Footer } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const TAG = "Quote";

class Quote extends React.Component {

  render() {
    return (
      <Layout>
        <Header className="custom-header">
          <CustomHeader {... this.props}/>
        </Header>
        <Content className="quote-content">
          <WrappedHomeForm {... this.props}/>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  componentDidMount() {
    document.title = "Get Quote"
    window.scrollTo(0, 0)
  }
}

class HomeForm extends React.Component {

   constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    this.state = {
      sendingCountry: {},
      beneficiarySettings: {},
      transferReasons:[],
      defaultTransferReason: "",
      acceptedBeneficiaryTypes: [],
      acceptedRecipientTypes: [],
      paymentMethods: [],
      sendingCurrencies: [],
      receivingCurrencies: [],
      sendingCurrency: 0,
      receivingCurrency: 0,
      calculationRequest: {},
      calculation: {},
      cards: [],
      user: {},
      product: {},
      getFieldDecorator: this.props.form,
    };
  }

  componentWillMount() {
    // check if has access or has mobile wallet phone
    if (!!this.props.authReducer.accessToken) {
      this.requestUserDetails()
    } else {
      Log.d(TAG, "componentDidMount() -- no access and refresh seen ..");
      const { cookies } = this.props;
      Helper.clearCookies(cookies)
      this.props.history.push({
        pathname: "/login"
      });
    }
  }

  requestProductsAndServices(userDetails) {
    var sendingCountryAlpha = this.state.sendingCountry.attributes.alpha_2_code;
    var receivingCountryAlpha = "ZW";

    Log.d(TAG, "requestProductsAndServices() -- sendingCountryAlpha=" + sendingCountryAlpha + " receivingCountryAlpha=" + receivingCountryAlpha)
    ServerData.requestProductsAndServices(receivingCountryAlpha, sendingCountryAlpha, (error, response) => {
      if (error) {
        message.error("" + error, 5);
      } else {
        for (var i = 0; i < response.data.length; i++) {
          var chosenProduct = response.data[i];
          Log.d(TAG, "requestProductsAndServices() -- chosenProduct=" + chosenProduct.attributes.name)
          if (chosenProduct.attributes.name.includes("Wallet") || chosenProduct.attributes.name.includes("wallet")) {
            Log.d(TAG, "requestProductsAndServices() -- has top up")
            this.setState({
              product : chosenProduct
            })
          }
        }
        if (Object.keys(this.state.product).length > 0) {
          this.requestProductDetails(this.state.product.id, userDetails);
        }
      }
    })
  }

  requestUserDetails() {
    Log.d(TAG, "requestUserDetails() -- called ..");
    const { cookies } = this.props;
    ServerData.requestUserDetails(cookies, cookies.get('access'), false, (error, userDetails) => {
      if (error) {
        Log.d(TAG, "requestUserDetails() -- error=" + error);
        var errorString = "" + error
        message.error(errorString, 5)
      } else {
        Log.d(TAG, "requestUserDetails() -- response data=" + JSON.stringify(userDetails));
        var mobileWallet = userDetails.data.attributes.mobile_wallet_msisdn;
        if (mobileWallet) {
          var included = userDetails.included;
          if (Object.keys(included[0]).length > 0) {
            this.setState({
              user : userDetails
            })
            this.setUserDetails(userDetails);
          }
        } else {
          message.error("You have no mobile wallet.", 5)
          this.props.history.push({
            pathname: "/"
          });
        }
      }
    })
  }

  setUserDetails(userDetails) {
    if (Object.keys(userDetails.included[0]).length > 0) {
      var relationships = userDetails.data.relationships;
      if (relationships.hasOwnProperty("saved_cards")) {
        var savedCardsObj = relationships.saved_cards;
        if (savedCardsObj.hasOwnProperty("data")) {
          if (savedCardsObj.data != null) {
            this.setState({
              cards : savedCardsObj.data
            })
          }
        }
      }
      this.requestCountryDetails(userDetails, relationships.country.data.id);
    }
  }

  requestCountryDetails(userDetails, countryID) {
    ServerData.requestCountryDetails(countryID, (error, response) => {
      Log.d(TAG, "requestCountryDetails() -- error=" + error + " response=" + response);
      if (error) {
        Log.d(TAG, "requestCountryDetails() -- error=" + error);
        var errorString = "" + error
        message.error(errorString, 5)
      } else {
        Log.d(TAG, "requestCountryDetails() -- countries=" + JSON.stringify(response.data));

        this.setState({
          sendingCountry : response.data
        });

        this.requestProductsAndServices(userDetails)
      }
    });
  }

  requestProductDetails(id, userDetails) {
    var sendingCountryAlpha = this.state.sendingCountry.attributes.alpha_2_code;
    var receivingCountryAlpha = "ZW";

    Log.d(TAG, "requestProductDetails() -- sendingCountryAlpha=" + sendingCountryAlpha + " receivingCountryAlpha=" + receivingCountryAlpha)

    this.props.form.setFieldsValue({
      receiving_amount: ""
    })

    this.props.form.setFieldsValue({
      sending_amount: ""
    })

    this.setState({
      calculationRequest: {},
      calculation: {}
    })

    var token = null;
    if (!!this.props.authReducer.accessToken) {
      // token = cookies.get('access');
      token = this.props.authReducer.accessToken;
    }

    ServerData.requestProductsDetails(id, token, receivingCountryAlpha, sendingCountryAlpha, (error, response) => {
      if (error) {
        message.error("" + error, 5);
      } else {
        Log.d(TAG, "requestProductDetails() -- response=" + JSON.stringify(response));
        var included = response.included;
        var attributes = response.data.attributes;
        var relationships = response.data.relationships;

        var sendingCurrencies = [];
        var receivingCurrencies = [];
        var paymentMethods = [];

        for (var i = 0; i < included.length; i++) {
          var includedObj = included[i];
          if (includedObj.type === "currencies") {
            if (this.hasCurrencyID(relationships.sending_currencies.data, includedObj.id)) {
              Log.d(TAG, "requestProductDetails() -- sending currency=" + includedObj.id);
              sendingCurrencies.push(includedObj);
            }

            if (this.hasCurrencyID(relationships.receiving_currencies.data, includedObj.id)) {
              Log.d(TAG, "requestProductDetails() -- receiving currency=" + includedObj.id);
              receivingCurrencies.push(includedObj);
            }
          } else if (includedObj.type === "payment_methods") {
            paymentMethods.push(includedObj);
          }
        }

        this.setState({
          beneficiarySettings: attributes.beneficiary_settings,
          transferReasons: attributes.transfer_reasons,
          defaultTransferReason: attributes.default_transfer_reason,
          acceptedBeneficiaryTypes: attributes.accepted_beneficiary_types,
          acceptedRecipientTypes: attributes.accepted_recipient_types,
          paymentMethods: paymentMethods,
          sendingCurrencies: sendingCurrencies,
          receivingCurrencies: receivingCurrencies
        })
      }
    })
  }

  requestCalculationRequest(field, isReverse, amount) {
    Log.d(TAG, "requestCalculationRequest() -- isReverse=" + isReverse + " amount=" + amount);
    if (Helper.isValidAmount(amount)) {
      if (amount > 0) {
        Log.d(TAG, "requestCalculationRequest() -- request crs");
        var sendingCountryData = {};
        sendingCountryData.type = "countries";
        sendingCountryData.id = this.state.sendingCountry.id;
        var sendingCountryObj = {};
        sendingCountryObj.data = sendingCountryData;

        var sendingCurrencyData = {};
        sendingCurrencyData.type = "currencies";
        sendingCurrencyData.id = this.state.sendingCurrencies[this.state.sendingCurrency].id;
        var sendingCurrencyObj = {};
        sendingCurrencyObj.data = sendingCurrencyData;

        var receivingCountryData = {};
        receivingCountryData.type = "countries";
        receivingCountryData.id = "246";
        var receivingCountryObj = {};
        receivingCountryObj.data = receivingCountryData;

        var receivingCurrencyData = {};
        receivingCurrencyData.type = "currencies";
        receivingCurrencyData.id = this.state.receivingCurrencies[this.state.receivingCurrency].id;
        var receivingCurrencyObj = {};
        receivingCurrencyObj.data = receivingCurrencyData;

        var productData = {};
        productData.type = "products";
        productData.id = this.state.product.id;
        var productObj = {};
        productObj.data = productData;

        var relationshipsObj = {};
        relationshipsObj.receiving_country = receivingCountryObj;
        relationshipsObj.sending_country = sendingCountryObj;
        relationshipsObj.sending_currency = sendingCurrencyObj;
        relationshipsObj.receiving_currency = receivingCurrencyObj;
        relationshipsObj.product = productObj;

        var attributesObj = {};
        attributesObj.receive = isReverse;
        attributesObj.amount = amount;

        var dataObj = {};
        dataObj.type = "calculation_requests";
        dataObj.id = null;
        dataObj.attributes = attributesObj;
        dataObj.relationships = relationshipsObj;

        var payloadObj = {};
        payloadObj.data = dataObj;

        Log.d(TAG, "requestCalculationRequest() -- payload=" + JSON.stringify(payloadObj));
        const accessToken = this.props.authReducer.accessToken;
        ServerData.requestCalculationRequest(accessToken, payloadObj, (error, response) => {
          if(error) {
            message.error("" + error, 5)
          } else {
            this.setState({
              calculationRequest : response
            })
            if (isReverse) {
              this.props.form.setFieldsValue({
                sending_amount: response.data.attributes.sending_amount
              })
            } else {
              this.props.form.setFieldsValue({
                receiving_amount: response.data.attributes.recipient_amount
              })
            }
          }
        })
      } else {
        this.props.form.setFields({
          [field]: {
            errors: [new Error("Please enter a positive amount")],
          },
        });
        this.setState({
          calculationRequest: {},
          calculation: {},
        })
      }
    }
  }

  requestCalculation(crs) {
    var attributesObj = {};
    attributesObj.calculation_request = crs;

    var dataObj = {};
    dataObj.type = "calculations";
    dataObj.id = null;
    dataObj.attributes = attributesObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestCalculation() -- payload=" + JSON.stringify(payloadObj));
    const accessToken = this.props.authReducer.accessToken;
    ServerData.requestCalculation(accessToken, payloadObj, (error, response) => {
      if(error) {
        message.error("" + error, 5)
      } else {
        Log.d(TAG, "requestCalculation() -- response=" + JSON.stringify(response));
        this.setState({
          calculation : response
        })

        var sendingCurrency = {}
        var receivingCurrency = {}
        if (this.state.sendingCurrencies.length > 0) {
          sendingCurrency = this.state.sendingCurrencies[this.state.sendingCurrency]
        }

        if (this.state.receivingCurrencies.length > 0) {
          receivingCurrency = this.state.receivingCurrencies[this.state.receivingCurrency]
        }

        if (!!this.props.authReducer.accessToken) {
          this.props.history.push({
            pathname: '/select-payment-method',
            state: {
              sendingCountry : "204",
              receivingCountry: "246",
              product: "13",
              calculation: this.state.calculation,
              recipient: {},
              paymentMethods : this.state.paymentMethods,
              transferReasons: [],
              defaultTransferReason: '',
              cards: this.state.cards,
              receivingCurrency: sendingCurrency,
              sendingCurrency : receivingCurrency,
              transaction: {}
            }
          });
        } else {
          this.props.history.push({
            pathname: "/login"
          });
        }
      }
    })
  }

  hasCurrencyID(collection, id) {
    if (collection.filter(e => e.id === id).length > 0) {
      return true;
    }
    return false;
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.calculationRequest && Object.keys(this.state.user).length > 0) {
          this.requestCalculation(this.state.calculationRequest.data.id);
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
        <Form
          className="quote-body"
          onSubmit={this.handleSubmit.bind(this)}
          hideRequiredMark={true}>
          {this.renderRecipientReq(getFieldDecorator)}
          <div className="home-item">
            <FormItem style={{ width: '100%' }}>
              <Button style={{ width: '100%' }} type="primary" htmlType="submit">Pay Top-up</Button>
            </FormItem>
          </div>
        </Form>
      )
  }

  renderRecipientReq(getFieldDecorator) {
    return (
      <div>
        <div className="home-item">
          <h2 className="home-quote">Mobile Wallet top-up</h2>
          {this.renderSendingAmount(getFieldDecorator)}
          {this.renderReceivingAmount(getFieldDecorator)}
          {this.renderRateArea(getFieldDecorator)}
        </div>
      </div>
    )
  }

  renderSendingAmount(getFieldDecorator) {
    if (Object.keys(this.state.sendingCurrencies).length > 0) {
      return (
        <div style={{ width: '100%' }}>
          <FormItem>
            {getFieldDecorator('sending_amount', {
                rules: [{ required: true, message: 'This field is required' },
                { validator: this.validateAmountInput }],
              })(
                <Input
                  addonBefore={this.renderSendingCurrencies()}
                  placeholder="You are sending"
                  onChange={this.onChangeSendingAmount}
                  type="number"/>
              )}
          </FormItem>
        </div>
      )
    } else {
      return (<div/>)
    }
  }

  renderSendingCurrencies() {
    Log.d(TAG, "renderSendingCurrencies() -- currencies=" + JSON.stringify(this.state.sendingCurrencies))
    return (
      <Select value={this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code}
        style={{ width: 100 }}
        onChange={this.onChangeSendingCurrency}>
        {this.state.sendingCurrencies.map((itemAr, index) => {
          return (
            <Option key={index}>{itemAr.attributes.letter_code}</Option>
            );
        })}
      </Select>
    )
  }

  renderReceivingAmount(getFieldDecorator) {
    if (Object.keys(this.state.receivingCurrencies).length > 0) {
      return (
        <div style={{ width: '100%' }}>
          <FormItem>
            {getFieldDecorator('receiving_amount', {
                rules: [
                  { required: true, message: 'This field is required' },
                  { validator: this.validateAmountInput }],
              })(
                <Input
                  addonBefore={this.renderReceivingCurrencies()}
                  placeholder="Recipient will receive"
                  onChange={this.onChangeReceivingAmount}
                  type="number"/>
              )}
          </FormItem>
        </div>
      )
    } else {
      return (<div/>)
    }
  }

  renderReceivingCurrencies() {
    Log.d(TAG, "renderReceivingCurrencies() -- currencies=" + JSON.stringify(this.state.receivingCurrencies))
    return (
      <Select value={this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code}
        style={{ width: 100 }}
        onChange={this.onChangeReceivingCurrency}>
        {this.state.receivingCurrencies.map((itemAr, index) => {
          return (
            <Option key={index}>{itemAr.attributes.letter_code}</Option>
            );
        })}
      </Select>
    )
  }

  validateAmountInput = (rule, value, callback) => {
    if (Helper.isValidAmount(value)) {
      callback();
    } else {
      callback('Invalid amount. Amount should be positive and have maximum 2 decimal places');
    }
  }

  renderRateArea(getFieldDecorator) {
    if (Object.keys(this.state.calculationRequest).length > 0) {
      return (
        <div className="quote-rate-parent">
          <h2 className="home-quote">Your generated rate</h2>
          <div className="quote-rate-horizontal">
            <div className="quote-rate-vertical">
              <span>Sending</span>
              <span>Fees</span>
              <b>Total to be paid</b>
              <span>Top-up amount</span>
            </div>
            <div className="quote-rate-vertical">
              <span>{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {this.state.calculationRequest.data.attributes.sending_amount}</span>
              <span>{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {this.state.calculationRequest.data.attributes.fees}</span>
              <b>{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {this.state.calculationRequest.data.attributes.amount_to_pay}</b>
              <span>{this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code} {this.state.calculationRequest.data.attributes.recipient_amount}</span>
            </div>
          </div>
          <div className="quote-rate-text">
            <b>Exchange rate {this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} 1 = {this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code} {this.state.calculationRequest.data.attributes.rate}</b>
          </div>
        </div>
      )
    } else {
      return (<div/>)
    }
  }

  onChangeSendingCurrency = (value) => {
    this.setState({
      sendingCurrency : value
    })
    setTimeout(() => {
      this.requestCalculationRequest('sending_amount', false, this.props.form.getFieldValue('sending_amount'))
    }, 700);
  }

  onChangeReceivingCurrency = (value) => {
    this.setState({
      receivingCurrency : value
    })
    setTimeout(() => {
      this.requestCalculationRequest('receiving_amount', true, this.props.form.getFieldValue('receiving_amount'))
    }, 700);
  }

  onChangeSendingAmount = (event) => {
    Log.d(TAG, "onChangeSendingAmount() -- value=" + event.target.value);
    this.props.form.setFieldsValue({
      sending_amount: event.target.value
    })

    setTimeout(() => {
      this.requestCalculationRequest('sending_amount', false, this.props.form.getFieldValue('sending_amount'))
    }, 700);

  }

  onChangeReceivingAmount = (event) => {
    this.props.form.setFieldsValue({
      receiving_amount: event.target.value
    })
    setTimeout(() => {
      this.requestCalculationRequest('receiving_amount', true, this.props.form.getFieldValue('receiving_amount'))
    }, 700);
  }
}

const WrappedHomeForm = Form.create()(HomeForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Quote));

// export default withCookies(Quote)
