import React from "react";
import { Link } from "react-router-dom";
import Log from "../utils/log.js";
//import Drawer from 'rc-drawer-menu';
import {
  showProductDetails,
  showCalculation,
  showCookiesNotification,
  removeAccessRefreshTokens,
  requestAirtimeDataSendingCountries,
  fetchUnreadNotificationsCount,
  logout,
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Menu, Button, Badge } from "antd";
import "../styles/user.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import logo from "../images/EcoCash_Remit_Logo.png";
import logo from "../images/sasai-money-transfer-logo.png";
import ManageUrls from "../endpoints/manage_urls.js";
//import Drawer1 from 'react-motion-drawer';

import Helper from "../utils/helper.js";

const TAG = "SmartHeader";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: false,
    };
  }

  manageUrls = new ManageUrls();
  baseCountryIsSA = () => this.manageUrls.baseCountryIsSA();

  closeDrawer() {
    Log.d(TAG, "closeDrawer() -- called ..");
    this.setState({
      showDrawer: false,
    });
  }

  openDrawer() {
    Log.d(TAG, "openDrawer() -- called ..");
    this.setState({
      showDrawer: !this.state.showDrawer,
    });
  }

  componentDidMount() {
    if(!!this.props.authReducer.accessToken) {
      this.props.fetchUnreadNotificationsCount(this.props.authReducer.accessToken);
    }

    Log.d(TAG, "componentDidMount() -- show notification");
    Log.d(
      TAG,
      "componentDidMount() -- cookiesNotification=" +
      this.props.userReducer.cookiesNotification
    );
    if (this.props.userReducer.cookiesNotification) {
      
      // notification.info({
      //   message: "Cookie Usage",
      //   description:
      //     "We use cookies in this website. It allows us to give you the best experience in sending money. You can manage your cookies under Preference or Settings of this browser.",
      //   placement: "bottomRight",
      //   btn,
      //   key,
      //   duration: 0,
      //   onClose: () => {}
      // });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.countryReducer.airtimeDataSendingCountries !==
      this.props.countryReducer.airtimeDataSendingCountries &&
      Object.keys(this.props.countryReducer.airtimeDataSendingCountries)
        .length > 0
    ) {
      this.props.history.push({
        pathname: "/airtime-sender-details",
      });
    }
  }

  render() {
    return (
      <div>
        {this.renderWelcomeHeader()}
        <div className="navigation">
          <div
            className="container2"
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {this.renderNavigationBar()}
          </div>
        </div>
        {this.renderDrawer()}
      </div>
    );
  }

  renderUpContainer() {
    if (!!this.props.authReducer.accessToken) {
      return (
        <div className="header-up-container1">{this.renderWelcomeHeader()}</div>
      );
    } else {
    }
  }

  renderFindAgents(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderFindAgents() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/find-agents",
              });
            }}
          >
            Find Agents
          </button>
        </li>
      );
    } else {
      if (url === "/find-agents") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/find-agents",
                });
              }}
            >
              <span>Find Agents</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/find-agents",
                });
              }}
            >
              <span>Find Agents</span>
            </button>
          </li>
        );
      }
    }
  }

  renderFAQ() {
    var url = this.props.match.url;
    if (url === "/faq") {
      return (
        <li className="list-item current-section">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/faq",
              });
            }}
          >
            <span>FAQs</span>
          </button>
        </li>
      );
    } else {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/faq",
              });
            }}
          >
            <span>FAQs</span>
          </button>
        </li>
      );
    }
  }

  renderLimitsMenu(isMenu) {
    if (!!this.props.authReducer.accessToken) {
      if (isMenu) {
        if (Object.keys(this.props.userReducer.user).length > 0) {
          if (this.props.userReducer.user.data.attributes.phone_verified) {
            return <Menu.Item key="4">{this.renderLimitsLink(true)}</Menu.Item>;
          }
        }
      } else {
        return this.renderLimits(false);
      }
    }
    return <span />;
  }

  renderDrawer() {
    /*return (
        <Drawer parent={null} width="240px" placement="right" className="drawer">
          <Menu
            style={{ width: 240 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline">
            <Menu.Item key="1">{this.renderHowItWorks(true)}</Menu.Item>
            <Menu.Item key="2">{this.renderFindAgents(true)}</Menu.Item>
            <Menu.Item key="3">{this.renderGetQuoteLink(true)}</Menu.Item>
            {this.renderLimitsMenu(true)}
            <Menu.Item key="5">{this.renderHeader1stButtonLink(true)}</Menu.Item>
            <Menu.Item key="6">{this.renderHeader2ndButtonLink(true)}</Menu.Item>
            <Menu.Item key="7">{this.renderProfileLink(true)}</Menu.Item>
            <Menu.Item key="8">{this.renderHeaderLogoutLink()}</Menu.Item>
          </Menu>
        </Drawer>
    )*/
    var drawerButton = "drawer-button";
    if (!!this.props.authReducer.accessToken) {
      drawerButton = "drawer-button-logged-in";
    }
    return (
      <div>
        <Button
          className={drawerButton}
          onClick={() => this.openDrawer()}
          icon="menu-unfold"
        ></Button>
        <Drawer
          className="drawer"
          show={this.state.showDrawer}
          onClose={this.closeDrawer.bind(this)}
        >
          <Menu
            style={{ width: 240 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
          >
            <Menu.Item key="1">{this.renderHowMoneyArrives(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">{this.renderHowItWorks(true)}</Menu.Item>
            {/* <Menu.Divider />
            <Menu.Item key="2">{this.renderFindAgents(true)}</Menu.Item> */}
            <Menu.Divider />
            <Menu.Item key="2">{this.renderGetQuoteLink(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">{this.renderRemitHopeLink(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4">{this.renderGetAirtimeLink(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="5">{this.renderBlog(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="6">
              {this.renderBreadwinnerHomeLink(true)}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="7">{this.renderHelp(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="8">
              {this.renderHeader1stButtonLink(true)}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="9">
              {this.renderHeader2ndButtonLink(true)}
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="10">{this.renderProfileLink(true)}</Menu.Item>
            {/* <Menu.Divider />
            <Menu.Item key="10">{this.renderAddOns(true)}</Menu.Item> */}
            <Menu.Divider />
            <Menu.Item key="10">{this.renderReferAFriend(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="11">{this.renderReferAFriend(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="12">{this.renderNotifications(true)}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="13">{this.renderHeaderLogoutLink()}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="14">{this.renderHeaderLogoutLink()}</Menu.Item>
          </Menu>
        </Drawer>
      </div>
    );
  }

  renderWelcome() {
    const { cookies } = this.props;
    // if (cookies.get("access")) {
    if (!!this.props.authReducer.accessToken) {
      return (
        <span className="header-welcome">
          {this.renderMobileWallet()}
          <span
            style={{
              color: this.props.textColor ? this.props.textColor : "black",
            }}
          >
            Welcome back,&nbsp; {cookies.get("first_name")}{" "}
            {cookies.get("last_name")}
          </span>
        </span>
      );
    }
  }

  renderWelcomeHeader() {
    const { cookies } = this.props;
    // if (cookies.get("access")) {
    if (!!this.props.authReducer.accessToken) {
      return (
        <div className="header__top bg--green">
          <div className="container2">
            <div className="header__top__inner">
              <ul className="header__top__info">
                <li style={{ color: "#fff" }}>
                  <span style={{ fontFamily: "Lato" }}>
                    Welcome back, {cookies.get("first_name")}{" "}
                    {cookies.get("last_name")}!
                  </span>
                </li>
              </ul>
              <div className="header__top__button">
                {this.renderHeaderLogout()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderNavigationBar() {
    if (!!this.props.authReducer.accessToken) {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <div className="navigation-inner2">
            <div className="navigation-inner-mobile">
              <button
                className="transaparent-button"
                onClick={this.onRenderHome}
              >
                <img className="header-logo1" src={logo} alt="Ecocash Logo" />
              </button>
            </div>
          </div>
          <div
            className="navigation-inner"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div className="navigation-inner-left">
              <button
                className="transaparent-button"
                onClick={this.onRenderHome}
              >
                <img className="header-logo1" src={logo} alt="Ecocash Logo" />
              </button>
            </div>
            <div className="navigation-inner-right">
              <ul className="nav-list navbar-list">
                {this.renderHowMoneyArrives(false)}
                {this.renderHowItWorks(false)}
                {/* {this.renderFindAgents(false)} */}
                {this.renderGetQuoteLink(false)}
                {this.renderRemitHopeLink(false)}
                {this.renderGetAirtimeLink(false)}
                {this.renderBlog(false)}
                {this.renderBreadwinnerHome(false)}
                {this.renderHeader2ndButtonLink(false)}
                {this.renderHeader1stButtonLink(false)}
                {this.renderProfileLink(false)}
                {this.renderAddOns(false)}
                {this.renderHelp(false)}
                {this.renderReferAFriend(false)}
                {this.renderNotifications(false)}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="navigation-inner">
          <div
            className="navigation-inner-left"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="header__logo">
              <button
                className="transaparent-button"
                onClick={this.onRenderHome}
              >
                <img className="header-logo1" src={logo} alt="Ecocash Logo" />
              </button>
            </div>
          </div>
          <div className="navigation-inner-middle">
            <ul className="nav-list navbar-list" />
          </div>
          <div className="navigation-inner-right">
            <ul className="nav-list navbar-list">
              {this.renderHowMoneyArrives(false)}
              {this.renderHowItWorks(false)}
              {/* {this.renderFindAgents(false)} */}
              {this.renderGetQuoteLink(false)}
              {this.renderGetAirtimeLink(false)}
              {this.renderBlog(false)}
              {this.renderBreadwinnerHome(false)}
              {this.renderHelp(false)}
              {this.renderHeader2ndButtonLink(false)}
              {this.renderAddOns(false)}
              {this.renderHeader1stButtonLink(false)}
              {this.renderNotifications(false)}
              {this.renderReferAFriend(false)}
            </ul>
          </div>
        </div>
      );
    }
  }

  renderMobileWallet() {
    /*if (Object.keys(this.state.user).length > 0) {
      var mobileWallet = this.state.user.data.attributes.mobile_wallet_msisdn;
      if (mobileWallet) {
        return (<a onClick={this.onMobileWalletTop} className="header-mobile-top-up">Mobile Wallet Top-up</a>)
      }
    }*/
    return <div />;
  }

  renderLimits(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      if (Object.keys(this.props.userReducer.user).length > 0) {
        if (this.props.userReducer.user.data.attributes.phone_verified) {
          return this.renderLimitsLink(isDrawer);
        }
      }
    }
  }

  renderHeader1stButtonLink(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      return this.renderRecipientsListLink(isDrawer);
    } else {
      return this.renderSignUpLink(isDrawer);
    }
  }

  renderBreadwinnerHomeLink(isDrawer) {
    if (Helper.baseCountryIsSA) {
      if (!!this.props.authReducer.accessToken) {
        return this.renderBreadwinnerHome(isDrawer);
      } else {
        return this.renderAddOns(true);
      }
    }
  }

  renderHeader2ndButtonLink(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      return this.renderTransactionListLink(isDrawer);
    } else {
      return this.renderLoginLink(isDrawer);
    }
  }

  renderNotifications(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      return this.renderNotificationListLink(isDrawer);
    }
  }

  renderReferAFriend(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      return this.renderReferAFriendListLink(isDrawer);
    }
  }

  renderHeaderLogout() {
    if (!!this.props.authReducer.accessToken) {
      return this.renderLogout();
    }
  }

  renderHeaderLogoutLink() {
    if (!!this.props.authReducer.accessToken) {
      return this.renderLogout2();
    }
  }

  renderOurPartners() {
    return (
      <Link to="/our-partners">
        <p className="header-button">Our Partners</p>
      </Link>
    );
  }

  renderPromotions(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderPromotions() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/promotions",
              });
            }}
          >
            Promotions
          </button>
        </li>
      );
    } else {
      if (url === "/promotions") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/promotions",
                });
              }}
            >
              <span>Promotions</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/promotions",
                });
              }}
            >
              <span>Promotions</span>
            </button>
          </li>
        );
      }
    }
  }

  renderHowMoneyArrives(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderHowMoneyArrives() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/how-money-arrives",
              });
            }}
          >
            How Money Arrives
          </button>
        </li>
      );
    } else {
      if (url === "/how-money-arrives") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/how-money-arrives",
                });
              }}
            >
              <span>How Money Arrives</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/how-money-arrives",
                });
              }}
            >
              <span>How Money Arrives</span>
            </button>
          </li>
        );
      }
    }
  }

  renderHowItWorks(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderHowItWorks() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/how-it-works",
              });
            }}
          >
            How It Works
          </button>
        </li>
      );
    } else {
      if (url === "/how-it-works") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/how-it-works",
                });
              }}
            >
              <span>How It Works</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/how-it-works",
                });
              }}
            >
              <span>How It Works</span>
            </button>
          </li>
        );
      }
    }
  }

  renderBlog(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderBlog() -- url=" + url + " props=" + JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/blog",
              });
            }}
          >
            Blog
          </button>
        </li>
      );
    } else {
      if (url === "/blog") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/blog",
                });
              }}
            >
              <span>Blog</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/blog",
                });
              }}
            >
              <span>Blog</span>
            </button>
          </li>
        );
      }
    }
  }

  renderBreadwinnerHome(isDrawer) {
    var url = this.props.match.url;
    if (!!this.props.authReducer.accessToken && Helper.baseCountryIsSA) {
      Log.d(
        TAG,
        "renderBreadwinnerHome() -- url=" +
        url +
        " props=" +
        JSON.stringify(this.props)
      );
      if (isDrawer) {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/breadwinner-home",
                });
              }}
            >
              Breadwinner
            </button>
          </li>
        );
      } else {
        if (url === "/breadwinner-home") {
          return (
            <li className="list-item current-section">
              <button
                className="transaparent-button"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/breadwinner-home",
                  });
                }}
              >
                <span>Breadwinner</span>
              </button>
            </li>
          );
        } else {
          return (
            <li className="list-item">
              <button
                className="transaparent-button"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/breadwinner-home",
                  });
                }}
              >
                <span>Breadwinner</span>
              </button>
            </li>
          );
        }
      }
    }
  }

  renderHelp(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderHelp() -- url=" + url + " props=" + JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/get-help",
              });
            }}
          >
            Help
          </button>
        </li>
      );
    } else {
      if (url === "/get-help") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/get-help",
                });
              }}
            >
              <span>Help</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/get-help",
                });
              }}
            >
              <span>Help</span>
            </button>
          </li>
        );
      }
    }
  }

  renderRemitHopeLink(isDrawer) {
    if (!Helper.baseCountryIsSA) {
      if (!!this.props.authReducer.accessToken) {
        return this.renderRemitHope(isDrawer);
      }
    }
  }

  renderRemitHope(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderHelp() -- url=" + url + " props=" + JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/remit-hope",
              });
            }}
          >
            Remit Hope
          </button>
        </li>
      );
    } else {
      if (url === "/remit-hope") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/remit-hope",
                });
              }}
            >
              <span>Remit Hope</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/remit-hope",
                });
              }}
            >
              <span>Remit Hope</span>
            </button>
          </li>
        );
      }
    }
  }

  renderGetQuoteLink(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderGetQuoteLink() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/get-quote",
              });
            }}
          >
            Send Money
          </button>
        </li>
      );
    } else {
      if (url === "/get-quote") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/get-quote",
                });
              }}
            >
              <span>Send Money</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/get-quote",
                });
              }}
            >
              <span>Send Money</span>
            </button>
          </li>
        );
      }
    }
  }

  renderGetAirtimeLink(isDrawer) {
    var url = this.props.match.url;
    Log.d(
      TAG,
      "renderGetAirtimeLink() -- url=" +
      url +
      " props=" +
      JSON.stringify(this.props)
    );
    if (!!this.props.authReducer.accessToken) {
      if (isDrawer) {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/send-airtime-data",
                });
              }}
            >
              Send Airtime &amp; Data
            </button>
          </li>
        );
      } else if (true) {
        if (url === "/send-airtime-data") {
          return (
            <li className="list-item current-section">
              <button
                className="transaparent-button"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/send-airtime-data",
                  });
                }}
              >
                <span>Send Airtime &amp; Data</span>
              </button>
            </li>
          );
        } else {
          return (
            <li className="list-item">
              <button
                className="transaparent-button"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/send-airtime-data",
                  });
                }}
              >
                <span>Send Airtime &amp; Data</span>
              </button>
            </li>
          );
        }
      }
    } else if (true) {
      if (isDrawer) {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.props.requestAirtimeDataSendingCountries}
            >
              Send Airtime &amp; Data
            </button>
          </li>
        );
      } else {
        if (url === "/airtime-sender-details") {
          return (
            <li className="list-item current-section">
              <button
                className="transaparent-button"
                onClick={this.props.requestAirtimeDataSendingCountries}
              >
                <span>Send Airtime &amp; Data</span>
              </button>
            </li>
          );
        } else {
          return (
            <li className="list-item">
              <button
                className="transaparent-button"
                onClick={this.props.requestAirtimeDataSendingCountries}
              >
                <span>Send Airtime &amp; Data</span>
              </button>
            </li>
          );
        }
      }
    }
  }

  renderAboutUs() {
    return (
      <Link to="/about-us">
        <p className="header-button">
          <span>About U</span>
        </p>
      </Link>
    );
  }

  renderOurTariffs() {
    return (
      <Link to="/our-tariffs">
        <p className="header-button">
          <span>Our Tariffs/Charges</span>
        </p>
      </Link>
    );
  }

  renderSignUp(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              const path = Helper.baseCountryIsSA ?  "/welcome-registration" : "/sign-up"
              this.props.history.push({
                pathname: path,
              });
            }}
          >
            Sign Up
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/welcome-registration" || url === "/sign-up") {
        return (
          <button
            className="header-button-parent-selected"
            onClick={() => {
              const path = Helper.baseCountryIsSA ?  "/welcome-registration" : "/sign-up"
              this.props.history.push({
                pathname: path,
              });
            }}
          >
            <span>Sign Up</span>
          </button>
        );
      } else {
        return (
          <button
            className="header-button"
            onClick={() => {
              const path = Helper.baseCountryIsSA ?  "/welcome-registration" : "/sign-up"
              this.props.history.push({
                pathname: path,
              });
            }}
          >
            <span>Sign Up</span>
          </button>
        );
      }
    }
  }

  renderSignUpLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              const path = Helper.baseCountryIsSA ?  "/welcome-registration" : "/sign-up"
              this.props.history.push({
                pathname: path,
              });
            }}
          >
            Register
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      const path = Helper.baseCountryIsSA ?  "/welcome-registration" : "/sign-up"
      if (url === "/welcome-registration" || url === "/sign-up") {
        return (
          <li className="list-button-signup">
            <Link to={path}>
              <span>Register</span>
            </Link>
          </li>
        );
      } else {
        return (
          <li className="list-button-signup">
            <Link to={path}>
              <span>Register</span>
            </Link>
          </li>
        );
      }
    }
  }

  renderRecipientsList(isDrawer) {
    if (isDrawer) {
      return (
        <button className="header-button" onClick={this.onLoadRecipientList}>
          Recipients
        </button>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/recipient-list") {
        return (
          <div className="header-button-parent-selected">
            <button
              className="header-button-selected"
              onClick={this.onLoadRecipientList}
            >
              <span>Recipients</span>
            </button>
          </div>
        );
      } else {
        return (
          <button
            className="header-button"
            onClick={this.onLoadRecipientList}
            style={{ color: this.props.textColor }}
          >
            <span>Recipients</span>
          </button>
        );
      }
    }
  }

  renderRecipientsListLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={this.onLoadRecipientList}
          >
            Recipients
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/recipient-list") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={this.onLoadRecipientList}
            >
              <span>Recipients</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.onLoadRecipientList}
            >
              <span>Recipients</span>
            </button>
          </li>
        );
      }
    }
  }

  renderTransactionListLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={this.onLoadTransactionList}
          >
            Transactions
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/transaction-list") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={this.onLoadTransactionList}
            >
              <span>Transactions</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.onLoadTransactionList}
            >
              <span>Transactions</span>
            </button>
          </li>
        );
      }
    }
  }

  renderNotificationListLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={this.onLoadNotificationList}
          >
            Notifications
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/notification-list") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={this.onLoadNotificationList}
            >
              <span>
                Notifications{" "}
                  <Badge
                    count={this.props.notificationsReducer.count.notSeenCount}
                  />
              </span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.onLoadNotificationList}
            >
              <span>
                Notifications{" "}
                  <Badge
                    count={this.props.notificationsReducer.count.notSeenCount}
                  />
              </span>
            </button>
          </li>
        );
      }
    }
  }

  renderReferAFriendListLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={this.onLoadReferAFriend}
          >
            Refer a friend
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/referral") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={this.onLoadReferAFriend}
            >
              <span>
                Refer a friend
              </span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.onLoadReferAFriend}
            >
              <span>
                Refer a friend
              </span>
            </button>
          </li>
        );
      }
    }
  }

  renderLimitsLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button className="transaparent-button" onClick={this.onLoadLimits}>
            Limits
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/limits") {
        return (
          <li className="list-item current-section">
            <button className="transaparent-button" onClick={this.onLoadLimits}>
              <span>Limits</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button className="transaparent-button" onClick={this.onLoadLimits}>
              <span>Limits</span>
            </button>
          </li>
        );
      }
    }
  }

  renderProfileLink(isDrawer) {
    if (!!this.props.authReducer.accessToken) {
      if (isDrawer) {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={this.onUpdateProfile}
            >
              Profile
            </button>
          </li>
        );
      } else {
        var url = this.props.match.url;
        if (url === "/update-profile") {
          return (
            <li className="list-item current-section">
              <button
                className="transaparent-button"
                onClick={this.onUpdateProfile}
              >
                <span>Profile</span>
              </button>
            </li>
          );
        } else {
          return (
            <li className="list-item">
              <button
                className="transaparent-button"
                onClick={this.onUpdateProfile}
              >
                <span>Profile</span>
              </button>
            </li>
          );
        }
      }
    }
  }

  renderAddOns(isDrawer) {
    if (!!this.props.authReducer.accessToken && Helper.baseCountryIsSA) {
      if (isDrawer) {
        return (
          <li className="list-item">
            <button className="transaparent-button" onClick={this.onAddOns}>
              Breadwinner Policy
            </button>
          </li>
        );
      } else {
        var url = this.props.match.url;
        if (url === "/add-ons") {
          return (
            <li className="list-item current-section">
              <button className="transaparent-button" onClick={this.onAddOns}>
                <span>Breadwinner Policy</span>
              </button>
            </li>
          );
        } else {
          return (
            <li className="list-item">
              <button className="transaparent-button" onClick={this.onAddOns}>
                <span>Breadwinner Policy</span>
              </button>
            </li>
          );
        }
      }
    }
  }

  renderLogout() {
    return (
      <button
        className="transaparent-button"
        onClick={() => this.onLogout("")}
        style={{
          color: "#fff",
          fontFamily: "Lato",
        }}
      >
        <span>
          <span>Logout</span>
        </span>
      </button>
    );
  }

  renderLogout2() {
    return (
      <li className="list-item">
        <button
          className="transaparent-button"
          onClick={() => this.onLogout("")}
        >
          <span>Logout</span>
        </button>
      </li>
    );
  }

  renderLoginLink(isDrawer) {
    if (isDrawer) {
      return (
        <li className="list-item">
          <button
            className="transaparent-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/login",
              });
            }}
          >
            Log In
          </button>
        </li>
      );
    } else {
      var url = this.props.match.url;
      if (url === "/login") {
        return (
          <li className="list-item current-section">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/login",
                });
              }}
            >
              <span>Login</span>
            </button>
          </li>
        );
      } else {
        return (
          <li className="list-item">
            <button
              className="transaparent-button"
              onClick={() => {
                this.props.history.push({
                  pathname: "/login",
                });
              }}
            >
              <span>Login</span>
            </button>
          </li>
        );
      }
    }
  }

  onAddOns = () => {
    this.props.history.push({
      pathname: "/breadwinner-policy",
    });
  };

  onUpdateProfile = () => {
    this.props.history.push({
      pathname: "/update-profile",
    });
  };

  onMobileWalletTop = () => {
    this.props.history.push({
      pathname: "/top-up",
    });
  };

  onLoadTransactionList = () => {
    this.props.history.push({
      pathname: "/transaction-list",
    });
  };

  onLoadNotificationList = () => {
    this.props.history.push({
      pathname: "/notification-list",
    });
  };

  onLoadReferAFriend = () => {
    this.props.history.push({
      pathname: "/referral",
    });
  };

  onLoadLimits = () => {
    this.props.history.push({
      pathname: "/limits",
    });
  };

  onLoadRecipientList = () => {
    this.props.showProductDetails({});
    this.props.showCalculation({});
    this.props.history.push({
      pathname: "/recipient-list",
    });
  };

  onRenderHome = () => {
    Log.d(TAG, "onRenderHome() -- called ..");
    this.props.history.push({
      pathname: "/",
    });
  };

  onLogout(message) {
    Log.d(TAG, "onLogout() -- called ..");
    Log.d(TAG, "onLogout() -- message=" + message);
    this.props.removeAccessRefreshTokens();
    const { cookies } = this.props;
    const accessToken = this.props.authReducer.accessToken;
    this.props.logout(cookies, accessToken);
    // ServerData.logOut(
    //   cookies,
    //   this.props.authReducer.accessToken,
    //   true,
    //   (error) => {
    //     if (error) {
    //       Log.d(TAG, "onLogout() -- error=" + error);
    //     }
    //   }
    // );
    // cookies.remove("access");
    // cookies.remove("refresh");
    cookies.remove("first_name");
    cookies.remove("last_name");
    this.props.history.push({
      pathname: "/login",
      state: { message: message },
    });
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      showProductDetails,
      showCalculation,
      showCookiesNotification,
      removeAccessRefreshTokens,
      requestAirtimeDataSendingCountries,
      fetchUnreadNotificationsCount,
      logout,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = props;
    this.drawerStyle = {
      position: "fixed",
      top: "0px",
      left: "0px",
      height: "100%",
      width: "240px",
      background: "#ffffff",
      overflowX: "hidden",
      overflowY: "scroll",
      webkitOverflowScrolling: "touch",
      borderRight: "1px solid #d9d9d9",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.drawerStyle = Object.assign({}, this.drawerStyle, { right: "0px" });
    }
    this.setState({
      nextProps,
    });
  }

  render() {
    return (
      <div>
        {this.props.show && (
          <div id="drawer" style={this.drawerStyle}>
            <div className="container">
              <Button
                className="drawer-close"
                onClick={this.props.onClose}
                icon="close-circle"
              />
              {this.props.children}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
