import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'
import thathaPromotion from '../../images/thatha-promo.jpg'
import Log from '../../utils/log.js';

const { Content, Header, Footer } = Layout;
const TAG = "Terms-Promotion";

const data = [
  {
    id: 1,
    title: 'Thatha Amathousand Promotion',
    author: 'Sasai Fintech',
    image: thathaPromotion 
  },
];

class TermsPromotions extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props))
  }

  componentDidMount() {
    document.title = "Promotion Terms"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="dashboard-body">
          {this.renderContent()}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  // renderContent() {
  //   Log.d(TAG, "renderContent() -- storyId=" + this.props.match.params.storyId)
  //   if (Number(this.props.match.params.storyId) === 1) {
  //     return this.renderContent1();
  //   } else {
  //     return this.renderContent2();
  //   }
  // }

  renderContent() {
    Log.d(TAG, "renderContent() -- id=" + this.props.match.params.id)
    
    switch (Number(this.props.match.params.id)) {
      case 1:
        return this.renderContent1();
      default:
        return this.renderContent1();
    }
  }

  renderContent1() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <h3 className="stories-title">{data[this.props.match.params.id - 1].title}</h3>
        <img className="stories-image" src={data[this.props.match.params.id - 1].image} alt="stories"/>
        <span className="terms-item-child"><b className="terms-item-bold">SASAI MONEY TRANSFER GROCERY VOUCHER COMPETITION RULES & T&C’s (“RULES/T&C’s”)</b></span>
        <span className="terms-item-child">1. This Sasai Money Transfer Grocery voucher competition (“the Promotional Competition”) is organized by Sasai Fintech (Pty) Ltd (the “Promoter”).</span>
        <span className="terms-item-child"><b className="terms-item-bold">2. The Promotional Competition is open to natural persons legally resident in South Africa over the age of 18 (eighteen) years (or are emancipated minors), (“Participant/s”), except any employee, director, member, partner, agent or consultant of the Promoter or Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, Cambridge or of either of their affiliates and/or associated companies, who may not participate in this Promotional Competition.</b></span>
        <span className="terms-item-child">3. By entering the Promotional Competition, all Participants and the winners agree to be bound by these rules/T&C’s, which will be interpreted by the Promoter, whose decision regarding any dispute will be final and binding. The Promoter reserves the right to amend, modify, change, postpone, suspend, extend or cancel this Promotional Competition and any giveaways or any aspect thereof, without notice at any time, for any reason which the Promoter reasonably deem necessary.</span>
        <span className="terms-item-child"><b className="terms-item-bold">4. The Promotional Competition runs from 26 October 2022 to 27 December 2022(“Promotional Competition Period”).</b></span>
        <span className="terms-item-child">5. A total of 99 grocery vouchers of R 1000 (One Thousand rand) each (“the Giveaway/s”) will be given away during the Promotional Competition period. The give-away will work as follows: 11 x R 1000 (One Thousand rand) vouchers will be given away each week during the Promotional Competition period.</span>
        <span className="terms-item-child">6. To enter the Promotional Competition:</span>
        <span className="terms-item-child"> 6.1 register to use the Sasai Money Transfer service (“Sasai Money Transfer”), via the Sasai Money Transfer App, Sasai Money Transfer website (moneytransfer.sasai.global) or an approved Sasai Money Transfer Agent. For details visit <a href="https://moneytransfer.sasai.global/">moneytransfer.sasai.global</a></span>
        <span className="terms-item-child">6.2 To qualify you must send an equivalent of USD50 (Fifty United Stated Dollars) or more in Rands with Sasai Money Transfer to a beneficiary in Zimbabwe, during the Promotional Competition period.</span>
        <span className="terms-item-child">7. Winners will be randomly selected from the list of entrants and announced on the Sasai Money Transfer website and the following social media pages - Facebook - @SasaiMoneyTransfer; Twitter - @SasaiMoneyTransfer; LinkedIn – SasaiMoneyTransfer; and will also be contacted via SMS.</span>
        <span className="terms-item-child">8. Winners will be notified on how they may collect their giveaways from either Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge by prior arrangement with Sasai Fintech.</span>
        <span className="terms-item-child">9. The Giveaway is neither transferable nor redeemable for cash. The Promoter reserves the right to substitute the Giveaway with any other give-away item of comparable commercial value.</span>
        <span className="terms-item-child">10. No liability shall attach to the Promoter or Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge in relation to any claims, losses or liabilities for any loss of profit or any other direct and/or indirect, special or consequential loss arising out of any of the Giveaways.</span>
        <span className="terms-item-child">11. Should any dispute arise in relation to the interpretation of these promotional rules, the Promoter’s decision shall be final and no correspondence shall be entered into.</span>
        <span className="terms-item-child"><b className="terms-item-bold">12. All Participants and the winners, as the case may be, indemnify the Promoter, Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge and their respective affiliates and/or associated companies against any and all claims of any nature whatsoever arising out of and/or from their participation in any way howsoever in this Promotional Competition (including, as a result of any act or omission, whether as a result of negligence, misrepresentation, misconduct or otherwise on the part of the Promoter, Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, Cambridge and/or use of the Giveaways).</b></span>
        <span className="terms-item-child">13. The Promoter may require the winners to complete and submit an information disclosure agreement and indemnification to enable the Promoter to ensure compliance with these Rrles/T&C’s and the Consumer Protection Act 68 of 2008. Should any winner refuse or be unable to comply with this rule for any reason, such a winner will be deemed to have rejected the Giveaway and it shall revert back to the Promoter.</span>
        <span className="terms-item-child">14. The Promoter may require the winners to be identified, photographed and published in printed media, or to appear on radio and television, when accepting their prizes or after having received their prizes. The Promoter may use the uploaded images in whole or in part, and any reproductions thereof, worldwide for advertising, promotion, publicity or otherwise on any website for any media purpose and for any other lawful purpose whatsoever. The winners will be given the opportunity to decline to the publication of their images and to participate in the Promoter’s marketing material in so far as it relates to the Giveaway.</span>
        <span className="terms-item-child">15. By entering the Promotional Competition, the participants agree that the Promoter and its affiliates and/or associated companies may contact the participants via email and through social media platforms to deliver marketing communication regarding their products and promotional activities, provided that the Participants are given the opportunity to opt-out of receiving marketing communication at any time via the appropriate opt-out mechanisms provided by the Promoter for such purpose. The Promoter may also use the personal data collected from the participants as described in the Privacy Policy accessible at moneytransfer.sasai.global/privacy-policy and that such use may include transfers to the Promoter’s affiliates and third party service providers in any country.</span>
        <span className="terms-item-child">16. All queries in connection with this promotional competition should be directed to the Sasai Fintech Sasai Money Transfer Customer Care Line on +2721 200 8517.</span>
        <span className="terms-item-child">17. A copy of the competition rules is available at no cost to the participants on request.</span>
        <br/>
      </div>
    )
  }
}

export default withCookies(TermsPromotions)
