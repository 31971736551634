import React from "react";
import Log from "../utils/log.js";
import {
  Input,
  Form,
  message,
  Layout,
  Button,
} from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helper from "../utils/helper.js";
import {
  requestSendingCountries,
  requestSignUp,
  requestLogin,
  requestPCAPredictAddressFind,
  requestPCAPredictAddressRetrieve,
  requestCountries,
  setSendingCountry,
  requestOTPCodeClientSignUp,
  requestOTPSignIn,
} from "../actions";

import ManageUrls from "../endpoints/manage_urls.js";
import baseUrlOptions from "../endpoints/base_url_options";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "SmartSignUp";

class NameConfirmation extends React.Component {

  componentDidMount() {}

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedNameConfirmationForm {...this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {}
}

class NameConfirmationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sendingCountry: 0, // step 1
      baseCountryIsSA: true,
      phone: "",
      whatsapp_mobile: "",
      password: "",
      refCode: "",
      emailAdd: "",
      confirmPassword: "",
      sendPromotions: false,
      sendingCountries: [],
      pType: "password",
      pTypeIcon: "eye",
      cpType: "password",
      cpTypeIcon: "eye",
      otp: "",
      first_name: "",
      last_name: "",
    };
  }

  current = 0;
  manageUrls = new ManageUrls();

  componentDidMount() {
    this.requestSendingCountry();
    this.requestCountries();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.countryReducer.sendingCountries.length > 0 &&
      prevProps.countryReducer.sendingCountries !==
        this.props.countryReducer.sendingCountries
    ) {
      this.setState({
        sendingCountry: 0,
      });
      this.props.form.setFieldsValue({
        sendingCountry: 0,
      });
    }

    if (
      this.props.countryReducer.countries.length > 0 &&
      prevProps.countryReducer.countries !== this.props.countryReducer.countries
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set countries=" +
          JSON.stringify(this.props.countryReducer.countries)
      );
    }

    if (
      this.props.otpReducer.signInSuccess !== null &&
      prevProps.otpReducer.signInSuccess !== this.props.otpReducer.signInSuccess
    ) {
      if(this.props.otpReducer.signInSuccess) {
        this.props.history.push({
          pathname: "/"
        })
      }
    }

    if (
      this.props.otpReducer.goToReg !== null &&
      prevProps.otpReducer.goToReg !== this.props.otpReducer.goToReg
    ) {
      window.confirm("Our customer love team will be in touch shortly.")
      this.props.history.push({
        pathname: "/"
      });
    }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      if (this.props.userReducer.error.includes("Invalid credentials")) {
        var errorMessage =
          // "We found an error verifying your credentials based on your registration details.";
          "Invalid credentials";
        if (Object.keys(this.props.userReducer.user).length > 0) {
          Log.d(
            TAG,
            "componentDidUpdate() -- error has user=" +
              JSON.stringify(this.props.userReducer.user)
          );
          errorMessage =
            errorMessage +
            " Your account status is " +
            Helper.showUserStatus(
              this.props.userReducer.user.data.attributes.user_status
            ) +
            ".";
        }
        message.error(errorMessage, 5);
      } else {
        message.error("" + this.props.userReducer.error, 5);
      }
    }

    if (
      this.props.userReducer.success !== null &&
      prevProps.userReducer.success !== this.props.userReducer.success
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set success=" + this.props.userReducer.success
      ); // means sign up is successful
    }
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    this.props.requestSendingCountries();
  }

  requestCountries() {
    Log.d(TAG, "requestCountries() -- called ..");
    this.props.requestCountries();
  }

  renderPhonePrefix() {
    Log.d(
      TAG,
      "renderPhonePrefix() -- countries=" +
        JSON.stringify(this.props.countryReducer.sendingCountries)
    );
    Log.d(
      TAG,
      "renderPhonePrefix() -- current country index=" +
        JSON.stringify(this.state.sendingCountry)
    );
    if (
      this.props.countryReducer.sendingCountries.length > 0 &&
      this.props.countryReducer.sendingCountries[this.state.sendingCountry]
    ) {
      Log.d(
        TAG,
        "renderPhonePrefix() -- current country=" +
          JSON.stringify(
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ]
          )
      );
      return (
        <div style={{ width: 45 }}>
          +
          {
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ].attributes.phone_prefix
          }
        </div>
      );
    } else {
      return <div />;
    }
  }

  onMobileChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      phone: event.target.value
    })
    this.setState({ phone : event.target.value })
  }
  
  onOTPChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      otp: event.target.value
    })
    this.setState({ otp : event.target.value })
  }

  onFirstNameChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      first_name: event.target.value
    })
    this.setState({ first_name : event.target.value })
  }

  onLastNameChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      last_name: event.target.value
    })
    this.setState({ last_name : event.target.value })
  }

  renderStep1(getFieldDecorator, phonePrefix) {
    Log.d(TAG, "renderStep1() -- mobile=" + this.state.phone);
    return (
      <div>
        <div className="su-item-2">
          <div className="su-left-title-text">
            <b>{this.markRequired}Your mobile number</b>
          </div>
          <FormItem className="su-row-full">
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "Please provide your phone number" },
                { validator: this.validatePhone },
              ],
              initialValue: this.state.phone,
            })(
              <Input
                className="mobile-number"
                placeholder="Phone number*"
                addonBefore={phonePrefix}
                onChange={this.onMobileChanged}
                maxlength="11"
              />
            )}
          </FormItem>
            <div className="su-left-title-text">
              <b>Enter One Time Pin</b>
            </div>
            <FormItem className="su-row-full">
              {getFieldDecorator("otp", {
                rules: [{ required: true, message: "Please enter one time pin" }],
                initialValue: this.state.otp,
              })(<Input
                    placeholder="OTP" 
                    className="su-tf-2 form-control"
                    onChange={this.onOTPChanged}
                    maxlength="4"
                  />)}
            </FormItem>
            <div className="su-left-title-text">
              <b>First Name</b>
            </div>
            <FormItem className="su-row-full">
              {getFieldDecorator("first_name", {
                rules: [{ required: true, message: "Please enter first name" }],
                initialValue: this.state.first_name,
              })(<Input
                    placeholder="First Name" 
                    className="su-tf-2 form-control"
                    onChange={this.onFirstNameChanged}
                  />)}
            </FormItem>
            <div className="su-left-title-text">
              <b>Last Name</b>
            </div>
            <FormItem className="su-row-full">
              {getFieldDecorator("last_name", {
                rules: [{ required: true, message: "Please enter last name" }],
                initialValue: this.state.last_name,
              })(<Input
                  placeholder="Last Name" 
                  className="su-tf-2 form-control"
                  onChange={this.onLastNameChanged}
                />)}
            </FormItem>
        </div>
      </div>
    );
  }

  handleSubmit(e) {
    Log.d(TAG, "handleSubmit() -- called ..");
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- values=" + JSON.stringify(values));
      Log.d(TAG, "handleSubmit() -- err=" + JSON.stringify(err));
      if (!err) {
        const prefix = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix;

        const payload = {
          username: `${prefix} ${values.phone}`,
          otp: this.state.otp,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          remember_me: true
        }

        this.props.requestOTPSignIn(payload);
      }
    });
  }

  reSendOTP() {
    const mobileUsername = this.props.countryReducer.sendingCountries[
      this.state.sendingCountry
    ].attributes.phone_prefix +
    " " +
    this.state.phone.replace(/\s/g, "");

    const payload = {
      username: mobileUsername
    }

    this.props.requestOTPCodeClientSignUp(payload);
  }

  otpSignIn() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const mobileUsername = this.props.countryReducer.sendingCountries[
          this.state.sendingCountry
        ].attributes.phone_prefix +
        " " +
        this.state.phone.replace(/\s/g, "");
    
        const payload = {
          username: mobileUsername,
          otp: this.state.otp
        }
    
        this.props.requestOTPSignIn(payload);
      }
    });

    
  }

  setSendingCountry = () => {
    const baseCountryAlphaCode =
      this.props.countryReducer.sendingCountries[this.state.sendingCountry]
        .attributes.alpha_2_code;

    if (baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ usernameIsPhoneNumber: true });
      this.manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA);
    } else {
      this.setState({ usernameIsPhoneNumber: false });
      this.manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM);
    }

    const sendingCountryObj =
      this.props.countryReducer.sendingCountries[this.state.sendingCountry];

    this.props.setSendingCountry(sendingCountryObj);
  };

  actionButtons() {

    const prev = () => {
      this.props.history.push({
        pathname: "/look-up"
      })
    }

    const buttonStyle = {
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
      marginRight: 8,
    }

    return (
      <>
        <Button
          style={buttonStyle}
          onClick={() => prev()}
        >
          Prev
        </Button>
        <Button
          style={buttonStyle}
          htmlType="submit"
        >
          Next
        </Button>
      </>
    );
  }

  actionButtons2() {
    const next = () => {
      this.otpSignIn();
    }

    const prev = () => {
      this.reSendOTP();
    }

    const buttonStyle = {
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
      marginRight: 8,
    }

    return (
      <>
        <Button
          style={buttonStyle}
          onClick={() => prev()}
        >
          Resend OTP
        </Button>
        <Button
          style={buttonStyle}
          onClick={() => next()}
        >
          Next
        </Button>
      </>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: "",
    })(this.renderPhonePrefix());

    return (
      <Form className="signup-body" onSubmit={this.handleSubmit.bind(this)}>
        <h2 style={{ fontFamily: "Lato" }}>Confirm details</h2>
        <p style={{ fontFamily: "Lato" }}>Your mobile number is already registered for Sasai Money Transfer. Kindly enter the below information to continue to login.</p>
        {this.renderStep1(getFieldDecorator, phonePrefix)}
        <div style={{ width: "100%", padding: "20px", textAlign: "left" }}>
          <b style={{ width: "100%", color: "red" }}>
            Fields with * are required.
          </b>
        </div>
        {this.actionButtons()}
      </Form>
    );
  }
}

const WrappedNameConfirmationForm = Form.create()(NameConfirmationForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestSendingCountries,
      requestSignUp,
      requestLogin,
      requestPCAPredictAddressFind,
      requestPCAPredictAddressRetrieve,
      requestCountries,
      setSendingCountry,
      requestOTPCodeClientSignUp,
      requestOTPSignIn,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(NameConfirmation);
