import React from 'react';
import { Menu, Dropdown, Card, Form, Layout, Icon, message, Select, InputNumber, Input } from 'antd';
import '../styles/qoute.css';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import {
  fetchUserDetails,
  requestSendingCountries,
  requestReceivingCountries,
  requestProductsAndServices,
  requestProductDetails,
  requestCalculationRequest,
  requestCalculation,
  patchRequestCalculation,
  showCalculation,
  showCalculationRequest,
  showSendingCountries,
  showReceivingCountry,
  showSendingCountry,
  showSendingCurrency,
  showReceivingCurrency,
  showRecipient,
  requestLimit,
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../../node_modules/flag-icon-css/css/flag-icon.css'
import ManageUrls from '../endpoints/manage_urls';
import baseUrlOptions from '../endpoints/base_url_options';

const { Content } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const TAG = "SmartQuote";

class Quote extends React.Component {

  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedHomeForm {... this.props} />
        </Content>
      </Layout>

    )
  }

  componentDidUpdate(prevProps) { }
}

class HomeForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
    var user = {};

    this.state = {
      products: [],
      service: 0,
      product: 0,
      sendingCountry: 0,
      sendingCountriesCopy: [],
      receivingCountry: 0,
      beneficiarySettings: {},
      transferReasons: [],
      defaultTransferReason: "",
      acceptedBeneficiaryTypes: [],
      acceptedRecipientTypes: [],
      paymentMethods: [],
      sendingCurrencies: [],
      receivingCurrencies: [],
      sendingCurrency: 0,
      receivingCurrency: 0,
      getFieldDecorator: this.props.form,
      user: user,
      sendingAmount: 0,
      receivingAmount: 0,
      isReverse: false,
      amountValue: "sending_amount",
      promoCode: "",
    };
  }

  manageUrls = new ManageUrls();
  baseCountryIsSA = () => this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA;

  componentDidMount() {
    this.setState({
      sendingCountriesCopy: this.checkHasSendingCountry()
        ? [this.props.countryReducer.sendingCountry]
        : this.props.countryReducer.sendingCountries
    }, () => {
      this.props.form.setFieldsValue({
        sending_country: this.state.sendingCountry,
        receiving_country: this.state.receivingCountry
      })
    });

    this.requestSendingCountry();
  }

  componentDidUpdate(prevProps) {

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      if (Object.values(this.props.userReducer.error).includes("rate request expired")) {
        message.loading("Fetching original rate ..", 3)
        this.requestCalculationRequest(this.state.amountValue, this.state.isReverse, this.props.form.getFieldValue(this.state.amountValue));
      } else {
        message.error("" + this.props.userReducer.error, 5)
      }
    }

    if (prevProps.countryReducer.receivingCountries !== this.props.countryReducer.receivingCountries) {
      if (this.props.countryReducer.receivingCountries.length > 0) {
        this.setState({
          receivingCountry: 0
        });
        this.requestProductsAndServices(this.props.countryReducer.receivingCountries[0]);
      }
    }

    // this is updating sending countries...
    if (prevProps.countryReducer.sendingCountries !== this.props.countryReducer.sendingCountries) {
      if (this.props.countryReducer.sendingCountries.length > 0) {
        const collection = this.checkHasSendingCountry()
          ? [this.props.countryReducer.sendingCountry]
          : this.props.countryReducer.sendingCountries
        this.setSendingCountry(collection);
      }
    }

    Log.d(TAG, "componentDidUpdate() requestProductsAndServices() -- prevProps services=" + JSON.stringify(prevProps.productServiceReducer))
    Log.d(TAG, "componentDidUpdate() requestProductsAndServices() -- services=" + JSON.stringify(this.props.productServiceReducer))
    if (prevProps.productServiceReducer.services !== this.props.productServiceReducer.services) {
      //Log.d(TAG, "componentDidUpdate() -- services=" + JSON.stringify(this.props.productServiceReducer.services))
      if (this.props.productServiceReducer.services.length > 0) {
        this.setState({
          service: 0,
        })
        this.props.form.setFieldsValue({
          service: 0
        })
        this.assignProducts(this.props.productServiceReducer.services[0]);
      }
    }

    if ((prevProps.productServiceReducer.product !== this.props.productServiceReducer.product) && Object.keys(this.props.productServiceReducer.product).length > 0) {
      var included = this.props.productServiceReducer.product.included;
      var attributes = this.props.productServiceReducer.product.data.attributes;
      var relationships = this.props.productServiceReducer.product.data.relationships;

      var sendingCurrencies = [];
      var receivingCurrencies = [];
      var paymentMethods = [];

      for (var i = 0; i < included.length; i++) {
        var includedObj = included[i];
        if (includedObj.type === "currencies") {
          if (this.hasCurrencyID(relationships.sending_currencies.data, includedObj.id)) {
            Log.d(TAG, "requestProductDetails() -- sending currency=" + includedObj.id);
            sendingCurrencies.push(includedObj);
          }

          if (this.hasCurrencyID(relationships.receiving_currencies.data, includedObj.id)) {
            Log.d(TAG, "requestProductDetails() -- receiving currency=" + includedObj.id);
            receivingCurrencies.push(includedObj);
          }
        } else if (includedObj.type === "payment_methods") {
          paymentMethods.push(includedObj);
        }
      }

      this.setState({
        beneficiarySettings: attributes.beneficiary_settings,
        transferReasons: attributes.transfer_reasons,
        defaultTransferReason: attributes.default_transfer_reason,
        acceptedBeneficiaryTypes: attributes.accepted_beneficiary_types,
        acceptedRecipientTypes: attributes.accepted_recipient_types,
        paymentMethods: paymentMethods,
        sendingCurrencies: sendingCurrencies,
        receivingCurrencies: receivingCurrencies
      })

      this.props.form.setFieldsValue({
        product: 0
      })
    }

    if ((prevProps.userReducer.user !== this.props.userReducer.user) && Object.keys(this.props.userReducer.user).length > 0) {

      var userIncluded = this.props.userReducer.user.included;
      if (userIncluded.length > 0) {
        var sendingCountries = [];
        for (var k = 0; k < this.props.userReducer.user.included.length; k++) {
          if (this.props.userReducer.user.included[k].type === "countries") {
            sendingCountries.push(userIncluded[k]);
          }
        }
        this.props.showSendingCountries(sendingCountries);
      }
      this.setSendingCountry(userIncluded);
    }

    if ((prevProps.calculationReducer.calculationRequest !== this.props.calculationReducer.calculationRequest) && Object.keys(this.props.calculationReducer.calculationRequest).length > 0) {
      if (this.props.calculationReducer.isReverse) {
        const sendingAmount = Number(this.props.calculationReducer.calculationRequest.data.attributes.sending_amount);
        this.props.form.setFieldsValue({
          sending_amount: sendingAmount
        })
        
        this.setState({
          sendingAmount,
        })
      } else {
        this.props.form.setFieldsValue({
          receiving_amount: this.props.calculationReducer.calculationRequest.data.attributes.recipient_amount
        })
      }
    }

    if ((prevProps.calculationReducer.calculation !== this.props.calculationReducer.calculation) && Object.keys(this.props.calculationReducer.calculation).length > 0) {
      var sendingCurrency = {}
      var receivingCurrency = {}
      if (this.state.sendingCurrencies.length > 0) {
        sendingCurrency = this.state.sendingCurrencies[this.state.sendingCurrency]
      }

      if (this.state.receivingCurrencies.length > 0) {
        receivingCurrency = this.state.receivingCurrencies[this.state.receivingCurrency]
      }

      this.props.showReceivingCountry(this.props.countryReducer.receivingCountries[this.state.receivingCountry]);
      this.props.showSendingCountry(this.state.sendingCountriesCopy[this.state.sendingCountry]);
      this.props.showSendingCurrency(sendingCurrency);
      this.props.showReceivingCurrency(receivingCurrency);
      this.props.showCalculation(this.props.calculationReducer.calculation);

      Log.d(TAG, "componentDidUpdate() -- current product=" + JSON.stringify(this.props.productServiceReducer.product));

      // when the user has no activity before triggering this event at more than 20 mins,
      // we should check if the cookies has still access value
      //const { cookies } = this.props;
      //if (cookies.get('access')) {
      this.props.showRecipient({}, false)
      if (this.props.productServiceReducer.product.data.attributes.name.includes("Home Wallet")) {
        var dReason = this.checkDefaultReason();
        this.props.history.push({
          pathname: '/select-payment-method',
          state: {
            sendingCountry: this.state.sendingCountriesCopy[this.state.sendingCountry],
            receivingCountry: this.props.countryReducer.receivingCountries[this.state.receivingCountry],
            product: this.props.productServiceReducer.product,
            calculation: this.props.calculationReducer.calculation,
            recipient: null,
            paymentMethods: this.state.paymentMethods,
            cards: this.checkUserCards(),
            receivingCurrency: receivingCurrency,
            sendingCurrency: sendingCurrency,
            transferReasons: this.state.transferReasons,
            defaultTransferReason: dReason,
            transaction: {}
          }
        });
      } else {
        this.props.history.push({
          pathname: "/recipient-list",
          state: {
            sendingCountry: this.state.sendingCountriesCopy[this.state.sendingCountry],
            receivingCountry: this.props.countryReducer.receivingCountries[this.state.receivingCountry],
            product: this.props.productServiceReducer.product,
            calculation: this.props.calculationReducer.calculation,
            beneficiarySettings: this.state.beneficiarySettings,
            transferReasons: this.state.transferReasons,
            defaultTransferReason: this.state.defaultTransferReason,
            acceptedBeneficiaryTypes: this.state.acceptedBeneficiaryTypes,
            acceptedRecipientTypes: this.state.acceptedRecipientTypes,
            paymentMethods: this.state.paymentMethods,
            sendingCurrency: sendingCurrency,
            receivingCurrency: receivingCurrency
          }
        });
      }

      /*} else {
        this.props.history.push({
          pathname: "/login"
        });
      }*/
    }
  }

  checkUserCards() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var relationships = this.props.userReducer.user.data.relationships;
      if (relationships.hasOwnProperty("saved_cards")) {
        var savedCardsObj = relationships.saved_cards;
        if (savedCardsObj.hasOwnProperty("data")) {
          if (savedCardsObj.data != null) {
            return savedCardsObj.data;
          }
        }
      }
    }
    return [];
  }

  checkDefaultReason() {
    var defaultReason = this.state.defaultTransferReason;
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var idVerificationType = this.props.userReducer.user.data.attributes.id_verification_type
      Log.d(TAG, "checkDefaultReason() -- idVerificationType=" + idVerificationType);
      if (idVerificationType) {
        if (idVerificationType !== "SI") {
          Log.d(TAG, "checkDefaultReason() -- find remittance");
          for (var tf = 0; tf < this.state.transferReasons.length; tf++) {
            if (this.state.transferReasons[tf][1] === "Remittance") {
              Log.d(TAG, "checkDefaultReason() -- found remittance");
              defaultReason = this.state.transferReasons[tf][0]
            }
          }
        }
      }
    }
    return defaultReason;
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    if (this.state.sendingCountriesCopy.length > 0) {
      this.requestReceivingCountries(this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.alpha_2_code)
    } else {
      Log.d(TAG, "requestSendingCountries() -- request");
      const { cookies } = this.props;
      // if (cookies.get('access')) {
      if (!!this.props.authReducer.accessToken) {
        Log.d(TAG, "requestSendingCountries() -- has access");
        // this.props.fetchUserDetails(cookies, cookies.get('access'), false, "Fetched user's details")
        this.props.fetchUserDetails(cookies, this.props.authReducer.accessToken, false, "Fetched user's details")
      } else {
        Log.d(TAG, "requestSendingCountries() -- has no access");
        this.props.requestSendingCountries();
      }
    }
  }

  checkHasSendingCountry() {
    return this.props.countryReducer.sendingCountry.hasOwnProperty("attributes");
  }

  setSendingCountry(collection) {
    if (collection.length > 0) {
      Log.d(TAG, "setSendingCountry() -- country=" + JSON.stringify(collection[0]));
      this.setState({
        sendingCountry: 0,
        sendingCountriesCopy: collection
      });

      this.requestReceivingCountries(collection[0].attributes.alpha_2_code)
    }
  }

  requestReceivingCountries(sendingCountry) {
    if (this.props.countryReducer.receivingCountries.length > 0) {
      Log.d(TAG, "requestReceivingCountries() -- has receiving countries, request products and services");
      this.requestProductsAndServices(this.props.countryReducer.receivingCountries[this.state.receivingCountry]);
    } else {
      Log.d(TAG, "requestReceivingCountries() -- request receiving countries");
      this.props.requestReceivingCountries(sendingCountry)
    }
  }

  requestProductsAndServices(countryDetails) {
    //Log.d(TAG, "requestProductsAndServices() -- props=" + JSON.stringify(this.props));
    //Log.d(TAG, "requestProductsAndServices() -- sendingCountries=" + JSON.stringify(this.props.countryReducer.sendingCountries));
    if (this.state.sendingCountriesCopy.length > 0 && this.props.countryReducer.receivingCountries.length > 0) {
      //Log.d(TAG, "requestProductsAndServices() -- sendingCountry=" + this.state.sendingCountry);
      //Log.d(TAG, "requestProductsAndServices() -- sendingCountry obj=" + JSON.stringify(countryDetails));
      var sendingCountryAlpha = this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.alpha_2_code;
      var receivingCountryAlpha = countryDetails.attributes.alpha_2_code;
      //Log.d(TAG, "requestProductsAndServices() -- sendingCountryAlpha=" + sendingCountryAlpha + " receivingCountryAlpha=" + receivingCountryAlpha)
      this.props.requestProductsAndServices(receivingCountryAlpha, sendingCountryAlpha);
    }
  }

  assignProducts(chosenService) {
    Log.d(TAG, "assignProducts() -- chosen service=" + JSON.stringify(chosenService))
    var products = [];

    this.setState({
      products: products,
      product: 0
    })

    const selectProduct = { id: 0, attributes: { name: "Select a pickup point" } };
    products.push(selectProduct);

    Log.d(TAG, "assignProducts() -- chosen service=" + chosenService.id + " name=" + chosenService.attributes.name)
    for (var i = 0; i < this.props.productServiceReducer.products.length; i++) {
      var currentProduct = this.props.productServiceReducer.products[i];
      Log.d(TAG, "assignProducts() -- currentProduct=" + JSON.stringify(this.props.productServiceReducer.products[i]))
      if (chosenService.id === currentProduct.relationships.group.data.id) {
        products.push(currentProduct);
      }
    }
    this.setState({
      products: products
    })

    Log.d(TAG, "assignProducts() -- products=" + JSON.stringify(products))

    if (products.length > 0) {
      Log.d(TAG, "assignProducts() -- product field value=" + this.props.form.getFieldValue('product'))
      Log.d(TAG, "assignProducts() -- first products=" + products[0].attributes.name)
      this.requestProductDetails(products[0].id);
    }
  }

  requestProductDetails(id) {
    var sendingCountryAlpha = this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.alpha_2_code;
    var receivingCountryAlpha = this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.alpha_2_code;
    Log.d(TAG, "requestProductDetails() -- products=" + JSON.stringify(this.state.products))
    Log.d(TAG, "requestProductDetails() -- id=" + id)
    Log.d(TAG, "requestProductDetails() -- sendingCountryAlpha=" + sendingCountryAlpha + " receivingCountryAlpha=" + receivingCountryAlpha)

    this.props.form.setFieldsValue({
      receiving_amount: ""
    })

    this.props.form.setFieldsValue({
      sending_amount: ""
    })

    this.setState({
      sendingAmount: 0,
      receivingAmount: 0,
      receivingCurrencies: [],
      sendingCurrencies: [],
    })

    this.props.showCalculation({});
    this.props.showCalculationRequest({});

    var token = null;
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      // token = cookies.get('access');
      token = this.props.authReducer.accessToken;
    }
    this.props.form.setFieldsValue({
      product: 0
    })

    if(id !== 0) {
      this.props.requestProductDetails(id, token, receivingCountryAlpha, sendingCountryAlpha);
    }
  }

  checkCalculationRequest(field, isReverse, amount, errorMessage) {
    Log.d(TAG, "checkCalculationRequest() -- isReverse=" + isReverse + " amount=" + amount);
    Log.d(TAG, "checkCalculationRequest() -- number amount=" + amount);

    if (Helper.isValidAmount(amount) && !isReverse) {
      //amount = amount.replace(/\,/g,'');
      if ((this.state.sendingAmount !== amount) && !isReverse) {
        Log.d(TAG, "checkCalculationRequest() -- request crs for sending");
        this.setState({
          sendingAmount: amount
        })
        this.requestCalculationRequest(field, isReverse, amount)
      } else {
        Log.d(TAG, "checkCalculationRequest() -- same amount=" + amount + " saved=" + this.state.amount);
      }
    } else if (Helper.isValidWholeAmount(amount) && isReverse) {
      if ((this.state.receivingAmount !== amount) && isReverse) {
        Log.d(TAG, "checkCalculationRequest() -- request crs for receiving");
        this.setState({
          receivingAmount: amount
        })
        this.requestCalculationRequest(field, isReverse, amount)
      } else {
        Log.d(TAG, "checkCalculationRequest() -- same amount=" + amount + " saved=" + this.state.amount);
      }
    } else {
      this.props.form.setFields({
        [field]: {
          errors: [new Error(errorMessage)],
        },
      });
      this.setState({
        calculationRequest: {},
        calculation: {},
      });
      this.props.showCalculation({});
      this.props.showCalculationRequest({});
    }
  }

  requestCalculationRequest(field, isReverse, amount) {
    Log.d(TAG, "requestCalculationRequest() -- amount=" + amount + " isReverse=" + isReverse);
    Log.d(TAG, "requestCalculationRequest() -- product=" + JSON.stringify(this.props.productServiceReducer.product));

    var sendingCountryData = {};
    sendingCountryData.type = "countries";
    sendingCountryData.id = this.state.sendingCountriesCopy[this.state.sendingCountry].id;
    var sendingCountryObj = {};
    sendingCountryObj.data = sendingCountryData;

    var sendingCurrencyData = {};
    sendingCurrencyData.type = "currencies";
    sendingCurrencyData.id = this.state.sendingCurrencies[this.state.sendingCurrency].id;
    var sendingCurrencyObj = {};
    sendingCurrencyObj.data = sendingCurrencyData;

    var receivingCountryData = {};
    receivingCountryData.type = "countries";
    receivingCountryData.id = this.props.countryReducer.receivingCountries[this.state.receivingCountry].id;
    var receivingCountryObj = {};
    receivingCountryObj.data = receivingCountryData;

    var receivingCurrencyData = {};
    receivingCurrencyData.type = "currencies";
    receivingCurrencyData.id = this.state.receivingCurrencies[this.state.receivingCurrency].id;
    var receivingCurrencyObj = {};
    receivingCurrencyObj.data = receivingCurrencyData;

    var productData = {};
    productData.type = "products";
    productData.id = this.props.productServiceReducer.product.data.id;
    var productObj = {};
    productObj.data = productData;

    var relationshipsObj = {};
    relationshipsObj.receiving_country = receivingCountryObj;
    relationshipsObj.sending_country = sendingCountryObj;
    relationshipsObj.sending_currency = sendingCurrencyObj;
    relationshipsObj.receiving_currency = receivingCurrencyObj;
    relationshipsObj.product = productObj;

    var attributesObj = {};
    attributesObj.receive = isReverse;
    attributesObj.amount = amount;

    var dataObj = {};
    dataObj.type = "calculation_requests";
    dataObj.id = null;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipsObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestCalculationRequest() -- payload=" + JSON.stringify(payloadObj));
    const accessToken = this.props.authReducer.accessToken;
    this.props.requestCalculationRequest(accessToken, payloadObj, isReverse);
  }

  requestCalculation(crs) {
    var attributesObj = {};
    attributesObj.calculation_request = crs;

    var dataObj = {};
    dataObj.type = "calculations";
    dataObj.id = null;
    dataObj.attributes = attributesObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestCalculation() -- payload=" + JSON.stringify(payloadObj));
    const accessToken = this.props.authReducer.accessToken;
    this.props.requestCalculation(accessToken, payloadObj);
  }

  hasCurrencyID(collection, id) {
    if (collection.filter(e => e.id === id).length > 0) {
      return true;
    }
    return false;
  }

  handleSubmit(e) {
    Log.d(TAG, "handleSubmit() -- called ..")
    let proceedWithTransaction = true; // update value, on confirm about mobile wallet
    const productId = this.props.productServiceReducer.product.data.relationships.group.data.id;

    if (productId === "6" || productId === "5") { // if product is product mobile wallet, mobile wallet productId === "6" OR is product mobile money, mobile money productId === "5"
      proceedWithTransaction =
        window.confirm("Recipient to confirm if their mobile wallet balance can receive the full amount sent. Reversal charges will be incurred for any recall of transactions sent to an incorrect wallet. Please check terms and conditions for more details.");
    }

    if (proceedWithTransaction) {
      e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          Log.d(TAG, "handleSubmit() -- calculation request=" + JSON.stringify(this.props.calculationReducer.calculationRequest))
          Log.d(TAG, "handleSubmit() -- sending countries=" + JSON.stringify(this.props.countryReducer.sendingCountries))
          Log.d(TAG, "handleSubmit() -- services=" + JSON.stringify(this.props.productServiceReducer.services))
          Log.d(TAG, "handleSubmit() -- products=" + JSON.stringify(this.props.productServiceReducer.products))
          Log.d(TAG, "handleSubmit() -- receiving countries=" + JSON.stringify(this.props.countryReducer.receivingCountries))
          if (this.props.countryReducer.receivingCountries.length > 0
            && this.props.countryReducer.sendingCountries.length > 0
            && this.props.productServiceReducer.services.length > 0
            && this.props.productServiceReducer.products.length > 0
            && Object.keys(this.props.calculationReducer.calculationRequest).length > 0) {
            Log.d(TAG, "handleSubmit() -- calculation ")
            let acceptedPromoCode = this.props.calculationReducer.calculationRequest.data.attributes.promocode;
            if (!!acceptedPromoCode) {
              this.props.showRecipient({}, false);
              this.props.history.push({
                pathname: "/recipient-list",
                state: {
                  sendingCountry: this.state.sendingCountriesCopy[this.state.sendingCountry],
                  receivingCountry: this.props.countryReducer.receivingCountries[this.state.receivingCountry],
                  product: this.props.productServiceReducer.product,
                  calculation: this.props.calculationReducer.calculation,
                  beneficiarySettings: this.state.beneficiarySettings,
                  transferReasons: this.state.transferReasons,
                  defaultTransferReason: this.state.defaultTransferReason,
                  acceptedBeneficiaryTypes: this.state.acceptedBeneficiaryTypes,
                  acceptedRecipientTypes: this.state.acceptedRecipientTypes,
                  paymentMethods: this.state.paymentMethods,
                  sendingCurrency: this.state.sendingCurrencies[this.state.sendingCurrency],
                  receivingCurrency: this.state.receivingCurrencies[this.state.receivingCurrency]
                }
              });
            } else {
              this.requestCalculation(this.props.calculationReducer.calculationRequest.data.id);
            }
          }
        }
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content>
        <Form
          onSubmit={this.handleSubmit.bind(this)}
          hideRequiredMark={true}>
          <div className="row">
            <div className="col-sm-7">
              <Card style={{ fontFamily: 'Lato', borderRadius: '4px' }} title="Choose your payout details" >
                {this.renderRecipientReq(getFieldDecorator)}
              </Card>
            </div>
            <div className="col-sm-5">
              {this.baseCountryIsSA() && this.renderFICA()}
              {this.renderRateArea(getFieldDecorator)}
            </div>
          </div>
        </Form>
      </Content>
    );
  }

  renderFICA() {
    Log.d(TAG, "renderFICA() -- called ..")
    Log.d(TAG, "renderFICA() -- substatus=" + JSON.stringify(this.props.userReducer.user));
    if (Object.keys(this.props.userReducer.user).length > 0) {

      // if (this.props.userReducer.user.data.attributes.user_sub_status === "AL") {
      //   Log.d(TAG, "renderFICA() -- FICA Light")
      //   return this.renderFICALight();
      // //  } else if (this.props.userReducer.user.data.attributes.user_sub_status === "AF") {
      //  } else if (true) {
      //   Log.d(TAG, "renderFICA() -- FICA Full")
      //   return (
      //     <Card >
      //      {this.renderFICAFull()}
      //     </Card>
      //   );
      // } else {
      //   Log.d(TAG, "renderFICA() -- No Fica")
      //   return this.renderNoSubStatus();
      // }
      return <FICALimit {...this.props} />;
    } else {
      Log.d(TAG, "renderFICA() -- No Fica available")
      return this.renderNoSubStatus();
    }
  }

  renderFICALight() {
    return (
      <div className="row">
        <div className="col">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="quote-title">Your Limits</span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Daily limit</h6>
              </div>
              <span className="text-muted">ZAR 3, 000</span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Monthly limit</h6>
              </div>
              <span className="text-muted">ZAR 10, 000</span>
            </li>
          </ul>
        </div>
      </div>

    )
  }

  renderFICAFull() {
    return (
      <div className="row">
        <div className="col">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your Limits</span>
          </h4>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Daily limit</h6>
                <small className="text-muted"></small>
              </div>
              <span className="text-muted">ZAR 5, 000</span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Monthly limit</h6>
                <small className="text-muted"></small>
              </div>
              <span className="text-muted">ZAR 25, 000</span>
            </li>
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">Yearly limit</h6>
                <small className="text-muted"></small>
              </div>
              <span className="text-muted">ZAR 120, 000</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  renderNoSubStatus() {
    return (
      <div />
    )
  }

  renderRecipientReq(getFieldDecorator) {
    return (
      <div className="home-item">
        <FormItem label="From" className="quote-form--1">
          {getFieldDecorator('sending_country', {
            rules: [{ required: true, message: 'Please provide your country' }],
          })(
            <Dropdown
              overlay={this.renderSendingCountriesParent()}
              trigger={['click']}>
              <div className="dropdown-item">
                <div className="flag-country">
                  {this.renderFlag(true)}
                  {this.renderSendingCountriesDropdown()}
                </div>
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        <FormItem label="To" className="quote-form--1">
          {getFieldDecorator('receiving_country', {
            rules: [{ required: true, message: 'Please provide recipient\'s country' }],
          })(
            <Dropdown
              overlay={this.renderReceivingCountriesParent()}
              trigger={['click']}>
              <div className="dropdown-item">
                <div className="flag-country">
                  {this.renderFlag(false)}
                  {this.renderReceivingCountriesDropdown()}
                </div>
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        {this.renderServicesArea(getFieldDecorator)}
        {this.renderProductsArea(getFieldDecorator)}
        <div className="quote-left-title-text">
          <div className="quote-lines"> Calculate amount to send or receive </div>
        </div>
        <div className="quote-amount-parent-box">
          <div className="quote-amount-left-column">
            {this.renderSendingCurrencies()}
            {this.renderSendingAmount(getFieldDecorator)}
          </div>
        </div>
        <div className="quote-amount-parent-box">
          <div className="quote-amount-left-column">
            {this.renderReceivingCurrencies()}
            {this.renderReceivingAmount(getFieldDecorator)}
          </div>
        </div>

      </div>

    )
  }

  renderFlag(sendingCountry) {
    Log.d(TAG, "renderFlag() -- sending countries=" + JSON.stringify(this.props.countryReducer.sendingCountries) + " receiving countries=" + JSON.stringify(this.props.countryReducer.receivingCountries));
    var flag = ""
    if (sendingCountry) {
      if (this.state.sendingCountriesCopy.length > 0) {
        Log.d(TAG, "renderFlag() -- show sending country flag");
        Log.d(TAG, "renderFlag() -- sendingCountry=" + this.state.sendingCountry);
        Log.d(TAG, "renderFlag() -- sendingCountry=" + JSON.stringify(this.props.countryReducer.sendingCountries[this.state.sendingCountry]));
        flag = "flag-icon flag-icon-" + this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.alpha_2_code.toLowerCase();
        Log.d(TAG, "renderFlag() -- flag sending=" + flag);
      }
    } else {
      if (this.props.countryReducer.receivingCountries.length > 0
        && this.props.countryReducer.receivingCountries[this.state.receivingCountry]) {
        Log.d(TAG, "renderFlag() -- show receiving country flag");
        flag = "flag-icon flag-icon-" + this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.alpha_2_code.toLowerCase();
        Log.d(TAG, "renderFlag() -- flag receiving=" + flag);
      }
    }
    Log.d(TAG, "renderFlag() -- flag=" + flag);
    return (
      <span class={flag} />
    )
  }

  renderSendingAmount(getFieldDecorator) {
    if (Object.keys(this.state.sendingCurrencies).length > 0) {
      return (
        <div style={{ width: '90%' }}>
          <FormItem>
            {getFieldDecorator('sending_amount', {
              rules: [{ required: true, message: 'This field is required' },
              { validator: this.validateAmountInput }],
            })(
              <InputNumber className="form-control"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                placeholder="You are sending (excluding fees)"
                onChange={this.onChangeSendingAmount} />
            )}
          </FormItem>
        </div>
      )
    } else {
      return (<div />)
    }
  }

  renderSendingCurrencies() {
    Log.d(TAG, "renderSendingCurrencies() -- currencies=" + JSON.stringify(this.state.sendingCurrencies))
    if (Object.keys(this.state.sendingCurrencies).length > 0) {
      return (
        <Select value={this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code}
          style={{ width: '80px', marginRight: 4 }}
          onChange={this.onChangeSendingCurrency}>
          {this.state.sendingCurrencies.map((itemAr, index) => {
            return (
              <Option key={index}>{itemAr.attributes.letter_code}</Option>
            );
          })}
        </Select>
      )
    } else {
      return (<div />)
    }
  }

  renderReceivingAmount(getFieldDecorator) {
    if (Object.keys(this.state.receivingCurrencies).length > 0) {
      return (
        <div style={{ width: '90%' }}>
          <FormItem>
            {getFieldDecorator('receiving_amount', {
              rules: [
                { required: true, message: 'This field is required' },
                { validator: this.validateAmountInput }],
            })(
              <InputNumber className="form-control"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                placeholder="Recipient will receive"
                onChange={this.onChangeReceivingAmount} />
            )}
          </FormItem>
        </div>
      )
    } else {
      return (<div />)
    }
  }

  renderReceivingCurrencies() {
    Log.d(TAG, "renderReceivingCurrencies() -- currencies=" + JSON.stringify(this.state.receivingCurrencies))
    if (Object.keys(this.state.receivingCurrencies).length > 0) {
      return (
        <Select value={this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code}
          style={{ width: '80px', marginRight: 4 }}
          onChange={this.onChangeReceivingCurrency}>
          {this.state.receivingCurrencies.map((itemAr, index) => {
            return (
              <Option key={index}>{itemAr.attributes.letter_code}</Option>
            );
          })}
        </Select>
      )
    } else {
      return (<div />)
    }
  }

  validateAmountInput = (rule, value, callback) => {
    if (Helper.isValidAmount(value)) {
      callback();
    } else {
      callback('Invalid amount. Amount should be positive and have maximum 2 decimal places');
    }
  }

  validateWholeAmountInput = (rule, value, callback) => {
    Log.d(TAG, "validateWholeAmountInput() -- called ..")
    if (Helper.isValidWholeAmount(value)) {
      callback();
    } else {
      callback('Invalid amount. Amount should be positive.');
    }
  }

  renderServicesArea(getFieldDecorator) {
    Log.d(TAG, "renderServicesArea() -- services=" + JSON.stringify(this.props.productServiceReducer.services) + " state=" + JSON.stringify(this.state))
    if (this.props.productServiceReducer.services) {
      if (this.props.productServiceReducer.services.length > 0) {
        return (
          <div className="quote-form--1">
            <FormItem label="Delivery Method">
              {getFieldDecorator('service', {
                rules: [{ required: true, message: 'Please delivery method' }],
              })(
                <Dropdown
                  overlay={this.renderServicesParent()}
                  trigger={['click']}>
                  <div className="dropdown-item">
                    {this.renderServicesDropdown()}
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
            </FormItem>
          </div>
        )
      }
    }

    return (<div />)
  }

  renderProductsArea(getFieldDecorator) {
    if ((this.props.productServiceReducer.products && this.state.products[this.state.product])
      && this.state.products[this.state.product].hasOwnProperty("attributes")
      && this.props.countryReducer.receivingCountries[this.state.receivingCountry]
    ) {
      Log.d(TAG, "renderProductsArea() -- product shown=" + JSON.stringify(this.state.products[this.state.product]))
      if (
        (
          this.props.productServiceReducer.products.length > 0
          // && this.state.products[this.state.product].attributes.name !== "Steward Bank" 
          // && this.baseCountryIsSA
        )
        // only display Pickup point if products are more than 1 OR receiving country is Zimbabwe
        &&
        (
          this.state.products.length > 1
          // || this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.name === "Zimbabwe"
        )
      ) {
        return (
          <div className="quote-form--1">
            <FormItem label="Pickup point">
              {getFieldDecorator('product', {
                rules: [{ required: true, message: 'Please select pickup point' }],
              })(
                <Dropdown
                  overlay={this.renderProductParent()}
                  trigger={['click']}>
                  <div className="dropdown-item">
                    {this.renderProductsDropdown()}
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
            </FormItem>
            {this.renderProductsInformation()}
          </div>
        )
      }
    }
    return (
      <div className="quote-form--1">
        {this.renderProductsInformation()}
      </div>
    )
  }

  renderProductsInformation() {
    Log.d(TAG, "renderProductsInformation() -- products=" + JSON.stringify(this.props.productServiceReducer.products))
    Log.d(TAG, "renderProductsInformation() -- services=" + JSON.stringify(this.props.productServiceReducer.services))
    if (Object.keys(this.props.productServiceReducer.products).length > 0 && this.state.product < Object.keys(this.props.productServiceReducer.products).length) {
      var info = this.props.productServiceReducer.products[this.state.product].attributes.important_info;
      if (this.props.productServiceReducer.services.length > 0 && this.state.service < this.props.productServiceReducer.services.length) {
        if (this.props.productServiceReducer.services[this.state.service].attributes.name === "Cash pick-up") {
          if (info) {
            info = "Cash guaranteed"
          } else {
            info = "Cash guaranteed \n\n" + info
          }
        }

        let cashPickUpMsg = '';
        // Display a pop-up message when a customer selects “Cash Pick-Up” as a delivery method.
        // when Zimbabwe is selected the first option is Cash Pick-up this is why the pop up is displayed on  
        // onChangeReceivingCountry when country equal to Zimbabwe and when onChangeService is called
        // and this.props.productServiceReducer.services[this.state.service].attributes.name === "Cash pick-up"

        if (this.props.countryReducer.receivingCountries[this.state.receivingCountry]) {
          if (this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.name === "Zimbabwe"
            && !!this.props.authReducer.accessToken && this.props.productServiceReducer.services[this.state.service].attributes.name === "Cash pick-up") {
            cashPickUpMsg = "Your recipient can collect funds at any BancABC and Steward Bank branches or selected Econet shops.";
          }
        }

        return (
          <div style={{}}>
            <p className="quote-product-info-text">
              {info}
            </p>
            {cashPickUpMsg && (
              <p className="quote-product-info-text">
                {cashPickUpMsg}
              </p>
            )}
          </div>
        )
      }
    }
    return (
      <div />
    )
  }

  renderRateArea(getFieldDecorator) {
    var action = "Get Started For Free"
    var whoReceives = "Beneficiary to Receive"

    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      action = "Choose your recipient"
    }

    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      if (this.props.productServiceReducer.product.data.attributes.name.includes("Home Wallet")) {
        action = "Top Up Now"
        whoReceives = "You receive"
      }
    }

    if (Object.keys(this.props.calculationReducer.calculationRequest).length > 0 && this.state.sendingCurrencies.length > 0 && this.state.receivingCurrencies.length > 0) {

      Log.d(TAG, "renderRateArea() -- cr=" + JSON.stringify(this.props.calculationReducer.calculationRequest));
      var sendStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.amount_to_pay).toLocaleString()
      var sendingAmount = Number(this.props.calculationReducer.calculationRequest.data.attributes.sending_amount).toLocaleString()
      var sendMinStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.sending_min_limit).toLocaleString()
      // var sendMaxStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.sending_max_limit).toLocaleString()
      var feesStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.fees).toLocaleString()
      var recipientStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.recipient_amount).toLocaleString()
      var receiveMinStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.receiving_min_limit).toLocaleString()
      // var receiveMaxStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.receiving_max_limit).toLocaleString()
      // var reverseRateStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.reverse_rate).toLocaleString()
      // var directRateStr = Number(this.props.calculationReducer.calculationRequest.data.attributes.rate).toLocaleString()
      var directRateStr = this.props.calculationReducer.calculationRequest.data.attributes.rate
      let calculationRequestId = this.props.calculationReducer.calculationRequest.data.id
      // let access = this.props.cookies && cookies.get("access") 
      let access = !!this.props.authReducer.accessToken
      let acceptedPromoCode = this.props.calculationReducer.calculationRequest.data.attributes.promocode;

      return (
        <div className="row">
          <div className="col">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="quote-title" style={{ marginTop: '20px' }}>Your generated rate</span>
            </h4>
            <ul className="list-group mb-3">
              {/* Alternatively display the paragraph below */}
              {
                // (acceptedPromoCode == undefined) && (
                //   <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                //   <b>You are sending in {this.state.sendingCurrencies[this.state.sendingCurrency].attributes.name}, while recipient will receive in {this.state.receivingCurrencies[this.state.receivingCurrency].attributes.name}</b>
                //   </li>
                // )
              }
              {/* Alternatively display the paragraph below */}
              {
                (sendMinStr !== "NaN" && receiveMinStr !== "NaN") &&
                (
                  <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                    <b style={{ color: 'red' }}>Minimum send value is {this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {sendMinStr} or {this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code} {receiveMinStr}</b>
                  </li>
                )
              }
              {/* Add messaging when promocode has been applied successfully */}
              {
                (!!acceptedPromoCode) && (
                  <div>
                    <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                      <div className="d-flex justify-content-between">
                        <h6 className="my-0">
                          <b className="text-secondary">Promocode applied successfully, please proceed</b>
                        </h6>
                      </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                      <div className="col-12">
                        <button onClick={e => { this.onApplyPromoCode(e, calculationRequestId) }} className="btn btn-danger btn-block btn-sm col-12">Remove Promotion Code</button>
                      </div>
                    </li>
                  </div>
                )
              }
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Amount to Send </h6>
                </div>
                <span className="text-muted"><b>{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {sendingAmount}</b></span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">{whoReceives}</h6> {/*Beneficiary to Receive*/}
                </div>
                <span className="text-muted">{this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code} {recipientStr}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Our Transfer Fees </h6>
                </div>
                <span className="text-muted">{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {feesStr}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">Amount to Pay </h6>
                </div>
                <span className="text-muted"><b>{this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} {sendStr}</b></span>
              </li>
              <li className="list-group-item d-flex justify-content-between lh-condensed bg-light">
                <div>
                  <h6 className="my-0">
                    {/* <b>Current rate 1 {this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code} = {reverseRateStr} {this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code}, excluding transfer fees</b> */}
                    <b>Current rate 1 {this.state.sendingCurrencies[this.state.sendingCurrency].attributes.letter_code} = {directRateStr} {this.state.receivingCurrencies[this.state.receivingCurrency].attributes.letter_code}, excluding transfer fees</b>
                  </h6>
                </div>
              </li>
              {
                // check if the user is authenticate by checking the presents of a "access" token and 
                // check the acceptedPromoCode if it is present then hide the promocode field because 
                // it would have been already applied
                (!!access && !acceptedPromoCode) && (
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div className="d-flex justify-content-between">
                      <Input className="col-6" placeholder="Optional Promotion Code" onChange={e => this.setState({ promoCode: e.target.value })} />
                      <button onClick={e => { this.onApplyPromoCode(e, calculationRequestId) }} className="col-6 btn btn-secondary btn-sm" style={{ fontSize: '.9em' }}>Apply Promotion Code</button>
                    </div>
                  </li>
                )
              }
              <li className="list-group-item d-flex justify-content-between lh-condensed ">
                <div style={{ width: '100%', marginTop: '20px' }}>
                  <FormItem>
                    <div className="form-group">
                      <button type="submit" className="quote-btn-register btn btn-primary"> {action}</button>
                    </div>
                  </FormItem>
                </div>
              </li>
            </ul>
          </div>

        </div>

      )
    } else {
      return (<div />)
    }
  }

  onServiceMethodAndReceivingCountry() {
    // Display a pop-up message when a customer selects “Cash Pick-Up” as a delivery method.
    // when Zimbabwe is selected the first option is Cash Pick-up this is why the pop up is displayed on  
    // onChangeReceivingCountry when country equal to Zimbabwe and when onChangeService is called
    // and this.state.service === 0 because cash pick-up is the first option on index 0
    if (this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.name === "Zimbabwe"
      && !!this.props.authReducer.accessToken && this.state.service === 0) {
      message.info("Your recipient can collect funds at any BancABC and Steward Bank branches or selected Econet shops.");
    }
  }

  renderSendingCountriesDropdown() {
    if (this.state.sendingCountriesCopy.length > 0) {
      Log.d("renderSendingCountriesDropdown() -- return has item")
      return (
        <div className="flag-country-name">
          {this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.name}
        </div>
      )
    }
    Log.d("renderSendingCountriesDropdown() -- return no item")
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderReceivingCountriesDropdown() {
    if (this.props.countryReducer.receivingCountries.length > 0
      && this.props.countryReducer.receivingCountries[this.state.receivingCountry]) {
      Log.d("renderReceivingCountriesDropdown() -- return has item")
      return (
        <div className="flag-country-name">
          {this.props.countryReducer.receivingCountries[this.state.receivingCountry].attributes.name}
        </div>
      )
    }
    Log.d("renderReceivingCountriesDropdown() -- return no item")
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderServicesDropdown() {
    if (this.props.productServiceReducer.services.length > 0 && this.state.service < this.props.productServiceReducer.services.length) {
      Log.d(TAG, "renderServicesDropdown() -- products=" + JSON.stringify(this.props.productServiceReducer.products));
      Log.d(TAG, "renderServicesDropdown() -- services=" + JSON.stringify(this.props.productServiceReducer.services) + " state service=" + this.state.service);
      Log.d(TAG, "renderServicesDropdown() -- service=" + JSON.stringify(this.props.productServiceReducer.services[this.state.service]));
      return (
        <div>
          {this.props.productServiceReducer.services[this.state.service].attributes.name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderProductsDropdown() {
    if (this.state.products.length > 0) {
      return (
        <div>
          {this.state.products[this.state.product].attributes.name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderSendingCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeSendingCountry}>
        {this.rendeSendingCountries()}
      </Menu>
    )
  }

  renderReceivingCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeReceivingCountry}>
        {this.renderReceivingCountries()}
      </Menu>
    )
  }

  renderServicesParent() {
    return (
      <Menu onClick={this.onChangeService}>
        {this.renderServices()}
      </Menu>
    )
  }

  renderProductParent() {
    return (
      <Menu onClick={this.onChangeProduct}>
        {this.renderProducts()}
      </Menu>
    )
  }

  rendeSendingCountries() {
    Log.d("renderSendingCountries() -- sending countries=" + JSON.stringify(this.state.sendingCountriesCopy));
    // we're replacing list from the reducer with the copy
    return this.state.sendingCountriesCopy.map((itemAr, index) => {
      Log.d("renderSendingCountries() -- sending country=" + JSON.stringify(itemAr));
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
      );
    })
  }

  renderReceivingCountries() {
    return this.props.countryReducer.receivingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
      );
    })
  }

  renderServices() {
    return this.props.productServiceReducer.services
    .sort((a, b) => a.attributes.ordering - b.attributes.ordering)
    .map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
      );
    })
  }

  renderProducts() {
    return this.state.products.map((itemAr, index) => {
      return (
        <Menu.Item key={index} disabled={index === 0}>{itemAr.attributes.name}</Menu.Item>
      );
    })
  }

  onRemovePromoCode = () => {
    const accessToken = this.props.authReducer.accessToken;
    this.props.requestCalculationRequest(accessToken, this.state.amountValue, this.state.isReverse);
  }

  onChangeSendingCountry = (event) => {
    this.setState({
      sendingCountry: event.key
    }, this.onUpdateSendingCountry)
    this.props.form.setFieldsValue({
      sending_country: event.key
    });

  }

  onUpdateSendingCountry() {
    let baseCountryAlphaCode = this.state.sendingCountriesCopy[this.state.sendingCountry].attributes.alpha_2_code;
    this.props.authReducer.accessToken || this.updateBaseCountry(baseCountryAlphaCode);
    this.props.requestReceivingCountries(baseCountryAlphaCode)
  }

  updateBaseCountry = (baseCountryAlphaCode) => {
    let manageUrls = new ManageUrls();
    if (baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA)
    } else {
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM)
    }
  }

  onChangeReceivingCountry = (event) => {
    this.setState({
      receivingCountry: event.key
    })
    this.props.form.setFieldsValue({
      receiving_country: event.key
    })
    this.requestProductsAndServices(this.props.countryReducer.receivingCountries[event.key]);
  }

  onChangeService = (event) => {
    this.setState({
      service: event.key
    })
    this.props.form.setFieldsValue({
      service: event.key
    })

    if (this.props.productServiceReducer.products.length > 0) {
      this.assignProducts(this.props.productServiceReducer.services[event.key]);
    }
  }

  onChangeProduct = (event) => {
    this.setState({
      product: event.key
    })
    this.props.form.setFieldsValue({
      product: event.key
    })
    Log.d(TAG, "onChangeProduct() -- product=" + event.key + " id=" + this.state.products[event.key].id)
    this.requestProductDetails(this.state.products[event.key].id);
  }

  onChangeSendingCurrency = (value) => {
    this.setState({
      sendingCurrency: value
    })
    this.setState({
      amountValue: 'sending_amount',
      isReverse: false
    })
    setTimeout(() => {
      this.checkCalculationRequest('sending_amount', false, this.props.form.getFieldValue('sending_amount'), "Please enter a positive amount with or without 2 decimal places")
    }, 700);
  }

  onChangeReceivingCurrency = (value) => {
    this.setState({
      receivingCurrency: value
    })
    this.setState({
      amountValue: 'receiving_amount',
      isReverse: true
    })
    setTimeout(() => {
      this.checkCalculationRequest('receiving_amount', true, this.props.form.getFieldValue('receiving_amount'), "Please enter a positive amount")
    }, 700);
  }

  onChangeSendingAmount = (rawValue) => {
    Log.d(TAG, "onChangeSendingAmount() -- value=" + rawValue);
    this.props.form.setFieldsValue({
      sending_amount: rawValue,
    })
    this.setState({
      amountValue: 'sending_amount',
      isReverse: false
    })
    setTimeout(() => {
      this.checkCalculationRequest('sending_amount', false, this.props.form.getFieldValue('sending_amount'), "Please enter a positive amount with or without 2 decimal places")
    }, 700);
  }

  onChangeReceivingAmount = (rawValue) => {
    this.props.form.setFieldsValue({
      receiving_amount: rawValue
    })
    this.setState({
      amountValue: 'receiving_amount',
      isReverse: true
    })
    setTimeout(() => {
      this.checkCalculationRequest('receiving_amount', true, this.props.form.getFieldValue('receiving_amount'), "Please enter a positive amount")
    }, 700);
  }

  onApplyPromoCode = (e, calculationRequestId) => {
    e.preventDefault();
    this.setState({ hidePromoCodeField: true });
    // this.requestCalculation(this.props.calculationReducer.calculationRequest.data.id);
    let payload = {
      "data": {
        "type": "calculations",
        // the id is set undefined for now and will be set in 
        // this function: this.props.patchRequestCalculation(rcPayload, payload) function
        "id": undefined,
        "attributes": {
          "promocode_value": this.state.promoCode
        }
      }
    }

    let rcPayload = {
      "data": {
        "type": "calculations",
        "id": null,
        "attributes": {
          "calculation_request": calculationRequestId
        }
      }
    }
    // "sending_min_limit", "sending_max_limit", "receiving_min_limit", "receiving_max_limit"
    let prepMissingLimitValues = this.props.calculationReducer.calculationRequest;
    let accessToken = this.props.authReducer.accessToken;
    this.props.patchRequestCalculation(rcPayload, payload, prepMissingLimitValues, accessToken);
  }
}

const WrappedHomeForm = Form.create()(HomeForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestSendingCountries,
    requestReceivingCountries,
    requestProductsAndServices,
    requestProductDetails,
    requestCalculationRequest,
    requestCalculation,
    patchRequestCalculation,
    showCalculation,
    showCalculationRequest,
    showSendingCountries,
    showReceivingCountry,
    showSendingCountry,
    showSendingCurrency,
    showReceivingCurrency,
    showRecipient,
    requestLimit,
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return { ...state };
}

class FICALimit extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      limitOptions: ["Day", "Month", "Year"],
      chosenLimitOption: 0,
      note: "",
      cumulative: "0.00",
      remaining: "0.00",
      total: "0.00",
      cumulativeP: 0,
      remainingP: 0,

      dailyLimitCumulative: "0.00",
      dailyLimitRemaining: "0.00",
      dailyLimitCumulativeP: 0,
      dailyLimitRemainingP: 0,

      monthlyLimitCumulative: "0.00",
      monthlyLimitRemaining: "0.00",
      monthlyLimitCumulativeP: 0,
      monthlyLimitRemainingP: 0,

      yearlyLimitCumulative: "0.00",
      yearlyLimitRemaining: "0.00",
      yearlyLimitCumulativeP: 0,
      yearlyLimitRemainingP: 0,
    }
  }

  componentDidMount() {
    // if (this.props.cookies.cookies.access) {
    if (this.props.authReducer.accessToken) {
      // this.props.requestLimit(this.props.userReducer.user.data.id, this.props.cookies.cookies.access, "user-profile");
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "user-profile");
    }
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.userReducer.limit).length > 0 && this.props.userReducer.limit !== prevProps.userReducer.limit) {
      var cpd = this.props.userReducer.limit.data.attributes.cumulative_per_day;
      var cpm = this.props.userReducer.limit.data.attributes.cumulative_per_month;
      var cpy = this.props.userReducer.limit.data.attributes.cumulative_per_year;

      var tpd = this.props.userReducer.limit.data.attributes.total_per_day;
      var tpm = this.props.userReducer.limit.data.attributes.total_per_month;
      var tpy = this.props.userReducer.limit.data.attributes.total_per_year;

      this.setState({
        dailyLimitCumulative: cpd,
        dailyLimitRemaining: String(Number(tpd) - Number(cpd)),
        // dailyLimitCumulativeP: (Number(cpd) / Number(tpd)) * 100,
        // dailyLimitRemainingP: ((Number(tpd) - Number(cpd)) / Number(tpd)) * 100,

        // monthlyLimitCumulative: cpm,
        monthlyLimitRemaining: String(Number(tpm) - Number(cpm)),
        // monthlyLimitCumulativeP: (Number(cpm) / Number(tpm)) * 100,
        // monthlyLimitRemainingP: ((Number(tpm) - Number(cpm)) / Number(tpm)) * 100,

        // yearlyLimitCumulative: cpy,
        yearlyLimitRemaining: String(Number(tpy) - Number(cpy)),
        // yearlyLimitCumulativeP: (Number(cpy) / Number(tpy)) * 100,
        // yearlyLimitRemainingP: ((Number(tpy) - Number(cpy)) / Number(tpy)) * 100,

        cumulative: "0.00",
        remaining: "0.00",
        cumulativeP: 0,
        remainingP: 0
      })
    }
  }

  render() {
    return (
      <div className="ant-card ant-card-bordered">
        <div className="ant-card-body">
          <div className="row">
            <div className="col">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your Sending Limits</span>
              </h4>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Daily usage</h6>
                    <small className="text-muted"></small>
                  </div>
                  <span className="text-muted">ZAR {parseInt(this.state.dailyLimitCumulative).toLocaleString()}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Daily remaining</h6>
                    <small className="text-muted"></small>
                  </div>
                  <span className="text-muted">ZAR {parseInt(this.state.dailyLimitRemaining).toLocaleString()}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Monthly remaining</h6>
                    <small className="text-muted"></small>
                  </div>
                  <span className="text-muted">ZAR {parseInt(this.state.monthlyLimitRemaining).toLocaleString()}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">Yearly remaining</h6>
                    <small className="text-muted"></small>
                  </div>
                  <span className="text-muted">ZAR {parseInt(this.state.yearlyLimitRemaining).toLocaleString()}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Quote)
