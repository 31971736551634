import React from 'react';
import { Layout } from 'antd';
import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import SmartGuestTransactionDetails from '../containers/GuestTransactionDetails.js'
import Log from '../utils/log.js';
import { withCookies } from 'react-cookie';
import '../styles/transaction.css'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Header, Footer } = Layout;
const TAG = "GuestTransactionDetails";

class GuestTransactionDetails extends React.Component {

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    document.title = "Guest Transaction Details"
    window.scrollTo(0, 0)
  }

  renderBody() {
    if (!!this.props.authReducer.guestUserAccessToken) {
      return <SmartGuestTransactionDetails {... this.props} />
    } else {
      return this.props.history.push("/");
    }
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1' }}>
          <CustomHeader {... this.props} />
        </Header>
        {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    )
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(GuestTransactionDetails));

// export default withCookies(GuestTransactionDetails)
