import React from "react";
import Log from "../utils/log.js";
import { Layout, Button } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content } = Layout;
const TAG = "SmartShareALinkOrCode";

const buttonStyle = {
  backgroundColor: "rgb(41, 108, 187)",
  borderColor: "rgb(41, 108, 187)",
  boxSizing: "border-box",
  color: "white",
  border: "1px solid rgb(41, 108, 187)",
  marginRight: 8,
};

class ShareALinkOrCode extends React.Component {
  host = window.location.origin;

  code = this.props.userReducer.user.data.attributes.code;
  link = `${this.host}/welcome-registration/${this.code}`

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            <h2 style={{ fontFamily: "Lato" }}>Share a link or code</h2>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: "50px 0",
                    }}
                  >
                    <Button
                      style={buttonStyle}
                      onClick={() => navigator.clipboard.writeText(this.link)}
                    >
                      Copy my referral link
                    </Button>
                    <Button
                      style={buttonStyle}
                      onClick={() => navigator.clipboard.writeText(this.code)}
                    >
                      Copy my referral my code
                    </Button>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      // requestSendingCountries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareALinkOrCode);
