import LocalStorageService from "./local_storage_service";

class RememberMe {
  static _localStorageService = new LocalStorageService();

  static get value() {
    return this._localStorageService.retrieveObjectFromLocalStorage("remember_me")
  }

  static setValue(value) {
    this._localStorageService.saveObjectToLocalStorage("remember_me", value)
  }
}

export default RememberMe;