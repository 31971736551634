import React from "react";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import {
  fetchUserDetails,
  requestCardDetails,
  requestApplyPromoCode,
  requestTransaction,
  showPaymentMethod,
  showCurrentCard,
  showCardList,
  requestProductDetails,
  requestRecalculation,
} from "../actions";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Icon,
  Form,
  message,
  Dropdown,
  Menu,
} from "antd";
import "../styles/payment.css";
import ManageUrls from "../endpoints/manage_urls.js";
import baseUrlOptions from "../endpoints/base_url_options.js";

const FormItem = Form.Item;
const TAG = "SmartPayment";

class SelectAirtimePayment extends React.Component {

  render() {
    return <WrappedSelectAirtimePaymentForm {...this.props} />;
  }
}

class SelectAirtimePaymentForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    var sendingCountry = {};
    var receivingCountry = {};
    var sendingCurrency = {};
    var receivingCurrency = {};
    var product = {};
    var calculation = {};
    var paymentMethods = [];
    var recipient = {};
    var cards = [];
    var transferReasons = [];
    var defaultTransferReason = "";
    var transaction = {};
    let airtimeDataProduct = {};

    if (props.location != null) {
      if (props.location.state != null) {
        sendingCountry = props.location.state.sendingCountry;
        receivingCountry = props.location.state.receivingCountry;
        sendingCurrency = props.location.state.sendingCurrency;
        receivingCurrency = props.location.state.receivingCurrency;
        product = props.location.state.product;
        calculation = props.location.state.calculation;
        paymentMethods = this.filterByInstantPayment(
          props.location.state.paymentMethods
        );
        recipient = props.location.state.recipient;
        cards = props.location.state.cards;
        transferReasons = props.location.state.transferReasons;
        defaultTransferReason = props.location.state.defaultTransferReason;
        transaction = props.location.state.transaction;
        airtimeDataProduct = props.location.state.airtimeDataProduct;
      }
    }

    this.state = {
      paymentMethods: this.filterByInstantPayment(paymentMethods),
      paymentMethod: 0,
      cards: cards,
      recipient: recipient,
      sendingCountry: sendingCountry,
      receivingCountry: receivingCountry,
      sendingCurrency: sendingCurrency,
      receivingCurrency: receivingCurrency,
      product: product,
      calculation: calculation,
      sourceOfFunds: [
        ["SAL", "Salary"],
        ["SV", "Savings"],
        ["GF", "Gift"],
        ["PN", "Pension"],
      ],
      promoIcon: "check",
      promoDisabled: false,
      promoCalculation: {},
      user: {},
      transferReasons: transferReasons,
      defaultTransferReason: defaultTransferReason,
      transaction: transaction,
      airtimeDataProduct: airtimeDataProduct,
      paymentOptions: this.formatPaymentOptions(
        product.data.attributes.payment_method_options
      ),
      paymentOption: 0,
    };
  }

  manageUrls = new ManageUrls();
  baseCountryIsSA = () =>
    this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA;

  checkIfHasUser() {
    Log.d(TAG, "checkIfHasUser() -- called..");
    var { cookies } = this.props;
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      if (Object.keys(this.props.userReducer.user).length <= 0) {
        this.props.fetchUserDetails(
          cookies,
          this.props.authReducer.accessToken,
          false,
          "Fetched user's details"
        );
      } else {
        this.requestProductDetails();
      }
    }
  }

  checkUserAndPaymentMethods(paymentMethods) {
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- product=" +
      JSON.stringify(this.props.productServiceReducer.product)
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- user=" +
      JSON.stringify(this.props.userReducer.user)
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- p name=" +
      this.props.productServiceReducer.product.data.attributes.name
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- u msisdn=" +
      this.props.userReducer.user.data.attributes.mobile_wallet_msisdn
    );
    if (
      this.props.productServiceReducer.product.data.attributes.name.includes(
        "Home Wallet"
      ) &&
      !this.props.userReducer.user.data.attributes.mobile_wallet_msisdn
    ) {
      message.error("You have no Mobile Wallet MSISDN", 5);
      this.props.history.push({
        pathname: "/",
      });
    } else {
      Log.d(
        TAG,
        "checkUserAndPaymentMethods() -- paymentMethods=" +
        JSON.stringify(paymentMethods)
      );
      if (paymentMethods.length <= 0) {
        message.error("No payment methods available", 5);
        this.props.history.push({
          pathname: "/",
        });
      } else {
        this.props.form.setFieldsValue({
          payment_method: 0,
        });
        this.props.showCardList(0, []);
        this.requestDetailsIfHasCards();
      }
    }
  }

  requestDetailsIfHasCards() {
    var relationships = this.props.userReducer.user.data.relationships;
    if (relationships.hasOwnProperty("saved_cards")) {
      Log.d(TAG, "requestDetailsIfHasCards() -- has saved cards");
      if (relationships.saved_cards.data != null) {
        Log.d(TAG, "requestDetailsIfHasCards() -- data is not null");
        if (relationships.saved_cards.data.length > 0) {
          Log.d(
            TAG,
            "requestDetailsIfHasCards() -- no. of cards is greater than zero"
          );
          this.setState({
            loading: true,
          });
          this.props.requestCardDetails(
            relationships.saved_cards.data[0].id,
            // this.props.cookies.cookies.access,
            this.props.authReducer.accessToken,
            0,
            []
          );
        }
      }
    }
  }

  componentDidMount() {
    this.checkIfHasUser();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.userReducer.user !== this.props.userReducer.user &&
      Object.keys(this.props.userReducer.user).length > 0
    ) {
      this.requestProductDetails();
    }

    if (
      prevProps.productServiceReducer.product !==
      this.props.productServiceReducer.product &&
      Object.keys(this.props.productServiceReducer.product).length > 0
    ) {
      var included = this.props.productServiceReducer.product.included;
      var attributes = this.props.productServiceReducer.product.data.attributes;

      var paymentMethods = [];

      for (var i = 0; i < included.length; i++) {
        var includedObj = included[i];
        if (includedObj.type === "payment_methods") {
          paymentMethods.push(includedObj);
        }
      }

      this.setState({
        transferReasons: 0,
        defaultTransferReason: 0,
        paymentMethods: this.filterByInstantPayment(paymentMethods),
        paymentOptions: this.formatPaymentOptions(
          attributes.payment_method_options
        ),
      });

      Log.d(
        TAG,
        "componentDidUpdate() -- paymentMethods=" +
        JSON.stringify(paymentMethods)
      );
      this.checkUserAndPaymentMethods(paymentMethods);
    }

    if (prevProps.userReducer.cardList !== this.props.userReducer.cardList) {
      Log.d(
        TAG,
        "componentDidUpdate() -- cards=" + this.props.userReducer.cardList
      );
      var relationships = this.props.userReducer.user.data.relationships;
      if (relationships.saved_cards) {
        if (relationships.saved_cards.data) {
          if (
            this.props.userReducer.currentCardIndex <
            relationships.saved_cards.data.length
          ) {
            this.props.requestCardDetails(
              relationships.saved_cards.data[
                this.props.userReducer.currentCardIndex
              ].id,
              // this.props.cookies.cookies.access,
              this.props.authReducer.accessToken,
              this.props.userReducer.currentCardIndex,
              this.props.userReducer.cardList
            );
          }
        }
      }
    }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("" + this.props.userReducer.error, 5);
    }

    if (
      prevProps.transactionReducer.transaction !==
      this.props.transactionReducer.transaction &&
      Object.keys(this.props.transactionReducer.transaction).length > 0
    ) {
      if (
        this.props.transactionReducer.transaction.hasOwnProperty("redirect")
      ) {

        window.location = this.props.transactionReducer.transaction.redirect;
      } else {
        this.props.history.push({
          pathname:
            "/transaction-number/" +
            this.props.transactionReducer.transaction.data.id,
        });
      }
    }

    if (
      prevProps.calculationReducer.calculation !==
      this.props.calculationReducer.calculation &&
      Object.keys(this.props.calculationReducer.calculation).length > 0
    ) {
      var promoDisabled = this.state.promoDisabled;
      var action = !promoDisabled ? "applied" : "removed";
      message.success("Promo " + action, 5);
      this.setState({
        promoDisabled: !promoDisabled,
        promoIcon: this.state.promoIcon === "check" ? "close" : "check",
        promoCalculation: !promoDisabled
          ? this.props.calculationReducer.calculation
          : {},
      });
      this.props.form.setFieldsValue({
        promo_code: !promoDisabled
          ? this.props.form.getFieldValue("promo_code")
          : "",
      });
    }
  }

  verifyPayment = (values) => {
    if (this.props.userReducer.user.data.attributes.user_status === "A") {
      if (Object.keys(this.state.transaction).length > 0) {
        this.requestTransaction(values);
      } else {

        this.props.showPaymentMethod(
          this.state.paymentMethods[this.state.paymentMethod],
          [],
          []
        );
        var cardDetails = null;
        var cardObj = {};
        cardObj.data = null;

        if (
          this.state.paymentMethods[this.state.paymentMethod].attributes
            .payment_type === "CD"
        ) {
          Log.d(
            TAG,
            "requestTransaction() -- cards=" +
            JSON.stringify(this.props.userReducer.cardList)
          );
          if (this.props.userReducer.cardList.length > 0) {
            var cardData = {};
            cardData.id =
              this.props.userReducer.cardList[values.saved_card].data.id;
            cardData.type = "cards";
            cardObj.data = cardData;
            cardDetails =
              this.props.userReducer.cardList[values.saved_card].data.attributes
                .card_type;
            this.props.showCurrentCard(cardDetails, cardObj);
            this.requestTransaction(values);
          } else {
            message.error(
              "No saved cards to use. Please select other payment method"
            );
          }
        } else if (
          this.state.paymentMethods[this.state.paymentMethod].attributes
            .payment_type === "NC"
        ) {
          cardDetails = Helper.getCardTypes()[values.card_type][0];
          this.props.showCurrentCard(cardDetails, cardObj);
          this.requestTransaction(values);

        } else {
          this.requestTransaction(values);

        }
        this.props.showCurrentCard(cardDetails, cardObj);
      }
    } else {
      this.requestTransaction(values);
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, "handleSubmit() -- values=" + JSON.stringify(values));
        Log.d(
          TAG,
          "handleSubmit() -- payment method=" + this.state.paymentMethod
        );
        Log.d(
          TAG,
          "handleSubmit() -- payment method details=" +
          JSON.stringify(this.state.paymentMethods[this.state.paymentMethod])
        );
        if (
          this.state.paymentMethods.length > 0 &&
          Object.keys(this.props.userReducer.user).length > 0
        ) {
          this.verifyPayment(values);
        } else {
          message.error("Redirecting ...", 5);
          this.props.history.push({
            pathname: "/",
          });
        }
      } else {
      }
    });
  }

  requestProductDetails() {
    Log.d(
      TAG,
      "requestProductDetails() -- product=" +
      JSON.stringify(this.props.productServiceReducer.product)
    );
    Log.d(
      TAG,
      "requestProductDetails() -- sending country=" +
      JSON.stringify(this.props.countryReducer.sendingCountry)
    );
    Log.d(
      TAG,
      "requestProductDetails() -- receiving country=" +
      JSON.stringify(this.props.countryReducer.receivingCountry)
    );
    if (
      Object.keys(this.props.productServiceReducer.product).length > 0 &&
      Object.keys(this.props.countryReducer.sendingCountry).length > 0 &&
      Object.keys(this.props.countryReducer.receivingCountry).length > 0
    ) {
      var sendingCountryAlpha = "";
      if (this.props.countryReducer.sendingCountry.data) {
        sendingCountryAlpha =
          this.props.countryReducer.sendingCountry.data.attributes.alpha_2_code;
      } else {
        sendingCountryAlpha =
          this.props.countryReducer.sendingCountry.attributes.alpha_2_code;
      }

      var receivingCountryAlpha = "";
      if (this.props.countryReducer.receivingCountry.data) {
        receivingCountryAlpha =
          this.props.countryReducer.receivingCountry.data.attributes
            .alpha_2_code;
      } else {
        receivingCountryAlpha =
          this.props.countryReducer.receivingCountry.attributes.alpha_2_code;
      }

      var token = null;
      if (this.props.authReducer.accessToken) {
        token = this.props.authReducer.accessToken;
      }
      this.props.requestProductDetails(
        this.props.productServiceReducer.product.data.id,
        token,
        receivingCountryAlpha,
        sendingCountryAlpha
      );
    }
  }

  requestCardDetails(currentIndex, currentSavedCards) {
    Log.d(
      TAG,
      "requestCardDetails() -- currentIndex=" +
      currentIndex +
      " current saved cards=" +
      JSON.stringify(currentSavedCards)
    );
    var access = this.props.authReducer.accessToken;
    Log.d(
      TAG,
      "requestCardDetails() -- current card=" +
      JSON.stringify(this.props.userReducer.cardList[currentIndex])
    );
    if (this.props.userReducer.cardList[currentIndex].hasOwnProperty("id")) {
      var currentID = this.props.userReducer.cardList[currentIndex].id;
      Log.d(TAG, "requestCardDetails() -- currentID=" + currentID);
      this.props.requestCardDetails(
        currentID,
        access,
        currentIndex,
        currentSavedCards
      );
    }
  }

  requestTransaction(values) {
    var cardObj = {};
    var attributesObj = {};
    cardObj.data = null;
    var relationshipObj = {};
    var transactionID = null;

    if (Object.keys(this.state.transaction).length > 0) {
      attributesObj.status = "P";
      transactionID = this.state.transaction.data.id;
    } else {

      var beneficiaryObj = {};
      if (Object.keys(this.state.recipient).length > 0) {
        Log.d(
          TAG,
          "requestTransaction() -- beneficiary ID=" +
          this.state.recipient.data.id
        );
        var beneficiaryData = {};
        beneficiaryData.id = this.state.recipient.data.id;
        beneficiaryData.type = "beneficiaries";
        beneficiaryObj.data = beneficiaryData;
      } else {
        beneficiaryObj.data = null;
      }

      relationshipObj.beneficiary = beneficiaryObj;
    }

    var paymentMethodObj = {};
    var paymentMethodData = {};
    paymentMethodData.id =
      this.state.paymentMethods[this.state.paymentMethod].id;
    paymentMethodData.type = "payment_methods";
    paymentMethodObj.data = paymentMethodData;

    if (values.hasOwnProperty("saved_card")) {
      var cardData = {};
      cardData.id = this.props.userReducer.cardList[values.saved_card].data.id;
      cardData.type = "cards";
      cardObj.data = cardData;
      attributesObj.card_type =
        this.props.userReducer.cardList[
          values.saved_card
        ].data.attributes.card_type;
    } else if (values.hasOwnProperty("card_type")) {
      Log.d(
        TAG,
        "requestTransaction() -- new card=" +
        Helper.getCardTypes()[values.card_type][1]
      );
      attributesObj.card_type = Helper.getCardTypes()[values.card_type][0];
    } else {
      attributesObj.card_type = null;
    }

    const airtimeProdObj = {
      data: {
        id: this.state.airtimeDataProduct.id,
        type: this.state.airtimeDataProduct.type,
      },
    };

    relationshipObj.airtime_data_product = airtimeProdObj;
    relationshipObj.payment_method = paymentMethodObj;
    relationshipObj.card = cardObj;

    var dataObj = {};
    dataObj.type = "transactions";
    dataObj.id = transactionID;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestTransaction() -- payload=" + JSON.stringify(payloadObj));
    if (!!this.props.authReducer.accessToken) {
      this.props.requestTransaction(
        payloadObj,
        this.props.authReducer.accessToken,
        transactionID
      );
    }
  }

  loadTerms = () => {
    window.open(encodeURI("https://" + window.location.hostname + "/terms-of-use"))
  }

  getSelectAPaymentMethodLabel = () => {
    const paymentOptionObject = this.state.paymentOptions[this.state.paymentOption];
    const paymentOption = paymentOptionObject.option[0];

    switch (paymentOption) {
      case "Cash":
        return "Where to pay";
      case "Card":
        return "Pay using new or saved card";

      default:
        return "Select a payment method";
    }
  }

  render() {
    Log.d(TAG, "render() -- called..");
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="payment-body" onSubmit={this.handleSubmit.bind(this)}>
        <FormItem className="payment-dropdown" label="How to pay">
          {getFieldDecorator("payment_option", {
            rules: [
              { required: true, message: "Please select a payment option" },
              //{ validator: this.validatePaymentMethod }
            ],
          })(
            <Dropdown
              trigger={["click"]}
              overlay={this.renderPaymentOptionsParent()}
            >
              <div className="dropdown-item">
                {this.renderPaymentOption()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        {this.state.paymentOption !== 0 && (
          <FormItem
            className="payment-dropdown"
            label={this.getSelectAPaymentMethodLabel()}
          >
            {getFieldDecorator("payment_method", {
              rules: [
                { required: true, message: "Please select a payment method" },
                { validator: this.validatePaymentMethod },
              ],
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderPaymentMethodsParent()}
              >
                <div className="dropdown-item">
                  {this.renderPaymentMethod()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        )}
        {this.renderPaymentMethodDetails()}
        {this.renderSavedCards(getFieldDecorator)}
        {this.renderNewCardTypes(getFieldDecorator)}

        <div style={{ width: "100%", textAlign: "left" }}>
          <FormItem>
            {getFieldDecorator("agree_terms", {
              rules: [
                {
                  required: true,
                  transform: (value) => value || undefined, // Those two lines
                  type: "boolean",
                  message: "Please agree on terms",
                },
              ],
            })(
              <Checkbox>
                <b>Accept <Button className="transaparent-button" onClick={this.loadTerms}>Terms and conditions</Button></b>
              </Checkbox>
            )}
          </FormItem>
        </div>
        <br />
        <div style={{ width: "100%", textAlign: "left", color: "red" }}>
          <b>Airtime and Data purchases are not reversible</b>
        </div>
        <div style={{ width: "100%", padding: "20px 0", textAlign: "left" }}>
          <b style={{ width: "100%", color: "red" }}>
            Fields with * are required.
          </b>
        </div>
        {/* <FormItem>
          <Button
            className="home-collect"
            style={{
              backgroundColor: "#154989",
              boxSizing: "border-box",
              color: "white",
              width: "100%",
            }}
            type="primary"
            htmlType="submit"
          >
            Review Transaction
          </Button>
        </FormItem> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                color: "#154989",
                margin: "8px",
              }}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Back
            </Button>
          </div>
          <FormItem className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "#154989",
                boxSizing: "border-box",
                color: "white",
                margin: "8px",
              }}
              type="primary"
              htmlType="submit"
            >
              Proceed
            </Button>
          </FormItem>
        </div>
      </Form>
    );
  }

  addSelectPaymentMethodOption = (oldPaymentMethods, paymentMethods) => {
    let hasSelectAirtimePaymentMethodOption = false;
    oldPaymentMethods.forEach((paymentMethod) => {
      if (paymentMethod.attributes.name === "Select a payment method") {
        hasSelectAirtimePaymentMethodOption = true;
      }
    });

    if (!hasSelectAirtimePaymentMethodOption) {
      const selectAirtimePaymentMethodOption = {
        id: "0",
        attributes: { name: "Select a payment method" },
      };
      paymentMethods.push(selectAirtimePaymentMethodOption);
    }
  };

  formatPaymentOptions = (paymentOptions) => {
    let newOptions = paymentOptions.map((type) => {
      let name = type[0];
      let options = type[1].map((option) => {
        let id = option[0];
        let paymentOption = option[1];
        return {
          id,
          paymentOption,
        };
      });
      return { option: [name], options };
    });

    return [{ option: "Select Payment Option", options: [] }, ...newOptions];
  };

  filterByInstantPayment = (oldPaymentMethods) => {
    const paymentMethods = [];
    const { cookies } = this.props;
    this.addSelectPaymentMethodOption(oldPaymentMethods, paymentMethods);

    oldPaymentMethods.forEach((item) => {
      if (cookies && cookies.get("browserType") === "Sasai") {
        if (
          item.attributes.payment_type === "EF" ||
          item.attributes.payment_type === "SC" ||
          item.attributes.payment_type === "NC"
        ) {
          paymentMethods.push(item);
        }
      } else {
        paymentMethods.push(item);
      }
    });
    Log.d("JSON _____CARD___TYPES______" + JSON.stringify({ paymentMethods }));
    return paymentMethods;
  };

  renderNewCardTypes(getFieldDecorator) {
    if (this.state.paymentMethods.length > 0) {
      if (
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.name.includes("Card") &&
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.name.includes("New")
      ) {
        Log.d(TAG, "renderNewCardTypes() -- has new card types");
        return (
          <FormItem className="payment-dropdown" label="Select a card type">
            {getFieldDecorator("card_type", {
              initialValue: 0,
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderNewCardTypesParent()}
              >
                <div className="dropdown-item">
                  {this.renderNewCardType()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        );
      }
    }
    return <div />;
  }

  renderSavedCards(getFieldDecorator) {
    if (this.state.paymentMethods.length > 0) {
      Log.d(TAG, "renderSavedCards() -- more payments");
      Log.d(
        TAG,
        "renderSavedCards() -- selected payment=" +
        JSON.stringify(this.state.paymentMethods[this.state.paymentMethod])
      );
      Log.d(
        TAG,
        "renderSavedCards() -- user cards=" +
        JSON.stringify(this.props.userReducer.cardList)
      );
      if (
        this.state.paymentMethods[this.state.paymentMethod].attributes
          .payment_type === "CD" &&
        this.props.userReducer.cardList.length > 0
      ) {
        Log.d(TAG, "renderSavedCards() -- has saved cards");
        return (
          <FormItem
            className="payment-dropdown"
            label="Select a saved card (Last digit ending):"
          >
            {getFieldDecorator("saved_card", {
              initialValue: 0,
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderSavedCardsParent()}
              >
                <div className="dropdown-item">
                  {this.renderSavedCard()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        );
      }
    }
    Log.d(TAG, "renderSavedCards() -- has NO saved cards");
    return <div />;
  }

  renderPaymentOptionsParent() {
    return (
      <Menu onClick={this.onChangePaymentOption}>
        {this.renderPaymentOptions()}
      </Menu>
    );
  }

  renderPaymentOptions() {
    return this.state.paymentOptions.map((itemAr, index) => {
      return <Menu.Item key={index} disabled={index === 0}>{itemAr.option}</Menu.Item>;
    });
  }

  renderPaymentOption() {
    if (this.state.paymentOptions.length > 0) {
      return (
        <div>
          {this.state.paymentOptions[this.state.paymentOption].option}
        </div>
      );
    }

    return <div />;
  }

  onChangePaymentOption = event => {
    this.setState({
      paymentOption: event.key,
      paymentMethod: 0,
    });
    this.props.form.setFieldsValue({
      payment_option: event.key,
      payment_method: 0
    });
  }

  renderPaymentMethodDetails() {
    if (this.state.paymentMethods.length > 0) {
      Log.d(
        TAG,
        "renderPaymentMethodDetails() -- payment method=" +
        JSON.stringify(this.state.paymentMethods[this.state.paymentMethod])
      );
      return (
        <div className="payment-description">
          {/* <b>
            {
              this.state.paymentMethods[this.state.paymentMethod].attributes
                .info_text
            }
          </b> */}
          <div
            dangerouslySetInnerHTML={{
              __html:
                this.state.paymentMethods[this.state.paymentMethod].attributes
                  .description,
            }}
          />
          <span className="payment-important-text">
            {
              this.state.paymentMethods[this.state.paymentMethod].attributes
                .important_text
            }
          </span>
        </div>
      );
    }
    return <div />;
  }

  validatePaymentMethod = (rule, value, callback) => {
    if (value === 0) {
      callback("Please select a payment method");
    }
    callback();
  };

  renderPaymentMethodsParent() {
    return (
      <Menu onClick={this.onChangePaymentMethod}>
        {this.renderPaymentMethods()}
      </Menu>
    );
  }

  renderPaymentMethods() {
    // only render methods for the selected group option
    if (this.state.paymentOption !== 0) {
      let paymentMethods = this.state.paymentMethods.filter((method) => {
        return this.state.paymentOptions[this.state.paymentOption].options.some(option =>
          option.id === method.id || method.id === "0"
        )
      })
      return paymentMethods.map((itemAr, index) => {
        return <Menu.Item key={itemAr.id} disabled={itemAr.id === "0"}>{itemAr.attributes.name}</Menu.Item>;
      });
    }
    return <Menu.Item />
  }

  renderPaymentMethod() {
    if (this.state.paymentMethods.length > 0) {
      return (
        <div>
          {this.state.paymentMethods[this.state.paymentMethod].attributes.name}
        </div>
      );
    }

    return <div />;
  }

  // onChangePaymentMethod = (event) => {
  //   this.setState({
  //     paymentMethod: event.key,
  //   });
  //   this.props.form.setFieldsValue({
  //     payment_method: event.key,
  //   });
  // };

  onChangePaymentMethod = event => {
    // we need to get the method index from event...
    let index = this.state.paymentMethods.findIndex(method => method.id === event.key);
    this.setState({
      paymentMethod: index
    });
    this.props.form.setFieldsValue({
      payment_method: index
    });
  };

  renderNewCardTypesParent() {
    return (
      <Menu onClick={this.onChangeNewCardType}>
        {this.renderNewCardTypesList()}
      </Menu>
    );
  }

  renderNewCardTypesList() {
    return Helper.getCardTypes().map((itemAr, index) => {
      if (
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.accepted_card_types.includes(itemAr[0])
      ) {
        return <Menu.Item key={index}>{itemAr[1]}</Menu.Item>;
      }
      return <span />;
    });
  }

  renderNewCardType() {
    const cardIndex = this.props.form.getFieldValue("card_type");
    const cardTypes = Helper.getCardTypes();

    const card = cardTypes[cardIndex][1];

    return <div>{card}</div>;
  }

  onChangeNewCardType = (event) => {
    this.props.form.setFieldsValue({
      card_type: event.key,
    });
  };

  renderSavedCardsParent() {
    return (
      <Menu onClick={this.onChangeSavedCard}>
        {this.renderSavedCardsList()}
      </Menu>
    );
  }

  renderSavedCardsList() {
    return this.props.userReducer.cardList.map((itemAr, index) => {
      Log.d(TAG, "renderSavedCardsList() -- item=" + JSON.stringify(itemAr));
      return (
        <Menu.Item key={index}>
          {itemAr.data.attributes.card_type} -{" "}
          {itemAr.data.attributes.last_digits}
        </Menu.Item>
      );
    });
  }

  renderSavedCard() {
    return (
      <div>
        {
          this.props.userReducer.cardList[
            this.props.form.getFieldValue("saved_card")
          ].data.attributes.card_type
        }{" "}
        -{" "}
        {
          this.props.userReducer.cardList[
            this.props.form.getFieldValue("saved_card")
          ].data.attributes.last_digits
        }
      </div>
    );
  }

  onChangeSavedCard = (event) => {
    this.props.form.setFieldsValue({
      saved_card: event.key,
    });
  };
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchUserDetails,
      requestCardDetails,
      requestApplyPromoCode,
      requestTransaction,
      showPaymentMethod,
      showCurrentCard,
      showCardList,
      requestProductDetails,
      requestRecalculation,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

const WrappedSelectAirtimePaymentForm = Form.create()(
  withCookies(SelectAirtimePaymentForm)
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectAirtimePayment);
