import React from "react";
import { Layout, Button } from "antd";
import { withCookies } from "react-cookie";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import "../../styles/dashboard.css";
import TermsUk from "./terms-uk.js";
import TermsZa from "./terms-za.js";

const { Content, Header, Footer } = Layout;

class DynamicTerms extends React.Component {

  componentDidMount() {
    document.title = "Terms of Use";
    window.scrollTo(0, 0);
  }

  loadPrivacyPolicy = () => {
    window.open(encodeURI("https://" + window.location.hostname + "/privacy-policy"))
  }

  linkToPrivacyPolicy =
    <Button className="transaparent-button" onClick={this.loadPrivacyPolicy}>www.cassavaecocash.co.za/privacy-policy</Button>

  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <div className="terms-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">A. TERMS & CONDITIONS OF SERVICE</h2>
          </div>
        </div>
        <Content className="terms-body">
          {TermsOfUseContent(this.props)}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
}

const TermsOfUseContent = props => {
  const param = props.match.params.country

  if(param === "gb") {
    return TermsUk();
  } else if(param === "za") {
    return TermsZa();
  } else {
    props.history.push({
        pathname: "/terms-of-use",
    })
  }
}

export default withCookies(DynamicTerms);
