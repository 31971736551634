import React from "react";
import Log from "../utils/log.js";
import { Layout, Table, message } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchReferrals } from "../actions";
import Helper from "../utils/helper.js";

const { Content } = Layout;
const TAG = "SmartFamilyAndFriends";

class FamilyAndFriends extends React.Component {
  requestReferrals(accessToken) {
    this.setState({
      loading: true,
    });
    this.props.fetchReferrals(accessToken);
  }

  componentDidMount() {
    const accessToken = this.props.authReducer.accessToken;
    this.requestReferrals(accessToken);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("Error loading referrals", 5);

      this.setState({
        loading: false,
        loadingTip: "",
      });
    }

    if (
      prevProps.referralsReducer.referrals !==
      this.props.referralsReducer.referrals
    ) {
      this.setState({
        loading: false,
        loadingTip: "",
        dataSource: this.props.referralsReducer.referrals.data,
      });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingTip: "",
      columns: [
        {
          title: "First Name",
          dataIndex: "attributes.first_name",
          key: "attributes.first_name",
        },
        {
          title: "Last Name",
          dataIndex: "attributes.last_name",
          key: "attributes.last_name",
        },
        {
          title: "User ID",
          dataIndex: "attributes.code",
          key: "attributes.code",
        },
        {
          title: "Status",
          dataIndex: "attributes.user_status",
          key: "attributes.user_status",
          render: (text) => Helper.showUserStatus(text),
        },
      ],
      dataSource: [],
    };
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            <h2 style={{ fontFamily: "Lato" }}>Family and friends</h2>
            <div>
              <Table
                dataSource={this.state.dataSource}
                columns={this.state.columns}
                onRow={(referral) => {
                  return {
                    onClick: () => this.props.history.push(`/family-and-friends/${referral.id}`),
                  };
                }}
              />
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchReferrals,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyAndFriends);
