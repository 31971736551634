import React from 'react';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Log from '../../utils/log.js';
import Login from '../../containers/Login.js'
import '../../styles/payment.css'
import { withCookies } from 'react-cookie';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SmartIntegratedForm from '../../containers/IntegratedForm.js'
import { Layout } from 'antd';
const { Content, Header, Footer } = Layout;

const TAG = "DumbIntegratedForm";

class IntegratedForm extends React.Component {

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {... this.props}/>
        </Header>
        {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  renderBody() {
    if (!!this.props.authReducer.accessToken) {
      return (this.renderLoggedInContent())
    } else {
      return (<Login {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>)
    }
  }


  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  renderLoggedInContent() {
    return (
      <Content>
        <SmartIntegratedForm {...this.props}/>
      </Content>
    )
  }

  componentDidMount() {
    document.title = "Integrated Form"
    window.scrollTo(0, 0)
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

// export default withCookies(Header)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(IntegratedForm));

// export default withCookies(IntegratedForm)
