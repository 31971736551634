import React from "react";
import Router from "./router.js";
import Log from "./utils/log.js";
import Helper from "./utils/helper.js";
import ServerData from "./utils/server_data.js";
import { withCookies } from "react-cookie";
import config from "./env-config.json";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  requestLogin,
  requestSendingCountries,
  receiveUserDetails,
  setAccessRefreshTokens,
} from "./actions";

// usually access tokens expired every 20 minutes, so we should have a
// new access token before it expires and token is blacklisted. I
// chose 10 mins interval of getting new access token
const REQUEST_REFRESH_ACCESS_TOKEN_TIME = 60 * 1000 * 10;

const TAG = "App";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    // when starting the application or on reload
    // check if the user had log in by trying to 
    // to retrieve a fresh access token from the 
    // 'web/refresh/' endpoint
    this.requestRefreshAccessToken();
    // refresh the token after the set 
    // REQUEST_REFRESH_ACCESS_TOKEN_TIME time period
    const intervalId = setInterval(
      this.requestRefreshAccessToken,
      REQUEST_REFRESH_ACCESS_TOKEN_TIME
    );
    this.setState({ intervalId: intervalId });
    setTimeout(() => {
      this.setState({
        loaded: true
      });
    }, 800);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    if (this.state.loaded) {
      return this.checkConfig();
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "#154989"
          }}
        >
          {/* <h2
            style={{ color: "white", fontFamily: "Lato", textAlign: "center" }}
          >
            Ecocash Remit
          </h2>
          <h2
            style={{ color: "white", fontFamily: "Lato", textAlign: "center" }}
          >
            International Money Transfer
          </h2>
          <h4
            style={{ color: "white", fontFamily: "Lato", textAlign: "center" }}
          >
            Loading ...
          </h4> */}
        </div>
      );
    }
  }

  checkConfig() {
    if (
      config.auth_url &&
      config.api_url &&
      config.upload_url &&
      config.proxy_url &&
      config.app_id
    ) {
      return <Router />;
    } else {
      Log.d(TAG, "checkConfig() -- has router");
      return this.renderNoConfig();
    }
  }

  renderNoConfig() {
    Log.d(TAG, "renderNoConfig() -- called ..");
    return (
      <div>
        <h5 style={{ fontFamily: "Lato" }}>
          No config URL(s) provided. Please contact your admin to put correct
          configurations.
        </h5>
      </div>
    );
  }

  requestRefreshAccessToken = () => {
    Log.d(TAG, "requestRefeshToken() -- called ..");
    Log.d(TAG, "requestRefeshToken() -- props=" + JSON.stringify(this.props));
    const { cookies } = this.props;
    // Log.d(TAG, "requestRefeshToken() -- refresh=" + cookies.get("refresh"));
    // Log.d(TAG, "requestRefeshToken() -- access=" + cookies.get("access"));
    Log.d(TAG, "requestRefeshToken() -- access=" + this.props.authReducer.accessToken);
    // if (cookies.get("refresh")) {
    if (true) {
      ServerData.requestRefreshToken(
        cookies,
        // { refresh: cookies.get("refresh") },
        null,
        true,
        (error, access, refresh, userDetails) => {
          if (error) {
            Log.d(TAG, "requestRefeshToken() -- error=" + error);
            Helper.clearCookies(cookies);
            // log out as there is an error on this
            /*this.props.history.push({
            pathname: "/login",
            state: { message : "" + error}
          });*/
            
          // when checking for a fresh access token on error
          // to prevent a loop only reload the window/app
          // when an old access token is already within 
          // the application memory
            if(!!this.props.authReducer.accessToken) {
              window.location.reload();
            }
          } else {
            this.props.setAccessRefreshTokens(access);
            Log.d(
              TAG,
              "requestRefeshToken() -- saved new refresh and access tokens"
            );
          }
        }
      );
    }
  };
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestLogin,
      requestSendingCountries,
      receiveUserDetails,
      setAccessRefreshTokens,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(App));
