import React from "react";
import CustomHeader from "./header.js";
import CustomFooter from "./footer.js";
import Log from "../utils/log.js";
import "./../styles/payment.css";
import { withCookies } from "react-cookie";
import SmartSelectAirtimePayment from "../containers/GuestSelectAirtimePayment.js";
import { Layout } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const { Content, Header, Footer } = Layout;

const TAG = "DumbAirtimeDataPayment";

class GuestAirtimePayment extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <Layout>
          <Header
            className="custom-header"
            style={{ position: "fixed", width: "100%", zIndex: "1" }}
          >
            <CustomHeader {...this.props} />
          </Header>
          {this.renderBody()}
          <Footer className="custom-footer">
            <CustomFooter />
          </Footer>
        </Layout>
      </div>
    );
  }

  renderBody() {
    if (this.props.countryReducer.airtimeDataSendingCountries.length > 0) {
      return this.renderContentWhenSafe();
    } else {
      return this.props.history.push("/");
    }
  }

  renderContentWhenSafe() {
    return (
      <Content className="payment-content">
        <SmartSelectAirtimePayment {...this.props} />
      </Content>
    );
  }

  componentDidMount() {
    document.title = "Select Payment";
    window.scrollTo(0, 0);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(GuestAirtimePayment));
