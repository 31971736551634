import React from "react";
import { Layout } from "antd";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js"; 
import firstStepIcon from "../../images/first-step-icon.svg";
import secondStepIcon from "../../images/second-step-icon.svg";
import thirdStepIcon from "../../images/third-step-icon.svg";
import forthStepIcon from "../../images/forth-step-icon.svg";
import appStoreBadge from "../../images/app-store-badge.svg";
import playStoreBadge from "../../images/play-store-badge.svg";
import breadwinnerPolicyLogo from "../../images/breadwinner.png";
import hollardLogo from "../../images/hollard_logo.png";

const { Content, Header, Footer } = Layout;

export default class BreadWinnerHomePage extends React.Component {
  render() {
    return (
        <div>
           <Layout>
            <Header
            className="custom-header"
            style={{ position: "fixed", width: "100%", zIndex: "1" }}
            >
            <CustomHeader {...this.props} />
            </Header>
            <Content>
                <div className="bwl-main-banner">
                    <div className="bwl-aside-gradient d-flex align-items-center justify-content-center">
                        <div className="bwl-main-banner-container">
                            <div className="bwl-main-banner-sub-container">
                                <img className="bwl-justify-self-center bwl-breadwinner-policy-logo" src={breadwinnerPolicyLogo} alt="breadwinner-policy-logo" />
                                <h2 className="bwl-justify-self-center bwl-main-banner-text">A unique death benefit cover for Zimbabwean citizens living in South Africa.</h2>
                                <div className="bwl-justify-content-center">
                                    <a className="bwl-read-more-btn" href="/breadwinner-home#benefitcover">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bwl-how-it-works-section">
                    <div className="container justify-content-center">
                        <div>
                            <h2 className="bwl-how-it-works-heading">How it works</h2>
                            <p className="bwl-how-it-works-text" >
                                To qualify, simply register as a Sasai Money Transfer customer on our website or via the Sasai Money Transfer App, opt in
                                for the Breadwinner Policy and send home a minimum amount of R750 every month to keep the cover.
                            </p>
                            <p className="bwl-how-it-works-text bwl-how-it-works-text-second-p">
                                <ul>
                                    <li>The policy becomes active after opting in and sending a minimum of R750 for three months.</li>
                                    <li>In the event of death, the policy must have been active, i.e. a minimum of R750 must have been
                                        remitted for the past three months.</li>
                                    <li>Sasai Money Transfer agents will not issue advice on the policy.</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bwl-htgs-curved-bg">
                    <div className="container">
                        <h2 className="text-center bwl-htgs-heading">How to get started</h2>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-between bwl-htgs-steps-container">
                            <div className="col-md-4 bwl-htgs-bubble-container">
                                <div className="bwl-htgs-first-level-bubbles">
                                    <div className="bwl-htgs-bubble-padding">
                                        <img src={firstStepIcon} className="bwl-htgs-bubble-icon-size" alt="avatar"/>
                                        <h5 className="bwl-htgs-bubble-heading-margin">Step 1</h5>
                                        <p className="bwl-htgs-bubble-text">Register on Sasai Money Transfer</p>
                                    </div>
                                </div>
                                <div className="bwl-hgts-second-level-bubbles">
                                    <div className="bwl-htgs-bubble-padding">
                                        <img src={thirdStepIcon} className="bwl-htgs-bubble-icon-size" alt="increase or decrease hand selection"/>
                                        <h5 className="bwl-htgs-bubble-heading-margin">Step 3</h5>
                                        <p className="bwl-htgs-bubble-text">Send a minimum of R750 every month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-none d-lg-block d-md-block bwl-htgs-center-mobile-phone">
                            </div>
                            <div className="col-md-4 bwl-htgs-bubble-container">
                                <div className="bwl-htgs-first-level-bubbles">
                                    <div className="bwl-htgs-bubble-padding">
                                        <img src={secondStepIcon} className="bwl-htgs-bubble-icon-size" alt="pie chart"/>
                                        <h5 className="bwl-htgs-bubble-heading-margin">Step 2</h5>
                                        <p className="bwl-htgs-bubble-text">Opt into breadwinner</p>
                                    </div>
                                </div>
                                <div className="bwl-hgts-second-level-bubbles">
                                    <div className="bwl-htgs-bubble-padding">
                                        <img src={forthStepIcon} className="bwl-htgs-bubble-icon-size" alt="brain"/>
                                        <h5 className="bwl-htgs-bubble-heading-margin">Step 4</h5>
                                        <p className="bwl-htgs-bubble-text">Download the Sasai Money Transfer App </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-between bwl-play-app-store-badge-row" >
                                <div className="pointer">
                                    <img className="bwl-play-app-store-badge-size" src={appStoreBadge} alt="app-store-badge" onClick={() => {
                                        window.open(encodeURI("https://itunes.apple.com/us/app/ecocash-remit/id1455082083?ls=1&mt=8"));
                                    }}/>
                                </div> 
                                <div className="pointer"  id="benefitcover">
                                    <img className="bwl-play-app-store-badge-size" src={playStoreBadge} alt="play-store-badge" onClick={() => {
                                        window.open(encodeURI("https://play.google.com/store/apps/details?id=com.ecocash.saremit.production"));
                                    }}/>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-content-center">
                    <div className="container">
                        <div className="bwl-justify-content-center">
                            <h2 className="bwl-benefits-heading">Benefit Cover</h2>
                        </div>
                        <p className="bwl-benefits-text" >
                            A unique death benefit cover for Zimbabwean citizens living in South Africa which aims to bring
                            financial relief to the policy holder's nominated beneficiary back home, in the event of passing away of
                            the policyholder.
                        </p>
                        <p className="bwl-benefits-text" >
                            Targeted at Zimbabweans over the age of 18 years living in S. A. (breadwinners) who have a financial 
                            responsibility towards family and friends (beneficiaries) back home in Zimbabwe.
                        </p>
                        <p className="bwl-benefits-text" >
                            Pay - out amount is the average send amount over the last three months. Nominated beneficiary will receive 
                            three instalments over three months.
                        </p>
                    </div>
                    <div className="bwl-benefits-grey-bg-padding">
                        <div className="container">
                            <div className="bwl-justify-content-center">
                                <h2 className="bwl-benefits-heading">Benefit</h2>
                            </div>
                            <p className="bwl-benefits-list" >
                            <ul>
                                <li>No monthly premiums - the policyholder simply has to remit monthly with Sasai Money Transfer.</li>
                                <li>Easy to get - Simple opt - in process during and after Sasai Money Transfer registration.</li>
                                <li>Claims are processed within 48hrs.</li>
                                <li>Peace of mind.</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                    <div className="bwl-benefits-padding">
                        <div className="container">
                            <div className="bwl-justify-content-center">
                                <h2 className="bwl-benefits-heading">Requirement</h2>
                            </div>
                            <p className="bwl-benefits-list" >
                            <ul>
                                <li>Policyholder must be 18 years or older.</li>
                                <li>Policyholder must send a minimum of R750 per month using Sasai Money Transfer. Policyholder must have sent
                                    money to Zimbabwe for 3 months in a row prior to the event of death.</li>
                                <li>Nominated beneficiary should reside in Zimbabwe.</li>
                            </ul>
                            </p>
                        </div>
                    </div>
                    <div className="bwl-benefits-grey-bg-padding">
                        <div className="container">
                            <div className="bwl-justify-content-center">
                                <h2 className="bwl-benefits-heading">Claims Process</h2>
                            </div>
                            <p className="bwl-benefits-list" >
                            <ul>
                                <li>Nominated beneficiary must contact Ecosure Zimbabwe with the necessary documents and follow the
                                    normal Ecosure claim process.</li>
                                <li>Claims will be processed within 48hrs subject to the presentation of all the required documentation.
                                </li>
                                <li>The death benefit claim will be paid in Zimbabwe in local currency.</li>
                                <li>Ecosure Zimbabwe can be reached by:</li>
                                <ul className="bwl-address">
                                    <li>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <b>Physical Address:</b> 
                                            </div>
                                            <div className="col-md-8">
                                                <p className="bwl-remove-margin">1906 Borrowdale Road,</p>
                                                <p className="bwl-remove-margin">Borrowdale, Harare Zimbabwe</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <b>Email:</b> 
                                            </div>
                                            <div className="col-md-8">
                                                <p className="bwl-remove-margin">ecosureoperations@econet.co.zw</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <b>Telephone:</b> 
                                            </div>
                                            <div className="col-md-8">
                                                <p className="bwl-remove-margin">311 (Zimbabwe)</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <hr />
                                <p className="bwl-disclaimer" >Underwritten by Hollard Life Assurance Company Limited (Reg No. 1993/001405/06), an
                                authorised financial service provider. Sasai is Juristic Representative of Hollard. Terms and
                                conditions
                                apply.</p>
                            </ul>
                            </p>
                            <div className="bwl-justify-content-center">
                                <img src={hollardLogo} alt="hollard-logo" height="100px"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer className="custom-footer">
            <CustomFooter />
            </Footer>
        </Layout>
        </div>
    );
  }
}