import Log from "./log";

const AUTH_ERROR = "AuthError";
const SERVER_ERROR = "ServerError";
class AuthError extends Error {
	constructor(message, statusCode) {
			super(message);
			this.name = AUTH_ERROR;
	}
}

class ServerError extends Error {
	constructor(message, statusCode) {
      message = "Apologies, unable to complete this operation at the moment. Please try again later."
			super(message);
			this.name = SERVER_ERROR;
	}
}


class xhr {
  static post(url, payload, headers, cb) {
    xhr.requestWithBody("POST", url, payload, headers, cb);
  }

  static postWithCustomError(url, payload, headers, cb) {
    xhr.requestWithBodyCustomError("POST", url, payload, headers, cb);
  }

  static postWithSignUpOTPError(url, payload, headers, cb) {
    xhr.requestWithBodySignUpOTPError("POST", url, payload, headers, cb);
  }

  static postWithCustomErrorOTP(url, payload, headers, cb) {
    xhr.requestWithBodyCustomErrorOTP("POST", url, payload, headers, cb);
  }

  static postWithCredentials(url, payload, headers, cb) {
    xhr.requestWithBodyAndCredentials("POST", url, payload, headers, cb);
  }

  static postRedirect(url, payload, headers, cb) {
    xhr.requestWithBodyRedirect("POST", url, payload, headers, cb);
  }

  static put(url, payload, headers, cb) {
    xhr.requestWithBody("PUT", url, payload, headers, cb);
  }

  static patch(url, payload, headers, cb) {
    xhr.requestWithBody("PATCH", url, payload, headers, cb);
  }

  static get(url, headers, cb) {
    xhr.request("GET", url, headers, cb);
  }

  static delete(url, headers, cb) {
    xhr.requestDelete("DELETE", url, headers, cb);
  }

  static postFile(url, payload, headers, cb) {
    xhr.requestWithFormData("POST", url, headers, payload, cb);
  }

  static updateFile(url, payload, headers, cb) {
    xhr.requestWithFormData("PUT", url, headers, payload, cb);
  }

  static serverErrorHandler() {
    if(window.location.protocol === "http:") {
      window.alert("Apologies, unable to complete this operation at the moment. Please try again later.");
      window.location.href = "http://" + window.location.host + "/";
      window.reload();
    } else {
      window.alert("Apologies, unable to complete this operation at the moment. Please try again later.");
      window.location.href = "https://" + window.location.host + "/";
      window.reload();
    }
  }

  static requestWithFormData(method, url, headers, payload, cb) {
    fetch(url, {
      method: method,
      headers: headers,
      body: payload
    })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(data => {
        cb(null, data);
      })
      .catch(error => {
        if (error.name !== AUTH_ERROR) {
          if (error.name !== AUTH_ERROR) {
            cb(error);
          } else {
            window.location.reload();
          }
        }
      });
  }

  static requestWithBodyAndCredentials(method, url, payload, headers, cb) {
    if(payload === null) {
      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: method,
        headers: headers
      })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(data => {
        Log.i(`Data in Body ${data}`);
        cb(null, data);
      })
      .catch(error => {
        Log.i(`Error in Body ${error}`);
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
    } else {
      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: method,
        body: JSON.stringify(payload),
        headers: headers
      })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(data => {
        Log.i(`Data in Body ${data}`);
        cb(null, data);
      })
      .catch(error => {
        Log.i(`Error in Body ${error}`);
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
    }
  }

  static requestWithBody(method, url, payload, headers, cb) {
    fetch(url, {
      method: method,
      body: JSON.stringify(payload),
      headers: headers
    })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred ${results}`);
          return;
        } else {
          return results.json();
        }
      })
      .then(data => {
        Log.i(`Data in Body ${data}`);
        cb(null, data);
      })
      .catch(error => {
        Log.i(`Error in Body ${error}`);
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
  }

  static requestWithBodyCustomError(method, url, payload, headers, cb) {
    fetch(url, {
      method: method,
      body: JSON.stringify(payload),
      headers: headers
    })
    .then(results => {
      // checking if user is in the system
      const isNotFound = results.status === 404;
      const authError = results.status === 401 || results.status === 403;
      const serverError = results.status === 500;

      if (serverError) {
        Log.i(`Server error occurred: ${results}`);
        xhr.serverErrorHandler();
      } else if (authError) {
        Log.i(`Auth error occurred ${results}`);
        return;
      } else {
        results.json().then(data => {
          if(isNotFound) {
            cb(data.detail);
          } else {
            cb(null, data)
          }
        });
      }
    })
    .catch(error => {
      Log.i(`Error in Body ${error}`);
      if (error.name !== AUTH_ERROR) {
        cb(error);
      } else {
        window.location.reload();
      }
    });
  }

  static requestWithBodyCustomErrorOTP(method, url, payload, headers, cb) {
    fetch(url, {
      method: method,
      body: JSON.stringify(payload),
      headers: headers
    })
    .then(results => {
      const isBadRequest = results.status === 400;
      const authError = results.status === 401 || results.status === 403;
      const serverError = results.status === 500;

      if (serverError) {
        Log.i(`Server error occurred: ${results}`);
        xhr.serverErrorHandler();
      } else   if (authError) {
          Log.i(`Auth error occurred ${results}`);
          return;
        } else {
          results.json().then(data => {
            if(isBadRequest) {
              if(data.non_field_errors.length > 0) {
                let message = "";
                const errMsgs = data.non_field_errors;
    
                for (let i = 0; i < errMsgs.length; i++) {
                  message += errMsgs[i]
                }
                cb(message)
              }
            } else {
              cb(null, data)
            }
          });
        }
    })
    .catch(error => {
      Log.i(`Error in Body ${error}`);
      if (error.name !== AUTH_ERROR) {
        cb(error);
      } else {
        window.location.reload();
      }
    });
  }

  static requestWithBodySignUpOTPError(method, url, payload, headers, cb) {
    fetch(url, {
      method: method,
      body: JSON.stringify(payload),
      headers: headers
    })
    .then(results => {
      const isBadRequest = results.status === 400;
      const authError = results.status === 401 || results.status === 403;
      const serverError = results.status === 500;

      if (serverError) {
        Log.i(`Server error occurred: ${results}`);
        xhr.serverErrorHandler();
      } else if (authError) {
        Log.i(`Auth error occurred ${results}`);
        throw new AuthError();
      } else {
        results.json().then(data => {
          if(isBadRequest) {
            
            if(data.username) {
              const message = data.username[0];
              cb(message)
            } else if (data.non_field_errors) {
              if (data.non_field_errors[0] === "NAME_REQUIRED") {
                cb(null, null, true)
              } else if(data.non_field_errors[0] === "NAME_NOT_MATCH") {
                cb(null, null, false, true)
              } else {
                cb(data.non_field_errors[0])
              }
            } else {
              cb("Error signing with OTP")
            }
          } else {
            cb(null, data)
          }
        });
      }
    })
    .catch(error => {
      Log.i(`Error in Body ${error}`);
      if (error.name !== AUTH_ERROR) {
        cb(error);
      } else {
        window.location.reload();
      }
    });
  }

  static requestWithBodyRedirect(method, url, payload, headers, cb) {
    fetch(url, {
      method: method,
      redirect: "follow",
      body: JSON.stringify(payload),
      headers: headers
    })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred ${results}`);
          throw new AuthError();
        } else {
          var redirectUrl = results.url !== url ? results.url : "";
          if (redirectUrl) {
            return { redirect: redirectUrl };
          } else {
            return results.json();
          }
        }
      })
      .then(data => {
        cb(null, data);
      })
      .catch(error => {
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
  }

  static request(method, url, headers, cb) {
    fetch(url, {
      method: method,
      headers: headers
    })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(data => {
        cb(null, data);
      })
      .catch(error => {
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
  }

  static requestDelete(method, url, headers, cb) {
    fetch(url, {
      method: method,
      headers: headers
    })
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`);
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          if (results.status === 200) {
            return results.json();
          } else {
            cb(null);
          }
        }
      })
      .then(data => {
        cb(null, data);
      })
      .catch(error => {
        if (error.name !== AUTH_ERROR) {
          cb(error);
        } else {
          window.location.reload();
        }
      });
  }
}

export default xhr;

export { AuthError, ServerError, AUTH_ERROR };
