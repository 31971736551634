import { combineReducers } from "redux";
import * as actionType from "../actions/ActionType";

function authReducer(
  state = {
    accessToken: "",
    refreshToken: "",
    guestUserAccessToken: "",
    firstName: "",
    lastName: "",
  },
  action
) {
  switch (action.type) {
    case actionType.SET_ACCESS_REFRESH_TOKENS:
      return Object.assign({}, state, {
        accessToken: action.accessToken,
        // refreshToken : action.refreshToken
      });
    case actionType.REMOVE_ACCESS_REFRESH_TOKENS:
      return Object.assign({}, state, {
        accessToken: "",
      });
    case actionType.SHOW_GUEST_USER_TOKENS:
      return Object.assign({}, state, {
        guestUserAccessToken: action.access,
      });
    default:
      return state;
  }
}

function notificationsReducer(
  state = {
    notifications: {},
    count: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: action.notifications,
      });
    case actionType.SHOW_UNREAD_NOTIFICATIONS_COUNT:
      return Object.assign({}, state, {
        count: action.count,
      });
    default:
      return state;
  }
}

function userReducer(
  state = {
    user: {},
    forgetPasswordData: {},
    error: null,
    success: null,
    reload: false,
    loginMessage: null,
    cardList: [],
    currentCard: null,
    cardObj: {},
    limit: {},
    requestLimitSource: null,
    currentCardIndex: 0,
    sendingCountries: [],
    phoneConfirmationID: null,
    signUpSuccess: false,
    pcaPredictAddress: {},
    pcaPredictAddresses: [],
    showPCAPredictError: null,
    cookiesNotification: true,
  },
  action
) {
  switch (action.type) {
    case actionType.RECEIVE_USER_DETAILS:
      return Object.assign({}, state, {
        user: action.user,
      });
    case actionType.SHOW_ERROR:
      return Object.assign({}, state, {
        error: action.error,
      });
    case actionType.SHOW_SUCCESS:
      return Object.assign({}, state, {
        success: action.success,
        reload: action.reload,
      });
    case actionType.LOAD_LOGIN:
      return Object.assign({}, state, {
        loginMessage: action.loginMessage,
      });
    case actionType.SHOW_CARDS:
      return Object.assign({}, state, {
        cardList: action.cardList,
        currentCardIndex: action.currentCardIndex,
      });
    case actionType.SHOW_PHONE_CONFIRMATION_ID:
      return Object.assign({}, state, {
        phoneConfirmationID: action.phoneConfirmationID,
      });
    case actionType.SHOW_FORGET_PASSWORD_DATA:
      return Object.assign({}, state, {
        forgetPasswordData: action.forgetPasswordData,
      });
    case actionType.SHOW_SUCCESS_SIGN_UP:
      return Object.assign({}, state, {
        signUpSuccess: action.signUpSuccess,
      });
    case actionType.SHOW_PCA_PREDICT_ADDRESS:
      return Object.assign({}, state, {
        pcaPredictAddress: action.pcaPredictAddress,
      });
    case actionType.SHOW_PCA_PREDICT_ADDRESSES:
      return Object.assign({}, state, {
        pcaPredictAddresses: action.pcaPredictAddresses,
      });
    case actionType.SHOW_PCA_PREDICT_ERROR:
      return Object.assign({}, state, {
        pcaPredictAddressError: action.pcaPredictAddressError,
      });
    case actionType.SHOW_CURRENT_CARD:
      return Object.assign({}, state, {
        currentCard: action.currentCard,
        cardObj: action.cardObj,
      });
    case actionType.SHOW_LIMIT:
      return Object.assign({}, state, {
        limit: action.limit,
        requestLimitSource: action.requestLimitSource,
      });
    case actionType.SHOW_COOKIES_NOTIFICATION:
      return Object.assign({}, state, {
        cookiesNotification: action.cookiesNotification,
      });
    default:
      return state;
  }
}

function countryReducer(
  state = {
    sendingCountries: [],
    receivingCountries: [],
    airtimeDataSendingCountries: [],
    airtimeDataReceivingCountries: [],
    countries: [],
    sendingCountry: {},
    receivingCountry: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_SENDING_COUNTRIES:
      return Object.assign({}, state, {
        sendingCountries: action.sendingCountries,
      });
    case actionType.SHOW_RECEIVING_COUNTRIES:
      return Object.assign({}, state, {
        receivingCountries: action.receivingCountries,
      });
    case actionType.SHOW_AIRTIME_DATA_RECEIVING_COUNTRIES:
      return Object.assign({}, state, {
        airtimeDataReceivingCountries: action.receivingCountries,
      });
    case actionType.SHOW_AIRTIME_DATA_SENDING_COUNTRIES:
      return Object.assign({}, state, {
        airtimeDataSendingCountries: action.sendingCountries,
      });
    case actionType.SHOW_SENDING_COUNTRY:
      return Object.assign({}, state, {
        sendingCountry: action.sendingCountry,
      });
    case actionType.SHOW_RECEIVING_COUNTRY:
      return Object.assign({}, state, {
        receivingCountry: action.receivingCountry,
      });
    case actionType.SHOW_COUNTRIES:
      return Object.assign({}, state, {
        countries: action.countries,
      });
    default:
      return state;
  }
}

function currencyReducer(
  state = {
    sendingCurrency: {},
    receivingCurrency: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_SENDING_CURRENCY:
      return Object.assign({}, state, {
        sendingCurrency: action.sendingCurrency,
      });
    case actionType.SHOW_RECEIVING_CURRENCY:
      return Object.assign({}, state, {
        receivingCurrency: action.receivingCurrency,
      });
    default:
      return state;
  }
}

function transactionReducer(
  state = {
    currentPage: 0,
    totalPages: 0,
    transactionList: [],
    next: null,
    prev: null,
    first: null,
    last: null,
    transaction: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_TRANSACTION_LIST:
      return Object.assign({}, state, {
        currentPage: action.currentPage,
        totalPages: action.totalPages,
        transactionList: action.transactionList,
        next: action.next,
        prev: action.prev,
        first: action.first,
        last: action.last,
      });
    case actionType.SHOW_TRANSACTION_DETAILS:
      return Object.assign({}, state, {
        transaction: action.transaction,
      });
    default:
      return state;
  }
}

function productServiceReducer(
  state = {
    services: [],
    products: [],
    product: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_PRODUCTS_SERVICES:
      return Object.assign({}, state, {
        products: action.products,
        services: action.services,
      });
    case actionType.SHOW_PRODUCT_DETAILS:
      return Object.assign({}, state, {
        product: action.product,
      });
    default:
      return state;
  }
}

function calculationReducer(
  state = {
    isReverse: false,
    calculation: {},
    calculationRequest: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CALCULATION:
      return Object.assign({}, state, {
        calculation: action.calculation,
      });
    case actionType.SHOW_CALCULATION_REQUEST:
      return Object.assign({}, state, {
        calculationRequest: action.calculationRequest,
        isReverse: action.isReverse,
      });
    default:
      return state;
  }
}

function recipientReducer(
  state = {
    recipients: [],
    recipient: {},
    next: null,
    prev: null,
    first: null,
    last: null,
    url: null,
    updated: false,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_RECIPIENTS:
      return Object.assign({}, state, {
        recipients: action.recipients,
        next: action.next,
        prev: action.prev,
        first: action.first,
        last: action.last,
        url: action.url,
      });
    case actionType.SHOW_RECIPIENT:
      return Object.assign({}, state, {
        recipient: action.recipient,
        updated: action.updated,
      });
    default:
      return state;
  }
}

function airtimeDataProductReducer(
  state = {
    products: [],
    product: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_AIRTIME_DATA_PRODUCTS:
      return Object.assign({}, state, {
        products: action.products,
        loading: action.loading,
        error: action.error,
      });
    case actionType.SHOW_AIRTIME_DATA_PRODUCT_DETAILS:
      return Object.assign({}, state, {
        product: action.product,
      });
    default:
      return state;
  }
}

function branchReducer(
  state = {
    branches: [],
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_BRANCHES:
      return Object.assign({}, state, {
        branches: action.branches,
      });
    default:
      return state;
  }
}

function cplReducer(
  state = {
    cpls: [],
    cplsShops: [],
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CPLS:
      return Object.assign({}, state, {
        cpls: action.cpls,
      });
    case actionType.SHOW_CPLS_SHOPS:
      return Object.assign({}, state, {
        cplsShops: action.cplsShops,
      });
    default:
      return state;
  }
}

function cppReducer(
  state = {
    cpps: [],
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CPPS:
      return Object.assign({}, state, {
        cpps: action.cpps,
      });
    default:
      return state;
  }
}

function paymentMethodReducer(
  state = {
    paymentMethod: {},
    reason: [],
    source: [],
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_PAYMENT:
      return Object.assign({}, state, {
        paymentMethod: action.paymentMethod,
        reason: action.reason,
        source: action.source,
      });
    default:
      return state;
  }
}

function agentsReducer(
  state = {
    agents: [],
    next: null,
    prev: null,
    first: null,
    last: null,
    url: null,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_AGENTS:
      return Object.assign({}, state, {
        agents: action.agents,
        next: action.next,
        prev: action.prev,
        first: action.first,
        last: action.last,
        url: action.url,
      });
    default:
      return state;
  }
}

function mmpReducer(
  state = {
    mmp: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CURRENT_MMP:
      return Object.assign({}, state, {
        mmp: action.mmp,
      });
    default:
      return state;
  }
}

function cashOutPointsReducer(
  state = {
    cashOutPoints: [],
    next: null,
    prev: null,
    first: null,
    last: null,
    url: null,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CASH_OUT_POINTS:
      return Object.assign({}, state, {
        cashOutPoints: action.cashOutPoints,
        next: action.next,
        prev: action.prev,
        first: action.first,
        last: action.last,
        url: action.url,
      });
    default:
      return state;
  }
}

function cashInPointsReducer(
  state = {
    cashInPoints: [],
    next: null,
    prev: null,
    first: null,
    last: null,
    url: null,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_CASH_IN_POINTS:
      return Object.assign({}, state, {
        cashInPoints: action.cashInPoints,
        next: action.next,
        prev: action.prev,
        first: action.first,
        last: action.last,
        url: action.url,
      });
    default:
      return state;
  }
}

function bwPolicyReducer(
  state = {
    bwBeneficiaries: [],
    bwBeneficiary: {},
    index: 0,
    total: 0,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_BREADWINNER_BENEFICIARIES:
      return Object.assign({}, state, {
        bwBeneficiaries: action.bwBeneficiaries,
      });
    case actionType.SHOW_BREADWINNER_BENEFICIARY:
      return Object.assign({}, state, {
        bwBeneficiary: action.bwBeneficiary,
      });
    case actionType.SHOW_BREADWINNER_BENEFICIARY_INDEX:
      return Object.assign({}, state, {
        index: action.index,
        total: action.total,
      });
    default:
      return state;
  }
}

function otpReducer(
  state = {
    success: false,
    signInSuccess: false,
    goToNames: false,
    goToReg: false,
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_OTP_CODE:
      return Object.assign({}, state, {
        success: action.success,
      });
    case actionType.SHOW_OTP_SIGN_IN:
      return Object.assign({}, state, {
        signInSuccess: action.success,
        goToNames: action.goToNames,
        goToReg: action.goToReg,
      });
    default:
      return state;
  }
}

function referenceCodeReducer(
  state = {
    referenceCode: ""
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_REFERENCE_CODE:
      return Object.assign({}, state, {
        referenceCode: action.referenceCode,
      });
    default:
      return state;
  }
}

function referralsReducer(
  state = {
    referrals: [],
    commissions: [],
    campaigns: [],
    airtimeDataBundleProducts: [],
    voucher: {},
    airtimeDataBundle: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_REFERRALS:
      return Object.assign({}, state, {
        referrals: action.referrals,
      });
    case actionType.SHOW_COMMISSIONS:
      return Object.assign({}, state, {
        commissions: action.commissions,
      });
    case actionType.SHOW_CAMPAIGNS:
      return Object.assign({}, state, {
        campaigns: action.campaigns,
      });
    case actionType.SHOW_REFERRALS_AIRTIME_DATA_BUNDLE_PRODUCTS:
      return Object.assign({}, state, {
        airtimeDataBundleProducts: action.airtimeDataBundleProducts,
      });
    case actionType.SHOW_REFERRALS_VOUCHER:
      return Object.assign({}, state, {
        voucher: action.voucher,
      });
    case actionType.SHOW_REFERRALS_AIRTIME_DATA_BUNDLE:
      return Object.assign({}, state, {
        airtimeDataBundle: action.airtimeDataBundle,
      });
    default:
      return state;
  }
}

function remitHopeReducer(
  state = {
    categories: [],
    campaigns: [],
    product: {},
    calculate: {},
    transaction: {},
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_REMIT_HOPE_CATEGORIES:
      return Object.assign({}, state, {
        categories: action.categories,
      });
    case actionType.SHOW_REMIT_HOPE_CAMPAIGNS:
      return Object.assign({}, state, {
        campaigns: action.campaigns,
      });
    case actionType.SHOW_REMIT_HOPE_PRODUCT:
      return Object.assign({}, state, {
        product: action.product,
      });
    case actionType.SHOW_REMIT_HOPE_CALCULATE:
      return Object.assign({}, state, {
        calculate: action.calculate,
      });
    case actionType.SHOW_REMIT_HOPE_TRANSACTION:
      return Object.assign({}, state, {
        transaction: action.transaction,
      });
    default:
      return state;
  }
}

function fileUploadReducer(
  state = {
    success: false,
    fileType: ""
  },
  action
) {
  switch (action.type) {
    case actionType.SHOW_UPLOADED_FILE:
      return Object.assign({}, state, {
        success: action.success,
        fileType: action.fileType
      });
    default:
      return state;
  }
}

const appReducer = combineReducers({
  authReducer,
  notificationsReducer,
  userReducer,
  countryReducer,
  transactionReducer,
  currencyReducer,
  productServiceReducer,
  airtimeDataProductReducer,
  calculationReducer,
  recipientReducer,
  branchReducer,
  cplReducer,
  paymentMethodReducer,
  cppReducer,
  agentsReducer,
  mmpReducer,
  cashOutPointsReducer,
  cashInPointsReducer,
  bwPolicyReducer,
  otpReducer,
  referralsReducer,
  referenceCodeReducer,
  remitHopeReducer,
  fileUploadReducer,
});

export default appReducer;
