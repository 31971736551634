import React from "react";
import Log from "../utils/log.js";
import { Layout, Card } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import shareALinkOrCodeImage from "../images/share-a-link-or-code.png";
import familyAndFriendsImage from "../images/family-and-friends.png";
import myRewards from "../images/my-rewards.png";

const { Content } = Layout;
const TAG = "SmartReferral";

const cardStyle = {
  width: 200,
  margin: "0 20px",
  cursor: "pointer",
};

const iconDivStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const imgStyle = {
  objectFit: "cover",
  width: "160px",
};

class Referral extends React.Component {
  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            <h2 style={{ fontFamily: "Lato" }}>Referrals</h2>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: "50px 0",
                    }}
                  >
                    <Card
                      onClick={() =>
                        this.props.history.push("/share-a-link-or-code")
                      }
                      style={cardStyle}
                    >
                      <div style={iconDivStyle}>
                        <img src={shareALinkOrCodeImage} style={imgStyle} />
                        <h6>Share a link or code</h6>
                      </div>
                    </Card>
                    <Card
                      onClick={() =>
                        this.props.history.push("/family-and-friends")
                      }
                      style={cardStyle}
                    >
                      <div style={iconDivStyle}>
                        <img src={familyAndFriendsImage} style={imgStyle} />
                        <h6>Family and Friends</h6>
                      </div>
                    </Card>
                    <Card
                      onClick={() => this.props.history.push("/my-rewards")}
                      style={cardStyle}
                    >
                      <div style={iconDivStyle}>
                        <img src={myRewards} style={imgStyle} />
                        <h6>My rewards</h6>
                      </div>
                    </Card>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      // requestSendingCountries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
