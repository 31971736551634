import React from 'react';
import moment from 'moment'
import { Form, Input, Menu, Button, Dropdown, Icon, message, DatePicker, Tooltip, Checkbox } from 'antd';
import { Layout } from 'antd';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import '../styles/recipient.css'
import endpointConfig from "../env-config.json";
import {
  requestReceivingCountries,
  fetchUserDetails,
  requestRecipientByID,
  requestReceivingCountry,
  requestProductDetails,
  manageRecipient,
  requestBranchesByBank,
  requestCplsByCpp,
  requestProductsAndServices,
  requestCurrentMMPDetails,
  showCurrentMMP,
  showProductsServices
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AUTH_ERROR, AuthError, ServerError } from '../utils/xhr.js';
import xhr from "../utils/xhr.js";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartManageRecipient";

class ManageRecipient extends React.Component {

  render() {
    return (
      <Content className="ar-content-container">
        <WrappedManageRecipientForm {...this.props} />
      </Content>
    )
  }

  componentDidUpdate(prevProps) {

  }
}

class ManageRecipientForm extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "ManageRecipientForm() -- props=" + JSON.stringify(props));

    var sendingCountry = {};
    var receivingCountry = {};
    var sendingCurrency = {};
    var receivingCurrency = {};
    var product = {};
    var calculation = {};
    var rawBeneficiarySettings = {};
    var transferReasons = [];
    var defaultTransferReason = "";
    var acceptedBeneficiaryTypes = [];
    var acceptedRecipientTypes = [];
    var paymentMethods = [];

    if (props.location != null) {
      if (props.location.state != null) {
        sendingCountry = props.location.state.sendingCountry
        receivingCountry = props.location.state.receivingCountry
        sendingCurrency = props.location.state.sendingCurrency
        receivingCurrency = props.location.state.receivingCurrency
        product = props.location.state.product
        calculation = props.location.state.calculation
        rawBeneficiarySettings = props.location.state.beneficiarySettings
        transferReasons = props.location.state.transferReasons
        defaultTransferReason = props.location.state.defaultTransferReason
        acceptedBeneficiaryTypes = props.location.state.acceptedBeneficiaryTypes
        acceptedRecipientTypes = props.location.state.acceptedRecipientTypes
        paymentMethods = props.location.state.paymentMethods
      }
    }

    Log.d(TAG, "ManageRecipientForm() -- product=" + JSON.stringify(product));

    this.state = {
      sendingCountry : sendingCountry,
      receivingCountry: receivingCountry,
      sendingCurrency : sendingCurrency,
      receivingCurrency : receivingCurrency,
      product: product,
      calculation: calculation,
      rawBeneficiarySettings : rawBeneficiarySettings,
      transferReasons : transferReasons,
      defaultTransferReason : defaultTransferReason,
      acceptedBeneficiaryTypes : acceptedBeneficiaryTypes,
      acceptedRecipientTypes : acceptedRecipientTypes,
      receivingCountries: [],
      beneficiarySettings: [],
      sourceOfFunds: [ ["SAL", "Salary"], ["SV", "Savings"], ["GF", "Gift"], ["PN", "Pension"]],
      paymentMethods : paymentMethods,
      cards: [],
      service: 0
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));
    if (prevProps.productServiceReducer.products !== this.props.productServiceReducer.products) {
      if (this.props.productServiceReducer.products.length > 0) {
        var services = this.props.productServiceReducer.services;
        for (var s = 0; s < services.length; s++) {
          Log.d(TAG, "componentDidUpdate() -- services[s]=" + JSON.stringify(services[s]));
          if (services[s].attributes.name === "Mobile wallet top-up") {
            delete services[s]
          }
        }
        this.props.showProductsServices(this.props.productServiceReducer.products, services)

        this.setState({
          service : 0,
        })
        this.props.form.setFieldsValue({
          service: 0
        })
        this.assignProducts(this.props.productServiceReducer.services[0]);
      }
    }

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
      if(!!this.props.userReducer.error.includes) {
        if (this.props.userReducer.error.includes("password") && this.props.userReducer.error.includes("expired")) {
          message.error("Please change your password first.", 5);
          this.props.history.push({
            pathname: "/update-profile"
          })
        }
      }
    }

    if (this.props.userReducer.success && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
    }

    if (this.props.countryReducer.receivingCountries.length > 0 && (prevProps.countryReducer.receivingCountries !== this.props.countryReducer.receivingCountries)) {
      Log.d(TAG, "componentDidUpdate() -- countries=" + JSON.stringify(this.props.countryReducer.receivingCountries));
      this.setState({
        receivingCountries: this.props.countryReducer.receivingCountries,
        recipient : {}
      });
      this.requestCountryDetails(this.props.countryReducer.receivingCountries[0].id)
    }

    if (Object.keys(this.props.userReducer.user).length > 0 && (prevProps.userReducer.user !== this.props.userReducer.user)) {
      Log.d(TAG, "componentDidUpdate() -- user=" + JSON.stringify(this.props.userReducer.user));
      var included = this.props.userReducer.user.included;

      if (Helper.acceptedIDDocumentsCount(this.props.userReducer.user) > 0) {
        if (this.props.userReducer.user.data.attributes.source_of_registration === "A") { // not important to check selfies of users registered by agents
          if (Object.keys(included[0]).length > 0) {
            this.props.showCurrentMMP({});
            this.setUserDetails(this.props.userReducer.user);
          }
        } else {
          if (Helper.acceptedSelfiesCount(this.props.userReducer.user) > 0) {
            if (Object.keys(included[0]).length > 0) {
              this.props.showCurrentMMP({});
              this.setUserDetails(this.props.userReducer.user);
            }
          } else {
            this.popBack()
          }
        }
      } else {
        this.popBack()
      }
    }

    if (Object.keys(this.props.recipientReducer.recipient).length > 0 && (prevProps.recipientReducer.recipient !== this.props.recipientReducer.recipient)) {
      if (this.props.recipientReducer.updated) {
        message.success("Success", 5);
        Log.d(TAG, "componentDidUpdate() -- sendingAmount=" + this.state.sendingAmount )
        Log.d(TAG, "componentDidUpdate() -- paymentMethods=" + JSON.stringify(this.state.paymentMethods))
        Log.d(TAG, "componentDidUpdate() -- defaultTransferReason=" + this.state.defaultTransferReason)
        Log.d(TAG, "componentDidUpdate() -- user=" + JSON.stringify(this.props.userReducer.user))
        if (Object.keys(this.state.product).length > 0 && Object.keys(this.props.userReducer.user).length > 0) {
          if (this.state.paymentMethods.length > 0) {
            var dReason = this.checkDefaultReason();
            Log.d(TAG, "componentDidUpdate() -- dReason=" + dReason)
            this.props.history.push({
              pathname: '/select-payment-method',
              state: {
                sendingCountry : this.state.sendingCountry,
                receivingCountry: this.state.receivingCountry,
                product: this.state.product,
                calculation: this.state.calculation,
                recipient: this.props.recipientReducer.recipient,
                paymentMethods : this.state.paymentMethods,
                cards: this.state.cards,
                receivingCurrency: this.state.receivingCurrency,
                sendingCurrency : this.state.sendingCurrency,
                transferReasons : this.state.transferReasons,
                defaultTransferReason : dReason,
                transaction: {}
              }
            });
          } else {
            message.error("No payment method is available under chosen payout type", 5);
          }
        } else {
          this.props.history.push({
            pathname: '/recipient-list'
          });
        }
      } else {
        this.setState({
          recipient: this.props.recipientReducer.recipient
        })
        Log.d(TAG, "componentDidUpdate() -- recipient=" + JSON.stringify(this.props.recipientReducer.recipient));
        Log.d(TAG, "componentDidUpdate() -- raw recipient=" + JSON.stringify(this.props.recipientReducer.recipient));

        var recipientData = this.props.recipientReducer.recipient.data;
        var recipienIncluded = this.props.recipientReducer.recipient.included;
        Log.d(TAG, "componentDidUpdate() -- recipienIncluded=" + JSON.stringify(recipienIncluded));
        var relationships = recipientData.relationships;

        Log.d(TAG, "componentDidUpdate() -- recipientData=" + JSON.stringify(recipientData));
        if (recipientData.hasOwnProperty("attributes") && recipientData.hasOwnProperty("relationships")) {
          Log.d(TAG, "componentDidUpdate() -- has attributes");
          var attributes = recipientData.attributes;
          Log.d(TAG, "componentDidUpdate() -- attributes length=" + Object.keys(attributes).length);
          for (var key in attributes) {
            if (!Helper.containsIDAttributes(key)) {
              this.setState({
                [key] : Helper.isEmpty(attributes[key]) ? "" : attributes[key]
              })
            }
          }
        }

        for (var index = 0; index < recipienIncluded.length; index++) {
          var includedObj = recipienIncluded[index];
          for (var rKey in relationships) {
            if (relationships[rKey].data) {
              if (rKey !== "passport_country") {
                if ((relationships[rKey].data.type === includedObj.type)) {
                  this.setState({
                    [rKey] : this.setCurrentKeyValue(JSON.stringify({ "type" : includedObj.type, "id" : includedObj.id }), includedObj.attributes.name)
                  })

                  if (includedObj.type === "mobile_money_providers") {
                    var mmp = {};
                    mmp.data = includedObj;
                    Log.d(TAG, "componentDidUpdate() -- current mmp from recipient=" + JSON.stringify(mmp));
                    this.props.showCurrentMMP(mmp);
                  }
                }
              }
            }
          }
        }

        if (relationships.hasOwnProperty("passport_country")) {
          this.setState({
            "passport_country" : { key : JSON.stringify(relationships.passport_country.data), value: "" }
          })
        }

        if (Object.keys(this.state.receivingCountry).length > 0 && Object.keys(this.state.rawBeneficiarySettings).length > 0) {
          Log.d(TAG, "componentDidUpdate() -- receivingCountry=" + JSON.stringify(this.state.receivingCountry));
          Log.d(TAG, "componentDidUpdate() -- rawBeneficiarySettings=" + JSON.stringify(this.state.rawBeneficiarySettings));
          this.setState({
            receivingCountries: [ this.state.receivingCountry ]
          })
          this.processRawBS(this.state.rawBeneficiarySettings)
        } else {
          Log.d(TAG, "componentDidUpdate() -- request receiving country");
          this.requestCountryDetails(recipientData.relationships.country.data.id);
        }
      }
    }

    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0 && (prevProps.countryReducer.receivingCountry !== this.props.countryReducer.receivingCountry)) {

      Log.d(TAG, "componentDidUpdate() -- receiving country=" + JSON.stringify(this.props.countryReducer.receivingCountry));
      this.setState({
        receivingCountry : this.props.countryReducer.receivingCountry.data
      });
      if (Object.keys(this.props.calculationReducer.calculation).length <= 0 && Object.keys(this.props.recipientReducer.recipient).length <= 0) {
        Log.d(TAG, "componentDidUpdate() -- no calculation and recipient, request products and services");
        this.requestProductsAndServices(this.props.countryReducer.receivingCountry.data)
      } else {
        Log.d(TAG, "componentDidUpdate() -- has calculation, DO NOT request products and services");
        var countryBS = this.props.countryReducer.receivingCountry.data.attributes.beneficiary_settings;
        this.processRawBS(countryBS);
      }
    }

    if (Object.keys(this.props.productServiceReducer.product).length > 0 && (prevProps.productServiceReducer.product !== this.props.productServiceReducer.product)) {

      Log.d(TAG, "componentDidUpdate() -- current product=" + JSON.stringify(this.props.productServiceReducer.product));
      var includedO = this.props.productServiceReducer.product.included;
      var paymentMethods = [];

      for (var i = 0; i < includedO.length; i++) {
        var includedObj1 = includedO[i];
        if (includedObj1.type === "payment_methods") {
          paymentMethods.push(includedObj1);
        }
      }

      this.setState({
        paymentMethods: paymentMethods
      })

      var productBS = this.props.productServiceReducer.product.data.attributes.beneficiary_settings;
      this.processRawBS(productBS)
    }

    if (this.props.branchReducer.branches.length > 0 && (prevProps.branchReducer.branches !== this.props.branchReducer.branches)) {
      var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch")
      this.resetBranches();
      var option = branchBS.options;

      Log.d(TAG, "componentDidUpdate() -- branches=" + JSON.stringify(this.props.branchReducer.branches));
      for (var k = 0; k < Object.keys(this.props.branchReducer.branches).length; k++) {
        var branch = this.props.branchReducer.branches[k]
        var optionKey = {};

        optionKey.type = branch.type;
        optionKey.id = branch.id;

        var optionKeyString = JSON.stringify(optionKey);
        option[optionKeyString] = branch.attributes.name
      }

      branchBS.options = option;
      this.replaceBS("branch", branchBS)
    }

    if (this.props.cplReducer.cpls.length > 0 && (prevProps.cplReducer.cpls !== this.props.cplReducer.cpls)) {
      var cplBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "cash_pickup_location")

      this.resetCpls();

      var optionCPL = cplBS.options;

      Log.d(TAG, "componentDidUpdate() -- cpls=" + JSON.stringify(this.props.cplReducer.cpls));
      for (var l = 0; l < Object.keys(this.props.cplReducer.cpls).length; l++) {
        var cpl = this.props.cplReducer.cpls[l]
        var optionKey1 = {};

        optionKey1.type = cpl.type;
        optionKey1.id = cpl.id;

        var optionKeyString1 = JSON.stringify(optionKey1);
        optionCPL[optionKeyString1] = cpl.attributes.name
        Log.d(TAG, "componentDidUpdate() -- cpl key=" + optionKeyString1 + " value=" + optionCPL[optionKeyString1]);

        var currentKeyValue = {}

        currentKeyValue.key = optionKeyString1
        currentKeyValue.value = optionCPL[optionKeyString1]

        Log.d(TAG, "componentDidUpdate() -- cpl currentKeyValue=" +  JSON.stringify(currentKeyValue));

        this.props.form.setFieldsValue({
          cash_pickup_location: currentKeyValue
        })

        this.setState({
          cash_pickup_location : currentKeyValue
        })
      }

      cplBS.options = optionCPL;
      this.replaceBS("cash_pickup_location", cplBS)

    }


    if (Object.keys(this.props.mmpReducer.mmp).length > 0 && (prevProps.mmpReducer.mmp !== this.props.mmpReducer.mmp)) {
      Log.d(TAG, "componentDidUpdate() -- current mmp=" + JSON.stringify(this.props.mmpReducer.mmp))

    }

    let isBranchDistrict =  this.state.beneficiarySettings.some(bs => bs.mainKey === "branch_district");
    let isNoBranchState =  !this.state.beneficiarySettings.some(bs => bs.mainKey === "branch_state");
    let branchDistrictBeneficiary = isBranchDistrict && this.state.beneficiarySettings.find(bs => bs.mainKey === "branch_district");
    let lessThanTwoBranchDistrictOptions = branchDistrictBeneficiary.options && Object.keys(branchDistrictBeneficiary.options).length < 2;
    let willLoadOptionsWithBranchState = !!this.state.branch_state && !!this.state.bank && lessThanTwoBranchDistrictOptions
    
    
    if(willLoadOptionsWithBranchState) {
      
      let bankId = !!this.state["bank"] ? JSON.parse(this.state["bank"].key).id : undefined;
      let stateId = !!this.state["branch_state"] ? JSON.parse(this.state["branch_state"].key).id : undefined;
      let districtId = !!this.state["branch_district"] ? JSON.parse(this.state["branch_district"].key).id : undefined;
      let cityId = !!this.state["branch_city"] ? JSON.parse(this.state["branch_city"].key).id : undefined;
      let countryId = JSON.parse(this.state["country"].key).id

      let bankNotEmpty = bankId !== 0 && bankId !== undefined;
      let stateNotEmpty = stateId !== 0 && stateId !== undefined;
      let districtNotEmpty = districtId !== 0 && districtId !== undefined;
      let cityNotEmpty = cityId !== 0 && cityId !== undefined;

      if(bankNotEmpty) {
        this.fetchStates(bankId, countryId);
        stateNotEmpty && this.fetchDistricts(bankId, countryId, stateId);
        districtNotEmpty && this.fetchCities(bankId, countryId, stateId, districtId);
        (districtNotEmpty && cityNotEmpty) && this.fetchBranches(bankId, stateId, districtId, cityId);
      }
    } 

    let willLoadOptionsWithoutBranchState = !!this.state.bank && lessThanTwoBranchDistrictOptions && isNoBranchState;
    
    if(willLoadOptionsWithoutBranchState) {
      let bankId = !!this.state["bank"] ? JSON.parse(this.state["bank"].key).id : undefined;
      let districtId = !!this.state["branch_district"] ? JSON.parse(this.state["branch_district"].key).id : undefined;
      let countryId = JSON.parse(this.state["country"].key).id
      let stateId = undefined
      let cityId = undefined

      let bankNotEmpty = bankId !== 0 && bankId !== undefined;
      let districtNotEmpty = districtId !== 0 && districtId !== undefined;

      if(bankNotEmpty) {
        this.fetchDistricts(bankId, countryId, stateId);
        districtNotEmpty && this.fetchBranches(bankId, stateId, districtId, cityId);
      }
    }
  }


  popBack() {
    this.props.history.push({
      pathname: "/recipient-list"
    })
  }

  assignProducts(chosenService) {
    Log.d(TAG, "assignProducts() -- chosen service=" + JSON.stringify(chosenService))
    var products = [];

    this.setState({
      products: products,
      product: 0
    })

    Log.d(TAG, "assignProducts() -- chosen service=" + chosenService.id + " name=" + chosenService.attributes.name)
    for (var i = 0; i < this.props.productServiceReducer.products.length; i++) {
      var currentProduct = this.props.productServiceReducer.products[i];
      if (chosenService.id === currentProduct.relationships.group.data.id) {
        products.push(currentProduct);
      }
    }
    this.setState({
      products : products
    })

    Log.d(TAG, "assignProducts() -- products=" + JSON.stringify(products))

    if (products.length > 0) {
      this.setState({
        product : 0
      })
      this.props.form.setFieldsValue({
        product: 0
      })
      Log.d(TAG, "assignProducts() -- first products=" + products[0].attributes.name)
      this.requestProductDetails(products[0].id);
    }
  }

  checkDefaultReason() {
    var defaultReason = this.state.defaultTransferReason;
    var idVerificationType = this.props.userReducer.user.data.attributes.id_verification_type
    Log.d(TAG, "checkDefaultReason() -- idVerificationType=" + idVerificationType);
    if (idVerificationType) {
      if (idVerificationType !== "SI") {
        Log.d(TAG, "checkDefaultReason() -- find remittance");
        for(var tf = 0; tf < this.state.transferReasons.length; tf++) {
          if (this.state.transferReasons[tf][1] === "Remittance") {
            Log.d(TAG, "checkDefaultReason() -- found remittance");
            defaultReason = this.state.transferReasons[tf][0]
          }
        }
      }
    }
    Log.d(TAG, "checkDefaultReason() -- defaultReason=" + defaultReason);
    return defaultReason;
  }

  checkIfHasUser() {
    // Log.d(TAG, "checkIfHasUser() -- access=" + cookies.get('access'));
    Log.d(TAG, "checkIfHasUser() -- access=" + this.props.authReducer.accessToken);
    // Log.d(TAG, "checkIfHasUser() -- refresh=" + cookies.get('refresh'));

    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      this.requestUserDetails()
    } else {
      Log.d(TAG, "checkIfHasUser() -- no access available");
    }
  }

  requestProductsAndServices(receivingCountry) {
    Log.d(TAG, "requestProductsAndServices() -- receivingCountry=" + JSON.stringify(receivingCountry));
    if (Object.keys(this.state.sendingCountry).length > 0 && Object.keys(receivingCountry).length > 0) {
      var sendingCountryAlpha = this.state.sendingCountry.attributes.alpha_2_code;
      var receivingCountryAlpha = receivingCountry.attributes.alpha_2_code;

      Log.d(TAG, "requestProductsAndServices() -- sendingCountryAlpha=" + sendingCountryAlpha + " receivingCountryAlpha=" + receivingCountryAlpha)
      this.props.requestProductsAndServices(receivingCountryAlpha, sendingCountryAlpha);
    }
  }

  setUserDetails(userDetails) {
    if (Object.keys(userDetails.included[0]).length > 0) {
      for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
        if (this.props.userReducer.user.included[i].type === "countries") {
          this.setState({
            sendingCountry: userDetails.included[i]
          })
        }
      }

      var relationships = userDetails.data.relationships;
      if (relationships.hasOwnProperty("saved_cards")) {
        var savedCardsObj = relationships.saved_cards;
        if (savedCardsObj.hasOwnProperty("data")) {
          if (savedCardsObj.data != null) {
            this.setState({
              cards : savedCardsObj.data
            })
          }
        }
      }

      var url = this.props.match.url;
      if (url.charAt(url.length - 1) === "/") {
        url = url.slice(0, url.lastIndexOf("/"))
      }

      var regex = new RegExp("^/manage-recipient/([a-zA-Z0-9])+$");
      var testURL = regex.test(url);
      Log.d(TAG, "setUserDetails() -- testURL=" + testURL + " url=" + url);
      if (testURL) {
        var recipientID = url.slice(url.lastIndexOf("/") + 1, url.length)
        Log.d(TAG, "setUserDetails() -- ID=" + recipientID);
    if (!!this.props.authReducer.accessToken) {
          this.requestBeneficiaryDetails(recipientID)
        }
      } else {
        if (Object.keys(this.state.receivingCountry).length > 0 && Object.keys(this.state.rawBeneficiarySettings).length > 0) {
          Log.d(TAG, "setUserDetails() -- receivingCountry=" + JSON.stringify(this.state.receivingCountry));
          Log.d(TAG, "setUserDetails() -- rawBeneficiarySettings=" + JSON.stringify(this.state.rawBeneficiarySettings));
          this.setState({
            receivingCountries: [ this.state.receivingCountry ],
            recipient : {}
          })
          this.processRawBS(this.state.rawBeneficiarySettings)
        } else {
          Log.d(TAG, "setUserDetails() -- request receiving country");
          for (var k = 0; k < this.props.userReducer.user.included.length; k++) {
            if (this.props.userReducer.user.included[k].type === "countries") {
              this.requestReceivingCountries(userDetails.included[k].attributes.alpha_2_code);
            }
          }
        }
      }
      if(Object.keys(this.state.product).length > 0) {
          this.requestProductDetails(this.state.product.data.id);
      }
    }
  }

  renderServicesArea(getFieldDecorator) {
    Log.d(TAG, "renderServicesArea() -- services=" + JSON.stringify(this.props.productServiceReducer.groups) + " state=" + JSON.stringify(this.state))
    if (this.props.productServiceReducer.groups && Object.keys(this.props.calculationReducer.calculation).length <= 0 && Object.keys(this.props.recipientReducer.recipient).length <= 0) {
      if (this.props.productServiceReducer.groups.length > 0) {
        return (
          <FormItem label="Terminating Platform">
            {getFieldDecorator('service', {
                rules: [{ required: true, message: 'Please terminating platform' }],
              })(
                <Dropdown
                  overlay={this.renderServicesParent()}
                  trigger={['click']}>
                  <div className="dropdown-item">
                    {this.renderServicesDropdown()}
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
          </FormItem>
        )
      }
    }

    return (<div/>)
  }

  renderServicesDropdown() {
    Log.d(TAG, "renderServicesDropdown() -- current service=" + JSON.stringify(this.props.productServiceReducer.groups[this.state.group]))
    if (this.props.productServiceReducer.groups.length > 0) {
      return (
        <div>
          {this.props.productServiceReducer.groups[this.state.group].attributes.name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderServicesParent(){
    return (
        <Menu onClick={this.onChangeService}>
          {this.renderServices()}
        </Menu>
      )
  }

  renderServices(){
    return this.props.productServiceReducer.groups.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  onChangeService = (event) => {
    this.setState({
      service : event.key
    })
    this.props.form.setFieldsValue({
      service: event.key
    })

    if (this.props.productServiceReducer.products.length > 0) {
      this.assignProducts(this.props.productServiceReducer.groups[event.key]);
    }
  }

  componentDidMount() {
    this.checkIfHasUser();
  }

  requestReceivingCountries(sendingCountry) {
    this.props.requestReceivingCountries(sendingCountry);
  }

  requestUserDetails() {
    Log.d(TAG, "requestUserDetails() -- called ..");
    const { cookies } = this.props;
    // this.props.fetchUserDetails(cookies, cookies.get('access'), false, "");
    this.props.fetchUserDetails(cookies, this.props.authReducer.accessToken, false, "");
  }

  requestBeneficiaryDetails(recipientID) {
    Log.d(TAG, "requestBeneficiaryDetails() -- called ..");
    // this.props.requestRecipientByID(recipientID, cookies.get('access'));
    this.props.requestRecipientByID(recipientID, this.props.authReducer.accessToken);
  }

  setCurrentKeyValue(key, value) {
    var currentKeyValue = {}
    var currentKey = key
    var currentValue = value
    currentKeyValue.key = currentKey;
    currentKeyValue.value = currentValue;
    return currentKeyValue;
  }

  requestCountryDetails = (countryID) => {
    this.props.requestReceivingCountry(countryID);
  }

  requestProductDetails(id) {
    var sendingCountryAlpha = this.state.sendingCountry.attributes.alpha_2_code;
    var receivingCountryAlpha = this.state.receivingCountry.attributes.alpha_2_code;

    Log.d(TAG, "requestProductDetails() -- sendingCountryAlpha=" + sendingCountryAlpha
      + " receivingCountryAlpha=" + receivingCountryAlpha
      + " product ID=" + id);

    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      // this.props.requestProductDetails(id, cookies.get('access'), receivingCountryAlpha, sendingCountryAlpha)
      this.props.requestProductDetails(id, this.props.authReducer.accessToken, receivingCountryAlpha, sendingCountryAlpha)
    }
  }

  processRawBS(beneficiarySettings) {
    Log.d(TAG, "processRawBS() -- beneficiarySettings=" + JSON.stringify(beneficiarySettings));
    if (beneficiarySettings.hasOwnProperty("recipient_type")) {
      Log.d(TAG, "processRawBS() -- has recipient_type");
      if (Object.keys(beneficiarySettings.recipient_type.options).length > 0) {
        Log.d(TAG, "processRawBS() -- has recipient_type options more than 0");
        var isEntity = Object.keys(beneficiarySettings.recipient_type.options)[0] === "E";
        if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
          if (this.props.recipientReducer.recipient.hasOwnProperty("data")) {
            if (this.props.recipientReducer.recipient.data.hasOwnProperty("attributes")) {
              if (this.props.recipientReducer.recipient.data.attributes.hasOwnProperty("recipient_type")) {
                isEntity = this.props.recipientReducer.recipient.data.attributes.recipient_type === "E";
              }
            }
          }
        }
        Log.d(TAG, "processRawBS() -- isEntity=" + isEntity);
        this.layoutByBeneficiarySettings(beneficiarySettings, isEntity)
      } else{
        this.layoutByBeneficiarySettings(beneficiarySettings, false)
      }
    } else {
      this.layoutByBeneficiarySettings(beneficiarySettings, false)
    }
  }

  layoutByBeneficiarySettings(rawBeneficiarySetting, isEntity) {
    //Log.d(TAG, "layoutByBeneficiarySettings() -- called");
    var addedBeneficiaryKey = [];
    var beneficiarySettings = []

    Object.keys(rawBeneficiarySetting).forEach((mainKey) => {
      var beneficiarySetting = rawBeneficiarySetting[mainKey];
      beneficiarySetting.mainKey = mainKey;
      beneficiarySetting.position = Helper.getOrder(mainKey)
      //Log.d(TAG, "layoutByBeneficiarySettings() -- main key=" + beneficiarySetting.mainKey);
      var uniqueField = Helper.containsUniqueFields(beneficiarySetting.mainKey) && addedBeneficiaryKey.filter(mainKey => mainKey === beneficiarySetting.mainKey).length <= 0;
      var isEntityWithEntityFields = Helper.isEntityWithEntityFields(isEntity, beneficiarySetting);
      var isRecipientWithRecipientFields = Helper.isReceipientWithReceipientFields(!isEntity, beneficiarySetting);

      /*Log.d(TAG, "layoutByBeneficiarySettings() -- isEntityWithEntityFields=" + isEntityWithEntityFields
        + " isRecipientWithRecipientFields=" + isRecipientWithRecipientFields
        + " main key=" + mainKey);*/

      if (beneficiarySetting.hasOwnProperty("options")) {
        Log.d(TAG, "layoutByBeneficiarySettings() -- main key=" + mainKey);
        if (uniqueField) {
          if (isEntityWithEntityFields) {
            Log.d(TAG, "layoutByBeneficiarySettings() -- add 1=" + mainKey);
            beneficiarySettings.push(this.setSelectOptions(beneficiarySetting));
            addedBeneficiaryKey.push(beneficiarySetting.mainKey);
          } else {
            if (isRecipientWithRecipientFields) {
              Log.d(TAG, "layoutByBeneficiarySettings() -- add 2=" + mainKey);
              beneficiarySettings.push(this.setSelectOptions(beneficiarySetting));
              addedBeneficiaryKey.push(beneficiarySetting.mainKey);
            }
          }
          this.setRecipientCorrectOptionsDetails(beneficiarySetting)
        }
      } else {
        if (uniqueField) {
          if (isEntityWithEntityFields) {
            //Log.d(TAG, "layoutByBeneficiarySettings() -- add 3=" + mainKey);
              beneficiarySettings.push(beneficiarySetting);
              addedBeneficiaryKey.push(beneficiarySetting.mainKey);
          } else {
            if (isRecipientWithRecipientFields) {
              //Log.d(TAG, "layoutByBeneficiarySettings() -- add 4=" + mainKey);
              beneficiarySettings.push(beneficiarySetting);
              addedBeneficiaryKey.push(beneficiarySetting.mainKey);
            }
          }
        }
      }
    });
    //Log.d(TAG, "layoutByBeneficiarySettings() -- beneficiarySettings raw count=" + beneficiarySettings.length);
    this.setState({
      beneficiarySettings : beneficiarySettings
    })
  }

  findKeyValueByValueInOptions(beneficiarySetting, value) {
    for (var option in beneficiarySetting.options) {
      if (option === value) {
        return this.setCurrentKeyValue(option, beneficiarySetting.options[option])
      }
    }
    return this.setCurrentKeyValue(null, null)
  }

  setRecipientCorrectOptionsDetails(beneficiarySetting) {
    /*Log.d(TAG, "setRecipientCorrectOptionsDetails() -- recipient=" + JSON.stringify(this.props.recipientReducer.recipient));
    Log.d(TAG, "setRecipientCorrectOptionsDetails() -- beneficiarySetting=" + JSON.stringify(beneficiarySetting));*/
    if (Object.keys(this.props.recipientReducer.recipient).length <= 0) {
      Log.d(TAG, "setRecipientCorrectOptionsDetails() -- recipient is empty");
      this.setState({
        [beneficiarySetting.mainKey] : this.setCurrentKeyValue(Object.keys(beneficiarySetting.options)[0], beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]])
      })
      Log.d(TAG, "setRecipientCorrectOptionsDetails() -- beneficiarySetting.mainKey 1=" + this.state[beneficiarySetting.mainKey]);
    } else {
      /*Log.d(TAG, "setRecipientCorrectOptionsDetails() -- recipient is NOT empty");
      if (Helper.containsIDAttributes(beneficiarySetting.mainKey)) {
        Log.d(TAG, "setRecipientCorrectOptionsDetails() -- Not containing=" + beneficiarySetting.mainKey);
        if (this.props.recipientReducer.recipient.data.attributes.hasOwnProperty(beneficiarySetting.mainKey)) {
          this.setState({
            [beneficiarySetting.mainKey] : this.findKeyValueByValueInOptions(beneficiarySetting, this.props.recipientReducer.recipient.data.attributes[beneficiarySetting.mainKey])
          })
          Log.d(TAG, "setRecipientCorrectOptionsDetails() -- beneficiarySetting.mainKey 2=" + JSON.stringify(this.state[beneficiarySetting.mainKey]));
          var id = JSON.parse(this.state[beneficiarySetting.mainKey].key).id;
          Log.d(TAG, "setRecipientCorrectOptionsDetails() -- id=" + id + " at value=" + this.state[beneficiarySetting.mainKey].value);
        } else {
          this.setState({
            [beneficiarySetting.mainKey] : this.setCurrentKeyValue(Object.keys(beneficiarySetting.options)[0], beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]])
          })
          Log.d(TAG, "setRecipientCorrectOptionsDetails() -- beneficiarySetting.mainKey 3=" + JSON.stringify(this.state[beneficiarySetting.mainKey]));
        }
      } else {
        Log.d(TAG, "setRecipientCorrectOptionsDetails() -- containing=" + beneficiarySetting.mainKey);
      }*/
    }
  }

  setSelectOptions(beneficiarySetting) {
    if (beneficiarySetting.mainKey === "country" || Helper.containsIDAttributes(beneficiarySetting.mainKey)) {
      return beneficiarySetting;
    }

    var type = beneficiarySetting.mainKey
    if (Object.keys(beneficiarySetting.options).length > 0) {
      try {
        var firstOptionKey = JSON.parse(Object.keys(beneficiarySetting.options)[0])
        type = firstOptionKey.type;
      } catch(error) {}
    }

    if (beneficiarySetting.mainKey === "branch") {
      type = "branches"
    }

    if (beneficiarySetting.mainKey === "cash_pickup_location") {
      type = "cash_pickup_locations"
    }

    var newOptions = {}
    if (beneficiarySetting.mainKey !== "cash_pickup_location") {
      var selectKey = "{\"type\" : \"" + type + "\", \"id\" : \"0\"}";
      var selectValue = "Select a " + beneficiarySetting.label
      newOptions[selectKey] = selectValue;
    }
    Object.keys(beneficiarySetting.options).forEach((key) =>{
      newOptions[key] = beneficiarySetting.options[key];
    })
    beneficiarySetting.options = newOptions;

    return beneficiarySetting;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, 'handleSubmit() -- Received values of form: '+ JSON.stringify(values));
      // if (!err && cookies.get('access')) {
      if (!err && !!this.props.authReducer.accessToken) {
        Helper.validateParentChildBS(this.props.form, "bank", "bank_name", this.state.beneficiarySettings, (error) => {
          if (error) {
            Helper.setError(this.props.form, "bank", error)
          } else {
            Helper.validateParentChildBS(this.props.form, "branch", "branch_name", this.state.beneficiarySettings, (error) => {
              if (error) {
                Helper.setError(this.props.form, "branch", error)
              } else {
                Helper.validateParentChildBSByID(this.props.form, "cash_pickup_provider", "cash_pickup_location", this.state.beneficiarySettings, (error) => {
                  if (error) {
                    Helper.setError(this.props.form, "cash_pickup_location", error)
                  } else {
                    Helper.validateParentChildBSByID(this.props.form, "mobile_money_provider", "mobile_money_msisdn", this.state.beneficiarySettings, (error) => {
                      if (error) {
                        Log.d(TAG, 'handleSubmit() -- show error');
                        Helper.setError(this.props.form, "mobile_money_msisdn", error)
                      } else {
                        Log.d(TAG, 'handleSubmit() -- request add ben');
                        this.requestAddBeneficiary(values)
                      }
                    });
                  }
                });
              }
            })
          }
        });
      }
    });
  }

  requestAddBeneficiary(values) {
    Log.d(TAG, "requestAddBeneficiary() -- state=" + JSON.stringify(this.state))
    Log.d(TAG, "requestAddBeneficiary() -- values=" + JSON.stringify(values))
    Log.d(TAG, "requestAddBeneficiary() -- beneficiarySettings=" + JSON.stringify(this.state.beneficiarySettings))
    var attributesObj = {}
    attributesObj["recipient_type"] = "P";
    var relationshipObj = {}
    for (var i = 0; i < this.state.beneficiarySettings.length; i++) {
      var beneficiarySetting = this.state.beneficiarySettings[i];
      if (!beneficiarySetting.hasOwnProperty("options")) {
        if (Helper.containsPhoneNumbers(beneficiarySetting.mainKey)) {
          if (!Helper.isEmpty(values[beneficiarySetting.mainKey])) {
            var phoneData = []
            var prefix = this.state.receivingCountry.attributes.phone_prefix;
            Log.d(TAG, "requestAddBeneficiary() -- key=" + beneficiarySetting.mainKey)
            if (beneficiarySetting.mainKey === "mobile_money_msisdn" && Object.keys(this.props.mmpReducer.mmp).length > 0) {
              prefix = this.props.mmpReducer.mmp.data.attributes.phone_prefix ? this.props.mmpReducer.mmp.data.attributes.phone_prefix : prefix;
            }
            Log.d(TAG, "requestAddBeneficiary() -- prefix=" + prefix)
            phoneData.push(prefix);
            phoneData.push(values[beneficiarySetting.mainKey]);
            attributesObj[beneficiarySetting.mainKey] = phoneData;
          } else {
            attributesObj[beneficiarySetting.mainKey] = null;
          }
        } else {
          if (beneficiarySetting.mainKey === "birth_date") {
            if (values[beneficiarySetting.mainKey]) {
              attributesObj[beneficiarySetting.mainKey] = moment(values[beneficiarySetting.mainKey]).format("YYYY-MM-DD");
            } else {
              attributesObj[beneficiarySetting.mainKey] = null;
            }
          } else {
            attributesObj[beneficiarySetting.mainKey] = this.assignPayloadValue(beneficiarySetting, values[beneficiarySetting.mainKey]);
          }
        }
        Log.d(TAG, "requestAddBeneficiary() -- bs w/o options=" + beneficiarySetting.mainKey + " attributesObj[beneficiarySetting.mainKey]=" + attributesObj[beneficiarySetting.mainKey]);
      } else {
        if (Object.keys(beneficiarySetting.options).length <= 0) {
        } else {
          var currentKeyObj = {}
          var currentKeyID = "0";
          var relData = {}
          try {
            currentKeyObj = JSON.parse(values[beneficiarySetting.mainKey].key);
            Log.d(TAG, "requestAddBeneficiary() -- currentKeyObj=" + JSON.stringify(currentKeyObj));
            if (currentKeyObj.hasOwnProperty("id")) {
              Log.d(TAG, "requestAddBeneficiary() -- has ID bs with options=" + beneficiarySetting.mainKey + " currentKeyID 1=" + currentKeyID);
              currentKeyID = currentKeyObj.id
              if (currentKeyID !== "0") {
                var relObj = {};
                relObj.type = currentKeyObj.type;
                relObj.id = currentKeyID;
                relData.data = relObj;
                relationshipObj[beneficiarySetting.mainKey] = relData
              } else {
                relData.data = null;
                relationshipObj[beneficiarySetting.mainKey] = relData
              }
            } else {
              Log.d(TAG, "requestAddBeneficiary() -- NO ID bs with options=" + beneficiarySetting.mainKey + " currentKeyID 1=" + currentKeyID);
              currentKeyID = values[beneficiarySetting.mainKey].key
              attributesObj[beneficiarySetting.mainKey] = currentKeyID;
            }

          } catch(error) {
            currentKeyID = values[beneficiarySetting.mainKey].key
            attributesObj[beneficiarySetting.mainKey] = currentKeyID;
            Log.d(TAG, "requestAddBeneficiary() -- bs with options=" + beneficiarySetting.mainKey + " currentKeyID 2=" + currentKeyID);

          }
        }
      }
    }

    var id = null;
    if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
      id = this.props.recipientReducer.recipient.data.id;
    }

    Log.d(TAG, "requestAddBeneficiary() -- id=" + id)
    Log.d(TAG, "requestAddBeneficiary() -- product=" + JSON.stringify(this.state.product))

    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      var prodObj = {};
      prodObj.type = "products";
      prodObj.id = this.props.productServiceReducer.product.data.id;
      var prodData = {};
      prodData.data = prodObj;
      relationshipObj.product = prodData;
    }

    var dataObj = {};
    dataObj.type = "beneficiaries";
    dataObj.id = id;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipObj;

    var payloadObj ={};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestAddBeneficiary() -- payload=" + JSON.stringify(payloadObj))

    // this.props.manageRecipient(payloadObj, id, cookies.get('access'));
    this.props.manageRecipient(payloadObj, id, this.props.authReducer.accessToken);
  }

  renderConfirmDetails(getFieldDecorator) {
    if (Object.keys(this.state.receivingCountry).length > 0 && Object.keys(this.state.beneficiarySettings).length > 0) {
      return (
        <FormItem>
          {getFieldDecorator("confirm_details", {
            rules: [{
              required: true, transform: value => (value || undefined),  // Those two lines
              type: 'boolean', message: "Please confirm if the above details are correct" }]
            })(
              <Checkbox>
              <b>I hereby confirm that the information provided herein is accurate</b>
            </Checkbox>
          )}
        </FormItem>
      )
    } else {
      return (<div/>)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    var action = "Select payment";
    var disabledButton = false;

    Log.d(TAG, "render() -- recipient=" + JSON.stringify(this.props.recipientReducer.recipient))
    Log.d(TAG, "render() -- state=" + JSON.stringify(this.state))

    if (Object.keys(this.props.calculationReducer.calculation).length <= 0) {
      if (Object.keys(this.props.recipientReducer.recipient).length > 0) {
        action = "Submit Recipient"
      } else {
        action = "Add Recipient"
      }
    }

    if (Object.keys(this.state.beneficiarySettings).length <= 0) {
      action = "No Fields Loaded"
      disabledButton = true;
    }
    return (
      <div class = "ru-box">
        <Form
          className="ar-body"
          onSubmit={this.handleSubmit}>
          <h2 className="ar-add-recipient">Manage Recipient</h2>
          <hr></hr>
          <div className = "row">
            <div className = "col-12">
            {this.renderReceivingCountryDropDown(getFieldDecorator)}
            </div>
          </div>

          {this.renderServicesArea(getFieldDecorator)}
          {this.renderBeneficiarySettings(getFieldDecorator)}
          {this.renderConfirmDetails(getFieldDecorator)}
          <div style={{ width: '100%', padding: '20px', textAlign: 'left'}}>
            <b style={{width: '100%', color: 'red'}}>Fields with * are required.</b>
          </div>
          <FormItem style={{ 'display': 'flex', 'justifyContent' : 'center' }}>
            <Button className="home-collect"
              style={{
                backgroundColor: '#154989',
                boxSizing: 'border-box',
                color: 'white',
                marginBottom: '8px',
                width: '100%',
                marginLeft: '8px'
              }} type="primary" htmlType="submit" disabled={disabledButton}>{action}</Button>
          </FormItem>
        </Form>
      </div>
    );
  }

  renderReceivingCountryDropDown(getFieldDecorator) {
    Log.d(TAG, "renderReceivingCountryDropDown() -- r=" + JSON.stringify(this.props.recipientReducer.recipient));
    Log.d(TAG, "renderReceivingCountryDropDown() -- p=" + JSON.stringify(this.props.productServiceReducer.product));
    if (Object.keys(this.props.recipientReducer.recipient).length > 0 || Object.keys(this.props.calculationReducer.calculation).length > 0) {
      return (<div/>)
    } else {
      return (
        <FormItem label="Recipient's country of residence">
          {getFieldDecorator('receivingCountry', {
              rules: [{ required: true, message: 'Please provide your recipient\'s country' }],
              initialValue : this.state.receivingCountry
            })(
              <Dropdown
                trigger={['click']}
                overlay={this.renderCountriesParent()}>
                <div className="dropdown-item">
                  {this.renderReceivingCountry()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
      )
    }
  }

  assignPayloadValue(beneficiarySetting, currentVal) {
    Log.d(TAG, "assignPayloadValue() -- bs w/o options=" + beneficiarySetting.mainKey + " currentVal=" + currentVal);
    if (currentVal === undefined) {
      if(beneficiarySetting.allow_null) {
        Log.d(TAG, "assignPayloadValue() -- bs w/o options=" + beneficiarySetting.mainKey + " set null");
        currentVal = null
      } else {
        currentVal = ""
      }
    }
    return currentVal;
  }

  renderReceivingCountry() {
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return (
        <div>
          {this.state.receivingCountry.attributes.name}
        </div>
        )
    } else {
      return (
        <div/>
      )
    }
  }

  renderCountriesParent(){
    return (
        <Menu onClick={this.onChangeCountry}>
          {this.renderCountries()}
        </Menu>
      )
  }

  onChangeCountry = (event) => {
    var currentState = this.state;
    Object.keys(currentState).forEach((key) => {
      if (key !== "access" && key !== "sendingCurrency" && key !== "receivingCurrency"
        && key !== "sendingAmount" && key !== "receivingAmount" && key !== "receivingCountries"
        && key !== "sendingCountry" && key !== "receivingCountry" && key !== "beneficiarySettings"
        && key !== "recipient") {
        delete currentState[key];
      }
    })
    currentState.receivingCountry = {};
    this.setState(currentState);

    Log.d(TAG, "onChangeCountry() -- current state=" + JSON.stringify(this.state));
    this.props.form.setFieldsValue({
      receivingCountry: {},
    })

    this.props.form.setFieldsValue({
      service: 0,
    })

    this.setState({
      service: 0
    })
    this.requestCountryDetails(this.state.receivingCountries[event.key].id)
  }

  renderCountries(){
    return this.state.receivingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  sortBeneficiarySettings(beneficiarySettings) {
    beneficiarySettings.sort((a, b) => a.position - b.position);
    return beneficiarySettings;
  }

  renderBeneficiarySettings(getFieldDecorator) {
    Log.d(TAG, "renderBeneficiarySettings() -- beneficiarySettings=" + JSON.stringify(this.state.beneficiarySettings));
    if (Object.keys(this.state.receivingCountry).length > 0 && Object.keys(this.state.beneficiarySettings).length > 0) {
      var bs = this.sortBeneficiarySettings(this.state.beneficiarySettings)
      return bs.map((beneficiarySetting, index) => {
        if (beneficiarySetting.hasOwnProperty("options")) {
          return ( this.renderBSDropDown(beneficiarySetting, getFieldDecorator) )
        } else {
          if (Helper.containsPhoneNumbers(beneficiarySetting.mainKey)) {
            return ( this.renderBSPhoneInput(beneficiarySetting, getFieldDecorator) )
          } else if (beneficiarySetting.mainKey === "birth_date"){
            return ( this.renderDatePicker(moment().subtract(18, 'years'), beneficiarySetting, getFieldDecorator) )
          } else {
            return ( this.renderBSInput(beneficiarySetting, getFieldDecorator) )
          }
        }
      });
    } else {
      return <div/>
    }
  }

  renderBSPhoneInput(beneficiarySetting, getFieldDecorator) {
    var phonePrefix = getFieldDecorator('phonePrefix')(
      this.renderPhonePrefix(beneficiarySetting, this.state[beneficiarySetting.mainKey])
    );

    Log.d(TAG, "renderBSPhoneInput() -- phonePrefix=" + this.state[beneficiarySetting.mainKey] + " main key=" + beneficiarySetting.mainKey);
    var phoneNumber = ""
    if (this.state[beneficiarySetting.mainKey]) {
      if ((typeof this.state[beneficiarySetting.mainKey]) === "object") {
        if (Object.keys(this.state[beneficiarySetting.mainKey]).length > 1) {
          phoneNumber = this.state[beneficiarySetting.mainKey][1]
        }
      }
    }

    var label = beneficiarySetting.label;

    /*if (beneficiarySetting.mainKey === "mobile_money_msisdn") {
      label = "Mobile Number"
    }*/

    Log.d(TAG, "renderBSPhoneInput() -- phoneNumber=" + phoneNumber);
    return (

      <FormItem label={label} key={beneficiarySetting.mainKey}>
        {getFieldDecorator(beneficiarySetting.mainKey, {
          rules: [{ validator: this.validateBSField }],
          initialValue: phoneNumber
        })(
          <Input addonBefore={phonePrefix} className="mobile-number" maxlength="20"/>
        )}
      </FormItem>

    )
  }

  renderPhonePrefix(beneficiarySetting, value) {
    Log.d(TAG, "renderPhonePrefix() -- main key=" + beneficiarySetting.mainKey);
    Log.d(TAG, "renderPhonePrefix() -- value=" + JSON.stringify(value));
    Log.d(TAG, "renderPhonePrefix() -- mmp=" + JSON.stringify(this.props.mmpReducer.mmp));
    var phonePrefix = this.state.receivingCountry.attributes.phone_prefix;
    if (beneficiarySetting.mainKey === "mobile_money_msisdn" && Object.keys(this.props.mmpReducer.mmp).length > 0) {
      phonePrefix = this.props.mmpReducer.mmp.data.attributes.phone_prefix ? this.props.mmpReducer.mmp.data.attributes.phone_prefix : phonePrefix
    }
    Log.d(TAG, "renderPhonePrefix() -- phonePrefix=" + phonePrefix);
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return (
        <div style={{ width: 45 }}>
          +{phonePrefix}
        </div>
      )
    } else {
      return (
        <div/>
      )
    }
  }

  renderBSInput(beneficiarySetting, getFieldDecorator) {
    var label = beneficiarySetting.label;
    Log.d(TAG, "renderBSInput() -- bs=" + JSON.stringify(beneficiarySetting))
    if (beneficiarySetting.mainKey === "nickname") {
      label=(
        <span>
          {beneficiarySetting.label}
          <Tooltip title="The nickname is to help you to identify and link your recipients to a Cash Pick-Up or FCA Wallet. For example: Cash-Tatenda or Wallet-Tatenda">
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
      )
    }

    if (beneficiarySetting.help_text) {
      label=(
        <span>
          {beneficiarySetting.label}
          <Tooltip title={beneficiarySetting.help_text}>
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
      )
    }

    return (

      <FormItem label={label} key={beneficiarySetting.mainKey}>
        {getFieldDecorator(beneficiarySetting.mainKey, {
          rules: [{ validator: this.validateBSField },
          { max: (beneficiarySetting.mainKey === "account_number") && 30, message:"Account Number can't be longer than 30 numbers" },
          { type: (beneficiarySetting.mainKey === "email") ? "email" : "string", message: (beneficiarySetting.mainKey === "email") ? "Please provide a valid e-mail address" : "" }],
          initialValue : this.state[beneficiarySetting.mainKey]
        })(
          <Input className = "form-control" disabled={ beneficiarySetting.mainKey === "swift_code" && !!this.state.bank } />
        )}
      </FormItem>

    )
  }

  renderDatePicker(maxBirthDate, beneficiarySetting, getFieldDecorator) {
    Log.d(TAG, "renderDatePicker() -- birth date=" + this.state[beneficiarySetting.mainKey] + " main key=" + beneficiarySetting.mainKey);
    var datePicked = moment().subtract(18, 'years')
    if (!Helper.isEmpty(this.state[beneficiarySetting.mainKey])) {
      Log.d(TAG, "renderDatePicker() -- date is not empty");
      datePicked = moment(this.state[beneficiarySetting.mainKey])
    }

    Log.d(TAG, "renderDatePicker() -- datePicked=" + datePicked);

    return (
      <FormItem label={beneficiarySetting.label} key={beneficiarySetting.mainKey}>
        {getFieldDecorator(beneficiarySetting.mainKey, {
          rules: [{ validator: this.validateBSField }],
          initialValue: datePicked
        })(
          <DatePicker
            allowClear={false}
            className="su-tf-2"
            format={'YYYY-MM-DD'}
            disabledDate={this.disabledDate}
            onChange={this.onChangeBirthDate} />
        )}
      </FormItem>
    )
  }

  onChangeBirthDate = (date, dateString) => {
    this.props.form.setFieldsValue({
      "birth_date": date
    })
    this.setState({
      "birth_date" : date
    })
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current + " maxBirthDate=" + moment().subtract(18, 'years'));
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > moment().subtract(18, 'years').endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

  validateBSField = (rule, value, callback) => {
    Log.d(TAG, "validateBSField() -- rule=" + JSON.stringify(rule) + " current value=" + JSON.stringify(value));
    const form = this.props.form;
    // if (rule.field === "bank" || rule.field === "bank_name" || rule.field === "branch" || rule.field === "branch_name") {
    if (rule.field === "bank_name" || rule.field === "branch_name") {
      callback()
    } else {
      Helper.validateBSField(form, this.state.beneficiarySettings, rule, value, this.state[rule.field], callback);
    }
  }

  findCorrectValue(id, mainKey) {
    Log.d(TAG, "findCorrectValue() -- called ..")
    var correctBS = Helper.findBDByMainKey(this.state.beneficiarySettings, mainKey);
    if (correctBS.hasOwnProperty("options")) {
      for (var key in correctBS.options) {
        var dataKey = JSON.parse(key);
        if (id === dataKey.id) {
          Log.d(TAG, "findCorrectValue() -- correct value here=" + correctBS.options[key] + " at main key=" + mainKey + " with ID=" + id)
          return correctBS.options[key];
        }
      }
    }
    return "";
  }

  renderBSDropDown(beneficiarySetting, getFieldDecorator) {
    var currentKeyValue = {}
    if (Object.keys(beneficiarySetting.options).length > 0 && this.state.hasOwnProperty(beneficiarySetting.mainKey) === false) {
      var currentKey = Object.keys(beneficiarySetting.options)[0]
      var currentValue = beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]]
      currentKeyValue.key = currentKey
      currentKeyValue.value = currentValue
    }

    if (this.state.hasOwnProperty(beneficiarySetting.mainKey) === true) {
      currentKeyValue = this.state[beneficiarySetting.mainKey]
    }

    if (beneficiarySetting.mainKey === "cash_pickup_location")  {
      return (
        <FormItem key={beneficiarySetting.mainKey}>
          {getFieldDecorator(beneficiarySetting.mainKey, {
              rules: [{ validator: this.validateBSField }],
              initialValue: currentKeyValue
            })(
              <span/>
            )}
        </FormItem>
      )
    } else if (beneficiarySetting.mainKey === "relationship")  {

      return (
        <FormItem label={beneficiarySetting.label} key={beneficiarySetting.mainKey}>
          {getFieldDecorator(beneficiarySetting.mainKey, {
              rules: [{ validator: this.validateBSField }],
              initialValue: currentKeyValue
            })(
              <Dropdown
                trigger={['click']}
                overlay={this.renderOptionsParent(beneficiarySetting)}
                disabled={Object.keys(this.props.recipientReducer.recipient).length > 0 && this.props.recipientReducer.recipient.data.attributes.relationship !== null}>
                <div className="dropdown-item">
                  {this.renderFirstOption(beneficiarySetting)}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
      )
    } else {
      return (
        <FormItem label={beneficiarySetting.label} key={beneficiarySetting.mainKey}>
          {getFieldDecorator(beneficiarySetting.mainKey, {
              rules: [{ validator: this.validateBSField }],
              initialValue: currentKeyValue
            })(
              <Dropdown
                trigger={['click']}
                overlay={this.renderOptionsParent(beneficiarySetting)}>
                <div className="dropdown-item">
                  {this.renderFirstOption(beneficiarySetting)}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
      )
    }
  }

  requestBranchesByBankID(beneficiarySetting, stateId, districtId, cityId, currentKey) {
    Log.d(TAG, "requestBranchesByBankID() -- called ..");
    Log.d(TAG, "requestBranchesByBankID() -- current key=" + currentKey);
    var currentKeyObj = JSON.parse(currentKey);
    if (currentKeyObj.id === "0") {
      Log.d(TAG, "requestBranchesByBankID() -- reset branches");
      this.resetBranches();
    } else {
      var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch")
      if (beneficiarySetting.mainKey === "bank" && Object.keys(branchBS).length > 0) {
        if (Object.keys(branchBS.options).length <= 1) {
          Log.d(TAG, "requestBranchesByBankID() -- request branches");
          Log.d(TAG, "requestBranchesByBankID() -- currentKey=" + JSON.stringify(currentKeyObj));
          // this.props.requestBranchesByBank(currentKeyObj.id, cookies.get('access'))
          this.props.requestBranchesByBank(currentKeyObj.id, stateId, districtId, cityId, this.props.authReducer.accessToken)

        }
      }
    }
  }

  requestCashPickupLocationByCashPickupProviderID(beneficiarySetting, currentKey) {
    Log.d(TAG, "requestCashPickupLocationByCashPickupProviderID() -- called ..");
    var currentKeyObj = JSON.parse(currentKey);
    /*if (currentKeyObj.id === "0") {
      this.resetCpls();
    } else {*/
      var cplBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "cash_pickup_location")
      if (beneficiarySetting.mainKey === "cash_pickup_provider" && Object.keys(cplBS).length > 0) {
        if (Object.keys(cplBS.options).length <= 1) {
          Log.d(TAG, "requestCashPickupLocationByCashPickupProviderID() -- currentKey=" + JSON.stringify(currentKeyObj));

          // this.props.requestCplsByCpp(currentKeyObj.id, cookies.get('access'));
          this.props.requestCplsByCpp(currentKeyObj.id, this.props.authReducer.accessToken);
        }
      }
    //}
  }

  renderFirstOption(beneficiarySetting) {
    var mainKey = beneficiarySetting.mainKey
    if (this.state.hasOwnProperty(mainKey)) {
      var key = this.state[mainKey].key;
      var v1 = beneficiarySetting.options[key];

      if (beneficiarySetting.mainKey === "passport_country" ) {
        for (var pkey in beneficiarySetting.options) {
          if (JSON.parse(pkey).id === JSON.parse(key).id) {
            v1 = beneficiarySetting.options[pkey];
          }
        }
      }
      // HOW TO TARGET A DROPDOWN FIELD
      // how to target relationship field
      // Log.d(beneficiarySetting.mainKey === "relationship");
      // how to obtain inital state of relationship value
      // Log.d(this.state.recipient.data.attributes.relationship)
      // how to obtain default first value (message prompting user to make a selection)
      // Log.d(beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]])
      // how to obtain the presentation name (key) for the value mentioned above
      // Log.d(beneficiarySetting.options[this.state.recipient.data.attributes.relationship])
      
      var value =  this.state[mainKey].value;
      
      if(beneficiarySetting.mainKey === "relationship" && value === undefined) {
        value = beneficiarySetting.options[this.state.recipient.data.attributes.relationship]
      }

      if (Helper.isEmpty(value)) {
        value = v1;
        Log.d(TAG, "renderFirstOption() -- v1=" + v1)
      }

      if (value === undefined) {
        value =  beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]];
      }

      Log.d(TAG, "renderFirstOption() -- value=" + value + " at beneficiary setting=" + beneficiarySetting.mainKey)
      return (
        <div>
          {value}
        </div>
      )
    } else {
      if (Object.keys(beneficiarySetting.options).length > 0) {
        Log.d(TAG, "renderFirstOption() -- main key=" + beneficiarySetting.mainKey + " first value=" + beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]])
        return (
          <div>
            {beneficiarySetting.options[Object.keys(beneficiarySetting.options)[0]]}
          </div>
        )
      } else {
        Log.d(TAG, "renderFirstOption() -- main key=" + beneficiarySetting.mainKey + " no value")
        return (
          <div/>
        )
      }
    }
  }

  renderOptionsParent(beneficiarySetting){
    let moreOptions = Object.keys(beneficiarySetting.options).length > 8

    return (
      <Menu style={{height: moreOptions ? "250px" : "", overflow: "scroll"}} onClick={(event) => this.onChangeOptions(event, beneficiarySetting)}>
        {this.renderOptions(beneficiarySetting)}
      </Menu>
    )
  }

  renderOptions(beneficiarySetting){
    var options = [];
    Object.keys(beneficiarySetting.options).forEach((mainKey) => {
      options.push(beneficiarySetting.options[mainKey])
    });
    Log.d(TAG, "renderOptions() -- options size=" +  Object.keys(options).length + " at main key=" + beneficiarySetting.mainKey);
    return options.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
        );
    })
  }
  
  // use this object as a store for current key value pair of form entries
  currentValueObject = {}

  onChangeOptions(event, beneficiarySetting){
    Log.d(TAG, "onChangeOptions() -- beneficiarySetting options=" + JSON.stringify(beneficiarySetting.options) + " event key=" + event.key);
    Log.d(TAG, "onChangeOptions() -- beneficiarySetting=" + JSON.stringify(beneficiarySetting));

    var currentKey = Object.keys(beneficiarySetting.options)[event.key]
    var currentValue = beneficiarySetting.options[Object.keys(beneficiarySetting.options)[event.key]]

    Log.d(TAG, "onChangeOptions() -- currentKey=" +  currentKey);
    Log.d(TAG, "onChangeOptions() -- currentValue=" +  currentValue);

    var currentKeyValue = {}

    currentKeyValue.key = currentKey
    currentKeyValue.value = currentValue

    Log.d(TAG, "onChangeOptions() -- currentKeyValue=" +  JSON.stringify(currentKeyValue));

    var mainKey = beneficiarySetting.mainKey

    this.props.form.setFieldsValue({
      [mainKey]: currentKeyValue
    })

    this.setState({
      [mainKey] : currentKeyValue
    })

    // store changes on form entries
    this.currentValueObject[mainKey] = currentKeyValue;

    // For prepopulating Swift Code Field
    if(beneficiarySetting.mainKey === "bank" && !!beneficiarySetting.prepopulate) {
      if(!!beneficiarySetting.prepopulate.swift_code) {
        this.setState({
          "swift_code": beneficiarySetting.prepopulate.swift_code[Object.keys(beneficiarySetting.options)[event.key]]
        })
        this.props.form.setFieldsValue({
          "swift_code": beneficiarySetting.prepopulate.swift_code[Object.keys(beneficiarySetting.options)[event.key]]
        })
      }
    }

    if(beneficiarySetting.mainKey === "branch_district" && !!this.state.bank) {
      let currentKey = !!this.currentValueObject.bank ? Object.values(this.currentValueObject.bank) : Object.values(this.state.bank)
      this.resetBranches();
      this.resetBranchCities();
      let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "bank")
      this.requestBranchesByBankIdStateIdDistrictIdCityId(beneficiarySetting, currentKey[0])
    }

    if(beneficiarySetting.mainKey === "branch_city") {
      this.resetBranches();
      
      let currentKey = !!this.currentValueObject.bank ? Object.values(this.currentValueObject.bank) : Object.values(this.state.bank)
      let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "bank")
      this.requestBranchesByBankIdStateIdDistrictIdCityId(beneficiarySetting, currentKey[0])
    }

    if(beneficiarySetting.mainKey === "branch_state") {
      let bankId = !!this.currentValueObject.bank ? JSON.parse(this.currentValueObject["bank"].key).id : JSON.parse(Object.values(currentKeyValue)[0]).id 
      let countryId = JSON.parse(this.state["country"].key).id
      let stateId = this.currentValueObject["branch_state"] && JSON.parse(this.currentValueObject["branch_state"].key).id
      this.resetBranchDistricts();
      this.resetBranchCities();
      this.resetBranches();

      this.fetchDistricts(bankId, countryId, stateId);
    }
  
    if(beneficiarySetting.mainKey === "bank") {
      let bankId = !!this.currentValueObject.bank ? JSON.parse(this.currentValueObject["bank"].key).id : JSON.parse(this.state["bank"].key).id
      let stateId = this.currentValueObject["branch_state"] && JSON.parse(this.currentValueObject["branch_state"].key).id
      let countryId = JSON.parse(this.state["country"].key).id
      this.resetBranchStates();
      this.resetBranchDistricts();
      this.resetBranchCities();
      this.resetBranches();

      this.fetchStates(bankId, countryId);
      this.fetchDistricts(bankId, countryId, stateId);
    }

      let hasCities = this.state.beneficiarySettings.some(beneficiarySetting => beneficiarySetting.mainKey === 'branch_state')

      if(beneficiarySetting.mainKey === "branch_district" && hasCities) {
        let bankId = this.currentValueObject["bank"] ? JSON.parse(this.currentValueObject["bank"].key).id : JSON.parse(this.state["bank"].key).id
        let stateId = this.currentValueObject["branch_state"] ? JSON.parse(this.currentValueObject["branch_state"].key).id : JSON.parse(this.state["branch_state"].key).id
        let districtId = this.currentValueObject["branch_district"] ? JSON.parse(this.currentValueObject["branch_district"].key).id : JSON.parse(this.state["branch_district"].key).id
        let countryId = JSON.parse(this.state["country"].key).id

        this.fetchCities(bankId, countryId, stateId, districtId);
      }
  
    var hasBranch = Object.keys(Helper.findBDByMainKey(this.state.beneficiarySettings, "branch")).length > 0;
    Log.d(TAG, "onChangeOptions() -- hasBranch=" + hasBranch);

    // if (beneficiarySetting.mainKey === "bank" && hasBranch && cookies.get('access')) {
    if (beneficiarySetting.mainKey === "bank" && hasBranch && this.props.authReducer.accessToken) {
      Log.d(TAG, "onChangeOptions() -- main key=" + beneficiarySetting.mainKey);
      this.resetBranchDistricts();
      this.resetBranchCities();
      this.resetBranches();
      
      if(!!this.state.bank) {
        this.requestBranchesByBankIdStateIdDistrictIdCityId(beneficiarySetting, currentKey);
      }
      this.requestBranchesByBankIdStateIdDistrictIdCityId(beneficiarySetting, currentKey);
    }

    var hasCpl = Object.keys(Helper.findBDByMainKey(this.state.beneficiarySettings, "cash_pickup_location")).length > 0;
    Log.d(TAG, "onChangeOptions() -- hasCpl=" + hasCpl);
    // if (beneficiarySetting.mainKey === "cash_pickup_provider" && hasCpl && cookies.get('access')) {
    if (beneficiarySetting.mainKey === "cash_pickup_provider" && hasCpl && this.props.authReducer.accessToken) {
      Log.d(TAG, "onChangeOptions() -- main key=" + beneficiarySetting.mainKey);
      this.resetCpls()
      this.requestCashPickupLocationByCashPickupProviderID(beneficiarySetting, currentKey);
    }

    var hasMMMsisdn = Object.keys(Helper.findBDByMainKey(this.state.beneficiarySettings, "mobile_money_msisdn")).length > 0;
    Log.d(TAG, "onChangeOptions() -- hasMMMsisdn=" + hasMMMsisdn);
    // if (beneficiarySetting.mainKey === "mobile_money_provider" && hasMMMsisdn && cookies.get('access') && JSON.parse(currentKey).id !== 0) {
    if (beneficiarySetting.mainKey === "mobile_money_provider" && hasMMMsisdn && this.props.authReducer.accessToken && JSON.parse(currentKey).id !== 0) {
      this.resetMobileMoneyMSISDN()
      this.requestMMPByID(currentKey);
    }

    if (beneficiarySetting.mainKey === "recipient_type") {
      // if (Object.keys(beneficiarySetting.options).length > 0 && cookies.get('access')) {
      if (Object.keys(beneficiarySetting.options).length > 0 && !!this.props.authReducer.accessToken) {
        this.layoutByBeneficiarySettings(this.state.receivingCountry, Object.keys(beneficiarySetting.options)[event.key] === "E")
      }
    }

    Log.d(TAG, "onChangeOptions() -- current state=" + JSON.stringify(this.state) + " at main key=" + mainKey);
  }

  requestBranchesByBankIdStateIdDistrictIdCityId(beneficiarySetting, currentKey) {
    let stateId = undefined;
    let districtId = undefined;
    let cityId = undefined;
    if(this.state.beneficiarySettings.some(beneficiarySetting => beneficiarySetting.mainKey === 'branch_state')) {
      if(!!this.currentValueObject.branch_district){
        if(!!this.currentValueObject.branch_city) {
          stateId =
           !!this.currentValueObject["branch_state"] ? JSON.parse(this.currentValueObject["branch_state"].key).id : JSON.parse(this.state["branch_state"].key).id
          districtId = !!this.currentValueObject["branch_district"].key.branch_district ? JSON.parse(this.currentValueObject["branch_district"].key).id : JSON.parse(this.state["branch_district"].key).id
          cityId =
           !!this.currentValueObject.branch_city ? JSON.parse(this.currentValueObject["branch_city"].key).id : JSON.parse(this.state["branch_city"].key).id
          // PARAMETERS: // beneficiarySetting, stateId, districtId, cityId, currentKey
          this.requestBranchesByBankID(beneficiarySetting, stateId, districtId, cityId, currentKey)
        }
      }
    } else if(!!this.currentValueObject.branch_district) {
      districtId = JSON.parse(this.currentValueObject["branch_district"].key).id
      // PARAMETERS: // beneficiarySetting, stateId, districtId, cityId, currentKey
      this.requestBranchesByBankID(beneficiarySetting, stateId, districtId, cityId, currentKey);
    } else {
      // PARAMETERS: // beneficiarySetting, stateId, districtId, cityId, currentKey
      this.requestBranchesByBankID(beneficiarySetting, stateId, districtId, cityId, currentKey);
    }
  }

  fetchCities(bankId, countryId, stateId, districtId) {
    fetch(`${endpointConfig.api_url}banks/${bankId}/cities/?filter[country]=${countryId}&filter[state]=${stateId}&filter[district]=${districtId}&include=country,state,district`)
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`)
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(json => {
        
        let branchStateOptionsObject = {"{\"type\" : \"branch_city\", \"id\" : \"0\"}":"Select a Branch city"};
        json.data.forEach(x => {
          branchStateOptionsObject[`{ "type": "${x.type}", "id": ${x.id} }`] = x.attributes.name
        })

        let populatedBranchCityOptions = branchStateOptionsObject;
        
        let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_city")
        beneficiarySetting.options = populatedBranchCityOptions;
        this.replaceBS("branch_city", beneficiarySetting)
      })
      .catch(error => {
        if(error.name !== AUTH_ERROR) {
          Log.i(`Error occurred: ${error}`);
        } else {
          window.location.reload();
        }
      });
  }

  fetchStates(bankId, countryId){
    fetch(`${endpointConfig.api_url}banks/${bankId}/states/?filter[country]=${countryId}&include=country`)
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`)
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(json => {
        
        let branchStateOptionsObject = {"{\"type\" : \"branch_district\", \"id\" : \"0\"}":"Select a Branch state"};
        json.data.forEach(x => {
          branchStateOptionsObject[`{ "type": "${x.type}", "id": ${x.id} }`] = x.attributes.name
        })

        let populatedBranchStateOptions = branchStateOptionsObject;
        
        let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_state")
        beneficiarySetting.options = populatedBranchStateOptions;
        this.replaceBS("branch_state", beneficiarySetting)
      })
      .catch(error => {
        if(error.name !== AUTH_ERROR) {
          Log.i(`Error occurred: ${error}`);
        } else {
          window.location.reload();
        }
      });
  }

  fetchDistricts(bankId, countryId, stateId) {
    let hasBranchState = this.state.beneficiarySettings.some(beneficiarySetting => beneficiarySetting.mainKey === 'branch_state');
    let fullEndpoint = '';

    if (hasBranchState && !!stateId) {
      fullEndpoint = 
      `${endpointConfig.api_url}banks/${bankId}/districts/?filter[country]=${countryId}&filter[state]=${stateId}&include=country,state`
    } else {
      fullEndpoint = `${endpointConfig.api_url}banks/${bankId}/districts/?filter[country]=${countryId}&include=country,state`
    }
    
    fetch(fullEndpoint)
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`)
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
          return results.json();
        }
      })
      .then(json => {
        
      let branctDistrictOptionsObject = {"{\"type\" : \"branch_district\", \"id\" : \"0\"}":"Select a Branch District"};
      json.data.forEach(x => {
        branctDistrictOptionsObject[`{ "type": "${x.type}", "id": ${x.id} }`] = x.attributes.name
      })

      let populatedDistrictOptions = branctDistrictOptionsObject;
      
      let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_district")
      beneficiarySetting.options = populatedDistrictOptions;
      this.replaceBS("branch_district", beneficiarySetting)
    })
    .catch(error => {
      if(error.name !== AUTH_ERROR) {
        Log.i(`Error occurred: ${error}`);
      } else {
        window.location.reload();
      }
    });
  }

  fetchBranches(bankId, stateId, districtId, cityId) {
    let fullEndpoint = `${endpointConfig.api_url}banks/${bankId}/branches/`;

    if (stateId !== undefined) {
      fullEndpoint = 
      `${endpointConfig.api_url}banks/${bankId}/branches/?filter[state]=${stateId}&filter[district]=${districtId}&filter[city]=${cityId}`
    } else {
      fullEndpoint = `${endpointConfig.api_url}banks/${bankId}/branches/?filter[district]=${districtId}`
    }
    
    fetch(fullEndpoint)
      .then(results => {
        const authError = results.status === 401 || results.status === 403;
        const serverError = results.status === 500;

        if (serverError) {
          Log.i(`Server error occurred: ${results}`)
          xhr.serverErrorHandler();
        } else if (authError) {
          Log.i(`Auth error occurred: ${results}`);
          throw new AuthError();
        } else {
          return results.json();
        }
      })
      .then(json => {
        
      let branchOptionsObject = {"{\"type\" : \"branch\", \"id\" : \"0\"}":"Select a Branch"};
      json.data.forEach(x => {
        branchOptionsObject[`{ "type": "${x.type}", "id": ${x.id} }`] = x.attributes.name
      })

      let populatedDistrictOptions = branchOptionsObject;
      let beneficiarySetting = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch")
      beneficiarySetting.options = populatedDistrictOptions;
      this.replaceBS("branch", beneficiarySetting)
    })
    .catch(error => {
      if(error.name !== AUTH_ERROR) {
        Log.i(`Error occurred: ${error}`);
      } else {
        window.location.reload();
      }
    });
  }


  resetMobileMoneyMSISDN() {
    this.setState({
      "mobile_money_msisdn" : ""
    })
    this.props.form.setFieldsValue({
      "mobile_money_msisdn": ""
    })
  }

  requestMMPByID(currentKey) {
    var currentKeyObj = JSON.parse(currentKey);
    Log.d(TAG, "requestMMPByID() -- id=" + currentKeyObj.id);
    // this.props.requestCurrentMMPDetails(currentKeyObj.id, cookies.get('access'));
    this.props.requestCurrentMMPDetails(currentKeyObj.id, this.props.authReducer.accessToken);
  }

  replaceBS(mainKey, newItem) {
    var currentBS = this.state.beneficiarySettings;
    for (var i = 0; i < currentBS.length; i++) {
      var bs = this.state.beneficiarySettings[i]
      if (bs.mainKey === mainKey) {
        bs = newItem
      }
    }
    this.setState({ beneficiarySettings : currentBS })
  }

  resetBranches() {
    var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch")

    var key = "{\"type\" : \"branches\", \"id\" : \"0\"}";
    var value = "Select a Branch"

    branchBS.options = { key : value };

    var currentKeyValue = {}

    currentKeyValue.key = key
    currentKeyValue.value = value

    this.setState({
      "branch" : currentKeyValue
    })
    this.props.form.setFieldsValue({
      "branch": currentKeyValue
    })
    this.replaceBS("branch", branchBS)
  }

  resetBranchStates() {
    var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_state")

    var key = "{\"type\" : \"states\", \"id\" : \"0\"}";
    var value = "Select a Branch state"

    branchBS.options = { key : value };

    var currentKeyValue = {}

    currentKeyValue.key = key
    currentKeyValue.value = value

    this.setState({
      "branch_state" : currentKeyValue
    })
    this.props.form.setFieldsValue({
      "branch_state": currentKeyValue
    })
    this.replaceBS("branch_state", branchBS)
  }

  resetBranchDistricts() {
    var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_district")

    var key = "{\"type\" : \"districts\", \"id\" : \"0\"}";
    var value = "Select a Branch district"

    branchBS.options = { key : value };

    var currentKeyValue = {}

    currentKeyValue.key = key
    currentKeyValue.value = value

    this.setState({
      "branch_district" : currentKeyValue
    })
    this.props.form.setFieldsValue({
      "branch_district": currentKeyValue
    })
    this.replaceBS("branch_district", branchBS)
  }

  resetBranchCities() {
    var branchBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "branch_city")

    var key = "{\"type\" : \"cities\", \"id\" : \"0\"}";
    var value = "Select a Branch city"

    branchBS.options = { key : value };

    var currentKeyValue = {}

    currentKeyValue.key = key
    currentKeyValue.value = value

    this.setState({
      "branch_city" : currentKeyValue
    })
    this.props.form.setFieldsValue({
      "branch_city": currentKeyValue
    })
    this.replaceBS("branch_city", branchBS)
  }

  resetCpls() {
    var cplBS = Helper.findBDByMainKey(this.state.beneficiarySettings, "cash_pickup_location")

    var key = "{\"type\" : \"cash_pickup_locations\", \"id\" : \"0\"}";
    var value = "Select a Cash Pickup Location"

    cplBS.options = { key : value };

    var currentKeyValue = {}

    currentKeyValue.key = key
    currentKeyValue.value = value

    this.setState({
      "cash_pickup_location" : currentKeyValue
    })
    this.props.form.setFieldsValue({
      "cash_pickup_location": currentKeyValue
    })

    this.replaceBS("cash_pickup_location", cplBS)
  }
}

const WrappedManageRecipientForm = Form.create()(ManageRecipientForm);
function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestReceivingCountries,
    fetchUserDetails,
    requestRecipientByID,
    requestReceivingCountry,
    requestProductDetails,
    manageRecipient,
    requestBranchesByBank,
    requestCplsByCpp,
    requestProductsAndServices,
    requestCurrentMMPDetails,
    showCurrentMMP,
    showProductsServices
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageRecipient)
