import React from 'react';
import { Layout } from 'antd';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import SmartTransactionDetails from '../../containers/TransactionDetails.js'
import SmartLogin from '../../containers/Login.js'
import Log from '../../utils/log.js';
import { withCookies } from 'react-cookie';
import '../../styles/transaction.css'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Header, Footer } = Layout;
const TAG = "TransactionDetails";

class TransactionDetails extends React.Component {

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    document.title = "Transaction Details"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>           
            <CustomHeader {... this.props}/>
        </Header>
        {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  renderBody() {
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      return (<SmartTransactionDetails {... this.props}/> )
    } else {
      return (<SmartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>)
    }
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(TransactionDetails));

// export default withCookies(TransactionDetails)
