import React from "react";
import Log from "../utils/log.js";
import {
  Input,
  Menu,
  Dropdown,
  Icon,
  DatePicker,
  Form,
  message,
  Layout,
  Steps,
  Button,
  Select,
} from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import Helper from "../utils/helper.js";
import {
  requestSendingCountries,
  requestSignUp,
  requestLogin,
  requestPCAPredictAddressFind,
  requestPCAPredictAddressRetrieve,
  requestCountries,
  setSendingCountry,
  fetchUserDetails,
  requestUpdatePersonalDetails,
} from '../actions';

const { Content } = Layout;
const { Option } = Select;
const FormItem = Form.Item;
const Step = Steps.Step;
const TAG = "SmartSignUp";

const steps = [
  {
    title: "Step 1",
    description: "Basic Information",
  },
  {
    title: "Step 2",
    description: "Upload Documents",
  },
  {
    title: "Step 3",
    description: "Upload Selfie",
  },
];

class PersonalDetails extends React.Component {

  componentDidMount() {
    // const { cookies } = this.props;
    // if (cookies.get('access')) {
    // if (!!this.props.authReducer.accessToken) {
    //   Log.d(TAG, "checkIfHasUser() -- has user");
    //   this.props.history.push({
    //     pathname: "/",
    //   });
    // } else {
    //   Log.d(TAG, "checkIfHasUser() -- has no user");
    //   Helper.clearCookies(cookies);
    // }
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedPersonalDetailsForm {... this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {}
}

class PersonalDetailsForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sendingCountry: 0, // step 1
      baseCountryIsSA: true,
      idNumber: "",
      phone: "",
      whatsapp_mobile: "",
      middle_name: "",
      password: "",
      refCode: "",
      emailAdd: "",
      confirmPassword: "",
      sendPromotions: false,
      sendingCountries: [],
      pType: "password",
      pTypeIcon: "eye",
      cpType: "password",
      cpTypeIcon: "eye",
      genders: [ "Male", "Female" ],
      maxBirthDate : moment().subtract(18, 'years'),
      birthDate: moment().subtract(18, 'years'),
      chosenVerificationType: 0,
      verificationTypes: [
        { "id": "SI", "value": "South African ID" },
        { "id": "FP", "value": "Foreign Passport" },
        { "id": "AP", "value": "Asylum Permit" },
        { "id": "FI", "value": "Foreign ID" }
      ],
      refCode: "",
    };
  }

  current = 0;

  componentDidUpdate(prevProps) {
    if (
      this.props.countryReducer.sendingCountries.length > 0 &&
      prevProps.countryReducer.sendingCountries !==
        this.props.countryReducer.sendingCountries
    ) {
      this.setState({
        sendingCountry: 0,
      });
      this.props.form.setFieldsValue({
        sendingCountry: 0,
      });
    }

    if (
      this.props.countryReducer.countries.length > 0 &&
      prevProps.countryReducer.countries !== this.props.countryReducer.countries
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set countries=" +
          JSON.stringify(this.props.countryReducer.countries)
      );
    }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      if (this.props.userReducer.error.includes("Invalid credentials")) {
        var errorMessage =
          "We found an error verifying your credentials based on your registration details.";
        if (Object.keys(this.props.userReducer.user).length > 0) {
          Log.d(
            TAG,
            "componentDidUpdate() -- error has user=" +
              JSON.stringify(this.props.userReducer.user)
          );
          errorMessage =
            errorMessage +
            " Your account status is " +
            Helper.showUserStatus(
              this.props.userReducer.user.data.attributes.user_status
            ) +
            ".";
        }
        message.error(errorMessage, 5);
      } else {
        message.error("" + this.props.userReducer.error, 5);
      }
    }

    if (
      this.props.userReducer.signUpSuccess !== false &&
      prevProps.userReducer.signUpSuccess !==
        this.props.userReducer.signUpSuccess
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- sign up success=" +
          this.props.userReducer.signUpSuccess
      );
      var payload = Helper.baseCountryIsSA
        ? {
            username:
              this.props.countryReducer.sendingCountries[
                this.state.sendingCountry
              ].attributes.phone_prefix +
              " " +
              this.state.phone.replace(/\s/g, ""),
            password: this.state.password,
          }
        : { username: this.state.emailAdd, password: this.state.password };
      this.requestLogin(payload);
    }

    if (
      this.props.userReducer.success !== null &&
      prevProps.userReducer.success !== this.props.userReducer.success
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set success=" + this.props.userReducer.success
      ); // means sign up is successful

      // if (this.state.current !== 2) {
      //   this.props.history.push({
      //     pathname: "/manage-breadwinner-policy/",
      //     state: {
      //       recipientId: null,
      //       bwBeneficiary: {},
      //       recipient: {},
      //     },
      //   });
      // } else {
      //   this.props.history.push({
      //     pathname: "/update-profile",
      //   });
      // }
    }

    // if (
    //   this.props.userReducer.loginMessage !== null &&
    //   prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage
    // ) {
    //   message.error("" + this.props.userReducer.loginMessage, 5);
    //   const { cookies } = this.props;
    //   Helper.clearCookies(cookies);
    //   // log out as there is an error on this
    //   this.props.history.push({
    //     pathname: "/",
    //     state: { message: this.props.userReducer.loginMessage },
    //   });
    // }
  }

  componentDidMount() {
    this.requestSendingCountry();
    this.requestCountries();

    if(this.props.referenceCodeReducer.referenceCode) {
      this.setState({ refCode: this.props.referenceCodeReducer.referenceCode });
    }

    this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, null);
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    this.props.requestSendingCountries();
  }

  requestCountries() {
    Log.d(TAG, "requestCountries() -- called ..");
    this.props.requestCountries();
  }


  renderPhonePrefix() {
    Log.d(
      TAG,
      "renderPhonePrefix() -- countries=" +
        JSON.stringify(this.props.countryReducer.sendingCountries)
    );
    Log.d(
      TAG,
      "renderPhonePrefix() -- current country index=" +
        JSON.stringify(this.state.sendingCountry)
    );
    if (
      this.props.countryReducer.sendingCountries.length > 0 &&
      this.props.countryReducer.sendingCountries[this.state.sendingCountry]
    ) {
      Log.d(
        TAG,
        "renderPhonePrefix() -- current country=" +
          JSON.stringify(
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ]
          )
      );
      return (
        <div style={{ width: 45 }}>
          +
          {
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ].attributes.phone_prefix
          }
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderCountriesParent(){
    return (
      <Menu 
        style={{maxHeight: "30vh", overflow: "scroll"}}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    )
  }

  renderCountries(){
    return this.props.countryReducer.sendingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  renderCountryName() {
    if (this.props.countryReducer.sendingCountries.length > 0 && this.props.countryReducer.sendingCountries[this.state.sendingCountry]) {
      return (
        <div>
          {this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.name}
        </div>
      )
    } else {
      return (
        <div>Not Available</div>
      )
    }
  }

  renderNationalities(){
    return this.props.countryReducer.countries.map((itemAr, index) => {
      return (
        <Option key={`${index} - ${itemAr.id}`} value={itemAr.id}>{itemAr.attributes.name}</Option>
        );
    })
  }

  renderGenderParent(){
    return (
      <Menu onClick={this.onChangeGender}>
        {this.renderGenders()}
      </Menu>
    )
  }

  renderGenders(){
    return this.state.genders.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
        );
    })
  }

  renderGenderName() {
    return (
      <div>
        {this.state.genders[this.state.chosenGender]}
      </div>
    )
  }

  onEmailAddChanged = (event) => {
    Log.d(TAG, 'onEmailAddChanged() -- called');
    this.props.form.setFieldsValue({
      email: event.target.value
    })

    this.setState({ email : event.target.value })
  }

  onMobileChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      phone: event.target.value
    })
    this.setState({ phone : event.target.value })
  }

  onWhatsAppChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      whatsapp_mobile: event.target.value
    })
    this.setState({ whatsapp_mobile : event.target.value })
  }
  
  onIdNumberChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      id_document_number: event.target.value
    })
    this.setState({ idNumber : event.target.value })
  }

  onChangeReferenceCode = (event) => {
    this.props.form.setFieldsValue({
      reference_code: event.target.value
    })
    this.setState({ refCode : event.target.value })
  }

  onChangeBirthDate = (date, dateString) => {
    this.setState({
      birthDate : date
    })
  }

  onChangeNationality = (value) => {
    Log.d(TAG, "onChangeNationality() -- value=" + value);
    this.setState({
      chosenNationality : value,
      homeWallet: false
    })
    this.props.form.setFieldsValue({
      nationality: value
    })
  }

  onChangeGender = (event) => {
    Log.d(TAG, "onChangeGender() -- event.key=" + event.key);
    this.setState({
      chosenGender : event.key
    })
    this.props.form.setFieldsValue({
      gender: event.key
    })
  }

  renderVerificationTypeParent(){
    return (
      <Menu onClick={this.onChangeVerificationType}>
        {this.renderVerificationTypes()}
      </Menu>
    )
  }

  renderVerificationTypes(){
    return this.state.verificationTypes.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
        );
    })
  }

  renderVerificationType() {
    return (
      <div>
        {this.state.verificationTypes[this.state.chosenVerificationType].value}
      </div>
    )
  }

  validateID = (rule, value, callback) => {
    Log.d(TAG, "validateID() -- called ..")
    if (value && 
      Number(this.state.chosenVerificationType) === 0 &&
      this.state.baseCountryIsSA) {
      if (this.checkIDNumber(value)) {
        callback();
      } else {
        callback('This field has invalid SA ID number.');
      }
    } else {
      callback();
    }
  }

  checkIDNumber(idNumber) {
    return Helper.isValidSAID(idNumber);
  }

  onChangeVerificationType = (event) => {
    Log.d(TAG, "onChangeVerificationType() -- event.key=" + event.key);
    this.setState({
      chosenVerificationType : event.key
    })
    this.props.form.setFieldsValue({
      id_verification_document: event.key
    })

    if (this.props.form.getFieldValue('id_document_number') && Number(event.key) === 0) {
      Log.d(TAG, "onChangeVerificationType() -- has ID number and event key is 0");
      if (this.checkIDNumber(this.props.form.getFieldValue('id_document_number'))) {
        Log.d(TAG, "onChangeVerificationType() -- no error 1");
        this.setNoErrorID('id_document_number');
      } else {
        Log.d(TAG, "onChangeVerificationType() -- has error");
        Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
      }
    } else {
      Log.d(TAG, "onChangeVerificationType() -- no error 2");
      this.setNoErrorID('id_document_number');
    }
  }

  setNoErrorID(field) {
    this.props.form.setFields({
      [field] : {
        value: this.props.form.getFieldValue(field),
        errors: null,
      }
    });
  }

  renderStep1(getFieldDecorator, phonePrefix) {
    Log.d(TAG, "renderStep1() -- mobile=" + this.state.phone);
    return (
      <div>
        <div className="su-item-2">
          <div className="su-left-title-text">
            <b>{this.markRequired}Your WhatsApp number</b>
          </div>
          <FormItem className="su-row-full">
            {getFieldDecorator("whatsapp_mobile", {
              rules: [
                {
                  required: false,
                  message: "Please provide your WhatsApp number",
                },
                { validator: this.validateWhatsAppMobile },
              ],
              initialValue: this.state.whatsapp_mobile,
            })(
              <Input
                className="mobile-number"
                placeholder="WhatsApp number"
                addonBefore={phonePrefix}
                onChange={this.onWhatsAppChanged}
                maxlength="11"
              />
            )}
          </FormItem>
          <div className="su-left-title-text">
            <b>{this.markRequired}Email</b>
          </div>
        </div>
        <div className="su-item-2">
          <FormItem className="su-row-full">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "Please provide a valid e-mail address",
                },
                {
                  required: !Helper.baseCountryIsSA,
                  message: "Please enter email address",
                },
              ],
              initialValue: this.state.emailAdd,
            })(
              <Input
                onChange={this.onEmailAddChanged}
                className="su-tf-2 form-control"
                placeholder="Email address"
              />
            )}
          </FormItem>
          <div className="su-left-title-text">
            <b>{this.markRequired}First Name</b>
          </div>
          <FormItem className = "su-row-full">
          {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'Please provide your first name' }], initialValue: this.state.firstName
              })(
                <Input placeholder="First Name*"
                  className="su-tf-2 form-control" />
              )}
            </FormItem>
            <div className="su-left-title-text">
              <b>Middle Name</b>
            </div>
            <FormItem className = "su-row-full">
            {getFieldDecorator('middle_name', { initialValue: this.state.middle_name })(
              <Input placeholder="Middle Name "
                className="su-tf-2 form-control"/>
            )}
            </FormItem>
            <div className="su-left-title-text">
              <b>Surname</b>
            </div>
            <FormItem className = "su-row-full">
            {getFieldDecorator('last_name', {
                  rules: [{ required: true, message: 'Please provide your surname' }], initialValue: this.state.surname
                })(
                  <Input placeholder="Surname*"
                    className="su-tf-2 form-control"/>
                )}
            </FormItem>
            {Helper.baseCountryIsSA && (
            <>
              <div className="su-left-title-text">
                <b>{this.markRequired}Nationality</b>
              </div>
              <FormItem className="su-row-full">
                {getFieldDecorator('nationality1', {
                    rules: [{ required: true, 
                    message: 'Please provide your nationality'}], 
                    initialValue: this.state.chosenNationality
                  })(
                    <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Please provide your nationality"
                    optionFilterProp="children"
                    onChange={this.onChangeNationality}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.renderNationalities()}
                  </Select>
                  )}
              </FormItem>
            </>)}
            {Helper.baseCountryIsSA && (
              <>
                <div className="su-left-title-text">
                    <b>{this.markRequired}ID Document Type</b>
                </div>
                <FormItem className = "su-row-full">
                    {getFieldDecorator('id_verification_document', { 
                      rules: [{ required: Helper.baseCountryIsSA, message: 'Please provide id document type.' }, ],
                      initialValue: this.state.chosenVerificationType 
                    })(
                      <Dropdown
                        className="su-tf-2 form-control"
                        trigger={['click']}
                        overlay={this.renderVerificationTypeParent()}>
                        <div className="dropdown-item">
                          <div>
                            {this.renderVerificationType()}
                          </div>
                          <Icon type="down" />
                        </div>
                      </Dropdown>
                    )}
                </FormItem>
              </>
            )}
            {Helper.baseCountryIsSA && (<FormItem className = "su-row-full">
            <div className="su-left-title-text">
              <b>{this.markRequired}ID Verification Document </b>
            </div>
            {getFieldDecorator('id_document_number', {
                rules: [{ required: true, message: 'Please provide your ID document number'}, {validator: this.validateID }],
                initialValue: this.state.idNumber,
              })(
                <Input placeholder = "ID Document Number*"
                  className="su-tf-2 form-control"
                  onChange={this.onIdNumberChanged}
                />
              )}
            </FormItem>)}
          {Helper.baseCountryIsSA && (<>
            <div className="su-left-title-text">
                <b>{this.markRequired}Occupation</b>
            </div>
            <FormItem className = "su-row-full">
            {getFieldDecorator('occupation', {
              rules: [
                { required: Helper.baseCountryIsSA, message: 'Please provide your occupation. ' }, 
                { min: Helper.baseCountryIsSA ? 4 : 0, message: 'Occupation should be 4 or more characters long. ' }, 
                { pattern: "^[a-zA-Z ]*$", message: 'Occupation should not include numbers and special characters. ' },
                { whitespace: true, message: 'Occupation should not be blank. ' }
                ],
              initialValue: this.state.occupation
              })(
                <Input placeholder = {Helper.baseCountryIsSA ? "Occupation*" : "Occupation"}
                  className="su-tf-2 form-control"/>
              )}
            </FormItem>
          </>)} 
          {Helper.baseCountryIsSA && (<>
            <div className="su-left-title-text">
                <b>Gender</b>
            </div>
            <FormItem className = "su-row-full">
                {getFieldDecorator(
                  'gender', { 
                    rules: [
                      { required: Helper.baseCountryIsSA, message: 'Please select your gender.' }, 
                    ],
                    initialValue: this.state.chosenGender 
                  })(
                  <Dropdown
                    className="su-tf-2 form-control"
                    trigger={['click']}
                    overlay={this.renderGenderParent()}>
                    <div className="dropdown-item">
                      <div>
                        {this.renderGenderName()}
                      </div>
                      <Icon type="down" />
                    </div>
                  </Dropdown>
                )}
            </FormItem>
          </>)}
          <div className="su-left-title-text">
              <b>{this.markRequired}Date of Birth </b>
          </div>
          <FormItem className = "su-row-full">
              {getFieldDecorator('birth_date', {
                  rules: [{ required: true, message: 'Please provide your birth date' }],
                  initialValue: this.state.birthDate
                })(
                  <DatePicker placeholder = "Date of birth"
                    allowClear={false}
                    format={'DD/MM/YYYY'}
                    className="su-tf-2"
                    disabledDate={this.disabledDate}
                    onChange={this.onChangeBirthDate}>
                  </DatePicker>
                )}
            </FormItem>
            {Helper.baseCountryIsSA && (
              <>
                <div className="su-left-title-text">
                  <b>Did someone refer us?</b>
                </div>
                  <FormItem className = "su-row-full">
                {getFieldDecorator('referral_code', {
                  initialValue: this.state.refCode
                })(
                    <Input
                      placeholder = "Referral Code"
                      className="su-tf-2 form-control"
                      onChange={this.onChangeReferenceCode}
                      />
                  )}
                </FormItem>
              </>
            )}
        </div>
      </div>
    );
  }

  handleSubmit(e) {
    Log.d(TAG, "handleSubmit() -- called ..");
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- values=" + JSON.stringify(values));
      Log.d(TAG, "handleSubmit() -- err=" + JSON.stringify(err));
      if (!err) {
        const prefix = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix;

        const payload = {
          data: {
            attributes: {
              birth_date: this.state.birthDate.format("YYYY-MM-DD"),
              email: values.email ? values.email : null,
              first_name: values.first_name,
              gender: this.state.genders[values.gender] === "Male" ? "M" : "F",
              last_name: values.last_name,
              middle_name: values.middle_name,
              id_document_number: Helper.baseCountryIsSA ? this.state.idNumber : null,
              id_verification_type: Helper.baseCountryIsSA ? this.state.verificationTypes[this.state.chosenVerificationType].id : null,
              mobile_wallet_auto_create: true,
              notify_promotions: true,
              occupation: values.occupation,
              ref_code: this.state.refCode === "" ? null : this.state.refCode,
              whatsapp_mobile: values.whatsapp_mobile === "" ? null : [prefix, values.whatsapp_mobile],
            },
            id: this.props.userReducer.user.data.id,
            relationships: {
              id_nationality: {
                data: {
                  id: this.state.chosenNationality,
                  type: "countries",
                },
              },
            },
            type: "accounts",
          },
        };

        const cookies = this.props.cookies;
        const userId = this.props.userReducer.user.data.id;
        const access = this.props.authReducer.accessToken;

        this.props.requestUpdatePersonalDetails(cookies, userId, access, payload, () => {
          this.props.history.push({
            pathname: "/where-you-live",
          });
        }); //cookies, userId, access, payload
      }
    });
  }

  actionButtons() {
    const next = () => {
      // this.props.history.push({
      //   pathname: "/personal-details",
      // });
    }
    const prev = () => {
      
    }

    const buttonStyle = {
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
      marginRight: 8,
    }

    return (
      <>
        <Button
          style={buttonStyle}
          onClick={() => prev()}
        >
          Prev
        </Button>
        <Button
          style={buttonStyle}
          htmlType="submit"
          onClick={() => next()}
        >
          Next
        </Button>
      </>
    );
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: "",
    })(this.renderPhonePrefix());

    return (
      <Form className="signup-body" onSubmit={this.handleSubmit.bind(this)}>
        <h2 style={{ fontFamily: "Lato" }}>Sign Up</h2>
        <div style={{ marginTop: "4vh", marginBottom: "4vh" }}>
          <Steps style={{ margin: "0" }} current={0}>
            {steps.map((item) => (
              <Step
                key={item.title}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </div>
        {this.renderStep1(getFieldDecorator, phonePrefix)}
        {this.actionButtons()}
        <div style={{ width: "100%", padding: "20px", textAlign: "left" }}>
          <b style={{ width: "100%", color: "red" }}>
            Fields with * are required.
          </b>
        </div>
      </Form>
    );
  }
}

const WrappedPersonalDetailsForm = Form.create()(PersonalDetailsForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestSendingCountries,
    requestSignUp,
    requestLogin,
    requestPCAPredictAddressFind,
    requestPCAPredictAddressRetrieve,
    requestCountries,
    setSendingCountry,
    fetchUserDetails,
    requestUpdatePersonalDetails,
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
