import React from 'react';
import { Layout, Carousel, Button } from 'antd';
import { withCookies } from 'react-cookie';

import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import '../styles/index.css'
import '../styles/home.css'
import Log from '../utils/log.js';

import twoPeoplePins from '../images/two-people-pins.png'
import twoPeoplePhone from '../images/two-people-phone.png'
import phone from '../images/EcoCash-to-EcoCash.svg'
import ussd from '../images/Multi-Channel.svg'
import secure from '../images/Safe-and-Secure.svg'
import cashG from '../images/Multiple-Delivery-Methods.svg'
import download from '../images/Quick-&-instant.svg'
import visaMasterCard from '../images/visa_mastercard_secure_code.png';
import pci from '../images/pci-dss.png';
import kazang from '../images/kazang.jpg';
import ecocash from '../images/ecocash.jpg';
import bluApproved from '../images/blu-approved.png';
import stewardBank from '../images/steward-bank-white.png';
import bancabc from '../images/bancabc-logo.png';
import econet from '../images/econet-logo.png';
import lesothoEcocash from '../images/lesotho-ecocash-logo.jpg';
import lesothoEcocashSpacheFono from '../images/lesotho-ecocash-spache-fono-logo.png';

import '../../node_modules/flag-icon-css/css/flag-icon.css'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helper from '../utils/helper.js';

const { Content, Header, Footer } = Layout;
const TAG = "Home";

class Home extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
    Log.d(TAG, "constructor() -- process.env=" + JSON.stringify(process.env))
    Log.d(TAG, "constructor() -- ENV=" + process.env.NODE_ENV);

    this.state = {
      headerColor: 'rgba(0, 0, 0, 0.1)',
      headerTextColor: '#a4aeb0'
    }

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();
  }

  listenScrollEvent = e => {
    if (window.scrollY > 800) {
      this.setState({ headerColor: 'white', headerTextColor: 'black' })
      Log.d(TAG, "listenScrollEvent() -- white")
    } else {
      this.setState({ headerColor: 'rgba(0, 0, 0, 0.1)', headerTextColor: '#a4aeb0' })
      Log.d(TAG, "listenScrollEvent() -- transparent")
    }
  }

  checkForUserDuplicateAddress() {
    if ("data" in this.props.userReducer.user) {
      if (this.props.location.state !== undefined && this.props.location.state.prevPath === "login") {
        // window.alert(JSON.stringify(this.props.userReducer.user))
        if (
          this.props.userReducer.user.data.attributes.user_status_flag !== undefined &&
          this.props.userReducer.user.data.attributes.user_status_flag === "010"
        ) {
          window.confirm('Please Note: \nYour registration is paused. Please send a copy of ' +
            'your address to compliance@cassavasmartech.com or ' +
            'contact our customer number on + 27(0) 10 500 8801.')
        }
      }
    }
  }

  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }

  render() {

    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1' }}>
          <CustomHeader {...this.props} menuBgColor={this.state.headerColor} textColor={this.state.headerTextColor} />
        </Header>
        <Carousel {...{ speed: 500, infinite: true }} className="home-banner-carousel" autoplay={true}>
          {(this.props.authReducer.accessToken && Helper.baseCountryIsSA) && (
            <div className="home-top-bg-4">
              {this.renderCarouselBody("home-top-title", "home-top-desc", twoPeoplePins, "home-top-ecocash", null, null, "12vh", "send-money-white-button", 4)}
            </div>
          )}
          <div className="home-top-bg-1">
            {this.renderCarouselBody("home-top-title", "home-top-desc", twoPeoplePins, "home-top-ecocash", "Send money home with Sasai Money Transfer", "Send money, airtime and data home with Sasai Money Transfer.", "10vh", "send-money-white-button", 2)}
          </div>
          <div className="home-top-bg-2">
            {this.renderCarouselBody("home-top-title-green", "home-top-desc-green", twoPeoplePhone, "home-top-ecocash-green", "Safe. Swift. Simple.", "It's instant, it's safe, it's DIRECT", "10vh", "send-money-green-button", 1)}
          </div>
        </Carousel>
        <Layout>
          <Content className="home-content-container2">
            <div className="home-choose-area">
              <h2 style={{ textAlign: 'center' }}>Why Choose Us?</h2>
              <div className="home-choose-icons-area">
                <div className="home-choose-icons-child-area">
                  <div className="home-choose-icon">
                    <span style={{ width: '90px', height: '90px', display: 'inline-block', marginTop: "10px" }}>
                      <img src={secure} alt="SECURE" style={{ width: '90px', height: '90px' }} />
                    </span>
                  </div>
                  <span style={{ textAlign: 'center' }}>Safe & Secure</span>
                </div>
                <div className="home-choose-icons-child-area">
                  <div className="home-choose-icon">
                    <span style={{ width: '90px', height: '90px', display: 'inline-block', marginTop: "10px" }}>
                      <img src={ussd} alt="ussd" style={{ width: '90px', height: '90px' }} />
                    </span>
                  </div>
                  <span style={{ textAlign: 'center' }}>Multi-Channel (Web, App, Sasai, USSD & Agent)</span>
                </div>
                <div className="home-choose-icons-child-area">
                  <div className="home-choose-icon">
                    <span style={{ width: '90px', height: '90px', display: 'inline-block', marginTop: "10px" }}>
                      <img src={cashG} alt="CASH-G" style={{ width: '90px', height: '90px' }} />
                    </span>
                  </div>
                  <span style={{ textAlign: 'center' }}>Multiple Delivery Methods (Cash, Bank Account, Mobile Money)</span>
                </div>
                <div className="home-choose-icons-child-area">
                  <div className="home-choose-icon">
                    <span style={{ width: '90px', height: '90px', display: 'inline-block', marginTop: "10px" }}>
                      <img src={phone} alt="phone" style={{ width: '90px', height: '90px' }} />
                    </span>
                  </div>
                  <span style={{ textAlign: 'center' }}>Send directly to EcoCash</span>
                </div>
                <div className="home-choose-icons-child-area">
                  <div className="home-choose-icon">
                    <span style={{ width: '90px', height: '90px', display: 'inline-block', marginTop: "10px" }}>
                      <img src={download} alt="DOWNLOAD" style={{ width: '90px', height: '90px' }} />
                    </span>
                  </div>
                  <span style={{ textAlign: 'center' }}>Quick & instant </span>
                </div>
              </div>
            </div>
            {/* <div className="home-last-container">
              <div className="home-stories-container">
                <h2>Stories</h2>
                <Carousel draggable="true" autoplay={true} ref={node => (this.carousel = node)} {...storiesProps}>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"My friend from Ruwadzano told me she received money in her EcoCash wallet from her son in Johannesburg. I could not believe it so I asked my son to try it out also. He did and just like that I was on my way to the Econet shop to collect $USD. Phewww goodbye to waiting long hours for bus drivers at the terminals."</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Justin, 34</h4>
                  </div>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"When I heard that EcoCash was now in Mzansi, I was very excited. It is a brand that I have always trusted so I quickly downloaded the Mobile App and tried it out. I sent my sister in Zvimba some money and indeed she received it direct into her EcoCash wallet with the option to get HARD CASH! I am a happy man."</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Mai John, 56</h4>
                  </div>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"I have always used alternative means of sending money home, it was hard for me to trust some of the service channels used to send money home because my mother would struggle to get cash. A friend sold Sasai Money Transfer to me one evening, he helped me register and I gave it a try. I was quite surprised and excited as my mother received usd cash all the way in Zvishavane. There no ques and no hassles. If Sasai Money Transfer maintains this level of service then I am sold."</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Sydwell</h4>
                  </div>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"Ini ndaka-receiver mari from SA from my brother. I thought kuti ndichaona moto to get the cash from Steward Bank Gweru but I got there and alas! I actually got ma-USA cash a few minutes after my brother sent the money. I even took a video of myself because it was unbelievable lol!!!!!...."</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Naomi</h4>
                  </div>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"I managed to collect the money USD Cash from Harare. No hassles, No ques. Many thanks Sasai Money Transfer"</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Mercy</h4>
                  </div>
                  <div>
                    <div><i style={{ lineHeight: '1.777777778', fontSize: '18px', color: '#a4aeb0' }}>"Chadziya chi-Sasai Money Transfer. $50 yangu yakasvika Cash pasina ma-stories kana ma-ques. Thanks Sasai Money Transfer USD guarantee yenyu ndyemashuwa...."</i></div>
                    <br />
                    <h4 style={{ color: '#0c5ca9' }}>Pertunia</h4>
                  </div>
                </Carousel>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'space-around' }}>
                  <div>
                    <Icon type="left-circle" onClick={this.previous} style={{ marginRight: '8px' }} />
                    <Icon type="right-circle" onClick={this.next} />
                  </div>
                </div>
              </div>
              <div className="home-stories-img" />
            </div> */}
            <div className="home-app-area" style={{
              paddingLeft: '20px',
              paddingRight: '20px'
            }}>
              <span className="home-app-area-title-text">Want to send money on the Go? Try out our mobile App. Enjoy the experience!</span>
              <h2 className="home-app-area-sub-title-text">Get the App and send money home to your loved ones.</h2>
              <div className="home-app-area-sub-title-area">
                <Button className="home-mobile-store" onClick={() => {
                  window.open(encodeURI("https://itunes.apple.com/us/app/ecocash-remit/id1455082083?ls=1&mt=8"));
                }}><b>GET IT ON APP STORE</b></Button>
                <Button className="home-mobile-store" onClick={() => {
                  window.open(encodeURI("https://play.google.com/store/apps/details?id=com.ecocash.saremit.production"));
                }}><b>GET IT ON GOOGLE PLAY</b></Button>
              </div>
            </div>
            <div className="home-payment-container">
              <div className="home-payment-options-container">
                <h4>The following cards are accepted</h4>
                <img className="home-visa-master-logo" src={visaMasterCard} alt="visa_master_card" />
              </div>
            </div>
            <div>
              <div className="home-partner-list">
                {/* <img className="home-partner-logo-start" src={cassavaFintech} alt="cassava-fintech" height="80qpx" /> */}
                <img className="home-partner-logo" src={pci} alt="pci-dss" />
                <img className="home-partner-logo" src={kazang} alt="kazang" />
                <img width="200px" className="home-partner-logo" src={lesothoEcocash} alt="lesotho-ecocash" />
                <img width="200px" className="home-partner-logo" src={lesothoEcocashSpacheFono} alt="lesotho-ecocash-spache-fono" />
                <img className="home-partner-logo" src={ecocash} alt="ecocash" />
                <img className="home-partner-logo" src={bluApproved} alt="blu-approved" />
                <img className="home-partner-logo" src={stewardBank} alt="steward-bank" />
                <img width="200px" className="home-partner-logo" src={bancabc} alt="bancabc" />
                <img width="200px" className="home-partner-logo" src={econet} alt="econet" />
              </div>
            </div>
          </Content>
          <Footer className="custom-footer">
            <CustomFooter />
          </Footer>
        </Layout>
      </Layout>
    )
  }

  componentDidMount() {
    document.title = "Sasai Money Transfer"
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.listenScrollEvent)
    this.checkForUserDuplicateAddress()
    //    Log.d(TAG, "constructor() -- isValidSAID=" + Helper.isValidSAID("9501127062394"));
  }

  renderCarouselBody(titleStyle, descripStyle, rightImage, ecocashStyle, title, desc, marginTop, btnStyle, position) {
    const callToActionTextBackground = position === 1 ? "transparent" : "rgba(255, 255, 255, .4)"

    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
        <div className="containers">
          <div className="row fullscreen d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
            {title && desc && (<div style={{ backgroundColor: callToActionTextBackground, padding: 10, display: 'flex', flexDirection: 'column', marginTop: marginTop }}>
              {/* <b className={ecocashStyle}>Ecocash to Ecocash</b> */}
              <p className={titleStyle}>
                {title}
              </p>
              <h2 className={descripStyle}>
                <span>
                  {desc}
                </span>
              </h2>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'left', flexWrap: 'wrap', marginTop: '8px' }}>
                <Button className={btnStyle} onClick={() => {
                  this.props.history.push({
                    pathname: "/get-quote",
                  });
                }}>Send Money</Button>
                <Button className="learn-more-button" onClick={() => {
                  this.props.history.push({
                    pathname: "/how-it-works",
                  });
                }}>Learn More</Button>
              </div>
            </div>
            )}
            <div className="col-lg-6" />
          </div>
        </div>
        {/* <img className="container-right-image" src={rightImage} alt="carousel-img"/> */}
        <div className="container-right-image"></div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      // fetchUserDetails
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Home));
