import React from "react";
import { Layout, Collapse, Icon } from "antd";
import { withCookies } from "react-cookie";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import "../../styles/dashboard.css";
import glomo from "../../images/glomo-1-1-1.jpg";

const { Content, Header, Footer } = Layout;
const Panel = Collapse.Panel;

const customPanelStyle = {
  padding: "14px",
  fontFamily: "Lato",
  fontSize: "16px",
  boxShadow: "none",
  background: "white",
};

class AboutUs extends React.Component {
  componentDidMount() {
    document.title = "About Us";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <div className="about-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">ABOUT SASAI MONEY TRANSFER</h2>
          </div>
        </div>
        <Content className="about-body">
          <Content className="dashboard-content-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="about-mzansi" />
              <div className="about-mzansi-text">
                <h2 className="about-us-text ">About Sasai</h2>
                {/* <b>Our Story</b> */}
                <p>
                  Sasai is a business of Cassava Technologies, a pan-African
                  fintech company offering digital financial services to
                  Africa's mobile consumers Our strategic approach focuses on
                  mass market disruptive innovations covering Mobile Money,
                  Payment Solutions, Money Transfer, and Micro Insurance. All
                  our services are delivered through the Sasai Super App, a
                  one-stop shop that is deeply integrated into consumers lives.
                </p>
                <p>
                  Through our fully-licensed, secure and scalable pan-African
                  money transfer service, Sasai Money Transfer, we facilitate
                  diaspora remittances across the African continent as well as
                  from the UK into Africa and rest of the world. We are the
                  fastest growing money transfer company in Africa.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                background: "rgb(41, 108, 187)",
                paddingTop: "115px",
                paddingBottom: "115px",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5vw",
                }}
              >
                <h2 style={{ color: "white" }}>Our Awards</h2>
                <br />
                <span style={{ color: "white", textAlign: "center" }}>
                  Best Mobile Money Service in the World – GSMA GLOMO Award
                </span>
                <br />
                <span style={{ color: "white", textAlign: "center" }}>
                  MWC2017; Best Mobile Innovation for Women in Emerging Markets
                </span>
                <br />
                <span style={{ color: "white", textAlign: "center" }}>
                  GSMA GLOMO Awards, MWC2018; Finance for the Future awards,
                  2017
                </span>
                <br />
              </div>
              <img
                width="350"
                height="274"
                src={glomo}
                class="vc_single_image-img attachment-full"
                alt="awards"
                sizes="(max-width: 350px) 100vw, 350px"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "115px",
              }}
            >
              <Collapse
                accordion
                expandIcon={({ isActive }) => (
                  <Icon type={isActive ? "" : "plus"} />
                )}
                style={{ width: "50%" }}
              >
                <Panel header="Our Vision" key="1" style={customPanelStyle}>
                  <span>
                    Our vision is of a digitally connected future that leaves no
                    African behind.
                  </span>
                </Panel>
                <Panel header="Our Purpose" key="2" style={customPanelStyle}>
                  <span>
                    We provide technology solutions that transform the lives of
                    consumers and enable enterprises to better serve their
                    customers.
                  </span>
                </Panel>
                <Panel
                  header="Our Guiding Principle is Stewardship"
                  key="4"
                  style={customPanelStyle}
                >
                  <span>
                    We are faithful stewards of the trust of our clients and the
                    communities in which we serve.{" "}
                  </span>
                </Panel>
                <Panel header="Our Values" key="3" style={customPanelStyle}>
                  <b>We are Entrepreneurial</b>
                  <br />
                  <b>We are Dependable</b>
                  <br />
                  <b>We are Honest</b>
                  <br />
                  <b>We are Faithful Stewards</b>
                </Panel>
              </Collapse>
            </div>
          </Content>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
}

export default withCookies(AboutUs);
