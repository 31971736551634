import React from 'react';
import { Layout, Divider } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Feedback from '../../containers/Feedback.js'
import '../../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class GetHelp extends React.Component {

  componentDidMount() {
    document.title = "Get Help"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="help-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">CONTACT US</h2>
          </div>
        </div>
        <Content className="get-help-body">
          <h2 style={{textAlign: 'center', marginBottom: '10vh'}}>GET IN TOUCH</h2>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '8px'}}>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <i class="material-icons" style={{margin: '8px'}}>location_on</i>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <span/>
              </div>
              <span style={{textAlign: 'center'}}><strong>South Africa:</strong></span>
              <span style={{textAlign: 'center'}}>Worcestor House, Eton Office Park,</span>
              <span style={{textAlign: 'center'}}>Corner Sloane and Harrison Avenue,</span>
              <span style={{textAlign: 'center'}}>Bryanston,</span>
              <span style={{textAlign: 'center'}}>South Africa</span>
              <br/>
              <span style={{textAlign: 'center'}}><strong>United Kingdom/European Union:</strong></span>
              <span style={{textAlign: 'center'}}>Sasai Money Transfer Head Office,</span>
              <span style={{textAlign: 'center'}}>17th Floor,</span>
              <span style={{textAlign: 'center'}}>6 New Street Square,</span>
              <span style={{textAlign: 'center'}}>London,</span>
              <span style={{textAlign: 'center'}}>EC4A 3BF</span>
              <br/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '8px'}}>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <i class="material-icons" style={{margin: '8px'}}>phone</i>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <span/>
              </div>
              <span style={{ textAlign: 'center' }}><strong>South Africa:</strong></span>
              <span style={{ textAlign: 'center' }}>Customer Service: <a href="tel:+27105008801">+27(0) 10 500 8801</a></span>
              <span style={{ textAlign: 'center' }}>WhatsApp: <a href="https://wa.me/+27611054898">+27 (0) 61 105 4898</a></span>
              <br/>
              <br/>
              <br/>
              <span style={{ textAlign: 'center' }}><strong>United Kingdom/European Union:</strong></span>
              <span style={{ textAlign: 'center' }}>Customer Service: <a href="tel:+442030518189">+44 (0) 203 051 8189</a></span>
              <span style={{ textAlign: 'center' }}>WhatsApp: <a href="https://wa.me/+447951619537">+44 (0) 795 161 9537</a></span>
              <br/>
              <br/>
              <br/>
            </div>
            <span/>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '8px'}}>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <i class="material-icons" style={{margin: '8px'}}>send</i>
                <div style={{width: '10vw'}}>
                  <Divider/>
                </div>
                <span/>
              </div>
              <span style={{textAlign: 'center'}}>Email: <a href="mailto:support@sasai.global">support@sasai.global</a></span>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </div>
          </div>
          <Feedback/>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(GetHelp)
