import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';

import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import '../styles/index.css'
import SmartQuote from '../containers/Quote.js';

const { Header, Footer } = Layout;

class Quote extends React.Component {

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props} />
        </Header>
        <SmartQuote {... this.props}/>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
    </Layout>
    )
  }

  componentDidMount() {
    document.title = "Get Quote"
    window.scrollTo(0, 0)
  }
}

export default withCookies(Quote)
