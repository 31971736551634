import React from 'react';
import { Layout, Collapse, Icon } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'

const { Content, Header, Footer } = Layout;
const Panel = Collapse.Panel;

const customPanelStyle = {
  padding: '14px',
  fontFamily: 'Lato',
  fontSize: '18px',
  boxShadow: 'none',
  background: 'white',
  fontWeight: '700',
};

class ContactUs extends React.Component {

  componentDidMount() {
    document.title = "FAQs"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
          <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="faqs-top-bg">
          <div className="misc-page-overlay">
            <h2 className="misc-top-title">FAQS</h2>
          </div>
        </div>
        <Content className="faq-content-container">
          <div className="faq-body">
            <h3 style={{marginTop: '60px', marginBottom: '60px'}}>GENERAL QUESTIONS</h3>
            <Collapse
              accordion
              expandIcon={({ isActive }) => (<Icon type={isActive ? "" : "plus"} />)}>
              <Panel header="1. Who is Sasai Fintech?" key="1" style={customPanelStyle}>
                <span className="panel-child">Sasai Fintech is the registered South African entity that brings you Sasai Money Transfer.</span>
              </Panel>
              <Panel header="2. Who is Sasai Money Transfer?" key="2" style={customPanelStyle}>
                <span className="panel-child">
                  Sasai Money Transfer is a remittance service that allows you to send money back to your home country from South Africa securely and legally.
                </span>
              </Panel>
              <Panel header="3. Can anyone sign up as an Sasai Money Transfer customer?" key="3" style={customPanelStyle}>
                <span className="panel-child">
                  Yes. As long as you are 18 years of age or older, you can set up your own Sasai Money Transfer account.
                </span>
              </Panel>
              <Panel header="4. Where or How do I sign up to be a customer?" key="4" style={customPanelStyle}>
                <span className="panel-child">
                  You can sign-up using any of the following channels:
                  <ul>
                    <li>Self sign-up on the Website (<a href="https://moneytransfer.sasai.global/">www.cassavaecocash.co.za</a>)</li>
                    <li>Self sign-up on the Mobile Application (The Sasai Money Transfer app can be downloaded on Google Play and App Store) or</li>
                    <li>Via an appointed sign-up agent located nationwide. For more information, please contact us on <a href="tel:+27105008801">+27(0) 10 500 8801</a> or dial *120*10000# for a “call me back” option</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="5. What document do I need to sign up as a customer?" key="5" style={customPanelStyle}>
                <span className="panel-child">
                  You must have any of the following valid identity documents:
                  <ul>
                    <li>South African ID (green barcoded or smart)</li>
                    <li>Asylum Permit, or</li>
                    <li>Foreign Passport</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="6. Is my proof of address required?" key="6" style={customPanelStyle}>
                <span className="panel-child">Proof of address is required if you need a higher transacting limit of R5000 per day / R25 000 per month.</span>
              </Panel>
              <Panel header="7. What are the maximum transaction limits?" key="7" style={customPanelStyle}>
                <span className="panel-child">
                  The transaction limits are as follows:
                  <ul>
                    <li>R3000 per day / R10 000 per month if you have been signed up only with your ID document.</li>
                    <li>R5000 per day / R25 000 per month if you have been signed up with your ID document plus proof of address.</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="8. Can I upgrade my limits?" key="8" style={customPanelStyle}>
                <span className="panel-child">
                  You can upgrade your limit from R3000 per day / R10 000 per month to R5000 per day / R25 000 per month by providing us with your proof of address. Some examples of proof of address are:
                  <ul>
                    <li>Utility account</li>
                    <li>Bank account</li>
                    <li>Retail account</li>
                    <li>Site verification (a visit by one of our sign-up agents to your residential address)</li>
                  </ul>
                  To upgrade your limits contact us on <a href="tel:+27105008801">+27(0) 10 500 8801</a>.
                </span>
              </Panel>
              <Panel header="9. What is the minimum transaction limit?" key="9" style={customPanelStyle}>
                <span className="panel-child">The minimum transaction limit is R150.</span>
              </Panel>
              <Panel header="10. What platforms are available to send money?" key="10" style={customPanelStyle}>
                <span className="panel-child">
                  Once signed up you can transact via the following channels:
                  <ul>
                    <li>Website (<a href="https://moneytransfer.sasai.global/">www.cassavaecocash.co.za</a>)</li>
                    <li>Android APP</li>
                    <li>iOS APP</li>
                    <li>USSD String (*120*10000#)</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="11. How do I process a send money transaction?" key="11" style={customPanelStyle}>
                <span className="panel-child">
                  As a signed-up customer you can process a transaction by:
                  <ul>
                    <li>Adding a recipient</li>
                    <li>Creating an order through a “get quote” option</li>
                    <li>Paying for the order using a system generated transaction reference number</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="12. How long is a quote valid for?" key="12" style={customPanelStyle}>
                <span className="panel-child">If a quote is not settled within 24 hours, it automatically expires.</span>
              </Panel>
              <Panel header="13. How do I pay for my transaction?" key="13" style={customPanelStyle}>
                <span className="panel-child">
                  You can pay for your transaction using any of the following methods:
                  <ul>
                    <li>Pay with your bank account via Electronic Funds Transfer (EFT)</li>
                    <li>Pay in cash via any of our appointed cash-in agent across South Africa. Please contact us on <a href="tel:+27105008801">+27(0) 10 500 8801</a> for more information about the nearest cash-in agent.</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="14. How long does it take for money to reach my recipient?" key="14" style={customPanelStyle}>
                <span className="panel-child">Once you pay for your order the funds are delivered instantly to the pay-out partner in the foreign country.</span>
              </Panel>
              <Panel header="15. How can my recipient receive the money I’ve sent?" key="15" style={customPanelStyle}>
                <span className="panel-child">
                  Your recipient can receive the money via any of the following methods captured at the time of creating a transaction:
                  <ul>
                    <li>In cash via appointed cash-out agents</li>
                    <li>Into the recipient’s EcoCash FCA mobile wallet</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="16. Will my recipient receive USD?" key="16" style={customPanelStyle}>
                <span className="panel-child">For remittances to Zimbabwe, we guarantee that recipients will receive USD.</span>
              </Panel>
              <Panel header="17. Once I’ve sent money what happens next?" key="17" style={customPanelStyle}>
                <span className="panel-child">After you have sent money, both you and the recipient will receive a notification by SMS confirming the transaction.</span>
              </Panel>
              <Panel header="18. How can I track my transaction?" key="18" style={customPanelStyle}>
                <span className="panel-child">If you want to ask anything about the progress of your transfer, please contact us on <a href="tel:+27105008801">+27(0) 10 500 8801</a> or email us on <a href="mailto:support@sasai.global">support@sasai.global</a></span>
              </Panel>
              <Panel header="19. What does my recipient need to collect cash?" key="19" style={customPanelStyle}>
                <span className="panel-child">
                  Your recipient will at least need the following:
                  <ul>
                    <li>A reference number that was communicated by an SMS to the recipient</li>
                    <li>Valid identification document</li>
                  </ul>
                </span>
              </Panel>
              <Panel header="20. Can I cancel my transaction?" key="20" style={customPanelStyle}>
                <span className="panel-child">If the funds have been received by the recipient, a transaction cannot be cancelled. If the funds have not been received by the recipient, please contact us on <a href="tel:+27105008801">+27(0) 10 500 8801</a> or email us on <a href="mailto:support@sasai.global">support@sasai.global</a>. It is important that you accurately capture all relevant recipient information.</span>
              </Panel>
              <Panel header="21. What countries can I send money to?" key="21" style={customPanelStyle}>
                <span className="panel-child">Currently, you can send money to Zimbabwe. EcoCash is the leading provider of remittances into Zimbabwe. We are working on allowing you to send money to various other countries across Africa and the world so watch this space.</span>
              </Panel>
            </Collapse>
          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(ContactUs)
