import React from 'react';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Log from '../../utils/log.js';
import Login from '../../containers/Login.js'
import '../../styles/payment.css'
import { withCookies } from 'react-cookie';
import SmartRemitHopePaymentOptions from '../../containers/RemitHopePaymentOptions.js'
import { Layout } from 'antd';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
const { Content, Header, Footer } = Layout;

const TAG = "DumbRemitHopePaymentOptions";

class RemitHopePaymentOptions extends React.Component {

  render() {
    return (
    <div className="wrapper">
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props} />
        </Header>
          {this.renderBody()}
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    </div>
    )
  }

  renderBody() {
    if (!!this.props.authReducer.accessToken) {
      return (this.renderLoggedInContent())
    } else {
      return (<Login {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>)
    }
  }

  checkIfHasUser() {
    // if (cookies.get('access')) {
    if (!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      this.props.history.push({
        pathname: "/"
      });
    } else {
      Log.d(TAG, "checkIfHasUser() -- has no user");
    }
  }


  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  renderLoggedInContent() {
    return (
      <Content className="payment-content">
        <SmartRemitHopePaymentOptions {... this.props}/>
      </Content>
    )
  }

  componentDidMount() {
    document.title = "Select Payment Method"
    window.scrollTo(0, 0);
    this.checkIfHasUser();
  }
}


function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

// export default withCookies(Header)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(RemitHopePaymentOptions));
