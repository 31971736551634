import React from 'react';
import { Layout, Button} from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Helper from '../../utils/helper.js';
import '../../styles/dashboard.css'
import  Log from '../../utils/log.js'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ManageUrls from '../../endpoints/manage_urls.js';

const TAG = 'PrivacyPolicy';

const { Content, Header, Footer } = Layout;

class PrivacyPolicy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      columns6 : [{
        title: 'What we do',
        dataIndex: 'we_do',
        key: 'we_do',
      }, {
        title: 'Our Reasons',
        dataIndex: 'our_reasons',
        key: 'our_reasons',
      }]
    }
  }

  componentDidMount() {
    document.title = "Privacy Policy"
    window.scrollTo(0, 0)
  }

  render() {
    var classStyle = "zoom-in-layout"
    // if (cookies.get('access') && Helper.isMobile()) {
    if (!!this.props.authReducer.accessToken && Helper.isMobile()) {
      classStyle = "zoom-out-layout"
    }
    return (
      <Layout className={classStyle} style={{minWidth: '600px'}}>
          <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="cookie-policy-body">
          {PrivacyPolicyContent(this.props)}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

const PrivacyPolicyContent = (props) => {
  const manageUrls = new ManageUrls()

  return manageUrls.baseCountryIsSA() ? SAPrivacyPolicy(props) : UKPrivacyPolicy(props)

}

const SAPrivacyPolicy = (props) => {

  return (
    <>
      <h2 style={{ fontFamily: 'Lato' }}>Privacy Policy</h2>
            
      <span className="terms-item-child">1.	Should you decide to register to use the Service, we may require you to provide us with personal information which includes but is not limited to 
        details such as your name, surname, age, identity number and document, date of birth, country of birth, nationality, citizenship, relationship status, customer number, email and/or 
        physical address, telephone number, occupation, nationality, source of income, financial information such as banking details, account numbers and bank statements, device ID, IMEI number, 
        reason for transfer of funds and personal information related to a recipient of the funds that you are sending.</span>

      <span className="terms-item-child">2.	The above information will be retained and used by us as described in this policy.</span>
      
      <span className="terms-item-child">3.	Should your personal information change, you need to advise us soon as reasonably possible so that we may update your personal information.</span>
      
      <span className="terms-item-child">4.	We make use of third-party service providers for various reasons, some of which includes:</span>
      <ol style={{ listStyleType: "none" }}>
        <span className="terms-item-child"><li>a) To facilitate the delivery/supply of our products and services;</li></span><br />
        <span className="terms-item-child"><li>b) To provide certain aspects required in the process of the delivery/supply of the products and services on our behalf;</li></span><br />
        <span className="terms-item-child"><li>c) To assist us in analysing how our products and services are used.</li></span>
      </ol>
      <span className="terms-item-child">Our third-party service providers may collect and process your personal information in order to provide their services to us. You consent to our third-party
        service providers processing your personal information to ensure proper delivery the Service and where applicable, accuracy of transactions performed and compliance with relevant laws and
      legislation.</span>

      <span className="terms-item-child">5.	Subject to clause 7 below, we will not, without your express consent:</span>
      
      <ol style={{ listStyleType: "none" }}>
        <span className="terms-item-child"><li>a)	use your personal information for any purpose other than as set out below:</li></span><br />
        
        <ol style={{ listStyleType: "none" }}>
          <span className="terms-item-child"><li>(i)	for purposes of the provision of the Service;</li></span><br/>
          <span className="terms-item-child"><li>(ii)	to contact you regarding current or new products or services offered by us or any of our divisions, affiliates and/or partners
          (unless you have opted out from receiving marketing material from us);</li></span><br/>
          <span className="terms-item-child"><li>(iii)	to inform you of new features, special offers and promotional competitions offered by us or any of our divisions, affiliates
          and/or partners (unless you have opted out from receiving marketing material from us); and</li></span><br/>
          <span className="terms-item-child"><li>(iv)	to improve our product and service selection and your experience on our Website by, for example, monitoring your browsing habits;</li></span><br/>
        </ol>

        <span className="terms-item-child"><li>b)	disclose your personal information to any third party other than as set out below:</li></span><br/>
        
        <ol style={{ listStyleType: "none" }}>
          <span className="terms-item-child"><li>(i)	to our employees and/or third party service providers who assist us to interact with you via our Website, email or any other method, in
          the context of the Service and thus need to know your personal information in order to assist us to communicate with you properly and efficiently;</li></span><br/>
          <span className="terms-item-child"><li>(ii)	to our divisions, affiliates and/or partners (including their employees and/or third party service providers) in order for them to interact
          directly with you via email or any other method for purposes of sending you marketing material regarding any current or new goods or services, new features, special offers or promotional items offered 
          by them (unless you have opted out from receiving marketing material from us);</li></span><br/>
          <span className="terms-item-child"><li>(iii)	to law enforcement, government officials, fraud detection agencies or other third parties when we believe in good faith that the disclosure of
          personal information is necessary to prevent physical harm or financial loss, to report or support the investigation into suspected illegal activity, or to investigate violations of the Terms and Conditions;</li></span><br/>
          <span className="terms-item-child"><li>(iv)	to our service providers (under contract with us) who help with parts of our business operations, in delivering the Service to you. However, our contracts
          dictate that these service providers may only use your information in connection with the services they perform for us and not for their own benefit.</li></span>
        </ol>
      </ol>
      
      <span className="terms-item-child">6.	We are entitled to use or disclose your personal information if such use or disclosure is required in order to comply with any applicable law, subpoena, order of court or legal
      process served on us, or to protect and defend our rights or property. In the event of a fraudulent online payment, Sasai Fintech is entitled to disclose relevant personal information for criminal investigation purposes
      or in line with any other legal obligation for disclosure of the personal information which may be required of it.</span>

      <span className="terms-item-child">7.	We will ensure that all of our employees, third party service providers, divisions, affiliates and partners (including their employees and third party service providers) having access to your
      personal information are bound by appropriate and legally binding confidentiality obligations in relation to your personal information.</span>
      
      <span className="terms-item-child">8.	We will –</span>
      <ol style={{ listStyleType: "none" }}>
        <span className="terms-item-child"><li>a)	treat your personal information as strictly confidential, save where we are entitled to share it as set out in this policy;</li></span><br/>
        <span className="terms-item-child"><li>b)	take appropriate technical and organisational measures to ensure that your personal information is kept secure and is protected against unauthorised or unlawful processing, accidental loss,
          destruction or damage, alteration, disclosure or access;</li></span><br/>
        <span className="terms-item-child"><li>c)	provide you with access to your personal information to view and/or update personal details;</li></span><br/>
        <span className="terms-item-child"><li>d)	promptly notify you if we become aware of any unauthorised use, disclosure or processing of your personal information;</li></span><br/>
        <span className="terms-item-child"><li>e)	provide you with reasonable evidence of our compliance with our obligations under this policy on reasonable notice and request; and</li></span><br/>
        <span className="terms-item-child"><li>f)	upon your request, promptly return or destroy any and all of your personal information in our possession or control, save for that which we are legally obliged to retain.</li></span>
      </ol>

      <span className="terms-item-child">9.	We will not retain your personal information longer than the period for which it was originally needed, unless we are required by law to do so, or you consent to us retaining such information
      for a longer period.</span>

      <span className="terms-item-child">10.	Sasai Fintech undertakes never to sell your personal information or to make it available to any third party, other than as provided for in this policy.</span>
      
      <span className="terms-item-parent">11.	You have the right to request Sasai to provide you with a copy of your personal information; to correct or change your personal information; or to delete your personal information. Should you wish to 
      obtain, change, or delete your personal information kindly contact the Sasai call centre at support@sasai.global. Kindly note that in certain cases we are required by law to retain your personal information for a specific period, and we will 
      not be allowed to delete it. However, we will notify you of all such cases if you request us to delete your personal information.</span>
      
      <span className="terms-item-parent">12.	If you disclose your personal information to a third party, such as an entity which operates a website linked to this Website or anyone other than SASAI FINTECH, SASAI FINTECH SHALL NOT BE LIABLE FOR ANY
      LOSS OR DAMAGE, HOWSOEVER ARISING, SUFFERED BY YOU AS A RESULT OF THE DISCLOSURE OF SUCH INFORMATION TO THE THIRD PARTY. This is because we do not regulate or control how that third party uses your personal information. You should
      always ensure that you read the privacy policy of any third party.</span>

    </>
  )
}

  const UKPrivacyPolicy = (props) => {

    const onClickContactUs = () => {
      props.history.push({
        pathname: "/get-help"
      })
    }

    const onClickCookiePolicy = () => {
      props.history.push({
        pathname: "/cookie-policy"
      })
    }
  
    return (
      <>
        <h2 style={{ fontFamily: 'Lato' }}>Privacy Policy</h2>
              
        <span className="terms-item-child">Sasai Money Transfer takes the privacy of its users seriously. We are committed to safeguarding the privacy of our users while providing a personalised and valuable service. This Privacy Policy Statement explains 
        the data processing practices of Sasai Money Transfer (moneytransfer.sasai.global)</span>

        <h3 style={{ fontFamily: 'Lato' }}>Information collected</h3>

        <span className="terms-item-child">Personal information is collected by Sasai Money Transfer. We collect personal information from you through the use of enquiry and registration forms and every time you e-mail us your details.</span>
        
        <span className="terms-item-child">We also collect information automatically about your visit to our site. The information obtained in this way, which includes demographic data and browsing patterns, is only used in aggregate form.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Use and Disclosure of Personal Information</h3>
        
        <span className="terms-item-child">We process personal information collected via moneytransfer.sasai.global for the purposes of:</span>
        
        <ul style={{ listStyleType: "disc" }}>
          <li><span className="terms-item-child">To periodically Electronically verify your details with National and Credit agency databases</span></li>
          <li><span className="terms-item-child">Providing a personalised service</span></li>
          <li><span className="terms-item-child">Conducting market research surveys</span></li>
          <li><span className="terms-item-child">Running competitions</span></li>
          <li><span className="terms-item-child">Providing you with information about products and services we offer</span></li>
        </ul>

        <span className="terms-item-child">We may also disclose your information to business partners, governmental agencies and third-party suppliers we engage to provide services which involve processing data on our behalf or in accordance with a 
        properly executed court order.</span>

        <span className="terms-item-child">We also use information in aggregate form (This is to ensure that no individual user is identified.) This information is used to:</span>
        
        <ul style={{ listStyleType: "disc" }}>
          <li><span className="terms-item-child">Build up marketing profiles</span></li>
          <li><span className="terms-item-child">Aid strategic development</span></li>
          <li><span className="terms-item-child">Manage our relationship with advertisers</span></li>
          <li><span className="terms-item-child">Audit usage of the site</span></li>
        </ul>

        <h3 style={{ fontFamily: 'Lato' }}>Regulatory Obligations & Legitimate interest</h3>
        
        <span className="terms-item-child">As a Money Service Business (MSB), we are legally obliged to establish the identity of all our customers by adopting Know Your Customer (KYC) procedures to obtain personal data. Once these details are obtained, 
        we are legally obliged to keep them for a minimum of 5 years</span>

        <span className="terms-item-child">If you no longer maintain a legitimate interest to continue processing transactions with us after the 5 year period of your last transaction, we will remove your personal data from our system</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Use of cookies</h3>

        <span className="terms-item-child">We use "cookies" to identify you when you visit the moneytransfer.sasai.global website and to build up a demographic profile.</span>
        
        <span className="terms-item-child">A cookie is a small piece of information sent by a web server to a web browser, which enables the server to collect information back from the browser. Find out more about the use of cookies on <a href="https://en.wikipedia.org/wiki/HTTP_cookie">Wikipedia</a>.</span>

        <span className="terms-item-child">Our use of cookies also allows registered users to be presented with a personalised version of the site. See our <Button className="transaparent-button" onClick={onClickCookiePolicy}>cookie policy</Button>.</span>

        <h3 style={{ fontFamily: 'Lato' }}>Use of Web Beacons</h3>

        <span className="terms-item-child">Sasai Money Transfer web pages may contain electronic images known as Web beacons - sometimes called single-pixel gifs - that allow Sasai Money Transfer to count users who have visited those pages and to deliver co-branded services. 
        Web beacons are not used to access your personally identifiable information on the Sasai Money Transfer network of sites and services; they are a technique we use to compile aggregated statistics about Sasai Money Transfer website usage.</span>
        
        <span className="terms-item-child">Web beacons collect only a limited set of information including a cookie number, time and date of a page view, and a description of the page on which the Web beacon resides.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Internet-based transfers</h3>

        <span className="terms-item-child">Given that the Internet is a global environment, using the Internet to collect and process personal data necessarily involves the transmission of data on an international basis. Therefore, by browsing the 
        moneytransfer.sasai.global site and communicating electronically with us, you acknowledge and agree to our processing of personal data in this way.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Controlling information about you</h3>
        
        <span className="terms-item-child">In your profile on the Sasai Money Transfer website you will find the following opt-in:</span>
        
        <ul style={{ listStyleType: "disc" }}>
          <li><span className="terms-item-child">Opt-in to receive marketing communications from us</span></li>
          <li><span className="terms-item-child"></span></li>
        </ul>

        <span className="terms-item-child">If you have agreed that we can use your information for marketing purposes, you can change your mind easily, via one of these methods:</span>
        
        <ul style={{ listStyleType: "disc" }}>
          <li><span className="terms-item-child">Sign in to our website and change your opt-in settings.</span></li>
          <li><span className="terms-item-child">Send an email to support@sasaifintech.com</span></li>
          <li><span className="terms-item-child">Write to us at: Sasai Money Transfer, 17th Floor, 6 New Street Square, London EC4A 3BF</span></li>
        </ul>

        <span className="terms-item-child">We will never lease, distribute or sell your personal information to third parties unless we have your permission or the law requires us to. Any personal information we hold about you is stored and processed 
        under our data protection policy, in line with the General Data Protection Regulation.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Your Individual rights:</h3>

        <ul style={{ listStyleType: "disc" }}>
          <li><span className="terms-item-child">To be informed about how your personal data is stored and being used</span></li>
          <li><span className="terms-item-child">To be informed about what type of data we hold on you and how it is accessed</span></li>
          <li><span className="terms-item-child">To request for your personal data to be Rectified, amended or corrected</span></li>
          <li><span className="terms-item-child">To request for your personal data to be deleted or erased from our records</span></li>
          <li><span className="terms-item-child">To ask for your personal data be restricted if it is being held in violation of the law</span></li>
          <li><span className="terms-item-child">To be informed about the portability of your data explaining means by which your personal data is transferred</span></li>
          <li><span className="terms-item-child">To be informed about how we obtain this data</span></li>
          <li><span className="terms-item-child">To validate the authenticity of the personal data we have about you</span></li>
          <li><span className="terms-item-child">To request for your right to be forgotten by deleting personal data as well as any links that may replicate, copy or repeat personal data from public records. This request may be limited to Regulatory 
          Obligations as explained above</span></li>
          <li><span className="terms-item-child">Your right to notification whenever there is a breach</span></li>
          <li><span className="terms-item-child">The right to complain to the Data Protection Officer whenever you feel your personal data has been misused</span></li>
        </ul>
        
        <h3 style={{ fontFamily: 'Lato' }}>Security</h3>


        <span className="terms-item-child">We will always hold your information securely. To prevent unauthorised disclosure or access to your information, we have implemented strong physical and electronic security safeguards. We are PCI compliant 
        and have strong security measures and controls in place to protect systems and data. Regular system scans and penetration tests are conducted to ensure system security. We also follow stringent procedures to ensure we work with all personal 
        data in line with the General Data Protection Regulation.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Links from our site</h3>
        
        <span className="terms-item-child">Our website may contain links to other websites. If you provide information to a website to which we link, we are not responsible for its protection and privacy. Always be wary when submitting data to 
        websites. Read the site's data protection and privacy policies fully.</span>
        
        <h3 style={{ fontFamily: 'Lato' }}>Changes to this Statement</h3>

        <span className="terms-item-child">Sasai Money Transfer will occasionally update this Statement of Privacy to reflect company and customer feedback. If there are material changes to this statement or in how Sasai Money Transfer will use your personally 
        identifiable information, Sasai Money Transfer will prominently post such changes prior to implementing the change. Sasai Money Transfer encourages you to periodically review this statement in order to be informed of how Sasai Money Transfer is protecting your information.</span>

        <span className="term-item-child">If you have any requests concerning your personal information or any queries with regard to these practices, please <Button className="transaparent-button" onClick={onClickContactUs}>contact us.</Button></span>
      
        <span className="term-item-child">You have the right to request Sasai to provide you with a copy of your personal information; to correct or change your personal information; or to delete your personal information. Should you wish to obtain, change, or delete your personal information 
          kindly contact the Sasai call centre at support@sasai.global. Kindly note that in certain cases we are required by law to retain your personal information for a specific period, and we will not be allowed to delete it. However, we will notify you of all such cases 
          if you request us to delete your personal information.</span>
      </>
    )
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(PrivacyPolicy));

// export default withCookies(PrivacyPolicy)
