import React from 'react';
import { Layout } from 'antd';
import Log from '../../utils/log.js';
import { withCookies } from 'react-cookie';
import '../../styles/transaction.css'
import deadTree from '../../images/dead-tree.png';

const { Content } = Layout;
const TAG = "TransactionError";

class TransactionError extends React.Component {

  constructor(props) {
   super(props);
   Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
   this.state = {
     message: ""
   };
 }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    document.title = "Payment Error"
    window.scrollTo(0, 0)
    var errorMessage = "Invalid error message"
    if (this.props.location.search) {
      Log.d(TAG, "componentDidMount() -- has search message=" + this.props.location.search);
      errorMessage = this.props.location.search.substring(this.props.location.search.lastIndexOf("=") + 1, this.props.location.search.length);
    } else if (this.props.match.params.error) {
      Log.d(TAG, "componentDidMount() -- has error message=" + this.props.match.params.error);
      errorMessage = this.props.match.params.error
    } else {

    }
    window.eftError(errorMessage);
    this.setState({
      message: errorMessage
    })

    setTimeout(() => {
      this.props.history.push({
        pathname: "/",
      });
    }, 10000);
  }

  render() {
    return (
      <Layout style={{ 'height': '100vh' }}>
        <Content className="tr-item-content-container">
          {this.renderTransactionError()}
        </Content>
      </Layout>
    )
  }

  renderTransactionError() {
    if (this.state.message) {
      return (
        <div className="tr-error-parent">
          <img className="tr-error-tree" src={deadTree} alt="error"/>
          <h2 className="tr-error-title">Dead Tree Found</h2>
          <h4 className="tr-error">It seems like an error appeared. We will redirect you to homepage in 10 seconds.</h4>
          <h2 className="tr-error">{decodeURI(this.state.message)}</h2>
        </div>
      )
    } else {
      return (<div/>)
    }
  }
}

export default withCookies(TransactionError)
