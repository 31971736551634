import {
  Card,
  Form,
  Layout,
  Dropdown,
  Icon,
  Button,
  Menu,
  message,
} from "antd";
import React from "react";
import "../styles/qoute.css";
import Log from "../utils/log";
import {
  requestProductsAndServicesWithFilter,
  requestAirtimeDataProducts,
  requestProductDetails,
  showAirtimeDataProductDetails,
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "GUEST SELECT PRODUCT OPTION";

class GuestSelectProductOption extends React.Component {
  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedSelectProductOptionForm {...this.props} />
        </Content>
      </Layout>
    );
  }
}

class ProductOptionForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(
      TAG,
      "Product Options::constructor() -- props=" + JSON.stringify(props)
    );

    let sendingCountry = {};
    let sender = {};
    let recipient = {};
    let receivingCountries = [];
    let receivingCountry = {};

    if (props.location != null) {
      if (props.location.state != null) {
        sendingCountry = props.location.state.sendingCountry;
        sender = props.location.state.sender;
        recipient = props.location.state.recipient;
        receivingCountries = props.location.state.receivingCountries;
        receivingCountry = props.location.state.receivingCountry;
      }
    }

    this.state = {
      airtimeDataOptions: [],
      recipient: recipient,
      sender: sender,
      receivingCountries: receivingCountries,
      sendingCountry: sendingCountry,
      receivingCountry: receivingCountry,
      selectedProductOption: null,
      products: this.props.airtimeDataProductReducer.products,
      loading: true,
    };
  }

  componentDidMount() {

    let { beneficiary_mobile } = this.state.recipient;
    this.props.requestAirtimeDataProducts(
      beneficiary_mobile[0] + "" + beneficiary_mobile[1],
    );

    let sendingCountry = this.state.sendingCountry.attributes.alpha_2_code;
    let receivingCountry =
      this.state.receivingCountry.attributes.alpha_2_code;
    let filter = "?filter[service_type]=AD&include=group";
    this.props.requestProductsAndServicesWithFilter(
      receivingCountry,
      sendingCountry,
      filter
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.airtimeDataProductReducer.products !==
      this.props.airtimeDataProductReducer.products
    ) {
      let airtimeDataOptions = this.props.airtimeDataProductReducer.products;
      this.setState({
        airtimeDataOptions,
      });
    }

    if (
      prevProps.airtimeDataProductReducer.loading !==
      this.props.airtimeDataProductReducer.loading
    ) {
      const loading = this.props.airtimeDataProductReducer.loading;
      this.setState({
        loading,
      });
    }

    if (
      prevProps.airtimeDataProductReducer.error !==
      this.props.airtimeDataProductReducer.error
    ) {
      const errorMessage = this.props.airtimeDataProductReducer.error;
      if (errorMessage) {
        const confirmResponse = window.confirm(errorMessage);
        if (confirmResponse) {

          // this.props.history.push({
          //   pathname: `edit-airtime-recipient/${recipientID}`,
          //   state: {
          //     recipient: this.props.recipientReducer.recipient,
          //     sendingCountry: this.props.countryReducer.sendingCountry,
          //     receivingCountries:
          //       this.props.countryReducer.sendingCountry.receivingCountries,
          //     receivingCountry: this.state.receivingCountry,
          //   },
          // });
        } else {
          this.props.history.push("/send-airtime-data");
        }
      }
    }

    if (
      this.props.userReducer.error &&
      Object.keys(this.props.userReducer.error).length > 0
    ) {
      message.error(this.props.userReducer.error);
    } else if (
      prevProps.productServiceReducer.products !==
      this.props.productServiceReducer.products
    ) {
      let products = this.props.productServiceReducer.products;
      this.setState({
        products,
      });
    }

    // redirect user if product global state has been Updated
    if (
      prevProps.productServiceReducer.product !==
      this.props.productServiceReducer.product &&
      Object.keys(this.props.productServiceReducer.product).length > 0
    ) {
      var included = this.props.productServiceReducer.product.included;
      var paymentMethods = [];

      for (var i = 0; i < included.length; i++) {
        var includedObj = included[i];
        if (includedObj.type === "payment_methods") {
          paymentMethods.push(includedObj);
        }
      }

      const airtimeDataProduct =
        this.props.airtimeDataProductReducer.products[
        this.state.selectedProductOption
        ];
      this.props.showAirtimeDataProductDetails(airtimeDataProduct);
      this.props.history.push({
        pathname: "guest-airtime-data-payment",
        state: {
          airtimeDataProduct,
          receivingCountry: this.state.receivingCountry,
          sendingCountry: this.state.sendingCountry,
          recipient: this.state.recipient,
          sender: this.state.sender,
          paymentMethods,
          transaction: {},
          product: this.props.productServiceReducer.product,
        },
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content style={{ width: "100%" }}>
        <Form
          // onSubmit={this.handleSubmit.bind(this)}
          hideRequiredMark={true}
        >
          <div className="row">
            <div className="col-sm-9">
              <Card
                style={{ fontFamily: "Lato", borderRadius: "4px" }}
                title={`Select airtime or data`}
              >
                {this.renderBuyAirtimeDataForm(getFieldDecorator)}
              </Card>
            </div>
          </div>
        </Form>
      </Content>
    );
  }

  renderBuyAirtimeDataForm(getFieldDecorator) {
    return (
      <div className="home-item" style={{ alignItems: "flex-start" }}>
        <FormItem label={"Airtime/ Data bundle"} className="quote-form--1">
          {getFieldDecorator("product_option", {
            rules: [{ required: true, message: "Please select a product" }],
          })(
            <Dropdown overlay={this.renderOptionsParent()} trigger={["click"]}>
              <div className="dropdown-item">
                {this.renderOptionsDropdown()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        <div style={{ width: "100%", textAlign: "center" }}>
          {!this.state.selectedProductOption &&
            (this.state.loading
              ? "Please wait, loading products.."
              : "Please select a product")}
        </div>
        {this.renderProductOptionDetails()}
        {this.renderFormActions()}
      </div>
    );
  }

  onChangeOption = (event) => {
    this.setState({
      selectedProductOption: event.key,
      sendingAmount:
        this.props.airtimeDataProductReducer.products[event.key].attributes
          .amount,
    });
    this.props.form.setFieldsValue({
      product_option: event.key,
    });
  };

  renderOptionsDropdown() {
    let product = this.state.selectedProductOption
      ? this.props.airtimeDataProductReducer.products[
      this.state.selectedProductOption
      ]
      : null;
    return (
      <div>
        {product
          ? `${product.attributes.name} | ${product.relationships.currency.data.letter_code}${product.attributes.amount}`
          : "Select Product"}
      </div>
    );
  }

  renderProductOptionDetails() {
    let product = this.state.selectedProductOption
      ? this.props.airtimeDataProductReducer.products[
      this.state.selectedProductOption
      ]
      : null;

    return (
      product && (
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: 16,
            marginTop: 16,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6>Details:</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p>Amount to pay</p>
            <p>
              {product.relationships.currency.data.letter_code}
              {product.attributes.amount}
            </p>
          </div>
          <div>
            <p>{product.attributes.name}</p>
          </div>
        </div>
      )
    );
  }

  renderOptionsParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeOption}
      >
        {this.renderOptions()}
      </Menu>
    );
  }

  renderOptions() {
    return this.state.airtimeDataOptions.map((item, index) => {
      return (
        <Menu.Item
          key={index}
        >{`${item.attributes.name} | ${item.relationships.currency.data.letter_code}${item.attributes.amount}`}</Menu.Item>
      );
    });
  }

  onChangeSendingAmount = (e) => {
    let amount = e.target.value;
    this.setState({ sendingAmount: amount }, () => {
      this.props.form.setFieldsValue({
        sending_amount: amount,
      });
    });
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let sendingCountry = this.state.sendingCountry.attributes.alpha_2_code;
        let receivingCountry = this.state.receivingCountry.attributes.alpha_2_code;
        let productId = this.state.products[0].id;
        this.props.requestProductDetails(
          productId,
          null,
          receivingCountry,
          sendingCountry
        );
      }
    });
  };

  renderFormActions = () => {
    return (
      <div style={{ width: "100%" }}>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                color: "#154989",
                margin: "8px",
              }}
              onClick={() => this.props.history.goBack()}
            >
              Back
            </Button>
          </div>
          <FormItem className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "#154989",
                boxSizing: "border-box",
                color: "white",
                margin: "8px",
              }}
              onClick={this.handleSubmit}
            >
              Next
            </Button>
          </FormItem>
        </div>
      </div>
    );
  };
}

const WrappedSelectProductOptionForm = Form.create()(ProductOptionForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestAirtimeDataProducts,
      requestProductsAndServicesWithFilter,
      requestProductDetails,
      showAirtimeDataProductDetails,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestSelectProductOption);
