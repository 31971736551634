import React from "react";
import { Form, Input, Menu, Button, Dropdown, Icon, Checkbox } from "antd";
import { Layout } from "antd";
import Log from "../utils/log.js";
import "../styles/recipient.css";

import { manageRecipient } from "../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartAddAirtimeRecipient";

class AddAirtimeRecipient extends React.Component {
  render() {
    return (
      <Content className="ar-content-container">
        <WrappedAddAirtimeRecipientForm {...this.props} />
      </Content>
    );
  }
}

class AddAirtimeRecipientForm extends React.Component {
  constructor(props) {
    super(props);

    const countries = this.props.countryReducer.airtimeDataReceivingCountries;

    this.state = {
      receivingCountries: countries,
      receivingCountry: countries[0],
    };
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div class="ru-box">
        <Form className="ar-body" onSubmit={this.handleSubmit}>
          <h2 className="ar-add-recipient">Add Recipient</h2>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              {this.renderRecievingCountry(getFieldDecorator)}
              {this.renderFirstName(getFieldDecorator)}
              {this.renderLastName(getFieldDecorator)}
              {this.renderPhoneInput(getFieldDecorator)}
              <div style={{ width: "100%", textAlign: "left" }}>
                <FormItem>
                  {getFieldDecorator("confirm details", {
                    rules: [
                      {
                        required: true,
                        transform: (value) => value || undefined, // Those two lines
                        type: "boolean",
                        message:
                          "Please confirm if the above details are correct",
                      },
                    ],
                  })(
                    <Checkbox>
                      <b>
                        I hereby confirm that the information provided herein is
                        accurate
                      </b>
                    </Checkbox>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/send-airtime-data",
                      });
                    }}
                  >
                    Back
                  </Button>
                </div>
                <FormItem className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </FormItem>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  renderCountries() {
    if (!!this.state) {
      return this.state.receivingCountries.map((itemAr, index) => {
        return <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>;
      });
    } else {
      return <div />;
    }
  }

  onChangeCountry = (event) => {
    this.setState({
      receivingCountry: this.state.receivingCountries[event.key],
    });
    this.props.form.setFieldsValue({
      "receiving-country": event.key,
    });
    this.props.form.setFieldsValue({
      phonePrefix:
        this.state.receivingCountries[event.key].attributes.phone_prefix,
    });
  };

  renderCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    );
  }

  renderReceivingCountry() {
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return <div>{this.state.receivingCountry.attributes.name}</div>;
    } else {
      return <div />;
    }
  }

  renderRecievingCountry(getFieldDecorator) {
    return (
      <FormItem label="Country">
        {getFieldDecorator("receiving-country", {
          rules: [
            {
              required: true,
              message: "Please select airtime receiving country",
            },
          ],
          initialValue: 0,
        })(
          <Dropdown trigger={["click"]} overlay={this.renderCountriesParent()}>
            <div className="dropdown-item">
              {this.renderReceivingCountry()}
              <Icon type="down" />
            </div>
          </Dropdown>
        )}
      </FormItem>
    );
  }

  renderFirstName(getFieldDecorator) {
    return (
      <FormItem label="First Name">
        {getFieldDecorator("first-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient first name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderLastName(getFieldDecorator) {
    return (
      <FormItem label="Last Name">
        {getFieldDecorator("last-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient last name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderPhonePrefix() {
    const phonePrefix = this.state.receivingCountry.attributes.phone_prefix;
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return <div style={{ width: 45 }}>+{phonePrefix}</div>;
    } else {
      return <div />;
    }
  }

  renderPhoneInput(getFieldDecorator) {
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: this.state.receivingCountry.attributes.phone_prefix,
    })(this.renderPhonePrefix());

    const phoneNumber = "";

    const label = "Mobile Number";

    return (
      <FormItem label={label}>
        {getFieldDecorator("mobile-number", {
          rules: [
            // { validator: this.validateBSField },
            {
              required: true,
              message: "Please enter recipient mobile number",
            },
          ],
          initialValue: phoneNumber,
        })(
          <Input
            addonBefore={phonePrefix}
            className="mobile-number"
            maxlength="20"
          />
        )}
      </FormItem>
    );
  }

  prepareRequest(values) {
    const receivingCountryId =
      this.state.receivingCountries[values["receiving-country"]];

    const requestBody = {
      data: {
        attributes: {
          airtime_msisdn: [values["phonePrefix"], values["mobile-number"]],
          beneficiary_type: "AT",
          first_name: values["first-name"],
          last_name: values["last-name"],
        },
        id: null,
        relationships: {
          country: {
            data: {
              id: receivingCountryId.id,
              type: "countries",
            },
          },
        },
        type: "beneficiaries",
      },
    };

    return requestBody;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const requestBody = this.prepareRequest(values);
        this.props.manageRecipient(
          requestBody,
          null,
          this.props.authReducer.accessToken
        );
      }
    });
  };
}

const WrappedAddAirtimeRecipientForm = Form.create()(AddAirtimeRecipientForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      manageRecipient,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAirtimeRecipient);
