import baseUrlOptions from "./base_url_options"
import LocalStorageService from "./local_storage_service"
import UrlMapper from "./url_mapper"

class ManageUrls {

    mapper = new UrlMapper()

    baseCountryIsSA() {
        return this.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA
    }

    // returns all of the url values as an object
    // { baseCountry, apiUrl, authUrl, uploadUrl }
    getUrlValues() {

        return this.mapper.getUrlValues()
    }

    // returns base country as a string
    getBaseCountry() {
        const values = this.mapper.getUrlValues()

        return values.baseCountry
    }

    // returns api url as a string
    getApiUrl() {
        const values = this.mapper.getUrlValues()

        return values.apiUrl
    }

     // returns api url as a string
     getBaseApiUrl() {
        const values = this.mapper.getUrlValues()

        return values.baseApiUrl
    }

    // returns auth url as a string
    getAuthUrl() {
        const values = this.mapper.getUrlValues()

        return values.authUrl
    }

    // returns upload url as a string
    getUploadUrl() {
        const values = this.mapper.getUrlValues()
        return values.uploadUrl
    }

    // returns proxy url as a string
    getProxyUrl() {
        const values = this.mapper.getUrlValues()
        return values.proxyUrl
    }

    // sets base country
    setBaseCountry(country) {
        const storage = new LocalStorageService()

        storage.setBaseCountry(country)
    }

    getSAUrlValues() {
        return this.mapper.getSAValues()
    }

    getUKValues() {
        return this.mapper.getUKValues()
    }
    
}

export default ManageUrls
