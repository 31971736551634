import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Log from '../../utils/log.js';
import SmartPersonalDetails from '../../containers/PersonalDetails.js'
import '../../styles/signup.css'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


const { Header, Footer, Content } = Layout;
const TAG = "DumbPersonalDetails";


class PersonalDetails extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props1=" + JSON.stringify(props));
  }

  render() {
    return (

      <div className="wrapper">
        <Layout className = "su-layout">
          <Header className="custom-header" style={{ 'width': '100%', 'zIndex': '1'}}>
            <CustomHeader {...this.props} />
          </Header>
          <Content className="su-content-container">
            <SmartPersonalDetails {...this.props} />
          </Content>
          <Footer className="custom-footer">
            <CustomFooter/>
          </Footer>
        </Layout>
      </div>

    )
  }

  componentWillMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    // this.checkIfHasUser();
    document.title = "Sign Up"
    window.scrollTo(0, 0)
  }

  // checkIfHasUser() {
  //   // if (cookies.get('access')) {
  //   if (!!this.props.authReducer.accessToken) {
  //     Log.d(TAG, "checkIfHasUser() -- has user");
  //     this.props.history.push({
  //       pathname: "/"
  //     });
  //   } else {
  //     Log.d(TAG, "checkIfHasUser() -- has no user");
  //   }
  // }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(PersonalDetails));

