import React from "react";
import { Form, Input, Button, Card, message } from "antd";
import { Layout } from "antd";
import Log from "../utils/log.js";
import "../styles/recipient.css";

import { requestGuestUserTokens, requestOTPCode } from "../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartAirtimeOTP";

class AirtimeOTP extends React.Component {
  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedAirtimeOTPForm {...this.props} />
        </Content>
      </Layout>
    );
  }
}

class AirtimeOTPForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
    }

  }

  componentDidUpdate(prevProps) {

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("" + this.props.userReducer.error, 5);
    }

    if (!!this.props.authReducer.guestUserAccessToken) {
      if (this.props.location.state.requestRecipientList) {
        this.props.history.push({
          pathname: "/guest-recipient-list",
          state: {
            sender: this.props.location.state.sender,
            sendingCountry: this.props.location.state.sendingCountry,
          },
        });
      }

      if (this.props.location.state.requestTransactionHistory) {
        this.props.history.push({
          pathname: "/guest-transactions",
          state: {
            sender: this.props.location.state.sender,
            sendingCountry: this.props.location.state.sendingCountry,
          },
        });
      }

      if (this.props.location.state.saveRecipient) {
        // API CALL FOR SAVING RECEPIENT
        this.props.history.push({
          pathname: 'guest-select-product-option',
          state: {
            sender: this.props.location.state.sender,
            sendingCountry: this.props.location.state.sendingCountry,

            receivingCountries: this.props.location.state.receivingCountries,
            receivingCountry: this.props.location.state.receivingCountry,
            recipient: this.props.location.state.recipient,
          }
        });
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { username } =
      this.props.location.state.otpSender;

    const mobile = username.split(" ");

    const countryCode = mobile[0];
    const mobileNumber = mobile[1];

    let message = '';

    if (this.props.location.state.requestTransactionHistory) {
      message = `To access your Airtime and Data Purchase History`;
    }
    if (this.props.location.state.saveRecipient) {
      message = `To Save your beneficiary details`;
    }
    if (this.props.location.state.requestRecipientList) {
      message = `To access your Saved Recipients`;
    }

    message = `${message}, please enter the One Time Password sent
    to + ${countryCode}${mobileNumber} in the next 25 seconds.`;

    return (
      <Content style={{ width: "100%" }}>
        <Form onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="row">
            <div className="col-sm-9">
              <Card
                style={{ fontFamily: "Lato", borderRadius: "4px" }}
                title={`One Time Password (OTP)`}
              >
                <p
                  className="quote-form--1"
                  style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  {message}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="col-8">
                    <FormItem>
                      {getFieldDecorator(
                        "otp",
                        {
                          rules: [
                            {
                              required: true,
                              message: "Please enter OTP code",
                            }
                          ],
                        },
                        { initialValue: this.state.password }
                      )(
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Enter OTP code"
                          height="46px"
                          maxLength={4}
                        />
                      )}
                    </FormItem>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="form-group">
                    <Button
                      className="home-collect"
                      style={{
                        backgroundColor: "white",
                        boxSizing: "border-box",
                        color: "#154989",
                        margin: "8px",
                      }}
                      onClick={() => {
                        const sender = this.props.location.state.sender;
                        this.props.requestOTPCode(sender);
                      }}
                    >
                      Resend OTP
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="form-group">
                    <Button
                      className="home-collect"
                      style={{
                        backgroundColor: "white",
                        boxSizing: "border-box",
                        color: "#154989",
                        margin: "4px",
                      }}
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                    >
                      Back
                    </Button>
                  </div>
                  <FormItem className="form-group">
                    <Button
                      className="home-collect"
                      style={{
                        backgroundColor: "#154989",
                        boxSizing: "border-box",
                        color: "white",
                        margin: "4px",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </FormItem>
                </div>
              </Card>
            </div>
          </div>
        </Form>
      </Content>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { otp } = values;
        const payload = { password: otp, ...this.props.location.state.otpSender };
        this.props.requestGuestUserTokens(payload);
      }
    });
  };
}

const WrappedAirtimeOTPForm = Form.create()(AirtimeOTPForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestGuestUserTokens,
      requestOTPCode,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(AirtimeOTP);
