import React from "react";
import { Layout } from 'antd';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import {
  fetchUserDetails,
  requestReceivingCurrency,
  requestSendingCurrency,
  requestTransactionDetails,
  showSendingCurrency,
  showReceivingCurrency,
  requestCalculationDetails
} from '../actions';
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import logo from "../images/sasai-money-transfer-logo.png";

const { Content } = Layout;
const TAG = "SmartPrintForm";

class PrintForm extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    this.state = {
      sourceOfFunds: [ ["SAL", "Salary"], ["SV", "Savings"], ["GF", "Gift"], ["PN", "Pension"]],
    }
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- props=" + JSON.stringify(this.props))

    if (this.props.match.params.token) {
      this.props.fetchUserDetails(this.props.cookies, this.props.match.params.token, false, null);
    }

    if (this.props.match.params.type) {
      if (this.props.match.params.type === "invoice") {
        if (this.props.match.params.transactionID) {
            this.props.requestTransactionDetails(this.props.match.params.transactionID, this.props.match.params.token)
        }
      }
    }

    if (this.props.match.params.calculationID) {
        this.props.requestCalculationDetails(this.props.match.params.calculationID, this.props.match.params.token)
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "PrintForm::componentDidUpdate() -- called ..")
    Log.d(TAG, "PrintForm::componentDidUpdate() -- props=" + JSON.stringify(this.props))

    if (prevProps.currencyReducer.sendingCurrency !== this.props.currencyReducer.sendingCurrency) {
      this.setState({
        sendingCurrency: this.props.currencyReducer.sendingCurrency.data
      })
    }

    if (prevProps.currencyReducer.receivingCurrency !== this.props.currencyReducer.receivingCurrency) {
      this.setState({
        receivingCurrency: this.props.currencyReducer.receivingCurrency.data
      })
    }

    if (Object.keys(this.props.calculationReducer.calculation).length > 0 && this.props.calculationReducer.calculation !== prevProps.calculationReducer.calculation) {
      Log.d(TAG, "PrintForm::componentDidUpdate() -- calculation=" + JSON.stringify(this.props.calculationReducer.calculation))
      this.props.requestSendingCurrency(this.props.calculationReducer.calculation.data.relationships.sending_currency.data.id);
      this.props.requestReceivingCurrency(this.props.calculationReducer.calculation.data.relationships.receiving_currency.data.id);
    }
  }

  render() {
    return (
      <Content className="print-form-content-container">
        {this.renderCheckingContent()}
      </Content>
    )
  }

  renderCheckingContent() {
      if (this.props.match.params.type) {

        Log.d(TAG, "renderCheckingContent() -- user=" + JSON.stringify(this.props.userReducer.user))
        Log.d(TAG, "renderCheckingContent() -- sc=" + JSON.stringify(this.props.currencyReducer.sendingCurrency))
        Log.d(TAG, "renderCheckingContent() -- rc=" + JSON.stringify(this.props.currencyReducer.receivingCurrency))
        if (Object.keys(this.props.userReducer.user).length > 0 &&
            Object.keys(this.props.currencyReducer.sendingCurrency).length > 0 &&
            Object.keys(this.props.currencyReducer.receivingCurrency).length > 0 &&
            Object.keys(this.props.calculationReducer.calculation).length > 0) {

            var sendingCurrencyObj = this.props.currencyReducer.sendingCurrency;
            var receivingCurrencyObj = this.props.currencyReducer.receivingCurrency;
            if (this.props.currencyReducer.receivingCurrency.data) {
              receivingCurrencyObj = this.props.currencyReducer.receivingCurrency.data
            }
            if (this.props.currencyReducer.sendingCurrency.data) {
              sendingCurrencyObj = this.props.currencyReducer.sendingCurrency.data
            }
          return (this.renderForm(sendingCurrencyObj, receivingCurrencyObj))
        }

      }

      return (this.renderNoContent())
  }

  renderNoContent() {
    return (
      <div style={{ textAlign : 'center' }}>
        <span>Invalid form</span>
      </div>
    )
  }

  renderForm(sendingCurrency, receivingCurrency) {

    return (
      <div id="capture" style={{ display: 'flex', flexDirection: 'column', padding: '5%', fontVariant: 'normal'}}>
        <img style={{ width: '270px', marginBottom: '20px', objectFit: 'contain' }} src={logo} alt="Ecocash Logo" />
        {this.renderFormType()}
        <b  style={{fontFamily: 'Lato'}}>Transaction Details</b>
        {this.renderTransactionDetails()}
        <span style={{ fontSize: '10px'}}>Amount to pay: {this.props.calculationReducer.calculation.data.attributes.amount_to_pay} {sendingCurrency.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Beneficiary receives: {this.props.calculationReducer.calculation.data.attributes.recipient_amount} {receivingCurrency.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Sending currency: {sendingCurrency.attributes.name}</span>
        <span style={{ fontSize: '10px'}}>Receiving currency: {receivingCurrency.attributes.name}</span>
        <span style={{ fontSize: '10px'}}>Rate: 1 {receivingCurrency.attributes.letter_code} = {Number(this.props.calculationReducer.calculation.data.attributes.reverse_rate).toLocaleString()} {sendingCurrency.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Fees: {this.props.calculationReducer.calculation.data.attributes.fees} {sendingCurrency.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>VAT: {this.props.calculationReducer.calculation.data.attributes.vat} {sendingCurrency.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>BoP Category: {this.props.match.params.reason}</span>
        <span style={{ fontSize: '10px'}}>Reason: {this.props.match.params.reasonName}</span>
        {this.renderSourceName()}
        {this.renderPickupPoint()}
        <span style={{ fontSize: '10px'}}>Payment method: {this.props.match.params.paymentMethodName}</span>
        {this.renderRecipient()}
        <b style={{ marginTop: '16px', fontFamily: 'Lato' }}>Sender Details</b>
        <span style={{ fontSize: '10px'}}>Full name: {this.props.userReducer.user.data.attributes.first_name} {this.props.userReducer.user.data.attributes.last_name}</span>
        <span style={{ fontSize: '10px'}}>User code: {this.props.userReducer.user.data.attributes.code}</span>
        <span style={{ fontSize: '10px'}}>Country: {this.props.match.params.sendingCountryName}</span>
        <span style={{ fontSize: '10px'}}>Postcode: {this.props.userReducer.user.data.attributes.postcode}</span>
        <span style={{ fontSize: '10px'}}>Address line 1: {this.props.userReducer.user.data.attributes.address_line_1}</span>
        <span style={{ fontSize: '10px'}}>Address line 2: {this.props.userReducer.user.data.attributes.address_line_2}</span>
        <span style={{ fontSize: '10px'}}>City: {this.props.userReducer.user.data.attributes.city}</span>
        <span style={{ fontSize: '10px'}}>Province: {this.props.userReducer.user.data.attributes.county}</span>
        <b style={{ marginTop: '20px', fontFamily: 'Lato'}}>Declaration</b>
        {this.renderDeclarationContent()}
        <span style={{ marginTop: '20px', textAlign: 'center', fontSize: '10px'}}>Sasai Fintech (PTY) LTD, Worcestor House, Eton Office Park, Corner Sloane and Harrison Avenue, Bryanston, Johannesburg, South Africa</span>
        <span style={{ textAlign: 'center', fontSize: '10px'}}>Ph: +27 87 238 0251 | Ph: +27 11 996 5500 Call Centre: +27 10 120 0800</span>
        <span style={{ textAlign: 'center', fontSize: '10px'}}>Sasai Money Transfer is issued by Sasai Fintech (PTY) LTD. Sasai Fintech (PTY) LTD is regulated by the South African Reserve Bank (SARB).</span>
      </div>
    )
  }

  renderRecipient() {
    Log.d(TAG, "renderRecipient() -- called ..");
    Log.d(TAG, "renderRecipient() -- receivingCountryName=" + this.props.match.params.receivingCountryName);
    if ((this.props.match.params.beneficiaryName !== "" + this.props.userReducer.user.data.attributes.first_name + " " + this.props.userReducer.user.data.attributes.last_name) && (this.props.match.params.beneficiaryName !== "Not Available")) {
      Log.d(TAG, "renderRecipient() -- show recipient");
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <b style={{ marginTop: '16px', fontFamily: 'Lato' }}>Recipient Details</b>
          <span style={{ fontSize: '10px'}}>Full name: {this.props.match.params.beneficiaryName !== "NA" ? this.props.match.params.beneficiaryName : "Not Available"}</span>
          <span style={{ fontSize: '10px'}}>Country: {this.props.match.params.receivingCountryName !== "NA" ? this.props.match.params.receivingCountryName : "Not Available"}</span>
        </div>
      )
    }
    return (<div/>)
  }

  renderSourceName(){
    return (<span style={{ fontSize: '10px'}}>Source: {this.renderSource(this.props.match.params.source)}</span>)
  }

  renderSource(source) {
    this.state.sourceOfFunds.filter((obj) => {
      if (obj[0] === source) {
        source = obj[1];
      }
      return obj;
    })
    return source
  }

  renderPickupPoint() {
    return (<span/>)
  }

  renderFormType() {
    if (this.props.match.params.type === "invoice") {
      return (<h3 id="form-title" style={{ marginBottom: '20px', fontFamily: 'Lato'}}>Tax Invoice</h3>)
    }
    return (<h3 id="form-title" style={{ marginBottom: '20px', fontFamily: 'Lato'}}>Integrated Form</h3>)
  }

  renderTransactionDetails() {
    if (Object.keys(this.props.transactionReducer.transaction).length > 0) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <span style={{ fontSize: '10px'}}>VAT number: 4230279525</span>
          <span style={{ fontSize: '10px'}}>Transaction reference: {this.props.transactionReducer.transaction.data.attributes.code}</span>
          <span style={{ fontSize: '10px'}}>Transaction status: {Helper.showStatus(this.props.transactionReducer.transaction.data.attributes.status)} at {this.props.transactionReducer.transaction.data.attributes.status_date}</span>
          <span style={{ fontSize: '10px'}}>Transaction log date: {this.props.transactionReducer.transaction.data.attributes.log_date}</span>
        </div>
      )
    }
    return <span/>
  }

  renderDeclarationContent() {
    if (this.props.match.params.type === "invoice") {
      return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <p style={{ fontSize: '10px'}}>In terms of Exchange Control Regulation 2(4) the Foreign Exchange provided may only be used for purpose for which it was made available.</p>
          <label>
            <input type="checkbox" checked={true}/>
            &nbsp;&nbsp;<span style={{ fontSize: '13px'}}>I agree with the declaration above</span>
          </label>
          <span style={{ marginTop: '40px', fontSize: '10px', paddingLeft: '0'}}>Signature:  <span style={{ 'border': '0', 'borderBottom': '1px solid #000', 'paddingLeft': '8px', 'paddingRight' : '40px'}}>{this.props.userReducer.user.data.attributes.first_name} {this.props.userReducer.user.data.attributes.last_name}</span></span>
          <span style={{ marginTop: '8px', fontSize: '10px'}}>Date: {moment().format("YYYY-MM-DD hh:mm:ss a")}</span>
          <p style={{ marginTop: '20px', fontSize: '10px'}}>Please ensure that you retain this receipt for five years as proof of your currency and the proceeds of any foreign currency obtained through this transaction may not be placed at the disposal of third parties normally resident in South Africa..</p>
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <p style={{ fontSize: '10px'}}>I, hereby declare that:</p>
          <ul style={{ fontSize: '10px'}}>
            <li>I have read this document and know and understand the contents thereof;</li>
            <li>the information furnished above is in all respects both true and correct;</li>
            <li>the currency applied for will only be used for the specific purpose stated herein;</li>
            <li>the documentation presented in support of this application is in all respects authentic;</li>
            <li>I have been informed of the limit applicable to the above transaction and confirm that this limit will not be exceeded as a result of the conclusion of this transaction and;</li>
            <li>I consent to this information being provided to the South African Revenue Service and/or the Financial Intelligence Centre</li>
          </ul>
          <label>
            <input type="checkbox" checked={true}/>
            &nbsp;&nbsp;<span style={{ fontSize: '13px'}}>I agree with the declaration above</span>
          </label>
          <span style={{ marginTop: '40px', fontSize: '10px', paddingLeft: '0'}}>Signature:  <span style={{ 'border': '0', 'borderBottom': '1px solid #000', 'paddingLeft': '8px', 'paddingRight' : '40px'}}>{this.props.userReducer.user.data.attributes.first_name} {this.props.userReducer.user.data.attributes.last_name}</span></span>
          <span style={{ marginTop: '8px', fontSize: '10px'}}>Date: {moment().format("YYYY-MM-DD hh:mm:ss a")}</span>
        </div>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestReceivingCurrency,
    requestSendingCurrency,
    requestTransactionDetails,
    showSendingCurrency,
    showReceivingCurrency,
    requestCalculationDetails
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintForm)
