import React from 'react';
import {  message } from 'antd';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import IdleTimer from 'react-idle-timer'
import 'rc-drawer-menu/assets/index.css';
import '../styles/header.css'
import Log from '../utils/log.js';
import ServerData from '../utils/server_data.js';
import SmartHeader from '../containers/Header.js'
import RememberMe from '../endpoints/remember_me';
import { removeAccessRefreshTokens } from '../actions';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const INACTIVITY_MINUTE = 5;
const INACTIVITY_TIME = 60 * 1000 * INACTIVITY_MINUTE;
const TAG = "DumbHeader";

class Header extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props))

    var pathName = "";

    if (props.location != null) {
      if (props.location.state != null) {
        pathName = props.location.pathname
      }
    }

    this.state = {
      pathName: pathName,
      user: {}
    };
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- called ..");
    const { cookies } = this.props;
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "componentDidMount() -- has user");
      // ServerData.requestUserDetails(cookies, cookies.get('access'), false, (error, userDetails) => {
      ServerData.requestUserDetails(cookies, this.props.authReducer.accessToken, false, (error, userDetails) => {
        if (error) {
          Log.d(TAG, "componentDidMount() -- error=" + error);
          var errorString = "" + error
          message.error(errorString, 5)
        } else {
          Log.d(TAG, "componentDidMount() -- response data=" + JSON.stringify(userDetails));
          this.setState({
            user: userDetails
          })
        }
      })
    } else {
      Log.d(TAG, "componentDidMount() -- has no user");
    }
  }

  onIdleChange = () => {
    if (
        !!this.props.authReducer.accessToken && 
        this.state.pathName !== "/login" &&
        !RememberMe.value
      ) {
      this.onLogout("Your session expired: Sessions expire in 5 minutes of inactivity");
    }
  }

  render() {
    return (
      <IdleTimer
        timeout={INACTIVITY_TIME}
        idleAction={this.onIdleChange}>
        <SmartHeader {... this.props}/>
      </IdleTimer>
    )
  }

  onLogout(message){
    Log.d(TAG, "onLogout() -- called ..");
    Log.d(TAG, "onLogout() -- message=" + message);
    const { cookies } = this.props;
    ServerData.logOut(
      cookies,
      this.props.authReducer.accessToken,
      true,
      (error) => {
        this.props.removeAccessRefreshTokens();
        if (error) {
          Log.d(TAG, "onLogout() -- error=" + error);
        }
      }
      );
      // cookies.remove('access')
      // cookies.remove('refresh')
      cookies.remove('first_name')
      cookies.remove('last_name')
      this.props.history.push({
      pathname: "/login",
      state: { message : message }
    });
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      removeAccessRefreshTokens,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

// export default withCookies(Header)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Header));
