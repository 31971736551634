import React from 'react';
import '../../styles/payment.css'
import '../../styles/print-form.css'
import { withCookies } from 'react-cookie';
import SmartPrintForm from '../../containers/PrintForm.js'
import { Button } from 'antd';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
// import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import moment from 'moment'
import FileSaver from 'file-saver';

class PrintForm extends React.Component {

  render() {
    return (
      <PrintProvider>
        <div className="print-form-content-container">
          <Print single name="print-form">
            <SmartPrintForm  {...this.props} />
          </Print>
          <NoPrint>
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
              <Button className="print-btn" type="primary" onClick={() => {
                window.print();
              }}>Print Form</Button>
              <Button className="back-btn" type="primary" onClick={() => {
                window.closeForm();
              }}>Close Form</Button>
              <Button style={{ marginLeft: '20px', marginBottom: '10%'}} type="primary" onClick={() => {
                this.exportPdf();
              }}>Save</Button>
            </div>
          </NoPrint>
        </div>
      </PrintProvider>
    )
  }
  exportPdf(){
    //  html2canvas(document.querySelector("#capture"), {
    //    width: 1000,
    //    height: 1400
    //  }).then(canvas => {
    //     window.showLoading(true);
    //     //document.body.appendChild(canvas);
    //     canvas.toBlob((blob) => {
    //         FileSaver.saveAs(blob, "Sasai Money Transfer Form " + moment().format('YYYYMMDD-hh:mm:ss a') + ".png");
    //     });
    // });

    htmlToImage.toPng(document.querySelector("#capture"), {backgroundColor: "white"})
    .then(blob => {
      FileSaver.saveAs(blob, "Sasai Money Transfer Form " + moment().format('YYYYMMDD-hh:mm:ss a') + ".png");
    })
 }

  componentDidMount() {
    document.title = "Print Form"
    window.scrollTo(0, 0)
  }
}

export default withCookies(PrintForm)
