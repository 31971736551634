import React from 'react';
import Log from '../utils/log.js';
import Autocomplete from 'react-autocomplete';
import { Input, Menu, Dropdown, Icon, DatePicker, Form, message, Checkbox, Layout, Steps, Button, Modal, Select } from 'antd';
import {
  requestSendingCountries,
  requestSignUp,
  requestLogin,
  requestPCAPredictAddressFind,
  requestPCAPredictAddressRetrieve,
  requestCountries,
  setSendingCountry,
} from '../actions';
import '../styles/login.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import Helper from '../utils/helper.js';
import 'core-js/features/string/includes';
import ManageUrls from '../endpoints/manage_urls.js';
import baseUrlOptions from '../endpoints/base_url_options';

const { Content } = Layout;
const { Option } = Select;
const FormItem = Form.Item;
const Step = Steps.Step;
const TAG = "SmartSignUp";

const steps = [{
  title: 'Step 1',
  description: 'Basic Information'
}, {
  title: 'Step 2',
  description: 'Verify Phone'
}, {
  title: 'Step 3',
  description: 'Upload Documents'
}, {
  title: 'Step 4',
  description: 'Upload Selfie'
}];

class SignUp extends React.Component {

  componentDidMount() {
    const { cookies } = this.props;
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      this.props.history.push({
        pathname: "/"
      });
    } else {
      Log.d(TAG, "checkIfHasUser() -- has no user");
      Helper.clearCookies(cookies)
    }
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedRegistrationForm {... this.props}/>
        </Content>
      </Layout>
    )
  }

  componentDidUpdate(prevProps) {}
}

class RegistrationForm extends React.Component {

   constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    this.state = {
      sendingCountry : 0, // step 1
      baseCountryIsSA: true,
      phone : "",
      whatsapp_mobile : "",
      password: "",
      refCode: "",
      emailAdd : "",
      confirmPassword : "",
      sendPromotions: false,
      sendingCountries: [],
      pType: "password",
      pTypeIcon: "eye",
      cpType: "password",
      cpTypeIcon: "eye",

      firstName: "", // step 2
      middleName: "",
      surname: "",
      chosenNationality: undefined,
      genders: [ "Male", "Female" ],
      chosenGender: 0,
      verificationTypes: [
        { "id": "SI", "value": "South African ID" },
        { "id": "FP", "value": "Foreign Passport" },
        { "id": "AP", "value": "Asylum Permit" },
        { "id": "FI", "value": "Foreign ID" }
      ],
      chosenVerificationType: 0,
      idNumber: "",
      maxBirthDate : moment().subtract(18, 'years'),
      birthDate: moment().subtract(18, 'years'),

      addressDatasource: [], // step 3
      selectedAddressId : "",
      provinces : [
        { "code": "EC", "value": "Eastern Cape" },
        { "code": "FS", "value": "Free State" },
        { "code": "GT", "value": "Gauteng" },
        { "code": "NL", "value": "KwaZulu Natal" },
        { "code": "LP", "value": "Limpopo" },
        { "code": "MP", "value": "Mpumalanga" },
        { "code": "NW", "value": "North West" },
        { "code": "NC", "value": "Northern Cape" },
        { "code": "WC", "value": "Western Cape" }
      ],
      chosenProvinceIndex: 0,
      chosenProvince: "",
      address : '',
      openDropDown: false,
      homeWallet: false,
      step: 0,
      current: 0,
      getFieldDecorator: this.props.form,
      occupation: "",
      visible: false,
      city: "",
      address_line_1: "",
      address_line_2: "",
      postal_code: "0000",

      // add logic for ensuring that the customer has read
      // the Ts & Cs plus Customer Privacy Notice
      hasReadTsnCsPrivacyNotice: false,
    };
  }

  manageUrls = new ManageUrls()

  checkIDNumber(idNumber) {
    return Helper.isValidSAID(idNumber);
  }

  next() {
    if (this.state.current === 0) {
      Log.d(TAG, "next() -- step1 -- called ..")
      if (this.props.countryReducer.sendingCountries.length > 0) {
        Log.d(TAG, "next() -- step1 -- validate ..")
        this.props.form.validateFieldsAndScroll((err, values) => {
          Log.d(TAG, "next() -- step1 -- values=" + JSON.stringify(values))
          Log.d(TAG, "next() -- step1 -- err=" + JSON.stringify(err))
          if (!err) {
            this.setState({
              sendingCountry : this.props.form.getFieldValue('sendingCountry'),
              phone : this.props.form.getFieldValue('phone'),
              whatsapp_mobile : this.props.form.getFieldValue('whatsapp_mobile'),
              password: this.props.form.getFieldValue('password'),
              refCode: this.props.form.getFieldValue('referral_code'),
              emailAdd : this.props.form.getFieldValue('email'),
              confirmPassword : this.props.form.getFieldValue('confirm_password'),
              sendPromotions: this.props.form.getFieldValue('send_promotions'),
            });
            const current = this.state.current + 1;
            this.setState({ current });
            this.setSendingCountry()
          }
        })
      } else {
        Helper.setError(this.props.form, "sendingCountry", 'This field is required.');
      }
    } else if (this.state.current === 1) {
      if (this.props.countryReducer.countries.length > 0) {
        this.props.form.validateFieldsAndScroll((err, values) => {
          Log.d(TAG, "next() -- step2 -- values=" + JSON.stringify(values))
          Log.d(TAG, "next() -- step2 -- err=" + JSON.stringify(err))
          if (!err) {
            if (values.id_document_number && 
                Number(this.state.chosenVerificationType) === 0 &&
                this.state.baseCountryIsSA) {
              if (this.checkIDNumber(values.id_document_number)) {
                if (this.checkBdayAndIDNumber(values)) {
                  this.setValuesStep2();
                } else {
                  Helper.setError(this.props.form, "id_document_number", 'Birthdate did not match with SA ID number value combination');
                }
              } else {
                Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
              }
            } else {
              this.setValuesStep2();
            }
          }
        })
      } else {
        Helper.setError(this.props.form, "nationality", 'This field is required.');
      }
    } else {
      const current = this.state.current + 1;
      this.setState({ current });
    }
  }

  setValuesStep2() {
    const current = this.state.current + 1;
    this.setState({
      firstName : this.props.form.getFieldValue('first_name'),
      middleName : this.props.form.getFieldValue('middle_name'),
      surname: this.props.form.getFieldValue('last_name'),
      // chosenNationality: this.props.form.getFieldValue('nationality'),
      birthDate : this.props.form.getFieldValue('birth_date'),
      idNumber : this.props.form.getFieldValue('id_document_number'),
      chosenVerificationType: this.props.form.getFieldValue('id_verification_document') || 0,
      chosenGender: this.props.form.getFieldValue('gender'),
      occupation: this.props.form.getFieldValue('occupation'),
      current: current
    });
  }

   prev() {
     const current = this.state.current - 1;
     this.setState({ current });
   }

   componentDidUpdate(prevProps) {

    if (this.props.countryReducer.sendingCountries.length > 0 && prevProps.countryReducer.sendingCountries !== this.props.countryReducer.sendingCountries) {
      this.setState({
        sendingCountry : 0
      })
      this.props.form.setFieldsValue({
        sendingCountry: 0
      })
    }

    if (this.props.countryReducer.countries.length > 0 && prevProps.countryReducer.countries !== this.props.countryReducer.countries) {
      Log.d(TAG, "componentDidUpdate() -- set countries=" + JSON.stringify(this.props.countryReducer.countries))
      /*this.setState({
        chosenNationality : 0
      })
      this.props.form.setFieldsValue({
        nationality: 0
      })*/
    }

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      if (this.props.userReducer.error.includes("Invalid credentials")) {
        var errorMessage = "We found an error verifying your credentials based on your registration details."
        if (Object.keys(this.props.userReducer.user).length > 0) {
          Log.d(TAG, "componentDidUpdate() -- error has user=" + JSON.stringify(this.props.userReducer.user));
          errorMessage = errorMessage + " Your account status is " + Helper.showUserStatus(this.props.userReducer.user.data.attributes.user_status) + ".";
        }
        message.error(errorMessage, 5)
      } else {
        message.error("" + this.props.userReducer.error, 5)
      }
    }

    if (this.props.userReducer.signUpSuccess !== false && prevProps.userReducer.signUpSuccess !== this.props.userReducer.signUpSuccess) {
      Log.d(TAG, "componentDidUpdate() -- sign up success=" + this.props.userReducer.signUpSuccess)
      var payload = this.state.baseCountryIsSA 
                ? {"username": this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix + " " + this.state.phone.replace(/\s/g, ''), "password" : this.state.password}
                : {"username": this.state.emailAdd, "password": this.state.password};
      this.requestLogin(payload);
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success) // means sign up is successful

      if (this.state.current !== 2) {
        this.props.history.push({
          pathname: "/manage-breadwinner-policy/",
          state: {
            recipientId : null,
            bwBeneficiary: {},
            recipient: {}
          }
        });
      } else {
        this.props.history.push({
          pathname: "/update-profile",
        });
      }
    }

    if (this.props.userReducer.loginMessage !== null && prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage) {
      message.error("" + this.props.userReducer.loginMessage, 5)
      const { cookies } = this.props;
      Helper.clearCookies(cookies)
      // log out as there is an error on this
      this.props.history.push({
        pathname: "/",
        state: { message : this.props.userReducer.loginMessage}
      });
    }

    if (this.props.userReducer.pcaPredictAddresses.length > 0 && prevProps.userReducer.pcaPredictAddresses !== this.props.userReducer.pcaPredictAddresses) {
      Log.d(TAG, "componentDidUpdate() -- set addresses=" + this.props.userReducer.success) // means sign up is successful
      this.setState({
        addressDatasource : this.props.userReducer.pcaPredictAddresses
      })
    }

    /* Auto-complete functionality lines 309-330 */
    if (prevProps.userReducer.pcaPredictAddress !== this.props.userReducer.pcaPredictAddress) {
      Log.d(TAG, "componentDidUpdate() -- address=" + JSON.stringify(this.props.userReducer.pcaPredictAddress))
      this.props.form.setFieldsValue({
        postal_code : this.props.userReducer.pcaPredictAddress.PostalCode
      })
      /*this.props.form.setFieldsValue({
        street : this.props.userReducer.pcaPredictAddress.Street
      })*/
      this.props.form.setFieldsValue({
        address_line_1 : this.props.userReducer.pcaPredictAddress.Line1
      })
      this.props.form.setFieldsValue({
        address_line_2 : this.props.userReducer.pcaPredictAddress.Line2
      })
      this.props.form.setFieldsValue({
        city_town : this.props.userReducer.pcaPredictAddress.City
      })
      this.props.form.setFieldsValue({
        state_province : this.props.userReducer.pcaPredictAddress.Province
      })
      this.setState({
        chosenProvince : this.props.userReducer.pcaPredictAddress.Province
      })


      // remove additional operations on province state field for auto-complete
      // Log.d(TAG, "componentDidUpdate() -- province code=" + this.props.userReducer.pcaPredictAddress.ProvinceCode)
      // if (this.props.userReducer.pcaPredictAddress.ProvinceCode && this.props.userReducer.pcaPredictAddress.ProvinceCode.length >= 2) {
      //   this.checkProvince(this.props.userReducer.pcaPredictAddress);
      // }
    }

    // remove additional operations on province state field for auto-complete
    // if (this.props.userReducer.pcaPredictAddressError !== null && prevProps.userReducer.pcaPredictAddressError !== this.props.userReducer.pcaPredictAddressError) {
    //   Log.d(TAG, "componentDidUpdate() -- set pca predict address error=" + this.props.userReducer.pcaPredictAddressError)
    //   message.error("" + this.props.userReducer.pcaPredictAddressError, 5)
    // }
  }

  checkProvince(pcaPredictAddress) {
      let provinceCode = pcaPredictAddress.ProvinceCode.substring(0, 2).toLowerCase();
      var selectedProv = 0;
      Log.d(TAG, "checkProvince() -- province code=" + provinceCode);
      if (provinceCode === "ec") {
        selectedProv = 0;
      } else if (provinceCode === "fs") {
        selectedProv = 1;
      } else if (provinceCode === "gt") {
        selectedProv = 2;
      } else if (provinceCode === "nl") {
        selectedProv = 3;
      } else if (provinceCode === "lp") {
        selectedProv = 4;
      } else if (provinceCode === "mp") {
        selectedProv = 5;
      } else if (provinceCode === "nw") {
        selectedProv = 6;
      } else if (provinceCode === "nc") {
        selectedProv = 7;
      } else if (provinceCode === "wc") {
        selectedProv = 8;
      }

      Log.d(TAG, "checkProvince() -- province=" + JSON.stringify(this.state.provinces[selectedProv]) + " selected=" + selectedProv);
      this.setState({
        chosenProvinceIndex : selectedProv
      });
      this.props.form.setFieldsValue({
        state_province: selectedProv
      });
  }


  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- current legal date=" + this.state.maxBirthDate)
    /*this.props.form.setFieldsValue({
      birth_date : this.state.maxBirthDate,
    })*/
    this.requestSendingCountry()
    this.requestCountries()
  }

  handleOk = e => {
    this.setState({
      visible: false,
    });
    const current = this.state.current + 1;
    this.setState({
      address_line_1 : this.props.form.getFieldValue('address_line_1'),
      address_line_2 : this.props.form.getFieldValue('address_line_2'),
      city: this.props.form.getFieldValue('city_town'),
      postal_code: this.props.form.getFieldValue('postal_code'),
      current: current
    });
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.requestSignUp(values);
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    //register here only
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.requestSignUp(values);
    });
  };

  handleSubmit(e){
    Log.d(TAG, "handleSubmit() -- called ..")
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- values=" + JSON.stringify(values))
      Log.d(TAG, "handleSubmit() -- err=" + JSON.stringify(err))
      if (!err) {
        this.checkTerms(err, values);
      }
    })
  }

  checkBdayAndIDNumber(values) {

    var birthYear = values.birth_date.format("YYYY-MM-DD").substring(2, 4);
    var birthMonth = values.birth_date.format("YYYY-MM-DD").substring(5, 7);
    var birthDay = values.birth_date.format("YYYY-MM-DD").substring(8, 10);

    var idYear = values.id_document_number.substring(0, 2);
    var idMonth = values.id_document_number.substring(2, 4);
    var idDay = values.id_document_number.substring(4, 6);

    Log.d(TAG, "checkBdayAndIDNumber() -- birthYear=" + birthYear + " birthMonth=" + birthMonth + " birthDay=" + birthDay);
    Log.d(TAG, "checkBdayAndIDNumber() -- idYear=" + idYear + " idMonth=" + idMonth + " idDay=" + idDay);

    if (birthYear !== idYear || birthMonth !== idMonth || birthDay !== idDay) {
      return false;
    }

    return true;
  }

  checkTerms(err, values) {
    this.setNoErrorID('id_document_number');
    if (values.terms === false) {
      this.props.form.setFields({
        terms: {
          errors: [new Error('Please tick this checkbox')],
        },
      });
    } else {
      // stop modal from showing by 
      // commenting out code that
      // changes visible to true

      // this.setState({
      //   visible: true,
      // });

      // if modal is not showing then add this code to prevent
      // application from hanging awaiting response from disabled modal
      
      this.props.form.validateFieldsAndScroll((err, values) => {
        this.requestSignUp(values);
      });
    }
  }

  validateBeneficiaryField = (rule, value, callback) => {
    const form = this.props.form;
    if (!value && form.getFieldValue('bread_winner')) {
      callback('This field is required');
    }
    callback();
  }

  requestSignUp(values) {
    Log.d(TAG, "requestSignUp() -- values=" + JSON.stringify(values));
    var countryObj = {};
    var countryDataObj = {};
    countryDataObj.type = "countries";
    countryDataObj.id = this.props.countryReducer.sendingCountries[this.state.sendingCountry].id;
    countryObj.data = countryDataObj;

    var nationalityObj = {};
    var nationalityDataObj = {};
    nationalityDataObj.type = "countries";
    
    if(this.state.chosenNationality) {
      nationalityDataObj.id = this.state.chosenNationality;
      nationalityObj.data = nationalityDataObj;
    }

    var relationshipObj = {};
    relationshipObj.country = countryObj;
    relationshipObj.id_nationality = nationalityObj;

    var attributesObj = {};
    if (this.state.emailAdd) {
      attributesObj.email = this.state.emailAdd;
    } else {
      attributesObj.email = null;
    }

    attributesObj.password = this.state.password;
    attributesObj.first_name = this.state.firstName;
    if (this.state.middleName) {
        attributesObj.middle_name = this.state.middleName;
    } else {
      attributesObj.middle_name = "";
    }

    attributesObj.last_name = this.state.surname;
    if(this.state.baseCountryIsSA) {
      attributesObj.gender = this.state.genders[this.state.chosenGender] === "Male" ? "M" : "F";
    } else {
      attributesObj.gender = null;
    }

    attributesObj.birth_date = this.state.birthDate.format("YYYY-MM-DD");
    attributesObj.postcode = values.postal_code ? values.postal_code : this.state.postal_code;
    attributesObj.address_line_1 = values.address_line_1 ? values.address_line_1 : this.state.address_line_1;
    attributesObj.address_line_2 = values.address_line_2 ? values.address_line_2 : this.state.address_line_2;
    attributesObj.city = values.city_town ? values.city_town : this.state.city;
    attributesObj.street = null;
    attributesObj.id_document_number = this.state.baseCountryIsSA ? this.state.idNumber : null;
    attributesObj.id_verification_type = this.state.baseCountryIsSA ? this.state.verificationTypes[this.state.chosenVerificationType].id : null;
    attributesObj.flat = null;
    attributesObj.building_no = null;
    attributesObj.county = this.state.baseCountryIsSA ? this.state.provinces[this.state.chosenProvinceIndex].code : this.state.chosenProvince;
    attributesObj.mobile = [this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix, this.state.phone.replace(/\s/g, '')];
    attributesObj.whatsapp_mobile = this.state.whatsapp_mobile ? [this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix, this.state.whatsapp_mobile.replace(/\s/g, '')] : null;
    attributesObj.notify_promotions = this.state.sendPromotions ? this.state.sendPromotions : false ;
    
    if(this.manageUrls.baseCountryIsSA()) {
      attributesObj.occupation = this.state.occupation ? this.state.occupation : "Unemployed";
    } else {
      attributesObj.occupation = null;
    }

    if (this.state.refCode) {
      attributesObj.ref_code = this.state.refCode;
    } else {
      attributesObj.ref_code = null;
    }

    //attributesObj.mobile_wallet_auto_create = this.state.homeWallet;
    attributesObj.mobile_wallet_auto_create = false;

    var dataObj = {};
    dataObj.type = "accounts"
    dataObj.id = null;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipObj;

    var payloadObj = {};
    payloadObj.data = dataObj;
    Log.d(TAG, "requestSignUp() -- payloadObj=" + JSON.stringify(payloadObj));
    this.props.requestSignUp(payloadObj);
  }

  getSavedFieldValue(value) {
    if (value) {
      if (value.trim()) {
        return value
      }
    }
    return null;
  }

  requestLogin(payload) {
    //const { cookies } = this.props;
    Log.d(TAG, "requestLogin() -- called ..")
    this.props.requestLogin(this.props.cookies, payload, true, "Welcome back");
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

  renderCountryName() {
    if (this.props.countryReducer.sendingCountries.length > 0 && this.props.countryReducer.sendingCountries[this.state.sendingCountry]) {
      return (
        <div>
          {this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.name}
        </div>
      )
    } else {
      return (
        <div>Not Available</div>
      )
    }
  }


  renderPhonePrefix() {
    Log.d(TAG, "renderPhonePrefix() -- countries=" + JSON.stringify(this.props.countryReducer.sendingCountries));
    Log.d(TAG, "renderPhonePrefix() -- current country index=" + JSON.stringify(this.state.sendingCountry));
    if (this.props.countryReducer.sendingCountries.length > 0 && this.props.countryReducer.sendingCountries[this.state.sendingCountry]) {
      Log.d(TAG, "renderPhonePrefix() -- current country=" + JSON.stringify(this.props.countryReducer.sendingCountries[this.state.sendingCountry]));
      return (
        <div style={{ width: 45 }}>
          +{this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix}
        </div>
      )
    } else {
      return (
        <div/>
      )
    }
  }

  render() {
    Log.d(TAG, "render() -- called ..")
    const { getFieldDecorator } = this.props.form;
    const { current } = this.state;
    var phonePrefix = getFieldDecorator('phonePrefix', {
      initialValue: "",
    })(
      this.renderPhonePrefix()
    );
    /*
    var passIcon = <div style={{ width: 45 }}><Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} /></div>;
    var showPasswordIcon  =  (<a onClick={this.onPasswordTypeChanged}>
          <Icon type={this.state.pTypeIcon} style={{ fontSize: 13 }}/>
      </a>);
      */
    return (
        <Form className = "signup-body"
          onSubmit={this.handleSubmit.bind(this)}>
          <h2 style={{ fontFamily: 'Lato' }}>Sign Up</h2>
          <div style={{ marginTop: '4vh', marginBottom: '4vh' }}>
            <Steps style={{ margin : '0' }} current={0}>
              {steps.map(item => <Step key={item.title} title={item.title} description={item.description} />)}
            </Steps>
          </div>
          {this.renderStepContainer(current, getFieldDecorator, phonePrefix)}
          <div style={{ width: '100%', padding: '20px', textAlign: 'left'}}>
            <b style={{width: '100%', color: 'red'}}>Fields with * are required.</b>
          </div>
          <div style={{ paddingTop: '8px' }}>
            {
              current > 0
              && (
              <Button style={{ backgroundColor: 'rgb(41, 108, 187)', borderColor: 'rgb(41, 108, 187)', boxSizing: 'border-box', color: 'white', border: '1px solid rgb(41, 108, 187)', marginRight: 8  }} onClick={() => this.prev()}>
                Previous
              </Button>
              )
            }
            {
              current < 2
              && <Button style={{ backgroundColor: 'rgb(41, 108, 187)', borderColor: 'rgb(41, 108, 187)', boxSizing: 'border-box', color: 'white', border: '1px solid rgb(41, 108, 187)' }} type="primary" onClick={() => this.next()}>Next</Button>
            }
            {
              current >= 2
              &&
              <div>
                {this.state.hasReadTsnCsPrivacyNotice ? (<div className = "form-group">
                <button
                  type="submit"
                  style={{ marginTop: 8, backgroundColor: 'rgb(41, 108, 187)', borderColor: 'rgb(41, 108, 187)', boxSizing: 'border-box', color: 'white', border: '1px solid rgb(41, 108, 187)' }}
                  className="su-btn-register btn btn-primary">
                  CREATE ACCOUNT
                </button>
              </div>) : (
                  <button
                    style={{ marginTop: 8, backgroundColor: 'rgb(41, 108, 187)', borderColor: 'rgb(41, 108, 187)', boxSizing: 'border-box', color: 'white', border: '1px solid rgb(41, 108, 187)' }}
                    className="su-btn-register btn btn-primary"
                    onClick={this.loadTerms}
                  >
                    VIEW TERMS AND CONDITIONS
                  </button>
                )}
                <Modal
                  title="Breadwinner Policy"
                  visible={this.state.visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button key="register-only" onClick={this.handleCancel}>
                      No, register only
                    </Button>,
                    <Button key="accept" onClick={this.handleOk}>
                      Accept
                    </Button>,
                  ]}>
                  <p>Are you interested to join our Breadwinner Policy?</p>
                </Modal>
              </div>
            }
          </div>

        </Form>
      )
  }

  renderStepContainer(current, getFieldDecorator, phonePrefix) {
    Log.d(TAG, "renderStepContainer() -- current=" + current);
    if (current === 0) {
      return (this.renderStep1(getFieldDecorator, phonePrefix))
    } else if (current === 1) {
      return (this.renderStep2(getFieldDecorator))
    } else if (current >= 2) {
      return (this.renderStep3(getFieldDecorator))
    }
  }

  renderReceivingCountry() {
    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
      return (
        <div>
          {this.props.countryReducer.receivingCountry.data.attributes.name}
        </div>
        )
    } else {
      return (
        <div/>
      )
    }
  }

  onAddressLine1Changed = (event, newValue) => {
    this.props.form.setFieldsValue({
      address_line_1: event.target.value
    })
    this.setState({ address_line_1: event.target.value })
  }

  onAddressLine2Changed = (event, newValue) => {
    this.props.form.setFieldsValue({
      address_line_2: event.target.value
    })
    this.setState({ address_line_2: event.target.value })
  }

  onCityChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      city: event.target.value
    })
    this.setState({ city: event.target.value })
  }

  onPostalChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      postal_code: event.target.value
    })
    this.setState({ postal_code: event.target.value })
  }

  renderStep3(getFieldDecorator){

    // postal code validation
    const maxLength = this.state.baseCountryIsSA ? 4 : 20
    const minLength = this.state.baseCountryIsSA ? 4 : 0
    const patternRegx = this.state.baseCountryIsSA ? new RegExp('^\\d+$') : new RegExp('[^\\n]+')

    return (
      <div className="su-item-2">
        <div className="su-left-title-text">
          <b>{this.markRequired}Tell us where you live</b>
        </div>
        {/* Auto-complete functionality line 756 */}
        {this.renderOption(getFieldDecorator)}
        <FormItem className="su-custom-row" >
          {getFieldDecorator('address_line_1', {
            rules: [{ required: true, message: this.state.baseCountryIsSA ? 'Please provide your street address' : 'Please provide your address line 1*' }], initialValue: this.state.address_line_1
            })(
              <Input placeholder={this.state.baseCountryIsSA ? "Street address*" : "Address line 1"} onChange={this.onAddressLine1Changed}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('address_line_2', {
            rules: [{ required: this.state.baseCountryIsSA, message: this.state.baseCountryIsSA ? 'Please provide your suburb' : 'Please provide your address line 2' }], initialValue: this.state.address_line_2
            })(
              <Input placeholder={this.state.baseCountryIsSA ? "Suburb*" : "Address line 2*"} onChange={this.onAddressLine2Changed}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('city_town', {
              rules: [{ required: true, message: 'Please provide your city' }], initialValue: this.state.city
            })(
              <Input placeholder="City*" onChange={this.onCityChanged}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('postal_code',
            {
              rules: [
                { required: true, message: this.state.baseCountryIsSA ? 'Please provide your postal code. ' : 'Please provide your post code. '},
                { pattern: patternRegx, message: this.state.baseCountryIsSA ? 'Postal code must be a number. ' :  'Post code must be a number. ' },
                { min: minLength, message: this.state.baseCountryIsSA ? 'Postal code is too short. ' : 'Post code is too short. '},
                { max: maxLength, message: this.state.baseCountryIsSA ? 'Postal code is too long. ' : 'Post code is too long. ' },
                { validator: (_, value) => value !== '0000' ? Promise.resolve() : Promise.reject('Please provide valid postal code. ') },
              ],
              initialValue: this.state.postal_code === "0000" ? "" : this.state.postal_code
            })(
              <Input placeholder={this.state.baseCountryIsSA ? "Postal Code*" : "Post Code*"} onChange={this.onPostalChanged}
                className="su-tf-2 form-control"/>
          )}
        </FormItem>
        <div className="su-left-title-text">
          <b>{this.markRequired}Select your province </b>
        </div>
        { this.state.baseCountryIsSA ? (
            <FormItem className = "su-row-full">
              {getFieldDecorator('state_province', {
                rules: [{ required: false }], initialValue: this.state.chosenProvinceIndex
              })(
                <Dropdown
                  className="su-tf-2 form-control"
                  trigger={['click']}
                  overlay={this.renderProvinceParent()}>
                  <div className="dropdown-item">
                    <div>
                      {this.renderProvinceName()}
                    </div>
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
            </FormItem>
          ) : (
            <FormItem className = "su-row-full">
              {getFieldDecorator('state_province', {
                rules: [{ required: this.state.baseCountryIsSA, message: 'Please provide your county/province' }], initialValue: this.state.chosenProvince
              })(
                <Input placeholder="County/Province*" onChange={this.onChangeProvince}
                  className="su-tf-2 form-control" />
              )}
            </FormItem>
          )
        }
        <FormItem className="su-row-full" style = {{ textAlign: "left"}}>
          {getFieldDecorator('valid_address', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [{
                required: true,
                message: 'Please tick this checkbox',
                transform: value => (value || undefined),
                type: 'boolean'
              }],
            })(
              <Checkbox>Confirm address information is valid.{this.markRequired}</Checkbox>
          )}
        </FormItem>
        {this.state.hasReadTsnCsPrivacyNotice && (<FormItem className="su-row-full" style = {{ textAlign: "left"}}>
          {getFieldDecorator('terms', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [{
                required: true,
                message: 'Please tick this checkbox',
                transform: value => (value || undefined),
                type: 'boolean'
              }],
          })(
            <Checkbox>I agree and have read the <Button className="transaparent-button" onClick={this.loadTerms}>Terms and Conditions and the Privacy Notice</Button>{this.markRequired}</Checkbox>
          )}
        </FormItem>)}
      </div>
    )
  }

  loadTerms = () => {
    this.setState({ hasReadTsnCsPrivacyNotice: true })
    window.open(encodeURI("https://" + window.location.hostname + "/terms-of-use"))
  }

  renderStep2(getFieldDecorator) {
    Log.d(TAG, "renderStep2() -- firstname=" + this.state.firstName);
    Log.d(TAG, "renderStep2() -- occupation=" + this.state.occupation);
    return (
      <div>
        <div className="su-left-title-text">
          <b>{this.markRequired}Tell us more about yourself</b>
        </div>
        <div className="su-item-2">
        <FormItem className = "su-row-full">
         {getFieldDecorator('first_name', {
              rules: [{ required: true, message: 'Please provide your first name' }], initialValue: this.state.firstName
            })(
              <Input placeholder="First Name*"
                className="su-tf-2 form-control" />
            )}
          </FormItem>
          <FormItem className = "su-row-full">
          {getFieldDecorator('middle_name', { initialValue: this.state.middleName })(
                <Input placeholder="Middle Name "
                  className="su-tf-2 form-control"/>
              )}
          </FormItem>
          <FormItem className = "su-row-full">
          {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Please provide your surname' }], initialValue: this.state.surname
              })(
                <Input placeholder="Surname*"
                  className="su-tf-2 form-control"/>
              )}
          </FormItem>
          {this.state.baseCountryIsSA && (
          <>
            <div className="su-left-title-text">
              <b>{this.markRequired}Nationality</b>
            </div>
            <FormItem className="su-row-full">
              {getFieldDecorator('nationality1', {
                  rules: [{ required: true, 
                  message: 'Please provide your nationality'}], 
                  initialValue: this.state.chosenNationality
                })(
                  <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Please provide your nationality"
                  optionFilterProp="children"
                  onChange={this.onChangeNationality}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.renderNationalities()}
                </Select>
                )}
            </FormItem>
          </>)}
         {this.state.baseCountryIsSA && (
         <>
          <div className="su-left-title-text">
              <b>{this.markRequired}ID Verification Document </b>
          </div>
          <FormItem className = "su-row-full">
              {getFieldDecorator('id_verification_document', { initialValue: this.state.chosenVerificationType })(
                <Dropdown
                  className="su-tf-2 form-control"
                  trigger={['click']}
                  overlay={this.renderVerificationTypeParent()}>
                  <div className="dropdown-item">
                    <div>
                      {this.renderVerificationType()}
                    </div>
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
          </FormItem>
         </>
         )}
         {this.state.baseCountryIsSA && (<FormItem className = "su-row-full">
         {getFieldDecorator('id_document_number', {
             rules: [{ required: true, message: 'Please provide your ID document number'}, {validator: this.validateID }],
             initialValue: this.state.idNumber
           })(
             <Input placeholder = "ID Document Number*"
               className="su-tf-2 form-control"/>
           )}
         </FormItem>)}
         {this.state.baseCountryIsSA && (<>
          <div className="su-left-title-text">
              <b>{this.markRequired}Occupation</b>
          </div>
          <FormItem className = "su-row-full">
          {getFieldDecorator('occupation', {
            rules: [
              { required: this.state.baseCountryIsSA, message: 'Please provide your occupation. ' }, 
              { min: this.state.baseCountryIsSA ? 4 : 0, message: 'Occupation should be 4 or more characters long. ' }, 
              { pattern: "^[a-zA-Z ]*$", message: 'Occupation should not include numbers and special characters. ' },
              { whitespace: true, message: 'Occupation should not be blank. ' }
              ],
            initialValue: this.state.occupation
            })(
              <Input placeholder = {this.state.baseCountryIsSA ? "Occupation*" : "Occupation"}
                className="su-tf-2 form-control"/>
            )}
          </FormItem>
         </>)} 
         {this.state.baseCountryIsSA && (<>
          <div className="su-left-title-text">
              <b>Gender</b>
          </div>
          <FormItem className = "su-row-full">
              {getFieldDecorator('gender', { initialValue: this.state.chosenGender })(
                <Dropdown
                  className="su-tf-2 form-control"
                  trigger={['click']}
                  overlay={this.renderGenderParent()}>
                  <div className="dropdown-item">
                    <div>
                      {this.renderGenderName()}
                    </div>
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
          </FormItem>
         </>)}
         <div className="su-left-title-text">
            <b>{this.markRequired}Date of Birth </b>
         </div>
         <FormItem className = "su-row-full">
            {getFieldDecorator('birth_date', {
                rules: [{ required: true, message: 'Please provide your birth date' }],
                initialValue: this.state.birthDate
              })(
                <DatePicker placeholder = "Date of birth"
                  allowClear={false}
                  format={'DD/MM/YYYY'}
                  className="su-tf-2"
                  disabledDate={this.disabledDate}
                  onChange={this.onChangeBirthDate}>
                </DatePicker>
              )}
          </FormItem>

        </div>
      </div>
    )
  }

  onChangeBirthDate = (date, dateString) => {
    this.setState({
      birthDate : date
    })
  }

  validatePhone = (rule, value, callback) => {
    Log.d(TAG, "validatePhone() -- called ..")
    if (value) {
      if (value.length >= 9) {
        if (isNaN(value)) {
          callback("Mobile number should contain valid numbers");
        } else {
          callback();
        }
      } else {
        callback("Mobile number should have at least 9 digits");
      }
    }
  }

  validateWhatsAppMobile = (rule, value, callback) => {
    Log.d(TAG, "validateWhatsAppMobile() -- called ..")
    if (value) {
      if (value.length >= 9) {
        if (isNaN(value)) {
          callback();
        } else {
          callback();
        }
      } else {
        callback("WhatsApp Mobile number should have at least 9 digits");
      }
    } else {
      callback();
    }
  }

  markRequired = (<span style={{ color: 'red' }}>* </span>);

  renderStep1(getFieldDecorator, phonePrefix) {
    Log.d(TAG, "renderStep1() -- mobile=" + this.state.phone)
    return (
      <div>
        <div className="su-item-2">
          <div className="su-left-title-text">
            <b>Sending country</b>
          </div>
        {getFieldDecorator('sendingCountry', {
            rules: [{ required: true, message: 'Please provide your country' }], initialValue: this.state.sendingCountry
          })(
            <Dropdown className = "su-tf-2 form-control"
              trigger={['click']}
              overlay={this.renderCountriesParent()}>
              <div className="dropdown-item">
                <div>
                  {this.renderCountryName()}
                </div>
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        <div className="su-left-title-text">
            <b>{this.markRequired}Your mobile number</b>
        </div>
        <FormItem className = "su-row-full">
        {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please provide your phone number' }, {validator: this.validatePhone }], initialValue: this.state.phone
          })(
            <Input className="mobile-number"
              placeholder = "Phone number*" addonBefore={phonePrefix} onChange={this.onMobileChanged} maxlength="11" />
          )}
        </FormItem>
        <div className="su-left-title-text">
            <b>{this.markRequired}Your WhatsApp number</b>
        </div>
        <FormItem className = "su-row-full">
        {getFieldDecorator('whatsapp_mobile', {
            rules: [{ required: false, message: 'Please provide your WhatsApp number' }, {validator: this.validateWhatsAppMobile }], initialValue: this.state.whatsapp_mobile
          })(
            <Input className="mobile-number"
              placeholder = "WhatsApp number" addonBefore={phonePrefix} onChange={this.onWhatsAppChanged} maxlength="11" />
          )}
        </FormItem>
        <div className="su-left-title-text">
            <b>{this.markRequired}Email</b>
        </div>
        </div>
        <div className="su-item-2">
        <FormItem className = "su-row-full">
        {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'Please provide a valid e-mail address' },
              { required: !this.state.baseCountryIsSA, message: 'Please enter email address' }
            ], initialValue: this.state.emailAdd
          })(
          <Input onChange={this.onEmailAddChanged} className="su-tf-2 form-control" placeholder="Email address"/>
        )}
        </FormItem>
        <div className="su-left-title-text">
            <b>{this.markRequired}Create Password</b>
        </div>
        <FormItem className = "su-row-full">
        {getFieldDecorator('password', {
            rules: [{
              required: true, message: 'Create password',
            }, {
              min: 6, message: 'Password should be minumum of 6 characters',
            }, {
              validator: this.validateToNextPassword,
            }],
              initialValue: this.state.password
            })(
            <Input className = "su-tf-2 form-control" placeholder = "Enter your password*" type={this.state.pType} onChange={this.onPasswordChanged}/>
            )}
          </FormItem>
          <FormItem className = "su-row-full">
          {getFieldDecorator('confirm_password', {
              rules: [{
                required: true, message: 'Please confirm your chosen password',
              }, {
                min: 6, message: 'Password should be minumum of 6 characters',
              }, {
                validator: this.compareToFirstPassword,
              }], initialValue: this.state.confirmPassword
            })(
              <Input className = "su-tf-2 form-control" placeholder = "Confirm your password*" type={this.state.cpType} onChange={this.onConfirmPasswordChanged}/>
            )}
          </FormItem>
          <div className="su-left-title-text">
            <b>Did someone refer us?</b>
          </div>
            <FormItem className = "su-row-full">
          {getFieldDecorator('referral_code', {
             initialValue: this.state.refCode
           })(
              <Input placeholder = "Referral Code"
                className="su-tf-2 form-control"/>
            )}
          </FormItem>
          <FormItem className="su-row-full" style = {{ textAlign: "left"}}>
            {getFieldDecorator('send_promotions', {
               initialValue: this.state.sendPromotions
             })(
              <Checkbox checked={this.state.sendPromotions} onChange={this.onChangeSendPromotions}>Send me promotions</Checkbox>
            )}
          </FormItem>
        </div>
      </div>
    )
  }

  onChangeSendPromotions = (e) => {
   this.setState({
     sendPromotions: e.target.checked,
   });
 }

  disabledPastDate = (current) => {
    return current && current < moment().endOf('day');
  }

  renderHomeWallet(getFieldDecorator) {
    Log.d(TAG, "renderHomeWallet() -- nationality=" + this.props.countryReducer.countries[this.state.chosenNationality].id)
    Log.d(TAG, "renderHomeWallet() -- nationality=" + this.props.countryReducer.countries[this.state.chosenNationality].attributes.alpha_2_code);
    if (this.props.countryReducer.countries.length > 0) {
      if (this.props.countryReducer.countries[this.state.chosenNationality].attributes.alpha_2_code === "ZW") {
        return (
          <FormItem className="su-row-full" style = {{ textAlign: "left"}}>
            {getFieldDecorator('home_wallet', {
              initialValue: this.state.homeWallet
            })(
              <Checkbox checked={this.state.homeWallet} onChange={(e) => {
                this.setState({
                  homeWallet: e.target.checked
                });
              }}>Create Home Wallet</Checkbox>
            )}
          </FormItem>
        )
      }
    }
  }

  renderNationalities(){
    return this.props.countryReducer.countries.map((itemAr, index) => {
      return (
        <Option key={`${index} - ${itemAr.id}`} value={itemAr.id}>{itemAr.attributes.name}</Option>
        );
    })
  }

  onChangeNationality = (value) => {
    Log.d(TAG, "onChangeNationality() -- value=" + value);
    this.setState({
      chosenNationality : value,
      homeWallet: false
    })
    this.props.form.setFieldsValue({
      nationality: value
    })
  }

  renderGenderParent(){
    return (
      <Menu onClick={this.onChangeGender}>
        {this.renderGenders()}
      </Menu>
    )
  }

  renderGenders(){
    return this.state.genders.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
        );
    })
  }

  onChangeGender = (event) => {
    Log.d(TAG, "onChangeGender() -- event.key=" + event.key);
    this.setState({
      chosenGender : event.key
    })
    this.props.form.setFieldsValue({
      gender: event.key
    })
  }

  renderGenderName() {
    return (
      <div>
        {this.state.genders[this.state.chosenGender]}
      </div>
    )
  }

  renderVerificationTypeParent(){
    return (
      <Menu onClick={this.onChangeVerificationType}>
        {this.renderVerificationTypes()}
      </Menu>
    )
  }

  renderVerificationTypes(){
    return this.state.verificationTypes.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
        );
    })
  }

  onChangeVerificationType = (event) => {
    Log.d(TAG, "onChangeVerificationType() -- event.key=" + event.key);
    this.setState({
      chosenVerificationType : event.key
    })
    this.props.form.setFieldsValue({
      id_verification_document: event.key
    })

    if (this.props.form.getFieldValue('id_document_number') && Number(event.key) === 0) {
      Log.d(TAG, "onChangeVerificationType() -- has ID number and event key is 0");
      if (this.checkIDNumber(this.props.form.getFieldValue('id_document_number'))) {
        Log.d(TAG, "onChangeVerificationType() -- no error 1");
        this.setNoErrorID('id_document_number');
      } else {
        Log.d(TAG, "onChangeVerificationType() -- has error");
        Helper.setError(this.props.form, "id_document_number", 'This field has invalid SA ID number.');
      }
    } else {
      Log.d(TAG, "onChangeVerificationType() -- no error 2");
      this.setNoErrorID('id_document_number');
    }
  }

  setNoErrorID(field) {
    this.props.form.setFields({
      [field] : {
        value: this.props.form.getFieldValue(field),
        errors: null,
      }
    });
  }

  renderVerificationType() {
    return (
      <div>
        {this.state.verificationTypes[this.state.chosenVerificationType].value}
      </div>
    )
  }

  renderProvinceParent(){
    return (
      <Menu onClick={this.onChangeProvinceIndex}>
        {this.renderProvinces()}
      </Menu>
    )
  }

  renderProvinces(){
    return this.state.provinces.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
        );
    })
  }

  onChangeProvinceIndex = (event) => {
    Log.d(TAG, "onChangeProvinceIndex() -- event.key=" + event.key);
    this.setState({
      chosenProvinceIndex : event.key
    })
    this.props.form.setFieldsValue({
      state_province: event.key
    })
  }

  onChangeProvince = (event) => {
    Log.d(TAG, "onChangeProvinceIndex() -- event.key=" + event.key);
    this.setState({
      chosenProvince : event.target.value
    })
    this.props.form.setFieldsValue({
      state_province: event.target.value
    })
  }

  renderProvinceName() {
    return (
      <div>
        {this.state.provinces[this.state.chosenProvinceIndex].value}
      </div>
    )
  }

  requestAddress(text, container) {
    if (text) {
      if (this.props.countryReducer.sendingCountries.length > 0) {
        this.setState({
          addressDatasource : []
        })
        this.props.requestPCAPredictAddressFind(text, container, this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code);
      }
    } else {
      Log.d(TAG, "requestAddress() -- close dropdown");
      this.setState({
        openDropDown : false,
        addressDatasource : []
      })
    }
  }

  // Auto-complete functionality lines 1356-1390
  renderOption(getFieldDecorator){
    return (
      <Autocomplete
        className="su-autocomplete-row-full"
        getItemValue={(item) => item.Text}
        items={this.state.addressDatasource}
        renderItem={(item, isHighlighted) =>
          <div className="su-address-item">
            <b>{item.Text}</b>
            <span>{item.Description}</span>
          </div>
        }
        wrapperProps={{ style: {width: '100%', marginBottom: '28px'}}}
        inputProps={{
          style: {
            "width": '100%',
            "border": '1px solid #d9d9d9',
            "height": '46px',
            "padding": '4px 11px',
            "border-radius": '0px',
            "font-size": '14px',
            "line-height": '1.5',
            "margin-top": '20px'
          },
          "placeholder": 'Start typing your address'
        }}
        open={this.state.openDropDown}
        onChange={this.onChangeAddress}
        onSelect={this.onSelectAddress}
        value={this.state.address}
        menuStyle={{overflow:'scroll', maxHeight: '150px', background: 'white'}}
        onMenuVisibilityChange={this.onMenuVisibilityChange}/>
    )
  }

  onMenuVisibilityChange = (isOpen) => {
    Log.d(TAG, "onMenuVisibilityChange() -- isOpen="+ isOpen);
  }

  onChangeAddress = (e) => {
    Log.d(TAG, "onChangeAddress() -- value="+ e.target.value)

    this.requestAddress(e.target.value, "");
    this.setState({
      address : e.target.value,
      openDropDown : true
    })

  }

  onSelectAddress = (value, item) => {
    Log.d(TAG, "onSelectAddress() -- value="+ value + " item=" + JSON.stringify(item))
    var description = item.Description;
    var regex = new RegExp("^.+([0-9]*) Addresses$");
    var testAddress = regex.test(description);
    this.setState({
      address : item.Text
    })
    if (testAddress) {
      Log.d(TAG, "onSelectAddress() -- request=" + value)
      this.requestAddress(value, item.Id);
    } else {
      Log.d(TAG, "onSelectAddress() -- DO NOT request=" + value)
      this.setState({
        openDropDown : false
      })
      this.props.requestPCAPredictAddressRetrieve(item.Id);
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Your password did not match to this value');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  }

  validateID = (rule, value, callback) => {
    Log.d(TAG, "validateID() -- called ..")
    if (value && 
      Number(this.state.chosenVerificationType) === 0 &&
      this.state.baseCountryIsSA) {
      if (this.checkIDNumber(value)) {
        callback();
      } else {
        callback('This field has invalid SA ID number.');
      }
    } else {
      callback();
    }
  }

  onPasswordTypeChanged = (e) => {
    Log.d(TAG, 'onPasswordTypeChanged() -- called');
    Log.d(TAG, 'onPasswordTypeChanged() -- pType=' + this.state.pType);
    var pType = "text"
    var pTypeIcon = "eye-o"
    if (this.state.pType === "text") {
      pType = "password"
      pTypeIcon = "eye"
    }
    this.setState({ pType, pTypeIcon })
  }

  onConfirmPasswordTypeChanged = (e) => {
    Log.d(TAG, 'onConfirmPasswordTypeChanged() -- called');
    Log.d(TAG, 'onConfirmPasswordTypeChanged() -- cpType=' + this.state.cpType);
    var cpType = "text"
    var cpTypeIcon = "eye-o"
    if (this.state.cpType === "text") {
      cpType = "password"
      cpTypeIcon = "eye"
    }
    this.setState({ cpType, cpTypeIcon })
  }

  onEmailAddChanged = (event) => {
    Log.d(TAG, 'onEmailAddChanged() -- called');
    this.props.form.setFieldsValue({
      email: event.target.value
    })

    this.setState({ emailAdd : event.target.value })
  }

  onPasswordChanged = (event) => {
    this.props.form.setFieldsValue({
      password: event.target.value
    })
    this.setState({ password : event.target.value })
  }

  onConfirmPasswordChanged = (event) => {
    this.props.form.setFieldsValue({
      confirm_password: event.target.value
    })
    this.setState({ confirmPassword : event.target.value })
  }

  onMobileChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      phone: event.target.value
    })
    this.setState({ phone : event.target.value })
  }

  onWhatsAppChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      whatsapp_mobile: event.target.value
    })
    this.setState({ whatsapp_mobile : event.target.value })
  }

  renderCountriesParent(){
    return (
      <Menu 
        style={{maxHeight: "30vh", overflow: "scroll"}}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    )
  }

  renderCountries(){
    return this.props.countryReducer.sendingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  setSendingCountry = () => {
    const baseCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code;

    if(baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ usernameIsPhoneNumber: true })
      this.manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA)
    } else {
      this.setState({ usernameIsPhoneNumber: false })
      this.manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM)
    }

    const sendingCountryObj = this.props.countryReducer.sendingCountries[this.state.sendingCountry]

    this.props.setSendingCountry(sendingCountryObj)
  }

  updateSendingCountryState = () => {
    const sendingCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code

    if(sendingCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ baseCountryIsSA: true })
    } else {
      this.setState({ baseCountryIsSA: false })
    }
  }

  onChangeCountry = (event) => {
    Log.d(TAG, "onChangeCountry() -- event.key=" + event.key);
    this.setState({
      sendingCountry : event.key
    }, this.updateSendingCountryState)
    this.props.form.setFieldsValue({
      sendingCountry: event.key
    })
    // this.requestReceivingCountries(this.props.countryReducer.sendingCountries[event.key].attributes.alpha_2_code);
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    this.props.requestSendingCountries()
  }

  requestCountries() {
    Log.d(TAG, "requestCountries() -- called ..");
    this.props.requestCountries()
  }
}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestSendingCountries,
    requestSignUp,
    requestLogin,
    requestPCAPredictAddressFind,
    requestPCAPredictAddressRetrieve,
    requestCountries,
    setSendingCountry
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
