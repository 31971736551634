import baseCountryOptions from "./base_url_options"

class LocalStorageService {
    getBaseCountry() {
        return window.localStorage.getItem("base-country")
    }

    setBaseCountry(country) {

        switch (country) {
            // SOUTH AFRICA
            case baseCountryOptions.SOUTH_AFRICA:
                this.saveStringToLocalStorage("base-country", country)
                break;

            // UNITED KINGDOM
            case baseCountryOptions.UNITED_KINGDOM:
                this.saveStringToLocalStorage("base-country", country)
                break;
        
            default:
                throw Error("unknown base country")
        }
    }

    saveObjectToLocalStorage(key = "", value = {}) {
        const stringValue = JSON.stringify(value)
        window.localStorage.setItem(key, stringValue)
    }

    retrieveObjectFromLocalStorage(key = "") {
        const stringValue = window.localStorage.getItem(key)

        return JSON.parse(stringValue)
    }

    saveStringToLocalStorage(key = "", value = "") {
        window.localStorage.setItem(key, value)
    }

    retrieveStringFromLocalStorage(key = "") {
        return window.localStorage.getItem(key)
    }
}

export default LocalStorageService