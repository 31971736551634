import React from "react";
import { Layout, message, Divider, Button, Popconfirm } from "antd";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import "../styles/transaction.css";
import {
  requestTransactionDetails,
  updateTransactionStatus,
  requestReceivingCurrency,
  requestSendingCurrency,
  requestReceivingCountry,
  fetchUserDetails,
  requestSendingCountry,
  showCalculation,
  requestProductDetails,
  showPaymentMethod,
  requestRecipientByID,
  requestCalculationDetails,
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content } = Layout;
const TAG = "SmartTransactionDetails";

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
    Log.d(TAG, "constructor() -- loc=" + window.location.hostname);

    this.state = {
      calculations: {},
      paymentMethods: {},
      paymentMethodsArr: {},
      beneficiaries: {},
      sourceOfFunds: [
        ["SAL", "Salary"],
        ["SV", "Savings"],
        ["GF", "Gift"],
        ["PN", "Pension"],
      ],
      cards: [],
      transferReasons: [],
      defaultTransferReason: "",
      transaction: {},
    };
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    this.checkIfHasUser();
  }

  checkIfHasUser() {
    // if (this.props.cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      // this.props.fetchUserDetails(this.props.cookies, this.props.cookies.cookies.access, false, null);
      this.props.fetchUserDetails(
        this.props.cookies,
        this.props.authReducer.accessToken,
        false,
        null
      );
    }
  }

  render() {
    return (
      <Layout className="trans-desc-layout">
        <Content className="tr-item-content-container">
          {this.renderTransactionDetails()}
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (
      Object.keys(this.props.userReducer.user).length > 0 &&
      prevProps.userReducer.user !== this.props.userReducer.user
    ) {
      this.requestTransactionDetails(this.props.match.params.transactionID);
    }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      message.error("" + this.props.userReducer.error, 5);

      if (
        this.props.userReducer.error.includes("password") &&
        this.props.userReducer.error.includes("expired")
      ) {
        message.error("Please change your password first.", 5);
        this.props.history.push({
          pathname: "/update-profile",
        });
      }
    }

    if (
      Object.keys(this.props.transactionReducer.transaction).length > 0 &&
      prevProps.transactionReducer.transaction !==
        this.props.transactionReducer.transaction
    ) {
      Log.d(TAG, "componentDidUpdate() -- set transaction");
      Log.d(
        TAG,
        "componentDidUpdate() -- transaction=" +
          JSON.stringify(this.props.transactionReducer.transaction)
      );

      this.setState({
        transaction: this.props.transactionReducer.transaction,
      });

      var included = this.props.transactionReducer.transaction.included;
      var countryID = "0";
      var sendingCurrencyID = "0";
      var receivingCurrencyID = "0";

      included.filter((obj) => {
        if (obj.type === "calculations") {
          this.setState({
            calculations: obj,
          });
          //this.props.showCalculation(obj)
          // this.props.requestCalculationDetails(obj.id, this.props.cookies.cookies.access);
          this.props.requestCalculationDetails(
            obj.id,
            this.props.authReducer.accessToken
          );
          sendingCurrencyID = obj.relationships.sending_currency.data.id;
          receivingCurrencyID = obj.relationships.receiving_currency.data.id;
        } else if (obj.type === "beneficiaries") {
          this.setState({
            beneficiaries: obj,
          });
          countryID = obj.relationships.country.data.id;
          // this.props.requestRecipientByID(obj.id, this.props.cookies.cookies.access);
          this.props.requestRecipientByID(
            obj.id,
            this.props.authReducer.accessToken
          );
        } else if (obj.type === "payment_methods") {
          this.setState({
            paymentMethods: obj,
          });
          this.props.showPaymentMethod(
            obj,
            this.props.transactionReducer.transaction.data.attributes
              .reason_for_transfer_name,
            this.props.transactionReducer.transaction.data.attributes
              .source_of_funds
          );
        }
        return obj;
      });

      if (countryID !== "0") {
        this.requestCountryDetails(countryID);
      }

      if (sendingCurrencyID !== "0") {
        this.requestCurrencyDetails(sendingCurrencyID, true);
      }

      if (receivingCurrencyID !== "0") {
        this.requestCurrencyDetails(receivingCurrencyID, false);
      }
    }

    if (
      Object.keys(this.props.countryReducer.sendingCountry).length > 0 &&
      prevProps.countryReducer.sendingCountry !==
        this.props.countryReducer.sendingCountry
    ) {
      if (
        Object.keys(this.props.countryReducer.sendingCountry).length > 0 &&
        Object.keys(this.props.countryReducer.receivingCountry).length > 0
      ) {
        var sendingCountryAlpha =
          this.props.countryReducer.sendingCountry.data.attributes.alpha_2_code;
        var receivingCountryAlpha =
          this.props.countryReducer.receivingCountry.data.attributes
            .alpha_2_code;
        var token = null;
        // if (cookies.get('access')) {
        if (!!this.props.authReducer.accessToken) {
          // token = cookies.get('access');
          token = this.props.authReducer.accessToken;
        }
        this.props.requestProductDetails(
          this.props.transactionReducer.transaction.data.relationships.product
            .data.id,
          token,
          receivingCountryAlpha,
          sendingCountryAlpha
        );
      }
    }

    if (
      Object.keys(this.props.countryReducer.receivingCountry).length > 0 &&
      prevProps.countryReducer.receivingCountry !==
        this.props.countryReducer.receivingCountry
    ) {
      this.props.requestSendingCountry(
        this.props.userReducer.user.data.relationships.country.data.id
      );
    }

    if (
      Object.keys(this.props.productServiceReducer.product).length > 0 &&
      prevProps.productServiceReducer.product !==
        this.props.productServiceReducer.product
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- product=" +
          JSON.stringify(this.props.productServiceReducer.product)
      );
      var attributes = this.props.productServiceReducer.product.data.attributes;
      var includedP = this.props.productServiceReducer.product.included;
      var paymentMethods = [];
      for (var i = 0; i < includedP.length; i++) {
        var includedObj = includedP[i];
        if (includedObj.type === "payment_methods") {
          paymentMethods.push(includedObj);
        }
      }

      this.setState({
        transferReasons: attributes.transfer_reasons,
        defaultTransferReason: attributes.default_transfer_reason,
        paymentMethodsArr: paymentMethods,
      });
    }

    if (
      Object.keys(this.props.userReducer.user).length > 0 &&
      prevProps.userReducer.user !== this.props.userReducer.user
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- user=" +
          JSON.stringify(this.props.userReducer.user)
      );
      this.setUserDetails(this.props.userReducer.user);
    }
  }

  setUserDetails(userDetails) {
    if (Object.keys(userDetails.included[0]).length > 0) {
      var relationships = userDetails.data.relationships;
      if (relationships.hasOwnProperty("saved_cards")) {
        var savedCardsObj = relationships.saved_cards;
        if (savedCardsObj.hasOwnProperty("data")) {
          if (savedCardsObj.data != null) {
            this.setState({
              cards: savedCardsObj.data,
            });
          }
        }
      }
    }
  }

  isNotAirtimeDataTransaction = () => {
    if (Object.keys(this.state.beneficiaries).length > 0) {
      return this.state.beneficiaries.attributes.beneficiary_type !== "AT";
    } 

    return false;
  }

  checkDefaultReason() {
    var defaultReason = this.state.defaultTransferReason;
    var idVerificationType =
      this.props.userReducer.user.data.attributes.id_verification_type;
    Log.d(
      TAG,
      "checkDefaultReason() -- idVerificationType=" + idVerificationType
    );
    if (idVerificationType) {
      if (idVerificationType !== "SI") {
        Log.d(TAG, "checkDefaultReason() -- find remittance");
        for (var tf = 0; tf < this.state.transferReasons.length; tf++) {
          if (this.state.transferReasons[tf][1] === "Remittance") {
            Log.d(TAG, "checkDefaultReason() -- found remittance");
            defaultReason = this.state.transferReasons[tf][0];
          }
        }
      }
    }
    Log.d(TAG, "checkDefaultReason() -- defaultReason=" + defaultReason);
    return defaultReason;
  }

  handleCancelOrder() {
    let { id } = this.props.transactionReducer.transaction.data;
    let payload = {
      type: "transactions",
      id,
      attributes: {
        status: "X",
      },
    };
    let accessToken = this.props.authReducer.accessToken;
    this.props.updateTransactionStatus(payload, accessToken);
    if (this.props.userReducer.error) {
      message.error(this.props.userReducer.error, 5);
    } else {
      message.success("Order cancellation was successful", 5);
      this.props.history.goBack();
    }
  }

  renderTransactionDetails() {
    if (
      Object.keys(this.props.transactionReducer.transaction).length > 0 &&
      this.props.transactionReducer.transaction.hasOwnProperty("data") &&
      Object.keys(this.props.userReducer.user).length > 0 &&
      Object.keys(this.props.currencyReducer.sendingCurrency).length > 0 &&
      Object.keys(this.props.currencyReducer.receivingCurrency).length > 0
    ) {
      var receivingCountryName = "Not Available";
      if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
        if (this.props.countryReducer.receivingCountry.data) {
          receivingCountryName =
            this.props.countryReducer.receivingCountry.data.attributes.name;
        } else {
          receivingCountryName =
            this.props.countryReducer.receivingCountry.attributes.name;
        }
      }

      return (
        <div className="tr-details-body">
          <div className="tr-details-group-body">
            <b className="tr-details-group-title">Recipient Details</b>
            <Divider />
            <div className="tr-details-row-body">
              <span className="tr-details-row-left-body">
                <b style={{ "font-weight": "bold" }}>Beneficiary Name: </b>
                {
                  this.props.transactionReducer.transaction.data.attributes
                    .beneficiary_name
                }
              </span>
              <span>
                <b style={{ "font-weight": "bold" }}> Beneficiary Country: </b>
                {receivingCountryName}
              </span>
            </div>
          </div>
          <div className="tr-details-group-body">
            <b className="tr-details-group-title">Transaction Details</b>
            <Divider />
            <div className="tr-details-row-body">
              <span className="tr-details-row-left-body">
                <b style={{ "font-weight": "bold" }}>Code: </b>{" "}
                {this.props.transactionReducer.transaction.data.attributes.code}
              </span>
              {this.isNotAirtimeDataTransaction() && (
              <span className="tr-details-row-right-body">
                {this.renderSurcharges()}
              </span>)}
            </div>
            <div className="tr-details-row-body">
              <span className="tr-details-row-left-body">
                <b style={{ "font-weight": "bold" }}>Reason: </b>{" "}
                {this.props.transactionReducer.transaction.data.attributes
                  .reason_for_transfer_name
                  ? this.props.transactionReducer.transaction.data.attributes
                      .reason_for_transfer_name
                  : "Not Available"}
              </span>
              {this.isNotAirtimeDataTransaction() && this.renderRecipientAmount()}
            </div>
            <div className="tr-details-row-body">
              <span className="tr-details-row-left-body">
                <b style={{ "font-weight": "bold" }}>Source of funds: </b>
                {this.renderSource(
                  this.props.transactionReducer.transaction.data.attributes
                    .source_of_funds
                )}
              </span>
              {this.renderAmountToPay()}
            </div>
            <div className="tr-details-row-body">
              {this.renderPaymentMethod()}
              <span>
                <b style={{ "font-weight": "bold" }}>Transfer type: </b>Online
              </span>
            </div>
            {this.isNotAirtimeDataTransaction() && (<div className="tr-details-row-body">
              {this.renderRate()}
              <span>
                <b style={{ "font-weight": "bold" }}>Status: </b>{" "}
                {Helper.showStatus(
                  this.props.transactionReducer.transaction.data.attributes
                    .status
                )}{" "}
                at{" "}
                {
                  this.props.transactionReducer.transaction.data.attributes
                    .status_date
                }
              </span>
            </div>)}
            {this.isNotAirtimeDataTransaction() && (<div className="tr-details-row-body">
              {this.renderFees()}
              <span>
                <b style={{ "font-weight": "bold" }}>Date: </b>{" "}
                {
                  this.props.transactionReducer.transaction.data.attributes
                    .log_date
                }
              </span>
            </div>)}
            <Divider />
            {this.renderPay()}
            {(this.isNotAirtimeDataTransaction() && Helper.baseCountryIsSA) && this.renderIntegratedForm()}
            {Helper.baseCountryIsSA && this.renderPrintInvoice()}
            <div>{this.renderCancelButton()}</div>
          </div>
        </div>
      );
    }
  }

  renderCancelButton() {
    return (
      Helper.showStatus(
        this.props.transactionReducer.transaction.data.attributes.status
      )
        .toLowerCase()
        .includes("pending") && (
        <Popconfirm
          title="Are you sure you want to cancel this transaction?"
          onConfirm={() => this.handleCancelOrder()}
          onCancel={false}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger">Cancel Order</Button>
        </Popconfirm>
      )
    );
  }

  renderIntegratedForm() {
    if (
      this.props.transactionReducer.transaction.data.attributes.status ===
        "S" ||
      this.props.transactionReducer.transaction.data.attributes.status === "E"
    ) {
      return;
    } else {
      return (
        <Button
          className="home-collect"
          style={{
            backgroundColor: "#154989",
            boxSizing: "border-box",
            color: "white",
            marginBottom: "8px",
            marginLeft: "8px",
          }}
          type="primary"
          onClick={this.showPrintIntegratedForm}
        >
          Print Integrated Form
        </Button>
      );
    }
  }

  renderPay() {
    if (
      this.props.transactionReducer.transaction.data.attributes.status === "S"
    ) {
      return (
        <Button
          className="home-collect"
          style={{
            backgroundColor: "#154989",
            boxSizing: "border-box",
            color: "white",
            marginBottom: "8px",
            marginLeft: "8px",
          }}
          type="primary"
          onClick={() => this.payNow()}
        >
          Pay Now
        </Button>
      );
    }

    return <span />;
  }

  payNow() {
    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      if (this.state.paymentMethodsArr.length > 0) {
        var dReason = this.checkDefaultReason();
        Log.d(TAG, "payNow() -- dReason=" + dReason);
        Log.d(
          TAG,
          "payNow() -- transferReasons=" +
            JSON.stringify(this.state.transferReasons)
        );
        this.props.history.push({
          pathname: "/select-payment-method",
          state: {
            sendingCountry: this.props.countryReducer.sendingCountry.data,
            receivingCountry: this.props.countryReducer.receivingCountry.data,
            product: this.props.productServiceReducer.product,
            calculation: this.props.calculationReducer.calculation,
            recipient: this.props.recipientReducer.recipient,
            paymentMethods: this.state.paymentMethodsArr,
            cards: this.state.cards,
            receivingCurrency: this.props.currencyReducer.receivingCurrency,
            sendingCurrency: this.props.currencyReducer.sendingCurrency,
            transferReasons: this.state.transferReasons,
            defaultTransferReason: dReason,
            transaction: this.state.transaction,
          },
        });
      } else {
        message.error(
          "No payment method is available under chosen payout type",
          5
        );
      }
    }
  }

  renderPrintInvoice() {
    if (
      this.props.transactionReducer.transaction.data.attributes.status ===
        "A" ||
      this.props.transactionReducer.transaction.data.attributes.status === "C"
    ) {
      return (
        <Button
          className="home-collect"
          style={{
            backgroundColor: "#154989",
            boxSizing: "border-box",
            color: "white",
            marginBottom: "8px",
            marginLeft: "8px",
          }}
          type="primary"
          onClick={this.showPrintInvoiceForm}
        >
          Print Invoice Form
        </Button>
      );
    }

    return <span />;
  }

  showPrintIntegratedForm = () => {
    var receivingCountryName = "Not Available";
    var paymentMethodName = "Not Available";
    var sendingCountryName = "Not Available";
    var beneficiaryName = "Not Available";
    var reason = "Not Available";
    var reasonName = "Not Available";
    var source = "Not Available";

    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
      if (this.props.countryReducer.receivingCountry.data) {
        receivingCountryName =
          this.props.countryReducer.receivingCountry.data.attributes.name;
      } else {
        receivingCountryName =
          this.props.countryReducer.receivingCountry.attributes.name;
      }
    }
    /*
      var url = "http://" + window.location.hostname + ":3000/form/integrated/" + this.props.cookies.cookies.access
                + "/" + this.state.calculations.attributes.sending_amount +
                "/" + this.state.calculations.attributes.recipient_amount +
                "/" + this.state.calculations.attributes.reverse_rate +
                "/" +this.state.calculations.attributes.fees +
                "/" + this.state.calculations.attributes.surcharges +
                "/" + this.state.paymentMethods.attributes.name +
                "/NA" +
                "/" + sendingCurrencyID +
                "/" + receivingCurrencyID +
                "/" + this.props.countryReducer.sendingCountry.data.attributes.name +
                "/" + receivingCountryName +
                "/" + this.props.transactionReducer.transaction.data.attributes.beneficiary_name +
                "/" + this.props.transactionReducer.transaction.data.attributes.reason_for_transfer_name +
                "/" + this.props.transactionReducer.transaction.data.attributes.source_of_funds;
*/
    if (this.state.paymentMethods.attributes.name) {
      paymentMethodName = this.state.paymentMethods.attributes.name.replace(
        /\//g,
        "or"
      );
    }

    if (Object.keys(this.props.countryReducer.sendingCountry).length > 0) {
      if (this.props.countryReducer.sendingCountry.data) {
        sendingCountryName =
          this.props.countryReducer.sendingCountry.data.attributes.name.replace(
            /\//g,
            "or"
          );
      } else {
        sendingCountryName =
          this.props.countryReducer.sendingCountry.attributes.name.replace(
            /\//g,
            "or"
          );
      }
    }

    if (
      this.props.transactionReducer.transaction.data.attributes.beneficiary_name
    ) {
      beneficiaryName =
        this.props.transactionReducer.transaction.data.attributes.beneficiary_name.replace(
          /\//g,
          "or"
        );
    }

    if (
      this.props.transactionReducer.transaction.data.attributes
        .reason_for_transfer
    ) {
      reason =
        this.props.transactionReducer.transaction.data.attributes
          .reason_for_transfer;
    }

    if (
      this.props.transactionReducer.transaction.data.attributes
        .reason_for_transfer_name
    ) {
      reasonName =
        this.props.transactionReducer.transaction.data.attributes.reason_for_transfer_name.replace(
          /\//g,
          "or"
        );
    }

    if (
      this.props.transactionReducer.transaction.data.attributes.source_of_funds
    ) {
      source =
        this.props.transactionReducer.transaction.data.attributes
          .source_of_funds;
    }

    // var url = "https://" + window.location.hostname + "/form/integrated/" + this.props.cookies.cookies.access
    var url =
      "https://" +
      window.location.hostname +
      "/form/integrated/" +
      this.props.authReducer.accessToken +
      "/" +
      this.state.calculations.id +
      "/" +
      paymentMethodName +
      "/" +
      sendingCountryName +
      "/" +
      receivingCountryName.replace(/\//g, "or") +
      "/" +
      beneficiaryName +
      "/" +
      reason +
      "/" +
      reasonName +
      "/" +
      source;

    Log.d(TAG, "showPrintIntegratedForm() -- url=" + url);
    window.open(url);
  };

  showPrintInvoiceForm = () => {
    var receivingCountryName = "Not Available";
    var paymentMethodName = "Not Available";
    var sendingCountryName = "Not Available";
    var beneficiaryName = "Not Available";
    var reason = "Not Available";
    var reasonName = "Not Available";
    var source = "Not Available";

    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
      if (this.props.countryReducer.receivingCountry.data) {
        receivingCountryName =
          this.props.countryReducer.receivingCountry.data.attributes.name;
      } else {
        receivingCountryName =
          this.props.countryReducer.receivingCountry.attributes.name;
      }
    }

    if (this.state.paymentMethods.attributes.name) {
      paymentMethodName = this.state.paymentMethods.attributes.name.replace(
        /\//g,
        "or"
      );
    }

    if (Object.keys(this.props.countryReducer.receivingCountry).length > 0) {
      sendingCountryName =
        this.props.countryReducer.sendingCountry.data.attributes.name.replace(
          /\//g,
          "or"
        );
    }

    if (
      this.props.transactionReducer.transaction.data.attributes.beneficiary_name
    ) {
      beneficiaryName =
        this.props.transactionReducer.transaction.data.attributes.beneficiary_name.replace(
          /\//g,
          "or"
        );
    }

    if (
      this.props.transactionReducer.transaction.data.attributes
        .reason_for_transfer
    ) {
      reason =
        this.props.transactionReducer.transaction.data.attributes
          .reason_for_transfer;
    }

    if (
      this.props.transactionReducer.transaction.data.attributes
        .reason_for_transfer_name
    ) {
      reasonName =
        this.props.transactionReducer.transaction.data.attributes.reason_for_transfer_name.replace(
          /\//g,
          "or"
        );
    }

    if (
      this.props.transactionReducer.transaction.data.attributes.source_of_funds
    ) {
      source =
        this.props.transactionReducer.transaction.data.attributes
          .source_of_funds;
    }

    // var url = "https://" + window.location.hostname + "/form/invoice/" + this.props.cookies.cookies.access
    var url =
      "https://" +
      window.location.hostname +
      "/form/invoice/" +
      this.props.authReducer.accessToken +
      "/" +
      this.state.calculations.id +
      "/" +
      paymentMethodName +
      "/" +
      sendingCountryName +
      "/" +
      receivingCountryName.replace(/\//g, "or") +
      "/" +
      beneficiaryName +
      "/" +
      reason +
      "/" +
      reasonName +
      "/" +
      source +
      "/" +
      this.props.transactionReducer.transaction.data.id;
    Log.d(TAG, "showPrintInvoiceForm() -- url=" + url);
    window.open(url);
  };

  renderFees() {
    var sendingCurrencyName = "";
    if (Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {
      if (this.props.currencyReducer.sendingCurrency.data) {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.data.attributes
            .letter_code;
      } else {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.attributes.letter_code;
      }
    }
    if (Object.keys(this.state.calculations).length > 0) {
      return (
        <div className="tr-details-row-left-body">
          <b style={{ "font-weight": "bold" }}>Fees: </b>
          {sendingCurrencyName} {this.state.calculations.attributes.fees}
        </div>
      );
    }
    return <div />;
  }

  renderRate() {
    if (Object.keys(this.state.calculations).length > 0) {
      return (
        <div className="tr-details-row-left-body">
          <b style={{ "font-weight": "bold" }}>Rate: </b>:{" "}
          {this.state.calculations.attributes.rate}
        </div>
      );
    }
    return <div />;
  }

  renderPaymentMethod() {
    if (Object.keys(this.state.paymentMethods).length > 0) {
      return (
        <div className="tr-details-row-left-body">
          <b style={{ "font-weight": "bold" }}> Payment Method: </b>
          {this.state.paymentMethods.attributes.name}
        </div>
      );
    }
    return <div />;
  }

  renderSource(source) {
    this.state.sourceOfFunds.filter((obj) => {
      if (obj[0] === source) {
        source = obj[1];
      }
      return obj;
    });

    if (source) {
      return source;
    }
    return "Not Available";
  }

  renderSurcharges() {
    var sendingCurrencyName = "";
    if (Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {
      if (this.props.currencyReducer.sendingCurrency.data) {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.data.attributes
            .letter_code;
      } else {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.attributes.letter_code;
      }
    }
    if (Object.keys(this.state.calculations).length > 0) {
      return (
        <div>
          <b style={{ "font-weight": "bold" }}>VAT: </b> {sendingCurrencyName}{" "}
          {this.state.calculations.attributes.surcharges}
        </div>
      );
    }
    return <div />;
  }

  renderRecipientAmount() {
    var receivingCurrencyName = "";
    if (Object.keys(this.props.currencyReducer.receivingCurrency).length > 0) {
      if (this.props.currencyReducer.receivingCurrency.data) {
        receivingCurrencyName =
          this.props.currencyReducer.receivingCurrency.data.attributes
            .letter_code;
      } else {
        receivingCurrencyName =
          this.props.currencyReducer.receivingCurrency.attributes.letter_code;
      }
    }
    if (Object.keys(this.state.calculations).length > 0) {
      return (
        <div>
          {" "}
          <b style={{ "font-weight": "bold" }}>Recipient Amount: </b>
          {receivingCurrencyName}{" "}
          {this.state.calculations.attributes.recipient_amount}
        </div>
      );
    }
    return <div />;
  }

  renderAmountToPay() {
    var sendingCurrencyName = "";
    if (Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {
      if (this.props.currencyReducer.sendingCurrency.data) {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.data.attributes
            .letter_code;
      } else {
        sendingCurrencyName =
          this.props.currencyReducer.sendingCurrency.attributes.letter_code;
      }
    }
    if (Object.keys(this.state.calculations).length > 0) {
      return (
        <div>
          <b style={{ "font-weight": "bold" }}>Amount To Pay: </b>:{" "}
          {sendingCurrencyName}{" "}
          {this.state.calculations.attributes.amount_to_pay}
        </div>
      );
    }
    return <div />;
  }

  requestTransactionDetails(id) {
    // if (this.props.cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "requestTransactionDetails() -- id=" + id);
      // this.props.requestTransactionDetails(id, this.props.cookies.get('access'));
      this.props.requestTransactionDetails(
        id,
        this.props.authReducer.accessToken
      );
    } else {
      Log.d(TAG, "requestTransactionDetails() -- no access");
    }
  }

  requestCurrencyDetails(id, isSending) {
    // if (this.props.cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      if (isSending) {
        this.props.requestSendingCurrency(id);
      } else {
        this.props.requestReceivingCurrency(id);
      }
    }
  }

  requestCountryDetails(id) {
    this.props.requestReceivingCountry(id);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      updateTransactionStatus,
      requestTransactionDetails,
      requestReceivingCurrency,
      requestSendingCurrency,
      requestReceivingCountry,
      fetchUserDetails,
      requestSendingCountry,
      showCalculation,
      showPaymentMethod,
      requestProductDetails,
      requestRecipientByID,
      requestCalculationDetails,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

// TODO: replace with existing form component
/*class PrintForm extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    this.state = {
      sourceOfFunds: [ ["SAL", "Salary"], ["SV", "Savings"], ["GF", "Gift"], ["PN", "Pension"]],
    }
  }

  render() {
    return (
      <Content className="payment-content-container">
        {this.renderCheckingContent()}
      </Content>
    )
  }

  renderCheckingContent() {
      if (Object.keys(this.props.userReducer.user).length > 0
        && Object.keys(this.props.countryReducer.sendingCountry).length > 0
        && Object.keys(this.props.transactionReducer.transaction).length > 0
        && Object.keys(this.props.currencyReducer.sendingCurrency).length > 0
        && Object.keys(this.props.currencyReducer.receivingCurrency).length > 0) {
        return (this.renderInvoice(this.props.currencyReducer.sendingCurrency.data, this.props.currencyReducer.receivingCurrency))
      } else {
        return (this.renderNoContent())
      }

  }

  renderNoContent() {
    return (
      <div style={{ textAlign : 'center' }}>
        <span>Invalid form</span>
      </div>
    )
  }

  renderInvoice(sendingCurrency, receivingCurrency) {

    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '5%' }}>
        <img style={{ height: '70px', width: '200px', marginBottom: '20px', objectFit: 'contain' }} src={ecocashLogo} alt="ecocashLogo"/>
        <h3 style={{ marginBottom: '20px'}}>Invoice</h3>
        <b>Transaction Details</b>
        <span style={{ fontSize: '10px'}}>Transaction reference: {this.props.transactionReducer.transaction.data.attributes.code}</span>
        <span style={{ fontSize: '10px'}}>Transaction status: {Helper.showStatus(this.props.transactionReducer.transaction.data.attributes.status)} at {this.props.transactionReducer.transaction.data.attributes.status_date}</span>
        <span style={{ fontSize: '10px'}}>Transaction log date: {this.props.transactionReducer.transaction.data.attributes.log_date}</span>
        <span style={{ fontSize: '10px'}}>Amount to pay: {this.props.calculationReducer.calculation.attributes.sending_amount} {this.props.currencyReducer.sendingCurrency.data.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Beneficiary receives: {this.props.calculationReducer.calculation.attributes.recipient_amount} {this.props.currencyReducer.receivingCurrency.data.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Sending currency: {this.props.currencyReducer.sendingCurrency.data.attributes.name}</span>
        <span style={{ fontSize: '10px'}}>Receiving currency: {this.props.currencyReducer.receivingCurrency.data.attributes.name}</span>
        <span style={{ fontSize: '10px'}}>Rate: 1 {this.props.currencyReducer.receivingCurrency.data.attributes.letter_code} = {this.props.calculationReducer.calculation.attributes.reverse_rate} {this.props.currencyReducer.sendingCurrency.data.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Fees: {this.props.calculationReducer.calculation.attributes.fees} {this.props.currencyReducer.sendingCurrency.data.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Surcharges: {this.props.calculationReducer.calculation.attributes.surcharges} {this.props.currencyReducer.sendingCurrency.data.attributes.letter_code}</span>
        <span style={{ fontSize: '10px'}}>Reason: {this.props.transactionReducer.transaction.data.attributes.reason_for_transfer_name}</span>
        <span style={{ fontSize: '10px'}}>Source: {this.renderSource(this.props.transactionReducer.transaction.data.attributes.source_of_funds)}</span>
        <span style={{ fontSize: '10px'}}>Payment method: {this.props.paymentMethodReducer.paymentMethod.attributes.name}</span>
        <b style={{ marginTop: '16px' }}>Recipient Details</b>
        <span style={{ fontSize: '10px'}}>Full name: {this.props.transactionReducer.transaction.data.attributes.beneficiary_name ? this.props.transactionReducer.transaction.data.attributes.beneficiary_name : "Not Available"}</span>
        <span style={{ fontSize: '10px'}}>Country: {this.props.countryReducer.receivingCountry.data.attributes.name}</span>
        <b style={{ marginTop: '16px' }}>Sender Details</b>
        <span style={{ fontSize: '10px'}}>Full name: {this.props.userReducer.user.data.attributes.first_name} {this.props.userReducer.user.data.attributes.last_name}</span>
        <span style={{ fontSize: '10px'}}>Customer number/User code: {this.props.userReducer.user.data.attributes.code}</span>
        <span style={{ fontSize: '10px'}}>Country: {this.props.countryReducer.sendingCountry.data.attributes.name}</span>
        <span style={{ fontSize: '10px'}}>Postcode: {this.props.userReducer.user.data.attributes.postcode}</span>
        <span style={{ fontSize: '10px'}}>Address line 1: {this.props.userReducer.user.data.attributes.address_line_1}</span>
        <span style={{ fontSize: '10px'}}>Address line 2: {this.props.userReducer.user.data.attributes.address_line_2}</span>
        <span style={{ fontSize: '10px'}}>City: {this.props.userReducer.user.data.attributes.city}</span>
        <span style={{ fontSize: '10px'}}>County: {this.props.userReducer.user.data.attributes.county}</span>
        <b style={{ marginTop: '20px'}}>Declaration</b>
        <p style={{ fontSize: '10px'}}>In terms of Exchange Control Regulation 2(4) the Foreign Exchange provided may only be used for purpose for which it was made available.</p>
        <span style={{ marginTop: '20px', fontSize: '10px'}}>Signature: _________________________________</span>
        <span style={{ marginTop: '8px', fontSize: '10px'}}>Date: {moment().format("YYYY-MM-DD hh:mm:ss a")}</span>
        <p style={{ marginTop: '20px', fontSize: '10px'}}>Please ensure that you retain this receipt for five years as proof of your currency and the proceeds of any foreign currency obtained through this transaction may not be placed at the disposal of third parties normally resident in South Africa..</p>

        <span style={{ marginTop: '20px', textAlign: 'center', fontSize: '8px'}}>Ecocash Remit, Worcestor House, Eton Office Park, Corner Sloane and Harrison Avenue, Bryanston, Johannesburg, South Africa</span>
        <span style={{ textAlign: 'center', fontSize: '8px'}}>Ph: +27 87 238 0251 | Ph: +27 11 996 5500 Call Centre: +27 10 120 0800</span>
        <span style={{ textAlign: 'center', fontSize: '8px'}}>Ecocash Remit is regulated by the South African Reserve Bank (SARB).</span>
      </div>
    )
  }

  renderSource(source) {
    this.state.sourceOfFunds.filter((obj) => {
      if (obj[0] === source) {
        source = obj[1];
      }
      return obj;
    })
    return source
  }
  componentDidUpdate(prevProps) { }
}*/

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
