import React from "react";
import Log from "../utils/log.js";
import Helper from '../utils/helper.js';
import { Form, Layout, Select, InputNumber, message } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchRemitHopeCalculateRequest } from "../actions";

const { Content } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const TAG = "SmartRemitHopeCampaign";

const cardStyle = {
  width: 200,
  margin: "0 20px",
  cursor: "pointer",
};

const iconDivStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const imgStyle = {
  objectFit: "cover",
  width: "50%",
  height: "auto",
};

class RemitHopeCampaign extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currency: 0,
      amount: 0,
      currencies: this.props.remitHopeReducer.product.included.filter((include) => include.type == "currencies"),
      getFieldDecorator: this.props.form,
      sendingAmount: 0,
      receivingAmount: 0,
      isReverse: false,
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    
    const campaign = this.props.location.state.campaign;
    const category = this.props.location.state.category;

    const optionalCity = campaign.meta_data.find((data) => data.key === "_nf_location").value;
    const optionalCountry = campaign.meta_data.find((data) => data.key === "wpneo_country").value;
    const fundingGoal = campaign.meta_data.find((data) => data.key === "_nf_funding_goal").value;
    const fundsRaised = campaign.wpcf_product.total_raised;
    const raisedPercentage = campaign.wpcf_product.raised_percent;

    const imageSrc = campaign.images.length > 0 ? campaign.images[0].src : "https://remithope.org/wp-content/uploads/2024/05/remit-hope-constant-default.jpeg"
    
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div 
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: 30,
              margnBottom: "20px",
            }}
          >
            <img style={imgStyle} src={imageSrc} alt="" />
            <div
              style={{
                display: "flex",
                alignContent: "flex-start",
                flexDirection: "column",
              }}
              >
              <h3 style={{textAlign: "start"}}>{campaign.name}</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  marginBottom: 40,
                }}
              >
                <CampaignDetails 
                  label="Funding Goal"
                  value={`$${fundingGoal}`}
                />
                <CampaignDetails 
                  label="Funds Raised"
                  value={`$${fundsRaised}`}
                />
                <CampaignDetails 
                  label="Raised Percent"
                  value={`${raisedPercentage}`}
                />
              </div>
              <Form onSubmit={this.handleSubmit.bind(this)}>
                <div className="remit-hope-donation-amount">
                  {this.renderCurrencies()}
                  {this.renderAmount(getFieldDecorator)}
                </div>
                <button type="submit" className="quote-btn-register btn btn-primary">Donate</button>
              </Form>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <h4>Campaign Story</h4>
            <div style={{ fontFamily: "Lato" }}
              dangerouslySetInnerHTML={{
                __html:
                campaign.description
              }}
            />
          </div>
        </Content>
      </Layout>
    );
  }

  renderCurrencies() {

    if (Object.keys(this.state.currencies).length > 0) {
      return (
        <Select value={this.state.currencies[this.state.currency].attributes.letter_code}
          style={{ width: '80px', marginRight: 4 }}
          onChange={this.onChangeCurrency}>
          {this.state.currencies.map((itemAr, index) => {
            return (
              <Option key={index}>{itemAr.attributes.letter_code}</Option>
            );
          })}
        </Select>
      )
    } else {
      return (<div />)
    }
  }

  renderAmount(getFieldDecorator) {
    if (Object.keys(this.state.currencies).length > 0) {
      return (
        <div style={{ width: '90%' }}>
          <FormItem>
            {getFieldDecorator('amount', {
              rules: [{ required: true, message: 'This field is required' },
              { validator: this.validateAmountInput }],
            })(
              <InputNumber className="form-control"
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                placeholder="Amount"
                onChange={this.onChangeAmount} />
            )}
          </FormItem>
        </div>
      )
    } else {
      return (<div />)
    }
  }

  onChangeCurrency = (value) => {
    const selectedCurrency = this.state.currencies[this.state.currency];
    const receivingCurrencies = this.props.remitHopeReducer.product.data.relationships.receiving_currencies.data;
    const isReverse = receivingCurrencies.some((currency) => selectedCurrency.id == currency.id);

    this.setState({
      currency: value,
      isReverse,
    }, this.makeRequest);
  }


  onChangeAmount = (rawValue) => {
    this.props.form.setFieldsValue({
      amount: rawValue,
    });

    this.setState({
      amount: rawValue,
    }, this.makeRequest);
  }

  makeRequest() {
    const receivingCurrencyId = this.props.remitHopeReducer.product.data.relationships.receiving_currencies.data[0].id;
    const sendingCurrencyId = this.props.remitHopeReducer.product.data.relationships.sending_currencies.data[0].id;

    let payload = {
      data: {
        attributes: {
          amount: this.state.amount,
          receive: !this.state.isReverse,
        },
        id: null,
        relationships: {
          receiving_currency: {
            data: {
              id: receivingCurrencyId,
              type: "currencies",
            },
          },
          sending_currency: {
            data: {
              id: sendingCurrencyId,
              type: "currencies",
            },
          },
        },
        type: "remit_hope_calculation_requests",
      },
    };

    const accessToken = this.props.authReducer.accessToken;

    if(Number(this.state.amount) > 0) {
      setTimeout(() => {
        this.props.fetchRemitHopeCalculateRequest(accessToken, payload);
      }, 700);
    }
  }

  validateAmountInput = (rule, value, callback) => {
    if (Helper.isValidAmount(value)) {
      callback();
    } else {
      callback('Invalid amount. Amount should be positive and have maximum 2 decimal places');
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const receivingMinLimit = this.props.remitHopeReducer.calculate.data.attributes.receiving_min_limit;
        const sendingMinLimit = this.props.remitHopeReducer.calculate.data.attributes.sending_min_limit;

        const letterCode = this.state.currencies[this.state.currency].attributes.letter_code;

        if(!this.state.isReverse) {
          if(Number(this.state.amount) < Number(receivingMinLimit)) {
            message.error(`Please enter amount of ${letterCode}${receivingMinLimit} or more.`, 5);
          } else {
            this.props.history.push({
              pathname: "/remit-hope/payment-options",
              state: {
                campaign: this.props.location.state.campaign,
              }
            });
          }
        } else {
          if(Number(this.state.amount) < Number(sendingMinLimit)) {
            message.error(`Please enter amount of ${letterCode}${sendingMinLimit} or more.`, 5);
          } else {
            this.props.history.push({
              pathname: "/remit-hope/payment-options",
              state: {
                campaign: this.props.location.state.campaign,
              }
            });
          }
        }
      }
    });
  }
}

const CampaignDetails = ({label, value}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <span
        style={{
          fontFamily: "Lato",
          fontSize: "14px",
          fontWeight: 800,
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontFamily: "Lato",
          fontSize: "14px",
        }}
      >
        {label}
      </span>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchRemitHopeCalculateRequest,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(RemitHopeCampaign));
