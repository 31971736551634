import React from "react";
import { Layout } from "antd";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import Log from "../../utils/log.js";
import { withCookies } from "react-cookie";
import SmartTransactionNumber from "../../containers/TransactionNumber.js";
import SmartGuestTransactionNumber from "../../containers/GuestTransactionNumber.js";
import "../../styles/transaction.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Header, Footer } = Layout;
const TAG = "TransactionNumber";

class TransactionNumber extends React.Component {

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    document.title = "Transaction Number";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout
        style={{
          background:
            "linear-gradient(to right, rgb(41, 108, 187), #154989)",
        }}
      >
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        {!!this.props.authReducer.accessToken ? (
          <SmartTransactionNumber {...this.props} />
        ) : (
          <SmartGuestTransactionNumber {...this.props} />
        )}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(TransactionNumber));
