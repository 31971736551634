import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class Tariffs extends React.Component {

  componentDidMount() {
    document.title = "Our Tariffs/Charges"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
          <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="dashboard-content-container">
          <div className="dashboard-body">
            <h2>Our Tariff/Charges</h2>
          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(Tariffs)
