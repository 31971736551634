import React from "react";
import { Layout, Button } from "antd";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import {
  requestAirtimeDataTransactionDetails,
  requestReceivingCurrency,
  requestSendingCurrency,
  requestReceivingCountry,
  fetchUserDetails,
  requestSendingCountry,
  showCalculation,
  showPaymentMethod,
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "../styles/user.css";
import checkIcon from "../images/check_icon.png";

const { Content } = Layout;
const TAG = "SmartGuestTransactionNumber";

class GuestTransactionNumber extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      Object.keys(this.props.transactionReducer.transaction).length > 0 &&
      prevProps.transactionReducer.transaction !==
      this.props.transactionReducer.transaction
    ) {
      Log.d(TAG, "componentDidUpdate() -- set transaction");
      var included = this.props.transactionReducer.transaction.included;
      var countryID = "0";
      var sendingCurrencyID = "0";
      var receivingCurrencyID = "0";

      included.filter((obj) => {
        if (obj.type === "calculations") {
          this.props.showCalculation(obj);
          sendingCurrencyID = obj.relationships.sending_currency.data.id;
          receivingCurrencyID = obj.relationships.receiving_currency.data.id;
          this.requestCurrencyDetails(sendingCurrencyID, true);
          this.requestCurrencyDetails(receivingCurrencyID, false);
        } else if (obj.type === "beneficiaries") {
          countryID = obj.relationships.country.data.id;
        } else if (obj.type === "payment_methods") {
          this.props.showPaymentMethod(
            obj,
            this.props.transactionReducer.transaction.data.attributes
              .reason_for_transfer_name,
            this.props.transactionReducer.transaction.data.attributes
              .source_of_funds
          );
        }
        return obj;
      });

      if (countryID !== "0") {
        this.requestCountryDetails(countryID);
      }
    }
  }

  requestCurrencyDetails(id, isSending) {
    if (isSending) {
      this.props.requestSendingCurrency(id);
    } else {
      this.props.requestReceivingCurrency(id);
    }
  }

  requestCountryDetails(id) {
    this.props.requestReceivingCountry(id);
  }

  componentDidMount() {
    this.checkTransactionID();
  }

  render() {
    return (
      <Content
        style={{
          marginRight: "10vw",
          marginLeft: "10vw",
          background: "white",
          height: "80vh",
        }}
        className="tr-item-content-container"
      >
        {this.renderCheckIcon()}
        {this.renderTransaction()}
        {Helper.baseCountryIsSA && this.renderStatus()}
        {/* <span>Have a good day!</span> */}
        <Button
          className="home-collect"
          style={{
            backgroundColor: "#154989",
            boxSizing: "border-box",
            color: "white",
            marginTop: "20px",
          }}
          type="primary"
          onClick={this.loadHome}
        >
          Ok
        </Button>
      </Content>
    );
  }

  loadHome = () => {
    this.props.history.push({
      pathname: "/",
    });
  };

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  };

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  };

  renderStatus() {
    if (Object.keys(this.props.transactionReducer.transaction).length > 0) {
      var status = Helper.showStatus(
        this.props.transactionReducer.transaction.data.attributes.status
      );

      if (
        this.props.transactionReducer.transaction.data.attributes.status ===
        "P" ||
        this.props.transactionReducer.transaction.data.attributes.status === "S"
      ) {
        const rawDate =
          this.props.transactionReducer.transaction.data.attributes.expiry_date;

        const date = moment(rawDate).format("D MMM YYYY");
        const time = moment(rawDate).format("HHmm");

        status = `This quote will expire on ${date} at ${time}hrs`;
      }

      return (
        <span
          style={{
            marginRight: "10vw",
            marginLeft: "10vw",
            marginBottom: "1vh",
          }}
        >
          Status: {status}
        </span>
      );
    }
    return <span />;
  }

  renderTransaction() {
    if (
      Object.keys(this.props.transactionReducer.transaction).length > 0
    ) {
      var userInstruction =
        "Your transaction code: " +
        this.props.transactionReducer.transaction.data.attributes.code;

      if (
        this.props.transactionReducer.transaction.data.attributes.status === "S"
      ) {
        return (
          <h4 className="tr-number-message">
            Thank you for using Sasai Money Transfer. Your transaction has been successful.
            Your transaction code is: {this.props.transactionReducer.transaction.data.attributes.code}
          </h4>
        );
      } else if (
        this.props.transactionReducer.transaction.data.attributes.status === "E"
      ) {
        return (
          <h4 className="tr-number-message">
            Your quote has expired. Your transaction code:{" "}
            {this.props.transactionReducer.transaction.data.attributes.code}
          </h4>
        );
      } else {
        if (
          this.props.transactionReducer.transaction.included[0].attributes
            .payment_type === "PA"
        ) {
          const amountToPay = this.props.airtimeDataProductReducer.product.attributes.amount;
          const sendingCurrency = this.props.airtimeDataProductReducer.product.relationships.currency;
          userInstruction = "Kindly visit your nearest agent and pay " + sendingCurrency.data.letter_code + " " + amountToPay + " using the following transaction reference: " + this.props.transactionReducer.transaction.data.attributes.code;

          return (
            <h4 className="tr-number-message">Your order has been successfully generated. {userInstruction}</h4>
          )
        }

        if (
          this.props.transactionReducer.transaction.included[0].attributes
            .payment_type === "CD" ||
          this.props.transactionReducer.transaction.included[0].attributes
            .payment_type === "NC" ||
          this.props.transactionReducer.transaction.included[0].attributes
            .payment_type === "EF"
        ) {
          return (
            <h4 className="tr-number-message">
              Thank you for using Sasai Money Transfer. Your transaction has been successful.
              Your transaction code is: {this.props.transactionReducer.transaction.data.attributes.code}
            </h4>
          );
        }

        return (
          <h4 className="tr-number-message">
            Thank you for using Sasai Money Transfer. Your transaction has been successful.
            Your transaction code is: {this.props.transactionReducer.transaction.data.attributes.code}
          </h4>
        );
      }
    }
    return <h2 className="tr-item-body">No transaction done.</h2>;
  }

  renderCheckIcon() {
    Log.d(TAG, "renderCheckIcon() -- props=" + JSON.stringify(this.props));
    if (
      Object.keys(this.props.transactionReducer.transaction).length > 0
    ) {
      return <img className="tr-number-check" src={checkIcon} alt="check" />;
    }
    return <span />;
  }

  checkTransactionID() {

    if (
      this.props.match.params.transactionID
    ) {
      var transactionID = this.props.match.params.transactionID;
      Log.d(
        TAG,
        "checkTransactionID() -- ID=" +
        transactionID
      );
      this.requestAirtimeDataTransactionDetails(
        transactionID
      );
    } else if (this.props.match.params.transactionID) {
      Log.d(TAG, "checkTransactionID() -- load webview");
      window.eftSuccess(this.props.match.params.transactionID);
    } else {
    }
  }

  requestAirtimeDataTransactionDetails(id) {
    if (!!this.props.authReducer.guestUserAccessToken) {
      this.props.requestAirtimeDataTransactionDetails(id, this.props.authReducer.guestUserAccessToken);
    } else if (!this.props.authReducer.guestUserAccessToken &&
      Object.keys(this.props.transactionReducer.transaction).length === 0) {
      this.props.requestAirtimeDataTransactionDetails(id, null);
    }
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestAirtimeDataTransactionDetails,
      requestReceivingCurrency,
      requestSendingCurrency,
      requestReceivingCountry,
      fetchUserDetails,
      requestSendingCountry,
      showCalculation,
      showPaymentMethod,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestTransactionNumber);
