import React from "react";
import { Layout, Button } from "antd";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { message } from "antd";
import moment from "moment";
import "../styles/user.css";
import checkIcon from "../images/check_icon.png";

const { Content } = Layout;
const TAG = "SmartReferralRedeemedAmountSummary";

class ReferralRedeemedAmountSummary extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.success &&
      prevProps.userReducer.success !== this.props.userReducer.success
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set success=" + this.props.userReducer.success
      );
      message.success("" + this.props.userReducer.success, 5);
    }
  }

  render() {
    if (!!this.props.authReducer.accessToken) {
      return (
        <Content
          style={{
            marginRight: "10vw",
            marginLeft: "10vw",
            background: "white",
            height: "80vh",
          }}
          className="tr-item-content-container"
        >
          {this.renderCheckIcon()}
          {this.renderTransaction()}
          <Button
            className="home-collect"
            style={{
              backgroundColor: "#154989",
              boxSizing: "border-box",
              color: "white",
              marginTop: "20px",
            }}
            type="primary"
            onClick={this.loadHome}
          >
            Ok
          </Button>
        </Content>
      );
    } else {
      this.props.history.push("/");
    }
  }

  loadHome = () => {
    this.props.history.push({
      pathname: "/",
    });
  };

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  };

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  };

  renderTransaction() {
    if (this.props.location.state.redeemOption === "airime-data-bundle") {
      const letterCode = this.props.location.state.letterCode;
      const amount =
          this.props.referralsReducer.airtimeDataBundle.data.attributes
            .amount_to_pay;

      return (
        <div>
          <h4 className="tr-number-message">
            You have successfully redeemed your commissioned earned for an
            airtime balance of {letterCode} {amount}
          </h4>
          <br/>
          <h4 className="tr-number-message">Have a great day.</h4>
        </div>
      );
    } else if (this.props.location.state.redeemOption === "voucher") {
      const letterCode = this.props.location.state.letterCode;
      const amount = this.props.location.state.amount;
      const promoCode =
          this.props.referralsReducer.voucher.data.attributes.promocode;

      return (
        <div>
          <h4 className="tr-number-message">
            You have successfully redeemed your commissioned earned for a free
            money transfer voucher of {letterCode} {amount}. Use the
            promotional code {promoCode} when sending money to your
            loved ones and friends.
          </h4>
          <br/>
          <h4 className="tr-number-message">Have a great day.</h4>
        </div>
      );
    }

    return <h4 className="tr-number-message">Have a great day.</h4>;
  }

  renderCheckIcon() {
    return <img className="tr-number-check" src={checkIcon} alt="check" />;
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralRedeemedAmountSummary);
