import React from 'react';
import Log from '../../utils/log.js';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import UserLayout from '../../containers/UserProfile.js'
import '../../styles/user.css'


const {  Header, Footer } = Layout;
const TAG = "DumbUpdateUserProfile";

class UpdateUserProfile extends React.Component {

  componentDidMount() {
    document.title = "Update Profile"
    window.scrollTo(0, 0)
  }

  render() {
    Log.d(TAG, "render() -- called ..");
    return (
      <div className="wrapper">
        <Layout>
            <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
              <CustomHeader {...this.props} />
            </Header>
            <UserLayout {...this.props}/>
            <Footer className="custom-footer">
              <CustomFooter/>
            </Footer>
          </Layout>
        </div>

    )
  }
}

export default withCookies(UpdateUserProfile)
