import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import '../styles/dashboard.css'

const { Content, Header, Footer } = Layout;

class FindAgent extends React.Component {

  componentDidMount() {
    document.title = "Find Agent"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
          <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <div className="misc-top-find-agent">
          <div style={{ backgroundColor: 'rgba(12, 92, 169, 0.74)', width: '100%', height: '100%'}}/>
          <h2 className="misc-top-title">FIND AN AGENT</h2>
        </div>
        <Content className="faq-content-container">
          <div className="faq-body">

          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(FindAgent)
