import React from "react";
import Log from "../utils/log.js";
import { requestBWBeneficiaries, fetchUserDetails } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Layout, Button, Popover, Checkbox, message } from "antd";
import "../styles/dashboard.css";
import breadwinner from "../images/breadwinner.png";
import hollardLogo from "../images/hollard_logo.png";
import { Link } from "react-router-dom";

const { Content } = Layout;
const TAG = "SmartBreadwinner";

class BreadWinner extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "BreadWinner::constructor() -- props=" + JSON.stringify(props));
    this.state = {
      hideNext: true,
      hidePolicyArea: true,
      next: "Opt In",
      popoverVisible: false,
    };
  }

  loadRecipients = () => {
    Log.d(TAG, "loadRecipients() -- called ..");
    this.setState({
      popoverVisible: false
    });
    this.props.history.push({
      pathname: "/recipient-list",
      state: {
        bwPolicy: true,
        sendingCountry: {},
        receivingCountry: {},
        sendingCurrency: {},
        receivingCurrency: {},
        product: {},
        calculation: {},
        beneficiarySettings: {},
        transferReasons: [],
        defaultTransferReason: "",
        acceptedBeneficiaryTypes: [],
        acceptedRecipientTypes: [],
        paymentMethods: []
      }
    });
  };

  loadUpdateRecipient(bwBeneficiary) {
    this.setState({
      popoverVisible: false
    });
    this.props.history.push({
      pathname: "/manage-breadwinner-policy",
      state: {
        recipientId: null,
        bwBeneficiary: bwBeneficiary,
        recipient: {}
      }
    });
  }

  componentWillMount() {
    Log.d(TAG, "componentWillMount() -- called ..");
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.accessToken !== '') {
      Log.d(TAG, "componentWillMount() -- request a user");
      Log.d(
        TAG,
        // "componentWillMount() -- access=" + this.props.cookies.cookies.access
        "componentWillMount() -- access=" + this.props.authReducer.accessToken
      );
      Log.d(
        TAG,
        "componentWillMount() -- cookies=" +
          // JSON.stringify(this.props.cookies.cookies.access)
          JSON.stringify(this.props.authReducer.accessToken)
      );
      this.props.fetchUserDetails(
        this.props.cookies,
        // this.props.cookies.cookies.access,
        this.props.authReducer.accessToken,
        true,
        null
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(this.props.userReducer.user).length > 0 &&
      prevProps.userReducer.user !== this.props.userReducer.user
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- user=" +
          JSON.stringify(this.props.userReducer.user)
      );
      /*if (!this.props.userReducer.user.data.attributes.phone_verified) {
        message.error("Please verify your phone first.", 5)
        Log.d(TAG, "componentDidUpdate() -- verify phone");
        this.props.history.push({
          pathname: "/update-profile",
          state: {
            defaultActiveKey : "7",
          }
        });
      } else {*/
      for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
        if (
          this.props.userReducer.user.included[i].type ===
          "breadwinner_policies"
        ) {
          var bwStatus = this.props.userReducer.user.included[i].attributes
            .policy_status;

          this.setState({
            hideNext: bwStatus === "N" || bwStatus === "D",
            bwStatus: bwStatus
          });

          /*this.setState({
              next: 'Update Beneficiary',
              hidePolicyArea: false,
              hideNext: false
            })
            this.props.requestBWBeneficiaries(this.props.userReducer.user.data.id, this.props.cookies.cookies.access);*/

          if (bwStatus === "R") {
            this.setState({
              next: "Opt In",
              hidePolicyArea: true
            });
          } else if (bwStatus === "P" || bwStatus === "A") {
            this.setState({
              next: "Add/Update Beneficiary",
              hidePolicyArea: false
            });
            this.props.requestBWBeneficiaries(
              this.props.userReducer.user.data.id,
              // this.props.cookies.cookies.access
              this.props.authReducer.accessToken
            );
          } else {
            this.setState({
              hidePolicyArea: true
            });
          }
        }
      }
      //}
    }
  }

  render() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      //if (this.props.userReducer.user.data.attributes.phone_verified) {
      return (
        <div className="userprofile-content-container">
          <Content className="breadwinner-content-container">
            <img
              className="breadwinner-logo"
              src={breadwinner}
              alt="breawdinner"
            />
            <span style={{ textAlign: "center" }}>
              The Breadwinner rewards you by paying out a monthly sum to your 
              chosen beneficiary in Zimbabwe after you have passed on. You don't 
              pay a monthly premium, it is at no additional cost.
            </span>
            <br/>
            {this.state.hidePolicyArea ? (
              <span style={{ textAlign: "center", marginTop: "20px" }}>
                <Checkbox
                  onChange={e => {
                    this.setState({ accepted: e.target.checked });
                  }}
                />
                &nbsp; Accept&nbsp;
                <Link to="/breadwinner-terms-of-use">
                  <u>Terms and Conditions</u>
                </Link>
              </span>
            ) : null}

            {this.renderPolicyArea()}
            {(this.state.accepted || (this.state.bwStatus === "P" || this.state.bwStatus === "A" )) ? (
              this.renderNext()
            ) : (
              <Button
                className="home-collect"
                style={{
                  borderRadius: 15 + "px",
                  backgroundColor: "#154989",
                  boxSizing: "border-box",
                  color: "white",
                  marginBottom: "8px",
                  width: "100%",
                  marginTop: "20px",
                  padding: "10px 0",
                }}
                onClick={() => {
                  message.warning("Please  accept the terms and conditions.");
                }}
                type="primary"
              >
                {this.state.next}
              </Button>
            )}
            <span style={{ textAlign: "center", marginTop: "60px" }}>
              Underwritten By:
            </span>
            <img className="hollard-logo" src={hollardLogo} alt="hollard" />
            {!this.state.hidePolicyArea > 0 ? (
              <span style={{ textAlign: "center", marginTop: "20px" }}>
                View{" "}
                <Link to="/breadwinner-terms-of-use">Terms and Conditions</Link>
              </span>
            ) : null}
            <p>
              <br></br>
              <i>
                <font size="1" style={{ lineHeight: 1.3 }}>
                  Underwritten by Hollard Life Assurance Company
                  Limited(1993/001405/06) an Authorised Financial Services
                  Provider. Sasai is a juristic representative of Hollard.
                  Terms and Conditions apply
                </font>
              </i>
            </p>
          </Content>
        </div>
      );
      //}
      /*return (
        <div className="userprofile-content-container">Phone is not yet verified.</div>
      )*/
    }
    return <div className="userprofile-content-container" />;
  }

  getPolicyStatus(policyStatus) {
    if (policyStatus === "N") {
      return "Not Ready";
    }
    if (policyStatus === "R") {
      return "Ready";
    }
    if (policyStatus === "P") {
      return "Pending";
    }
    if (policyStatus === "A") {
      return "Active";
    }
    if (policyStatus === "D") {
      return "Declined";
    }
    return policyStatus;
  }

  renderPolicyStatus() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
        if (
          this.props.userReducer.user.included[i].type ===
          "breadwinner_policies"
        ) {
          return (
            <div className="breadwinner-policy-container">
              <div className="breadwinner-policy-child-container">
                <span style={{ fontFamily: "Lato-Bold", color: "rgb(41, 108, 187)" }}>
                  Policy Status:
                </span>
                <span style={{ textAlign: "end" }}>
                  {this.getPolicyStatus(
                    this.props.userReducer.user.included[i].attributes
                      .policy_status
                  )}
                </span>
              </div>
            </div>
          );
        }
      }
    }
  }

  handleVisibleChange = visible => {
    this.setState({
      popoverVisible: visible
    });
  };

  renderOptions() {
    return (
      <div style={{ display: "block" }}>
        <Button
          style={{ margin: "8px" }}
          onClick={() => this.loadUpdateRecipient({})}
        >
          Create a breadwinner policy beneficiary
        </Button>
        {this.renderCreateFromRecipients()}
        {this.renderUpdateBWBeneficiary()}
      </div>
    );
  }

  renderCreateFromRecipients() {
    if (this.props.userReducer.user.data.attributes.phone_verified) {
      return (
        <Button style={{ margin: "8px" }} onClick={this.loadRecipients}>
          Create from existing transaction beneficiaries
        </Button>
      );
    }
  }

  renderUpdateBWBeneficiary() {
    if (this.props.bwPolicyReducer.bwBeneficiaries.length > 0) {
      return (
        <Button
          style={{ margin: "8px" }}
          onClick={() =>
            this.loadUpdateRecipient(
              this.props.bwPolicyReducer.bwBeneficiaries[0]
            )
          }
        >
          Update existing breadwinner policy beneficiary
        </Button>
      );
    }
  }

  renderNext() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      if (!this.state.hideNext) {
        return (
          <Popover
            content={this.renderOptions()}
            title="Choose Action"
            trigger="click"
            visible={this.state.popoverVisible}
            onVisibleChange={this.handleVisibleChange}
          >
            <Button
              className="home-collect"
              style={{
                backgroundColor: "#154989",
                boxSizing: "border-box",
                color: "white",
                marginBottom: "8px",
                width: "100%",
                marginTop: "20px",
                padding: "10px 0",
              }}
              type="primary"
            >
              {this.state.next}
            </Button>
          </Popover>
        );
      }
    }
  }

  renderPolicyArea() {
    if (Object.keys(this.props.userReducer.user).length > 0) {
      for (var i = 0; i < this.props.userReducer.user.included.length; i++) {
        if (
          this.props.userReducer.user.included[i].type ===
          "breadwinner_policies"
        ) {
          if (!this.state.hidePolicyArea) {
            return (
              <div className="breadwinner-policy-container">
                <div className="breadwinner-policy-child-container">
                  <span style={{ fontFamily: "Lato-Bold", color: "rgb(41, 108, 187)" }}>
                    Policy Status:
                  </span>
                  <span style={{ textAlign: "end" }}>{this.getPolicyStatus(
                    this.props.userReducer.user.included[i].attributes
                      .policy_status
                  )}</span>
                </div>
                <div className="breadwinner-policy-child-container">
                  <span style={{ fontFamily: "Lato-Bold", color: "rgb(41, 108, 187)" }}>
                    Policy Number:
                  </span>
                  <span style={{ textAlign: "end" }}>
                    {this.props.userReducer.user.included[i].attributes
                      .policy_number
                      ? this.props.userReducer.user.included[i].attributes
                          .policy_number
                      : "Not Available"}
                  </span>
                </div>
                <div className="breadwinner-policy-child-container">
                  <span style={{ fontFamily: "Lato-Bold", color: "rgb(41, 108, 187)" }}>
                    Beneficiary:
                  </span>
                  <span style={{ textAlign: "end" }}>
                    {this.getBeneficiary()}
                  </span>
                </div>
              </div>
            );
          }
        }
      }
    }
  }

  getBeneficiary() {
    if (this.props.bwPolicyReducer.bwBeneficiaries.length > 0) {
      return (
        this.props.bwPolicyReducer.bwBeneficiaries[0].attributes.first_name +
        " " +
        this.props.bwPolicyReducer.bwBeneficiaries[0].attributes.last_name
      );
    }
    return "Not Available";
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestBWBeneficiaries,
      fetchUserDetails
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadWinner);
