import React from 'react';
import { Layout, Row, Col } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import picknpayLogo from '../../images/pick-n-pay-logo.png'
import kazangLogo from '../../images/kazang-logo.png'
import smartcallLogo from '../../images/smart-call-logo.png'
import stewardBankLogo from '../../images/steward_bank.png'
import econetLogo from '../../images/econet_logo.png'
import cassavaRemitLogo from '../../images/cassava_logo.png'
import blueLabelLogo from '../../images/blue-label-telecoms.png'
import '../../styles/dashboard.css'


const { Content, Header, Footer } = Layout;

class Partners extends React.Component {

  componentDidMount() {
    document.title = "How It Works"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="dashboard-body">
          <h2 className="partners-text">Partners</h2>
          <Row gutter={16}>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={picknpayLogo} alt="pick-n-pay-logo"/>
            </Col>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={kazangLogo} alt="kazang-logo"/>
            </Col>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={blueLabelLogo} alt="blue-label-telecoms"/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={smartcallLogo} alt="smart-call-logo"/>
            </Col>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={stewardBankLogo} alt="steward_bank"/>
            </Col>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={econetLogo} alt="econet_logo"/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="partners-column" span={8}>
              <img className="partners-logo" src={cassavaRemitLogo} alt="cassavaRemitLogo"/>
            </Col>
          </Row>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

export default withCookies(Partners)
