import React from "react";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import {
  fetchUserDetails,
  requestCardDetails,
  requestTransaction,
  showPaymentMethod,
  showCurrentCard,
  showCardList,
  requestProductDetails,
  requestRecalculation,
  requestCalculation,
  requestRemitHopeTransaction,
} from "../actions";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { Button, Icon, Form, message, Dropdown, Menu } from "antd";
import "../styles/payment.css";
import ManageUrls from "../endpoints/manage_urls.js";
import baseUrlOptions from "../endpoints/base_url_options.js";

const FormItem = Form.Item;
const TAG = "SmartPayment";

class RemitHopePaymentOptions extends React.Component {
  componentDidUpdate(prevProps) { }

  render() {
    return <WrappedRemitHopePaymentOptionsForm {...this.props} />;
  }
}

class RemitHopePaymentOptionsForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));

    var sendingCountry = {};
    var receivingCountry = {};
    var sendingCurrency = {};
    var receivingCurrency = {};
    var product = {};
    var calculation = {};
    var paymentMethods = [];
    var recipient = {};
    var cards = [];
    var transferReasons = [];
    var defaultTransferReason = "";
    var transaction = {};

    if (props.location != null) {
      if (props.location.state != null) {
        sendingCountry = props.location.state.sendingCountry;
        receivingCountry = props.location.state.receivingCountry;
        sendingCurrency = props.location.state.sendingCurrency;
        receivingCurrency = props.location.state.receivingCurrency;
        product = props.location.state.product;
        calculation = props.location.state.calculation;
        paymentMethods = this.filterByInstantPayment(
          props.remitHopeReducer.product.included.filter((included) => {
            return included.type === "payment_methods"
          })
        );
        recipient = props.location.state.recipient;
        cards = props.location.state.cards;
        transferReasons = props.location.state.transferReasons;
        defaultTransferReason = props.location.state.defaultTransferReason;
        transaction = props.location.state.transaction;
      }
    }

    this.state = {
      paymentMethods: this.filterByInstantPayment(paymentMethods),
      paymentMethod: 0,
      cards: cards,
      recipient: recipient,
      sendingCountry: sendingCountry,
      receivingCountry: receivingCountry,
      sendingCurrency: sendingCurrency,
      receivingCurrency: receivingCurrency,
      product: product,
      calculation: calculation,
      sourceOfFunds: [
        ["SAL", "Salary"],
        ["SV", "Savings"],
        ["GF", "Gift"],
        ["PN", "Pension"]
      ],
      user: {},
      transferReasons: transferReasons,
      defaultTransferReason: defaultTransferReason,
      transaction: {},
      paymentOptions: this.formatPaymentOptions(this.props.remitHopeReducer.product.data.attributes.payment_method_options),
      paymentOption: 0,

      payload: {},
    };
  }

  manageUrls = new ManageUrls()
  baseCountryIsSA = () => this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA

  checkIfHasUser() {
    Log.d(TAG, "checkIfHasUser() -- called..");
    var { cookies } = this.props;
    // if (cookies.get("access")) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      if (Object.keys(this.props.userReducer.user).length <= 0) {
        this.props.fetchUserDetails(
          cookies,
          this.props.authReducer.accessToken,
          false,
          "Fetched user's details"
        );
      }
    }
  }

  checkUserAndPaymentMethods(paymentMethods) {
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- product=" +
      JSON.stringify(this.props.remitHopeReducer.product)
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- user=" +
      JSON.stringify(this.props.userReducer.user)
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- p name=" +
      this.props.remitHopeReducer.product.data.attributes.name
    );
    Log.d(
      TAG,
      "checkUserAndPaymentMethods() -- u msisdn=" +
      this.props.userReducer.user.data.attributes.mobile_wallet_msisdn
    );
    if (
      this.props.remitHopeReducer.product.data.attributes.name.includes(
        "Home Wallet"
      ) &&
      !this.props.userReducer.user.data.attributes.mobile_wallet_msisdn
    ) {
      message.error("You have no Mobile Wallet MSISDN", 5);
      this.props.history.push({
        pathname: "/"
      });
    } else {
      Log.d(
        TAG,
        "checkUserAndPaymentMethods() -- paymentMethods=" +
        JSON.stringify(paymentMethods)
      );
      if (paymentMethods.length <= 0) {
        message.error("No payment methods available", 5);
        this.props.history.push({
          pathname: "/"
        });
      } else {
        this.props.form.setFieldsValue({
          payment_method: 0
        });
        this.props.showCardList(0, []);
        this.requestDetailsIfHasCards();
      }
    }
  }

  requestDetailsIfHasCards() {
    var relationships = this.props.userReducer.user.data.relationships;
    if (relationships.hasOwnProperty("saved_cards")) {
      Log.d(TAG, "requestDetailsIfHasCards() -- has saved cards");
      if (relationships.saved_cards.data != null) {
        Log.d(TAG, "requestDetailsIfHasCards() -- data is not null");
        if (relationships.saved_cards.data.length > 0) {
          Log.d(
            TAG,
            "requestDetailsIfHasCards() -- no. of cards is greater than zero"
          );
          this.setState({
            loading: true
          });
          this.props.requestCardDetails(
            relationships.saved_cards.data[0].id,
            // this.props.cookies.cookies.access,
            this.props.authReducer.accessToken,
            0,
            []
          );
        }
      }
    }
  }

  componentDidMount() {
    this.checkIfHasUser();

    var relationships = this.props.userReducer.user.data.relationships;
    if (relationships.saved_cards) {
      if (relationships.saved_cards.data) {
        if (
          this.props.userReducer.currentCardIndex <
          relationships.saved_cards.data.length
        ) {
          this.props.requestCardDetails(
            relationships.saved_cards.data[
              this.props.userReducer.currentCardIndex
            ].id,
            // this.props.cookies.cookies.access,
            this.props.authReducer.accessToken,
            this.props.userReducer.currentCardIndex,
            this.props.userReducer.cardList
          );
        }
      }
    }
  }

  componentDidUpdate(prevProps) {


    if(this.props.calculationReducer.calculation !== prevProps.calculationReducer.calculation) {

      if(Object.keys(this.props.calculationReducer.calculation).length > 0) {
        let payload = this.state.payload;

        payload.data.relationships.crs.data.id = this.props.calculationReducer.calculation.data.id;
  
        const accessToken = this.props.authReducer.accessToken;
  
        this.props.requestRemitHopeTransaction(accessToken, payload);
      }
    }

    if (
      prevProps.remitHopeReducer.product !==
      this.props.remitHopeReducer.product &&
      Object.keys(this.props.remitHopeReducer.product).length > 0
    ) {
      var included = this.props.remitHopeReducer.product.included;
      var attributes = this.props.remitHopeReducer.product.data.attributes;

      var paymentMethods = [];

      for (var i = 0; i < included.length; i++) {
        var includedObj = included[i];
        if (includedObj.type === "payment_methods") {
          paymentMethods.push(includedObj);
        }
      }

      this.setState({
        // transferReasons: attributes.transfer_reasons,
        // defaultTransferReason: this.checkDefaultReason(
        //   attributes.default_transfer_reason
        // ),
        paymentMethods: this.filterByInstantPayment(paymentMethods),
        paymentOptions: this.formatPaymentOptions(attributes.payment_method_options)
      });

      Log.d(
        TAG,
        "componentDidUpdate() -- paymentMethods=" +
        JSON.stringify(paymentMethods)
      );
      this.checkUserAndPaymentMethods(paymentMethods);
    }

    // if (Object.keys(this.props.userReducer.cardList).length < 1) {
      if (this.props.userReducer.currentCardIndex !== prevProps.userReducer.currentCardIndex) {
        Log.d(
          TAG,
          "componentDidUpdate() -- cards=" + this.props.userReducer.cardList
        );
        var relationships = this.props.userReducer.user.data.relationships;
        if (relationships.saved_cards) {
          if (relationships.saved_cards.data) {
            if (
              this.props.userReducer.currentCardIndex <
              relationships.saved_cards.data.length
            ) {
              this.props.requestCardDetails(
                relationships.saved_cards.data[
                  this.props.userReducer.currentCardIndex
                ].id,
                // this.props.cookies.cookies.access,
                this.props.authReducer.accessToken,
                this.props.userReducer.currentCardIndex,
                this.props.userReducer.cardList
              );
            }
          }
        }
      }
    // }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("" + this.props.userReducer.error, 5);
    }

    if (
      prevProps.remitHopeReducer.transaction !==
      this.props.remitHopeReducer.transaction &&
      Object.keys(this.props.remitHopeReducer.transaction).length > 0
    ) {
      if (
        this.props.remitHopeReducer.transaction.hasOwnProperty("redirect")
      ) {
        // redirect on saved cards here...
        window.location = this.props.remitHopeReducer.transaction.redirect;
      } else {
        this.props.history.push({
          pathname:
            "/transaction-number/" +
            this.props.remitHopeReducer.transaction.data.id
        });
      }
    }
  }

  checkDefaultReason(dReason) {
    var defaultReason = dReason;
    if (Object.keys(this.props.userReducer.user).length > 0) {
      var idVerificationType = this.props.userReducer.user.data.attributes
        .id_verification_type;
      Log.d(
        TAG,
        "checkDefaultReason() -- idVerificationType=" + idVerificationType
      );
      if (idVerificationType) {
        if (idVerificationType !== "SI") {
          Log.d(TAG, "checkDefaultReason() -- find remittance");
          for (var tf = 0; tf < this.state.transferReasons.length; tf++) {
            if (this.state.transferReasons[tf][1] === "Remittance") {
              Log.d(TAG, "checkDefaultReason() -- found remittance");
              defaultReason = this.state.transferReasons[tf][0];
            }
          }
        }
      }
    }
    return defaultReason;
  }

  verifyPayment = (values) => {
    if (this.props.userReducer.user.data.attributes.user_status === "A") {
      if (Object.keys(this.state.transaction).length > 0) {
        this.requestTransaction(values);
      } else {
        this.props.showPaymentMethod(
          this.state.paymentMethods[this.state.paymentMethod],
          this.state.transferReasons[
          this.props.form.getFieldValue("transfer_reason")
          ],
          this.state.sourceOfFunds[
          this.props.form.getFieldValue("source")
          ]
        );
        var cardDetails = null;
        var cardObj = {};
        cardObj.data = null;

        if (
          this.state.paymentMethods[this.state.paymentMethod].attributes
            .payment_type === "CD"
        ) {
          Log.d(
            TAG,
            "requestTransaction() -- cards=" +
            JSON.stringify(this.props.userReducer.cardList)
          );
          if (this.props.userReducer.cardList.length > 0) {
            var cardData = {};
            cardData.id = this.props.userReducer.cardList[
              values.saved_card
            ].data.id;
            cardData.type = "cards";
            cardObj.data = cardData;
            cardDetails = this.props.userReducer.cardList[
              values.saved_card
            ].data.attributes.card_type;
            this.props.showCurrentCard(cardDetails, cardObj);
            this.props.history.push({
              pathname: "/payment-form/"
            });
          } else {
            message.error(
              "No saved cards to use. Please select other payment method"
            );
          }
        } else if (
          this.state.paymentMethods[this.state.paymentMethod].attributes
            .payment_type === "NC"
        ) {
          cardDetails = Helper.getCardTypes()[values.card_type][0];
          this.props.showCurrentCard(cardDetails, cardObj);
          this.props.history.push({
            pathname: "/payment-form/"
          });
        } else {
          this.props.history.push({
            pathname: "/payment-form/"
          });
        }
        this.props.showCurrentCard(cardDetails, cardObj);
      }
    } else {
      this.requestTransaction(values);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const method = this.state.paymentMethods[this.state.paymentMethod];
        const option = this.state.paymentOptions[this.state.paymentOption];

        let cardObj = {};
        let attributesObj = {};
        cardObj.data = null;
        // let relationshipObj = {};
        let cardData = {};

        let paymentMethodObj = {};
        let paymentMethodData = {};
        paymentMethodData.id = this.state.paymentMethods[
          this.state.paymentMethod
        ].id;
        paymentMethodData.type = "payment_methods";
        paymentMethodObj.data = paymentMethodData;

        if (values.hasOwnProperty("saved_card")) {
          cardData.id = this.props.userReducer.cardList[values.saved_card].data.id;
          cardData.type = "cards";
          cardObj.data = cardData;
          attributesObj.card_type = this.props.userReducer.cardList[
            values.saved_card
          ].data.attributes.card_type;
        } else if (values.hasOwnProperty("card_type")) {
          Log.d(
            TAG,
            "requestTransaction() -- new card=" +
            Helper.getCardTypes()[values.card_type][1]
          );
          attributesObj.card_type = Helper.getCardTypes()[values.card_type][0];
        } else {
          attributesObj.card_type = null;
        }

        // relationshipObj.payment_method = paymentMethodObj;
        // relationshipObj.card = cardObj;

        const payloadObj = {
          data: {
            attributes: {
              card_type: null,
              external_product_id: this.props.location.state.campaign.id,
              external_product_name: this.props.location.state.campaign.name,
            },
            id: null,
            relationships: {
              card: cardObj,
              crs: {
                data: {
                  type: "calculations",
                  id: this.props.remitHopeReducer.calculate.data.id,
                },
              },
              payment_method: paymentMethodObj,
            },
            type: "remit_hope_transactions",
          },
        };

        this.setState({
          payload: payloadObj,
        })


        const payload = {
          data: {
            type: "calculations",
            id: null,
            attributes: {
              calculation_request: this.props.remitHopeReducer.calculate.data.id,
            },
          },
        };

        this.props.requestCalculation(payload);

      }
    });
  }

  requestProductDetails() {
    Log.d(
      TAG,
      "requestProductDetails() -- product=" +
      JSON.stringify(this.props.remitHopeReducer.product)
    );
    Log.d(
      TAG,
      "requestProductDetails() -- sending country=" +
      JSON.stringify(this.props.countryReducer.sendingCountry)
    );
    Log.d(
      TAG,
      "requestProductDetails() -- receiving country=" +
      JSON.stringify(this.props.countryReducer.receivingCountry)
    );
    
  }

  requestCardDetails(currentIndex, currentSavedCards) {
    Log.d(
      TAG,
      "requestCardDetails() -- currentIndex=" +
      currentIndex +
      " current saved cards=" +
      JSON.stringify(currentSavedCards)
    );
    var access = this.props.authReducer.accessToken;
    Log.d(
      TAG,
      "requestCardDetails() -- current card=" +
      JSON.stringify(this.props.userReducer.cardList[currentIndex])
    );
    if (this.props.userReducer.cardList[currentIndex].hasOwnProperty("id")) {
      var currentID = this.props.userReducer.cardList[currentIndex].id;
      Log.d(TAG, "requestCardDetails() -- currentID=" + currentID);
      this.props.requestCardDetails(
        currentID,
        access,
        currentIndex,
        currentSavedCards
      );
    }
  }

  requestTransaction(values) {
    var cardObj = {};
    var attributesObj = {};
    cardObj.data = null;
    var relationshipObj = {};
    var transactionID = null;

    if (Object.keys(this.state.transaction).length > 0) {
      attributesObj.status = "P";
      transactionID = this.state.transaction.data.id;
    } else {
      var reason = [];
      if (this.props.form.getFieldValue("transfer_reason") !== undefined) {
        Log.d(
          TAG,
          "requestTransaction() -- selected reason=" +
          this.state.transferReasons[
          this.props.form.getFieldValue("transfer_reason")
          ]
        );
        reason = this.state.transferReasons[
          this.props.form.getFieldValue("transfer_reason")
        ];
      }

      var source = [];
      if (this.props.form.getFieldValue("source") !== undefined) {
        Log.d(
          TAG,
          "requestTransaction() -- selected source=" +
          this.state.sourceOfFunds[this.props.form.getFieldValue("source")]
        );
        source = this.state.sourceOfFunds[
          this.props.form.getFieldValue("source")
        ];
      }

      var crsObj = {};
      var crsData = {};
      crsData.id = this.state.calculation.data.id;
      crsData.type = "calculations";
      crsObj.data = crsData;

      var beneficiaryObj = {};
      // if (Object.keys(this.state.recipient).length > 0) {
      //   Log.d(
      //     TAG,
      //     "requestTransaction() -- beneficiary ID=" +
      //     this.state.recipient.data.id
      //   );
      //   var beneficiaryData = {};
      //   beneficiaryData.id = this.state.recipient.data.id;
      //   beneficiaryData.type = "beneficiaries";
      //   beneficiaryObj.data = beneficiaryData;
      // } else {
      //   beneficiaryObj.data = null;
      // }

      if (reason.length > 0) {
        attributesObj.reason_for_transfer = reason[0];
      } else {
        attributesObj.reason_for_transfer = null;
      }

      if (source.length > 0) {
        attributesObj.source_of_funds = source[0];
      } else {
        attributesObj.source_of_funds = null;
      }

      relationshipObj.beneficiary = beneficiaryObj;
      relationshipObj.crs = crsObj;
    }

    var paymentMethodObj = {};
    var paymentMethodData = {};
    paymentMethodData.id = this.state.paymentMethods[
      this.state.paymentMethod
    ].id;
    paymentMethodData.type = "payment_methods";
    paymentMethodObj.data = paymentMethodData;

    if (values.hasOwnProperty("saved_card")) {
      var cardData = {};
      cardData.id = this.props.userReducer.cardList[values.saved_card].data.id;
      cardData.type = "cards";
      cardObj.data = cardData;
      attributesObj.card_type = this.props.userReducer.cardList[
        values.saved_card
      ].data.attributes.card_type;
    } else if (values.hasOwnProperty("card_type")) {
      Log.d(
        TAG,
        "requestTransaction() -- new card=" +
        Helper.getCardTypes()[values.card_type][1]
      );
      attributesObj.card_type = Helper.getCardTypes()[values.card_type][0];
    } else {
      attributesObj.card_type = null;
    }

    relationshipObj.payment_method = paymentMethodObj;
    relationshipObj.card = cardObj;

    var dataObj = {};
    dataObj.type = "transactions";
    dataObj.id = transactionID;
    dataObj.attributes = attributesObj;
    dataObj.relationships = relationshipObj;

    var payloadObj = {};
    payloadObj.data = dataObj;

    Log.d(TAG, "requestTransaction() -- payload=" + JSON.stringify(payloadObj));

    if (!!this.props.authReducer.accessToken) {
      this.props.requestTransaction(
        payloadObj,
        // cookies.get("access"),
        this.props.authReducer.accessToken,
        transactionID
      );
    }
  }

  getSelectAPaymentMethodLabel = () => {
    const paymentOptionObject = this.state.paymentOptions[this.state.paymentOption];
    const paymentOption = paymentOptionObject.option[0];

    switch (paymentOption) {
      case "Cash":
        return "Where to pay";
      case "Card":
        return "Pay using new or saved card";

      default:
        return "Select a payment method";
    }
  }

  render() {
    Log.d(TAG, "render() -- called..");
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="payment-body" onSubmit={this.handleSubmit.bind(this)}>
        {this.renderReasonAndSource(getFieldDecorator)}
        <FormItem className="payment-dropdown" label="How to pay">
          {getFieldDecorator("payment_option", {
            rules: [
              { required: true, message: "Please select a payment option" },
              //{ validator: this.validatePaymentMethod }
            ]
          })(
            <Dropdown
              trigger={["click"]}
              overlay={this.renderPaymentOptionsParent()}
            >
              <div className="dropdown-item">
                {this.renderPaymentOption()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        {(this.state.paymentOption === 0 || this.state.paymentOption === "3" ) ?

          <div></div> :

          <FormItem className="payment-dropdown" label="Where to Pay">
            {getFieldDecorator("payment_method", {
              rules: [
                { required: true, message: "Please select where to pay" },
                { validator: this.validatePaymentMethod }
              ]
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderPaymentMethodsParent()}
              >
                <div className="dropdown-item">
                  {this.renderPaymentMethod()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        }
        {this.renderPaymentMethodDetails()}
        {this.renderSavedCards(getFieldDecorator)}
        {this.renderNewCardTypes(getFieldDecorator)}

        <div style={{ width: "100%", padding: "20px", textAlign: "left" }}>
          <b style={{ width: "100%", color: "red" }}>
            Fields with * are required.
          </b>
        </div>
        <FormItem>
          <Button
            className="home-collect"
            style={{
              backgroundColor: "#154989",
              boxSizing: "border-box",
              color: "white",
              width: "100%"
            }}
            type="primary"
            htmlType="submit"
          >
            {Object.keys(this.props.userReducer.user).length > 0
              ? Object.keys(this.state.transaction).length > 0
                ? "Pay Now"
                : this.props.userReducer.user.data.attributes.user_status ===
                  "A"
                  ? "Review Transaction"
                  : "Proceed"
              : "Proceed"}
          </Button>
        </FormItem>
      </Form>
    );
  }

  findDefaultReason() {
    Log.d(
      TAG,
      "findDefaultReason() -- defaultTransferReason=" +
      this.state.defaultTransferReason
    );
    if (this.state.defaultTransferReason) {
      for (var i = 0; i < this.state.transferReasons.length; i++) {
        if (
          this.state.defaultTransferReason === this.state.transferReasons[i][0]
        ) {
          Log.d(
            TAG,
            "findDefaultReason() -- correct=" + this.state.transferReasons[i][0]
          );
          return i;
        }
      }
    }
    return 0;
  }

  renderReasonAndSource(getFieldDecorator) {
    if (
      Object.keys(this.props.recipientReducer.recipient).length > 0 &&
      this.state.transferReasons.length > 0
    ) {
      return (
        <div>
          <FormItem label="Reason for transfer">
            {getFieldDecorator("transfer_reason", {
              initialValue: this.findDefaultReason()
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderReasonParent()}
              // disabled={true}
              >
                <div className="dropdown-item">
                  {this.renderReason()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
          <FormItem label="Source of funds">
            {getFieldDecorator("source", {
              initialValue: 0
            })(
              <Dropdown trigger={["click"]} overlay={this.renderSourceParent()}>
                <div className="dropdown-item">
                  {this.renderSource()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        </div>
      );
    }
    return <div />;
  }

  renderReasonParent() {
    return <Menu onClick={this.onChangeReason}>{this.renderReasons()}</Menu>;
  }

  onChangeReason = event => {
    this.props.form.setFieldsValue({
      transfer_reason: event.key
    });
  };

  renderReasons() {
    return this.state.transferReasons.map((itemAr, index) => {
      return <Menu.Item key={index}>{itemAr[1]}</Menu.Item>;
    });
  }

  renderReason() {
    if (Object.keys(this.state.transferReasons).length > 0) {
      Log.d(
        TAG,
        "renderReason() -- reason=" +
        JSON.stringify(
          this.state.transferReasons[
          this.props.form.getFieldValue("transfer_reason")
          ]
        )
      );
      return (
        <div>
          {
            this.state.transferReasons[
            this.props.form.getFieldValue("transfer_reason")
            ][1]
          }
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderSourceParent() {
    return <Menu onClick={this.onChangeSource}>{this.renderSources()}</Menu>;
  }

  onChangeSource = event => {
    this.props.form.setFieldsValue({
      source: event.key
    });
  };

  addSelectPaymentMethodOption = (oldPaymentMethods, paymentMethods) => {
    let hasSelectPaymentMethodOption = false;
    oldPaymentMethods.forEach(paymentMethod => {
      if (paymentMethod.attributes.name === "Select a payment method") {
        hasSelectPaymentMethodOption = true
      }
    })

    if (!hasSelectPaymentMethodOption) {
      const selectPaymentMethodOption = { id: "0", attributes: { name: "Select a payment method" } }
      paymentMethods.push(selectPaymentMethodOption)
    }
  }

  formatPaymentOptions = paymentOptions => {
    let newOptions = paymentOptions.map(type => {
      let name = type[0];
      let options = type[1].map(option => {
        let id = option[0];
        let paymentOption = option[1];
        return {
          id, paymentOption
        }
      });
      return { option: [name], options }
    })

    return [{ option: "Choose Payment Option", options: [] }, ...newOptions];
  }

  filterByInstantPayment = oldPaymentMethods => {
    const { cookies } = this.props;
    const paymentMethods = [];
    this.addSelectPaymentMethodOption(oldPaymentMethods, paymentMethods)

    oldPaymentMethods.forEach(item => {
      if (cookies && cookies.get("browserType") === "Sasai") {
        if (
          item.attributes.payment_type === "EF" ||
          item.attributes.payment_type === "SC" ||
          item.attributes.payment_type === "NC"
        ) {
          paymentMethods.push(item);
        }
      } else {
        paymentMethods.push(item);
      }
    });
    Log.d("JSON _____CARD___TYPES______" + JSON.stringify({ paymentMethods }));
    return paymentMethods;
  };

  renderSources() {
    return this.state.sourceOfFunds.map((itemAr, index) => {
      return <Menu.Item key={index}>{itemAr[1]}</Menu.Item>;
    });
  }

  renderSource() {
    return (
      <div>
        {this.state.sourceOfFunds[this.props.form.getFieldValue("source")][1]}
      </div>
    );
  }

  renderNewCardTypes(getFieldDecorator) {
    if (this.state.paymentMethods.length > 0) {
      if (
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.name.includes("Card") &&
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.name.includes("New")
      ) {
        Log.d(TAG, "renderNewCardTypes() -- has new card types");
        return (
          <FormItem className="payment-dropdown" label="Select a card type">
            {getFieldDecorator("card_type", {
              initialValue: 0
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderNewCardTypesParent()}
              >
                <div className="dropdown-item">
                  {this.renderNewCardType()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        );
      }
    }
    return <div />;
  }

  renderSavedCards(getFieldDecorator) {
    if (this.state.paymentMethods.length > 0) {
      Log.d(TAG, "renderSavedCards() -- more payments");
      Log.d(
        TAG,
        "renderSavedCards() -- selected payment=" +
        JSON.stringify(this.state.paymentMethods[this.state.paymentMethod])
      );
      Log.d(
        TAG,
        "renderSavedCards() -- user cards=" +
        JSON.stringify(this.props.userReducer.cardList)
      );
      if (
        this.state.paymentMethods[this.state.paymentMethod].attributes
          .payment_type === "CD" &&
        this.props.userReducer.cardList.length > 0
      ) {
        Log.d(TAG, "renderSavedCards() -- has saved cards");
        return (
          <FormItem
            className="payment-dropdown"
            label="Select a saved card (Last digit ending):"
          >
            {getFieldDecorator("saved_card", {
              initialValue: 0
            })(
              <Dropdown
                trigger={["click"]}
                overlay={this.renderSavedCardsParent()}
              >
                <div className="dropdown-item">
                  {this.renderSavedCard()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        );
      }
    }
    Log.d(TAG, "renderSavedCards() -- has NO saved cards");
    return <div />;
  }

  renderPaymentOptionsParent() {
    return (
      <Menu onClick={this.onChangePaymentOption}>
        {this.renderPaymentOptions()}
      </Menu>
    );
  }

  renderPaymentOptions() {
    return this.state.paymentOptions.map((itemAr, index) => {
      return <Menu.Item key={index} disabled={index === 0}>{itemAr.option}</Menu.Item>;
    });
  }

  renderPaymentOption() {
    if (this.state.paymentOptions.length > 0) {
      return (
        <div>
          {this.state.paymentOptions[this.state.paymentOption].option}
        </div>
      );
    }

    return <div />;
  }

  onChangePaymentOption = event => {
    this.setState({
      paymentOption: event.key,
      paymentMethod: 0,
    });
    this.props.form.setFieldsValue({
      payment_option: event.key,
      payment_method: 0
    });

    if(this.state.paymentOptions[event.key].options[0].paymentOption === "EFT") {
      const index = this.state.paymentMethods.findIndex(method => method.attributes.name === "EFT");
      this.setState({
        paymentMethod: index
      });
    }
  }

  renderPaymentMethodDetails() {
    if (this.state.paymentMethods.length > 0) {
      Log.d(
        TAG,
        "renderPaymentMethodDetails() -- payment method=" +
        JSON.stringify(this.state.paymentMethods[this.state.paymentMethod])
      );
      return (
        <div className="payment-description">
          {/* <b>
            {
              this.state.paymentMethods[this.state.paymentMethod].attributes
                .info_text
            }
          </b> */}
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.paymentMethods[this.state.paymentMethod]
                .attributes.description
            }}
          />
          <span className="payment-important-text">
            {
              this.state.paymentMethods[this.state.paymentMethod].attributes
                .important_text
            }
          </span>
        </div>
      );
    }
    return <div />;
  }

  validatePaymentMethod = (rule, value, callback) => {
    if (value === 0) {
      callback("Please select a payment method")
    }
    callback()
  }

  renderPaymentMethodsParent() {
    return (
      <Menu onClick={this.onChangePaymentMethod}>
        {this.renderPaymentMethods()}
      </Menu>
    );
  }

  renderPaymentMethods() {
    // only render methods for the selected group option
    if (this.state.paymentOption !== 0) {
      let paymentMethods = this.state.paymentMethods.filter((method) => {
        return this.state.paymentOptions[this.state.paymentOption].options.some(option =>
          option.id === method.id || method.id === "0"
        )
      })
      return paymentMethods.map((itemAr, index) => {
        return <Menu.Item key={itemAr.id} disabled={itemAr.id === "0"}>{itemAr.attributes.name}</Menu.Item>;
      });
    }
    return <Menu.Item />
  }

  renderPaymentMethod() {
    if (this.state.paymentMethods.length > 0) {
      return (
        <div>
          {this.state.paymentMethods[this.state.paymentMethod].attributes.name}
        </div>
      );
    }

    return <div />;
  }

  onChangePaymentMethod = event => {
    // we need to get the method index from event...
    let index = this.state.paymentMethods.findIndex(method => method.id === event.key);
    this.setState({
      paymentMethod: index
    });
    this.props.form.setFieldsValue({
      payment_method: index
    });
  };

  renderNewCardTypesParent() {
    return (
      <Menu onClick={this.onChangeNewCardType}>
        {this.renderNewCardTypesList()}
      </Menu>
    );
  }

  renderNewCardTypesList() {
    return Helper.getCardTypes().map((itemAr, index) => {
      if (
        this.state.paymentMethods[
          this.state.paymentMethod
        ].attributes.accepted_card_types.includes(itemAr[0])
      ) {
        return <Menu.Item key={index}>{itemAr[1]}</Menu.Item>;
      }
      return <span />;
    });
  }

  renderNewCardType() {
    const cardIndex = this.props.form.getFieldValue("card_type")
    const cardTypes = Helper.getCardTypes()

    const card = cardTypes[cardIndex][1]

    return (
      <div>
        {card}
      </div>
    );
  }

  onChangeNewCardType = event => {
    this.props.form.setFieldsValue({
      card_type: event.key
    });
  };

  renderSavedCardsParent() {
    return (
      <Menu onClick={this.onChangeSavedCard}>
        {this.renderSavedCardsList()}
      </Menu>
    );
  }

  renderSavedCardsList() {
    return this.props.userReducer.cardList.map((itemAr, index) => {
      Log.d(TAG, "renderSavedCardsList() -- item=" + JSON.stringify(itemAr));
      return (
        <Menu.Item key={index}>
          {itemAr.data.attributes.card_type} -{" "}
          {itemAr.data.attributes.last_digits}
        </Menu.Item>
      );
    });
  }

  renderSavedCard() {
    return (
      <div>
        {
          this.props.userReducer.cardList[
            this.props.form.getFieldValue("saved_card")
          ].data.attributes.card_type
        }{" "}
        -{" "}
        {
          this.props.userReducer.cardList[
            this.props.form.getFieldValue("saved_card")
          ].data.attributes.last_digits
        }
      </div>
    );
  }

  onChangeSavedCard = event => {
    this.props.form.setFieldsValue({
      saved_card: event.key
    });
  };
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchUserDetails,
      requestCardDetails,
      requestTransaction,
      showPaymentMethod,
      showCurrentCard,
      showCardList,
      requestProductDetails,
      requestRecalculation,

      requestCalculation,
      requestRemitHopeTransaction,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

const WrappedRemitHopePaymentOptionsForm = Form.create()(withCookies(RemitHopePaymentOptionsForm));

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemitHopePaymentOptions);
