import {
  Card,
  Form,
  Layout,
  Dropdown,
  Icon,
  Button,
  Menu,
  message,
} from "antd";
import React from "react";
import "../styles/qoute.css";
import Log from "../utils/log";
import { buyReferralAirtimeDataBundle } from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "SELECT PRODUCT OPTION";

class AirtimeDataChooseBundle extends React.Component {
  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedAirtimeDataChooseBundleForm {...this.props} />
        </Content>
      </Layout>
    );
  }
}

class ProductOptionForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(
      TAG,
      "Product Options::constructor() -- props=" + JSON.stringify(props)
    );

    let productOption = "";
    let sendingCountry = {};
    let recipient = {};
    let receivingCountries = [];
    let receivingCountry = {};

    if (props.location != null) {
      if (props.location.state != null) {
        productOption = this.props.location.state.productOption;
        sendingCountry = this.props.location.state.sendingCountry;
        recipient = this.props.location.state.recipient;
        receivingCountries = this.props.location.state.receivingCountries;
        receivingCountry = this.props.location.state.receivingCountry;
      }
    }

    this.state = {
      access: this.props.authReducer.accessToken || "",
      productOption: productOption,
      airtimeDataOptions: [],
      recipient: recipient,
      receivingCountries: receivingCountries,
      sendingCountry: sendingCountry,
      receivingCountry: receivingCountry,
      selectedProductOption: null,
      products: [],
      sendingAmount: "",
    };
  }

  componentDidMount() {
    let airtimeDataOptions =
      this.props.referralsReducer.airtimeDataBundleProducts.data;

    const currencies =
      this.props.referralsReducer.airtimeDataBundleProducts.included.filter(
        (include) => include.type === "currencies"
      );

    airtimeDataOptions = airtimeDataOptions.map((product) => {
      const currency = currencies.find(
        (currency) => product.relationships.currency.data.id === currency.id
      );

      let currencyData = product.relationships.currency.data;
      currencyData.letter_code = currency.attributes.letter_code;

      let newProduct = product;
      newProduct.relationships.currency.data = currencyData;

      this.setState({
        airtimeDataOptions,
        letterCode: currency.attributes.letter_code,
      });

      return newProduct;
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error &&
      Object.keys(this.props.userReducer.error).length > 0
    ) {
      message.error(this.props.userReducer.error);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content style={{ width: "100%" }}>
        <Form
          // onSubmit={this.handleSubmit.bind(this)}
          hideRequiredMark={true}
        >
          <div className="row">
            <div className="col-sm-9">
              <Card
                style={{ fontFamily: "Lato", borderRadius: "4px" }}
                title={`Select airtime or data`}
              >
                {this.renderBuyAirtimeDataForm(getFieldDecorator)}
              </Card>
            </div>
          </div>
        </Form>
      </Content>
    );
  }

  renderBuyAirtimeDataForm(getFieldDecorator) {
    return (
      <div className="home-item" style={{ alignItems: "flex-start" }}>
        <FormItem label={"Airtime/ Data bundle"} className="quote-form--1">
          {getFieldDecorator("product_option", {
            rules: [{ required: true, message: "Please select a product" }],
          })(
            <Dropdown overlay={this.renderOptionsParent()} trigger={["click"]}>
              <div className="dropdown-item">
                {this.renderOptionsDropdown()}
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </FormItem>
        {!this.state.selectedProductOption && (
          <div style={{ width: "100%", textAlign: "center" }}>
            Please select a product
          </div>
        )}
        {this.renderProductOptionDetails()}
        {this.renderFormActions()}
      </div>
    );
  }

  onChangeOption = (event) => {
    this.setState({
      selectedProductOption: event.key,
      sendingAmount:
        this.props.referralsReducer.airtimeDataBundleProducts.data[event.key]
          .attributes.amount,
    });
    this.props.form.setFieldsValue({
      product_option: event.key,
    });
  };

  renderOptionsDropdown() {
    let product = this.state.selectedProductOption
      ? this.props.referralsReducer.airtimeDataBundleProducts.data[
          this.state.selectedProductOption
        ]
      : null;
    return (
      <div>
        {product
          ? `${product.attributes.name} | ${product.relationships.currency.data.letter_code} ${product.attributes.amount}`
          : "Select Product"}
      </div>
    );
  }

  renderProductOptionDetails() {
    let product = this.state.selectedProductOption
      ? this.props.referralsReducer.airtimeDataBundleProducts.data[
          this.state.selectedProductOption
        ]
      : null;

    return (
      product && (
        <div
          style={{
            display: "flex",
            width: "100%",
            padding: 16,
            marginTop: 16,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6>Details:</h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <p>Amount to pay</p>
            <p>
              {`${product.relationships.currency.data.letter_code} ${product.attributes.amount}`}
            </p>
          </div>
          <div>
            <p>{product.attributes.name}</p>
          </div>
        </div>
      )
    );
  }

  renderOptionsParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeOption}
      >
        {this.renderOptions()}
      </Menu>
    );
  }

  renderOptions() {
    return this.state.airtimeDataOptions.map((item, index) => {
      return (
        <Menu.Item
          key={index}
        >{`${item.attributes.name} | ${item.relationships.currency.data.letter_code} ${item.attributes.amount}`}</Menu.Item>
      );
    });
  }

  onChangeSendingAmount = (e) => {
    let amount = e.target.value;
    this.setState({ sendingAmount: amount }, () => {
      this.props.form.setFieldsValue({
        sending_amount: amount,
      });
    });
  };

  preparePayload() {
    const airtimeDataProductId =
      this.state.airtimeDataOptions[+this.state.selectedProductOption].id;
    const receivingCountryId = this.state.receivingCountry.id;

    return {
      data: {
        attributes: {
          beneficiary_first_name: this.state.recipient.beneficiary_first_name,
          beneficiary_last_name: this.state.recipient.beneficiary_last_name,
          beneficiary_mobile: this.state.recipient.beneficiary_mobile,
        },
        id: null,
        relationships: {
          airtime_data_product: {
            data: {
              id: airtimeDataProductId,
              type: "airtime_data_products",
            },
          },
          receiving_country: {
            data: {
              id: receivingCountryId,
              type: "countries",
            },
          },
        },
        type: "airtime_data_transactions",
      },
    };
  }

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = this.preparePayload();
        const access = this.props.authReducer.accessToken;

        this.props.buyReferralAirtimeDataBundle(access, payload, () => {
          this.props.history.push({
            pathname: "/redeemed-amount-summary",
            state: {
              redeemOption: "airime-data-bundle",
              amount: this.state.sendingAmount,
              letterCode: this.state.letterCode,
            },
          });
        });
      }
    });
  };

  renderFormActions = () => {
    return (
      <div style={{ width: "100%" }}>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                color: "#154989",
                margin: "8px",
              }}
              onClick={() => this.props.history.goBack()}
            >
              Back
            </Button>
          </div>
          <FormItem className="form-group">
            <Button
              className="home-collect"
              style={{
                backgroundColor: "#154989",
                boxSizing: "border-box",
                color: "white",
                margin: "8px",
              }}
              onClick={this.handleSubmit}
            >
              Next
            </Button>
          </FormItem>
        </div>
      </div>
    );
  };
}

const WrappedAirtimeDataChooseBundleForm = Form.create()(ProductOptionForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      buyReferralAirtimeDataBundle,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirtimeDataChooseBundle);
