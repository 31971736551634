import React from 'react';
import { Link } from 'react-router-dom'
import { Divider } from 'antd'
import '../styles/footer.css'
import facebook from '../images/facebook-trans.png';
import twitter from '../images/twitter-trans.png';
import instagram from '../images/instagram-trans.png';

class Footer extends React.Component {

  getCurrentYear = () => {
    let now = new Date();
    return now.getFullYear();
  }

  render() {
    return (
        <div className="footer-container">
          <div className="footer-apps">
            <div className="footer-parent-copyright-area">
              <div className="footer-copyright-area">
                <Link to='/about-us'>
                  <p className="footer-info-text">About Us</p>
                </Link>
                <Divider className="footer-info-text-divider" type="vertical" />
                <Link to='/blog'>
                  <p className="footer-info-text">Blog</p>
                </Link>
                <Divider className="footer-info-text-divider" type="vertical" />
                <Link to='/terms-of-use'>
                  <p className="footer-info-text">Terms of use</p>
                </Link>
                <Divider className="footer-info-text-divider" type="vertical" />
                <Link to='/privacy-policy'>
                  <p className="footer-info-text">Privacy Policy</p>
                </Link>
                {/* <Divider className="footer-info-text-divider" type="vertical" />
                <Link to='/faq'>
                  <p className="footer-info-text">FAQs</p>
                </Link> */}
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '60%' }}>
                <Divider className="footer-divider" />
              </div>
            </div>
            <div className="footer-social-logo">
              <a href="https://www.facebook.com/SasaiMoneyTransfer/">
                <img className="footer-social-logo-icon" src={facebook} alt="facebook"/>
              </a>
              <a href="https://twitter.com/SasaiMoneyTrnsf">
                <img className="footer-social-logo-icon" src={twitter} alt="twitter"/>
              </a>
              <a href="https://www.instagram.com/sasaimoneytransfer/">
                <img className="footer-social-logo-icon" src={instagram} alt="twitter"/>
              </a>
            </div>
            <div className="footer-address-area">
              <span className="footer-address-text">Copyright © {this.getCurrentYear()} Sasai Money Transfer. All Rights Reserved.</span>

              <span className="footer-address-text">In South Africa Sasai Money Transfer is regulated by the South African Reserve Bank (SARB).</span>

              <span className="footer-address-text">Sasai Fintech Limited, 17th Floor, 6 New Street Square, London EC4A 3BF
                Registered in England and Wales Company Number 05771797, 
                Registered Office: The Broadgate Tower 8th Floor, 
                Primrose Street, London, United Kingdom, EC2A 2EW.
              </span>
              <span className="footer-address-text">Sasai Fintech Limited is authorised by the Financial Conduct Authority (FCA) under the Payment Service Regulations 2017 for the provision of payment services, 
                FCA API number 574783 and regulated by HMRC for the purposes of compliance with the MLR2017 number:12192388.
              </span>
            </div>
          </div>
        </div>
      )
  }
}

export default Footer
