import React from "react";
import "../../styles/dashboard.css";

const TermsUk = () => {
  return (
    <>
      <h2 style={{ fontFamily: 'Lato' }}>Terms and Conditions</h2>
      <b className="terms-item-parent">1. Money Transfer Service</b>
      <span className="terms-item-child">1.1. These Terms and Conditions set out the terms and conditions which apply to money transmission services with Sasai Fintech Limited of Registered Office: The Broadgate Tower 8th Floor, Primrose Street, London, United Kingdom, EC2A 2EW. Registration Number 05771797 (Sasai Fintech Limited).
        Sasai Fintech's head office address is 17th Floor, 6 New Street Square, London EC4A 3BF, telephone number 020 30518189, fax number 0845 2992132, email support@sasaremit.com.
        Sasai Fintech Limited is Authorised with the Financial Conduct Authority under the Payment Services Regulations 2017 (registration number: 574783 for the provision of payment services. Sasai Fintech Limited is currently fully authorised and PSD2 approved.
        We are currently registered with Her Majesties Revenue and Customs HMRC registration number 12192388.
        For simplicity we will refer to ourselves as " Sasai Fintech" or "we/our/us".
        We operate a money transmission service which you will use to send and to receive money transfers.  Our money transfers can be sent and picked up at most locations worldwide.  You may call the number listed above or check our website at  moneytransfer.sasai.global (the "Website") for the address and business hours of nearby locations.  Some locations are open 24 hours a day.  The main characteristics of the payment services which we may provide to you are as follows:</span>
      <span className="terms-item-child">1.	Retail money remittance service: a money transfer service for a sending customer where a remittance payment is made to a named receiving customer, normally in another country, and where the transaction size is less than £5,000  (money may be received in cash or in a bank account);</span>
      <span className="terms-item-child">2.	High value money transfer service: a money transfer service for a sending customer where a remittance payment is made to a named receiving customer, normally in another country, and where the transaction size is £5,000 or more; and</span>
      <span className="terms-item-child">3.	Foreign Exchange plus onward transfer: provision of currency exchange for a customer plus onward transmission of funds for a payment purpose.  All payments are subject to availability, the payee showing documentary evidence of their identity and providing all details about the money transfer required by us as set out in these Terms and Conditions and including, without limitation, the name of the payer, country of origin, name of the payee, the payment amount, transaction control identification number and any other conditions or requirements which we consider necessary or applicable at the payee payment location.  In limited circumstances, the making of a money transfer may be subject to local taxes and service charges.</span>

      <span className="terms-item-child">1.2. We are agreeing to provide you with a money transfer service.  We do not accept any responsibility for any goods or services which may be paid for by a money transfer (or any taxes, charges or duties payable thereon).  You are cautioned against sending money to any person you do not know.  You bear the risk of your intended payee failing to pick up the payment after it has been transferred to the correct destination.</span>

      <b claspanssName="terms-item-parent">2. Communications</b>
      <span className="terms-item-child">2.1 We may accept your instructions even if they are not given in conventional written paper form such as by letter. This would cover any form of electronic or telephonic communication, including those not currently available. It applies to all present and any future business with you, but does not imply that we can or do currently actually accept all types of electronic communications: we will tell you what types we will accept.</span>
      <span className="terms-item-child">2.2 Note that there may be no signature, security or password protection for email, phone, fax and other future forms of electronic communication. You should bear this in mind if you decide to permit us to accept those types of instruction. If we do decide to accept any type or types of electronic communication from you, we advise:</span>
      <span className="terms-items-child">2.2.1 against using analogue mobile or cordless phones to contact us as they can sometimes be intercepted or overheard;</span>
      <span className="terms-items-child">2.2.2 you to be careful not to let other people see your details if you are online in a public place; and</span>
      <span className="terms-items-child">2.2.3 against using email for sending us confidential information.</span>
      <span className="terms-item-child">2.3 Where we agree to accept instructions in a particular format (including in electronic format), we will not be able to act on the instructions unless they are legible and clear.</span>
      <span className="terms-item-child">2.4 We may decline to act on any communication, even if we have told you we will in general accept that type of instruction. We may do this in particular if we consider that there is doubt about the validity of the communication and it is in our or your interest to query it with you. However, we are not obliged to check or consider the validity of your communications unless we have previously agreed a system of validation with you. Subject to any legal or regulatory requirements which may apply, we are authorised to act upon any instruction, agreement or arrangement without enquiring about its purpose, or the circumstances in which it is given, or about the disposition of any proceeds.</span>
      <span className="terms-item-child">2.5 If we come to believe that you may not have properly authorised a communication, we may, after making reasonable efforts to check whether it was authorised, refuse to act on the instruction and take steps to reverse any action already taken on it.</span>
      <span className="terms-item-child">2.6 Without prejudice to clause 6 of these Terms and Conditions, we may rely and act without further enquiry on any electronic communication which we reasonably believe you have given us, even if in fact it has not come from you, and we may assume that:</span>
      <span className="terms-items-child">2.6.1 a person identifying themselves by using the correct login email, username and password is authorised to instruct us and carry out a payment transaction; and</span>
      <span className="terms-items-child">2.6.2 all payment transactions are valid where the correct login email, username and password have been used.</span>
      <span className="terms-item-child">2.7 You must follow any security procedures we specify. We may also require you to sign a separate agreement before you can use some types of electronic communication to send us instructions and to access some kinds of services by electronic communication.</span>
      <span className="terms-item-child">2.8 We may insist that you confirm any electronic communication in conventional written form by the next business day. We need not wait for confirmation before acting on the instruction. For the purposes of these Terms and Conditions, "business day" means any day on which we are open for business (other than a Saturday or Sunday or a public holiday) as required for the execution of a payment transaction.</span>
      <span className="terms-item-child">2.9 We can communicate with you by any form of electronic communication by which you have chosen to be able to give us instructions.</span>
      <span className="terms-item-child">2.10 The language of these Terms and Conditions shall be English and all information provided, made available and notified to you shall be in English.</span>
      <span className="terms-item-child">2.11 We will provide you with a further copy of these Terms and Conditions upon request.</span>
      <span className="terms-item-child">2.12 After we receive a payment instruction from you as payer, we shall make available to you the following information in accordance with the provisions of clause 2.14:</span>
      <span className="terms-items-child">2.12.1 a reference enabling you to identify each payment transaction and, where appropriate, information relating to the payee;</span>
      <span className="terms-items-child">2.12.2 the amount of the payment transaction in the currency used for the payment instruction;</span>
      <span className="terms-items-child">2.12.3 the amount of the charges and transfer fees for the payment transaction payable by you (with a breakdown of such amounts where applicable);</span>
      <span className="terms-items-child">2.12.4 where applicable, the exchange rate used in the payment transaction by us and the amount of the payment transaction after that currency conversion; and</span>
      <span className="terms-items-child">2.12.5 the date of receipt by us of your payment instruction.</span>
      <span className="terms-item-child">2.13 After the execution of a payment transaction, we shall make available to you, if you are the payee, the following information in accordance with the provisions of clause 2.14:</span>
      <span className="terms-items-child">2.13.1 a reference enabling you to identify the payment transaction and, where appropriate, the payer, and any information transferred with the payment transaction;</span>
      <span className="terms-items-child">2.13.2 the amount of the payment transaction in the currency in which the funds are at your disposal;</span>
      <span className="terms-items-child">2.13.3 the amount of the charges and transfer fees for the payment transaction payable by you (with a breakdown of such amounts where applicable);</span>
      <span className="terms-items-child">2.13.4 where applicable, the exchange rate used in the payment transaction by us, and the amount of the payment transaction before that currency conversion; and</span>
      <span className="terms-items-child">2.13.5 the credit value date.</span>
      <span className="terms-item-child">2.14 The information specified in clauses 2.12 and 2.13 shall, unless otherwise agreed, be made available to you immediately and at any time on your online account on our website or using one of the methods of communication described above enabling you to store and reproduce the information unchanged. You will examine the confirmations and communications which we send to you within a reasonable time after receiving them and will promptly advise us without undue delay of any apparent mistake or discrepancy. Delay in notification may make correcting any error difficult.</span>
      <span className="terms-item-child">2.15 If you prefer paperless communications with us, we may communicate and make available all relevant transaction information with you through an internet money transmission service (if any) accessible via our Website.  To access the internet money transmission service, you will need a computer with an internet connection and the Internet Explorer web browser, version 7 and above, as well as a working email address.</span>
      <span className="terms-item-child">2.16 If you require information on an individual payment transaction before sending us an instruction, please contact us using the contact details set out in clause 1.1 of these Terms and Conditions or via our Website.</span>  
      <b className="terms-item-parent">3. Relationship</b>
      <span className="terms-item-child">3.1 We will use reasonable care and skill in providing money transmission services to you, but you should bear in mind that your payments do not carry the benefit of any interest and that our service does not have the benefit of any government-backed insurance, guarantee or compensation scheme (for example, no compensation is available from the Financial Services Compensation Scheme if we are unable to meet our liabilities) and the relationship of  Sasai Fintech with you is not that of a bank or trustee.</span>
      <span className="terms-item-child">3.2 You must not give out security details, such as any password or PIN, to anyone. You must notify us without undue delay in writing or by telephone using the contact details set out in clause 1.1 of these Terms and Conditions or via our Website on becoming aware that someone else knows your password, PIN or other security information or that any of these have been lost, stolen or misappropriated, and we will take immediate steps to try to prevent these from being used.</span>
      <span className="terms-item-child">3.3 You agree to assist us in the discharge of our anti-money laundering, security validation and verification responsibilities by providing such information and evidence which we may request from time to time in this regard. You agree to notify us promptly of any changes in the details you have supplied to us. In addition, we may, in our sole discretion, require other documents to be supplied to us at any time during our relationship with you in order to fulfil our legal and regulatory obligations.</span>
      <b className="terms-item-child">3.4 You represent and warrant that:</b>
      <span className="terms-items-child">3.4.1 you are over 18 years old</span>
      <span className="terms-items-child">3.4.2 the information and details you supply to us are true, accurate and complete. You agree that  Sasai Fintech can periodically validate information provided by you, using electronic address and identification verification tools and software which may be provided by public database processing companies, credit reference agencies and other sources</span>
      <span className="terms-items-child">3.4.3 your money transfer instructions to us and your use of your chosen form of payment instrument for payment to us will not breach any applicable agreement, law or regulation,
        and you agree to compensate us for any loss we suffer as a result of the above representations being untrue or incorrect.</span>
      <span className="terms-item-child">3.5 We may use information about you and your individual representatives to discharge the responsibilities referred to in clauses 3.3, 5.8, 5.11 and 5.12 to provide our services and to manage our relationship with you. We may disclose this information to payers, payees and intermediaries in the course of providing our services or as required by Regulation EC 1781/2006 on information on the payer accompanying transfers of funds; persons with whom we share information for anti-money-laundering, security verification or validation purposes; regulatory and prosecuting authorities; service providers acting on our behalf. This may involve transfer of information to countries which do not have data protection laws as strict as those in the UK. Individuals wishing to access or correct the information that we hold about them can do so by contacting our Data Protection Officer at Sasai Fintech Limited, 17th Floor, 6 New Street Square, London, EC4A 3BF. Before giving us information about any individual you should make sure that they are aware of the matters set out in this clause 3.5.</span>
      <b className="terms-item-parent">4. Fees, Charges and Exchange Rates</b>
      <span className="terms-item-child">4.1 Our tariff card/fees table sets out how, when and what we charge for the services we provide. These details are also displayed prominently at our premises and those of our branches and agents or on our Website or may be obtained by contacting us using the contact details set out in clause 1.1 of these Terms and Conditions.  You agree to pay our fees and charges for each payment transaction and in the circumstances set out in further detail in our tariff card/ fees table.</span>
      <span className="terms-item-child">4.2 If you are the recipient or payee of an international payment we may deduct our charges and transfer fees for each such payment from the money transferred before making it available to you.  If we deduct any charges under this clause 4.2, we will give you details in the information which we regularly make available to you (using the means agreed with us by which you wish to be notified) of the full amount of the money we receive and of our charges for receiving the money which we have deducted.</span>
      <span className="terms-item-child">4.3 We may change any of our charges at any time. We will tell you (in accordance with clause 7.5 of these Terms and Conditions) at least 2 months before the change to the charge takes effect. Please see clause 7 of these Terms and Conditions which explains in more detail the procedures for changes to our contract terms.</span>
      <span className="terms-item-child">4.4 Applicable exchange rates for payment transactions, as well as the relevant date and base exchange rate used for determining the applicable exchange rate, are set out on our Website, displayed prominently at our premises and those of our branches and agents or may be obtained by contacting us using the contact details set out in clause 1.1 of these Terms and Conditions.  Payment transactions will be executed, unless otherwise agreed, in the currency of the destination country (but please note that in some countries payment is only available in U.S. dollars or another alternate currency).  Currency will be converted from sterling (GBP) at the time of transfer and the payee will receive the relevant foreign currency amount.  For transfers to certain countries, the currency may be converted at the time the payee receives the transfer, in which case the payment amount may be subject to exchange rate fluctuations between the time of transfer and the time the payee collects the payment amount.  Our exchange rate may be less favourable than some publicly reported commercial exchange rates used in transactions between banks and other financial institutions.  Any difference between our exchange rates offered to you and the exchange rates received by us will be kept by us, in addition to our transfer fees and other charges. 
        If you wish to transmit payments to countries that provide payment in multiple currencies, you must select the currency of the payment at the time you consent to the payment transfer.  The transfer fees and charges and the money we make when changing your funds into foreign currency may vary based upon the payment currency that you select. Some payment service providers in foreign locations may offer payees the choice to receive funds in a currency which differs from the one that you select as payer.  In such instances, we may make additional money when your funds are converted into the currency selected by the payee.</span>
      <span className="terms-item-child">4.5 We may change our exchange rates at any time and without notice to you. Such changes to an exchange rate will be applied immediately.</span>
      <span className="terms-item-child">4.6 Telephone notification to the payee that the money transfer is available for pick-up is offered in most countries for an additional fee.</span>
      <span className="terms-item-child">4.7 Supplemental messages may be included with money transfers to most countries for an additional fee.</span>
      <span className="terms-item-child">4.8 Sasai Fintech reserves the right to amend any exchange rate or total amount payable to a client provided by the  Sasai Fintech website online system should there be any technical or other fault regarding the calculation of exchange rates or amounts on said system. On occasions the system may not allow  Sasai Fintech to perform conversions due to technical problems and  Sasai Fintech takes no responsibility for adverse exchange rate movements that occur during these outages.</span>   

      <b className="terms-item-parent">5. Payment transactions</b>
      <span className="terms-item-child">5.1 In order for a payment instruction from you to be properly executed, you must provide us with the information or unique identifier which is necessary for the proper execution of the payment instruction. If this is incorrect, it could result in the payment transaction being delayed or the monies transferred being lost. The information could comprise the payee's bank sort code and account number or, where applicable, the payee's SWIFT number, BIC number and IBAN number.</span>
      <span className="terms-item-child">5.2 You must give consent before the execution of each payment transaction by giving us payment instructions in a signed and completed order form which is available at our premises and those of our branches and agents or from our Website or by using the wizard and filling in the instructions on our websites  moneytransfer.sasai.global or by contacting us using the contact details set out in clause 1.1 of these Terms and Conditions, by email, letter or facsimile transmission, in each case correctly addressed to us, or by text SMS message or phone instruction which will be transcribed to a phone or text attendant form and saved for records or by handing it to us at our premises.</span>
      <span className="terms-item-child">5.3 We shall have the right to stop the use of any personalised set of procedures agreed between you and us, which are used by you in order to initiate payment instructions to us, on reasonable grounds relating to:</span>
      <span className="terms-items-child">5.3.1 the security of such security features; or</span>
      <span className="terms-items-child">5.3.2 the suspected unauthorised or fraudulent use of such security features.</span>
      <span className="terms-item-child">5.4 We will execute payment instructions so that the amount to be transferred reaches the payee's payment service provider no later than the end of the next business day after we received your instruction.  We must receive payment instructions before the cut-off time of 17:00hrs UK time or we will deem the instruction to have been received on the next business day.  Instructions received on days which are not business days for us will also be deemed to have been received on the next business day.</span>
      <span className="terms-item-child">5.5 Once we receive your payment instruction, you cannot revoke it unless you inform us in writing that you withdraw your consent no later than the business day before the agreed day of execution of the instruction.</span>
      <span className="terms-item-child">5.6 Where we receive a payment instruction from you for execution on a specific day, you agree that the time of receipt is deemed to be that specific day on which we are to execute the payment instruction.</span>
      <span className="terms-item-child">5.7 It is your responsibility to ensure that the monies you pay to us are sufficient to make each and every payment transaction which you authorise us to make. We will not make any money transfer instructed by you unless you have paid to us sufficient funds first. When we accept a cheque, credit or debit card or other non-cash form of payment from you we make no promise to make payment of any relevant money transfer if your form of payment is uncollectible, nor do we assume any liability for damages resulting from non-payment of the money because of uncollectability.</span>
      <span className="terms-item-child">5.8 Applicable laws prohibit money transmitters from conducting business with certain individuals and countries.  In order to comply, we are required to screen all transfers against the list of names provided by various governments and/or government agencies.  If a potential match is found, we will suspend the transfer and request additional information on either the payer or the payee, as necessary.  Upon satisfactory review, the payment transfer will be released for collection.</span>
      <span className="terms-item-child">5.9 We have the right to refuse to accept a payment instruction or payment and to refuse to execute any payment transaction for any of the following reasons:</span>
      <span className="terms-items-child">5.9.1 if any condition in these Terms and Conditions has not been satisfied; or</span>
      <span className="terms-items-child">5.9.2 if execution would be unlawful.</span>      
      <span className="terms-item-child">5.10 If any payment instruction is declined you may contact us using the contact details set out in clause 1.1 of these Terms and Conditions or via our Website.  If we have declined the payment instruction we will, where reasonably possible (and where we are not prevented from so doing by law or regulation) tell you why the payment instruction was declined at the earliest opportunity and in any event no later than the end of the next business day after we received your instruction.  If the reason for our declining the payment instruction was based on incorrect information, we will agree with you what needs to be done to correct that information.</span>
      <span className="terms-item-child">5.11 You agree that we may retain monies received by us for your benefit until our security validation, verification and anti-money laundering procedures have been completed before you may withdraw them or instruct us to transfer them.  Our Website sets out a list of purposes for money transfers which you are prohibited from transacting, transmitting or receiving (including, without limitation, the purchase of drugs and weapons and, in certain countries, gambling activities).  You agree that you will not transact, transmit or receive payment transfers for or in connection with any criminal or illegal purpose whatsoever.  We may report any suspicious activity relating to your transactions to the relevant authorities.</span>
      <span className="terms-item-child">5.12 Additional security questions may be required for certain payment transactions (regardless of the payment amount) and, in addition, the payee may be required to provide documentary evidence of their identity.  Further information regarding the use of security questions for the destinations of your payment transfers is available on our Website or by contacting us using the contact details set out in clause 1.1 of these Terms and Conditions.</span>
      <span className="terms-item-child">5.13 You may not be protected against reversals of money transfers in respect of which you are the payee where the reversal is due to the use of a stolen or unauthorised payment instrument or means of authentication by the payer. Once a payee has received a payment sent by us in accordance with your instructions as payer, that payment cannot normally be reversed.</span>
      <b className="terms-item-parent">6. Liability and refunds</b>
      <span className="terms-item-child">6.1 The extent of your liability as payer for any losses you incur in respect of an unauthorised payment transaction:</span>
      <span className="terms-items-child">6.1.1 arising from the use of lost or stolen (or, where you have failed to keep them safe, from the misappropriation of) personalised security features or procedures agreed between us and you for your use in order to give us payment instructions, including (without limitation) an unauthorised payment transaction through any electronic communication or a misappropriation of the security features of our electronic communications service, is a maximum of £50, or</span>
      <span className="terms-items-child">6.1.2 where you have acted fraudulently or have with intent or gross negligence failed to: (i) take all reasonable steps to keep safe any of the personalised security features or procedures agreed between us and you for your use in order to give us payment instructions, including (without limitation) an unauthorised payment transaction through any electronic communication or a misappropriation of the security features of our electronic communications service, (ii) use such features and procedures in accordance with the terms and conditions governing their issue and use, or (iii) notify us in writing or by telephone using the contact details set out in clause 1.1 of these Terms and Conditions or via our Website and without undue delay on becoming aware of the loss, theft, misappropriation or unauthorised use of such security features or procedures, is the full amount of those losses.</span>
      <span className="terms-item-child">6.2 You may be entitled to redress for an unauthorised or incorrectly executed payment transaction only if you notify us in writing or by telephone using the contact details set out in clause 1.1 of these Terms and Conditions without undue delay on becoming aware of any unauthorised or incorrectly executed payment transaction, and in any event no later than 13 months after the debit date. Such redress may include, in relation to an unauthorised executed payment transaction, our refunding the amount of the unauthorised payment transaction to you as payer.</span>
      <span className="terms-item-child">6.3 Where you initiate a payment instruction as payer, we are responsible to you for the correct execution of the payment transaction unless the payee's payment service provider received the amount of the payment transaction in accordance with the payment instruction execution times set out in clause 5.4 of these Terms and Conditions.  You may request that we make immediate efforts to trace the payment transaction and notify you of the outcome. 
        Where we are liable to you as payer under this clause 6.3 for a non-executed or defective payment transaction, we may without undue delay refund to you the amount of the non-executed or defective payment transaction.</span>
      <span className="terms-item-child">6.4 Where you are the intended payee of a payment instruction initiated by a third party payer, and the payer's payment service provider can prove that we received the amount of the payment transaction in accordance with the payment instruction execution times set out in clause 5.4, we are liable to you for the correct execution of the payment transaction and shall immediately make available the amount of the payment transaction to you as payee.</span>
      <span className="terms-item-child">6.5 If we are in breach of contract or otherwise negligent and we might reasonably have expected your loss to result directly from our breach or negligence, we are in any case liable to you.</span>
      <span className="terms-item-child">6.6 We will not be liable for any losses not directly associated with any incident that may cause you to make a claim against us, nor are we liable for loss of profits, loss of business, loss of goodwill or any form of special damages whatsoever and howsoever arising and whether such liability was reasonably foreseeable or not and whether or not we have been advised of the possibility of such loss being incurred.</span>
      <span className="terms-item-child">6.7 Nothing in clauses 6.5 and 6.6 of these Terms and Conditions excludes our liability for fraudulent misrepresentation by ourselves, our employees or agents or our liability for death or personal injury caused by our negligence or the negligence of our employees or agents.</span>
      <span className="terms-item-child">6.8 Notwithstanding anything to the contrary in these Terms and Conditions, we shall not be liable to you or be obliged to perform our obligations under these Terms and Conditions if we are prevented, hindered or delayed from or in performing any of our obligations under these Terms and Conditions due to abnormal and unforeseeable circumstances beyond our control (including any strike, lock-out, labour dispute, act of God, war, riot, civil commotion, malicious damage, compliance with a law or governmental order, rule, regulation or direction, accident, breakdown or other failure of equipment, software or communications network, fire, flood, or other circumstances affecting the supply of goods or services).</span>
      <span className="terms-item-child">6.9 You acknowledge that our Website is subject to periodic maintenance and testing and that you may not be able to access it from time to time as a result.  We are not responsible for any loss you may suffer as a result of your being unable to access our Website at any time.</span>
      <span className="terms-item-child">6.10 You must send us all relevant supporting documentation in relation to any claim you make for a refund or compensation.</span>
      <span className="terms-item-child">6.11 In the case of funds being paid to us in error, or by a person that is not a registered client and does not wish to become a client, we may charge an administration fee to return or handle cash to the value of 1% of the amount deposited with a minimum charge of £25. In the case of returning funds transferred electronically to us in error, we may charge an administration fee with a minimum charge of £25 at our discretion.</span>
      <span className="terms-item-child">6.12 Sasai Fintech will endeavour to pay client funds to the nominated beneficiary (Payee) using the account details provided. Should this payment be returned due to incorrect account details or of the like,  Sasai Fintech accepts no responsibility for any fees or any loss of interest. In the case where we trace or try to repatriate funds paid to an incorrect account because you provided incorrect details we will charge an administration fee of £50. Sasai Fintech will always attempt to act in the best interests of its clients. The foreign exchange physical settlement process relies on an inter-bank, third party settlement function. Sasai Fintech accepts no responsibility for any losses or fees caused as a result of the third party settlement.</span>
      <span className="terms-item-child">6.13 Sasai Fintech will not be liable for any glitches, pricing issues, downtime, miscalculations or any other problems with the Online System that may result in Clients being quoted incorrect rates or amounts by the system. Sasai Fintech reserves the right to cancel or amend any such transactions resulting from any problems whatsoever with the Sasai Fintech Online System.</span>      
      <b className="terms-item-parent">7. Modifications to Terms and Conditions; Termination</b>

      <span className="terms-item-child">7.1. We may change any provision of these Terms and Conditions.</span>
      <span className="terms-item-child">7.2. We will notify you in writing at least 2 months before we make any change to these Terms and Conditions. You will be deemed to have accepted any such change if you do not notify us to the contrary before the date on which any such change comes into effect. However, if you choose not to accept any such change, our notice of the change shall be deemed to be notice of termination given under clause 7.8 of these Terms and Conditions and our agreement pursuant to these Terms and Conditions will terminate the day before any change comes into effect.</span>
      <span className="terms-item-child">7.3. If we have made a major change or a lot of minor changes in any one year, we will give you a copy of the new terms and conditions or a summary of the changes.</span>
      <span className="terms-item-child">7.4. If we agree to fix any condition for a certain time, we will not change it during that time.</span>
      <span className="terms-item-child">7.5. When we tell you about a change we will do so by letter, email, text or messages or in any other way which is sent to you individually or will do so in a way that we reasonably believe is likely to come to your attention and which satisfies legal and other regulatory requirements.</span>
      <span className="terms-item-child">7.6. Our agreement under these Terms and Conditions will continue until terminated in accordance with this clause 7.</span>
      <span className="terms-item-child">7.7. You may terminate our agreement under these Terms and Conditions by giving us at least 1 month's written notice.  We shall not charge you for the termination of our agreement under these Terms and Conditions after the expiry of 12 months from its commencement.</span>
      <span className="terms-item-child">7.8. We may terminate our agreement under these Terms and Conditions by giving you at least 2 months' written notice. Such termination will not release you from any liability in respect of any sums owing to us or from any previous liability for any act performed by us in accordance with instructions received from you.</span>
      <span className="terms-item-child">7.9 If you are an individual, and up to and including the time at which we conclude our agreement with you under these Terms and Conditions we have only communicated with you for the purposes of the agreement without you and us being physically present at the same time, you have a right to cancel the agreement when you give us your first payment instruction, starting on the day on which you indicate to us your acceptance of the agreement ("conclusion day") and ending on the expiry of 14 calendar days beginning with the day after conclusion day.  Such right may be exercised by contacting us at our address, facsimile number or email address using the contact details set out in clause 1.1 above or via our Website and informing us that you wish to cancel your agreement with us.  If you exercise this right before you have sent us any payment instruction, the agreement will terminate and you will not be charged a cancellation fee.  If you exercise your right after you have sent us your first payment instruction, we may deduct a cancellation fee from the monies you have already paid to us before we transfer them back to you (please note that we will not be able to transfer any monies back to you to the extent that we have already executed your payment instruction). If you do not exercise this right, the agreement will not have been cancelled by you.</span>    
      <b className="terms-item-parent">8. General</b>
      <span className="terms-item-child">8.1. Termination of our agreement under these Terms and Conditions does not affect your or our accrued rights and obligations at the date of termination.</span>
      <span className="terms-item-child">8.2. A failure to exercise or delay in exercising a right or remedy provided by these Terms and Conditions or by law does not constitute a waiver of the right or remedy or a waiver of other rights or remedies.  No single or partial exercise of a right or remedy provided by these Terms and Conditions or by law prevents further exercise of the right or remedy or the exercise of another right or remedy.</span>
      <span className="terms-item-child">8.3. In case any provision in or obligation under these Terms and Conditions shall be invalid, illegal or unenforceable in any jurisdiction, the validity, legality and enforceability of the remaining provisions or obligations, or of such provision or obligation in any other jurisdiction, shall not in any way be affected or impaired thereby.</span>
      <span className="terms-item-child">8.4. You may not assign, transfer or create any trust in respect of, or purport to assign, transfer or create any trust in respect of, a right or obligation under these Terms and Conditions.</span>
      <span className="terms-item-child">8.5. Neither these Terms and Conditions nor any transaction carried out under them shall confer contractual or other rights on, or be enforceable against us by, any party other than you.</span>
      <b className="terms-item-parent">9. Governing law and jurisdiction; redress</b>
      <span className="terms-item-child">9.1. These Terms and Conditions and our agreement under these Terms and Conditions and all matters arising from or connected with these Terms and Conditions and our agreement are governed by English law.</span>
      <span className="terms-item-child">9.2. The courts of England have exclusive jurisdiction to settle any dispute arising from or connected with these Terms and Conditions and our agreement under these Terms and Conditions (including a dispute regarding their existence, validity or termination or relating to any non-contractual or other obligation arising out of or in connection with them) or the consequences of their nullity. You may also be eligible for redress for any dispute and matter arising out of or in connection with these Terms and Conditions through the dispute resolution mechanism provided by the Financial Ombudsman Service (please see below for further details).</span>
      <span className="terms-item-child">9.3. In the event that a pay-out has not occurred within the 72 (seventy two) hour period, due to a technical problem on Sasai’s side or that of its pay-out partner or any other reason that is not due to the negligence or fault of the customer, such as lack of available cash at the pay-out partner, the customer may cancel the money transfer transaction and request a refund, free of charge. In all such instances, the refund to the customer as aforesaid shall operate as the customer’s sole recourse against Sasai and Sasai shall have no other liability to the customer in this regard. The refund process is detailed in clause 10 below.</span>
      <b className="terms-item-parent">10. Governing law and jurisdiction; redress</b>
      <span className="terms-item-child">10.1 If you have any queries or require a copy of these Terms and Conditions (or any document comprised in them), please contact us using the contact details in clause 1.1 of these Terms and Conditions.</span>
      <span className="terms-item-child">10.2. We aim to provide the highest level of customer service possible. If you do experience a problem, we will always seek to resolve this as quickly and efficiently as possible.  However, if you are unhappy and would like to make a complaint, please refer to our Complaints Policy for details of our internal process for dealing with complaints promptly and fairly.  Our Complaints Policy is available on our Website or by contacting our customer service team at: 
            Customer Service and Complaints Dept
            Sasai Fintech Limited
            17th Floor, 6 New Street Square, London EC4A 3BF
      </span>
      <span className="terms-item-child">&ensp;&ensp;Telephone: <a href="tel:+442030518189">020 30518189</a></span>
      <span className="terms-item-child">&ensp;&ensp;WhatsApp: <a href="https://wa.me/+447951619537">+44 (0) 795 161 9537</a></span>
      <span className="terms-item-child">&ensp;&ensp;Fax: 0845 299 2132</span>
      <span className="terms-item-child">&ensp;&ensp;Email: <a href="mailto:support@sasai.global">support@sasai.global</a></span>
    </>    
  )
}

export default TermsUk;
