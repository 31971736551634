import React from 'react';
import Log from '../utils/log.js';
import {
  requestRecipients,
  fetchUserDetails,
  deleteRecipient,
  showRecipient,
  showReceivingCountry,
  showSendingCountry,
  showSendingCurrency,
  showReceivingCurrency,
  showCalculation,
  showProductDetails,
  requestLimit,
  requestProductDetails,
  requestRecipientByID
} from '../actions';
import CustomFooter from '../components/footer.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, Table, Button, Spin, Modal, Input, Divider } from 'antd';
import '../styles/user.css'
import ErrorLayout from '../components/error-layout.js';
import ManageUrls from '../endpoints/manage_urls.js';

const { Content, Footer } = Layout;
const TAG = "SmartRecipientList";
const Search = Input.Search;
const confirm = Modal.confirm;

class GuestRecipientList extends React.Component {

  manageUrls = new ManageUrls()

  constructor(props) {
    super(props);
    Log.d(TAG, "GuestRecipientList::constructor() -- props=" + JSON.stringify(props));

    var transferReasons = [];
    var sender = {};
    var bwPolicy = false;

    if (props.location != null && props.location.state != null) {
      sender = props.location.state.sender;
    }

    Log.d(TAG, "GuestRecipientList::constructor() -- transfer reasons=" + JSON.stringify(transferReasons));

    this.state = {
      // access: cookies.get('access') || '',
      access: this.props.authReducer.accessToken || '',
      loading: false,
      loadingTip: "",
      sender,
      // currentLink : (receivingCountry ? (Object.keys(receivingCountry).length > 0 ? manageUrls.getApiUrl() + "beneficiaries/?filter[beneficiary_type]=MT&filter[country]=" + receivingCountry.id + "&page=1" : manageUrls.getApiUrl() + "beneficiaries/?page=1") : manageUrls.getApiUrl() + "beneficiaries/?page=1"),
      currentLink: this.manageUrls.getApiUrl() + "airtime_data_beneficiaries/?page[number]=1",
      columns: [{
        title: 'User ID',
        dataIndex: 'id',
        key: 'id',
      }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button onClick={(event) => { event.stopPropagation(); this.onDelete(record); }}
              style={{
                'min-width': "100px",
                'height': '30px',
                'color': 'rgb(41, 108, 187)',
                'border-radius': "0px",
                'border': "1px solid rgb(41, 108, 187)"
              }}
            >Delete</Button>
          </span>
        ),
      }],
      recipient: {},
      disableAddButton: true,
      bwPolicy: bwPolicy
    }
  }

  componentWillMount() {
    // Log.d(TAG, "componentWillMount() -- user=" + JSON.stringify(this.props.userReducer.user))
    // Log.d(TAG, "componentWillMount() -- country=" + JSON.stringify(this.state.sendingCountry))
    // if (this.props.cookies.cookies.access !== undefined) {
    if (this.props.authReducer.guestUserAccessToken !== '') {
      this.requestRecipients(this.state.currentLink)
    }
  }
  requestRecipients(url) {
    if (!!this.props.authReducer.guestUserAccessToken) {
      Log.d(TAG, "requestRecipients() -- access=" + this.props.authReducer.guestUserAccessToken);
      this.setState({
        loading: true,
        loadingTip: "Fetching beneficiaries .."
      })

      // this.props.requestRecipients(url, cookies.get('access'));
      this.props.requestRecipients(url, this.props.authReducer.guestUserAccessToken);
    }
  }

  reset() {
    this.setState({
      recipients: [],
      next: null,
      prev: null,
      first: null,
      last: null,
      loading: false,
      loadingTip: "",
    });
    if (this.state.currentLink) {
      this.requestRecipients(this.state.currentLink)
    }
  }

  onDelete = (recipient) => {
    Log.d(TAG, "onDelete() -- recipient=" + JSON.stringify(recipient));
    const { deleteRecipient } = this.props;

    confirm({
      title: 'Do you want to remove ' + recipient.name + ' as a recipient?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        // if (cookies.get('access')) {
        if (!!this.props.authReducer.accessToken) {

          // deleteRecipient(recipient.id, cookies.get('access'))
          deleteRecipient(recipient.id, this.props.authReducer.accessToken)
        }
      },
      onCancel() { },
    });
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </div>
    )
  }

  renderContent() {
    if (!this.props.userReducer.error) {
      return (this.renderTable())
    }
    return (this.renderError())
  }

  renderError() {
    Log.d(TAG, "renderError() -- error=" + this.props.userReducer.error);
    return (
      <Content className="recipient-content-container">
        <ErrorLayout {...this.props} />
      </Content>
    )
  }

  renderTable() {
    return (
      <Content className="recipient-content-container">
        <div className="rl-top" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <h2 className="rl-select-recipient">Select your recipient</h2>
          <div className="rl-p-search-add-right">
            <Search
              placeholder="Search recipient and press 'Enter'"
              onSearch={this.onSearch}
              className="search"
            />
          </div>

        </div>
        <Divider />
        <div className="trl-point-table" style={{ width: '100%' }}>
          <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? '' : 'table-even-row'}
              size="middle"
              pagination={{ hideOnSinglePage: true }}
              className="trans-body"
              onRow={(recipient) => {
                return {
                  onClick: () => {
                    Log.d(TAG, "onClickRow() -- recipient=" + JSON.stringify(recipient))
                    this.loadUpdateBeneficiary(recipient)
                  }
                };
              }}

              bordered
              dataSource={this.props.recipientReducer.recipients}
              columns={this.state.columns} />
          </Spin>
          <div className="pagination-box">
            <div className="pagination-box-left">
              {this.showPrevButton()}
              {this.showFirstButton()}
            </div>
            <div className="pagination-box-middle">
              {this.renderCurrentLink()}
            </div>
            <div className="pagination-box-right">
              {this.showLastButton()}
              {this.showNextButton()}
            </div>
          </div>
        </div>

      </Content>
    )
  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (<span>Page {this.state.currentLink.slice(this.state.currentLink.lastIndexOf("=") + 1, this.state.currentLink.length)} of {this.showLastPage()} pages</span>)
    }
    return (<div />)
  }


  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (prevProps.recipientReducer.recipients !== this.props.recipientReducer.recipients) {
      Log.d(TAG, "componentDidUpdate() -- recipients=" + JSON.stringify(this.props.recipientReducer.recipients))
      this.setState({
        loading: false,
        loadingTip: "",
        currentLink: this.props.recipientReducer.url
      })
    }

  }

  enableAddButton() {
    this.setState({
      disableAddButton: false
    })
  }

  showAdd() {
    if (this.state.bwPolicy) {
      this.props.history.push({
        pathname: "/manage-breadwinner-policy/",
        state: {
          recipientId: null,
          bwBeneficiary: {},
          recipient: {}
        }
      });
    } else {
      this.props.history.push({
        pathname: "/manage-recipient",
        state: {

        }
      });
    }
  }

  loadUpdateBeneficiary = (recipient) => {
    Log.d(TAG, "onClickRow() -- recipient=" + JSON.stringify(recipient))
    this.props.history.push({
      pathname: "/airtime-recipient-details",
      state: {
        sender: this.props.location.state.sender,
        sendingCountry: this.props.location.state.sendingCountry,
        recipient
      }
    });
  }

  onAddRecipient = () => {
    if (Object.keys(this.props.productServiceReducer.product).length > 0) {
      // this.props.requestLimit(this.props.userReducer.user.data.id, this.props.cookies.cookies.access, "rl-add");
      this.props.requestLimit(this.props.userReducer.user.data.id, this.props.authReducer.accessToken, "rl-add");
    } else {
      this.showAdd()
    }
  }

  showLastPage() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <span>{this.props.recipientReducer.last.slice(this.props.recipientReducer.last.lastIndexOf("=") + 1, this.props.recipientReducer.last.length)}</span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  showFirstButton() {
    if (this.props.recipientReducer.first !== null) {
      return (
        <Button className="pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.recipientReducer.last !== null) {
      return (
        <Button className="pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.last)}>Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.recipientReducer.prev !== null) {
      return (
        <Button className="pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.prev)}>Prev</Button>
      )
    } else {
      return (
        <Button className="pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.recipientReducer.next !== null) {
      return (
        <Button className="pagination-box-item" onClick={() => this.requestRecipients(this.props.recipientReducer.next)}>Next&gt;&gt;</Button>
      )
    }
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    const searchUrl = this.manageUrls.getApiUrl() + "airtime_data_beneficiaries/?filter[q]=" + value + "&page=1";
    this.requestRecipients(searchUrl)
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestRecipients,
    fetchUserDetails,
    deleteRecipient,
    showRecipient,
    showReceivingCountry,
    showSendingCountry,
    showSendingCurrency,
    showReceivingCurrency,
    showCalculation,
    showProductDetails,
    requestLimit,
    requestProductDetails,
    requestRecipientByID
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestRecipientList)
