import React from "react";
import { Link } from "react-router-dom";
import Log from "../utils/log.js";
import config from "../env-config.json";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  requestLogin,
  requestSendingCountries,
  fetchUserDetails
} from "../actions";
import { Button } from "antd";
import Cookies from "universal-cookie";
import { AUTH_ERROR, AuthError, ServerError } from "../utils/xhr.js";
import xhr from "../utils/xhr.js";

const TAG = "SasaiLogin";
// const defaultMessage = "Authorising Sasai ...";
const defaultMessage = "";
const REFRESH_TOKEN_EXPIRATION = 60 * 60 * 24; // 1 day
const ACCESS_TOKEN_EXPIRATION = 60 * 19.5; // 19 mins

const cookieHelper = new Cookies();

const Assistance = () => (
  <span>
    To register, visit <Link to={"/"}>www.cassavaecocash.co.za</Link> or call
    084133 / 0101200800 for assistance
  </span>
);
class SasaiLogin extends React.Component {
  state = {
    message: defaultMessage,
    enabled: false
  };

  componentDidMount = () => {
    const getMobileOperatingSystem = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection f
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    };

    window.authenticateWithSasai = () => {
      const value = cookieHelper.get("phoneNumber");
      Log.i(`Mobile number ${value}`);
      const operatingSystem = getMobileOperatingSystem();
      if (window.SasaiCall && window.SasaiCall.openAuthSilent) {
        Log.i("Calling android authentication");
        const clientData = {
          type: "800",
          appId: config.sasaiAppId,
          scope: "user_info"
        };
        const jsonData = JSON.stringify(clientData);
        window.SasaiCall.openAuthSilent(jsonData);
      } else if (
        window.webkit &&
        window.webkit.messageHandlers.currentCookies &&
        window.webkit.messageHandlers.currentCookies.postMessage
      ) {
        Log.i("Calling iphone authentication");
        const clientData = {
          type: "801",
          appId: config.sasaiAppId,
          scope: "user_info"
        };

        const jsonData = JSON.stringify(clientData);
        window.webkit.messageHandlers.currentCookies.postMessage(jsonData);
      } else {
        const message = `window.SasaiCall.openAuth doesn't exist: Operating system----->${operatingSystem}`;
        Log.i(message);
      }
    };

    window.returnAuth = callbackData => {
      const data = JSON.parse(callbackData);
      Log.i(callbackData);
      if (data) {
        const { code } = data;
        cookieHelper.set("browserType", "Sasai");
        const mobileNumber = cookieHelper.get("phoneNumber");
        if (code && mobileNumber) {
          const payload = {
            username: `${mobileNumber}`,
            password: `sasai:${code}`
          };
          Log.d(TAG, "requestLogin() -- props=" + JSON.stringify(this.props));
          const authUrl = `${config.auth_url}login/`;
          fetch(authUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            body: JSON.stringify(payload)
          })
            .then(results => {
              const authError = results.status === 401 || results.status === 403;
              const serverError = results.status === 500;

              if (serverError) {
                Log.i(`Server error occurred: ${results}`)
                xhr.serverErrorHandler();
              } else if (authError) {
                Log.i(`Auth error occurred: ${results}`);
                throw new AuthError();
              } else {
                return results.json();
              }
            })
            .then(data => {
              const { history } = this.props;
              const { refresh, access } = data;
              Log.i(JSON.stringify({ refresh, access, history, cookieHelper }));
              if (cookieHelper && refresh && access && history) {
                cookieHelper.remove("access");
                cookieHelper.remove("refresh");
                cookieHelper.set("refresh", refresh, {
                  maxAge: REFRESH_TOKEN_EXPIRATION,
                  secure: process.env.NODE_ENV === "production"
                });
                cookieHelper.set("access", access, {
                  maxAge: ACCESS_TOKEN_EXPIRATION,
                  secure: process.env.NODE_ENV === "production"
                });
                this.props.fetchUserDetails(cookieHelper, access, false, "");
                history.push("/");
              } else {
                this.setState({
                  message: <Assistance />
                });
              }
            })
            .catch(error => {
              if(error.name !== AUTH_ERROR) {
                const { message } = error;
                Log.i(JSON.stringify({ message }));
                this.setState({ message: "Failed to connect", enabled: true });
              } else {
                window.location.reload();
              }
            });
        }
      }
    };

    window.authenticateWithSasai();
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "#154989"
        }}
      >
        <h4 style={{ color: "white", fontFamily: "Lato", textAlign: "center" }}>
          {this.state.message}
        </h4>
        {this.state.enabled ? (
          <Button
            onClick={() => {
              this.setState({ message: "Retrying ..." }, () => {
                window.authenticateWithSasai();
              });
            }}
          >
            Retry
          </Button>
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestLogin,
      requestSendingCountries,
      fetchUserDetails
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SasaiLogin);
