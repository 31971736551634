import React from 'react';
import { Layout, Button } from 'antd';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import {
  requestTransactionDetails,
  requestReceivingCurrency,
  requestSendingCurrency,
  requestReceivingCountry,
  fetchUserDetails,
  requestSendingCountry,
  showCalculation,
  showPaymentMethod
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { message } from 'antd';
import moment from 'moment';
import '../styles/user.css'
import SmartLogin from './Login.js';
import checkIcon from '../images/check_icon.png'

const { Content } = Layout;
const TAG = "SmartTransactionNumber";

class TransactionNumber extends React.Component {


  componentDidUpdate(prevProps) {

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
      if (this.props.userReducer.error.includes("password") && this.props.userReducer.error.includes("expired")) {
        message.error("Please change your password first.", 5);
        this.props.history.push({
          pathname: "/update-profile"
        })
      }
    }

    if (this.props.userReducer.success && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
    }

    if (Object.keys(this.props.transactionReducer.transaction).length > 0 && prevProps.transactionReducer.transaction !== this.props.transactionReducer.transaction) {
      Log.d(TAG, "componentDidUpdate() -- set transaction")
      var included = this.props.transactionReducer.transaction.included;
      var countryID = "0";
      var sendingCurrencyID = "0";
      var receivingCurrencyID = "0";

      if (!!this.props.authReducer.accessToken) {
        const { cookies } = this.props;
        //  this.props.fetchUserDetails(cookies, cookies.get('access'), false, "");
        this.props.fetchUserDetails(cookies, this.props.authReducer.accessToken, false, "");
      }

      included.filter((obj) => {
        if (obj.type === "calculations") {
          this.props.showCalculation(obj)
          sendingCurrencyID = obj.relationships.sending_currency.data.id;
          receivingCurrencyID = obj.relationships.receiving_currency.data.id;
          this.requestCurrencyDetails(sendingCurrencyID, true)
          this.requestCurrencyDetails(receivingCurrencyID, false)
        } else if (obj.type === "beneficiaries") {
          countryID = obj.relationships.country.data.id;
        } else if (obj.type === "payment_methods") {
          this.props.showPaymentMethod(obj, this.props.transactionReducer.transaction.data.attributes.reason_for_transfer_name, this.props.transactionReducer.transaction.data.attributes.source_of_funds)
        }
        return obj;
      });

      if (countryID !== "0") {
        this.requestCountryDetails(countryID)
      }
    }
  }

  requestCurrencyDetails(id, isSending) {
    if (!!this.props.authReducer.accessToken) {
      if (isSending) {
        this.props.requestSendingCurrency(id);
      } else {
        this.props.requestReceivingCurrency(id);
      }
    }
  }

  requestCountryDetails(id) {
    this.props.requestReceivingCountry(id);
  }


  componentDidMount() {
    this.checkTransactionID()
  }

  render() {
    if (!!this.props.authReducer.accessToken) {
      return (
        <Content style={{ marginRight: '10vw', marginLeft: '10vw', background: 'white', height: '80vh' }} className="tr-item-content-container">
          {this.renderCheckIcon()}
          {/* {this.renderMessage()} */}
          {this.renderTransaction()}
          {Helper.baseCountryIsSA && this.renderStatus()}
          {/* <span>Have a good day!</span> */}
          <Button className="home-collect"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              marginTop: '20px'
            }} type="primary" onClick={this.loadHome}>Ok</Button>
        </Content>
      )
    } else {
      return (<SmartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError} />)
    }
  }

  loadHome = () => {
    this.props.history.push({
      pathname: "/",
    });
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }

  renderStatus() {
    if (Object.keys(this.props.transactionReducer.transaction).length > 0) {
      var status = Helper.showStatus(this.props.transactionReducer.transaction.data.attributes.status)

      if (this.props.transactionReducer.transaction.data.attributes.status === "P" || this.props.transactionReducer.transaction.data.attributes.status === "S") {
        const rawDate = this.props.transactionReducer.transaction.data.attributes.expiry_date

        const date = moment(rawDate).format("D MMM YYYY")
        const time = moment(rawDate).format("HHmm")

        status = `This quote will expire on ${date} at ${time}hrs`

      }

      return (
        <span style={{ marginRight: '10vw', marginLeft: '10vw', marginBottom: '1vh' }}>Status: {status}</span>
      )
    }
    return (
      <span />
    )
  }

  renderTransaction() {
    if (Object.keys(this.props.transactionReducer.transaction).length > 0
      && Object.keys(this.props.calculationReducer.calculation).length > 0 && Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {
      var userInstruction = "Your transaction code: " + this.props.transactionReducer.transaction.data.attributes.code;

      if (this.props.transactionReducer.transaction.data.attributes.status === "S") {
        return (
          <h4 className="tr-number-message">Your quote has been successfully generated and saved. Your transaction code: {this.props.transactionReducer.transaction.data.attributes.code}. To proceed to payment, please go to Transaction History and select the transaction with this transaction code.</h4>
        )
      } else if (this.props.transactionReducer.transaction.data.attributes.status === "E") {
        return (
          <h4 className="tr-number-message">Your quote has expired. Your transaction code: {this.props.transactionReducer.transaction.data.attributes.code}</h4>
        )
      } else {
        if (this.props.paymentMethodReducer.paymentMethod.attributes.payment_type === "PA") {

          var amountToPay = "";

          if (this.props.calculationReducer.calculation.data) {
            amountToPay = this.props.calculationReducer.calculation.data.attributes.amount_to_pay
          } else {
            amountToPay = this.props.calculationReducer.calculation.attributes.amount_to_pay;
          }

          userInstruction = "Kindly visit your nearest agent and pay in " + this.props.currencyReducer.sendingCurrency.data.attributes.letter_code + " " + amountToPay + " using the following transaction reference: " + this.props.transactionReducer.transaction.data.attributes.code;
        }

        if (this.props.paymentMethodReducer.paymentMethod.attributes.payment_type === "CD" || this.props.paymentMethodReducer.paymentMethod.attributes.payment_type === "NC" || this.props.paymentMethodReducer.paymentMethod.attributes.payment_type === "EF") {
          return (
            <h4 className="tr-number-message">Your transaction has been successful. {userInstruction}</h4>
          )
        }

        return (
          <h4 className="tr-number-message">Your quote has been successfully generated. {userInstruction}</h4>
        )
      }
    }
    return (
      <h2 className="tr-item-body">No transaction done.</h2>
    )
  }

  // renderMessage() {
  //   Log.d(TAG, "renderMessage() -- props=" + JSON.stringify(this.props))
  //   if (Object.keys(this.props.transactionReducer.transaction).length > 0 && Object.keys(this.props.userReducer.user).length > 0
  //     && Object.keys(this.props.calculationReducer.calculation).length > 0 && Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {

  //       var amountToPay = "";

  //       if (this.props.calculationReducer.calculation.data) {
  //         amountToPay = this.props.calculationReducer.calculation.data.attributes.amount_to_pay
  //       } else {
  //         amountToPay = this.props.calculationReducer.calculation.attributes.amount_to_pay;
  //       }

  //       if (this.props.transactionReducer.transaction.data.attributes.status === "P") {
  //         return (<span style={{ marginBottom: '8px', marginRight: '10vw', marginLeft: '10vw', textAlign: 'center' }}>Hi {this.props.userReducer.user.data.attributes.first_name}. Please pay using the following details: Transaction ref: {this.props.transactionReducer.transaction.data.attributes.code}; Total to pay: {this.props.currencyReducer.sendingCurrency.data.attributes.letter_code} {amountToPay}</span>)
  //       } else {
  //         return (<span style={{ marginBottom: '8px', marginRight: '10vw', marginLeft: '10vw', textAlign: 'center' }}>Hi {this.props.userReducer.user.data.attributes.first_name}. Thank you for trusting Sasai Money Transfer.</span>)
  //       }
  //   }
  //   return (
  //     <span/>
  //   )
  // }

  renderCheckIcon() {
    Log.d(TAG, "renderCheckIcon() -- props=" + JSON.stringify(this.props))
    if (Object.keys(this.props.transactionReducer.transaction).length > 0 && Object.keys(this.props.userReducer.user).length > 0
      && Object.keys(this.props.calculationReducer.calculation).length > 0 && Object.keys(this.props.currencyReducer.sendingCurrency).length > 0) {
      return (<img className="tr-number-check" src={checkIcon} alt="check" />)
    }
    return (
      <span />
    )
  }

  checkTransactionID() {
    // if (this.props.match.params.transactionID && cookies.get('access')) {
    if (this.props.match.params.transactionID && this.props.authReducer.accessToken) {
      var transactionID = this.props.match.params.transactionID
      // Log.d(TAG, "checkTransactionID() -- ID=" + transactionID + " token=" + cookies.get('access'));
      Log.d(TAG, "checkTransactionID() -- ID=" + transactionID + " token=" + this.props.authReducer.accessToken);
      // this.requestTransactionDetails(transactionID, cookies.get('access'))
      this.requestTransactionDetails(transactionID, this.props.authReducer.accessToken)
    } else if (this.props.match.params.transactionID) {
      Log.d(TAG, "checkTransactionID() -- load webview");
      window.eftSuccess(this.props.match.params.transactionID);
    } else {

    }
  }

  requestTransactionDetails(id, token) {
    this.props.requestTransactionDetails(id, token)
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestTransactionDetails,
    requestReceivingCurrency,
    requestSendingCurrency,
    requestReceivingCountry,
    fetchUserDetails,
    requestSendingCountry,
    showCalculation,
    showPaymentMethod
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionNumber)
