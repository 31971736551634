import React from 'react';
import { Layout } from 'antd';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import {
  requestForgetPassword,
  requestSendingCountries,
  showForgetPasswordData
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Input, Button, Icon, Form, message, Dropdown, Menu } from 'antd';
import '../styles/user.css'
import '../styles/forgot-password.css'

import ManageUrls from "../endpoints/manage_urls.js";
import baseUrlOptions from "../endpoints/base_url_options.js";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartForgetPassword";

class ForgetPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      current: 'mail',
    }
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
    this.props.showForgetPasswordData({})
  }

  render() {
    return (
      <Layout className="log-layout">
          <Content className="user-content-container-2">
            <Menu
              className="user-password-menu"
              onClick={this.handleClick}
              selectedKeys={[this.state.current]}
              mode="horizontal">
              <Menu.Item key="mail" className="user-password-menu-item">
                <Icon type="mail" />Send by Email
              </Menu.Item>
              <Menu.Item key="mobile" className="user-password-menu-item">
                <Icon type="mobile" />Send by SMS
              </Menu.Item>
            </Menu>
            {this.renderBody()}
            <div style={{ width: '100%', padding: '20px', textAlign: 'left'}}>
              <b style={{width: '100%', color: 'red'}}>Fields with * are required.</b>
            </div>
          </Content>
      </Layout>

    )
  }

  renderBody() {
    if (this.state.current === 'mail') {
        return (<WrappedEmailForm {...this.props} />)
    }
    return (<WrappedSMSForm {...this.props} />)
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
      if (this.props.userReducer.success === 'Please login with your new password') {
        Log.d(TAG, "componentDidUpdate() -- login now")
        Helper.clearCookies(this.props.cookies)
        this.props.history.push({
          pathname: "/login"
        });
      }
    }
  }
}

class EmailForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      sendingCountry : 0,
      phone: "",
      success: false
    }
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, 'Received values of form: '+ values);
        this.requestForgetPassword(values.email)
      }
    });
  }

  render() {
    var emailIcon = <Icon type="mail" style={{ fontSize: 13 }}/>
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="user-password-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title">Enter your e-mail address</h3>
        <FormItem style={{ width: '100%' }}>
          {getFieldDecorator('sending_country', {
              rules: [{ required: true, message: 'Please provide your country' }],
            })(
              <Dropdown
                overlay={this.renderSendingCountriesParent()}
                trigger={['click']}
                disabled={this.state.success}>
                <div className="dropdown-item">
                  {this.renderSendingCountriesDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
        <FormItem label="Your e-mail address">
          {getFieldDecorator('email', {
            rules: [{
              required: true, message: 'Please provide your e-mail address'
            }, {
              type: 'email', message: 'Please provide a valid e-mail address'
            }],
          })(
            <Input className="mobile-number"
                addonBefore={emailIcon}
             />
          )}
        </FormItem>
        <FormItem className="user-custom-btn-row">
          <Button  className="home-collect"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              marginBottom: '8px'
            }} type="primary" htmlType="submit">SEND AN E-MAIL</Button>
        </FormItem>
      </Form>
    )
  }

  requestForgetPassword(email) {

    var payload = {
      "data" : {
        "type" : "passwords",
        "id" : null,
        "attributes" : {
          "email" : email,
          "user_type": 2
        }
      }
    }
    this.props.requestForgetPassword(payload, true, "");
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    this.props.requestSendingCountries()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryReducer.sendingCountries !== this.props.countryReducer.sendingCountries && this.props.countryReducer.sendingCountries.length > 0) {
      this.props.form.setFieldsValue({
        sending_country: this.state.sendingCountry
      })
    }

  }

  renderSendingCountriesDropdown() {
    if (Object.keys(this.props.countryReducer.sendingCountries).length > 0) {
      return (
        <div>
          {this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderSendingCountriesParent(){
    return (
      <Menu 
        style={{maxHeight: "30vh", overflow: "scroll"}} 
        onClick={this.onChangeSendingCountry}
      >
        {this.rendeSendingCountries()}
      </Menu>
    )
  }

  rendeSendingCountries(){
    return this.props.countryReducer.sendingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  onChangeSendingCountry = (event) => {
    this.setState({
      sendingCountry: event.key
    }, this.updateSendingCountryAndBaseCountry);

    this.props.form.setFieldsValue({
      sending_country: this.state.sendingCountry
    })
  }

  updateBaseCountryLocalStorage = () => {
    const manageUrls = new ManageUrls()
  
    const baseCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code
    
    if(baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ usernameIsPhoneNumber: true })
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA)
    } else {
      this.setState({ usernameIsPhoneNumber: false })
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM)
    }
  }
  
  updateSendingCountryAndBaseCountry = () => {
    this.updateBaseCountryLocalStorage()
  }
}

class SMSForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sendingCountry : 0,
      phone: "",
      success: false

    }
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, 'Received values of form: '+ JSON.stringify(values));
        this.requestForgetPassword(values.phone_number, values.verification_code, values.new_password)
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countryReducer.sendingCountries !== this.props.countryReducer.sendingCountries && this.props.countryReducer.sendingCountries.length > 0) {
      this.props.form.setFieldsValue({
        sending_country: this.state.sendingCountry
      })
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      this.setState({
        success : true
      })
    }

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      this.setState({
        success : false
      })
    }

    if (Object.keys(this.props.userReducer.forgetPasswordData).length > 0 && prevProps.userReducer.forgetPasswordData !== this.props.userReducer.forgetPasswordData) {
      Log.d(TAG, "componentDidUpdate() -- set password data=" + JSON.stringify(this.props.userReducer.forgetPasswordData));
    }
  }

  renderPhonePrefix() {
    if (Object.keys(this.props.countryReducer.sendingCountries).length > 0) {
      return (
        <div style={{ width: 45 }}>
          +{this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix}
        </div>
      )
    } else {
      return (
        <div/>
      )
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    var phonePrefix = getFieldDecorator('phonePrefix', {
      initialValue: "",
    })(
      this.renderPhonePrefix()
    );

    return (
      <Form className="user-password-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title">Enter your mobile</h3>
        <FormItem style={{ width: '100%' }}>
          {getFieldDecorator('sending_country', {
              rules: [{ required: true, message: 'Please provide your country' }],
            })(
              <Dropdown
                overlay={this.renderSendingCountriesParent()}
                trigger={['click']}
                disabled={this.state.success}>
                <div className="dropdown-item">
                  {this.renderSendingCountriesDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('phone_number', {
            rules: [{ required: true, message: 'Please put your phone number' }],
          }, { initialValue: this.state.phone })(
            <Input className="mobile-number" disabled={this.state.success} addonBefore={phonePrefix} placeholder="Phone Number*"  onChange={this.onMobileChanged}/>
          )}
        </FormItem>
        {this.renderVerificationCode(getFieldDecorator)}
        <FormItem className="user-custom-btn-row">
          <Button className="home-collect"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              marginBottom: '8px'
            }} type="primary"
            htmlType="submit">{this.state.success ? 'CHANGE PASSWORD' : 'SEND AN SMS'}</Button>
        </FormItem>
      </Form>
    )
  }

  renderVerificationCode(getFieldDecorator) {
    Log.d(TAG, "renderVerificationCode() -- success=" + this.state.success)
    if (this.state.success) {
      return (
        <div>
          <FormItem>
            {getFieldDecorator('verification_code', {
              rules: [{
                required: true, message: 'Please provide your verification code',
              }]})(
              <Input className = "form-control" placeholder="Verification Code"/>
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('new_password', {
              rules: [{
                required: true, message: 'Please provide your new password',
              }, {
                validator: this.validateToNextPassword,
              }, {
                min: 6, message: 'New Password should be minumum of 6 characters',
              }],
            })(
              <Input className = "form-control" type="password" placeholder="New Password"/>
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirm_new_password', {
              rules: [{
                required: true, message: 'Please provide confirm your new password',
              }, {
                validator: this.compareToFirstPassword,
              }, {
                min: 6, message: 'Confirm New Password should be minumum of 6 characters',
              }],
            })(
              <Input className = "form-control" type="password" placeholder="Confirm New Password"/>
            )}
          </FormItem>
        </div>
      )

    }

    return (<span/>)
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('New password did not match to this value');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_new_password'], { force: true });
    }
    callback();
  }


  requestForgetPassword(mobile, code, password) {
    var attributesObj= {};

    if (code) {
      attributesObj.code = code;
    }
    if (password) {
      attributesObj.password = password;
    }

    attributesObj.user_type = 2;
    attributesObj.mobile = [this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.phone_prefix, mobile]

    var dataObj = {};
    dataObj.attributes = attributesObj
    dataObj.id = (Object.keys(this.props.userReducer.forgetPasswordData).length > 0 ? this.props.userReducer.forgetPasswordData.data.id : null)
    dataObj.type = "passwords"

    var payloadObj  ={};
    payloadObj.data = dataObj
    Log.d(TAG, "requestForgetPassword() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestForgetPassword(payloadObj, false, (Object.keys(this.props.userReducer.forgetPasswordData).length > 0 ? this.props.userReducer.forgetPasswordData.data.id : ""));
  }

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    this.props.requestSendingCountries()
  }

  renderSendingCountriesDropdown() {
    if (Object.keys(this.props.countryReducer.sendingCountries).length > 0) {
      return (
        <div>
          {this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderSendingCountriesParent(){
    return (
      <Menu 
        style={{maxHeight: "30vh", overflow: "scroll"}} 
        onClick={this.onChangeSendingCountry}
      >
        {this.rendeSendingCountries()}
      </Menu>
    )
  }

  rendeSendingCountries(){
    return this.props.countryReducer.sendingCountries.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>
        );
    })
  }

  onChangeSendingCountry = (event) => {
    this.setState({
      sendingCountry: event.key
    }, this.updateSendingCountryAndBaseCountry);

    this.props.form.setFieldsValue({
      sending_country: this.state.sendingCountry
    })
  }

  updateBaseCountryLocalStorage = () => {
    const manageUrls = new ManageUrls()
  
    const baseCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code
    
    if(baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ usernameIsPhoneNumber: true })
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA)
    } else {
      this.setState({ usernameIsPhoneNumber: false })
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM)
    }
  }
  
  updateSendingCountryAndBaseCountry = () => {
    this.updateBaseCountryLocalStorage()
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestForgetPassword,
    requestSendingCountries,
    showForgetPasswordData
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

const WrappedEmailForm = Form.create()(EmailForm);
const WrappedSMSForm = Form.create()(SMSForm);

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
