import React from 'react';
import { Layout } from 'antd';
import SmartNotificationList from '../../containers/NotificationList.js'
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import SmartLogin from '../../containers/Login.js'
import CustomFooter from '../footer.js';
import Log from '../../utils/log.js';
import '../../styles/transaction.css'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const { Header, Footer } = Layout;
const TAG = "NotificationList";

class NotificationList extends React.Component {

  componentDidMount() {
    document.title = "Notification List"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          {/* <CustomHeader  key={this.props.cookies.get('access')} {...this.props}/> */}
          <CustomHeader  key={this.props.authReducer.accessToken} {...this.props}/>
        </Header>
        {this.renderBody()}
      </Layout>
    )
  }

  renderBody() {
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      return (<SmartNotificationList {... this.props}/>)
    } else {
      return (
        <div>
          <SmartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>
          <Footer className="custom-footer">
            <CustomFooter/>
          </Footer>
        </div>
      )
    }
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    window.location.reload();
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(NotificationList));

// export default withCookies(TransactionList)
