import React from "react";
import { Layout, Card, Skeleton } from "antd";
import { bindActionCreators } from "redux";
import Log from '../../utils/log.js';
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import remitHopeLogo from "../../images/remit-hope-logo.png";

import shareALinkOrCodeImage from "../../images/share-a-link-or-code.png";
import familyAndFriendsImage from "../../images/family-and-friends.png";
import myRewards from "../../images/my-rewards.png";
import { fetchRemitHopeCategories, fetchRemitHopeProduct } from "../../actions/index.js";

const { Content, Header, Footer } = Layout;
const { Meta } = Card;

const cardStyle = {
  width: 200,
  // margin: "0 20px",
  cursor: "pointer",
};

const iconDivStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const imgStyle = {
  objectFit: "cover",
  height: "160px",
};

const TAG = "Remit Hope Home Page";

class RemitHopeHomePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: []
    }

    const accessToken = this.props.authReducer.accessToken;
    this.props.fetchRemitHopeCategories(accessToken);
    this.props.fetchRemitHopeProduct(accessToken);
  }

  componentDidMount() {
    document.title = "Remit Hope"
    window.scrollTo(0, 0)
    this.checkIfHasUser()
  }
    

  openRemitHope = () => {
    window.open(encodeURI("https://remithope.org/"));
  };

  checkIfHasUser() {
    // if (cookies.get('access')) {
    if (!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      this.props.history.push({
        pathname: "/"
      });
    } else {
      Log.d(TAG, "checkIfHasUser() -- has no user");
    }
  }

  render() {
    return (
      <div>
        <Layout>
          <Header
            className="custom-header"
            style={{ position: "fixed", width: "100%", zIndex: "1" }}
          >
            <CustomHeader {...this.props} />
          </Header>
          <Content>
            <div className="rh-main-banner">
              <div className="rh-aside-gradient d-flex align-items-center justify-content-center">
                <div className="bwl-main-banner-container">
                  <div className="bwl-main-banner-sub-container">
                    <img
                      className="bwl-justify-self-center rh-remit-hope-logo"
                      src={remitHopeLogo}
                      alt="remit-hope-logo"
                    />
                    <div className="rh-justify-self-center">
                      <h2 className="rh-main-banner-text">
                        Where every dollar changes lives!
                      </h2>
                    </div>
                    <div className="rh-justify-self-center">
                      <h2 className="rh-sub-banner-text">
                        Reimagine the way people send money home, turning each
                        transaction into an opportunity to make a meaningful
                        impact.
                      </h2>
                    </div>
                    <div className="bwl-justify-content-center">
                      <button
                        className="rh-learn-more-btn"
                        onClick={this.openRemitHope}
                      >
                        Learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h2 className="remit-hope-category-heading">Campaign Categories</h2>
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 20,
                      justifyContent: "center",
                      margin: "50px 0",
                    }}
                  >
                    {this.props.remitHopeReducer.categories.length > 0 ?

                      this.props.remitHopeReducer.categories
                      .filter((category) => category.id != 15)
                      .map((category => {
                        return (
                          <Card
                            onClick={() =>
                              this.props.history.push({
                                pathname: `/remit-hope/categories/${category.id}`,
                                state: { category }
                              })
                            }
                            style={cardStyle}
                            cover={<img src={category.image.src} style={imgStyle} />}
                          >
                            <h6 style={{textAlign: "center"}}>{category.name}</h6>
                          </Card>
                        )
                      })) :
                        <div style={{width: "70vw"}}>
                          <Skeleton 
                            active 
                            size="large"
                            paragraph={{ rows: 8 }}
                          />
                        </div>
                    }
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Content>
          <Footer className="custom-footer">
            <CustomFooter />
          </Footer>
        </Layout>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchRemitHopeCategories,
      fetchRemitHopeProduct,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(RemitHopeHomePage));
