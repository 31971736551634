import React from 'react';
import { Table, Layout, Spin, Button, Input, Menu, Dropdown, Icon } from 'antd';
import Log from '../utils/log.js';
import '../styles/transaction.css'
import {
  fetchUserDetails,
  requestTransactions,
  showTransactionList
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ErrorLayout from '../components/error-layout.js';
import CustomFooter from '../components/footer.js';
import ManageUrls from '../endpoints/manage_urls.js';
const { Content, Footer } = Layout;
const TAG = "SmartTransactionList";
const Search = Input.Search;

class TransactionList extends React.Component {

  manageUrl = new ManageUrls();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingTip: "",
      currentLink: this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1",
      columns: [{
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      }, {
        title: 'Beneficiary Name',
        dataIndex: 'beneficiary_name',
        key: 'beneficiary_name',
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      }, {
        title: 'Last Updated',
        dataIndex: 'status_date',
        key: 'status_date',
      }, {
        title: 'Created',
        dataIndex: 'log_date',
        key: 'log_date',
      }, {
        title: 'Transaction Details',
        key: 'transaction_details',
        render: (text, record) => (
          <p>{record.airtime_data_product_name}<br />@{record.amount_to_pay}</p>
        )
      },
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (text, record) => (
        //     <span>
        //      <Button onClick={(event) => { 
        //       // event.stopPropagation(); this.onDelete(record); 
        //     }}
        //       style = {{
        //         'min-width' : "100px",
        //         'height' : '30px',
        //         'color': 'rgb(41, 108, 187)',
        //         'border-radius' : "0px",
        //         'border' : "1px solid rgb(41, 108, 187)"
        //       }}
        //      >Redo Transaction</Button>
        //     </span>
        //   ),
        // }
      ],
      filter: 0,
      filters: ["All", "Pending", "Authorised", "Completed", "Cancelled", "Reversed", "Quoted", "Expired"]
    }
  }


  componentDidMount() {
    document.title = "Transaction List"
    this.requestTransactions(this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1");
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (prevProps.transactionReducer.transactionList !== this.props.transactionReducer.transactionList) {
      this.setState({
        loading: false,
        loadingTip: ""
      })
    }
  }

  requestTransactions(url) {
    Log.d(TAG, "requestTransactions() -- access=" + this.props.authReducer.accessToken);
    if (!!this.props.authReducer.guestUserAccessToken) {
      this.setState({
        loading: true,
        loadingTip: "Fetching transactions .."
      })
      this.props.requestTransactions(url, null, this.props.authReducer.guestUserAccessToken);
    }
  }

  render() {
    return (
      <Layout className="trans-layout">
        <Content className="trans-content-container">
          <div className="rl-top" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <h2 className="rl-select-recipient">Transaction List</h2>
            <div className="rl-p-search-add-right">
              <Search
                placeholder="Type recipient name or code and press 'Enter'"
                onSearch={this.onSearch}
                className="search" />
              <Dropdown overlay={this.renderFilterParent()}>
                <div className="trl-p-filter">
                  <Icon type="filter" />
                </div>
              </Dropdown>
            </div>
          </div>
          {this.renderContent()}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    )
  }

  renderFilterParent() {
    return (
      <Menu onClick={this.onChangeFilter}>
        {this.renderFilters()}
      </Menu>
    )
  }

  renderFilters() {
    return this.state.filters.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
      );
    })
  }

  onChangeFilter = (event) => {
    Log.d(TAG, "onChangeFilter() -- event.key=" + event.key);
    this.setState({
      filter: event.key
    })
    var status = "";
    if (Number(event.key) === 1) {
      status = "&filter[status]=P"
    } else if (Number(event.key) === 2) {
      status = "&filter[status]=A"
    } else if (Number(event.key) === 3) {
      status = "&filter[status]=C"
    } else if (Number(event.key) === 4) {
      status = "&filter[status]=X"
    } else if (Number(event.key) === 5) {
      status = "&filter[status]=R"
    } else if (Number(event.key) === 6) {
      status = "&filter[status]=S"
    } else if (Number(event.key) === 7) {
      status = "&filter[status]=E"
    }
    this.requestTransactions(this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1" + status);
  }

  renderContent() {
    Log.d(TAG, "renderError() -- error=" + this.props.userReducer.error);
    if (!this.props.userReducer.error) {
      return (this.renderTable())
    }
    return (
      <ErrorLayout {...this.props} />
    )
  }

  renderTable() {
    return (
      <div className="trl-point-table" style={{ width: '100%' }}>
        <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? '' : 'table-even-row'}
            size="middle"
            pagination={{ hideOnSinglePage: true }}
            className="trans-body"
            bordered
            dataSource={this.props.transactionReducer.transactionList}
            columns={this.state.columns}
            onRow={(transaction) => {
              return {
                onClick: () => {
                  Log.d(TAG, "onClickRow() -- transaction=" + JSON.stringify(transaction))
                  this.loadTransaction(transaction)
                }
              };
            }} />
        </Spin>
        <div className="tn-pagination-box">
          <div className="tn-pagination-box-left">
            {this.showPrevButton()}
            {this.showFirstButton()}
          </div>
          <div className="tn-pagination-box-middle">
            <span>Page {this.props.transactionReducer.currentPage} of {this.props.transactionReducer.totalPages} pages </span>
          </div>
          <div className="tn-pagination-box-right">
            {this.showLastButton()}
            {this.showNextButton()}
          </div>
        </div>
      </div>
    )
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    if (value && value.length > 0) {
      Log.d(TAG, "onSearch() -- search now")
      if (value.includes("/")) {
        Log.d(TAG, "onSearch() -- search by code")
        this.requestTransactions(this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1&filter[code]=" + value.trim());
      } else {
        Log.d(TAG, "onSearch() -- search by recipient")
        this.requestTransactions(this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1&filter[recipient]=" + value.trim());
      }
    } else {
      this.requestTransactions(this.manageUrl.getApiUrl() + "airtime_data_transactions?page=1");
    }
  }

  onFilter = () => {
    Log.d(TAG, "onFilter() -- called ..");
  }

  showFirstButton() {
    if (this.props.transactionReducer.first !== null) {
      return (
        <Button className="tn-pagination-box-item" onClick={() => this.requestTransactions(this.props.transactionReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.transactionReducer.last !== null) {
      return (
        <Button onClick={() => this.requestTransactions(this.props.transactionReducer.last)}
          className="tn-pagination-box-item">Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.transactionReducer.prev !== null) {
      return (
        <Button onClick={() => this.requestTransactions(this.props.transactionReducer.prev)}
          className="tn-pagination-box-item"> Prev</Button>
      )
    }
    else {
      return (
        <Button className="tn-pagination-box-item" onClick={() => this.requestTransactions(this.props.transactionReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.transactionReducer.next !== null) {
      return (
        <Button onClick={() => this.requestTransactions(this.props.transactionReducer.next)}
          className="tn-pagination-box-item">Next>></Button>
      )
    }
  }

  loadTransaction = (transaction) => {
    Log.d(TAG, "loadTransaction() -- transaction=" + JSON.stringify(transaction))
    this.props.history.push({
      pathname: "/guest-transaction-details/" + transaction.id,
      state: {
        transaction: transaction
      }
    });
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestTransactions,
    showTransactionList
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList)
