import React from "react";
import {
  Menu,
  Dropdown,
  Card,
  Form,
  Layout,
  Icon,
  message,
  Select,
  InputNumber,
  Input,
} from "antd";
import "../styles/qoute.css";
import Log from "../utils/log.js";
import Helper from "../utils/helper.js";
import {
  requestSendingCountries,
  requestReceivingCountries,
  showSendingCountries,
  showReceivingCountry,
  showSendingCountry,
  showSendingCurrency,
  showReceivingCurrency,
  showRecipient,
} from "../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../../node_modules/flag-icon-css/css/flag-icon.css";
import ManageUrls from "../endpoints/manage_urls";
import baseUrlOptions from "../endpoints/base_url_options";
import howMoneyArrivesData  from "../utils/how-money-arrives-data.json";

const { Content } = Layout;
const FormItem = Form.Item;
const Option = Select.Option;
const TAG = "SmartQuote";

class Quote extends React.Component {
  render() {
    return (
      <Layout className="quote-layout">
        <Content className="quote-content-container">
          <WrappedHomeForm {...this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {}
}

class HomeForm extends React.Component {
  constructor(props) {
    super(props);
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
    var user = {};

    this.state = {
      sendingCountry: 0,
      sendingCountriesCopy: [],
      receivingCountry: 0,
      receivingCountryObj: {},
      payoutOptions: [],
      countryPayoutPartners: [],
      payoutPartners: [],
      getFieldDecorator: this.props.form,
      sendingAmount: 0,
      receivingAmount: 0,
      amountValue: "sending_amount",
      payoutOption: 0,
      payoutOptionId: 0,
      payoutPartner: 0,
    };

    this.payoutPartnersData = howMoneyArrivesData;
    
  }

  manageUrls = new ManageUrls();
  baseCountryIsSA = () =>
    this.manageUrls.getBaseCountry() === baseUrlOptions.SOUTH_AFRICA;

  componentDidMount() {
    this.setState(
      {
        sendingCountriesCopy: this.checkHasSendingCountry()
          ? [this.props.countryReducer.sendingCountry]
          : this.props.countryReducer.sendingCountries,
      },
      () => {
        this.props.form.setFieldsValue({
          sending_country: this.state.sendingCountry,
          receiving_country: this.state.receivingCountry,
        });
      }
    );

    this.requestSendingCountry();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      if (
        Object.values(this.props.userReducer.error).includes(
          "rate request expired"
        )
      ) {
        message.loading("Fetching original rate ..", 3);
        this.requestCalculationRequest(
          this.state.amountValue,
          this.state.isReverse,
          this.props.form.getFieldValue(this.state.amountValue)
        );
      } else {
        message.error("" + this.props.userReducer.error, 5);
      }
    }

    // this is updating sending countries...
    if (
      prevProps.countryReducer.sendingCountries !==
      this.props.countryReducer.sendingCountries
    ) {
      if (this.props.countryReducer.sendingCountries.length > 0) {
        const collection = this.checkHasSendingCountry()
          ? [this.props.countryReducer.sendingCountry]
          : this.props.countryReducer.sendingCountries;
        this.setSendingCountry(collection);

        this.setState({
          receivingCountryObj: this.props.countryReducer.receivingCountries[this.state.receivingCountry],
        })
      }
    }
    
    if (
      prevProps.countryReducer.receivingCountries !==
      this.props.countryReducer.receivingCountries
    ) {
      if (this.props.countryReducer.receivingCountries.length > 0) {

        const receivingCountryObj = this.props.countryReducer.receivingCountries[this.state.receivingCountry];

        const payoutOptions = this.getPayoutOptionsByReceivingCountry(receivingCountryObj);

        this.setState({
          receivingCountryObj,
          payoutOptions,
        });

      }
    }
  }

  getPayoutOptionsByReceivingCountry(receivingCountryObj) {

    const payoutPartnersCountry = this.payoutPartnersData.find(country => 
      country.attributes.alpha_2_code === receivingCountryObj.attributes.alpha_2_code
    );

    if(payoutPartnersCountry === undefined) {
      return [];
    }

    const allPayoutOptions = payoutPartnersCountry.attributes.payout_partners.map((payoutPartner) => payoutPartner.payout_option);

    const countryPayoutPartners = payoutPartnersCountry.attributes.payout_partners;

    this.setState({
      countryPayoutPartners,
    })

    const objectPayoutOptions = {};
    
    for (let newOption of allPayoutOptions) {
      objectPayoutOptions[newOption.name] = newOption;
    }

    const pleaseSelectOption = {
      id: 0,
      name: "Please a payout option",
    }

    const payoutOptionsBefore = Object.values(objectPayoutOptions);

    return [pleaseSelectOption, ...payoutOptionsBefore];
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    if (this.state.sendingCountriesCopy.length > 0) {
      this.requestReceivingCountries(
        this.state.sendingCountriesCopy[this.state.sendingCountry].attributes
          .alpha_2_code
      );
    } else {
      Log.d(TAG, "requestSendingCountries() -- request");
      const { cookies } = this.props;
      // if (cookies.get('access')) {
      if (!!this.props.authReducer.accessToken) {
        Log.d(TAG, "requestSendingCountries() -- has access");
        // this.props.fetchUserDetails(cookies, cookies.get('access'), false, "Fetched user's details")
        this.props.fetchUserDetails(
          cookies,
          this.props.authReducer.accessToken,
          false,
          "Fetched user's details"
        );
      } else {
        Log.d(TAG, "requestSendingCountries() -- has no access");
        this.props.requestSendingCountries();
      }
    }
  }

  checkHasSendingCountry() {
    return this.props.countryReducer.sendingCountry.hasOwnProperty(
      "attributes"
    );
  }

  setSendingCountry(collection) {
    if (collection.length > 0) {
      Log.d(
        TAG,
        "setSendingCountry() -- country=" + JSON.stringify(collection[0])
      );
      this.setState({
        sendingCountry: 0,
        sendingCountriesCopy: collection,
      });

      this.requestReceivingCountries(collection[0].attributes.alpha_2_code);
    }
  }

  requestReceivingCountries(sendingCountry) {
    if (this.props.countryReducer.receivingCountries.length > 0) {
      Log.d(
        TAG,
        "requestReceivingCountries() -- has receiving countries, request products and services"
      );
    } else {
      Log.d(TAG, "requestReceivingCountries() -- request receiving countries");
      this.props.requestReceivingCountries(sendingCountry);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Content>
        <div className="row">
          <div className="col-sm-6">
            <Card
              className="mb-3"
              style={{ fontFamily: "Lato", borderRadius: "4px" }}
              title="How money arrives"
            >
              {this.renderRecipientReq(getFieldDecorator)}
            </Card>
            {this.state.payoutPartners.length > 0 && (
              <CashOutLocation 
                payoutPartners={this.state.payoutPartners}
                payoutPartner={this.state.payoutPartner}
              />
            )}
          </div>
          <div className="col-sm-6">
            <CashOutProcess />
          </div>
        </div>
      </Content>
    );
  }

  renderRecipientReq(getFieldDecorator) {
    return (
      <div className="home-item">
        <FormItem label="Choose a destination" className="su-row-full">
              {getFieldDecorator('nationality1', {
                  rules: [{ required: true, 
                  message: "Please provide recipient's country"}], 
                  initialValue: 0,
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Please provide recipient's country"
                    optionFilterProp="children"
                    onChange={this.onChangeReceivingCountry}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                  {this.renderReceivingCountryParent()}
                </Select>
                )}
            </FormItem>

        <div className="quote-form--1">
          <FormItem label="Choose Payout Option">
            {getFieldDecorator("payout_option", {
              rules: [{ required: true, message: "Please payout option" }],
            })(
              <Dropdown
                overlay={this.renderPayoutOptionParent()}
                trigger={['click']}>
                <div className="dropdown-item">
                  {this.renderPayoutOptionsDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        </div>
        <div className="quote-form--1">
          <FormItem label="Choose Payout Partner">
            {getFieldDecorator("payout_partner", {
              rules: [
                { required: true, message: "Please payout option" },
                { validator: this.validatePayoutPartner },
              ],
            })(
              <Dropdown
                overlay={this.renderPayoutPartnerParent()}
                trigger={['click']}>
                <div className="dropdown-item">
                  {this.renderPayoutPartnersDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>
        </div>
      </div>
    );
  }

  onChangeReceivingCountry = (value) => {
    Log.d(TAG, "onChangeReceivingCountry() -- value=" + value);
    const receivingCountryObj = this.props.countryReducer.receivingCountries[value];
    
    const payoutOptions = this.getPayoutOptionsByReceivingCountry(receivingCountryObj);
    
    this.setState({
      receivingCountry: value,
      receivingCountryObj,
      payoutOptions,
      payoutOption: 0,
      payoutOptionId: 0,
      payoutPartners: [],
    })

    this.props.form.setFieldsValue({
      receiving_country: value,
      payout_partner: 0,
    });
  }

  renderReceivingCountryParent(){
    return this.props.countryReducer.receivingCountries.map((itemAr, index) => {
      return (
        <Option key={`${index} - ${itemAr.id}`} value={index}>{itemAr.attributes.name}</Option>
        );
    })
  }

  validatePayoutPartner = (rule, value, callback) => {
    const form = this.props.form;
    if (!form.getFieldValue("payout_option")) {
      callback("Please fill in payout option, first");
    }
    callback();
  };

  onChangePayoutOption = (event) => {

    const selectedPayoutOption = this.state.payoutOptions[+event.key];

    const beforePayoutPartners = this.state.countryPayoutPartners.filter((partner) => partner.payout_option.id === selectedPayoutOption.id);

    const pleaseSelectPartner = {
      name: "Please select payout partner",
      id: 0,
    }

    const payoutPartners = [pleaseSelectPartner, ...beforePayoutPartners];

    this.setState({
      payoutOption: +event.key,
      payoutOptionId: selectedPayoutOption.id,
      payoutPartner: 0,
      payoutPartners,
    });
    
    this.props.form.setFieldsValue({
      payout_option: event.key
    });
  }

  renderPayoutOptionParent() {
    return (
      <Menu onClick={this.onChangePayoutOption}>
        {this.renderPayoutOptions()}
      </Menu>
    )
  }

  renderPayoutOptions() {
    return this.state.payoutOptions.map((itemAr, index) => {
      return (
        <Menu.Item key={index} disabled={index === 0} value={itemAr.id}>
          {itemAr.name}
        </Menu.Item>
      );
    })
  }

  renderPayoutOptionsDropdown() {
    if (this.state.payoutOptions.length > 0) {
      return (
        <div>
          {this.state.payoutOptions[this.state.payoutOption].name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  onChangePayoutPartner = (event) => {
    this.setState({
      payoutPartner: +event.key,
    });
  }

  renderPayoutPartnerParent() {
    return (
      <Menu onClick={this.onChangePayoutPartner}>
        {this.renderPayoutPartners()}
      </Menu>
    )
  }

  renderPayoutPartners() {
    return this.state.payoutPartners.map((itemAr, index) => {
      return (
        <Menu.Item key={index} disabled={index === 0} value={itemAr.id}>
          {itemAr.name}
        </Menu.Item>
      );
    })
  }

  renderPayoutPartnersDropdown() {
    if (this.state.payoutPartners.length > 0) {
      return (
        <div>
          {this.state.payoutPartners[this.state.payoutPartner].name}
        </div>
      )
    }
    return (
      <div>
        Not Available
      </div>
    )
  }

  renderPartners() {
    return this.state.payoutPartners.map((itemAr, index) => {
      return (
        <Option key={`${index} - ${itemAr.id}`} value={itemAr.id}>
          {itemAr.name}
        </Option>
      );
    });
  }

  renderSendingCountriesDropdown() {
    if (this.state.sendingCountriesCopy.length > 0) {
      Log.d("renderSendingCountriesDropdown() -- return has item");
      return (
        <div className="flag-country-name">
          {
            this.state.sendingCountriesCopy[this.state.sendingCountry]
              .attributes.name
          }
        </div>
      );
    }
    Log.d("renderSendingCountriesDropdown() -- return no item");
    return <div>Not Available</div>;
  }

  renderSendingCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeSendingCountry}
      >
        {this.rendeSendingCountries()}
      </Menu>
    );
  }

  onChangeSendingCountry = (event) => {
    this.setState(
      {
        sendingCountry: event.key,
      },
      this.onUpdateSendingCountry
    );
    this.props.form.setFieldsValue({
      sending_country: event.key,
    });
  };

  onUpdateSendingCountry() {
    let baseCountryAlphaCode =
      this.state.sendingCountriesCopy[this.state.sendingCountry].attributes
        .alpha_2_code;
    this.props.authReducer.accessToken ||
      this.updateBaseCountry(baseCountryAlphaCode);
    this.props.requestReceivingCountries(baseCountryAlphaCode);
  }

  updateBaseCountry = (baseCountryAlphaCode) => {
    let manageUrls = new ManageUrls();
    if (baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA);
    } else {
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM);
    }
  };
}
class CashOutProcess extends React.Component {
  render() {
    return (
      <div className="ant-card ant-card-bordered mb-3">
        <div className="ant-card-body">
          <div className="row">
            <div className="col">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Cashout Process:</span>
              </h4>
              <ol className="list-group mb-3">
                <div className="list-group-item d-flex justify-content-between lh-condensed">
                  <li>
                    The recipient will receive an Ecocash Voucher code via SMS.
                  </li>
                </div>
                <div className="list-group-item d-flex justify-content-between lh-condensed">
                  <li>
                    The recipient will be required to visit any Econet Shop on
                    the list of cashout locations listed to the right.
                  </li>
                </div>
                <div className="list-group-item d-flex justify-content-between lh-condensed">
                  <li>
                    The recipient will be required to produced their proof of ID
                    (Nation ID, Passport or Drivers License) and voucher code.
                  </li>
                </div>
                <div className="list-group-item d-flex justify-content-between lh-condensed">
                  <li>
                    The recipient can choose to either get cash or deposit the
                    funds into their EcoCash wallet.
                  </li>
                </div>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function CashOutLocation({payoutPartners, payoutPartner: payoutPartnerIndex}) {
  const payoutParnter = payoutPartners[payoutPartnerIndex];
  const locations = payoutParnter.payout_option ? payoutParnter.payout_option.locations : []; 

  if(payoutPartnerIndex > 0 && locations.length > 0) {
    const isAddress = payoutParnter.payout_option.location_type === "addresses";

    return (
      <div className="ant-card ant-card-bordered mb-3">
        <div className="ant-card-body">
          <div className="row">
            <div className="col">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Cashout Locations:</span>
              </h4>
              {isAddress ? (
                <ol className="list-group mb-3" style={{ maxHeight: "35vh", overflow: "scroll" }}>
                  {locations.map((location) => (
                    <div key={location.address} className="list-group-item d-flex justify-content-between lh-condensed">
                      <li>
                        {location.name && (<p><b>{location.name}</b></p>)} 
                        <p>{location.address}</p>
                      </li>
                    </div>
                  ))}
                </ol>
              ) : (
                locations.map((location) => (
                  <button
                    style={{ marginTop: 8, backgroundColor: 'rgb(41, 108, 187)', borderColor: 'rgb(41, 108, 187)', boxSizing: 'border-box', color: 'white', border: '1px solid rgb(41, 108, 187)' }}
                    className="su-btn-register btn btn-primary"
                    onClick={() => window.open(encodeURI(location.link))}
                  >
                    Click for more details
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (payoutParnter.payout_option) {
    if (payoutParnter.payout_option.id === 2 && payoutPartnerIndex > 0) {
      return (
        <div className="ant-card ant-card-bordered mb-3">
          <div className="ant-card-body">
            <div className="row">
              <div className="col">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-muted">Cashout Locations:</span>
                </h4>
                <ol className="list-group mb-3" style={{ maxHeight: "35vh", overflow: "scroll" }}>
                  <div className="list-group-item d-flex justify-content-between lh-condensed">
                    <p>
                      Location information not available
                    </p>
                  </div>
                </ol>
              </div>
            </div>
          </div>
        </div>
      )} else {
        return (<></>)
      };
  } else {
    return (<></>)
  }
}

const WrappedHomeForm = Form.create()(HomeForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestSendingCountries,
      requestReceivingCountries,
      showSendingCountries,
      showReceivingCountry,
      showSendingCountry,
      showSendingCurrency,
      showReceivingCurrency,
      showRecipient,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
