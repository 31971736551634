import React from "react";
import { Layout } from "antd";
import { withCookies } from "react-cookie";
import Log from "../utils/log.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CustomHeader from "./header.js";
import CustomFooter from "./footer.js";
import "../styles/index.css";
import SmartSelectProductOption from "../containers/GuestSelectProductOption";

const { Header, Footer } = Layout;
const TAG = "DumbAirtimeDataSelectProductOption";

class GuestSelectProductOption extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <SmartSelectProductOption {...this.props} />
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }

  componentDidMount() {
    document.title = "Get Quote";
    window.scrollTo(0, 0);
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(GuestSelectProductOption));
