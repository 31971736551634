import React from "react";
import {
  Form,
  Input,
  Menu,
  Button,
  Dropdown,
  Icon,
  Checkbox,
  message,
} from "antd";
import { Layout } from "antd";
import Log from "../utils/log.js";
import "../styles/recipient.css";

import { fetchReferralsAirtimeDataProducts, requestOTPCode } from "../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartAddAirtimeSender";

class AirtimeDataRecipientDetails extends React.Component {
  render() {
    return (
      <Content className="ar-content-container">
        <WrappedAirtimeDataRecipientDetailsForm {...this.props} />
      </Content>
    );
  }
}

class AirtimeDataRecipientDetailsForm extends React.Component {
  constructor(props) {
    super(props);

    const receivingCountries =
      this.props.countryReducer.airtimeDataReceivingCountries;
    let sendingCountry = {};
    let sender = {};

    this.state = {
      receivingCountries,
      sender,
      sendingCountry,
      receivingCountry: receivingCountries[0],
      receivingCountryIndex: 0,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      message.error("" + this.props.userReducer.error);
      this.setState({
        loading: false,
      });
    }

    if (
      prevProps.referralsReducer.airtimeDataBundleProducts !==
      this.props.referralsReducer.airtimeDataBundleProducts
    ) {
      if (
        Object.keys(this.props.referralsReducer.airtimeDataBundleProducts)
          .length > 0
      ) {
        if (
          this.props.referralsReducer.airtimeDataBundleProducts.data.length > 0
        ) {
          const values = this.props.form.getFieldsValue();
          const recipientData = this.prepareValues(values);

          this.setState({
            loading: false,
          });

          this.props.history.push({
            pathname: "/my-rewards/airtime-data/choose-bundle",
            state: {
              sender: this.state.sender,
              receivingCountries: this.state.receivingCountries,
              receivingCountry: recipientData.receivingCountry,
              sendingCountry: this.state.sendingCountry,
              recipient: recipientData.recipient,
            },
          });
        } else {
          this.setState({
            loading: false,
          });

          const balance =
            this.props.referralsReducer.commissions.data.meta.balance.amount;

          if (+balance === 0) {
            window.alert(
              "You have insufficient commissions to redeem airtime or data bundle"
            );
          } else {
            window.alert(
              "Network operator not available for entered mobile number"
            );
          }
        }
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="ru-box">
        <Form className="ar-body" onSubmit={this.handleSubmit}>
          <h2 className="ar-add-recipient">Recipient Details</h2>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              {this.renderReceivingCountries(getFieldDecorator)}
              {this.renderFirstName(getFieldDecorator)}
              {this.renderLastName(getFieldDecorator)}
              {this.renderPhoneInput(getFieldDecorator)}
              {/* {this.renderConfirmCheckbox(getFieldDecorator)} */}
              <div>
                <p>{this.state.loading && "Please wait..."}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </div>
                <FormItem className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </FormItem>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  renderConfirmCheckbox(getFieldDecorator) {
    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <FormItem>
          {getFieldDecorator("confirm details", {
            rules: [
              {
                required: true,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean",
                message: "Please confirm if the above details are correct",
              },
            ],
          })(
            <Checkbox>
              <b>
                I hereby confirm that the information provided herein is
                accurate
              </b>
            </Checkbox>
          )}
        </FormItem>
      </div>
    );
  }

  renderSaveRecipientCheckbox(getFieldDecorator) {
    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <FormItem>
          {getFieldDecorator("save recipient", {
            rules: [
              {
                required: false,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean",
                message: "Please confirm if the above details are correct",
              },
            ],
          })(
            <Checkbox
              onChange={(e) =>
                this.setState({ saveRecipient: e.target.checked })
              }
            >
              <b>Save recipient details</b>
            </Checkbox>
          )}
        </FormItem>
      </div>
    );
  }

  renderCountries() {
    if (!!this.state) {
      return this.state.receivingCountries.map((itemAr, index) => {
        return <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>;
      });
    } else {
      return <div />;
    }
  }

  onChangeCountry = (event) => {
    this.setState({
      receivingCountry: this.state.receivingCountries[event.key],
    });
    this.props.form.setFieldsValue({
      "receiving-country": event.key,
    });
    this.props.form.setFieldsValue({
      phonePrefix:
        this.state.receivingCountries[event.key].attributes.phone_prefix,
    });
  };

  renderCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    );
  }

  renderReceivingCountry() {
    if (
      this.state.receivingCountry != null &&
      Object.keys(this.state.receivingCountry).length > 0
    ) {
      return <div>{this.state.receivingCountry.attributes.name}</div>;
    } else {
      return <div />;
    }
  }

  renderReceivingCountries(getFieldDecorator) {
    return (
      <FormItem label="Country">
        {getFieldDecorator("receiving-country", {
          rules: [
            {
              required: true,
              message: "Please select airtime receiving country",
            },
          ],
          initialValue: this.state.receivingCountryIndex,
        })(
          <Dropdown trigger={["click"]} overlay={this.renderCountriesParent()}>
            <div className="dropdown-item">
              {this.renderReceivingCountry()}
              <Icon type="down" />
            </div>
          </Dropdown>
        )}
      </FormItem>
    );
  }

  renderFirstName(getFieldDecorator) {
    return (
      <FormItem label="First Name">
        {getFieldDecorator("first-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient first name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderLastName(getFieldDecorator) {
    return (
      <FormItem label="Last Name">
        {getFieldDecorator("last-name", {
          rules: [
            {
              required: true,
              message: "Please enter recipient last name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderPhonePrefix() {
    const phonePrefix = this.state.receivingCountry.attributes.phone_prefix;
    if (Object.keys(this.state.receivingCountry).length > 0) {
      return <div style={{ width: 45 }}>+{phonePrefix}</div>;
    } else {
      return <div />;
    }
  }

  renderPhoneInput(getFieldDecorator) {
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: this.state.receivingCountry.attributes.phone_prefix,
    })(this.renderPhonePrefix());

    const phoneNumber = "";

    const label = "Mobile Number";

    return (
      <FormItem label={label}>
        {getFieldDecorator("mobile-number", {
          rules: [
            // { validator: this.validateBSField },
            {
              required: true,
              message: "Please enter recipient mobile number",
            },
          ],
          initialValue: phoneNumber,
        })(
          <Input
            addonBefore={phonePrefix}
            className="mobile-number"
            maxlength="20"
          />
        )}
      </FormItem>
    );
  }

  prepareValues(values) {
    const receivingCountry =
      this.state.receivingCountries[values["receiving-country"]];
    const beneficiaryData = {
      receivingCountry,
      recipient: {
        beneficiary_mobile: [values["phonePrefix"], values["mobile-number"]],
        beneficiary_first_name: values["first-name"],
        beneficiary_last_name: values["last-name"],
      },
    };
    return beneficiaryData;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const access = this.props.authReducer.accessToken;
        this.props.fetchReferralsAirtimeDataProducts(
          access,
          values["phonePrefix"] + "" + values["mobile-number"]
        );
        this.setState({
          loading: true,
        });
      }
    });
  };
}

const WrappedAirtimeDataRecipientDetailsForm = Form.create()(
  AirtimeDataRecipientDetailsForm
);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      fetchReferralsAirtimeDataProducts,
      requestOTPCode,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirtimeDataRecipientDetails);
