import React from 'react';
import Log from '../utils/log.js';
import config from '../env-config.json'
import {
  showCashInPoints,
  requestCashPoints
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, message, Table, Spin, Button, Input } from 'antd';
import '../styles/dashboard.css'

const { Content } = Layout;
const TAG = "SmartCashInPoints";
const Search = Input.Search;

class CashInPoints extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "CashInPoints::constructor() -- props=" + JSON.stringify(props));
    this.state = {
      currentLink: config.api_url + "cash_in_points/?page=1",
      loading : false,
      loadingTip : "",
      columns : [
        {
         title: 'City',
         dataIndex: 'city',
         key: 'city',
       }, {
         title: 'Address',
         dataIndex: 'address',
         key: 'address',
       }, {
         title: 'Location',
         dataIndex: 'location',
         key: 'location',
       },{
         title: 'Contact Person',
         dataIndex: 'contact_person',
         key: 'contact_person',
       },{
         title: 'Phone',
         dataIndex: 'phone',
         key: 'phone',
       }]
    }
  }

  componentWillMount() {
    this.props.showCashInPoints([]);
    this.requestCashInPoints(this.state.currentLink)
  }

  render() {
    return (
      <Content className="cashout-content-container">
        <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
          <div className="cash-point-table">
            <div className="rl-top">
              <div/>
              <div className="rl-p-search-add-right">
                <Search
                  placeholder="Search cash in point"
                  onSearch={this.onSearch}
                  style={{ width: '100%', height: '40px' }}
                />
              </div>
            </div>
            <Table
              rowClassName={(record, index) => index%2 === 0 ? '' : 'table-even-row' }
              size = "middle"
              pagination = {{hideOnSinglePage : true}}
              className="cashout-table-body"
              bordered
              dataSource={this.props.cashInPointsReducer.cashInPoints}
              columns={this.state.columns}/>
            <div className="pagination-box">
              <div className = "pagination-box-left">
                {this.showPrevButton()}
                  {this.showFirstButton()}
              </div>
              <div className = "pagination-box-middle">
                {this.renderCurrentLink()}
              </div>
              <div className = "pagination-box-right">
                  {this.showLastButton()}
                  {this.showNextButton()}
              </div>
            </div>
          </div>

        </Spin>
      </Content>
    )
  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (<span className="agent-page-text">Page {this.state.currentLink.slice(this.state.currentLink.lastIndexOf("=") + 1, this.state.currentLink.length)} of {this.showLastPage()} pages</span>)
    }
    return (<div/>)
  }

  showLastPage() {
    if (this.props.cashInPointsReducer.last !== null) {
      return (
        <span>{this.props.cashInPointsReducer.last.slice(this.props.cashInPointsReducer.last.lastIndexOf("=") + 1, this.props.cashInPointsReducer.last.length)}</span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  showFirstButton() {
    if (this.props.cashInPointsReducer.first !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashInPoints(this.props.cashInPointsReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.cashInPointsReducer.last !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashInPoints(this.props.cashInPointsReducer.last)}>Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.cashInPointsReducer.prev !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashInPoints(this.props.cashInPointsReducer.prev)}>Prev</Button>
      )
    }else {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashInPoints(this.props.cashInPointsReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.cashInPointsReducer.next !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashInPoints(this.props.cashInPointsReducer.next)}>Next>></Button>
      )
    }
  }

  requestCashInPoints(url) {
    this.setState({
      loading : true,
      loadingTip : "Fetching cash in points .."
    })
    this.props.requestCashPoints(url, "in");
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error);
    }

    if (prevProps.cashInPointsReducer.cashInPoints !== this.props.cashInPointsReducer.cashInPoints) {
      Log.d(TAG, "componentDidUpdate() -- cashInPoints=" + JSON.stringify(this.props.cashInPointsReducer.cashInPoints))
      this.setState({
        loading : false,
        loadingTip : "",
        currentLink : this.props.cashInPointsReducer.url
      })
    }
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    this.requestCashInPoints(config.api_url + "cash_in_points/?filter[q]=" + value + "&page=1")
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    showCashInPoints,
    requestCashPoints
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(CashInPoints)
