import "antd/dist/antd.css";
import "whatwg-fetch";
import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./app.js";
import { CookiesProvider } from "react-cookie";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
// import 'core-js/es/map';
// import 'core-js/es/set';

import reducer from "./reducers";
const store = createStore(reducer, compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__? window.__REDUX_DEVTOOLS_EXTENSION__(): f => f
));

class Entry extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

ReactDOM.render(
  <CookiesProvider>
    <Entry />
  </CookiesProvider>,
  document.getElementById("root")
);
