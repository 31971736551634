import React from 'react';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import Log from '../../utils/log.js';
import PartLogin from '../../containers/Login.js'
import { Layout } from 'antd';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import '../../styles/login.css'

const { Header, Footer } = Layout;
const TAG = "Login";

class Login extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  render() {
    return (
      <div className="wrapper">
          <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
            <CustomHeader {...this.props} />
          </Header>
          <PartLogin {... this.props} onLogin={this.onLogin} onLoginError={this.onLoginError}/>
          <Footer className="custom-footer">
            <CustomFooter/>
          </Footer>
        </div>
     
    )
  }

  componentDidMount() {
    document.title = "Log In"
    window.scrollTo(0, 0)
  }

  onLogin = () => {
    Log.d(TAG, "onLogin() -- called ..");
    this.props.history.push({
      pathname: "/",
      state: { prevPath: "login" }
    });
  }

  onLoginError = () => {
    Log.d(TAG, "onLoginError() -- called ..");

  }

}
export default withCookies(Login)
