import React from 'react';
import Log from '../utils/log.js';
import config from '../env-config.json'
import {
  showCashOutPoints,
  requestCashPoints
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, message, Table, Spin, Button, Input, Modal  } from 'antd';
import '../styles/dashboard.css'

const { Content } = Layout;
const TAG = "SmartCashOutPoints";
const Search = Input.Search;

class CashOutPoints extends React.Component {

  constructor(props) {
    super(props);
    Log.d(TAG, "CashOutPoints::constructor() -- props=" + JSON.stringify(props));
    this.state = {
      currentLink: config.api_url + "cash_out_points/?page=1",
      loading : false,
      loadingTip : "",
      visible: false,
      columns : [
        {
         title: 'City',
         dataIndex: 'city',
         key: 'city',
       }, {
         title: 'Address',
         dataIndex: 'address',
         key: 'address',
       }, {
         title: 'Location',
         dataIndex: 'location',
         key: 'location',
       },{
         title: 'Contact Person',
         dataIndex: 'contact_person',
         key: 'contact_person',
       },{
         title: 'Phone',
         dataIndex: 'phone',
         key: 'phone',
       }]
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ visible: false });
    }, 3000);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  componentWillMount() {
    this.props.showCashOutPoints([]);
    //this.props.requestCashPoints(this.state.currentLink, "out");
    this.requestCashOutPoints(this.state.currentLink)
  }

  render() {
    return (
      <Content className="cashout-content-container">
        <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
          <div className="cash-point-table">
            <div className="rl-top">
              <div/>
              <div className="rl-p-search-add-right">
                <Search
                  placeholder="Search cash out point"
                  onSearch={this.onSearch}
                  style={{ width: '100%', height: '40px' }}
                />
              </div>
            </div>
            <Table
              rowClassName={(record, index) => index%2 === 0 ? '' : 'table-even-row' }
              size = "middle"
              pagination = {{hideOnSinglePage : true}}
              className="cashout-table-body"
              bordered
              dataSource={this.props.cashOutPointsReducer.cashOutPoints}
              columns={this.state.columns}/>
            <div className="pagination-box">
              <div className = "pagination-box-left">
                {this.showPrevButton()}
                  {this.showFirstButton()}
              </div>
              <div className = "pagination-box-middle">
                {this.renderCurrentLink()}
              </div>
              <div className = "pagination-box-right">
                  {this.showLastButton()}
                  {this.showNextButton()}
              </div>
            </div>
          </div>
        </Spin>
        <Button style={{ maxWidth: '100%', background: '#154989', marginTop: '20px', marginLeft: '8px', marginRight: '8px', border: '1px solid #154989'}} type="primary" onClick={this.showModal}>
          Cash Collection Points
        </Button>
        <Modal
          visible={this.state.visible}
          title="Cash Collection Points in Zimbabwe"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={900}
          footer={null}>
          <div className="cash-out-agents"/>
        </Modal>
      </Content>
    )
  }

  renderCurrentLink() {
    if (this.state.currentLink) {
      return (<span className="agent-page-text">Page {this.state.currentLink.slice(this.state.currentLink.lastIndexOf("=") + 1, this.state.currentLink.length)} of {this.showLastPage()} pages</span>)
    }
    return (<div/>)
  }

  showLastPage() {
    if (this.props.cashOutPointsReducer.last !== null) {
      return (
        <span>{this.props.cashOutPointsReducer.last.slice(this.props.cashOutPointsReducer.last.lastIndexOf("=") + 1, this.props.cashOutPointsReducer.last.length)}</span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  showFirstButton() {
    if (this.props.cashOutPointsReducer.first !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashOutPoints(this.props.cashOutPointsReducer.first)}>First</Button>
      )
    }
  }

  showLastButton() {
    if (this.props.cashOutPointsReducer.last !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashOutPoints(this.props.cashOutPointsReducer.last)}>Last</Button>
      )
    }
  }

  showPrevButton() {
    if (this.props.cashOutPointsReducer.prev !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashOutPoints(this.props.cashOutPointsReducer.prev)}>Prev</Button>
      )
    }else {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashOutPoints(this.props.cashOutPointsReducer.prev)} disabled> Prev</Button>
      )
    }
  }

  showNextButton() {
    if (this.props.cashOutPointsReducer.next !== null) {
      return (
        <Button className = "pagination-box-item" onClick={() => this.requestCashOutPoints(this.props.cashOutPointsReducer.next)}>Next>></Button>
      )
    }
  }

  requestCashOutPoints(url) {
    this.setState({
      loading : true,
      loadingTip : "Fetching cash out points .."
    })
    this.props.requestCashPoints(url, "out");
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error);
    }

    if (prevProps.cashOutPointsReducer.cashOutPoints !== this.props.cashOutPointsReducer.cashOutPoints) {
      Log.d(TAG, "componentDidUpdate() -- cashOutPoints=" + JSON.stringify(this.props.cashOutPointsReducer.cashOutPoints))
      this.setState({
        loading : false,
        loadingTip : "",
        currentLink : this.props.cashOutPointsReducer.url
      })
    }
  }

  onSearch = (value) => {
    Log.d(TAG, "onSearch() -- value=" + value)
    this.requestCashOutPoints(config.api_url + "cash_out_points/?filter[q]=" + value + "&page=1")
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    showCashOutPoints,
    requestCashPoints
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(CashOutPoints)
