import React from "react";
import { Button, Layout } from "antd";
import { withCookies } from "react-cookie";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";
import "../../styles/dashboard.css";

const { Content, Header, Footer } = Layout;

class CookiePolicy extends React.Component {
  componentDidMount() {
    document.title = "Cookie Policy";
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <Content className="cookie-policy-body">
          {CookiePolicyContent(this.props)}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}

const CookiePolicyContent = (props) => {

    const onClickFindOutMore = () => {
        window.open(encodeURI("https://en.wikipedia.org/wiki/HTTP_cookie"))
    }

    return (
        <>
            <h2 style={{ fontFamily: 'Lato' }}>Cookie Policy</h2>
            
            <span className="terms-item-child">Our website places cookies on your computer or mobile device. Most websites you visit will use cookies. 
            Cookies help us provide you with an enhanced user experience and also allow us to improve our site. Cookies cannot be used to identify you 
            personally. By using our site, you agree to our use of cookies. Please note that it is possible to disable cookies being stored on your 
            computer by changing your browser settings, however our website may not function properly in that case.</span>

            <h2 style={{ fontFamily: 'Lato' }}>What is a cookie?</h2>

            <span className="terms-item-child">A cookie is a simple text file that is stored on your computer or mobile device by a website’s server and 
            only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain 
            some anonymous information such as a unique identifier and the site name and some digits and numbers. It allows a website to remember things 
            like your preferences or what’s in your shopping basket.</span>

            <span className="terms-item-child"><Button className="transaparent-button" onClick={onClickFindOutMore}><b>Find out more.</b></Button></span>

        </>
    )
}

export default withCookies(CookiePolicy)