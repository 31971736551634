import React from 'react';
import Log from '../utils/log.js';
import { Input, Form, Layout, message,Button } from 'antd';
import {
  sendFeedback
} from '../actions';
import '../styles/dashboard.css'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const { Content } = Layout;
const FormItem = Form.Item;
const { TextArea } = Input;
const TAG = "SmartFeedback";

class Feedback extends React.Component {

  render() {
    return (
      <Layout>
        <Content>
          <WrappedSendFeedbackForm {... this.props}/>
        </Content>
      </Layout>
    )
  }
}

class SendFeedbackForm extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.userReducer.success !== null && prevProps.userReducer.success !== this.props.userReducer.success) {
      Log.d(TAG, "componentDidUpdate() -- set success=" + this.props.userReducer.success)
      message.success("" + this.props.userReducer.success, 5)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.sendFeedback(values.name, values.email, values.message)
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} style={{marginTop: '100px'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <FormItem style={{width: '48%'}}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'This field is required' }]
            })(
              <Input placeholder="Name"/>
            )}
          </FormItem>
          <FormItem style={{width: '48%'}}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'This field is required' }, { type: 'email', message: 'Please provide a valid e-mail address' }]
            })(
              <Input placeholder="Email"/>
            )}
          </FormItem>
        </div>
        <FormItem>
          {getFieldDecorator('message', {
            rules: [{ required: true, message: 'This field is required' }]
          })(
            <TextArea rows={4} placeholder="Message"/>
          )}
        </FormItem>
        <FormItem className = "form-group">
          <Button className="home-collect" style={{ backgroundColor: '#154989', boxSizing: 'border-box', color: 'white' }}  type="primary" htmlType="submit">Send Message</Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedSendFeedbackForm = Form.create()(SendFeedbackForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    sendFeedback
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
