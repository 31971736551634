import React from "react";
import Autocomplete from 'react-autocomplete';
import Log from "../utils/log.js";
import {
  Input,
  Menu,
  Dropdown,
  Icon,
  Form,
  message,
  Checkbox,
  Layout,
  Steps,
  Button,
} from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import Helper from "../utils/helper.js";
import {
  requestSendingCountries,
  requestSignUp,
  requestLogin,
  requestPCAPredictAddressFind,
  requestPCAPredictAddressRetrieve,
  requestCountries,
  setSendingCountry,
  fetchUserDetails,
  requestUpdateAddressDetails,
} from '../actions';

const { Content } = Layout;
const FormItem = Form.Item;
const Step = Steps.Step;
const TAG = "SmartSignUp";

const steps = [
  {
    title: "Step 1",
    description: "Basic Information",
  },
  {
    title: "Step 2",
    description: "Upload Documents",
  },
  {
    title: "Step 3",
    description: "Upload Selfie",
  },
];

class WhereYouLive extends React.Component {

  componentDidMount() {}

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedWhereYouLiveForm {... this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {}
}

class WhereYouLiveForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sendingCountry: 0, // step 1
      baseCountryIsSA: true,
      phone: "",
      whatsapp_mobile: "",
      password: "",
      refCode: "",
      emailAdd: "",
      confirmPassword: "",
      sendPromotions: false,
      sendingCountries: [],
      pType: "password",
      pTypeIcon: "eye",
      cpType: "password",
      cpTypeIcon: "eye",
      genders: [ "Male", "Female" ],
      maxBirthDate : moment().subtract(18, 'years'),
      birthDate: moment().subtract(18, 'years'),

      addressDatasource: [], // step 3
      selectedAddressId : "",
      provinces : [
        { "code": "EC", "value": "Eastern Cape" },
        { "code": "FS", "value": "Free State" },
        { "code": "GT", "value": "Gauteng" },
        { "code": "NL", "value": "KwaZulu Natal" },
        { "code": "LP", "value": "Limpopo" },
        { "code": "MP", "value": "Mpumalanga" },
        { "code": "NW", "value": "North West" },
        { "code": "NC", "value": "Northern Cape" },
        { "code": "WC", "value": "Western Cape" }
      ],
      chosenProvinceIndex: 0,
      chosenProvince: "",
      address : '',
      openDropDown: false,
      homeWallet: false,
      step: 0,
      current: 0,
      getFieldDecorator: this.props.form,
      occupation: "",
      visible: false,
      city: "",
      address_line_1: "",
      address_line_2: "",
      postal_code: "0000",

      // add logic for ensuring that the customer has read
      // the Ts & Cs plus Customer Privacy Notice
      hasReadTsnCsPrivacyNotice: false,
    };
  }

  current = 0;

  componentDidUpdate(prevProps) {
    if (
      this.props.countryReducer.sendingCountries.length > 0 &&
      prevProps.countryReducer.sendingCountries !==
        this.props.countryReducer.sendingCountries
    ) {
      this.setState({
        sendingCountry: 0,
      });
      this.props.form.setFieldsValue({
        sendingCountry: 0,
      });
    }

    if (
      this.props.countryReducer.countries.length > 0 &&
      prevProps.countryReducer.countries !== this.props.countryReducer.countries
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set countries=" +
          JSON.stringify(this.props.countryReducer.countries)
      );
    }

    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      if (this.props.userReducer.error.includes("Invalid credentials")) {
        var errorMessage =
          "We found an error verifying your credentials based on your registration details.";
        if (Object.keys(this.props.userReducer.user).length > 0) {
          Log.d(
            TAG,
            "componentDidUpdate() -- error has user=" +
              JSON.stringify(this.props.userReducer.user)
          );
          errorMessage =
            errorMessage +
            " Your account status is " +
            Helper.showUserStatus(
              this.props.userReducer.user.data.attributes.user_status
            ) +
            ".";
        }
        message.error(errorMessage, 5);
      } else {
        message.error("" + this.props.userReducer.error, 5);
      }
    }

    if (
      this.props.userReducer.signUpSuccess !== false &&
      prevProps.userReducer.signUpSuccess !==
        this.props.userReducer.signUpSuccess
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- sign up success=" +
          this.props.userReducer.signUpSuccess
      );
      var payload = Helper.baseCountryIsSA
        ? {
            username:
              this.props.countryReducer.sendingCountries[
                this.state.sendingCountry
              ].attributes.phone_prefix +
              " " +
              this.state.phone.replace(/\s/g, ""),
            password: this.state.password,
          }
        : { username: this.state.emailAdd, password: this.state.password };
      this.requestLogin(payload);
    }

    if (
      this.props.userReducer.success !== null &&
      prevProps.userReducer.success !== this.props.userReducer.success
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set success=" + this.props.userReducer.success
      ); // means sign up is successful
    }

    if (this.props.userReducer.pcaPredictAddresses.length > 0 && prevProps.userReducer.pcaPredictAddresses !== this.props.userReducer.pcaPredictAddresses) {
      Log.d(TAG, "componentDidUpdate() -- set addresses=" + this.props.userReducer.success) // means sign up is successful
      this.setState({
        addressDatasource : this.props.userReducer.pcaPredictAddresses
      })
    }

    /* Auto-complete functionality lines 309-330 */
    if (prevProps.userReducer.pcaPredictAddress !== this.props.userReducer.pcaPredictAddress) {
      Log.d(TAG, "componentDidUpdate() -- address=" + JSON.stringify(this.props.userReducer.pcaPredictAddress))
      this.props.form.setFieldsValue({
        postal_code : this.props.userReducer.pcaPredictAddress.PostalCode
      })
      /*this.props.form.setFieldsValue({
        street : this.props.userReducer.pcaPredictAddress.Street
      })*/
      this.props.form.setFieldsValue({
        address_line_1 : this.props.userReducer.pcaPredictAddress.Line1
      })
      this.props.form.setFieldsValue({
        address_line_2 : this.props.userReducer.pcaPredictAddress.Line2
      })
      this.props.form.setFieldsValue({
        city_town : this.props.userReducer.pcaPredictAddress.City
      })
      this.props.form.setFieldsValue({
        state_province : this.props.userReducer.pcaPredictAddress.Province
      })
      this.setState({
        chosenProvince : this.props.userReducer.pcaPredictAddress.Province
      })


      // remove additional operations on province state field for auto-complete
      // Log.d(TAG, "componentDidUpdate() -- province code=" + this.props.userReducer.pcaPredictAddress.ProvinceCode)
      // if (this.props.userReducer.pcaPredictAddress.ProvinceCode && this.props.userReducer.pcaPredictAddress.ProvinceCode.length >= 2) {
      //   this.checkProvince(this.props.userReducer.pcaPredictAddress);
      // }
    }
  }

  componentDidMount() {
    this.requestSendingCountry();
    this.requestCountries();

    this.props.fetchUserDetails(this.props.cookies, this.props.authReducer.accessToken, false, null);
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    this.props.requestSendingCountries();
  }

  requestCountries() {
    Log.d(TAG, "requestCountries() -- called ..");
    this.props.requestCountries();
  }


  renderProvinceParent(){
    return (
      <Menu onClick={this.onChangeProvinceIndex}>
        {this.renderProvinces()}
      </Menu>
    )
  }

  renderProvinces(){
    return this.state.provinces.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr.value}</Menu.Item>
        );
    })
  }

  onChangeProvinceIndex = (event) => {
    Log.d(TAG, "onChangeProvinceIndex() -- event.key=" + event.key);
    this.setState({
      chosenProvinceIndex : event.key
    })
    this.props.form.setFieldsValue({
      state_province: event.key
    })
  }

  onChangeProvince = (event) => {
    Log.d(TAG, "onChangeProvinceIndex() -- event.key=" + event.key);
    this.setState({
      chosenProvince : event.target.value
    })
    this.props.form.setFieldsValue({
      state_province: event.target.value
    })
  }

  renderProvinceName() {
    return (
      <div>
        {this.state.provinces[this.state.chosenProvinceIndex].value}
      </div>
    )
  }

  requestAddress(text, container) {
    if (text) {
      if (this.props.countryReducer.sendingCountries.length > 0) {
        this.setState({
          addressDatasource : []
        })
        this.props.requestPCAPredictAddressFind(text, container, this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code);
      }
    } else {
      Log.d(TAG, "requestAddress() -- close dropdown");
      this.setState({
        openDropDown : false,
        addressDatasource : []
      })
    }
  }

  // Auto-complete functionality lines 1356-1390
  renderOption(getFieldDecorator){
    return (
      <Autocomplete
        className="su-autocomplete-row-full"
        getItemValue={(item) => item.Text}
        items={this.state.addressDatasource}
        renderItem={(item, isHighlighted) =>
          <div className="su-address-item">
            <b>{item.Text}</b>
            <span>{item.Description}</span>
          </div>
        }
        wrapperProps={{ style: {width: '100%', marginBottom: '28px'}}}
        inputProps={{
          style: {
            "width": '100%',
            "border": '1px solid #d9d9d9',
            "height": '46px',
            "padding": '4px 11px',
            "border-radius": '0px',
            "font-size": '14px',
            "line-height": '1.5',
            "margin-top": '20px'
          },
          "placeholder": 'Start typing your address'
        }}
        open={this.state.openDropDown}
        onChange={this.onChangeAddress}
        onSelect={this.onSelectAddress}
        value={this.state.address}
        menuStyle={{overflow:'scroll', maxHeight: '150px', background: 'white'}}
        onMenuVisibilityChange={this.onMenuVisibilityChange}/>
    )
  }

  onMenuVisibilityChange = (isOpen) => {
    Log.d(TAG, "onMenuVisibilityChange() -- isOpen="+ isOpen);
  }

  onChangeAddress = (e) => {
    Log.d(TAG, "onChangeAddress() -- value="+ e.target.value)

    this.requestAddress(e.target.value, "");
    this.setState({
      address : e.target.value,
      openDropDown : true
    })

  }

  onSelectAddress = (value, item) => {
    Log.d(TAG, "onSelectAddress() -- value="+ value + " item=" + JSON.stringify(item))
    var description = item.Description;
    var regex = new RegExp("^.+([0-9]*) Addresses$");
    var testAddress = regex.test(description);
    this.setState({
      address : item.Text
    })
    if (testAddress) {
      Log.d(TAG, "onSelectAddress() -- request=" + value)
      this.requestAddress(value, item.Id);
    } else {
      Log.d(TAG, "onSelectAddress() -- DO NOT request=" + value)
      this.setState({
        openDropDown : false
      })
      this.props.requestPCAPredictAddressRetrieve(item.Id);
    }
  }

  onAddressLine1Changed = (event, newValue) => {
    this.props.form.setFieldsValue({
      address_line_1: event.target.value
    })
    this.setState({ address_line_1: event.target.value })
  }

  onAddressLine2Changed = (event, newValue) => {
    this.props.form.setFieldsValue({
      address_line_2: event.target.value
    })
    this.setState({ address_line_2: event.target.value })
  }

  onCityChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      city: event.target.value
    })
    this.setState({ city: event.target.value })
  }

  onPostalChanged = (event, newValue) => {
    this.props.form.setFieldsValue({
      postal_code: event.target.value
    })
    this.setState({ postal_code: event.target.value })
  }

  loadTerms = () => {
    this.setState({ hasReadTsnCsPrivacyNotice: true })
    window.open(encodeURI("https://" + window.location.hostname + "/terms-of-use"))
  }

  renderStep1(getFieldDecorator, phonePrefix) {
    Log.d(TAG, "renderStep1() -- mobile=" + this.state.phone);

    const maxLength = Helper.baseCountryIsSA ? 4 : 20
    const minLength = Helper.baseCountryIsSA ? 4 : 0
    const patternRegx = Helper.baseCountryIsSA ? new RegExp('^\\d+$') : new RegExp('[^\\n]+')

    const submitButtonStyle = {
      marginTop: 8,
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
    };

    return (
      <div>
        <div className="su-item-2">
        <div className="su-left-title-text">
          <b>{this.markRequired}Tell us where you live</b>
        </div>
        {/* Auto-complete functionality line 756 */}
        {this.renderOption(getFieldDecorator)}
        <FormItem className="su-custom-row" >
          {getFieldDecorator('address_line_1', {
            rules: [{ required: true, message: Helper.baseCountryIsSA ? 'Please provide your street address' : 'Please provide your address line 1*' }], initialValue: this.state.address_line_1
            })(
              <Input placeholder={Helper.baseCountryIsSA ? "Street address*" : "Address line 1"} onChange={this.onAddressLine1Changed}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('address_line_2', {
            rules: [{ required: Helper.baseCountryIsSA, message: Helper.baseCountryIsSA ? 'Please provide your suburb' : 'Please provide your address line 2' }], initialValue: this.state.address_line_2
            })(
              <Input placeholder={Helper.baseCountryIsSA ? "Suburb*" : "Address line 2*"} onChange={this.onAddressLine2Changed}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('city_town', {
              rules: [{ required: true, message: 'Please provide your city' }], initialValue: this.state.city
            })(
              <Input placeholder="City*" onChange={this.onCityChanged}
                className="su-tf-2 form-control"/>
            )}
        </FormItem>
        <FormItem className="su-custom-row" >
          {getFieldDecorator('postal_code',
            {
              rules: [
                { required: true, message: Helper.baseCountryIsSA ? 'Please provide your postal code. ' : 'Please provide your post code. '},
                { pattern: patternRegx, message: Helper.baseCountryIsSA ? 'Postal code must be a number. ' :  'Post code must be a number. ' },
                { min: minLength, message: Helper.baseCountryIsSA ? 'Postal code is too short. ' : 'Post code is too short. '},
                { max: maxLength, message: Helper.baseCountryIsSA ? 'Postal code is too long. ' : 'Post code is too long. ' },
                { validator: (_, value) => value !== '0000' ? Promise.resolve() : Promise.reject('Please provide valid postal code. ') },
              ],
              initialValue: this.state.postal_code === "0000" ? "" : this.state.postal_code
            })(
              <Input placeholder={Helper.baseCountryIsSA ? "Postal Code*" : "Post Code*"} onChange={this.onPostalChanged}
                className="su-tf-2 form-control"/>
          )}
        </FormItem>
        <div className="su-left-title-text">
          <b>{this.markRequired}Select your province </b>
        </div>
        { Helper.baseCountryIsSA ? (
            <FormItem className = "su-row-full">
              {getFieldDecorator('state_province', {
                rules: [{ required: false }], initialValue: this.state.chosenProvinceIndex
              })(
                <Dropdown
                  className="su-tf-2 form-control"
                  trigger={['click']}
                  overlay={this.renderProvinceParent()}>
                  <div className="dropdown-item">
                    <div>
                      {this.renderProvinceName()}
                    </div>
                    <Icon type="down" />
                  </div>
                </Dropdown>
              )}
            </FormItem>
          ) : (
            <FormItem className = "su-row-full">
              {getFieldDecorator('state_province', {
                rules: [{ required: Helper.baseCountryIsSA, message: 'Please provide your county/province' }], initialValue: this.state.chosenProvince
              })(
                <Input placeholder="County/Province*" onChange={this.onChangeProvince}
                  className="su-tf-2 form-control" />
              )}
            </FormItem>
          )
        }
        <FormItem className="su-row-full" style = {{ textAlign: "left"}}>
          {getFieldDecorator('valid_address', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [{
                required: true,
                message: 'Please tick this checkbox',
                transform: value => (value || undefined),
                type: 'boolean'
              }],
            })(
              <Checkbox>Confirm address information is valid.{this.markRequired}</Checkbox>
          )}
        </FormItem>
        {this.state.hasReadTsnCsPrivacyNotice && (<FormItem className="su-row-full" style = {{ textAlign: "left"}}>
          {getFieldDecorator('terms', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [{
                required: true,
                message: 'Please tick this checkbox',
                transform: value => (value || undefined),
                type: 'boolean'
              }],
          })(
            <Checkbox>I agree and have read the <Button className="transaparent-button" onClick={this.loadTerms}>Terms and Conditions and the Privacy Notice</Button>{this.markRequired}</Checkbox>
          )}
        </FormItem>)}
        <div style={{ width: "100%", padding: "20px", textAlign: "left" }}>
          <b style={{ width: "100%", color: "red" }}>
            Fields with * are required.
          </b>
        </div>
        {this.state.hasReadTsnCsPrivacyNotice ? (
          <button
            type="submit"
            style={submitButtonStyle}
            className="su-btn-register btn btn-primary">
            CREATE ACCOUNT
          </button>
          
          ) : (
          <button
            style={submitButtonStyle}
            className="su-btn-register btn btn-primary"
            onClick={this.loadTerms}
          >
            VIEW TERMS AND CONDITIONS
          </button>
          )}
        </div>
      </div>
    );
  }

  handleSubmit(e) {
    Log.d(TAG, "handleSubmit() -- called ..");
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      Log.d(TAG, "handleSubmit() -- values=" + JSON.stringify(values));
      Log.d(TAG, "handleSubmit() -- err=" + JSON.stringify(err));
      if (!err) {
        // alert(`values: ${JSON.stringify(values)}`)
        // this.checkTerms(err, values);

        const cookies = this.props.cookies;
        const userId = this.props.userReducer.user.data.id;
        const access = this.props.authReducer.accessToken;

        const payload = {
          data: {
            attributes: {
              address_line_1: values.address_line_1 ? values.address_line_1 : this.state.address_line_1,
              address_line_2: values.address_line_2 ? values.address_line_2 : this.state.address_line_2,
              building_no: null,
              city: values.city_town ? values.city_town : this.state.city,
              county: Helper.baseCountryIsSA ? this.state.provinces[this.state.chosenProvinceIndex].code : this.state.chosenProvince,
              flat: null,
              postcode: values.postal_code ? values.postal_code : this.state.postal_code,
              street: null,
            },
            id: userId,
            type: "accounts",
          },
        };

        this.props.requestUpdateAddressDetails(cookies, userId, access, payload, () => {
          this.props.history.push({
            pathname: "/update-profile",
            state: {
              defaultActiveKey: "3",
            }
          });
        });
      }
    });
  }

  actionButtons() {
    // const next = () => {
      // this.props.history.push({
      //   pathname: "/personal-details",
      // });
    // }
    const prev = () => {
      
    }

    const buttonStyle = {
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
      marginRight: 8,
    }

    return (
      <>
        <Button
          style={buttonStyle}
          onClick={() => prev()}
        >
          Prev
        </Button>
        <Button
          style={buttonStyle}
          htmlType="submit"
        >
          Next
        </Button>
      </>
    );
  }

  disabledDate = (current) => {
    Log.d(TAG, "disabledDate() -- current=" + current);
    if (current) {
      var hundredRangeMet = current < moment().subtract(100, 'years').endOf('day');
      var eighteenRangeMet = current > this.state.maxBirthDate.endOf('day');
      var rangeMet = hundredRangeMet || eighteenRangeMet
      return rangeMet;
    }
    return false;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const phonePrefix = {}
    // const phonePrefix = getFieldDecorator("phonePrefix", {
    //   initialValue: "",
    // })(this.renderPhonePrefix());

    return (
      <Form className="signup-body" onSubmit={this.handleSubmit.bind(this)}>
        <h2 style={{ fontFamily: "Lato" }}>Sign Up</h2>
        <div style={{ marginTop: "4vh", marginBottom: "4vh" }}>
          <Steps style={{ margin: "0" }} current={0}>
            {steps.map((item) => (
              <Step
                key={item.title}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </div>
        {this.renderStep1(getFieldDecorator, phonePrefix)}
      </Form>
    );
  }
}

const WrappedWhereYouLiveForm = Form.create()(WhereYouLiveForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestSendingCountries,
    requestSignUp,
    requestLogin,
    requestPCAPredictAddressFind,
    requestPCAPredictAddressRetrieve,
    requestCountries,
    setSendingCountry,
    fetchUserDetails,
    requestUpdateAddressDetails,
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhereYouLive);
