import config from "../env-config.json";
import LocalStorageService from "./local_storage_service";
import baseUrlOptions from "./base_url_options";

class UrlMapper {

    getUrlValues() {
        return this.mapToStoredCountry()
    }

    getSAValues() {
        return {
            baseCountry : baseUrlOptions.SOUTH_AFRICA,
            apiUrl : config.za_api_url,
            baseApiUrl : config.za_base_api_url,
            authUrl : config.za_auth_url,
            uploadUrl : config.za_upload_url,
            proxyUrl : config.za_proxy_url,
        }
    }

    getUKValues() {
        return {
            baseCountry : baseUrlOptions.UNITED_KINGDOM,
            apiUrl : config.gb_api_url,
            baseApiUrl : config.gb_base_api_url,
            authUrl : config.gb_auth_url,
            uploadUrl : config.gb_upload_url,
            proxyUrl : config.gb_proxy_url,
        }
    }
    
    mapToStoredCountry() {

        const storage = new LocalStorageService()

        const country = storage.getBaseCountry()

        switch (country) {

            // UNITED KINGDOM
            case baseUrlOptions.UNITED_KINGDOM:
                return this.getUKValues()
        
            // default option is 
            // SOUTH AFRICA
            default:
                return this.getSAValues()
        }
    }

}

export default UrlMapper