import React from "react";
import { Form, Input, Menu, Button, Dropdown, Icon, Checkbox, message } from "antd";
import { Layout } from "antd";
import Log from "../utils/log.js";
import "../styles/recipient.css";

import { requestAirtimeDataReceivingCountries, requestOTPCode, removeGuestUserToken } from "../actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartAddAirtimeSender";

class AddAirtimeSenderDetails extends React.Component {
  render() {
    return (
      <Content className="ar-content-container">
        <WrappedAddAirtimeSenderForm {...this.props} />
      </Content>
    );
  }
}

class AddAirtimeSenderForm extends React.Component {
  constructor(props) {
    super(props);

    const countries = this.props.countryReducer.airtimeDataSendingCountries;

    this.state = {
      sendingCountries: countries,
      sendingCountry: countries[0],
      goToOTPScreen: false,
      requestTransactionHistory: false,
      requestRecipientList: false,
    };
  }

  componentDidMount() {
    this.setState({
      goToOTPScreen: false,
    });
    this.props.removeGuestUserToken()
  }

  componentDidUpdate(prevProps) {

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      if(this.props.userReducer.error === "Not found.") {
        message.error("No transaction history available because you have not done any transactions yet. Click NEXT and proceed to buy airtime.");
      } else {
        message.error("" + this.props.userReducer.error);
      }
    }

    if (prevProps.countryReducer.airtimeDataReceivingCountries
      !== this.props.countryReducer.airtimeDataReceivingCountries
      && this.props.countryReducer.airtimeDataReceivingCountries.length > 0) {
      const values = this.props.form.getFieldsValue();
      const senderData = this.prepareOTPValues(values);
      if (this.state.goToOTPScreen) {
        this.props.requestOTPCode(senderData.sender);
      } else {
        const values = this.props.form.getFieldsValue();
        const senderData = this.prepareValues(values);
        const { sender: otpSender } = this.prepareOTPValues(values);
        this.props.history.push({
          pathname: 'airtime-recipient-details',
          state: {
            otpSender,
            sender: senderData.sender,
            sendingCountry: senderData.sendingCountry,
            requestTransactionHistory: this.state.requestTransactionHistory,
            requestRecipientList: this.state.requestRecipientList,
          }
        });
      }
    }

    if (prevProps.otpReducer.success !== this.props.otpReducer.success &&
      this.props.otpReducer.success) {
      const values = this.props.form.getFieldsValue();
      const { sender: otpSender } = this.prepareOTPValues(values);
      const senderData = this.prepareValues(values);
      this.props.history.push({
        pathname: 'airtime-otp',
        state: {
          otpSender,
          sender: senderData.sender,
          sendingCountry: senderData.sendingCountry,
          requestTransactionHistory: this.state.requestTransactionHistory,
          requestRecipientList: this.state.requestRecipientList,
        }
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="ru-box">
        <Form className="ar-body" onSubmit={this.handleSubmit}>
          <h2 className="ar-add-recipient">Sender Details</h2>
          <hr></hr>
          <div className="row">
            <div className="col-12">
              {this.renderSendingCountries(getFieldDecorator)}
              {this.renderFirstName(getFieldDecorator)}
              {this.renderLastName(getFieldDecorator)}
              {this.renderPhoneInput(getFieldDecorator)}
              {this.renderConfirmCheckbox(getFieldDecorator)}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/send-airtime-data",
                      });
                    }}
                  >
                    Back
                  </Button>
                </div>
                <FormItem className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "#154989",
                      boxSizing: "border-box",
                      color: "white",
                      margin: "8px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </Button>
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div className="form-group">
                  <Button
                    className="home-collect"
                    style={{
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      color: "#154989",
                      margin: "8px",
                    }}
                    onClick={(e) => {
                      // this.handleSubmit(e);
                      e.preventDefault();
                      this.props.form.validateFieldsAndScroll((err, values) => {
                        if (!err) {
                          this.setState({
                            goToOTPScreen: true,
                            requestTransactionHistory: true,
                          });
                          const sendingCountry =
                            this.state.sendingCountries[
                            values["sending-country"]
                            ];
                          this.props.requestAirtimeDataReceivingCountries(
                            sendingCountry.attributes.alpha_2_code
                          );
                        }
                      });
                    }}
                  >
                    Purchase History
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  renderConfirmCheckbox(getFieldDecorator) {
    return (
      <div style={{ width: "100%", textAlign: "left" }}>
        <FormItem>
          {getFieldDecorator("confirm details", {
            rules: [
              {
                required: true,
                transform: (value) => value || undefined, // Those two lines
                type: "boolean",
                message: "Please confirm if the above details are correct",
              },
            ],
          })(
            <Checkbox>
              <b>
                I hereby confirm that the information provided herein is
                accurate
              </b>
            </Checkbox>
          )}
        </FormItem>
      </div>
    );
  }

  renderCountries() {
    if (!!this.state) {
      return this.state.sendingCountries.map((itemAr, index) => {
        return <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>;
      });
    } else {
      return <div />;
    }
  }

  onChangeCountry = (event) => {
    this.setState({
      sendingCountry: this.state.sendingCountries[event.key],
    });
    this.props.form.setFieldsValue({
      "sending-country": event.key,
    });
    this.props.form.setFieldsValue({
      phonePrefix:
        this.state.sendingCountries[event.key].attributes.phone_prefix,
    });
  };

  renderCountriesParent() {
    return (
      <Menu
        style={{ maxHeight: "30vh", overflow: "scroll" }}
        onClick={this.onChangeCountry}
      >
        {this.renderCountries()}
      </Menu>
    );
  }

  renderSendingCountry() {
    if (Object.keys(this.state.sendingCountry).length > 0) {
      return <div>{this.state.sendingCountry.attributes.name}</div>;
    } else {
      return <div />;
    }
  }

  renderSendingCountries(getFieldDecorator) {
    return (
      <FormItem label="Country">
        {getFieldDecorator("sending-country", {
          rules: [
            {
              required: true,
              message: "Please select airtime sending country",
            },
          ],
          initialValue: 0,
        })(
          <Dropdown trigger={["click"]} overlay={this.renderCountriesParent()}>
            <div className="dropdown-item">
              {this.renderSendingCountry()}
              <Icon type="down" />
            </div>
          </Dropdown>
        )}
      </FormItem>
    );
  }

  renderFirstName(getFieldDecorator) {
    return (
      <FormItem label="First Name">
        {getFieldDecorator("first-name", {
          rules: [
            {
              required: true,
              message: "Please enter sender first name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderLastName(getFieldDecorator) {
    return (
      <FormItem label="Last Name">
        {getFieldDecorator("last-name", {
          rules: [
            {
              required: true,
              message: "Please enter sender last name",
            },
          ],
          initialValue: "",
        })(<Input className="form-control" />)}
      </FormItem>
    );
  }

  renderPhonePrefix() {
    const phonePrefix = this.state.sendingCountry.attributes.phone_prefix;
    if (Object.keys(this.state.sendingCountry).length > 0) {
      return <div style={{ width: 45 }}>+{phonePrefix}</div>;
    } else {
      return <div />;
    }
  }

  renderPhoneInput(getFieldDecorator) {
    const phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: this.state.sendingCountry.attributes.phone_prefix,
    })(this.renderPhonePrefix());

    const phoneNumber = "";

    const label = "Mobile Number";

    return (
      <FormItem label={label}>
        {getFieldDecorator("mobile-number", {
          rules: [
            // { validator: this.validateBSField },
            {
              required: true,
              message: "Please enter sender mobile number",
            },
          ],
          initialValue: phoneNumber,
        })(
          <Input
            addonBefore={phonePrefix}
            className="mobile-number"
            maxlength="9"
          />
        )}
      </FormItem>
    );
  }

  prepareValues(values) {
    const sendingCountry =
      this.state.sendingCountries[values["sending-country"]];
    const senderData = {
      sendingCountry,
      sender: {
        sender_mobile: [values["phonePrefix"], values["mobile-number"]],
        sender_first_name: values["first-name"],
        sender_last_name: values["last-name"],
      },
    };
    return senderData;
  }

  prepareOTPValues(values) {
    const sendingCountry =
      this.state.sendingCountries[values["sending-country"]];
    const senderData = {
      sendingCountry,
      sender: {
        username: `${values["phonePrefix"]} ${values["mobile-number"]}`,
        first_name: values["first-name"],
        last_name: values["last-name"],
      },
    };
    return senderData;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // this.showConfirm(values);
        const sendingCountry = this.state.sendingCountries[values["sending-country"]];

        this.setState({
          goToOTPScreen: false,
        });
        
        this.props.requestAirtimeDataReceivingCountries(sendingCountry.attributes.alpha_2_code);
      }
    });
  };
}



const WrappedAddAirtimeSenderForm = Form.create()(AddAirtimeSenderForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestAirtimeDataReceivingCountries,
      requestOTPCode,
      removeGuestUserToken
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAirtimeSenderDetails);
