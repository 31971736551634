import React from 'react';
import { Layout } from 'antd';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import UserForgetPassword from '../../containers/ForgetPassword.js'
import { withCookies } from 'react-cookie';
import '../../styles/user.css'

const { Header, Footer } = Layout;

class ForgetPassword extends React.Component {

  render() {
    return (
      <Layout>
        <Header className="custom-header"  style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <UserForgetPassword {...this.props} />
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  componentDidMount() {
    document.title = "Forget Password"
    window.scrollTo(0, 0)
  }
}

export default withCookies(ForgetPassword)
