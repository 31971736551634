export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const SHOW_SENDING_COUNTRIES = 'SHOW_SENDING_COUNTRIES';
export const SHOW_RECEIVING_COUNTRIES = 'SHOW_RECEIVING_COUNTRIES';
export const SHOW_COUNTRIES = 'SHOW_NATIONALITIES';
export const SHOW_SENDING_COUNTRY = 'SHOW_SENDING_COUNTRY';
export const SHOW_RECEIVING_COUNTRY = 'SHOW_RECEIVING_COUNTRY';
export const SHOW_SENDING_CURRENCY = 'SHOW_SENDING_CURRENCY';
export const SHOW_RECEIVING_CURRENCY = 'SHOW_RECEIVING_CURRENCY';
export const SHOW_PRODUCTS_SERVICES = 'SHOW_PRODUCTS_SERVICES';
export const SHOW_PRODUCT_DETAILS = 'SHOW_PRODUCT_DETAILS';
export const SHOW_TRANSACTION_LIST = 'SHOW_TRANSACTION_LIST';
export const SHOW_TRANSACTION_DETAILS = 'SHOW_TRANSACTION_DETAILS';
export const UPDATE_TRANSACTION_DETAILS = 'UPDATE_TRANSACTION_DETAILS';
export const SHOW_CALCULATION_REQUEST = 'SHOW_CALCULATION_REQUEST';
export const SHOW_CALCULATION = 'SHOW_CALCULATION';
export const SHOW_RECIPIENTS = 'SHOW_RECIPIENTS';
export const SHOW_RECIPIENT = 'SHOW_RECIPIENT';
export const SHOW_BRANCHES = 'SHOW_BRANCHES';
export const SHOW_CPLS = 'SHOW_CPLS';
export const SHOW_CPLS_SHOPS = 'SHOW_CPLS_SHOPS';
export const SHOW_CPPS = 'SHOW_CPPS';
export const SHOW_FORGET_PASSWORD_DATA = 'SHOW_FORGET_PASSWORD_DATA';
export const SHOW_PHONE_CONFIRMATION_ID = 'SHOW_PHONE_CONFIRMATION_ID';
export const SHOW_CARDS = 'SHOW_CARDS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_PAYMENT = 'SHOW_PAYMENT';
export const SHOW_LIMIT = 'SHOW_LIMIT';
export const SHOW_CURRENT_CARD = 'SHOW_CURRENT_CARD';
export const SHOW_SUCCESS_SIGN_UP = 'SHOW_SUCCESS_SIGN_UP';
export const SHOW_PCA_PREDICT_ADDRESS = 'SHOW_PCA_PREDICT_ADDRESS';
export const SHOW_PCA_PREDICT_ADDRESSES = 'SHOW_PCA_PREDICT_ADDRESSES';
export const SHOW_PCA_PREDICT_ERROR = 'SHOW_PCA_PREDICT_ERROR';
export const LOAD_LOGIN = 'LOAD_LOGIN';
export const SHOW_AGENTS = 'SHOW_AGENTS';
export const SHOW_CURRENT_MMP = 'SHOW_CURRENT_MMP';
export const SHOW_COOKIES_NOTIFICATION = 'SHOW_COOKIES_NOTIFICATION';
export const SHOW_CASH_OUT_POINTS = 'SHOW_CASH_OUT_POINTS';
export const SHOW_CASH_IN_POINTS = 'SHOW_CASH_IN_POINTS';
export const SHOW_BREADWINNER_BENEFICIARIES = 'SHOW_BREADWINNER_BENEFICIARIES';
export const SHOW_BREADWINNER_BENEFICIARY = 'SHOW_BREADWINNER_BENEFICIARY';
export const SHOW_BREADWINNER_BENEFICIARY_INDEX = 'SHOW_BREADWINNER_BENEFICIARY_INDEX';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const SET_ACCESS_REFRESH_TOKENS = 'SET_ACCESS_REFRESH_TOKENS';
export const REMOVE_ACCESS_REFRESH_TOKENS = 'REMOVE_ACCESS_REFRESH_TOKENS';
export const SET_FIRSTNAME_LASTNAME = 'SET_FIRSTNAME_LASTNAME';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_FIRSTNAME_LASTNAME = 'GET_FIRSTNAME_LASTNAME';
export const SHOW_AIRTIME_DATA_PRODUCTS = 'SHOW_AIRTIME_DATA_PRODUCTS';
export const SHOW_AIRTIME_DATA_PRODUCT_DETAILS = 'SHOW_AIRTIME_DATA_PRODUCT_DETAILS';
export const SHOW_AIRTIME_DATA_RECEIVING_COUNTRIES = 'SHOW_AIRTIME_DATA_RECEIVING_COUNTRIES';
export const SHOW_AIRTIME_DATA_SENDING_COUNTRIES = 'SHOW_AIRTIME_DATA_SENDING_COUNTRIES';
export const SHOW_OTP_CODE = 'SHOW_OTP_CODE';
export const SHOW_OTP_SIGN_IN = 'SHOW_OTP_SIGN_IN';
export const SHOW_GUEST_USER_TOKENS = 'SHOW_GUEST_USER_TOKENS';
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';
export const SHOW_UNREAD_NOTIFICATIONS_COUNT = 'SHOW_UNREAD_NOTIFICATIONS_COUNT';
export const UPDATE_READ_NOTIFICATIONS = 'UPDATE_READ_NOTIFICATIONS';
export const SHOW_REFERRALS = 'SHOW_REFERRALS';
export const SHOW_COMMISSIONS = 'SHOW_COMMISSIONS';
export const SHOW_CAMPAIGNS = 'SHOW_CAMPAIGNS';
export const SHOW_REFERRALS_AIRTIME_DATA_BUNDLE_PRODUCTS = 'SHOW_REFERRALS_AIRTIME_DATA_BUNDLE_PRODUCTS';
export const SHOW_REFERRALS_VOUCHER = 'SHOW_REFERRALS_VOUCHER';
export const SHOW_REFERRALS_AIRTIME_DATA_BUNDLE = 'SHOW_REFERRALS_AIRTIME_DATA_BUNDLE';
export const SHOW_REFERENCE_CODE = 'SHOW_REFERENCE_CODE';
export const SHOW_REMIT_HOPE_CATEGORIES = 'SHOW_REMIT_HOPE_CATEGORIES';
export const SHOW_REMIT_HOPE_CAMPAIGNS = 'SHOW_REMIT_HOPE_CAMPAIGNS';
export const SHOW_REMIT_HOPE_PRODUCT = 'SHOW_REMIT_HOPE_PRODUCT';
export const SHOW_REMIT_HOPE_CALCULATE = 'SHOW_REMIT_HOPE_CALCULATE';
export const SHOW_REMIT_HOPE_TRANSACTION = 'SHOW_REMIT_HOPE_TRANSACTION';
export const SHOW_UPLOADED_FILE = 'SHOW_UPLOADED_FILE';
