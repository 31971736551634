import React from "react";
import Log from "../utils/log.js";
import {
  Input,
  Icon,
  Form,
  Layout,
  message,
  Dropdown,
  Menu,
  Button,
  Checkbox,
} from "antd";
import {
  requestLogin,
  requestSendingCountries,
  receiveUserDetails,
  requestAirtimeDataSendingCountries,
  requestOTPRequestForLogin,
  requestLoginUsingOTP,
} from "../actions";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Recaptcha from "react-recaptcha";
import ManageUrls from "../endpoints/manage_urls.js";
import baseUrlOptions from "../endpoints/base_url_options.js";
import RememberMe from "../endpoints/remember_me.js"
import config from "../env-config.json";

const { Content } = Layout;
const FormItem = Form.Item;
const TAG = "SmartLogin";
const SITE_KEY = config.google_recaptcha;

class Login extends React.Component {
  render() {
    return (
      <Layout className="log-layout" style={{ minWidth: "400px" }}>
        <Content className="login-content-container">
          <WrappedLoginForm {...this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      message.error("" + this.props.userReducer.error, 5);
    }

    if (
      this.props.userReducer.success !== null &&
      prevProps.userReducer.success !== this.props.userReducer.success
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set success=" + this.props.userReducer.success
      );
      message.success("" + this.props.userReducer.success, 5);
    }

    if (
      this.props.userReducer.loginMessage !== null &&
      prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage
    ) {
      message.error("" + this.props.userReducer.loginMessage, 5);
    }
  }

  componentWillMount() {
    this.props.receiveUserDetails({});
  }
}

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    var message = "";

    if (props.location != null) {
      if (props.location.state != null) {
        message = props.location.state.message;
      }
    }

    this.state = {
      sendingCountry: 0,
      pType: "password",
      pTypeIcon: "eye",
      password: "",
      phone: "",
      message: message,
      recaptchaResponse: "",
      usernameIsPhoneNumber: true,
      loginType: "password",
      otp: "",
    };
  }

  manageUrls = new ManageUrls()

  baseCountryIsSA = () => this.manageUrls.baseCountryIsSA()

  componentDidUpdate(prevProps) {
    if (
      this.props.userReducer.error !== null &&
      prevProps.userReducer.error !== this.props.userReducer.error
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- set error=" + this.props.userReducer.error
      );
      this.props.onLoginError();
    }

    if (
      Object.keys(this.props.userReducer.user).length > 0 &&
      prevProps.userReducer.user !== this.props.userReducer.user
    ) {
      Log.d(
        TAG,
        "componentDidUpdate() -- prev=" +
          JSON.stringify(prevProps.userReducer.user)
      );
      Log.d(
        TAG,
        "componentDidUpdate() -- current=" +
          JSON.stringify(this.props.userReducer.user)
      );
      Log.d(
        TAG,
        "componentDidUpdate() -- set user=" +
          JSON.stringify(this.props.userReducer.user)
      );
      Log.d(
        TAG,
        "componentDidUpdate() -- password_reset_needed=" +
          this.props.userReducer.user.data.attributes.password_reset_needed
      );

      if (this.props.userReducer.user.data.attributes.password_reset_needed) {
        Log.d(TAG, "componentDidUpdate() -- reset password");
        message.warning(
          "Please reset your password after your uploaded selfie has been approved.",
          5
        );
        this.props.history.push({
          pathname: "/update-profile"
        });
      } else if (!this.props.userReducer.user.data.attributes.phone_verified) {
        message.warning(
          // "Please verify your phone if you already have uploaded a selfie.",
          "Please verify your phone.",
          5
        );
        Log.d(TAG, "componentDidUpdate() -- verify phone");
        this.props.history.push({
          pathname: "/update-profile",
          state: {
            defaultActiveKey: "7"
          }
        });
      } else if (this.baseCountryIsSA()) {
        if (!this.props.userReducer.user.data.attributes.user_sub_status && !this.props.userReducer.user.data.attributes.product_change_request ) {
          message.warning(
            "Please Select Sending Limit",
            5
          );
          Log.d(TAG, "componentDidUpdate() -- select a sending limit");
          this.props.history.push({
            pathname: "/update-profile",
            state: {
              defaultActiveKey: "9"
            }
          });
        } else {
          Log.d(TAG, "componentDidUpdate() -- just login");
          this.props.onLogin();
        }
      } else {
        Log.d(TAG, "componentDidUpdate() -- just login");
        this.props.onLogin();
      }
    }

    if (
      prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage
    ) {
      this.props.onLoginError();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (
        !err &&
        Object.keys(this.props.countryReducer.sendingCountries).length > 0
      ) {
        const sendingCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code
        const sendingCountry = this.props.countryReducer.sendingCountries.find(country => country.attributes.alpha_2_code === sendingCountryAlphaCode)

        if(this.state.loginType === "otp") {
          const prefix = this.props.countryReducer.sendingCountries[
            this.state.sendingCountry
          ].attributes.phone_prefix;

          const payload = {
            username: `${prefix} ${this.state.phone}`,
            otp: this.state.otp,
            remember_me: RememberMe.value,
          }

          this.props.requestLoginUsingOTP(payload, sendingCountry);
        } else {
          Log.d(
            TAG,
            "handleSubmit() --  recaptchaResponse=" +
              this.state.recaptchaResponse.trim()
          );
          if (this.state.recaptchaResponse.trim().length > 0) {
            Log.d(TAG, "Received values of form: " + JSON.stringify(values));
            if (values.username.trim() && values.password.trim()) {
              var payload = {
                username:
                  this.state.usernameIsPhoneNumber ? 
                    this.props.countryReducer.sendingCountries[
                      this.state.sendingCountry
                    ].attributes.phone_prefix +
                    " " +
                    values.username : 
                  values.username,
                password: values.password,
                remember_me: RememberMe.value,
              };
  
              
              Log.d(TAG, "handleSubmit() -- payload=" + JSON.stringify(payload));
              this.requestLogin(payload, sendingCountry);
            } else {
              message.error("Fields should not be blank.", 5);
            }
          } else {
            message.error(
              "Captcha checking failed. Please check the captcha checkbox.",
              5
            );
          }

        }
      }
    });
  };

  requestLogin(payload, sendingCountry) {
    Log.d(TAG, "requestLogin() -- props=" + JSON.stringify(this.props));
    this.props.requestLogin(
      this.props.cookies,
      payload,
      true,
      "Welcome back",
      this.state.recaptchaResponse,
      sendingCountry,
    );
  }

  validatePhone = (rule, value, callback) => {
    Log.d(TAG, "validatePhone() -- called ..");
    if (value) {
      if (value.length >= 9 || !this.state.usernameIsPhoneNumber) {
        if (isNaN(value) && this.state.usernameIsPhoneNumber) {
          callback("Mobile number should contain valid numbers");
        } else {
          callback();
        }
      } else {
        if(this.state.usernameIsPhoneNumber){
          callback("Mobile number should have at least 9 digits");
        }
      }
    }
  };

  PasswordLogin = (getFieldDecorator, phonePrefix, loginField) => <>
        <Form onSubmit={this.handleSubmit} className="log-body">
          <h3 className="log-line">{`Log in using your registered ${loginField}`}</h3>
          <span className="log-sub-line">{this.state.message}</span>

          <FormItem style={{ width: "100%" }}>
            {getFieldDecorator("sending_country", {
              rules: [{ required: true, message: "Please provide your country" }]
            })(
              <Dropdown
                style={{ height: "46px" }}
                overlay={this.renderSendingCountriesParent()}
                trigger={["click"]}
              >
                <div className="dropdown-item">
                  {this.renderSendingCountriesDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>

          <FormItem>
            {getFieldDecorator(
              "username",
              {
                rules: [
                  { required: true, message: `Please put your ${this.state.usernameIsPhoneNumber ? "phone number" : "email address"}` },
                  { 
                    required: true, 
                    type: "string", 
                    pattern: this.state.usernameIsPhoneNumber ? /^\d+$/ : /\S+@\S+\.+[a-zA-Z]{2,}$/,
                    message: `Please enter valid ${this.state.usernameIsPhoneNumber ? "phone number" : "email address"}` 
                  },
                  { validator: this.validatePhone }
                ]
              },
              { initialValue: this.state.phone }
            )(
              <Input
                style={{height: 46}}
                className="mobile-number"
                addonBefore={this.state.usernameIsPhoneNumber ? phonePrefix : null}
                placeholder={this.state.usernameIsPhoneNumber ? "Phone Number": "Email Address"}
                onChange={this.onMobileChanged}
                maxlength={this.state.usernameIsPhoneNumber && "11"}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator(
              "password",
              {
                rules: [{ required: true, message: "Please put your password" }]
              },
              { initialValue: this.state.password }
            )(
              <Input
                className="form-control"
                type={this.state.pType}
                placeholder="Password"
                height="46px"
                onChange={this.onPasswordChanged}
              />
            )}
          </FormItem>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FormItem style={{ display: "flex", justifyContent: "start" }}>
              {getFieldDecorator('remember_me', {
                  rules: [{ required: false, message: 'Please tick this checkbox' }],
                })(
                <Checkbox
                  defaultChecked={RememberMe.value}
                  onChange={(value) => { RememberMe.setValue(value.target.checked) }}
                >Remember me</Checkbox>
              )}
            </FormItem>
            {this.state.usernameIsPhoneNumber && (
              <div className="login-form-forgot">
                <Button
                  className="transaparent-button"
                  onClick={this.signInUsingOTP}
                >
                  Login using OTP
                </Button>
              </div>
            )}
          </div>
          <FormItem style={{ display: "flex", justifyContent: "center" }}>
            <Recaptcha
              sitekey={SITE_KEY}
              render="explicit"
              verifyCallback={this.onVerifyCallback}
              onloadCallback={this.onLoadCallback}
            />
          </FormItem>
          <div className="log-rmb-forgot-container">
            <div className="login-form-forgot">
              <Button
                className="transaparent-button"
                onClick={this.loadForgetPassword}
              >
                Forgot password
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            <FormItem className="form-group">
              <Button
                className="home-collect"
                style={{
                  backgroundColor: "#154989",
                  boxSizing: "border-box",
                  color: "white",
                  margin: "8px"
                }}
                type="primary"
                htmlType="submit"
              >
                Log In
              </Button>
            </FormItem>
            <div className="form-group">
              <Button
                className="home-collect"
                style={{
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  color: "#154989",
                  margin: "8px"
                }}
                onClick={() => {
                  const path = this.baseCountryIsSA() ? "/welcome-registration" : "/sign-up"
                  this.props.history.push({
                    pathname: path
                  });
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </Form>
      </>

  OTPLogin = (getFieldDecorator, phonePrefix, loginField) => <>
        <Form onSubmit={this.handleSubmit} className="log-body">
          <h3 className="log-line">{`Log in using your registered ${loginField}`}</h3>
          <span className="log-sub-line">{this.state.message}</span>

          <FormItem style={{ width: "100%" }}>
            {getFieldDecorator("sending_country", {
              rules: [{ required: true, message: "Please provide your country" }]
            })(
              <Dropdown
                style={{ height: "46px" }}
                overlay={this.renderSendingCountriesParent()}
                trigger={["click"]}
                disabled
              >
                <div className="dropdown-item">
                  {this.renderSendingCountriesDropdown()}
                  <Icon type="down" />
                </div>
              </Dropdown>
            )}
          </FormItem>

          <FormItem>
            {getFieldDecorator(
              "username",
              {
                rules: [
                  { required: true, message: `Please put your ${this.state.usernameIsPhoneNumber ? "phone number" : "email address"}` },
                  { 
                    required: true, 
                    type: "string", 
                    pattern: this.state.usernameIsPhoneNumber ? /^\d+$/ : /\S+@\S+\.+[a-zA-Z]{2,}$/,
                    message: `Please enter valid ${this.state.usernameIsPhoneNumber ? "phone number" : "email address"}` 
                  },
                  { validator: this.validatePhone }
                ]
              },
              { initialValue: this.state.phone }
            )(
              <Input
                style={{height: 46}}
                className="mobile-number"
                addonBefore={this.state.usernameIsPhoneNumber ? phonePrefix : null}
                placeholder={this.state.usernameIsPhoneNumber ? "Phone Number": "Email Address"}
                onChange={this.onMobileChanged}
                maxlength={this.state.usernameIsPhoneNumber && "11"}
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator(
              "otp",
              {
                rules: [{ required: true, message: "Please put your OTP" }]
              },
              { initialValue: this.state.otp }
            )(
              <Input
                className="form-control"
                type={this.state.pType}
                placeholder="One Time Password"
                height="46px"
                onChange={this.onOTPChanged}
              />
            )}
          </FormItem>
          <div style={{display: "flex", flexDirection: "row"}}>
            <FormItem style={{ display: "flex", justifyContent: "start" }}>
              {getFieldDecorator('remember_me', {
                  rules: [{ required: false, message: 'Please tick this checkbox' }],
                })(
                <Checkbox
                  defaultChecked={RememberMe.value}
                  onChange={(value) => { RememberMe.setValue(value.target.checked) }}
                >Remember me</Checkbox>
              )}
            </FormItem>
            <div className="login-form-forgot">
              <Button
                className="transaparent-button"
                onClick={this.signInUsingPassword}
              >
                Login using Password
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap"
            }}
          >
            <FormItem className="form-group">
              <Button
                className="home-collect"
                style={{
                  backgroundColor: "#154989",
                  boxSizing: "border-box",
                  color: "white",
                  margin: "8px"
                }}
                type="primary"
                htmlType="submit"
              >
                Log In
              </Button>
            </FormItem>
            <div className="form-group">
              <Button
                className="home-collect"
                style={{
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  color: "#154989",
                  margin: "8px"
                }}
                onClick={() => {
                  const prefix = this.props.countryReducer.sendingCountries[
                    this.state.sendingCountry
                  ].attributes.phone_prefix;

                  const payload = {
                    username: `${prefix} ${this.state.phone}`
                  }

                  this.props.requestOTPRequestForLogin(payload);
                }}
              >
                Request OTP
              </Button>
            </div>
          </div>
        </Form>
      </>

  render() {
    const { getFieldDecorator } = this.props.form;

    var phonePrefix = getFieldDecorator("phonePrefix", {
      initialValue: ""
    })(this.renderPhonePrefix());

    /*var passIcon = <div style={{ width: 45 }}><Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} /></div>;
    var showPasswordIcon  =  (<a onClick={this.onPasswordTypeChanged}>
          <Icon type={this.state.pTypeIcon} style={{ fontSize: 13 }}/>
      </a>);
    */

    const loginField = this.baseCountryIsSA() ? "mobile number" : "email address";

    if(this.state.loginType === "password") {
      return this.PasswordLogin(getFieldDecorator, phonePrefix, loginField)
    } else if(this.state.loginType === "otp") {
      return this.OTPLogin(getFieldDecorator, phonePrefix, loginField)
    } else {
      return this.PasswordLogin(getFieldDecorator, phonePrefix, loginField)
    }
  }

  onLoadCallback = () => {};

  onVerifyCallback = response => {
    if (response.trim().length > 0) {
      this.setState({
        recaptchaResponse: response
      });
    }
  };

  loadForgetPassword = () => {
    this.props.history.push({
      pathname: "/forget-password"
    });
  };

  signInUsingOTP = () => {
    this.setState({
      loginType: "otp",
    });
  }

  signInUsingPassword = () => {
    this.setState({
      loginType: "password",
    });
  }

  onMobileChanged = (event, newValue) => {
    this.setState({ phone: event.target.value });
  };

  onPasswordTypeChanged = e => {
    Log.d(TAG, "onPasswordTypeChanged() -- called");
    Log.d(TAG, "onPasswordTypeChanged() -- pType=" + this.state.pType);
    var pType = "text";
    var pTypeIcon = "eye-o";
    if (this.state.pType === "text") {
      pType = "password";
      pTypeIcon = "eye";
    }
    this.setState({ pType, pTypeIcon });
  };

  onPasswordChanged = event => {
    this.setState({ password: event.target.value });
  };
  
  onOTPChanged = event => {
    this.setState({ otp: event.target.value });
  };

  componentDidMount() {
    Log.d(TAG, "componentDidMount() -- state=" + JSON.stringify(this.state));
    this.manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA);
    this.checkIfHasUser();
  }

  renderPhonePrefix() {
    if (Object.keys(this.props.countryReducer.sendingCountries).length > 0) {
      return (
        <div style={{ width: 45 }}>
          +
          {
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ].attributes.phone_prefix
          }
        </div>
      );
    } else {
      return <div />;
    }
  }

  checkIfHasUser() {
    Log.d(TAG, "checkIfHasUser() -- props=" + JSON.stringify(this.props));
    this.props.form.setFieldsValue({
      sending_country: this.state.sendingCountry
    });
    this.requestSendingCountry();

    if (!!this.props.authReducer.accessToken) {
      // if (this.props.cookies.cookies.access) {
      if (!!this.props.authReducer.accessToken) {
        Log.d(TAG, "checkIfHasUser() -- has user");
        this.props.history.push({
          pathname: "/"
        });
      }
    }
  }

  requestSendingCountry() {
    Log.d(TAG, "requestSendingCountries() -- called ..");
    this.props.requestSendingCountries();
  }

  renderSendingCountriesDropdown() {
    if (Object.keys(this.props.countryReducer.sendingCountries).length > 0) {
      return (
        <div>
          {
            this.props.countryReducer.sendingCountries[
              this.state.sendingCountry
            ].attributes.name
          }
        </div>
      );
    }
    return <div>Not Available</div>;
  }

  renderSendingCountriesParent() {
    return (
      <Menu
        style={{maxHeight: "30vh", overflow: "scroll"}} 
        onClick={this.onChangeSendingCountry}>
          {this.rendeSendingCountries()}
      </Menu>
    );
  }

  rendeSendingCountries() {

    return this.props.countryReducer.sendingCountries.map((itemAr, index) => {
      return <Menu.Item key={index}>{itemAr.attributes.name}</Menu.Item>;
    });
  }

  updateBaseCountryLocalStorage = () => {
    const manageUrls = new ManageUrls()

    const baseCountryAlphaCode = this.props.countryReducer.sendingCountries[this.state.sendingCountry].attributes.alpha_2_code
    
    if(baseCountryAlphaCode === baseUrlOptions.SOUTH_AFRICA) {
      this.setState({ usernameIsPhoneNumber: true })
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA)
    } else {
      this.setState({ usernameIsPhoneNumber: false })
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM)
    }
  }

  updateSendingCountryAndBaseCountry = () => {
    this.updateBaseCountryLocalStorage()
  }

  onChangeSendingCountry = event => {
    this.setState({
      sendingCountry: event.key
    }, this.updateSendingCountryAndBaseCountry);

    this.props.form.setFieldsValue({
      sending_country: this.state.sendingCountry
    });
  };
}

const WrappedLoginForm = Form.create()(LoginForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestLogin,
      requestSendingCountries,
      receiveUserDetails,
      requestAirtimeDataSendingCountries,
      requestOTPRequestForLogin,
      requestLoginUsingOTP,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
