import React from 'react';
import { Layout, Alert } from 'antd';
import CustomHeader from './header.js';
import CustomFooter from './footer.js';
import '../styles/user.css'
import Log from '../utils/log.js';
import { withCookies } from 'react-cookie';

const { Content, Header, Footer } = Layout;
const TAG = "NoMatch";

class NoMatch extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props));
    const { cookies } = this.props;

    this.state = {
      userID: cookies.get('user_id') || '',
    }
  }

  componentDidMount() {
    document.title = "Page not found"
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header">
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="user-content-container-2">
          <img className="user-no-match-img" src="http://cdn.ventmere.com/lifa-dev/images/index/industrial/missing.png" alt="error"/>
          <Alert
            className="user-alert"
            message="Failed"
            description="Page not found"
            type="error"/>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }
}


export default withCookies(NoMatch)
