import React from "react";
import { Layout } from "antd";
import CustomHeader from "../header.js";
import CustomFooter from "../footer.js";

const { Content, Header, Footer } = Layout;

export default class BreadWinnerTermsAndConditions extends React.Component {
  render() {
    return (
      <Layout>
        <Header
          className="custom-header"
          style={{ position: "fixed", width: "100%", zIndex: "1" }}
        >
          <CustomHeader {...this.props} />
        </Header>
        <div className="terms-top-bg">
          <div className="misc-page-overlay">
            <h5
              className="misc-top-title"
              style={{ textTransform: "capitalize" }}
            >
              Breadwinner Provider
            </h5>
            <h5 style={{ color: "white" }}>Benefit Section</h5>
          </div>
        </div>
        <Content className="terms-body">
          <div id="parent">
            <h4 className="bread-winner-h4">A. Provider benefits</h4>
            <ul>
              <li style={{ listStyleType: "decimal" }}>
                <h5>What the beneficiary get from these benefits</h5>
                <p>
                  This policy provides the <em>beneficary</em> cash to help them
                  pay for ongoing living expenses when the
                  <em>insured person </em>dies. The amount we will pay when we
                  receive a valid claim is determined by the amount that you
                  remit on a monthly basis on the EcoCash app. These payments
                  are according to the rules set out in this document and your
                  policy general terms and conditions document.
                </p>
                <ul style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    <strong>Benefits that are covered under this policy</strong>
                    <ul className="dash">
                      <li>
                        <strong>Monthly Provider ( Insured ) benefit</strong>
                        <p style={{ paddingLeft: "1em" }}>
                          We will pay the benefit amount each month for a period
                          of three (3) months if the <em>insured person</em>,
                          who we have listed on your policy schedule, dies
                          during the <em>period of insurance</em>. The benefit
                          amount is equal to the average
                          <em>remittance value</em> in the 3 months before the
                          death of the
                          <em>insured person.</em>
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Who your policy covers for these benefits</strong>
                    <ul className="dash">
                      <li>
                        The <em>insured person </em>is covered under the Monthly
                        Provider (Insured) benefit.
                      </li>
                      <li>
                        The <em>insured person</em> must be 18 years or older,
                        but under the age of 66 years as at the
                        <em>benefit start date</em>.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <h4 className="bread-winner-h4">B. Claiming under this benefit</h4>
            <strong>
              There are many more rules about claiming for your benefits on this
              policy than we have noted here.
            </strong>
            These rules are listed in the section Claiming under this policy in
            the policy General terms and conditions. You must read this section
            together with that one.
            <ul style={{ listStyleType: "decimal" }}>
              <li>
                <h5>Who we will pay the benefit amount to</h5>
                <p>
                  If we assess a claim as valid, we will pay any benefits that
                  must be paid to the beneficiary:
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>Reason for claim</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>Who we will pay</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          Death of the <em>insured person</em>
                        </p>
                      </td>
                      <td>
                        <p>
                          The <em>beneficiary </em>that you have selected on the
                          EcoCash application and that we have listed on the
                          policy schedule
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                <h5> When we will pay the benefit amount</h5>
                <p>
                  We will pay the benefit after we have received all the
                  information the claimant have to send us and we have assessed
                  a claim as valid. See the section Claiming under this policy
                  in the policy General terms and conditions to understand what
                  we mean by assessing &ldquo;a claim as valid&rdquo;.&nbsp;
                </p>
              </li>
            </ul>
            <h4 className="bread-winner-h4">
              C. List of defined terms used to explain this benefit
            </h4>
            <p>
              <br /> In this document you see we have certain words in
              <em>italics (i.e. slanted text like this</em>). Whenever you see a
              word in italics, it means we have given a specific meaning to that
              word and would like you to understand exactly what it means.
            </p>
            <p>
              To see the full list of these words, please see the List of
              defined terms document.
            </p>
            <h4 className="bread-winner-h4">List of Defined Terms</h4>
            <p>
              We have given a specific meaning to certain words. These words
              appear in <em>italics</em>
              throughout your policy documents, and have the same meaning each
              time we use these words.
            </p>
            <p>
              It is
              <strong>extremely important that you understand the </strong>
              <strong>definitions</strong> that we have given for these
              words.&nbsp;
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <em>Accident</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means an unfortunate incident that happens
                      unexpectedly and unintentionally, resulting in damage or
                      injury.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Accidental Death</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means death as a result of an unfortunate incident
                      that happens unexpectedly and unintentionally.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Appointed Executor</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means a person who has been appointed by the court to
                      claim on the policyholders or beneficiaries behalf.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Beneficiary</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      A person or entity that you choose to receive the benefits
                      on the death of the
                      <em>main insured person</em>. When you inform us of your
                      choice, you are nominating a beneficiary.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Benefit premium</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      The monthly cost of the selected benefits on this policy
                      for the <em>insured person</em>.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Benefit start date</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This is the date when an <em>insured person&rsquo;s</em>
                      cover for a specific benefit begins under this policy, as
                      set out in the policy schedule.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Certified copy</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      A&nbsp;certified copy&nbsp;is a&nbsp;photocopy&nbsp;of an
                      original document that has been rubber stamped and signed
                      by a Commissioner of Oaths to say that
                      the&nbsp;photocopy&nbsp;is an exact&nbsp;copy&nbsp;of the
                      original and has not been changed in any way.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Claimant </em>
                    </p>
                  </td>
                  <td>
                    <p>The person who has told us about a claim.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Commissioner of oaths</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      A Commissioner for Oaths is a person who is authorised to
                      verify affidavits, which are statements in writing and on
                      oath, and other legal documents.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Eligible</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means having the necessary qualities or satisfying
                      the necessary conditions.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Entity</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means an individual, company, or organization that
                      has legal rights and obligations.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Estate</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      This means the policies, assets and money that we leave
                      behind when we pass away.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Insured event</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Is something that happens to an <em>insured person</em>
                      under this policy that results in us paying out a claim.
                      The <em>insured event</em> is set out in each benefit
                      section.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Insured person</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Refers to the person named in the policy schedule who we
                      accepted as the <em>insured person.</em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Material information</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Information that affects our decision to cover an
                      <em>insured person</em> on the terms and conditions in
                      this policy.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Period of insurance</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Means the period between the <em>policy start date</em> or
                      the <em>benefit start date</em>
                      and the date when the policy ends, provided we receive
                      your monthly <em>total premium</em>.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Policyholder </em>
                    </p>
                  </td>
                  <td>
                    <p>
                      Refers to you, the owner of the policy named in the policy
                      schedule.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Policy month</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      The period between anniversary days. Your anniversary day
                      is the date of the month that the first premium was paid.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Policy review date</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      The month on which we will review your policy every year,
                      as set out in the policy schedule. This is the date on
                      which we:
                    </p>
                    <p>
                      - will automatically increase the <em>Benefit premium</em>
                      (if applicable);
                    </p>
                    <p>
                      - may increase the <em>Policy fee</em>; and
                    </p>
                    <p>- may make changes to the policy conditions.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Policy start date</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      The date cover for the <em>main insured person</em> begins
                      under this policy. The date is set out in the policy
                      schedule.
                    </p>
                    <p>
                      The <em>policy start date</em> will be the later of:
                    </p>
                    <p>
                      - the first of the month during which we received the
                      first <em>total premium</em>; and
                    </p>
                    <p>- the date that we agree to restart this policy.</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Pre-existing condition</em>
                    </p>
                  </td>
                  <td>
                    <p>
                      A medical condition, illness, bodily injury or disability
                      that:
                    </p>
                    <p>
                      - existed at any time before the
                      <em>insured person&rsquo;s</em>
                      <em>benefit start date</em>; and
                    </p>
                    <p>
                      - the <em>insured person</em> knew about, or reasonably
                      knew about it or experienced symptoms of.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Premium payer</em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Remittance value</em>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      <em>Total premium </em>
                    </p>
                  </td>
                  <td>
                    <p>
                      The <em>total premium on your behalf </em>is the total of
                      the monthly
                      <em>benefit premiums, which is guaranteed by Sasai</em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <h4 className="bread-winner-h4">D. Introducing your policy</h4>
            <h6 className="bread-winner-h6">Welcome to Hollard Life</h6>
            <p>
              Congratulations on choosing the
              <strong>Breadwinner Provider </strong>policy. We are committed to
              giving you world class service. Hollard Life aims to ensure that
              all our communication with you is honest and easy to understand.
            </p>
            <p>
              Let this be the start of a long, successful relationship
              together.&nbsp;
            </p>
            <h6 className="bread-winner-h6">
              Additional information document &ndash; let&rsquo;s keep in
              contact
            </h6>
            <p>
              To keep our channels of communication open, we have provided a
              separate document called the Additional information document. Here
              you will find our contact details and guidance on who to contact
              when, along with disclosures and other important
              information.&nbsp;
            </p>
            <p>
              Please keep this handy to be sure you always have all the
              information you need.
            </p>
            <p>
              We will send you an updated Additional information document if any
              details change. Likewise, please would you also send us your
              updated details if any telephone numbers, email addresses or any
              other relevant information change on your side.
            </p>
            <h6 className="bread-winner-h6">
              We have entered into a legal contract
            </h6>
            <p>
              We are legally bound to do what we say we will do, to pay you the
              benefits as we have promised, as long as you do what is required
              of you. This is fair.
            </p>
            <p>
              Our legal contract has five (5) parts, which we explain below:
            </p>
            <ul>
              <li>The record of your original activation for this policy</li>
              <li>The policy schedule</li>
              <li>The policy benefits</li>
              <li>The policy terms and conditions</li>
              <li>The policy list of defined terms</li>
              <li>
                <strong>The record of your original application</strong>:
                <ul className="dash">
                  <li>
                    You applied for this policy by completing the online
                    activation process on the EcoCash app. We have a record of
                    this activation which forms part of our legal contract.
                  </li>
                  <li>
                    This activation includes details such as your contact
                    information; the size of your benefits; and the premium rate
                    used to determine the <em>total premiums </em>that will be
                    paid by the <em>premium payer</em>.
                  </li>
                  <li>
                    Your original activation includes all the information that
                    we gave you and you gave us during the application process.
                    You can update the information on the EcoCash app when you
                    need to, which will also then form part of our legal
                    contract.
                  </li>
                </ul>
              </li>
              <li>
                <strong>The policy schedule</strong>:
                <ul className="dash">
                  <li>
                    We provide a summary of details, based on your original
                    activation, as a confirmation of our agreement.
                  </li>
                  <li>
                    For example, this includes your (the <em>policyholder</em>
                    &rsquo;s) personal and contact details; the size of your
                    benefits; the premium rate used to determine the
                    <em>total premiums</em>
                    that will be paid by the <em>premium payer</em>, plus other
                    information that is not included in the other two policy
                    documents.
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  The policy terms and conditions and the policy benefits:
                </strong>
                <ul className="dash">
                  <li>
                    The policy terms and conditions sets out the <u>general </u>
                    terms and conditions of this policy. It includes the rules
                    and circumstances relevant to us paying out your benefits
                    and how to claim correctly so you can receive your benefits.
                    It also tells you what this insurance policy will not pay
                    for.
                  </li>
                  <li>
                    The policy benefits section sets out the terms and
                    conditions <u>specific</u> to the benefit. This explains,
                    for example, what benefits you get and when you need to wait
                    before you can get benefits.
                  </li>
                  <li>
                    The policy list of defined terms sets out the definitions of
                    certain words. Whenever you see a word in
                    <em>italics (i.e. slanted letters like this)</em>, it means
                    we have given a specific meaning to that word and would like
                    you to understand exactly what it means.
                  </li>
                </ul>
              </li>
            </ul>
            <h6 className="bread-winner-h6">
              Changes must be agreed in writing before they can be confirmed as
              real changes
            </h6>
            <p>
              Because we have a legal contract, we are not bound by any changes
              unless we have agreed to them in writing and have included them
              into this policy by giving you a new policy schedule or new policy
              benefits or terms and conditions. When this happens, the new
              documents replace all previous EcoCash Breadwinner Provider
              Product policy documents. The latest policy documents will always
              be the final determining factor if we ever have any disagreement
              around meaning or interpretation.
            </p>
            <p>
              We urge you, for your own protection, to please take the time to
              <strong>
                carefully read these documents together &ndash; they relate to
                each other.
              </strong>
              The better you understand what you are and aren&rsquo;t covered
              for, as well as your responsibilities, the better we will be able
              to meet your expectations.&nbsp;
            </p>
            <h6 className="bread-winner-h6">
              We are in this together - the parties to this policy
            </h6>
            <p>
              The policy is a legal contract between you and Hollard Life. We
              both have rights and obligations under this policy. In the policy
              documents we refer to both parties as follows:
            </p>
            <ul>
              <li>
                We&rsquo;, &lsquo;us&rsquo; and &lsquo;our&rsquo; refer to
                Hollard Life Assurance Company Limited (Hollard Life),
                registration number 1993/001405/06. Hollard Life is a registered
                insurance company (Long-term Insurance Act 1998) and an
                authorised Financial Services Provider (Financial Advisory and
                Intermediary Services Act 2002).
              </li>
              <li>
                &lsquo;You&rsquo; and &lsquo;your&rsquo; refer to the
                <em>policyholder</em> named in the policy schedule who is the
                owner of this policy and who is also the
                <em>main insured person</em>. Only you, as the
                <em>policyholder</em> have the <em>policyholder</em> rights
                &ndash; you cannot transfer them to anyone else.
              </li>
            </ul>
            <h6 className="bread-winner-h6">
              To get the best out of this policy, take the time to understand it
            </h6>
            <p>
              This policy is here to protect you. In order for you to get the
              best out of it and avoid disappointment, it is extremely important
              that you <strong>read your policy documents</strong>. Please be
              sure to understand them well. If you aren&rsquo;t sure about
              anything, please call us. Please ensure that you follow any
              instructions in these documents. Failing to do this may result in
              us not being able to pay your claims or having to cancel your
              policy.
            </p>
            <p>
              Please <strong>check your policy schedule</strong>.
              <strong>
                If there are any errors or out-of-date information on your
                policy schedule, immediately update your information on the
                EcoCash app.
              </strong>
              You&rsquo;ll find our contact details in the Additional
              Information document.
            </p>
            <h6 className="bread-winner-h6">
              Some wording explained to help you understand
            </h6>
            <p>
              It is our intention that you have a strong understanding of this
              policy &ndash;
              <strong>
                we want you to know exactly what you are covered for.
              </strong>
              When reading these policy documents note that:
            </p>
            <ul>
              <li>
                We provide definitions to help you to clearly understand the
                words we use &ndash; see the list of defined terms document. The
                meaning of these words is the same in all your policy documents.
              </li>
              <li>
                When a sentence includes a word that has been defined (or plural
                of that word), we put it in<em>italics</em> for you. When you
                see a word in <em>italics</em>, you know you can look in the
                list to see the exact meaning we have given to this word.
              </li>
              <li>
                The most common definitions that we use in this policy are:
              </li>
              <li>
                <em>&lsquo;policyholder&rsquo;</em> refers to you, the owner of
                the policy, the person named in the policy schedule as the
                <em>policyholder</em>. The <em>policyholder</em> and
                <em>insured person</em> is the same person.
              </li>
              <li>
                <em>&lsquo;premium payer&rsquo; </em>refers to Sasai, who have
                agreed to pay the <em>total premiums on your behalf</em>.
              </li>
              <li>
                <em>&lsquo;insured person&rsquo; </em>refers to the person named
                in the policy schedule as the policyholder, who we agreed is the
                <em>insured person. </em>
              </li>
              <li>
                The headings in the policy are for your ease of reference only
                and do not change the meaning of the content under each heading.
              </li>
              <li>
                Days refer to ordinary calendar days, including weekends and
                public holidays.
              </li>
              <li>
                Month means a calendar month including the first day and the
                last day.
              </li>
              <li>
                You will see that we have used words like &ldquo;must&rdquo; and
                other phrases that sound instructional. We use these words to
                emphasise that legally, there are things that must apply. To
                protect your interests, we want to be extremely clear what this
                legal contract expects of you.
              </li>
            </ul>
            <h4 className="bread-winner-h4">
              E. Total premium &ndash; to be paid on time every month
            </h4>
            <h5>1. The monthly total premium</h5>
            <h6>
              <strong>1.1 </strong>
              <strong>What must be paid</strong>
            </h6>
            <p>
              The word &ldquo;premium&rdquo; in the context of insurance means
              the amount you must pay on time, so you can be insured. The
              premium rate used to determine the (<em>total premiums</em>) that
              will be paid by the
              <em>premium payer</em> is shown in your policy schedule. The
              premium payment is guaranteed by the
              <em>premium payer.</em> Once you have remitted funds on the
              EcoCash app, <em>total premiums</em> are guaranteed by Sasai.
            </p>
            <h4 className="bread-winner-h4">F. Conditions for cover</h4>
            <h5>1. Who qualifies to be a policyholder?</h5>
            <p>
              When you want someone to be insured under the policy, they are
              only allowed if you can say yes to the questions below. When you
              can say yes to these questions, we say that person is &ldquo;
              <em>eligible</em>&rdquo; for cover.
            </p>
            <ol>
              <li>
                Is the person within the age limits explained in the policy
                benefit section?
              </li>
              <li>
                Do they have official permission from the South African
                authorities to live and work in the Republic of South Africa?
              </li>
              <li>
                Do they live in the Republic of South Africa (in other words,
                they do not stay in another country for more than ninety (90)
                days in a row or a total of 180 days in a year)?
              </li>
            </ol>
            <p>
              If the above three (3) conditions are in place, you are allowed to
              be insured under this policy. Check that the details on the policy
              schedule we send you are correct.
            </p>
            <h5>2. When does cover for the insured person end?</h5>
            <p>
              <strong>This is very important for you to know.</strong> Here we
              explain the events when cover ends for any of the benefits on this
              policy. However, depending on the type of benefits you have, there
              might be additional situations when cover will end. Therefore, you
              <strong>
                must read this section together with the policy benefits section
              </strong>
              .
            </p>
            <h6>
              <strong>2.1 </strong>
              <strong>When cover for the insured person ends</strong>
            </h6>
            <p>
              Cover for the <em>insured person</em> ends when any of the
              following happens:
            </p>
            <ul>
              <li>
                you are no longer <em>eligible</em> (you can no longer answer
                yes to all three questions in point 1 above);
                <ul>
                  <li>
                    you, the <em>policyholder </em>tells us to end this policy;
                  </li>
                  <li>you no longer have a Sasai account; or</li>
                  <li>
                    when you die (you, the <em>insured person).</em>
                  </li>
                  <li>when you fail to remit for six months</li>
                </ul>
              </li>
            </ul>
            <h4 className="bread-winner-h4">G. Claiming under this policy</h4>
            <h5>1. How to claim</h5>
            <p>
              If policyholder die, your beneficiary needs to tell us so that we
              can pay out the benefits due to them. The beneficiary needs to
              follow the steps below &ndash; we call this &ldquo;submitting a
              claim&rdquo;. This is how to claim:
            </p>
            <ol>
              <li>
                <strong>
                  Check the exclusions list (what this policy does not pay for)
                  first.
                </strong>
                Before submitting a claim to us, check the exclusions (both the
                general exclusions in this document and the specific benefit
                exclusions in the benefits section).
              </li>
              <li>
                <strong>
                  Contact us to find out what documents are required to submit a
                  claim.
                </strong>
                See the Additional information document for contact details.
              </li>
              <li>
                <strong>Make sure the right person submits the claim. </strong>
                Only specific people are allowed to submit a claim. It must be
                one of the following two people (1) the <em>beneficiary;</em>
                or (2) the
                <em>appointed executor</em>. We call these people the qualified
                <em>claimants</em>.
              </li>
              <li>
                <strong>
                  Tell us in writing within 180 days from the date of death.
                </strong>
                The <em>claimant</em>
                must give us your policy details and tell us you passed away.
                They must do so as soon as possible because a claim cannot be
                submitted more than 180 days after the death<em>. </em>
              </li>
              <li>
                <strong>
                  Send evidence and other documents to us on time.
                </strong>
                We will tell the <em>claimant</em>
                how soon they must send documents to us, so we can process the
                claim. These documents must be sent to us on time and in the
                format we ask for. We will not pay for these documents.
              </li>
              <li>
                <strong>
                  The claim will not be paid if it is not done properly.
                </strong>
                It is very important that the
                <em>claimant</em> sticks to all the rules above. If what the
                beneficiary wants to claim for is excluded, or we do not receive
                the information we ask for within the agreed time, the claim
                will not be successful.
              </li>
            </ol>
            <h5>
              2. We will pay the right person as noted in your policy schedule
            </h5>
            <p>
              If the claim is correct (i.e. meets all the rules above and is
              correctly due in terms of this policy) we call the claim valid.
              When we assess that a claim is valid, we pay the benefits as an
              EcoCash, Steward Bank or cash pick up remittance in Zimbabwe
              through the Sasai remittance app. We pay this to the beneficiary
              as we have noted in your policy schedule and as explained in the
              policy benefit section.
            </p>
            <h5>3. We pay interest from month 13 onwards</h5>
            <ol>
              <li>
                When a claim is valid, we try to pay the <em>benefit amount</em>
                to the right person. Sometimes we struggle to get hold of the
                right person and so the amount waits to be paid. For the first
                12 months that this amount waits, we will not pay any interest
                on the amount.
              </li>
              <li>
                From month 13 onwards, we will pay interest. We calculate this
                interest on a monthly basis at a rate of:
              </li>
            </ol>
            <ul>
              <li>
                The Standard Bank of South Africa money market interest rate
                that is applicable during the period that the benefit sits
                waiting to be paid
              </li>
              <li>less our administration fee.</li>
            </ul>
            <h5>
              4. If we do not receive claims information or cannot find the
              right person to pay&nbsp;
            </h5>
            <p>
              Please make sure the contact details on the policy are correct. It
              is your responsibility, as the
              <em>policyholder</em>, to make sure that all the contact details
              noted on the policy schedule and on your EcoCash app profile are
              correct and updated if any of them change. Any information must be
              updated on your account using the EcoCash app.
            </p>
            <p>
              We need all the contact details on your policy to be up to date so
              that we can speak to the right person to send us claims
              documentation and so we can pay the benefits to the right person
              when we need to.
            </p>
            <p>
              If we cannot find the right person within six months of the date
              that a benefit would have been paid for a valid claim, it is
              called an &ldquo;unclaimed benefit&rdquo;. We will keep the claim
              case open until we have obtained the outstanding information that
              will make it possible for us to pay the claim.
            </p>
            <p>
              We will take the following steps to find the nominated
              <em> beneficiary:</em>
            </p>
            <ul>
              <li>
                Using the contact details you provided, we will try to contact
                the nominated beneficiary, or the partner or an adult child of
                the <em>insured person </em>(as applicable) to tell them of the
                available benefit.
              </li>
              <li>
                If we cannot reach any of the people mentioned above, we will
                compare our internal database with an external database or make
                use of an external tracing company to try to find updated
                contact details. We call this a &ldquo;tracing&rdquo; process.
              </li>
              <li>
                If we still cannot reach any of the people mentioned above, even
                with the new contact details found through the tracing process,
                we will repeat the tracing process after 3 years, and again
                after 10 years.
              </li>
              <li>
                If after 10 years we are still unable to trace any of the people
                mentioned above, we will not repeat the tracing process.
              </li>
              <li>
                Every time we have to follow the tracing process, we will deduct
                the costs of doing this from the benefit amount to be paid.
                These costs include administrative, tracing and management fees.
                These fees may change over time.
              </li>
              <li>
                We will not try to trace any of the people mentioned above when
                the value of the claim is less than R1,000.00, or when the cost
                of tracing is more than the benefit.
              </li>
            </ul>
            <h5>5. If you do not agree with our claims decision</h5>
            <p>When we assess a claim, we could:</p>
            <ol>
              <li>Pay an amount; or</li>
              <li>Say that it is not valid; or</li>
              <li>Cancel this policy.</li>
            </ol>
            <p>
              We will always explain why we made one of the three decisions
              above. The <em>claimant</em>
              might disagree with us and may request that we review our
              decision. This must be done within 90 days.
            </p>
            <ol>
              <li>
                <strong>Tell us within 90 days</strong>
              </li>
            </ol>
            <p>
              We will only review our decision if the <em>claimant</em> sends us
              a written request within 90 days from the date of our letter
              explaining our decision.
            </p>
            <ol>
              <li>
                <strong>You may want to tell the Ombudsman </strong>
              </li>
            </ol>
            <p>
              Alternatively, the <em>claimant</em> may contact the Ombudsman for
              Long-term Insurance. See the Additional information document for
              contact details. The Ombudsman is an independent office appointed
              by the industry to make independent and fair decisions.
            </p>
            <p>
              <strong>
                You may want to take legal action &ndash; if so, this must begin
                within 180 days
              </strong>
            </p>
            <p>
              If the <em>claimant</em> is not satisfied with the outcome of our
              review of this decision, the <em>claimant</em>may also take legal
              action against us. This means the <em>claimant</em> must instruct
              a lawyer to give a document to the sheriff of the court, who will
              give us a document (called a summons). If the summons does not
              reach us within 180 days after the 90 days allowed for the review
              of our claims decision, then no legal action can be taken against
              us.
            </p>
            <p>
              Alternatively, the <em>claimant</em> may choose to take legal
              action against us without first requesting us to&nbsp;review our
              decision or contacting the Ombudsman. In this case, the summons
              must reach us within 270 days of the date of our letter explaining
              our claims decision. If this time limit is not met, the claimant
              no longer has any right to take legal action against us.
            </p>
            <p>
              If the <em>claimant</em> wants to contact the Ombudsman for
              assistance after starting legal action against us, then the
              claimant must first withdraw the summons against us before
              contacting the Ombudsman.
            </p>
            <h4 className="bread-winner-h4">
              H. General Exclusions &ndash; when we will not pay any benefits
            </h4>
            <p>
              Certain benefits might have specific exclusions that apply in
              addition to these general exclusions. Therefore, it is essential
              that you read the exclusions listed in the policy benefits section
              as well as this list of exclusions.&nbsp;
            </p>
            <p>
              In addition to any specific exclusions set out in each benefit
              section, we will not pay a claim for an
              <em>insured event</em> because of any of the exclusions listed
              below:&nbsp;
            </p>
            <h6>
              <strong>1. </strong>
              <strong>
                Insured person&rsquo;s involvement in crime-related activities
              </strong>
            </h6>
            <p>
              We will not pay a claim if any <em>insured event</em> is directly
              or indirectly caused by the
              <em>insured person</em> being involved in crime-related
              activities, such as:
            </p>
            <ul>
              <li>being prosecuted for committing a crime; or</li>
              <li>
                being convicted by a Court of Law for having committed a crime.
              </li>
            </ul>
            <p>
              We will delay our claims decision until the finalisation of any or
              all of the above that may be applicable. You must give us proof of
              the outcome of the investigation or the criminal trial (as
              applicable).
            </p>
            <h6>
              <strong>2. </strong>
              <strong>
                The insured person is or was not <em>eligible</em>
              </strong>
            </h6>
            <p>
              We will not pay a claim for the <em>insured person</em> if they
              are not <em>eligible</em> or are no longer
              <em>eligible</em>. See the section about who is allowed to be
              insured under this policy to see the three questions to ask about
              an<em> insured person</em> to see if they are <em>eligible</em> or
              not.
            </p>
            <p>
              If we find that the <em>insured person</em> was not
              <em>eligible</em> right from the date that their benefit started,
              we will not pay the claim. We will refund the
              <em>premium payer </em>the portion of the <em>total premiums</em>
              we have received since the benefit start date, less an
              administration fee.&nbsp;
            </p>
            <h6>
              <strong>
                <em>3. </em>
              </strong>
              <strong>
                Insured event or accident happens before
                <em>the benefit start date</em>
              </strong>
            </h6>
            <p>
              We will not pay a claim for the <em>insured person</em> if the
              <em>insured event</em> or an
              <em>accident</em>
              leading to the <em>insured event</em> happened before the
              <em>benefit start date</em>.
            </p>
            <h4 className="bread-winner-h4">I. Your responsibilities</h4>
            <h5>1. You must tell us as soon as any information changes</h5>
            <p>
              You must tell us when any of the following information changes:
            </p>
            <ul>
              <li>
                Information about the <em>policyholder</em>
              </li>
              <li>full name, address, and contact details;</li>
              <li>email address, cell phone number</li>
              <li>
                Information about the <em>beneficiary </em>(the person who
                receives the benefits if the<em> insured person </em>dies)
              </li>
              <li>full names, as they appear on their Zimbabwe ID</li>
              <li>surname, as it appears on their Zimbabwe ID</li>
              <li>ID Number</li>
              <li>Age</li>
              <li>Address</li>
              <li>Contact Cell Phone Number</li>
              <li>Contact Email Address</li>
            </ul>
            <p>
              Information must be updated on your account using the EcoCash app.
            </p>
            <p>
              You must send us proof of age or proof of date of birth for the
              <em>insured person</em>
              before any benefits will be paid.
            </p>
            <h5>2. You must give us true information</h5>
            <p>
              We base our decision to insure the <em>insured person</em> on the
              information you give to us. If any information that you give to us
              is incomplete or incorrect, our decision will have been based on
              incomplete or incorrect information. If we had known the complete
              and correct information, we may not have agreed to cover the
              <em>insured person</em> for the amount set out in the policy
              schedule. Incomplete information includes things that you have not
              told us but should have told us.
            </p>
            <p>
              It is your responsibility to ensure that we receive all
              <em>material information</em> (i.e. any information that may
              affect our decision to cover an <em>insured person</em>). It is
              your responsibility that this information is complete and
              correct.&nbsp;
            </p>
            <p>
              <strong>
                If you give us the incorrect information about the insured
                person&rsquo;s age
              </strong>
            </p>
            <p>
              When you send us the proof of age and we find that it is different
              to the information you first gave us we will reconsider the cover
              as follows:
            </p>
            <ul>
              <li>
                If we would not have covered the <em>insured person</em>, we
                will pay back the portion of the <em>total premiums</em> that
                have been paid so far to the <em>premium payer</em>, less an
                administration fee.
              </li>
            </ul>
            <h5>3. You must always be honest</h5>
            <p>
              All dealings about this policy must be done honestly and in good
              faith. We will not accept any responsibility under this policy if
              you or any person acting for you is dishonest or misrepresents any
              information.
            </p>
            <ol>
              <li>
                The beneficiary will lose their right to claim if we are
                prejudiced or suffer a loss because of your:
              </li>
            </ol>
            <ul>
              <li>dishonest behaviour;</li>
              <li>misrepresentation; or</li>
              <li>criminal activity.</li>
            </ul>
            <ol>
              <li>
                We will cancel your policy from the <em>policy start date</em>
                or from the date of the actions listed above. If we cancel your
                policy from the <em>policy start date</em>, we may refund the
                <em>total premium</em>s paid to the <em>premium payer,</em> less
                an administration fee.
              </li>
              <li>We will take legal steps to recover damages from you.</li>
            </ol>
            <h5>4. You must keep to the terms and conditions</h5>
            <p>
              You and your beneficiaries must keep to all the rules, terms,
              conditions and the claims process set out in this policy.
            </p>
            <h5>
              5. You cannot transfer your rights under this policy to another
              person
            </h5>
            <p>
              You may not transfer your rights or benefits under this policy to
              another person or <em>entity </em>(like a trust or fund or
              company).
            </p>
            <p>
              If you try to transfer the rights to any of the benefits in this
              policy to another person or
              <em>entity</em>, we will not recognise that contract. We will
              continue our contract with you on all of the benefits as if you
              had not made a contract with someone else. This means that your
              contract with that other person or
              <em>entity</em> is not valid.
            </p>
            <h4 className="bread-winner-h4">
              J. Nominate one or more beneficiaries
            </h4>
            <h5>1. What is a beneficiary and how do you nominate one?</h5>
            <p>
              A <em>beneficiary</em> is a person who will receive the benefits
              we pay out if you die (you, the <em>insured person</em>). You
              nominate that person through the activation process on the EcoCash
              app providing the relevant details.
            </p>
            <ul>
              <li>full names, as they appear on their Zimbabwe ID</li>
              <li>surname, as it appears on their Zimbabwe ID</li>
              <li>ID Number</li>
              <li>age</li>
              <li>address</li>
              <li>contact cell phone number</li>
              <li>contact email address</li>
              <li>
                relationship to the <em>insured person</em>
              </li>
            </ul>
            <h5>2. Rules about nominating a beneficiary</h5>
            <h6>
              <strong>2.1 </strong>
              <strong> Nominate a beneficiary </strong>
            </h6>
            <p>
              You must nominate a <em>beneficiary</em> to receive the benefits
              in the event of the death of the <em>insured person, </em>as noted
              in the policy benefits section.
            </p>
            <ul>
              <li>You may only nominate a primary and secondary</li>
              <li>
                The secondary beneficiary will only receive the benefits if the
                benefit payment on the EcoCash app to the primary beneficiary is
                unsuccessful.
              </li>
              <li>
                You may change or cancel the nomination at any time, but you
                must inform us by updating the information on your account using
                the EcoCash app in order for the change or cancellation to be
                made.
              </li>
              <li>
                A <em>beneficiary</em> will have no interests or rights in the
                policy during the lifetime of the
                <em>policyholder</em>.
              </li>
              <li>
                Nominations in a will or any other testamentary instrument that
                the
                <em>policyholder</em> agreed to, shall not affect any existing
                <em>beneficiary</em> nomination that we have recorded.
              </li>
            </ul>
            <h6>
              <strong>2.2 </strong>
              <strong>Who we pay if there is no beneficiary</strong>
            </h6>
            <ol>
              <li>
                If there is no <em>beneficiary</em> it means that:
              </li>
              <li>
                the <em>beneficiary</em> is not alive when
                <em>the insured person</em> died; or
              </li>
              <li>
                the <em>beneficiary</em> cannot be located within 12 months of
                the death of the<em>insured person</em>.
              </li>
              <li>
                When we find no <em>beneficiary</em>, we will look to find the
                next best person (who will then be the correct <em>claimant</em>
                ) to receive the benefits that we would have paid to the
                <em>beneficiary</em>. We will pay:
              </li>
              <li>
                The partner of the <em>insured person</em>, or if there is no
                partner, then
              </li>
              <li>
                Adult child of the <em>insured person</em>, or if there is no
                adult child, then
              </li>
              <li>
                Parent of the <em>insured person</em>; or if there is no parent,
                then
              </li>
              <li>
                The <em>estate</em> of the deceased <em>insured person</em>.
              </li>
              <li>
                The <em>claimant</em> must be over the age of 18 and must give
                us proof of their relationship to the
                <em>insured person</em>
                <em>.</em>
              </li>
              <li>
                If someone other than one of the <em>claimants</em> listed above
                contacts us to claim the <em>insured person&rsquo;s</em>
                benefit, we will pay the benefit to the
                <em>insured person&rsquo;s</em>
                <em>estate</em>.
              </li>
            </ol>
            <h4 className="bread-winner-h4">K. Changes to the policy</h4>
            <h5>1. When the policy conditions will change</h5>
            <p>
              We will tell you at least one month before we make any of the
              changes noted below. We will send you updated policy documents and
              an explanation of the changes.
            </p>
            <h6>
              <strong>1.1 </strong>
              <strong>We may make changes to the policy conditions</strong>
            </h6>
            <p>
              We may change the terms and conditions of this policy at any time
              and not just at the <em>policy review date</em>. Any change we
              make will not affect the extent of cover already in place in terms
              of this policy.
            </p>
            <p>
              If any regulatory authority introduces measures that affect this
              policy or if the law changes, we will make the necessary changes
              and tell you the reason for the changes. This could mean that we
              have to cancel a benefit.
            </p>
            <h6>
              <strong>1.2 </strong>
              <strong>You may make changes to the policy</strong>
            </h6>
            <p>
              If you ask us to make any changes to the policy and we agree to
              make the change, it will be effective from the date agreed to by
              us.
            </p>
            <h4 className="bread-winner-h4">L. Ending this policy</h4>
            <h5>1. Cancelling this policy</h5>
            <h6>
              <strong>1.1 </strong>
              <strong>We may cancel this policy at any time</strong>
            </h6>
            <p>
              We may cancel this policy by giving you thirty-one (31) days
              written notice. If this happens, we will always explain why we are
              cancelling your policy.
            </p>
            <h6>
              <strong>1.2 </strong>
              <strong>You may cancel this policy at any time</strong>
            </h6>
            <ol>
              <li>
                <strong>
                  If you cancel during a cooling off period, we may provide a
                  refund
                </strong>
              </li>
            </ol>
            <ul>
              <li>
                A cooling off period is the time within the 31 days from the
                <em>policy start date. </em>
              </li>
              <li>
                If you cancel within a cooling off period and we have not paid
                any benefit under this policy so far, we will give the
                <em>premium payer </em>a refund. This refund equals the
                <em>total premiums</em> that have been paid so far, minus the
                costs for any cover we provided for risk benefits.
              </li>
            </ul>
            <ol>
              <li>
                <strong>
                  If you cancel at any time outside of a cooling off period
                </strong>
              </li>
            </ol>
            <ul>
              <li>
                You may cancel this policy by giving us one month&rsquo;s
                written notice, if it is any date outside of a cooling off
                period as explained above.
              </li>
              <li>
                At the end of the notice period, this policy will automatically
                end.
              </li>
            </ul>
            <h6>
              <strong>1.3 </strong>
              <strong>
                We will refund<em> total premium</em>s paid after cancellation
              </strong>
            </h6>
            <p>
              If any <em>total premiums </em>are paid after the date that this
              policy ends, we will refund these <em>total premium</em>s to the
              <em>premium payer</em>.
            </p>
            <h4 className="bread-winner-h4">M. General Conditions</h4>
            <h5>1. Good faith</h5>
            <p>
              We will always act in good faith in our mutual dealings. If we
              make an administration error, it will not take away any cover you
              have, or give you any cover that you do not have.
            </p>
            <h5>2. Our liability</h5>
            <p>
              Our liability in terms of this policy is conditional on you, or
              anyone acting on your behalf, keeping to all the terms and
              conditions of this policy.
            </p>
            <p>
              Our liabilities are based on the rules of this policy, which
              include that:
            </p>
            <ul>
              <li>
                We will first assess if a claim is valid before we pay the
                relevant benefit for that claim.
              </li>
              <li>
                Once we have paid for a benefit for the <em>insured person</em>
                on this policy, we have fully and finally fulfilled our
                responsibility in terms of this policy.
              </li>
              <li>
                Our responsibility will not be more than the benefit for which
                <em>total premiums </em>have been paid in full every month.
              </li>
              <li>
                No benefit under this policy will have any investment or
                surrender value.
              </li>
            </ul>
            <h5>3. Your privacy</h5>
            <p>
              We respect the confidentiality of your personal information. At
              the same time, it is essential for insurance companies to share
              claims and underwriting information (as well as credit
              information) with outside parties for the fair assessment and
              underwriting of risks, and to reduce the number of fraudulent
              claims.
            </p>
            <p>
              We may communicate your personal information to other insurance
              companies, and to our service providers who assist us in managing
              your cover and our relationship with you. We will always do this
              as allowed by the relevant privacy legislation.
            </p>
            <h5>
              4. We expect you will read all correspondence we send to you
            </h5>
            <p>
              We will send all correspondence to you on the EcoCash app, SMS or
              email using the contact details that you last gave us. Remember,
              it is your responsibility to update us when your contact details
              change. We assume that you received and read our correspondence if
              it was addressed to you, unless we receive notification that our
              correspondence was not received by you.
            </p>
            <p>All communication with us must be on the EcoCash app.</p>
            <h5>5. Special arrangements do not become the rule</h5>
            <p>
              If we agree to change any deadlines or requirements in terms of
              this policy, it does not mean that we have agreed generally or in
              all cases to change the deadlines or requirements.
            </p>
            <h5>6. South African Rand only</h5>
            <p>
              <em>Total premiums</em> and benefits under this policy must be
              paid in South African Rand only.
            </p>
            <h5>7. South African law only</h5>
            <p>
              We will govern and interpret the policy in accordance with South
              African law in the courts of the Republic of South Africa.
            </p>
            <p>
              <strong> Breadwinner Provider</strong>
            </p>
            <p>Additional Information</p>
            <h4 className="bread-winner-h4">Additional information</h4>
            <p>
              This document contains contact details, disclosures and other
              important information to ensure you always have all the
              information you need. Please keep this document together with your
              general terms and conditions, benefit sections, list of defined
              terms and policy schedule. You chose the policy without
              interacting with an intermediary.
            </p>
            <p>
              <strong>About Sasai</strong>
            </p>
            <p>
              Hollard has appointed Sasai Fintech (Pty) Ltd
              (&ldquo;Sasai&rdquo;) to perform certain intermediary functions
              on Hollard&rsquo;s behalf, as set out in a written agreement
              between Hollard. Sasai is a juristic representative of Hollard.
            </p>
            <p>
              Sasai is obliged to make additional disclosures about the
              policy. Please ensure that you receive and understand this
              information.
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>Juristic Representative:</p>
                  </td>
                  <td>
                    <p>Sasai Fintech (Pty) Ltd</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Company Registration number:</p>
                  </td>
                  <td>
                    <p>2015/225878/07</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Address (Postal &amp; Physical):</p>
                  </td>
                  <td>
                    <p>Worcestor House,</p>
                    <p>Eton Office Park,</p>
                    <p>Corner Sloane and Harrison Avenue,</p>
                    <p>Bryanston</p>
                    <p>Gauteng</p>
                    <p>2196</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Telephone Number:</p>
                  </td>
                  <td>
                    <p>0872380251</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Email Address:</p>
                  </td>
                  <td>
                    <p>DWeinrich@cassavasmartech.com</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Authority:</p>
                  </td>
                  <td>
                    <p>
                      Sasai is a juristic representative appointed by Hollard.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Claims Contact Number: Contact:</p>
                  </td>
                  <td>
                    <p>010&nbsp;120 0800</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Conflict of Interest Management Policy:</p>
                  </td>
                  <td>
                    <p>
                      Sasai has been appointed by Hollard as a juristic
                      representative (JR). Hollard has a Conflict of Interest
                      Management Policy (COIMP) in place. Should you require a
                      copy thereof please contact Hollard using the details
                      below.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Shareholding and interest:</p>
                  </td>
                  <td>
                    <p>
                      Sasai does not hold more than 10% of the Insurer&rsquo;s
                      shares and does not receive more than 30% of total
                      remuneration from the Insurer.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>About the Insurer and administrator- Hollard</strong>
            </p>
            <p>
              The insurer is Hollard Life Assurance Company Limited
              (Registration number 1993/001405/06). Hollard Life is a registered
              insurer and authorised financial services provider. Hollard Life
              is a public unlisted company and has Professional Indemnity
              insurance and Fidelity Guarantee insurance in place.
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>FSP Name:</p>
                  </td>
                  <td>
                    <p>
                      Hollard Life Assurance Company Limited (Registration
                      number 1993/001405/06)
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>FSP License Number:</p>
                  </td>
                  <td>
                    <p>17697</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>FSP License Categories:</p>
                  </td>
                  <td>
                    <p>Category I</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Financial Products:</p>
                  </td>
                  <td>
                    <p>Long Term Insurance</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Professional indemnity insurance and fidelity insurance
                    </p>
                  </td>
                  <td>
                    <p>
                      Hollard has professional indemnity and fidelity insurance.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Address (Postal &amp; Physical):</p>
                  </td>
                  <td>
                    <p>Hollard Villa Arcadia, 22 Oxford Road, Parktown 2193</p>
                    <p>PO Box 87419, Houghton, 2041</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Telephone Number:</p>
                  </td>
                  <td>
                    <p>0861&nbsp;000 107</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Fax Number:</p>
                  </td>
                  <td>
                    <p>011 351 3012</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Email Address:</p>
                  </td>
                  <td>
                    <p>
                      <a href="mailto:hpsadmin@hollard.co.za">
                        hpsadmin@hollard.co.za
                      </a>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Compliance Contact:</p>
                  </td>
                  <td>
                    <p>
                      <a href="mailto:compliance@hollard.co.za">
                        compliance@hollard.co.za
                      </a>
                      or 0861 000 107
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Complaints Contact:</p>
                  </td>
                  <td>
                    <p>
                      <a href="mailto:HPScomplaints@hollard.co.za">
                        HPScomplaints@hollard.co.za
                      </a>
                      or 011&nbsp;3514150
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Conflict of Interest Management Policy</p>
                  </td>
                  <td>
                    <p>
                      Hollard Life has a conflicts of interest management policy
                      in place. A copy of the Hollard Conflict of Interest
                      policy is available on the website.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>How to claim for the benefits</strong>
            </p>
            <p>
              Please contact Sasai Fintech on 010&nbsp;120 0800 if you want to
              make a claim.
            </p>
            <ol>
              <li>
                <strong>
                  The claimant must tell us of the claim in writing within 180
                  days
                </strong>
                from the date of the insured event. We will tell the claimant
                what evidence and other documents we need to process the claim.
              </li>
              <li>
                <strong>
                  The claimant must send us the evidence and other documents we
                  ask for within the time that we will give them.
                </strong>
                All the information that the claimant sends must be in the
                format we prescribe and without expense to us.
              </li>
              <li>
                <strong>If the steps mentioned above are not followed, </strong>
                or we do not receive the information we ask for within the
                agreed time, the claim will not be successful.
              </li>
            </ol>
            <p>
              <strong>How to complain</strong>
            </p>
            <p>
              <em>We</em> hope that <em>you</em> never have reason to complain,
              but if <em>you</em> do,
              <em>we</em> will do
              <em>our</em> best to work with <em>you</em> to resolve it through
              the following process.
            </p>
            <p>
              <strong>General complaints &ndash; contact Hollard</strong>
            </p>
            <p>
              Please contact Hollard with complaints about your policy wording
              or your premium or claims.
            </p>
            <p>
              Tel: 0861 000 107 Email:
              <a href="mailto:hpsadmin@hollard.co.za">hpsadmin@hollard.co.za</a>
            </p>
            <p>
              <strong>
                Unresolved complaints &ndash; contact Hollard Complaints
              </strong>
            </p>
            <p>
              If <em>your</em> concerns are not resolved to <em>your</em>
              satisfaction by contacting the above details, please contact
              Hollard on:
            </p>
            <p>Tel: 011&nbsp;351 4150 Email: hpscomplaints@hollard.co.za</p>
            <h5>If you do not agree with our claims decision</h5>
            <p>When we assess a claim, we could:</p>
            <ol>
              <li>Pay an amount; or</li>
              <li>Say that it is not valid; or</li>
              <li>Cancel this policy.</li>
            </ol>
            <p>
              We will always explain why we made one of the three (3) decisions
              above. The claimant might disagree with us and may request that we
              review our decision. This must be done within 90 days.
            </p>
            <p>
              We will only review our decision if the claimant sends us a
              written request within 90 days from the date of our letter
              explaining our decision.&nbsp;
            </p>
            <p>
              <strong>Complaining</strong>
              <strong> to the Ombudsman </strong>
            </p>
            <p>
              If your concerns are not resolved to your satisfaction by Hollard,
              you may contact the Ombudsman for Long-term Insurance.
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Address</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Telephone and fax</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Email and Web addresses</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Physical:</p>
                    <p>3rd Floor, Sunclare Building</p>
                    <p>21 Dreyer Street, Claremont</p>
                    <p>Cape Town</p>
                    <p>7700</p>
                    <p>Postal:</p>
                    <p>The Ombudsman for Long-term Insurance</p>
                    <p>Private Bag X45</p>
                    <p>Claremont</p>
                    <p>Cape Town</p>
                    <p>7735</p>
                  </td>
                  <td>
                    <p>Tel: +27 (0) 21 657 5000</p>
                    <p>Sharecall: 0860 103 236</p>
                    <p>Fax: +27 (0) 21 674 0951</p>
                  </td>
                  <td>
                    <p>Email: info@ombud.co.za</p>
                    <p>Web: www.ombud.co.za</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>
                Complaints about how <em>you</em> got this policy
              </strong>
            </p>
            <p>
              If <em>you</em> are not happy about this policy or the way
              <em>you</em> got this policy, please write to The Compliance
              Officer on the Hollard Head Office contact details provided above.
            </p>
            <p>
              Tel: 0860 666 675 Email:
              <a href="mailto:compliance@hollard.co.za">
                compliance@hollard.co.za
              </a>
            </p>
            <p>
              Alternatively, you may also contact The Office of the Ombud for
              Financial Services Providers (FAIS Ombud).
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>
                      <strong>Address</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Telephone and fax</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      <strong>Email address</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Physical:</p>
                    <p>Kasteel Park Office Park</p>
                    <p>
                      Orange Building, 2<sup>nd</sup> Floor
                    </p>
                    <p>Cnr Nossob and Jochemus Street</p>
                    <p>Erasmus Kloof</p>
                    <p>Pretoria</p>
                    <p>0048</p>
                    <p>Postal:</p>
                    <p>The FAIS Ombud</p>
                    <p>PO Box 74571</p>
                    <p>Lynnwood Ridge</p>
                    <p>0040</p>
                  </td>
                  <td>
                    <p>Tel: 012 762 5000</p>
                    <p>Fax: 086 764 1422</p>
                  </td>
                  <td>
                    <p>Email: info@faisombud.co.za</p>
                    <p>Web: www.faisombud.co.za</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>
                You may want to take legal action &ndash; if so, this must begin
                within 180 days
              </strong>
            </p>
            <p>
              If the claimant is not satisfied with the outcome of our review of
              this decision, the claimant may also take legal action against us.
              This means the claimant must instruct a lawyer to give a document
              to the sheriff of the court, who will give us a document (called a
              summons). If the summons does not reach us within 180 days after
              the 90 days allowed for the review of our claims decision, then no
              legal action can be taken against us.
            </p>
            <p>
              Alternatively, the claimant may choose to take legal action
              against us without first asking us to&nbsp;review our decision or
              contacting the Ombudsman. In this case, the summons must reach us
              within 270 days of the date of our letter explaining our claims
              decision. If this time limit is not met, the claimant no longer
              has any right to take legal action against us.
            </p>
            <p>
              If the claimant wants to contact the Ombudsman for assistance
              after starting legal action against us, then the claimant must
              first withdraw the summons against us before contacting the
              Ombudsman.
            </p>
            <p>
              <strong>Combat insurance fraud</strong>
            </p>
            <p>
              Fraud affects the insurance industry as a whole. It increases
              claims costs, which in turn increases premiums. Hollard is
              committed to prevent fraud and we have our own internal Anti-Fraud
              policy. Please report any suspicious or unethical activity
              anonymously on 0801 516 170 (toll free) or via email at
              Hollard@tip-offs.com.
            </p>
            <p>
              <strong>About your policy</strong>
            </p>
            <p>
              Please refer to the general terms and conditions, benefit sections
              and policy schedule for the following information:
            </p>
            <ul>
              <li>the complete nature and extent of benefits;</li>
              <li>
                your premium payment responsibilities, manner and frequency
                thereof, and the consequences of non-payment of premium;
              </li>
              <li>special conditions, exclusions; and</li>
              <li>how to cancel your policy.</li>
            </ul>
            <p>
              <strong>Matters of importance</strong>
            </p>
            <ul>
              <li>
                All material facts must be accurately, fully and properly
                disclosed by you. All information provided by you or on your
                behalf is your own responsibility.
              </li>
              <li>
                Misrepresentation, incorrect information or non-disclosure by
                you of any material facts or circumstances may impact negatively
                on any claims arising from your insurance contract.
              </li>
              <li>
                The application form, general terms and conditions, benefit
                sections and the policy schedule, must be read as one document.
              </li>
              <li>
                You are entitled to a copy of the document that represents our
                contract with you, or a policy summary, and will receive one
                within 31 days from the date of entering into or varying the
                policy. You are also entitled to a full copy of the policy upon
                request.
              </li>
              <li>
                If you have not received these documents, or you feel that the
                policy does not meet legal requirements, please submit your
                complaint in writing to us on the details given above.
              </li>
              <li>
                You must be informed of any material changes to the information
                provided above.
              </li>
              <li>
                If the information above was given to you verbally, it must be
                confirmed in writing within 31days.
              </li>
              <li>
                Do not sign any incomplete or blank documents. No person may
                request or insist that you do so.
              </li>
              <li>
                Complete all forms in ink, keep all documents handed to you and
                make notes of what is said to you.
              </li>
              <li>You must not be induced to waive any right or benefit.</li>
              <li>
                You have a right to cancel a policy in writing within 31 days
                after receipt of the summary. You may not exercise this cooling
                off option if you have already claimed under the policy or if
                the event for which the policy insures you has already happened.
              </li>
              <li>
                Please remember to read through everything and make sure that
                the information we have on record for you is correct. If
                anything changes or needs to be amended, please contact us to
                ensure that you are not affected at claims stage.
              </li>
              <li>
                You must not sign any incomplete or blank documents. No person
                may request or insist that you do so.
              </li>
            </ul>
          </div>
        </Content>
        <Footer className="custom-footer">
          <CustomFooter />
        </Footer>
      </Layout>
    );
  }
}
