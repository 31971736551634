import React from 'react';
import { Table, Layout, Spin, Menu, Icon, message,
  // Button, Dropdown, Input
} from 'antd';
import * as moment from 'moment';
import Log from '../utils/log.js';
import '../styles/transaction.css'
import {
  fetchUserDetails,
  requestTransactions,
  showTransactionList,
  fetchNotifications,
  updateReadNotifications,
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ErrorLayout from '../components/error-layout.js';
import CustomFooter from '../components/footer.js';
// import Helper from '../utils/helper.js';
import ManageUrls from '../endpoints/manage_urls.js';
const { Content, Footer } = Layout;
const TAG = "SmartNotificationList";
// const Search = Input.Search;

class NotificationList extends React.Component {
  
  manageUrl = new ManageUrls();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingTip: "",
      currentLink: this.manageUrl.getApiUrl() + "transactions/?page=1",
      columns: [
        {
          title: "Type",
          dataIndex: "additionalFields.imageUrl",
          key: "additionalFields.imageUrl",
          render: (text) => {
            const iconStyle={display: "flex", justifyContent: "center", justifyItems: "center"};
            
            switch (text) {
              case "info":
                return (
                  <p style={iconStyle}>
                    <Icon
                      type="info-circle"
                      theme="twoTone"
                      twoToneColor="orange"
                    />
                  </p>
                );
              case "success":
                return (
                  <p style={iconStyle}>
                    <Icon
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                    />
                  </p>
                );
              case "failure":
                return (
                  <p style={iconStyle}>
                    <Icon
                      type="exclamation-circle"
                      theme="twoTone"
                      twoToneColor="red"
                    />
                  </p>
                );

              default:
                return (
                  <p style={iconStyle}>
                    <Icon
                      type="info-circle"
                      theme="twoTone"
                      twoToneColor="orange"
                    />
                  </p>
                );
            }
          },
        },
        {
          title: "Date",
          dataIndex: "createAt",
          key: "createAt",
          render: (text) => {
            const resultDate = moment.unix(text);
            const formattedDate = resultDate.format('DD MMM YYYY');

            return (
              <span>{formattedDate}</span>
            );
          },
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "Message",
          dataIndex: "content",
          key: "content",
        },
      ],
      filter: 0,
      filters: [
        "All",
        "Pending",
        "Authorised",
        "Completed",
        "Cancelled",
        "Reversed",
        "Quoted",
        "Expired",
      ],
    };
  }


  componentDidMount() {
    document.title = "Notification List"
    this.checkIfHasUser();

    this.props.fetchNotifications(this.props.authReducer.accessToken);
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)

      if (this.props.userReducer.error.includes("password") && this.props.userReducer.error.includes("expired")) {
        message.error("Please change your password first.", 5);
        this.props.history.push({
          pathname: "/update-profile"
        })
      }

      this.setState({
        loading : false,
        loadingTip : ""
      })
    }

    if (prevProps.transactionReducer.transactionList !== this.props.transactionReducer.transactionList) {
      this.setState({
        loading : false,
        loadingTip : ""
      })
    }

  }

  render() {
    return (
      <Layout className="trans-layout">
        <Content className="trans-content-container">
          <div className="rl-top" style={{ paddingLeft: '10px', paddingRight: '10px'}}>
            <h2 className="rl-select-recipient">Notification List</h2>
            {/* <div className="rl-p-search-add-right">
              <Search
                placeholder="Type recipient name or code and press 'Enter'"
                onSearch={this.onSearch}
                className="search"/>
              <Dropdown overlay={this.renderFilterParent()}>
                <div className="trl-p-filter">
                  <Icon type="filter" />
                </div>
              </Dropdown>
            </div> */}
          </div>
          {this.renderContent()}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  renderFilterParent(){
    return (
      <Menu onClick={this.onChangeFilter}>
        {this.renderFilters()}
      </Menu>
    )
  }

  renderFilters(){
    return this.state.filters.map((itemAr, index) => {
      return (
        <Menu.Item key={index}>{itemAr}</Menu.Item>
        );
    })
  }

  onChangeFilter = (event) => {
    Log.d(TAG, "onChangeFilter() -- event.key=" + event.key);
    this.setState({
      filter : event.key
    })
    var status = "";
    if (Number(event.key) === 1) {
      status = "&filter[status]=P"
    } else if (Number(event.key) === 2) {
      status = "&filter[status]=A"
    } else if (Number(event.key) === 3) {
      status = "&filter[status]=C"
    } else if (Number(event.key) === 4) {
      status = "&filter[status]=X"
    } else if (Number(event.key) === 5) {
      status = "&filter[status]=R"
    } else if (Number(event.key) === 6) {
      status = "&filter[status]=S"
    } else if (Number(event.key) === 7) {
      status = "&filter[status]=E"
    }
    this.requestTransactions(this.manageUrl.getApiUrl() + "transactions/?page=1" + status);
  }

  renderContent() {
    Log.d(TAG, "renderError() -- error=" + this.props.userReducer.error);
    if (!this.props.userReducer.error) {
      return (this.renderTable())
    }
    return (
      <ErrorLayout {...this.props}/>
    )
  }

  renderTable() {
    return (
      <div className="trl-point-table" style={{ width: '100%' }}>
        <Spin spinning={this.state.loading} tip={this.state.loadingTip}>
          <Table
            rowClassName={(record, index) => record.status === "read" ? '' : 'table-even-row' }
            size = "middle"
            pagination = {{hideOnSinglePage : true}}
            className="trans-body"
            bordered
            dataSource={this.props.notificationsReducer.notifications.items}
            columns={this.state.columns}
            onRow={(transaction) => {
              return {
                onClick: () => {
                  if (transaction.status !== "read") {
                    this.props.updateReadNotifications(
                      {
                        status: "read",
                        notificationIds: [transaction.id],
                      },
                      this.props.authReducer.accessToken
                    );
                  }
                }
              };
            }}/>
        </Spin>
        {/* <div className="tn-pagination-box">
          <div className = "tn-pagination-box-left">
            {this.showPrevButton()}
              {this.showFirstButton()}
          </div>
          <div className = "tn-pagination-box-middle">
          <span>Page {this.props.transactionReducer.currentPage} of {this.props.transactionReducer.totalPages} pages </span>
          </div>
          <div className = "tn-pagination-box-right">
              {this.showLastButton()}
              {this.showNextButton()}
          </div>
        </div> */}
      </div>
    )
  }

  onSearch = (value) => {
    
  }

  onFilter = () => {
    Log.d(TAG, "onFilter() -- called ..");
  }

  showFirstButton() {
    // if (this.props.transactionReducer.first !== null) {
    //   return (
    //     <Button className = "tn-pagination-box-item" onClick={() => this.requestTransactions(this.props.transactionReducer.first)}>First</Button>
    //   )
    // }
  }

  showLastButton() {
    // if (this.props.transactionReducer.last !== null) {
    //   return (
    //     <Button onClick={() => this.requestTransactions(this.props.transactionReducer.last)}
    //     className = "tn-pagination-box-item">Last</Button>
    //   )
    // }
  }

  showPrevButton() {
    // if (this.props.transactionReducer.prev !== null) {
    //   return (
    //     <Button onClick={() => this.requestTransactions(this.props.transactionReducer.prev)}
    //     className = "tn-pagination-box-item"> Prev</Button>
    //   )
    // }
    // else {
    //   return (
    //     <Button className = "tn-pagination-box-item" onClick={() => this.requestTransactions(this.props.transactionReducer.prev)} disabled> Prev</Button>
    //   )
    // }
  }

  showNextButton() {
    // if (this.props.transactionReducer.next !== null) {
    //   return (
    //     <Button onClick={() => this.requestTransactions(this.props.transactionReducer.next)}
    //     className = "tn-pagination-box-item">Next>></Button>
    //   )
    // }
  }

  loadTransaction = (transaction) => {
    // Log.d(TAG, "loadTransaction() -- transaction=" + JSON.stringify(transaction))
    // this.props.history.push({
    //   pathname: "/transaction-details/" + transaction.id,
    //   state: {
    //     transaction : transaction
    //   }
    // });
  }

  checkIfHasUser() {
    // Log.d(TAG, "checkIfHasUser() -- access=" + cookies.get('access'));
    Log.d(TAG, "checkIfHasUser() -- access=" + this.props.authReducer.accessToken);
    // Log.d(TAG, "checkIfHasUser() -- refresh=" + cookies.get('refresh'));

    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      this.requestUserDetails()
    } else {
      Log.d(TAG, "checkIfHasUser() -- no access available");
    }
  }

  requestUserDetails() {
    Log.d(TAG, "requestUserDetails() -- called ..");
    const { cookies } = this.props;
    // this.props.fetchUserDetails(cookies, cookies.get('access'), false, "");
    this.props.fetchUserDetails(cookies, this.props.authReducer.accessToken, false, "");
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    fetchUserDetails,
    requestTransactions,
    showTransactionList,
    fetchNotifications,
    updateReadNotifications,
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
