import React from "react";
import Log from "../utils/log.js";
import {
  Form,
  Layout,
  Button,
} from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helper from "../utils/helper.js";
import {
  requestSendingCountries,
  requestSignUp,
  requestLogin,
  requestPCAPredictAddressFind,
  requestPCAPredictAddressRetrieve,
  requestCountries,
  setSendingCountry,
  setReferenceCode,
} from "../actions";

const { Content } = Layout;
const TAG = "SmartWelcomeRegistration";

class WelcomeRegistration extends React.Component {

  componentDidMount() {
    const { cookies } = this.props;
    // if (cookies.get('access')) {
    if (!!this.props.authReducer.accessToken) {
      Log.d(TAG, "checkIfHasUser() -- has user");
      this.props.history.push({
        pathname: "/",
      });
    } else {
      if (this.props.match.params.referenceCode) {
        this.props.setReferenceCode(this.props.match.params.referenceCode);
      }
      Log.d(TAG, "checkIfHasUser() -- has no user");
      Helper.clearCookies(cookies);
    }
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <WrappedWelcomeRegistrationForm {...this.props} />
        </Content>
      </Layout>
    );
  }

  componentDidUpdate(prevProps) {}
}

class WelcomeRegistrationForm extends React.Component {

  renderStep1() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="">
            <div className="">
              <b>Welcome to Sasai Money Transfer</b>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p>You will need the following to register:</p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <ul
                  style={{
                    width: "40%",
                    margin: "0 auto",
                  }}
                >
                  <li style={{ textAlign: "left"}}>Mobile Number</li>
                  <li style={{ textAlign: "left"}}>Proof of ID</li>
                  <li style={{ textAlign: "left"}}>Selfie</li>
                </ul>
              </div>
              <br />
              <div>{this.actionButtons()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  actionButtons() {
    const next = () => {
      this.props.history.push({
        pathname: "/look-up",
      });
    };

    const buttonStyle = {
      backgroundColor: "rgb(41, 108, 187)",
      borderColor: "rgb(41, 108, 187)",
      boxSizing: "border-box",
      color: "white",
      border: "1px solid rgb(41, 108, 187)",
      marginRight: 8,
    };

    return (
      <>
        <Button style={buttonStyle} onClick={() => next()}>
          Next
        </Button>
      </>
    );
  }

  render() {
    return (
      <div className="signup-body">
        <h2 style={{ fontFamily: "Lato" }}>Registration</h2>
        <div>{this.renderStep1()}</div>
      </div>
    );
  }
}

const WrappedWelcomeRegistrationForm = Form.create()(WelcomeRegistrationForm);

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      requestSendingCountries,
      requestSignUp,
      requestLogin,
      requestPCAPredictAddressFind,
      requestPCAPredictAddressRetrieve,
      requestCountries,
      setSendingCountry,
      setReferenceCode,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeRegistration);
