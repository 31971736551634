import React from 'react';
import { Layout } from 'antd';
import queryString from 'query-string';
import Log from '../utils/log.js';
import Helper from '../utils/helper.js';
import { Input, Button, Form, message } from 'antd';
import {
  requestResetPassword
} from '../actions';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import '../styles/index.css'
import '../styles/user.css'
// import '../../styles/ant-theme-vars.less'
//import 'antd/dist/antd.css';
import ManageUrls from '../endpoints/manage_urls.js';
import baseUrlOptions from '../endpoints/base_url_options.js';

const FormItem = Form.Item;
const { Content } = Layout;
const TAG = "SmartResetPassword";

class ResetPassword extends React.Component {

  render() {
    return (
      <Content className="user-content-container-2">
        <WrappedPasswordForm {...this.props} />
      </Content>
    )
  }

  componentDidMount() {
    const manageUrls = new ManageUrls();

    if(this.props.match.params.country === 'za'){
      manageUrls.setBaseCountry(baseUrlOptions.SOUTH_AFRICA);
    } if (this.props.match.params.country === 'gb') {
      manageUrls.setBaseCountry(baseUrlOptions.UNITED_KINGDOM);
    }
  }

  componentDidUpdate(prevProps) {
    Log.d(TAG, "componentDidUpdate() -- called ..");
    Log.d(TAG, "componentDidUpdate() -- props=" + JSON.stringify(this.props));

    if (this.props.userReducer.error !== null && prevProps.userReducer.error !== this.props.userReducer.error) {
      Log.d(TAG, "componentDidUpdate() -- set error=" + this.props.userReducer.error)
      message.error("" + this.props.userReducer.error, 5)
    }

    if (this.props.userReducer.loginMessage !== null && prevProps.userReducer.loginMessage !== this.props.userReducer.loginMessage) {
      Log.d(TAG, "componentDidUpdate() -- show login .. ")
      Helper.clearCookies(this.props.cookies)
      this.props.history.push({
        pathname: "/login"
      });
    }
  }
}

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      isAgent: false,
    }
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const userType = Number(params.user_type);

    if (userType == 2) {
      this.setState({ isAgent: false });
    } else if (userType == 18) {
      this.setState({ isAgent: true });
    } else {
      this.setState({ isAgent: false });
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('New password did not match to this value');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_new_password'], { force: true });
    }
    callback();
  }

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        Log.d(TAG, 'Received values of form: '+ values);
        this.requestResetPassword()
      }
    });
  }

  removeTrailingSlash(site){
    return site.replace(/\/+$/, "");
  }

  requestResetPassword(){
    var url = this.removeTrailingSlash(this.props.match.url);
    var urlSplit = url.split("/");

    Log.d(TAG, "requestResetPassword() -- urlSplit=" + JSON.stringify(urlSplit));

    var uid = urlSplit[urlSplit.length - 2];
    var token = urlSplit[urlSplit.length - 1];

    Log.d(TAG, "requestResetPassword() -- uid=" + uid);
    Log.d(TAG, "requestResetPassword() -- token=" + token);

    var attributesObj = {};
    attributesObj.token  = token;
    attributesObj.user_type = this.state.isAgent ? 18 : 2;
    attributesObj.password  = this.props.form.getFieldValue('new_password');

    var dataObj = {};
    dataObj.attributes = attributesObj;
    dataObj.id = uid;
    dataObj.type = "passwords";

    var payloadObj = {};
    payloadObj.data = dataObj;
    Log.d(TAG, "requestResetPassword() -- payload=" + JSON.stringify(payloadObj));
    this.props.requestResetPassword(payloadObj, uid);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const numericPattern = new RegExp('\\d', 'g');
    const alphaNumericPattern = new RegExp('.', 'g');

    const patternCheck = this.state.isAgent ? numericPattern : alphaNumericPattern;

    return (
      <Form className="user-password-body"
        onSubmit={this.handleSubmit.bind(this)}>
        <h3 className="user-title">Reset Password</h3>
        <FormItem label="New Password">
          {getFieldDecorator('new_password', {
            rules: [{
              required: true, message: 'Please provide your new password',
            }, {
              validator: this.validateToNextPassword,
            }, {
              min: this.state.isAgent ? 6 : 8, message: `Confirm New Password should be minumum of ${this.state.isAgent ? "6 digits" : "8 character"}`,
            }, {
              pattern: patternCheck, message: this.state.isAgent ? "Password must be a number" : "Please provide a password",
            },{
              max: this.state.isAgent ? 6 : 256, message: this.state.isAgent ? "Password should be exactly 6 digits long" : "Password should be less than 256 characters"
            }],
          })(
            <Input type="password" className = "form-control"/>
          )}
        </FormItem>
        <FormItem label="Confirm New Password">
          {getFieldDecorator('confirm_new_password', {
            rules: [{
              required: true, message: 'Please provide confirm your new password',
            }, {
              validator: this.compareToFirstPassword,
            }, {
              min: this.state.isAgent ? 6 : 8, message: `Confirm New Password should be minumum of ${this.state.isAgent ? "6 digits" : "8 character"}`,
            }, {
              pattern: patternCheck, message: this.state.isAgent ? "Password must be a number" : "Please provide a password",
            },{
              max: this.state.isAgent ? 6 : 256, message: this.state.isAgent ? "Password should be 6 digits long" : "Password should be less than 256 characters"
            }],
          })(
            <Input type="password" className = "form-control" />
          )}
        </FormItem>
        <div style={{ width: '100%', padding: '20px', textAlign: 'left'}}>
          <b style={{width: '100%', color: 'red'}}>Fields with * are required.</b>
        </div>
        <FormItem className="user-custom-btn-row">
          <Button className="home-collect"
            style={{
              backgroundColor: '#154989',
              boxSizing: 'border-box',
              color: 'white',
              marginBottom: '8px'
            }} type="primary" htmlType="submit">RESET PASSWORD</Button>
        </FormItem>
      </Form>
    )
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch))
  return bindActionCreators({
    requestResetPassword
  }, dispatch)
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state))
  return {...state};
}

const WrappedPasswordForm = Form.create()(PasswordForm);

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
