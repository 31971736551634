import React from "react";
import Log from "../utils/log.js";
import { Layout, Descriptions } from "antd";
import "../styles/login.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Helper from "../utils/helper.js";
import * as moment from "moment";

const { Content } = Layout;
const { Item } = Descriptions;
const TAG = "SmartFamilyAndFriendsDetails";

class FamilyAndFriendsDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      details: null,
    };
  }

  componentDidMount() {
    const referralId = this.props.match.params.referralId;
    const selectedReferral = this.props.referralsReducer.referrals.data.find(
      (referral) => referral.id === referralId
    );

    if (selectedReferral) {
      this.setState({
        details: selectedReferral,
      });
    } else {
      this.props.history("/family-and-friends");
    }
  }

  renderReferralDetails(details) {
    const name = `${details.attributes.first_name} ${details.attributes.last_name}`;
    const status = Helper.showUserStatus(details.attributes.user_status);
    const mobile = `+${details.attributes.mobile[0]}${details.attributes.mobile[1]}`;
    const commission =
      details.attributes.commission === null
        ? "0.00"
        : details.attributes.commission;

    const resultDate = moment(details.attributes.date_joined);
    const date = resultDate.format("DD/MM/YYYY");

    return (
      <Descriptions bordered>
        <Item label="Customer Name:" span={3}>
          {name}
        </Item>
        <Item label="Registration Status:" span={3}>
          {status}
        </Item>
        <Item label="Registration Date:" span={3}>
          {date}
        </Item>
        <Item label="Mobile Number:" span={3}>
          {mobile}
        </Item>
        <Item label="Commission Earned:" span={3}>
          {commission}
        </Item>
      </Descriptions>
    );
  }

  render() {
    return (
      <Layout className="su-layout">
        <Content className="signup-content-container">
          <div className="signup-body">
            <h2 style={{ fontFamily: "Lato" }}>Referral details</h2>
            {this.state.details === null ? (
              <p>No details</p>
            ) : (
              this.renderReferralDetails(this.state.details)
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  Log.d(TAG, "mapDispatchToProps() -- dispatch=" + JSON.stringify(dispatch));
  return bindActionCreators(
    {
      // requestSendingCountries,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  Log.d(TAG, "mapStateToProps() -- props=" + JSON.stringify(state));
  return { ...state };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyAndFriendsDetails);
